import React from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../../shared/constants/colors'
import { InputImage } from '../../../shared/ui-kit/actual/InputImage'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { FlexColumn, StyledButton } from '../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../shared/ui-kit/titles'
import { PassportAddStepStatus } from './PersonalDataPassport'

const ImageWrapper = styled(FlexColumn)`
  border-radius: 12px;
  width: 100%;
  background-color: ${colors.lightGray};
  margin: 16px 0;
`

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
// mock data
const userInfo = {
  avatarLink: '/images/mock-items/users-avatars/user-3-large.jpg',
  city: 'Санкт-Петербург',
  fullName: 'Кольцов Илья Николаевич',

  rating: {
    clientRating: 5,
    clientReviewsCount: 5,
    lessorRating: 5,
    lessorReviewsCount: 5,
  },
  userName: 'koltsov_ilia',
}

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<PassportAddStepStatus>>
}
export const DocsBusinessAddForm = ({ setStatus }: Props) => {
  // const {
  //   userInfo
  // } = useProfileInfo();
  return (
    <FormWrapper>
      <H3 style={{ marginTop: '8px' }}>Добавить документы</H3>
      {/* <ImageWrapper>
        <InputImage
          // defaultImageUrl={userInfo?.avatarLink}
          handleChange={() => {}}
          variant={'full'}
        />
      </ImageWrapper>
      <TextField
        inputProps={{
          defaultValue: `${userInfo?.fullName || ''}`,
          placeholder: 'ФИО',
        }}
        label={'ФИО'}
        fullwidth
      />
      <TextField
        inputProps={{
          defaultValue: '',
          placeholder: 'Серия и номер',
        }}
        label={'Серия и номер'}
        fullwidth
      />
      <TextField
        inputProps={{
          defaultValue: '',
          placeholder: 'Кем выдан',
        }}
        label={'Кем выдан'}
        fullwidth
      />
      <TextField
        inputProps={{
          defaultValue: '',
          placeholder: 'Код подразделения',
        }}
        label={'Код подразделения'}
        fullwidth
      />
      <TextField
        inputProps={{
          defaultValue: '',
          placeholder: 'Дата выдачи',
        }}
        label={'Дата выдачи'}
        fullwidth
      />
      <TextField
        inputProps={{
          defaultValue: '',
          placeholder: 'Прописан',
        }}
        label={'Прописан'}
        fullwidth
      /> */}
      <StyledButton onClick={() => setStatus('success')} fullwidth>
        Подтвердить
      </StyledButton>
      <StyledButton onClick={() => setStatus('error')} variant={'secondary'} fullwidth>
        Отмена
      </StyledButton>
    </FormWrapper>
  )
}
