import { BalanceChangeType, OperationStatus, TransactionType } from '../api/generated'

export const transactionsStatuses = {
  Canceled: 'отменен',
  Pending: 'в процессе',
  Succeeded: 'успешно',
}
export const operationsStatuses: Record<OperationStatus, string> = {
  approved: 'успешно',
  cancelled: 'отменен',
  created: 'создано',
  rejected: 'отменено',
  undefined: 'неизвестно',
}
export const transactionsTypes: Record<TransactionType, string> = {
  payment: 'пополнение',
  payout: 'вывод средств',
}
export const operationsTypes: Record<BalanceChangeType, string> = {
  manualChange: 'админ',
  payment: 'пополнение',
  payout: 'вывод',
  rentCancel: 'отмена аренды',
  rentCompletion: 'завершение аренды',
  rentCreation: 'создание аренды',
  rentPledgeReturn: 'возврат залога',
  undefined: 'неопознано',
  withdrawalRequest: 'запрос на вывод',
}
