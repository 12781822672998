import styled from 'styled-components/macro'

import { grayStarLarge, primaryStarLargeSVG } from '../../constants/images'
import { FlexRow } from '../projectComponents'

const StarsWrapper = styled(FlexRow)`
  column-gap: 12px;
`
const Star = styled.div`
  cursor: pointer;
`

type Props = {
  rating: number
  setRating: (rating: number) => void
}
export const SetStarsRating = ({ rating, setRating }: Props) => {
  return (
    <StarsWrapper>
      {[1, 2, 3, 4, 5].map(i => {
        if (i > rating) {
          return (
            <Star key={i} onClick={() => setRating(i)}>
              <img alt={''} src={grayStarLarge} />
            </Star>
          )
        } else {
          return (
            <Star key={i} onClick={() => setRating(i)}>
              <img alt={''} src={primaryStarLargeSVG} width={35} />
            </Star>
          )
        }
      })}
    </StarsWrapper>
  )
}
