import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-custom-alert'
import { FieldErrors, UseFormSetValue } from 'react-hook-form'

import debounce from 'lodash.debounce'
import styled from 'styled-components/macro'

import { useGetUserAddresses } from '../../../../entities/userAddress'
import {
  AddUserAddressCommand,
  PostApiProductsCreateProductBody,
  UserAddressDto,
} from '../../../../shared/api/generated'
import fetchGeoCode from '../../../../shared/api/geo-code-api'
import { queryClient } from '../../../../shared/api/query-client'
import { DEBOUNCE_TIMEOUT } from '../../../../shared/constants/transition'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { InputSelect } from '../../../../shared/ui-kit/actual/InputSelect/InputSelect'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { AddressRow } from '../../../../shared/ui-kit/actual/components/AddressRow'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import { StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { verticalScroll } from '../../../../shared/ui-kit/scroll'
import { H2 } from '../../../../shared/ui-kit/titles'

const Wrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    max-width: 100%;
  }
`

const AddressesList = styled.div`
  width: 100%;
  max-height: 300px;
  height: auto;
  overflow: auto;
  ${verticalScroll};
`

const NewAddressSection = styled.div`
  position: relative;
`

type Props = {
  errors: FieldErrors<PostApiProductsCreateProductBody>
  selectedAddressId: number
  setDisabledBtn: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<UserAddressDto>>
  setSelectedAddressId: React.Dispatch<React.SetStateAction<number>>
  setValue: UseFormSetValue<PostApiProductsCreateProductBody>
  submitAddress: (data: AddUserAddressCommand) => Promise<void>
}
export const AddItemPickup = ({
  errors,
  selectedAddressId,
  setDisabledBtn,
  setSelectedAddress,
  setSelectedAddressId,
  setValue,
  submitAddress,
}: Props) => {
  const { userId } = useContext(UserStorage)
  const [isLoadingNewAddress, setIsLoadingNewAddress] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')

  const { data: dataAddress, isLoading: addressesLoading } = useGetUserAddresses(userId)

  useEffect(() => {
    if (dataAddress?.addresses && !selectedAddressId) {
      const defaultAddress = dataAddress?.addresses.find(address => address.isDefault)

      setSelectedAddressId(defaultAddress?.id)
      setValue('PickupAddressId', defaultAddress?.id)

      setSelectedAddress(defaultAddress)
      setDisabledBtn(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAddress, setDisabledBtn, setSelectedAddress, setSelectedAddressId, setValue])
  const saveNewAddress = async e => {
    e.preventDefault()
    setIsLoadingNewAddress(true)

    if (inputValue.length > 6) {
      try {
        const [data, point] = await fetchGeoCode(inputValue)
        const [longitude, latitude] = point.split(' ')
        const address =
          data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
            .GeocoderMetaData.Address.Components
        const fullLineAddress =
          data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
            .GeocoderMetaData.Address.formatted

        const postCode =
          data?.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
            .GeocoderMetaData.AddressDetails?.Country?.AdministrativeArea?.Locality?.Thoroughfare
            ?.Premise?.PostalCode?.PostalCodeNumber || ''

        const newAddress = {
          address: fullLineAddress,
          city: address[2].name,
          country: address[0].name,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          name: inputValue,
          postcode: postCode,
        }

        await submitAddress(newAddress)
        setInputValue('')
        queryClient.invalidateQueries({ queryKey: ['get_user_addresses'] })
      } catch (error) {
        toast.error('Ошибка при отправке нового адреса')
      } finally {
        setIsLoadingNewAddress(false)
      }
    }
  }

  const handleChecked = (addressId: number) => {
    const checkedAddress = dataAddress?.addresses.find(address => address.id == addressId)

    setSelectedAddress(checkedAddress)
    setSelectedAddressId(addressId)
    setValue('PickupAddressId', addressId)
    setDisabledBtn(false)
  }

  const [selectIsOpen, setSelectIsOpen] = useState(true)

  const inputClickHandler = () => {
    setSelectIsOpen(true)
  }

  const handleSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.textContent)
    setSelectIsOpen(prev => !prev)
  }

  const [isLoadingAddress, setIsLoadingAddress] = useState(false)
  const [variantsAddresses, setVariantAddresses] = useState([])

  const fetchAddress = async (searchValue: string) => {
    setIsLoadingAddress(true)

    if (searchValue.length >= 3) {
      try {
        const [data] = await fetchGeoCode(searchValue)

        setVariantAddresses(data?.response?.GeoObjectCollection?.featureMember)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingAddress(false)
      }
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedFetcher = useCallback(
    debounce(({ searchValue }) => {
      void fetchAddress(searchValue)
    }, DEBOUNCE_TIMEOUT),
    []
  )

  const handleChangeAddressInput = searchValue => {
    setInputValue(searchValue)
    debouncedFetcher({ searchValue })
  }

  return (
    <Wrapper>
      <H2>Самовывоз</H2>
      <AddressesList>
        {addressesLoading && !isLoadingNewAddress ? (
          <PageLoader />
        ) : (
          dataAddress?.addresses?.map(address => (
            <AddressRow
              address={address}
              isLoading={addressesLoading}
              isSelected={address.id === selectedAddressId}
              key={address.id}
              setSelectedAddress={handleChecked}
            />
          ))
        )}
      </AddressesList>
      <NewAddressSection>
        <TextField
          inputProps={{
            onChange: e => handleChangeAddressInput(e.target.value),
            onClick: inputClickHandler,
            placeholder: 'Добавить новый адрес самовывоза',
            type: 'text',
            value: inputValue,
          }}
          error={errors.PickupAddressId?.message?.toString()}
          label={'Адрес'}
          size={'md'}
          fullwidth
        />
        {inputValue.trim().length > 1 && selectIsOpen ? (
          <InputSelect
            isLoadingAddress={isLoadingAddress}
            selectOptions={variantsAddresses}
            setSelected={handleSelectOption}
          />
        ) : null}
        <StyledButton
          disabled={inputValue.trim().length < 5}
          onClick={saveNewAddress}
          style={{ marginTop: '50px' }}
          variant={'secondary'}
        >
          Добавить адрес самовывоза
        </StyledButton>
      </NewAddressSection>
    </Wrapper>
  )
}
