import { AccountType } from '../../../../shared/api/generated'
import { MediumText } from '../../../../shared/ui-kit/projectComponents'
import { H4 } from '../../../../shared/ui-kit/titles'

const Danger = () => (
  <div>
    <H4>Небезопасный аккаунт</H4>
    <MediumText>Данный статус присваивается аккаунтам, которые не подтвердили паспорт</MediumText>
  </div>
)
const Verified = () => (
  <div>
    <H4>Подтвержденный аккаунт</H4>
    <MediumText>Для получения данного статуса требуется подтвердить паспорт</MediumText>
  </div>
)
const Premium = () => (
  <div>
    <H4>Премиум аккаунт</H4>
    <MediumText>Данный статус присваивается пользователям, которые оформили премиум</MediumText>
  </div>
)
// eslint-disable-next-line no-undef
const componentList: Record<AccountType, () => JSX.Element> = {
  notVerified: Danger,
  premium: Premium,
  undefined: Danger,
  verified: Verified,
}

export const TagsModalTexts = ({ accountType }: { accountType: AccountType }) => {
  return <>{componentList[accountType]()}</>
}
