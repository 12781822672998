import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { getNounDeclension } from '../../utils/getNounDeclension'
import { FlexColumn, FlexColumnStart, FlexRow } from '../projectComponents'

interface SectionButtonProps {
  img: string
  productCount?: number
  title: string
  to: string
}

const SectionWrapper = styled(FlexColumn)`
  width: 350px;
  height: 195px;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0px 7.800000190734863px 13.606666564941406px 0px #5e5e5e12;
`
const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
`

const BackgroundImage = styled(FlexColumn)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 124.8px;
  text-align: left;
  align-items: start;
  justify-content: center;
`
const Title = styled.h2`
  font-weight: 700;
  font-size: 21px;
  margin: 0;
  margin-left: 20px;
  color: ${colors.black};
  line-height: 150%;
  max-width: 200px;
`
const Subtitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-left: 20px;
  color: ${colors.black};
  line-height: 150%;
`
const BottomWrapper = styled(FlexRow)`
  height: 70px;
  justify-content: start;
  margin-left: 20px;
`
const BottomTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  color: ${colors.black};
`
const BottomSubtitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  margin: 0;
  color: ${colors.grey};
`
const UserImage = styled.img`
  width: 34.67px;
  height: 34.67px;
  border: 0.87px px solid #e2ecff;
  border-radius: 100%;
`

export const SectionItem = ({ img, productCount, title, to }: SectionButtonProps) => {
  return (
    <SectionWrapper>
      <StyledLink to={to}>
        <BackgroundImage style={{ backgroundImage: `url(${img})` }}>
          <Title>{title}</Title>
        </BackgroundImage>
        <BottomWrapper>
          {/* <FlexRow>
            <UserImage
              alt={''}
              src={'/images/mock-items/users-avatars/1.jpg'}
              style={{ zIndex: 3 }}
            />
            <UserImage
              alt={''}
              src={'/images/mock-items/users-avatars/2.jpg'}
              style={{ transform: 'translateX(-50%)', zIndex: 2 }}
            />
            <UserImage
              alt={''}
              src={'/images/mock-items/users-avatars/3.jpg'}
              style={{ transform: 'translateX(-100%)', zIndex: 1 }}
            />
          </FlexRow> */}
          <FlexColumnStart style={{ transform: 'translateX(-10px)' }}>
            <BottomTitle>{title}</BottomTitle>
            {productCount > 0 && (
              <BottomSubtitle>
                Более {productCount} {getNounDeclension(productCount)}
              </BottomSubtitle>
            )}
          </FlexColumnStart>
        </BottomWrapper>
      </StyledLink>
    </SectionWrapper>
  )
}
