import { useContext } from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { useGetTransactionsQuery } from '../../../entities/transactions/queries'
import { TransactionDto } from '../../../shared/api/generated'
import { transactionsStatuses, transactionsTypes } from '../../../shared/constants/transactions'
import UserStorage from '../../../shared/storage/UserStorage/UserStorage'
import { NoProducts } from '../../../shared/ui-kit/actual/NoProducts'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumnBetween, Table, Th } from '../../../shared/ui-kit/projectComponents'

const Wrapper = styled(FlexColumnBetween)``

const NoContentWrapper = styled(FlexColumnBetween)`
  width: 100%;
  height: 100%;
  row-gap: 8px;
`

// const mockData = { passportImg: '/images/mock-items/my-profile/passport-example.png', verifiedState: 'pending' }
// const mockData = { passportImg: '/images/mock-items/my-profile/passport-example.png', verifiedState: 'rejected' }

// const mockData = null

export const TransactionsList = ({ transactions }: { transactions: TransactionDto[] }) => (
  <>
    <Table>
      <thead>
        <tr>
          <Th>Дата</Th>
          <Th>Сумма</Th>
          <Th>Тип</Th>
          <Th>Статус</Th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(t => (
          <tr key={t.id}>
            <td>{format(new Date(t.createdAt), 'dd MMMM HH:mm', { locale: ru })}</td>
            <td>{t.amount}</td>
            <td>{transactionsTypes[t.type]}</td>
            <td>{transactionsStatuses[t.status] || t.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
)
const NoContent = () => (
  <NoContentWrapper>
    <NoProducts
      imgPath={'/images/interface/Wallet-transparent.svg'}
      message={'Вы еще не проводили транзакции'}
      width={100}
    />
  </NoContentWrapper>
)

export const PersonalDataTransactions = () => {
  const { userId } = useContext(UserStorage)
  const { data, isLoading, isSuccess } = useGetTransactionsQuery({ Take: 1000, UserId: userId })

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <Wrapper>
        {data && data.items?.length && !isLoading ? (
          <TransactionsList transactions={data.items} />
        ) : (
          <>{isSuccess && <NoContent />}</>
        )}
      </Wrapper>
    </>
  )
}
