import { ToastContainer } from 'react-custom-alert'

import ReactDOM from 'react-dom/client'

import './app/index.css'
import 'react-custom-alert/dist/index.css'

import App from './app/App'
import { AppProvider } from './app/providers/AppProvider/AppProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AppProvider>
    <App />
    <ToastContainer floatingTime={4000} />
  </AppProvider>
)
