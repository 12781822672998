import React, { useState } from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { useDeleteReviewMutation } from '../../../../entities/reviews/queries'
import { EditReviewForm } from '../../../../features/admin/ui/EditReviewForm'
import { ReviewListItemDto } from '../../../api/generated'
import { queryClient } from '../../../api/query-client'
import { colors } from '../../../constants/colors'
import { MiniModal } from '../../actual/modals/MiniModal'
import { FlexColumn, FlexRow, FlexRowBetween, Modal, StyledButton } from '../../projectComponents'
import { H2 } from '../../titles'
import { Spacer } from '../Spacer'

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  img {
    width: 16px;
    height: 16px;
  }
`

const DataElement = styled(FlexColumn)`
  width: calc(100% - 30px);
  justify-content: start;
  align-items: start;
  margin: 5px;
`
const DataTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin: 8px 0 4px;
`
const DataInfo = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  text-align: left;
`
const BorderLine = styled.div`
  width: calc(100% - 32px);
  margin: 20px 0;
  border: 0.5px solid ${colors.lightGray};
`
const UserAvatar = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 100%;
`

interface ReviewModalProps {
  reviewData: ReviewListItemDto
  setOpen: any
}

const ReviewModal: React.FC<ReviewModalProps> = ({ reviewData, setOpen }) => {
  const createDate = format(new Date(reviewData?.createOn), 'dd MMMM HH:mm', { locale: ru })

  const showProductInfo = [
    {
      info: reviewData?.buyerUserName,
      title: 'Ник рецензента',
    },
    {
      info: reviewData?.buyerFullName,
      title: 'ФИО рецензента',
    },
    {
      info: reviewData?.authorId,
      title: 'ID рецензента',
    },
  ]
  const showProductInfo2 = [
    {
      info: reviewData?.sellerUserName,
      title: 'Ник владельца',
    },
    {
      info: reviewData?.sellerFullName,
      title: 'ФИО владельца',
    },
    {
      info: reviewData?.sellerId,
      title: 'ID владельца',
    },
  ]
  const showProductInfo3 = [
    {
      info: reviewData?.reviewId,
      title: 'ID оценки',
    },
    {
      info: reviewData?.rating,
      title: 'Оценка',
    },
    {
      info: reviewData?.productId,
      title: 'ID продукта',
    },
    {
      info: createDate,
      title: 'Дата создания',
    },
  ]
  const showProductInfo4 = [
    {
      info: reviewData?.content,
      title: 'Текст',
    },
  ]

  const { mutate: deleteMutate } = useDeleteReviewMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_reviews_list'] })
    },
  })
  const [deleteOpen, setDeleteOpen] = useState(false)
  const openDeleteModal = () => {
    setDeleteOpen(true)
  }
  const handleDelete = () => {
    deleteMutate({ reviewId: reviewData?.reviewId })
    setDeleteOpen(false)
  }
  const [editOpen, setEditOpen] = useState(false)
  const openEditModal = () => {
    setEditOpen(true)
  }

  return (
    <Modal>
      <FlexColumn style={{ width: '100%' }}>
        <CloseButton onClick={() => setOpen(false)}>
          <img alt={''} src={'/images/buttons/close.png'} />
        </CloseButton>
        <Spacer />
        <Spacer />
        <Spacer />
        <DataElement>
          <UserAvatar
            alt={''}
            src={reviewData?.buyerAvatarImageUrl || '/images/buttons/user-avatar.png'}
          />
        </DataElement>
        {showProductInfo.map((ui, idx) => (
          <DataElement key={idx}>
            <DataTitle>{ui.title} </DataTitle>
            <DataInfo>{ui.info} </DataInfo>
          </DataElement>
        ))}
        <BorderLine />
        <DataElement>
          <UserAvatar
            alt={''}
            src={reviewData?.sellerAvatarImageUrl || '/images/buttons/user-avatar.png'}
          />
        </DataElement>
        {showProductInfo2.map((ui, idx) => (
          <DataElement key={idx}>
            <DataTitle>{ui.title} </DataTitle>
            <DataInfo>{ui.info} </DataInfo>
          </DataElement>
        ))}
        <BorderLine />
        {showProductInfo3.map((ui, idx) => (
          <DataElement key={idx}>
            <DataTitle>{ui.title} </DataTitle>
            <DataInfo>{ui.info} </DataInfo>
          </DataElement>
        ))}
        <BorderLine />
        {showProductInfo4.map((ui, idx) => (
          <DataElement key={idx}>
            <DataTitle>{ui.title} </DataTitle>
            <DataInfo>{ui.info} </DataInfo>
          </DataElement>
        ))}
        <Spacer />
      </FlexColumn>

      <FlexRow style={{ gap: 16, marginBottom: 32 }}>
        <StyledButton onClick={() => openEditModal()} size={'xl'}>
          Редактировать отзыв
        </StyledButton>
        <StyledButton onClick={() => openDeleteModal()} size={'xl'} variant={'warning'}>
          Удалить отзыв
        </StyledButton>
      </FlexRow>

      <MiniModal setViewModal={() => setDeleteOpen(false)} viewModal={deleteOpen}>
        <FlexColumn>
          <br />
          <H2>Вы уверены, что хотите удалить отзыв?</H2>
          <br />
          <FlexRowBetween>
            <StyledButton onClick={() => setDeleteOpen(false)} variant={'secondary'}>
              НЕТ
            </StyledButton>
            <StyledButton onClick={handleDelete} variant={'warning'}>
              ДА
            </StyledButton>
          </FlexRowBetween>
          <br />
        </FlexColumn>
      </MiniModal>

      <MiniModal setViewModal={() => setEditOpen(false)} viewModal={editOpen}>
        <EditReviewForm
          commentOld={reviewData?.content}
          ratingOld={reviewData?.rating}
          reviewId={reviewData?.reviewId}
        />
      </MiniModal>
    </Modal>
  )
}

export default ReviewModal
