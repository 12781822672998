import React from 'react'

import styled from 'styled-components/macro'

import { FlexColumn } from '../projectComponents'

interface BlockProps {
  $hideFilterButton?: boolean
  $noMarginRight?: boolean
}

const Block = styled(FlexColumn)<BlockProps>`
  cursor: pointer;
  margin-right: ${({ $noMarginRight }) => !$noMarginRight && '12px'};
  display: ${({ $hideFilterButton }) => $hideFilterButton && 'none'};

  img {
    height: 18px;
    width: 18px;
  }
`

interface FilterProps {
  hideFilterButton?: boolean
  noMarginRight?: boolean
  onClickFilter: () => void
}

export const FilterButton: React.FC<FilterProps> = ({
  hideFilterButton,
  noMarginRight,
  onClickFilter,
}) => {
  return (
    <Block
      $hideFilterButton={hideFilterButton}
      $noMarginRight={noMarginRight}
      onClick={onClickFilter}
    >
      <img alt={''} src={'/images/buttons/filter.svg'} />
    </Block>
  )
}
