import React, { useContext } from 'react'

import * as Accordion from '@radix-ui/react-accordion'

import { colors } from '../../../../shared/constants/colors'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { AccordionWrapper } from '../../../../shared/ui-kit/AccordionWrapper'
import { AccordionContent, AccordionTrigger } from '../../../../shared/ui-kit/actual/AccordionItems'
import { useProfileInfo } from '../../../profile/model/use-profile-info'
import { PersonalDataAddress } from '../PersonalDataAddress'
import { PersonalDataBusinessDocs } from '../PersonalDataBusinessDocs'
import { PersonalDataBusinessPay } from '../PersonalDataBusinessPay'
import { PersonalDataForm } from '../PersonalDataForm'
import { PersonalDataOperations } from '../PersonalDataOperations'
import PersonalDataPassport from '../PersonalDataPassport'
import { PersonalDataPay } from '../PersonalDataPay'
import { PersonalDataTransactions } from '../PersonalDataTransactions'
type Props = {
  setDesktopAccordionState: React.Dispatch<React.SetStateAction<string>>
}
export const ProfilePersonalDataAccordion = ({ setDesktopAccordionState }: Props) => {
  const store = useContext(UserStorage)
  const { userInfo } = useProfileInfo()

  return (
    <AccordionWrapper>
      <Accordion.Root
        className={'AccordionRoot'}
        orientation={'horizontal'}
        // defaultValue={'item-1'}
        type={'single'}
        collapsible
      >
        <Accordion.Item className={'AccordionItem'} value={'item-1'}>
          <AccordionTrigger
            onClick={() => setDesktopAccordionState(prev => (prev === 'item-1' ? '' : 'item-1'))}
          >
            <div>
              <img alt={''} src={'/images/buttons/user-avatar-small.svg'} />
              <span>Личные данные</span>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <PersonalDataForm />
          </AccordionContent>
        </Accordion.Item>

        <Accordion.Item className={'AccordionItem'} value={'item-2'}>
          <AccordionTrigger
            onClick={() => setDesktopAccordionState(prev => (prev === 'item-2' ? '' : 'item-2'))}
          >
            <div>
              <img alt={''} src={'/images/buttons/Wallet.svg'} />
              <span>Способы оплаты</span>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            {userInfo.legalStatus === 'legalEntity' || userInfo.legalStatus === 'soleProprietor' ? (
              <PersonalDataBusinessPay />
            ) : (
              <PersonalDataPay />
            )}
          </AccordionContent>
        </Accordion.Item>

        <Accordion.Item className={'AccordionItem'} value={'item-3'}>
          <AccordionTrigger
            onClick={() => setDesktopAccordionState(prev => (prev === 'item-3' ? '' : 'item-3'))}
          >
            <div>
              <img alt={''} src={'/images/interface/Wallet.svg'} />
              <span>История платежей</span>
            </div>
          </AccordionTrigger>
          <Accordion.Content className={'AccordionContent'}>
            <PersonalDataTransactions />
          </Accordion.Content>
        </Accordion.Item>

        <Accordion.Item className={'AccordionItem'} value={'item-4'}>
          <AccordionTrigger
            onClick={() => setDesktopAccordionState(prev => (prev === 'item-4' ? '' : 'item-4'))}
          >
            <div>
              <img alt={''} src={'/images/interface/Wallet.svg'} />
              <span>История операций</span>
            </div>
          </AccordionTrigger>
          <Accordion.Content className={'AccordionContent'}>
            <PersonalDataOperations />
          </Accordion.Content>
        </Accordion.Item>

        <Accordion.Item className={'AccordionItem'} value={'item-5'}>
          <AccordionTrigger
            onClick={() => setDesktopAccordionState(prev => (prev === 'item-5' ? '' : 'item-5'))}
          >
            <div>
              <img alt={''} src={'/images/buttons/checkmark-double.svg'} />
              <span>Документы</span>
            </div>
          </AccordionTrigger>
          <Accordion.Content className={'AccordionContent'}>
            {userInfo.legalStatus === 'legalEntity' || userInfo.legalStatus === 'soleProprietor' ? (
              <PersonalDataBusinessDocs />
            ) : (
              <PersonalDataPassport />
            )}
          </Accordion.Content>
        </Accordion.Item>

        <Accordion.Item className={'AccordionItem'} value={'item-6'}>
          <AccordionTrigger
            onClick={() => setDesktopAccordionState(prev => (prev === 'item-6' ? '' : 'item-6'))}
          >
            <div>
              <img alt={''} src={'/images/buttons/geo-tag.svg'} />
              <span>Адреса</span>
            </div>
          </AccordionTrigger>
          <Accordion.Content className={'AccordionContent'}>
            <PersonalDataAddress />
          </Accordion.Content>
        </Accordion.Item>
        <div
          className={'AccordionTrigger'}
          onClick={store.logout}
          style={{ cursor: 'pointer', justifyContent: 'start', zIndex: -1 }}
        >
          <div style={{ marginTop: '36px' }}>
            <img alt={''} src={'/images/buttons/Logout.svg'} />
            <span style={{ color: colors.red }}>Выйти из аккаунта</span>
          </div>
        </div>
      </Accordion.Root>
    </AccordionWrapper>
  )
}
