import { useEffect, useMemo, useState } from 'react'

import { useGetPassportsListQuery } from '../entities/documents/queries'
import { VerificationStatus } from '../shared/api/generated'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { TextsField } from '../shared/ui-kit/actual/TextsField'
import { AdminPassportsTable } from '../shared/ui-kit/actual/components/AdminPassportsTable'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  BasePage,
  FlexColumn,
  FlexRowBetween,
  FlexRowGap,
  SectionsPageWrapper,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H1 } from '../shared/ui-kit/titles'

const statusesTranslate = [
  { id: 'verificationInProgress', name: 'в процессе' },
  { id: 'verified', name: 'верифицирован' },
  { id: 'verificationRejected', name: 'отклонен' },
  { id: 'verificationError', name: 'ошибка' },
  { id: 'undefined', name: 'не известно' },
]

export default function AdminPassportsListPage() {
  const [statuses, setStatuses] = useState<string[]>(['verificationInProgress'])

  useEffect(() => {
    setPage(0)
  }, [statuses])
  const resetTerms = () => {
    setStatuses([])
    setPage(0)
  }
  const [itemsPerPage, setItemsPerPage] = useState(24)
  const [page, setPage] = useState(0)
  const { data: passportsData, isLoading } = useGetPassportsListQuery({
    skip: page * itemsPerPage,
    take: itemsPerPage,
    verificationStatuses: statuses as VerificationStatus[],
  })
  const totalPages = useMemo(
    () => (passportsData ? Math.ceil(passportsData?.totalCount / itemsPerPage) : 1),
    [itemsPerPage, passportsData]
  )

  return (
    <BasePage>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <ArrowBackButton />
        <H1 style={{ width: '100%' }}>Просмотр списка жалоб</H1>
      </FlexRowBetween>
      <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15 }}>
        <p style={{ fontSize: 15, margin: 0, padding: 0 }}>
          Всего жалоб c примененным фильтром : {passportsData?.totalCount}
        </p>
        {/* <Filter onClick={() => setOpenUserSortModal(true)}>
              <img alt={''} src={'/images/buttons/filter2.png'} />
            </Filter> */}
        <StyledButton onClick={resetTerms} size={'sm'}>
          Сбросить фильтр
        </StyledButton>
      </FlexRowBetween>
      <br />
      <FlexRowGap style={{ alignSelf: 'flex-start', margin: '0 0 24px 24px' }}>
        <label htmlFor={'search'}>Фильтр по статусу</label>
        <TextsField
          selectedTexts={statuses}
          setSelectedTexts={setStatuses}
          texts={statusesTranslate}
        />
      </FlexRowGap>
      <SectionsPageWrapper>
        {!isLoading ? (
          <>
            <AdminPassportsTable passports={passportsData?.items || []} />
            {passportsData && (
              <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
            )}
          </>
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
    </BasePage>
  )
}
