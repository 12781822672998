const fetchGeoCode = async (address: string) => {
  const response = await fetch(
    `https://geocode-maps.yandex.ru/1.x/?apikey=1e6b7289-8a3d-4c9f-b923-40b79bb35e27&geocode=${encodeURIComponent(address)}&format=json`
  )

  if (!response.ok) {
    throw new Error('Ошибка HTTP: ' + response.status)
  }

  const data = await response.json()
  const point = data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos
    .split(' ')
    .join(' ')

  return [data, point]
}

export default fetchGeoCode
