import React, { ComponentPropsWithoutRef, useId } from 'react'

import { selector } from 'postcss-selector-parser'
import styled from 'styled-components/macro'

import { FlexRow, FlexRowBetween } from '../projectComponents'

const Block = styled(FlexRow)`
  height: 50px;
  align-items: center;

  label {
    color: #000;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`

interface RadioInputBaseProps {
  defaultValue?: any
  labelString?: string
}

export const RadioInputNew = ({
  defaultValue,
  labelString,
  ...inputProps
}: ComponentPropsWithoutRef<'input'> & RadioInputBaseProps) => {
  const id = useId()
  const getRadioImg = () => {
    const url = `/images/buttons/${inputProps.checked ? 'active-radio.svg' : 'disabled-radio.svg'}`

    return url
  }

  return (
    <Block>
      <label htmlFor={id}>
        {labelString}
        <div style={{ cursor: 'pointer', height: 20, width: 20 }}>
          <input
            defaultValue={defaultValue}
            id={id}
            style={{ opacity: 0, position: 'absolute' }}
            type={'radio'}
          />
          <img alt={'selector'} height={20} src={getRadioImg()} style={{}} width={20} />
        </div>
      </label>
    </Block>
  )
}
