import React, { useEffect } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import { useDeleteUserMutation } from '../../../entities/admin/queries'
import { useGetUserProfileQuery } from '../../../entities/userProfile/queries'
import { MiniModal } from '../../../shared/ui-kit/actual/modals/MiniModal'
import { FlexColumn, FlexRowBetween, StyledButton } from '../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../shared/ui-kit/titles'
type Props = {
  deleteOpen: boolean
  navigate?: NavigateFunction
  setDeleteOpen: (value: React.SetStateAction<boolean>) => void
  userId: string
}
export const DeleteUserModal = ({ deleteOpen, navigate, setDeleteOpen, userId }: Props) => {
  const {
    data: userProfile,
    isLoading: isProfileLoading,
    refetch: refetchProfile,
  } = useGetUserProfileQuery(userId)
  const { isSuccess: deleteSuccess, mutate: deleteUserMutate } = useDeleteUserMutation({})

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteOpen(false)
      // Если открыто из профиля, то закрыть после удаления. Если из списка не передаем navigate
      navigate && navigate(-1)
    }
  }, [deleteSuccess, navigate, setDeleteOpen])

  return (
    <MiniModal setViewModal={() => setDeleteOpen(false)} viewModal={deleteOpen}>
      <FlexColumn>
        <br />
        <H2>Вы уверены, что хотите удалить {userProfile?.accountName}?</H2>
        <br />
        <FlexRowBetween>
          <StyledButton onClick={() => setDeleteOpen(false)} variant={'secondary'}>
            НЕТ
          </StyledButton>
          <StyledButton onClick={() => deleteUserMutate({ userId })} variant={'warning'}>
            ДА
          </StyledButton>
        </FlexRowBetween>
        <br />
      </FlexColumn>
    </MiniModal>
  )
}
