import { useState } from 'react'
import { toast } from 'react-custom-alert'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'

import { useDocumentsAddPassportMutation } from '../../../entities/documents/queries'
import {
  AddUserAddressCommand,
  PostApiDocumentsAddPassportBody,
  PostApiProductsCreateProductBody,
} from '../../../shared/api/generated'
import { queryClient } from '../../../shared/api/query-client'
import { filesCompression } from '../../../shared/utils/filesCompression'
import { useProfileInfo } from '../../profile/model/use-profile-info'

export function useAddPassportForm() {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<PostApiDocumentsAddPassportBody>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const { userId } = useProfileInfo()
  const [imageFiles, setImageFiles] = useState<File[]>([null, null, null, null, null, null])

  const {
    isError: isAddPassportError,
    isLoading: isAddPassportLoading,
    isSuccess: isAddPassportSuccess,
    mutate: addPassportMutate,
  } = useDocumentsAddPassportMutation()

  const [isCompressing, setIsCompressing] = useState(false)

  const addPassport = async (data: PostApiDocumentsAddPassportBody) => {
    data.UserId = userId
    setIsCompressing(true)
    if (imageFiles) {
      data.OtherImages = []
      await Promise.all(
        imageFiles.map(async (image, idx) => {
          if (image && image instanceof File) {
            try {
              const { compressedFile } = await filesCompression(image)

              if (idx === 0 && compressedFile && compressedFile instanceof File) {
                data.MainImage = compressedFile
              } else if (compressedFile && compressedFile instanceof File) {
                data.OtherImages[idx] = compressedFile
              }
            } catch {
              return
            }
          }
        })
      )
      setIsCompressing(false)
    }
    addPassportMutate(data)
  }

  return {
    clearErrors,
    errors,
    getValues,
    handleSubmit: handleSubmit(addPassport),
    imageFiles,
    isAddPassportError,
    isAddPassportSuccess,
    isLoading: isCompressing || isAddPassportLoading,
    isValid,
    register,
    reset,
    setImageFiles,
    setValue,
  }
}
