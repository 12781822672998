import { ReactNode } from 'react'

import styled from 'styled-components/macro'

import { BubbleButtonContainer } from '../../../shared/ui-kit/actual/BubbleButtonContainer'
import { StyledButton } from '../../../shared/ui-kit/projectComponents'

const BubbleButton = styled(StyledButton)`
  width: 59px;
  height: 59px;
  font-size: 32px;
  font-weight: 300;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`

export const MessengerBubbleButton = ({
  content,
  onClick,
}: {
  content: ReactNode
  onClick?: () => void
}) => {
  return (
    <BubbleButtonContainer>
      <BubbleButton onClick={onClick} isIcoBtn>
        {content}
      </BubbleButton>
    </BubbleButtonContainer>
  )
}
