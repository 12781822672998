import React, { useEffect, useState } from 'react'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'

import {
  useGetRolesListMutation,
  useUpdateUserRolesMutation,
} from '../../../entities/admin/queries'
import { useUsersGetUserInfoQuery } from '../../../entities/ownProfile/queries'
import { CheckBox } from '../../../shared/ui-kit/actual/checkbox'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { BaseModal } from '../../../shared/ui-kit/actual/modals/BaseModal'
import {
  FlexColumnStart,
  FlexRowBetween,
  FlexRowStart,
  MediumTextL,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../shared/ui-kit/titles'

type Props = {
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<unknown, unknown>>
  rolesOpen: boolean
  setRolesOpen: (value: React.SetStateAction<boolean>) => void
  userId: string
}
export const SetRolesModal = ({ refetch, rolesOpen, setRolesOpen, userId }: Props) => {
  const { data: userInfo, isLoading: isInfoLoading } = useUsersGetUserInfoQuery(userId)
  const { data: rolesInfo, isLoading: isRolesLoading, mutate } = useGetRolesListMutation()

  useEffect(() => {
    mutate({})
  }, [mutate])
  const {
    isLoading: isRolesUpdateLoading,
    isSuccess,
    mutate: updateRolesMutate,
  } = useUpdateUserRolesMutation({})
  const handleUpdateRoles = () => {
    updateRolesMutate({ rolesIds: userRolesIds, userId })
  }

  useEffect(() => {
    if (isSuccess) {
      setRolesOpen(false)
      refetch()
    }
  }, [isSuccess, refetch, setRolesOpen])

  const [userRolesIds, setUserRolesIds] = useState<string[]>()

  useEffect(() => {
    userInfo && setUserRolesIds(userInfo?.roles.map(role => role.id))
  }, [userInfo])

  const handleUserRoles = (id: string) => {
    userRolesIds.includes(id)
      ? setUserRolesIds(prev => [...prev.filter(roleId => roleId !== id)])
      : setUserRolesIds(prev => [...prev, id])
  }

  return (
    <BaseModal setViewModal={() => setRolesOpen(false)} viewModal={rolesOpen}>
      <AbsoluteLoader isLoading={isRolesUpdateLoading || isRolesLoading || isInfoLoading} />

      <H2>Выберете роли пользователя</H2>
      <FlexColumnStart style={{ flexWrap: 'wrap', maxHeight: '230px' }}>
        {rolesInfo?.roles?.map(role => (
          <FlexRowStart key={role.id}>
            {userRolesIds && (
              <CheckBox
                checked={userRolesIds.includes(role.id)}
                setChecked={() => handleUserRoles(role.id)}
              />
            )}
            {'  '}-{'  '}
            <MediumTextL>{role.type}</MediumTextL>
          </FlexRowStart>
        ))}
      </FlexColumnStart>
      <FlexRowBetween style={{ gap: '12px' }}>
        <StyledButton onClick={() => setRolesOpen(false)} variant={'outline'}>
          Отмена
        </StyledButton>
        <StyledButton onClick={handleUpdateRoles} variant={'primary'} fullwidth>
          Подтвердить изменение ролей
        </StyledButton>
      </FlexRowBetween>
    </BaseModal>
  )
}
