import { useForm } from 'react-hook-form'

import { useUsersUpdateUserInfoMutation } from '../../../entities/ownProfile/queries'
import { SignUpCommand, UpdateUserInfoCommand } from '../../../shared/api/generated'

export function useUpdateProfile() {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    setError,
    setFocus,
    setValue,
    watch,
  } = useForm<UpdateUserInfoCommand>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })

  const signUpMutation = useUsersUpdateUserInfoMutation()

  const registration = async (data: SignUpCommand) => {
    signUpMutation.mutate(data)
  }

  return {
    clearErrors,
    errors,
    getValues,
    handleSubmit: handleSubmit(registration),
    isValid,
    register,
    setError,
    setFocus,
    setValue,
    signUpMutation,
    watch,
  }
}
