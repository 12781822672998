import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { usePathnames } from '../../../hooks/usePathnames'
import FilterStorage from '../../../storage/FilterStorage/FilterStorage'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { breakpoints, tabletDesktopView } from '../../breakPoints'
import { MainSearchComponent } from '../../deprecated/MainSearchComponent'
import {
  DesktopView,
  FlexRow,
  ImgButton,
  MobileTabletView,
  StyledButton,
  StyledLink,
  StyledLinkHeader,
} from '../../projectComponents'
import { LogoAllSharing } from '../LogoAllsharing'
import { RightModal } from '../modals/RightModal'
import { MainMenuWindow } from '../modalsContent/MainMenuWindow'
import SortModalWindow from '../modalsContent/SortModalWindow'
import { AvatarWhenAuth } from './AvatarWhenAuth'
type Props = {
  isFixed?: boolean
  isMobile?: boolean
}
const Header = styled.div<Props>`
  /* position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')}; */
  top: 0;
  z-index: 2000;
  width: clamp(${breakpoints.mobileMin}, 100%, ${breakpoints.desktopMax});
  display: flex;
  gap: 3vw;
  align-items: center;
  justify-content: space-between;
  padding: 10px 36px;
  border-radius: 0 0 24px 24px;
  box-shadow: 0px 7.800000190734863px 13.606666564941406px 0px #5e5e5e12;
  background-color: ${colors.white};
  @media (max-width: ${breakpoints.mobileMax}) {
    width: calc(100% + 24px);
    padding: 5px 12px;
    gap: 5px;
  }
  ${({ isMobile }) => !isMobile && tabletDesktopView}
`

const SearchWrapper = styled(FlexRow)`
  justify-content: center;
  width: clamp(270px, 60vw, 500px);
  & > div {
    flex-grow: 1;
  }
`
const Cart = styled(Link)`
  img {
    padding-top: 6px;
    width: 24px;
  }
`
const HeaderComponent = ({ isMobile }: Props) => {
  const { isAuth, user } = useContext(UserStorage)

  const { orderType, setOrderBy } = useContext(FilterStorage)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFixed, setIsFixed] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const { lastPathname } = usePathnames()

  const handleFilter = () => {
    setOpenMenu(prev => !prev)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const isAdmin = user?.UserRole === 'Admin'
  const navigate = useNavigate()

  return (
    <>
      <Header isFixed={isFixed} isMobile={isMobile}>
        <FlexRow style={{ gap: '8px' }}>
          {isAuth ? (
            <AvatarWhenAuth />
          ) : (
            <FlexRow>
              <LogoAllSharing />
            </FlexRow>
          )}
          <DesktopView>
            <StyledButton
              onClick={() => {
                navigate(isAuth ? '/add' : '/register')
              }}
              variant={'outline'}
              fullwidth
            >
              Новый товар
            </StyledButton>
          </DesktopView>
          <MobileTabletView>
            <StyledButton
              onClick={() => {
                navigate(isAuth ? '/add' : '/register')
              }}
              size={'small'}
              variant={'outline'}
              isIcoBtn
              mini
            >
              <span style={{ fontSize: '32px' }}>+</span>
            </StyledButton>
          </MobileTabletView>
        </FlexRow>

        <SearchWrapper>
          <FlexRow>
            {isAuth && <LogoAllSharing />}

            <MainSearchComponent onClickFilter={handleFilter} setIsFixed={setIsFixed} noLogo />
          </FlexRow>
          <SortModalWindow
            handleMenu={handleFilter}
            openMenu={openMenu}
            selectedSort={orderType}
            setSelectedSort={setOrderBy}
          />
        </SearchWrapper>
        <FlexRow>
          {isAuth ? (
            <>
              <DesktopView style={{ marginRight: '30px' }}>
                {isAdmin ? (
                  <StyledLink to={'/admin'}>Админ</StyledLink>
                ) : (
                  <StyledLink to={'/orders'}>Корзина</StyledLink>
                )}
              </DesktopView>
              <MobileTabletView style={{ marginRight: '10px' }}>
                {isAdmin ? (
                  <StyledLink to={'/admin'}>Админ</StyledLink>
                ) : (
                  <Cart to={'/orders'}>
                    <img alt={''} src={'/images/buttons/cart.svg'} />
                  </Cart>
                )}
              </MobileTabletView>
              <ImgButton
                alt={''}
                onClick={() => setIsModalOpen(prev => !prev)}
                src={'/images/buttons/menu-button.svg'}
              />
            </>
          ) : (
            <>
              <DesktopView>
                <StyledLinkHeader to={lastPathname === 'login' ? '/register' : `/login`}>
                  {lastPathname === 'login' ? 'Регистрация' : `Войти`}
                </StyledLinkHeader>
              </DesktopView>
              <MobileTabletView>
                <Cart to={'/login'}>
                  <img alt={''} src={'/images/buttons/login.png'} style={{ width: '36px' }} />
                </Cart>
              </MobileTabletView>
            </>
          )}
        </FlexRow>
      </Header>
      <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
        <MainMenuWindow handleModalClose={handleModalClose} />
      </RightModal>
    </>
  )
}

export const MainHeader = observer(HeaderComponent)
