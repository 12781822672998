import React from 'react'

import { IncomingPhotos } from '../../../../shared/ui-kit/actual/IncomingPhotos'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumn, MediumText, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H3, H4 } from '../../../../shared/ui-kit/titles'
import { AddPhotos } from '../AddPhotos'
import { StepWrapper } from '../StepWrapper'

type Props = {
  arePhotosSelected: boolean
  beforeRentSellerImagesUrls: string[]
  isLoading: boolean
  setArePhotosSelected: React.Dispatch<React.SetStateAction<boolean>>
  setImageFiles: React.Dispatch<React.SetStateAction<File[]>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const Step3 = ({
  arePhotosSelected,
  beforeRentSellerImagesUrls,
  isLoading,
  setArePhotosSelected,
  setImageFiles,
  setStep,
}: Props) => {
  return (
    <StepWrapper>
      <AbsoluteLoader isLoading={isLoading} />

      <FlexColumn>
        <H3>Проверьте товар</H3>
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Сделайте фотографии по инструкции, даже если не видите дефектов. В противном случае
          платформа вправе считать, что они были причинены по вашей вине
        </MediumText>
        <H4>Фото от арендодателя</H4>
        <IncomingPhotos data={beforeRentSellerImagesUrls} />
        <H4>Ваши фото</H4>

        <AddPhotos
          arePhotosSelected={arePhotosSelected}
          secondPhotoButtonNote={'Изменить фотографии'}
          setArePhotosSelected={setArePhotosSelected}
          setImageFiles={setImageFiles}
        />
      </FlexColumn>

      <FlexColumn style={{ gap: '8px' }}>
        <StyledButton onClick={() => setStep(12)} variant={'secondary'} fullwidth>
          Отказаться от товара
        </StyledButton>
        <StyledButton
          disabled={!arePhotosSelected}
          onClick={() => setStep(prev => prev + 1)}
          fullwidth
        >
          Далее
        </StyledButton>
      </FlexColumn>
      <br />
    </StepWrapper>
  )
}
