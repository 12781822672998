import { useParams } from 'react-router-dom'

import SectionTemplate from './model/SectionTemplate'

const SectionChildPage = () => {
  const { parentCategoryId } = useParams()

  return <SectionTemplate key={parentCategoryId} isChildPage />
}

export default SectionChildPage
