import { memo } from 'react'

import styled from 'styled-components/macro'

const P = styled.p`
  margin-top: unset;
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  }
`

export const LineBreaks = memo(({ children }: { children: string }) => {
  if (children.includes('\r\n')) {
    return (
      <>
        {children
          .split('\r\n')
          .map((p, idx) => (p ? <P key={idx}>{p}</P> : <P key={idx}>&nbsp;</P>))}
      </>
    )
  }
  if (children.includes('\n')) {
    return (
      <>
        {children.split('\n').map((p, idx) => (p ? <P key={idx}>{p}</P> : <P key={idx}>&nbsp;</P>))}
      </>
    )
  } else {
    return <P>{children}</P>
  }
})
