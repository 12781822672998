import React, { useContext } from 'react'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { ProductsListOrderType } from '../../../api/generated'
import { sortingSelectors } from '../../../constants/sortingSelectors'
import FilterStorage from '../../../storage/FilterStorage/FilterStorage'
import { BackArrowWithTitleButton } from '../../actual/BackArrowWithTitleButton'
import { RadioInput } from '../../actual/RadioInput'
import { FlexColumnStart } from '../../projectComponents'
import { BaseModal } from '../modals/BaseModal'

const Title = styled.p`
  font-weight: 700;
  font-size: 21px;
  margin: 0;
  margin-bottom: 16px;
`

interface SortModalWindowProps {
  handleMenu: () => void
  openMenu: boolean
  selectedSort: string
  setSelectedSort?: (it: any) => void
}

const SortModalWindow: React.FC<SortModalWindowProps> = ({
  handleMenu,
  openMenu,
  selectedSort,
}) => {
  const { setOrderBy } = useContext(FilterStorage)
  const handleSetOrder = (value: ProductsListOrderType) => {
    setOrderBy(value)
  }

  return (
    <BaseModal setViewModal={handleMenu} viewModal={openMenu}>
      <BackArrowWithTitleButton bottomSpace={'sm'} onClick={handleMenu} title={'Сортировка'} />
      <FlexColumnStart style={{ width: '100%' }}>
        <Title>Сортировать</Title>
        <FlexColumnStart style={{ width: '100%' }}>
          {sortingSelectors.map((selector, idx) => (
            <RadioInput
              handleSetOrder={() => handleSetOrder(selector.value)}
              key={idx}
              selectedSort={selectedSort}
              selector={selector}
            />
          ))}
        </FlexColumnStart>
      </FlexColumnStart>
    </BaseModal>
  )
}

export default observer(SortModalWindow)
