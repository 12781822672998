import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import {
  useProductsArchiveProductMutation,
  useProductsRestoreProductMutation,
} from '../../../../entities/marketItem/queries'
import { useGetReviewsListQuery } from '../../../../entities/reviews/queries'
import {
  GetArchivedProductInfoResponse,
  GetProductInfoResponse,
  GetReviewsListResponse,
  ReviewType,
} from '../../../../shared/api/generated'
import { queryClient } from '../../../../shared/api/query-client'
import { colors } from '../../../../shared/constants/colors'
import { useShare } from '../../../../shared/hooks/use-share'
import { useQueryModal } from '../../../../shared/hooks/useQueryModal'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { ArrowBackButton } from '../../../../shared/ui-kit/actual/ArrowBackButton'
import { BlockTitle } from '../../../../shared/ui-kit/actual/InfoButton'
import { Paragraph } from '../../../../shared/ui-kit/actual/Paragraph'
import { ProfileHeader } from '../../../../shared/ui-kit/actual/ProfileHeader'
import { SliderComponent } from '../../../../shared/ui-kit/actual/Slider'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { ExistedPrice } from '../../../../shared/ui-kit/actual/components/ExistedPrice'
import { LineBreaks } from '../../../../shared/ui-kit/actual/components/LineBreaks'
import { LocationInfo } from '../../../../shared/ui-kit/actual/components/LocationInfo'
import { ButtonColumn } from '../../../../shared/ui-kit/actual/components/buttonColumn'
import { MainHeader } from '../../../../shared/ui-kit/actual/headers/MainHeader'
import { MiniModal } from '../../../../shared/ui-kit/actual/modals/MiniModal'
import { breakpoints, devices } from '../../../../shared/ui-kit/breakPoints'
import {
  DesktopView,
  FlexColumn,
  FlexColumnBetween,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  MediumTextL,
  MobileTabletView,
  MobileView,
  NeutralLink,
  StyledButton,
  StyledLink,
} from '../../../../shared/ui-kit/projectComponents'
import { H1, H4 } from '../../../../shared/ui-kit/titles'
import displayStringByAmount, {
  displayRateByAmount,
} from '../../../../shared/utils/displayStringByAmount'
import { getProductAvgRating } from '../../../../shared/utils/getAvgRating'
import { ReviewsList } from '../list/ReviewsList'
import Order from '../order'

const Page = styled(FlexColumn)`
  width: clamp(${breakpoints.mobileMin}, 100%, ${breakpoints.desktopMiddle});
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 24px;
`

const Wrapper = styled(FlexColumn)`
  width: clamp(${breakpoints.mobileMin}, 100%, ${breakpoints.desktopMin});

  flex-direction: column;
  box-sizing: border-box;
  padding: 0px;
  padding-bottom: 36px;
  @media (${devices.desktopMiddle}) {
  }
`

const SubTitle = styled.h6`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #008c8c;
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
`

const TableWrapper = styled.div`
  width: -webkit-fill-available;
  padding: 18px 20px;
  background-color: #f8f8fa;
  border-radius: 10px;
`

const BtnWrapper = styled(FlexColumn)`
  width: 100%;
  margin: 24px 0 8px;
  gap: 12px;
`
const BottomButton = styled(StyledButton)`
  margin-bottom: 96px;
  margin-top: 12px;
  @media screen and (${devices.desktopMin}) {
    margin-bottom: 24px;
  }
`

interface ItemComponentProps {
  avgSellerRating: number
  isArchive?: boolean
  productInfo: GetArchivedProductInfoResponse | GetProductInfoResponse
  productReviews: GetReviewsListResponse
  setOpenOwnerRating: any
  setOpenRating: any
}

export const ItemComponent = ({
  avgSellerRating,
  isArchive,
  productInfo,
  productReviews,
  setOpenOwnerRating,
  setOpenRating,
}: ItemComponentProps) => {
  const { userId } = useContext(UserStorage)
  const { setViewModal, viewModal } = useQueryModal('orderOpen')
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const { share } = useShare({
    shareFn: () => null,
    text: 'Можно взять в аренду!',
    title: 'Интересный товар',
  })

  const avgRating = getProductAvgRating(productReviews)
  // TODO: добавить загрузку циклов аренды как появится на бэке
  // TODO: вернуть количество товаров на платформе и количество времени на платформе когда появится на бэке

  const {
    isLoading: isArchiveLoading,
    isSuccess: isArchiveSuccess,
    mutate: archiveProductMutate,
  } = useProductsArchiveProductMutation()

  const {
    isLoading: isRestoreLoading,
    isSuccess: isRestoreSuccess,
    mutate: restoreProductMutate,
  } = useProductsRestoreProductMutation()

  const handleArchiveProduct = () => {
    archiveProductMutate({ productId: productInfo.id })
  }
  const handleRestoreProduct = () => {
    restoreProductMutate({ productId: productInfo.id })
  }
  const navigate = useNavigate()

  useEffect(() => {
    if (isArchiveSuccess || isRestoreSuccess) {
      setOpenDeleteModal(false)
      navigate(-1)
    }
  }, [isArchiveSuccess, navigate, setViewModal, isRestoreSuccess])

  return (
    <>
      <AbsoluteLoader isLoading={isArchiveLoading || isRestoreLoading} />
      <Page style={{ overflow: viewModal ? 'hidden' : 'auto' }}>
        <DesktopView>
          <MainHeader />
          <br />
        </DesktopView>
        <Wrapper>
          <MobileTabletView>
            <ProfileHeader
              right={
                <FlexRow onClick={share} style={{ gap: '16px' }}>
                  <img alt={'share'} src={'/images/buttons/share.svg'} />
                  {/* <img alt={'like'} src={'/images/buttons/like-heart.svg'} /> */}
                </FlexRow>
              }
              center={<H1>{productInfo?.name}</H1>}
              left={<ArrowBackButton />}
            />
          </MobileTabletView>
          <SliderComponent
            items={productInfo?.imagesUrls?.map((image, index) => (
              <img
                alt={'product'}
                height={425}
                key={index}
                src={image}
                style={{ objectFit: 'contain' }}
                width={'100%'}
              />
            ))}
            slidesToShow={1}
            dots
          />

          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'sm'}>
            {productInfo?.name}
          </BlockTitle>
          <FlexRowBetween style={{ alignItems: 'center' }}>
            <Paragraph>Залог {productInfo?.pledge}₽</Paragraph>
            <SubTitle>
              <ExistedPrice productInfo={productInfo} />
            </SubTitle>
          </FlexRowBetween>

          {/* <Sizes /> */}

          {/* 
            <NeutralLink to={`/messages/:${getProductInfoQuery.id}`}>
              <StyledButton style={{ marginTop: '8px' }} variant={'secondary'} fullwidth>
                {' '}
                Написать арендодателю{' '}
              </StyledButton>
            </NeutralLink> */}
          <BtnWrapper>
            {isArchive ? (
              <FlexColumn style={{ alignItems: 'flex-start', width: '100%' }}>
                <Paragraph style={{ marginBottom: '16px' }}>
                  Причина архивации: {(productInfo as GetArchivedProductInfoResponse).comment}
                </Paragraph>

                <StyledButton onClick={handleRestoreProduct} variant={'primary'} fullwidth>
                  {' '}
                  Вернуть в общий доступ{' '}
                </StyledButton>
              </FlexColumn>
            ) : (
              <>
                {productInfo?.owner?.id == userId ? (
                  <FlexRowBetween style={{ gap: '8px' }}>
                    <StyledButton
                      onClick={() => setOpenDeleteModal(true)}
                      variant={'warning'}
                      fullwidth
                    >
                      Скрыть объявление
                    </StyledButton>
                    <StyledLink style={{ width: '100%' }} to={`/item/${productInfo.id}/edit`}>
                      Редактировать объявление
                    </StyledLink>
                  </FlexRowBetween>
                ) : (
                  <StyledButton onClick={() => setViewModal(true)} variant={'primary'} fullwidth>
                    {' '}
                    Добавить в корзину{' '}
                  </StyledButton>
                )}
              </>
            )}
            <FlexRowBetween style={{ gap: '8px' }}>
              <ButtonColumn
                bottom={
                  <>
                    {productReviews?.totalCount || 0}{' '}
                    {displayStringByAmount('отзыв', productReviews?.totalCount || 0)}
                  </>
                }
                top={
                  <>
                    {' '}
                    <img alt={''} src={'/images/interface/star.svg'} />
                    &nbsp;{avgRating.toFixed(1)}
                  </>
                }
                onClick={() => setOpenRating(true)}
              />
              <ButtonColumn bottom={<>Циклов аренды</>} top={<>{productInfo?.rentCyclesCount}</>} />
            </FlexRowBetween>
          </BtnWrapper>

          <TableWrapper>
            <Paragraph style={{ marginBottom: '16px' }}>
              В данной таблице указаны варианты аренды и цены
            </Paragraph>
            <FlexColumnBetween style={{ gap: '8px' }}>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>1 день</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {productInfo?.dayPrice} ₽
                </Paragraph>
              </FlexRowBetween>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>3 дня</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {productInfo?.threeDaysPrice} ₽
                </Paragraph>
              </FlexRowBetween>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>Неделя</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {productInfo?.weekPrice} ₽
                </Paragraph>
              </FlexRowBetween>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>Месяц</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {productInfo?.monthPrice} ₽
                </Paragraph>
              </FlexRowBetween>
            </FlexColumnBetween>
          </TableWrapper>
          <br />
          {productInfo?.defectFines?.length > 0 && (
            <TableWrapper>
              <Paragraph style={{ marginBottom: '16px' }}>
                В данной таблице указаны штрафы за дефекты
              </Paragraph>
              <>
                <FlexColumnBetween style={{ gap: '8px' }}>
                  {productInfo?.defectFines?.map((defect, idx) => (
                    <FlexRowBetween key={idx} style={{ alignItems: 'center' }}>
                      {' '}
                      <Paragraph style={{ color: colors.black, maxWidth: '85%' }}>
                        {defect.defectName}
                      </Paragraph>{' '}
                      <Paragraph
                        style={{
                          color: colors.primary,
                          fontWeight: 600,
                          whiteSpace: 'nowrap',
                          width: 'max-content',
                        }}
                      >
                        {defect.amount} ₽
                      </Paragraph>
                    </FlexRowBetween>
                  ))}
                </FlexColumnBetween>
              </>
            </TableWrapper>
          )}

          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
            О товаре
          </BlockTitle>
          <Paragraph style={{ width: '100%' }}>
            <LineBreaks>{productInfo?.description}</LineBreaks>
          </Paragraph>
          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
            Доставка
          </BlockTitle>
          <div style={{ alignSelf: 'flex-start' }}>
            {productInfo?.deliveryType === 'countryWithSelection' && (
              <FlexColumnStart>
                <Paragraph>В городах: </Paragraph>
                <MediumTextL>{productInfo.deliveryCities.join(', ')}</MediumTextL>
              </FlexColumnStart>
            )}
            {productInfo?.deliveryType === 'russiaAny' && (
              <FlexRowBetween>
                <Paragraph>В пределах РФ</Paragraph>
              </FlexRowBetween>
            )}
            {productInfo?.deliveryType === 'notAvailable' && (
              <FlexRowBetween>
                <Paragraph>Варианты доставки не указаны арендодателем</Paragraph>
              </FlexRowBetween>
            )}
          </div>
          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
            Самовывоз
          </BlockTitle>
          {productInfo?.pickupAddress ? (
            <LocationInfo pickupAddress={productInfo.pickupAddress} />
          ) : (
            <Paragraph style={{ alignSelf: 'flex-start' }}>
              Адрес самовывоза не указан арендодателем
            </Paragraph>
          )}
          <NeutralLink to={`/users/${productInfo?.owner?.id}`}>
            <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
              {productInfo?.owner?.fullName}
            </BlockTitle>
            <Box style={{ gap: '4px', margin: '8px 0 16px' }}>
              <img alt={'star'} src={'/images/interface/star.svg'} />
              <Paragraph>
                <span style={{ color: '#000' }}>{avgSellerRating.toFixed(1)} </span>·{' '}
                {/* {productReviews?.totalCount || 0}{' '}
              {displayRateByAmount('оцен', productReviews?.totalCount || 0)} на товары */}
              </Paragraph>
            </Box>
          </NeutralLink>
          {/*<FlexRowBetween style={{ gap: '8px' }}>*/}
          {/*  <ButtonColumn*/}
          {/*    top={*/}
          {/*      <>*/}
          {/*        {' '}*/}
          {/*        <img alt={''} src={'/images/buttons/like.svg'} />*/}
          {/*        &nbsp;300*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    bottom={<>товаров сдано в аренду</>}*/}
          {/*  />*/}
          {/*  <ButtonColumn*/}
          {/*    top={*/}
          {/*      <>*/}
          {/*        {' '}*/}
          {/*        <img alt={''} src={'/images/buttons/clock.svg'} />*/}
          {/*        &nbsp;2 года*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    bottom={<>на платформе</>}*/}
          {/*  />*/}
          {/*</FlexRowBetween>*/}

          <ReviewsList
            linkHandle={() => setOpenRating(true)}
            reviews={productReviews?.items}
            reviewsCount={productReviews?.totalCount}
            userRating={productInfo?.rating}
            header
          />

          {/* <StyledButton
            onClick={() => setOpenOwnerRating(true)}
            style={{ marginTop: '24px' }}
            variant={'primary'}
            fullwidth
          >
            {' '}
            Написать отзыв{' '}
          </StyledButton> */}
          <BottomButton onClick={() => setOpenRating(true)} variant={'secondary'} fullwidth>
            {' '}
            Все отзывы{' '}
          </BottomButton>
        </Wrapper>
        <MiniModal setViewModal={setOpenDeleteModal} viewModal={openDeleteModal} minWidth>
          <FlexColumn>
            <br />
            <H4>Вы уверены, что хотите отправить в архив {productInfo?.name}?</H4>
            <br />
            <FlexRowBetween style={{ gap: '24px' }}>
              <StyledButton
                onClick={() => setOpenDeleteModal(false)}
                variant={'secondary'}
                fullwidth
              >
                Не сейчас
              </StyledButton>
              <StyledButton onClick={handleArchiveProduct} variant={'warning'} fullwidth>
                Скрыть объявление
              </StyledButton>
            </FlexRowBetween>
            <br />
          </FlexColumn>
        </MiniModal>
        <Order openMenu={viewModal} productInfo={productInfo} setViewModal={setViewModal} />
      </Page>
    </>
  )
}
