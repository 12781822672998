import { observer } from 'mobx-react-lite'

import { PhoneEmailRegister } from '../features/register/ui/PhoneEmailRegister'
import { RegFooter } from '../features/register/ui/footer/RegFooter'
import { AuthLayout } from '../shared/ui-kit/actual/layouts/AuthLayout'

const RegistrationPage = () => {
  return <AuthLayout footer={<RegFooter />} form={<PhoneEmailRegister />} />
}

export default observer(RegistrationPage)
