import { useContext } from 'react'

import styled from 'styled-components/macro'

import { useUsersGetUserInfoQuery } from '../../../../entities/ownProfile/queries'
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import {
  DesktopView,
  FlexRow,
  SmallText,
  SmallTextBold,
  StyledLinkImg,
} from '../../projectComponents'
const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 20%;
  object-fit: cover;
`

export const AvatarWhenAuth = () => {
  const profileStore = useContext(ProfileStorage)
  const profile = profileStore.getUserInfo()
  const store = useContext(UserStorage)

  const { data } = useUsersGetUserInfoQuery(store?.userId)

  return (
    <StyledLinkImg to={'/profile'}>
      <FlexRow style={{ gap: '10px' }}>
        <Avatar
          src={
            data?.profileImageUrl ||
            profile?.profileImageUrl ||
            '/images/bottom-menu/profile-light.svg'
          }
          alt={'user avatar'}
        />
        <DesktopView>
          <SmallTextBold>{data?.fullName || profile.fullName}</SmallTextBold>
          <SmallText>@{data?.accountName || profile.accountName}</SmallText>
        </DesktopView>
      </FlexRow>
    </StyledLinkImg>
  )
}
