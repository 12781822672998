import React, { useContext, useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import FilterStorage from '../../../../storage/FilterStorage/FilterStorage'
import {
  FilterTitle,
  FlexColumn,
  FlexColumnStart,
  FlexRowBetween,
  MediumText,
} from '../../../projectComponents'
import { TextField } from '../../TextField'

const Block = styled(FlexColumn)`
  width: 100%;
`

interface SelectPriceComponentProps {
  title: string
}

export const FilterPricePanel: React.FC<SelectPriceComponentProps> = ({ title }) => {
  const { setDayPriceFrom, setDayPriceTo, sorting } = useContext(FilterStorage)
  const [priceFrom, setPriceFrom] = useState<number | string>(sorting.dayPriceFrom || '')
  const [priceTo, setPriceTo] = useState<number | string>(sorting.dayPriceTo || '')
  const [validError, setValidError] = useState('')

  useEffect(() => {
    if (priceTo && +priceFrom > +priceTo) {
      setValidError('Минимальная цена не может быть больше максимальной')
    } else {
      setValidError('')
    }
  }, [priceFrom, priceTo])

  return (
    <Block>
      <FlexRowBetween style={{ marginBottom: 12 }}>
        <FilterTitle>{title}</FilterTitle>
      </FlexRowBetween>
      <FlexRowBetween style={{ gap: '8px' }}>
        <FlexColumnStart>
          <MediumText>Минимальная цена</MediumText>
          <TextField
            inputProps={{
              min: 0,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPriceFrom(e.target.value),
              placeholder: 'От',
              type: 'number',
              value: priceFrom,
            }}
            error={validError}
            onBlur={() => setDayPriceFrom(+priceFrom)}
            size={'md'}
            fullwidth
          />
        </FlexColumnStart>
        <FlexColumnStart>
          <MediumText>Максимальная цена</MediumText>
          <TextField
            inputProps={{
              min: 0,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPriceTo(e.target.value),
              placeholder: 'До',
              type: 'number',
              value: priceTo,
            }}
            onBlur={() => setDayPriceTo(+priceTo)}
            size={'md'}
            fullwidth
          />
        </FlexColumnStart>
      </FlexRowBetween>
    </Block>
  )
}
