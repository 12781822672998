import { useContext, useEffect } from 'react'

import { useGetCategoriesListQuery } from '../../entities/categoriesList'
import { GetCategoriesListQuery } from '../api/generated'
import CategoriesStorage from '../storage/CategoriesStorage/CategoriesStorage'

export function useCategoriesLoader(params?: GetCategoriesListQuery) {
  const { disableCategoryFilter, parentCategoryId, sectionId, skip, take, term } = params

  const { categories, setCategories } = useContext(CategoriesStorage)
  const { data, isLoading } = useGetCategoriesListQuery({
    disableCategoryFilter,
    parentCategoryId,
    sectionId,
    skip,
    take,
    term,
  })

  useEffect(() => {
    if (!isLoading && data) {
      setCategories(data?.items)
    }
  }, [data, isLoading, setCategories])

  return { categories, data, isLoading }
}
