import React, { useState } from 'react'

import styled from 'styled-components/macro'

import { useUpdateReviewMutation } from '../../../entities/reviews/queries'
import { queryClient } from '../../../shared/api/query-client'
import { SetStarsRating } from '../../../shared/ui-kit/actual/SetStarsRating'
import { TextArea } from '../../../shared/ui-kit/actual/TextArea'
import { StyledButton } from '../../../shared/ui-kit/projectComponents'

const FormWrapper = styled.form`
  align-self: stretch;
  margin-bottom: 40px;
  padding: 16px;
`

type Props = {
  commentOld: string
  ratingOld: number
  reviewId: number
}
export const EditReviewForm = ({ commentOld, ratingOld, reviewId }: Props) => {
  const [rating, setRating] = useState(ratingOld)
  const [comment, setComment] = useState(commentOld)
  const createReviewMutation = useUpdateReviewMutation({
    onError: error => {},
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_reviews_list'] })
    },
  })

  return (
    <FormWrapper onClick={e => e.preventDefault()}>
      <SetStarsRating rating={rating} setRating={setRating} />
      <br />
      <TextArea
        inputProps={{
          placeholder: 'Комментарий',
          rows: 7,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
        style={{ marginBottom: '8px', width: '100%' }}
        value={comment}
        fullwidth
      />
      <br />
      <StyledButton
        onClick={() =>
          createReviewMutation.mutate({
            content: comment,
            rating: rating,
            reviewId: reviewId,
          })
        }
        disabled={!rating || createReviewMutation.isLoading || createReviewMutation.isSuccess}
        variant={'secondary'}
        fullwidth
      >
        {(createReviewMutation.isSuccess && 'Отзыв изменен') ||
          (createReviewMutation.isLoading && 'Отзыв отправляется') ||
          'Изменить отзыв'}
      </StyledButton>
    </FormWrapper>
  )
}
