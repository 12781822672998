import { useNavigate } from 'react-router-dom'

import { BlockTitle } from '../../../../shared/ui-kit/actual/InfoButton'
import { Desc, FlexColumn, StyledButton } from '../../../../shared/ui-kit/projectComponents'

export const Finish = ({ rentId }: { rentId: number }) => {
  const navigate = useNavigate()

  return (
    <>
      {/* <button onClick={() => setCurrentStage(5)}>Ошибка заказа</button> */}
      <FlexColumn style={{ flex: 1, width: '100%' }}>
        <img alt={''} height={40} src={'/images/buttons/checkbox-active.svg'} width={40} />
        <BlockTitle topSpace={'sm'}>Успешно!</BlockTitle>
        <Desc style={{ color: '#838589', textAlign: 'center' }}>
          Заказ ожидает подтверждения арендодателем
        </Desc>
      </FlexColumn>
      <StyledButton
        onClick={() => {
          navigate(`/delivery/receive/${rentId}`)
        }}
        style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
        variant={'primary'}
      >
        Ок
      </StyledButton>
    </>
  )
}
