import { Dispatch, SetStateAction } from 'react'

import styled from 'styled-components/macro'

import { RentListItem } from '../../../shared/api/generated'
import { PaginationComponent } from '../../../shared/ui-kit/actual/PaginationComponent'
import { H2 } from '../../../shared/ui-kit/titles'
import { ArchiveOrdersBuyer, ArchiveOrdersSeller } from './ArchiveOrders'
import { CurrentOrders } from './CurrentOrders'

const OrdersHead = styled(H2)`
  margin: 36px 0;
`
const Wrapper = styled.div`
  width: 100%;
`

type Props = {
  active: RentListItem[]
  activeSale: RentListItem[]
  buyerActivePage: number
  buyerFinishedPage: number
  finished: RentListItem[]
  finishedSale: RentListItem[]
  sellerActivePage: number
  sellerFinishedPage: number
  setBuyerActivePage: Dispatch<SetStateAction<number>>
  setBuyerFinishedPage: Dispatch<SetStateAction<number>>
  setSellerActivePage: Dispatch<SetStateAction<number>>
  setSellerFinishedPage: Dispatch<SetStateAction<number>>
  totalBuyerActivePages: number
  totalBuyerFinishedPages: number
  totalSellerActivePages
  totalSellerFinishedPages: number
}
export const OrdersDesktopBodyWrapper = ({
  active,
  activeSale,
  buyerActivePage,
  buyerFinishedPage,
  finished,
  finishedSale,
  sellerActivePage,
  sellerFinishedPage,
  setBuyerActivePage,
  setBuyerFinishedPage,
  setSellerActivePage,
  setSellerFinishedPage,
  totalBuyerActivePages,
  totalBuyerFinishedPages,
  totalSellerActivePages,
  totalSellerFinishedPages,
}: Props) => {
  return (
    <Wrapper>
      <OrdersHead>В аренде у Вас</OrdersHead>
      <CurrentOrders active={active} direction={'receive'} />
      {!!active.length && (
        <PaginationComponent
          maxPages={totalBuyerActivePages}
          pageId={buyerActivePage}
          setPageId={setBuyerActivePage}
        />
      )}
      {activeSale.length > 0 && (
        <>
          <OrdersHead>Вы сдали в аренду</OrdersHead>
          <CurrentOrders active={activeSale} direction={'send'} />
          <PaginationComponent
            maxPages={totalSellerActivePages}
            pageId={sellerActivePage}
            setPageId={setSellerActivePage}
          />
        </>
      )}
      <OrdersHead>Архив / Вы брали в аренду</OrdersHead>
      <ArchiveOrdersBuyer finished={finished} />
      {!!finished.length && (
        <PaginationComponent
          maxPages={totalBuyerFinishedPages}
          pageId={buyerFinishedPage}
          setPageId={setBuyerFinishedPage}
        />
      )}
      {finishedSale.length > 0 && (
        <>
          <OrdersHead>Архив / Вы сдавали в аренду</OrdersHead>
          <ArchiveOrdersSeller finished={finishedSale} />
          <PaginationComponent
            maxPages={totalSellerFinishedPages}
            pageId={sellerFinishedPage}
            setPageId={setSellerFinishedPage}
          />
        </>
      )}
    </Wrapper>
  )
}
