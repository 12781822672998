import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useGetCategoryInfo } from '../entities/categoriesList/queries'
import { useSectionsGetSectionInfoQuery } from '../entities/sectionList'
import Breadcrumbs from '../features/market/ui/sections/BreadCrumbs'
import { useCategoriesLoader } from '../shared/hooks/useCategoriesLoader'
import FilterStorage from '../shared/storage/FilterStorage/FilterStorage'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { BackArrowWithTitleLink } from '../shared/ui-kit/actual/BackArrowWithTitleLink'
import { BottomMenuComponent } from '../shared/ui-kit/actual/BottomMenuComponent'
import { BlockTitle } from '../shared/ui-kit/actual/InfoButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import CategoryButton from '../shared/ui-kit/actual/components/CategoryButton'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../shared/ui-kit/actual/layouts/BasePageLayout'
import { RightModal } from '../shared/ui-kit/actual/modals/RightModal'
import { MainMenuWindow } from '../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import SortModalWindow from '../shared/ui-kit/actual/modalsContent/SortModalWindow'
import { breakpoints, devices } from '../shared/ui-kit/breakPoints'
import { MainSearchComponent } from '../shared/ui-kit/deprecated/MainSearchComponent'
import {
  BaseWrapper,
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  ImgButton,
  MobileView,
  TabletDesktopView,
} from '../shared/ui-kit/projectComponents'
import { H1 } from '../shared/ui-kit/titles'
import { generatePagesArray } from '../shared/utils/paginationHelper'

const CategoriesWrapper = styled.div`
  min-height: 580px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 16px;
  transition: all 0.3s ease-in;
  justify-content: flex-start;
  align-self: flex-start;
  @media all and (width <= ${breakpoints.tabletMax}) {
    justify-content: center;
    align-self: center;
  }
`

const PaginationWrapper = styled.div`
  margin-top: 32px;

  @media all and (max-width: 800px) {
    margin-bottom: 35px;
  }
`

interface SectionTemplateProps {
  isChildPage?: boolean
}

const AdminCategoriesPage = ({ isChildPage = false }: SectionTemplateProps) => {
  const { parentCategoryId, sectionId } = useParams()

  const sectionsId = parentCategoryId || sectionId
  const sectionIdNumber = sectionsId ? Number(sectionsId) : undefined

  const filterStore = useContext(FilterStorage)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const [openMenu, setOpenMenu] = useState(false)
  const [selectedSort, setSelectedSort] = useState(filterStore.sorting)

  const handleFilter = useCallback(() => {
    setOpenMenu(prev => !prev)
  }, [setOpenMenu])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleFilterModalOpen = useCallback(() => {
    setIsModalOpen(prev => !prev)
  }, [setIsModalOpen])

  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(24)
  const { data, isLoading } = useCategoriesLoader({
    parentCategoryId: parentCategoryId ? sectionIdNumber : undefined,
    sectionId: parentCategoryId ? undefined : sectionIdNumber,
    skip: page * itemsPerPage,
    take: itemsPerPage,
  })

  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )
  const isEmpty = useMemo(() => !isLoading && (!data || data.items.length === 0), [isLoading, data])

  const filteredCategories = data?.items

  useEffect(() => {
    const params = new URLSearchParams()

    params.set('page', page.toString())
    navigate(`?${params.toString()}`, { replace: true })
  }, [page, navigate])

  const sectionData = useSectionsGetSectionInfoQuery({ id: Number(sectionId) })
  const { data: parentCategoryInfo, mutate } = useGetCategoryInfo({ id: Number(parentCategoryId) })

  useEffect(() => {
    if (isChildPage) {
      mutate({})
    }
  }, [isChildPage, mutate])

  return (
    <>
      <BasePageLayout>
        <BaseWrapper>
          <FlexColumn style={{ width: '100%' }}>
            <FlexRowBetween style={{ alignItems: 'center' }}>
              <ArrowBackButton />
              <FlexRow style={{ flexGrow: 1 }}>
                <H1>{parentCategoryInfo?.name || sectionData?.data?.name}</H1>
              </FlexRow>
            </FlexRowBetween>

            <SortModalWindow
              handleMenu={handleFilter}
              openMenu={openMenu}
              selectedSort={selectedSort.orderType}
              setSelectedSort={setSelectedSort}
            />
            <BlockTitle bottomSpace={'sm'} style={{ width: '100%' }} topSpace={'zero'}>
              Категории
            </BlockTitle>
            <CategoriesWrapper>
              {data?.items?.length > 0 &&
                filteredCategories?.map(category => (
                  <CategoryButton
                    hasChild={isChildPage || category.hasChildCategories}
                    id={category.categoryId}
                    image={category.imageUrl}
                    key={category?.categoryId}
                    name={category.categoryName}
                    sectionId={category.sectionId}
                  />
                ))}
            </CategoriesWrapper>
            {isEmpty && <p>В этом разделе пусто, попробуйте другой</p>}
            <PaginationWrapper>
              <PaginationComponent
                maxPages={totalPages}
                pageId={page}
                pagesNum={generatePagesArray(totalPages, page, 100)}
                setPageId={setPage}
              />
            </PaginationWrapper>
          </FlexColumn>
          <BottomMenuComponent />
        </BaseWrapper>
      </BasePageLayout>
      <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
        <MainMenuWindow handleModalClose={handleFilterModalOpen} />
      </RightModal>
      {isLoading && <AbsoluteLoader isLoading={isLoading} />}
    </>
  )
}

export default AdminCategoriesPage
