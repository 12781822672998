import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useClipboard } from 'use-clipboard-copy'
import { useHookFormMask } from 'use-mask-input'

import { useGetBankAccountTopUpRequisitesQuery } from '../../../../entities/payments/queries'
import { copyTextSVG } from '../../../constants/images'
import { FlexColumn, FlexColumnStart, FlexRowGap, MediumText } from '../../projectComponents'
import { H4 } from '../../titles'
import { TextArea } from '../TextArea'
import { TextField } from '../TextField'
import { AbsoluteLoader } from './AbsoluteLoader'

export const BankRequisites = () => {
  const { data, isLoading } = useGetBankAccountTopUpRequisitesQuery()
  const companyName = useClipboard()
  const address = useClipboard()
  const kpp = useClipboard()
  const inn = useClipboard()
  const paymentAccount = useClipboard()
  const correspondentBank = useClipboard()
  const bankName = useClipboard()
  const bic = useClipboard()

  // useEffect(() => {
  //   if (data) {
  //     kpp.target.value = data.kpp
  //   }
  // }, [data])

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <FlexColumnStart>
        <br />
        <H4>Реквизиты для пополнения</H4>
        <br />
        <FlexRowGap>
          <TextArea
            inputProps={{
              defaultValue: data?.companyName,
              disabled: true,
              name: 'companyName',
              placeholder: `Название компании`,
            }}
            fullwidth
          />
          <input
            defaultValue={data?.companyName}
            ref={companyName.target}
            style={{ display: 'none' }}
          />
          <img alt={''} onClick={() => companyName.copy()} src={copyTextSVG} />
        </FlexRowGap>
        <br />
        <FlexRowGap>
          <TextArea
            inputProps={{
              defaultValue: data?.address,
              disabled: true,
              name: 'address',
              placeholder: `Адрес`,
              rows: 3,
            }}
            fullwidth
          />
          <input defaultValue={data?.address} ref={address.target} style={{ display: 'none' }} />
          <img alt={''} onClick={() => address.copy()} src={copyTextSVG} />
        </FlexRowGap>
        <br />
        {/* <TextArea
          inputProps={{ rows: 2 }}
          style={{ width: '100%' }}
          value={data?.address}
          fullwidth
        />
        <br /> */}
        <MediumText>КПП</MediumText>
        <FlexRowGap>
          <TextField
            inputProps={{
              defaultValue: data?.kpp,
              disabled: true,
              maxLength: 12,
              name: 'kpp',
              placeholder: `КПП`,
              type: 'string',
            }}
            label={`КПП`}
            size={'xl'}
            fullwidth
          />{' '}
          <input defaultValue={data?.kpp} ref={kpp.target} style={{ display: 'none' }} />
          <img alt={''} onClick={() => kpp.copy()} src={copyTextSVG} />
        </FlexRowGap>
        <br />
        <MediumText>ИНН</MediumText>
        <FlexRowGap>
          <TextField
            inputProps={{
              defaultValue: data?.inn,
              disabled: true,
              maxLength: 12,
              name: 'inn',
              placeholder: `ИНН`,
              type: 'string',
            }}
            label={`ИНН`}
            size={'xl'}
            fullwidth
          />
          <input defaultValue={data?.inn} ref={inn.target} style={{ display: 'none' }} />
          <img alt={''} onClick={() => inn.copy()} src={copyTextSVG} />
        </FlexRowGap>
        <br />
        <MediumText>Расчетный счет</MediumText>
        <FlexRowGap>
          <TextField
            inputProps={{
              defaultValue: data?.paymentAccount,
              name: 'paymentAccount',
              placeholder: `Расчетный счет / Текущий счет`,
              type: 'string',
            }}
            label={`Расчетный счет / Текущий счет`}
            size={'xl'}
            fullwidth
          />
          <input
            defaultValue={data?.paymentAccount}
            ref={paymentAccount.target}
            style={{ display: 'none' }}
          />
          <img alt={''} onClick={() => paymentAccount.copy()} src={copyTextSVG} />
        </FlexRowGap>
        <br />
        <MediumText>Корреспондентский счет</MediumText>
        <FlexRowGap>
          <TextField
            inputProps={{
              defaultValue: data?.correspondentBank,
              name: 'correspondentBank',
              placeholder: `Корреспондентский счет`,
              type: 'string',
            }}
            label={`Введите корреспондентский счет`}
            size={'xl'}
            fullwidth
          />
          <input
            defaultValue={data?.correspondentBank}
            ref={correspondentBank.target}
            style={{ display: 'none' }}
          />
          <img alt={''} onClick={() => correspondentBank.copy()} src={copyTextSVG} />
        </FlexRowGap>

        <br />
        <MediumText>БИК</MediumText>
        <FlexRowGap>
          <TextField
            inputProps={{
              defaultValue: data?.bic,
              maxLength: 9,
              name: 'bankIdentificationCode',
              placeholder: `БИК банка`,
              type: 'string',
            }}
            label={`Введите БИК банка`}
            size={'xl'}
            fullwidth
          />
          <input defaultValue={data?.bic} ref={bic.target} style={{ display: 'none' }} />
          <img alt={''} onClick={() => bic.copy()} src={copyTextSVG} />
        </FlexRowGap>

        <br />
        <FlexRowGap>
          <TextField
            inputProps={{
              defaultValue: data?.bankName,
              name: 'bankName',
              placeholder: `Название банка`,
              type: 'string',
            }}
            label={`Введите полное название банка`}
            size={'xl'}
            fullwidth
          />
          <input defaultValue={data?.bankName} ref={bankName.target} style={{ display: 'none' }} />
          <img alt={''} onClick={() => bankName.copy()} src={copyTextSVG} />
        </FlexRowGap>
      </FlexColumnStart>
    </>
  )
}
