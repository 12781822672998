import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { ProductListItem } from '../../../api/generated'
import { shortString } from '../../../utils/truncate'
import { FlexRowBetween, Table, Th } from '../../projectComponents'

export const AdminProductsTable = ({
  isArchive,
  products,
}: {
  isArchive?: boolean
  products: ProductListItem[]
}) => (
  <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}>
    {' '}
    <Table>
      <thead>
        <tr>
          <Th>Дата</Th>
          <Th style={{ maxWidth: '200px' }}>Наименование</Th>
          <Th>Описание</Th>
          <Th>Залог</Th>
          <Th>Цена за день</Th>
        </tr>
      </thead>
      <tbody>
        {products.map(p => (
          <tr key={p.productId}>
            <td>{format(new Date(p.createdOn), 'dd MMMM HH:mm', { locale: ru })}</td>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '150px' }}
                to={`/admin/${isArchive ? `archive-product` : `product`}/${p.productId}`}
              >
                {shortString(p.name, 18)}
              </Link>{' '}
            </td>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '100px' }}
                to={`/${isArchive ? `archive-item` : `item`}/${p.productId}`}
              >
                {shortString(p.description, 36)}
              </Link>
            </td>
            <td>{p.pledge}</td>
            <td>{p.dayPrice}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </FlexRowBetween>
)
