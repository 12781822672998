import React, { FC, ReactNode } from 'react'
import { QueryClientProvider } from 'react-query'

import { configurePersistable } from 'mobx-persist-store'

import { queryClient } from '../../../shared/api/query-client'

export const AppProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  configurePersistable(
    {
      debugMode: true,
      expireIn: 86400000,
      removeOnExpiration: true,
      storage: window.localStorage,
      stringify: false,
    },
    { delay: 200, fireImmediately: false }
  )

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
