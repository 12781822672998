import { PHONE_NUMBER_VALIDATION } from '../constants/regex'

export const PHONE_RULES = {
  maxLength: 12,
  minLength: { message: 'Минимум 10 цифр', value: 12 },
  pattern: {
    message: 'Введите корректный номер телефона',
    value: PHONE_NUMBER_VALIDATION,
  },
  required: {
    message: 'Введите номер телефона',
    value: true,
  },
}
