import React, { useEffect, useState } from 'react'
import { toast } from 'react-custom-alert'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useRentsValidateQrCodeMutation } from '../../../../entities/rent/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { InputImageControlled } from '../../../../shared/ui-kit/actual/InputImageControlled'
import { StyledCheckbox } from '../../../../shared/ui-kit/actual/StyledCheckbox'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  FlexRowGap,
  MediumText,
  NeutralLink,
  StyledButton,
  StyledInput,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { GoodsView } from '../GoodsView'
import { QRCodeScanner } from '../QRCodeScanner'
import { StepWrapper } from '../StepWrapper'

type Props = {
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const AgreementWrapper = styled(FlexRowGap)`
  margin: 16px 0;
  align-items: center;
`

export const Step3 = ({ rentInfo, setStep }: Props) => {
  // const [parentImageUrl, setParentImageUrl] = useState('')
  const [isAgree, setIsAgree] = useState(false)
  const [isScannerActive, setIsScannerActive] = useState(false)

  // const handleUrlChange = (url: string) => {
  //   setParentImageUrl(url)
  // }

  // useEffect(() => {
  //   parentImageUrl && isAgree && setStep(prev => prev + 1)
  // }, [parentImageUrl, setStep, isAgree])
  const { data, isLoading, isSuccess, mutate } = useRentsValidateQrCodeMutation()

  useEffect(() => {
    if (isSuccess && data?.isValid) {
      toast.success('QR-код прошел проверку')
      setIsScannerActive(false)
      setStep(p => p + 1)
    } else if (isSuccess && !data?.isValid) {
      setIsScannerActive(false)
      toast.error('QR-код не прошел проверку')
    }
  }, [data?.isValid, isSuccess, setStep])

  const [qrMessage, setQrMessage] = useState('')
  const [message, setMessage] = useState('')
  const handleCheckCode = () => {
    mutate({ code: message, rentId: rentInfo?.rentId })
  }

  useEffect(() => {
    qrMessage && mutate({ code: qrMessage, rentId: rentInfo?.rentId })
  }, [mutate, qrMessage, rentInfo?.rentId])

  return (
    <>
      {isScannerActive ? (
        <QRCodeScanner
          isScannerActive={isScannerActive}
          qrMessage={qrMessage}
          setIsScannerActive={setIsScannerActive}
          setQrMessage={setQrMessage}
        />
      ) : (
        <StepWrapper>
          <AbsoluteLoader isLoading={isLoading} />
          <FlexColumnStart>
            <H3>На пути к арендатору</H3>
            <MediumText>Отсканируйте QR-код для передачи товара</MediumText>
            <GoodsView rentInfo={rentInfo} />
          </FlexColumnStart>
          <FlexColumn style={{ gap: '8px' }}>
            <AgreementWrapper>
              <StyledCheckbox checked={isAgree} onChange={e => setIsAgree(e.target.checked)} />
              <MediumText>
                Я согласен со всеми правилами платформы (Подробнее:{' '}
                <Link
                  style={{ color: colors.primary }}
                  target={'_blank'}
                  to={'https://landing.allsharing.su/rules/offer-for-lessors-ru.html'}
                >
                  Договор
                </Link>
                )
              </MediumText>
            </AgreementWrapper>
            <NeutralLink to={`/users/${rentInfo?.buyerId}`}>
              <StyledButton variant={'secondary'} fullwidth>
                Об арендаторе
              </StyledButton>
            </NeutralLink>
            <FlexRowBetween style={{ gap: '16px' }}>
              <StyledInput
                disabled={!isAgree}
                onChange={e => setMessage(e.target.value)}
                placeholder={'Введите код'}
                style={{ borderRadius: '15px', flexGrow: 1, height: '48px', paddingLeft: '16px' }}
                value={message}
              />
              <StyledButton disabled={!isAgree} onClick={handleCheckCode} variant={'secondary'}>
                Проверить
              </StyledButton>
            </FlexRowBetween>
            {/* <InputImageControlled
          defaultImageNode={<StyledButton fullwidth>Отсканировать QR-код</StyledButton>}
          disabled={!isAgree}
          handleChange={() => null}
          handleUrlChange={handleUrlChange}
          variant={'full'}
        /> */}
            <StyledButton
              disabled={!isAgree}
              onClick={() => setIsScannerActive(!isScannerActive)}
              fullwidth
            >
              Отсканировать QR-код
            </StyledButton>
            <br />
          </FlexColumn>
        </StepWrapper>
      )}
    </>
  )
}
