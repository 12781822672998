import { ReactNode } from 'react'

type Props = {
  EmailSearch: ReactNode
  FullNameSearch: ReactNode
  PhoneSearch: ReactNode
  UserNameSearch: ReactNode
}

export const AdminUsersTableHead = ({
  EmailSearch,
  FullNameSearch,
  PhoneSearch,
  UserNameSearch,
}: Props) => {
  return (
    <thead>
      <tr>
        <th style={{ width: '40px' }}>Фото</th>
        <th style={{ minWidth: '250px' }}>
          <div>Полное имя</div>
          {FullNameSearch}
        </th>
        <th>
          <div>Никнейм</div>
          {UserNameSearch}
        </th>
        <th>
          <div>Email</div>
          {EmailSearch}
        </th>
        <th>
          <div>Телефон</div>
          {PhoneSearch}
        </th>
        <th>ID</th>
        {/* <th>Рейтинг</th> */}
        <th>Действия</th>
      </tr>
    </thead>
  )
}
