import { useLocation } from 'react-router-dom'

import { ROUTES_TRANSLATE, RoutesTranslate } from '../constants/routesTranslate'

export const usePathnames = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter(x => x) as (keyof RoutesTranslate)[]
  const lastPathname = pathnames[pathnames.length - 1]
  const titleFromLocation = ROUTES_TRANSLATE[lastPathname]

  return { lastPathname, pathnames, titleFromLocation }
}
