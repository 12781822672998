import styled from 'styled-components/macro'

import { horizontalScroll } from '../scroll'

export const Paragraph = styled.div`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #6e6e70;
  line-height: auto;
  overflow: auto;
  ${horizontalScroll}
`
