import * as Accordion from '@radix-ui/react-accordion'
import classNames from 'classnames'

export const AccordionTrigger = ({ children, ...props }) => (
  <Accordion.Header className={'AccordionHeader'}>
    <Accordion.Trigger className={classNames('AccordionTrigger', props.className)} {...props}>
      {children}
      <img
        alt={''}
        className={'AccordionChevron'}
        src={'/images/buttons/arrow-down-dark.svg'}
        aria-hidden
      />
    </Accordion.Trigger>
  </Accordion.Header>
)

export const AccordionContent = ({ children, ...props }) => (
  <Accordion.Content className={classNames('AccordionContent', props.className)} {...props}>
    <div className={'AccordionContentText'}>{children}</div>
  </Accordion.Content>
)
