import { Link } from 'react-router-dom'

import { StyledTextAuthFooter } from '../../../shared/ui-kit/projectComponents'

export const SigninFooter = () => {
  return (
    <footer>
      <StyledTextAuthFooter>
        Нет аккаунта? <Link to={'/register'}>Зарегистрироваться</Link>
      </StyledTextAuthFooter>
    </footer>
  )
}
