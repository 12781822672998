import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { useParams } from 'react-router-dom'

import {
  PostApiRentsArriveBackToSellerBody,
  PostApiRentsCompleteWithClaimBySellerBody,
} from '../../../shared/api/generated'
import { filesCompression } from '../../../shared/utils/filesCompression'
type Props = {
  defectIds?: number[]
  isSuccess: boolean
  message?: string
  mutate: UseMutateFunction<
    unknown,
    unknown,
    PostApiRentsArriveBackToSellerBody | PostApiRentsCompleteWithClaimBySellerBody,
    unknown
  >
  nextStep?: number
  setStep: Dispatch<SetStateAction<number>>
}
export const useSendPhotos = ({
  defectIds,
  isSuccess,
  message,
  mutate,
  nextStep,
  setStep,
}: Props) => {
  const [arePhotosSelected, setArePhotosSelected] = useState(false)
  const [imageFiles, setImageFiles] = useState<File[]>([null, null, null, null, null, null])

  const { rentId } = useParams()
  const [isCompressing, setIsCompressing] = useState(false)

  const compressFiles = async (imageFiles: File[]) => {
    if (imageFiles) {
      setIsCompressing(true)
      await Promise.all(
        imageFiles.map(async (image, idx) => {
          if (!image || !(image instanceof File)) {
            return
          }
          try {
            const { compressedFile } = await filesCompression(image)

            if (compressedFile && compressedFile instanceof File) {
              imageFiles[idx] = compressedFile
            }
          } catch {
            return
          }
        })
      )

      setIsCompressing(false)
    }

    return imageFiles
  }
  const handleSubmit = defectIds
    ? async () => {
        await compressFiles(imageFiles)

        mutate({
          ImageBackSide: imageFiles[3],
          ImageDownSide: imageFiles[5],
          ImageFrontSide: imageFiles[0],
          ImageLeftSide: imageFiles[4],
          ImageRightSide: imageFiles[2],
          ImageUpSide: imageFiles[1],
          Message: message,
          OtherImages: imageFiles.filter((img, idx) => idx > 5 && img),
          RentId: +rentId,
          SelectedDefectFinesIds: defectIds,
        } as PostApiRentsCompleteWithClaimBySellerBody)
      }
    : async () => {
        await compressFiles(imageFiles)

        mutate({
          ImageBackSide: imageFiles[3],
          ImageDownSide: imageFiles[5],
          ImageFrontSide: imageFiles[0],
          ImageLeftSide: imageFiles[4],
          ImageRightSide: imageFiles[2],
          ImageUpSide: imageFiles[1],
          OtherImages: imageFiles.filter((img, idx) => idx > 5 && img),
          RentId: +rentId,
        } as PostApiRentsArriveBackToSellerBody)
      }

  useEffect(() => {
    if (isSuccess) {
      nextStep ? setStep(nextStep) : setStep(p => p + 1)
    }
  }, [isSuccess, nextStep, setStep])

  return {
    arePhotosSelected,
    handleSubmit,
    imageFiles,
    isCompressing,
    setArePhotosSelected,
    setImageFiles,
  }
}
