import { useEffect, useMemo, useState } from 'react'

import { useGetClaimsListQuery } from '../entities/claims/queries'
import { ClaimStatus } from '../shared/api/generated'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { TextsField } from '../shared/ui-kit/actual/TextsField'
import { AdminClaimsTable } from '../shared/ui-kit/actual/components/AdminClaimsTable'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  BasePage,
  FlexColumn,
  FlexRowBetween,
  FlexRowGap,
  SectionsPageWrapper,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H1, H5 } from '../shared/ui-kit/titles'

const statusesTranslate = [
  { id: 'approved', name: 'принята' },
  { id: 'created', name: 'создана' },
  { id: 'rejected', name: 'отклонена' },
]

export default function AdminClaimsListPage() {
  const [statuses, setStatuses] = useState<string[]>([])

  useEffect(() => {
    setPage(0)
  }, [statuses])
  const resetTerms = () => {
    setStatuses([])
    setPage(0)
  }
  const [itemsPerPage, setItemsPerPage] = useState(24)
  const [page, setPage] = useState(0)
  const { data: claimsData, isLoading } = useGetClaimsListQuery({
    skip: page * itemsPerPage,
    statuses: statuses as ClaimStatus[],
    take: itemsPerPage,
  })
  const totalPages = useMemo(
    () => (claimsData ? Math.ceil(claimsData?.totalCount / itemsPerPage) : 1),
    [itemsPerPage, claimsData]
  )

  return (
    <BasePage>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <ArrowBackButton />
        <H1 style={{ width: '100%' }}>Просмотр списка жалоб</H1>
      </FlexRowBetween>
      <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15 }}>
        <p style={{ fontSize: 15, margin: 0, padding: 0 }}>
          Всего жалоб c примененным фильтром : {claimsData?.totalCount}
        </p>
        {/* <Filter onClick={() => setOpenUserSortModal(true)}>
              <img alt={''} src={'/images/buttons/filter2.png'} />
            </Filter> */}
        <StyledButton onClick={resetTerms} size={'sm'}>
          Сбросить фильтр
        </StyledButton>
      </FlexRowBetween>
      <br />
      <FlexRowGap style={{ alignSelf: 'flex-start', margin: '0 0 24px 24px' }}>
        <label htmlFor={'search'}>Фильтр по статусу</label>
        <TextsField
          selectedTexts={statuses}
          setSelectedTexts={setStatuses}
          texts={statusesTranslate}
        />
      </FlexRowGap>
      <SectionsPageWrapper>
        {!isLoading ? (
          <>
            <AdminClaimsTable claims={claimsData?.items || []} />
            {claimsData && (
              <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
            )}
          </>
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
    </BasePage>
  )
}
