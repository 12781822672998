import { Link } from 'react-router-dom'

import { formatDistance } from 'date-fns'
import styled from 'styled-components/macro'

import { ReviewListItemDto } from '../../../../shared/api/generated'
import { BlockTitle } from '../../../../shared/ui-kit/actual/InfoButton'
import { Paragraph } from '../../../../shared/ui-kit/actual/Paragraph'
import {
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
} from '../../../../shared/ui-kit/projectComponents'
import { getDateAgo } from '../../../../shared/utils/dateAgo'
import { displayRateByAmount } from '../../../../shared/utils/displayStringByAmount'
import { ReviewItem } from '../components/ReviewItem'

const Wrapper = styled(FlexColumnStart)`
  row-gap: 24px;
  width: 100%;
`

export const ReviewsList = ({
  header = true,
  linkHandle,
  linkPath,
  reviews,
  reviewsCount,
  userRating,
}: {
  header?: boolean
  linkHandle?: () => void
  linkPath?: string
  reviews: ReviewListItemDto[]
  reviewsCount: number
  userRating: number
}) => {
  const maxLength = header ? 3 : reviews?.length

  return (
    <FlexColumnStart style={{ marginTop: '32px', width: '100%' }}>
      {header && (
        <>
          <FlexRowBetween style={{ alignItems: 'center', margin: '0 0 8px', width: '100%' }}>
            <BlockTitle style={{ width: '100%' }}>Отзывы </BlockTitle>
            <Link onClick={linkHandle} to={linkPath}>
              <img alt={''} src={'/images/buttons/arrow-right-black.svg'} />
            </Link>
          </FlexRowBetween>
          <FlexRow style={{ gap: '4px', justifyContent: 'start' }}>
            <img alt={'star'} src={'/images/interface/star.svg'} />
            <Paragraph>
              <span style={{ color: '#000' }}>{userRating.toFixed(1)} </span>· {reviewsCount || 0}{' '}
              {displayRateByAmount('оцен', reviewsCount || 0)} на товар
            </Paragraph>
          </FlexRow>
        </>
      )}
      <br />
      <Wrapper>
        {reviews
          ?.reverse()
          .slice(0, maxLength)
          .map((review, idx) => {
            return (
              <ReviewItem
                avatar={review?.buyerAvatarImageUrl}
                dateAgo={getDateAgo(review?.createOn)}
                desc={review?.content}
                key={review?.reviewId}
                name={review?.buyerUserName}
                rating={review?.rating}
              />
            )
          })}
      </Wrapper>
    </FlexColumnStart>
  )
}
