import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useCreateCriterionMutation } from '../../../../entities/criteria/queries'
import { CriterionFilterType } from '../../../api/generated'
import { StyledSelect } from '../../SelectWrapper'
import { FlexColumn, FlexRowBetween, MediumText, StyledButton } from '../../projectComponents'
import { H3 } from '../../titles'
import { TagsField } from '../TagsField'
import { TagsFieldNew } from '../TagsFieldNew'
import { TextField } from '../TextField'
import { MiniModal } from '../modals/MiniModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const ContentModal = styled(FlexColumn)`
  padding-bottom: 20px;
  width: 100%;
`

interface AddCriteriaModalProps {
  addCriteriaModal: boolean
  categoryId: number
  setAddCriteriaModal: (v: boolean) => void
}
type FilterOption = { label: string; value: CriterionFilterType }
const filterOption: FilterOption[] = [
  { label: 'диапазон чисел', value: 'numberFromTo' },
  { label: 'одно число', value: 'numberOneOf' },
  { label: 'одно целое слово', value: 'stringExact' },
  { label: 'диапазон слов', value: 'stringOneOf' },
  { label: 'поиск по фрагментам слова', value: 'stringTerm' },
]

export const AdminAddCriteriaModal = ({
  addCriteriaModal,
  categoryId,
  setAddCriteriaModal,
}: AddCriteriaModalProps) => {
  const { isLoading, isSuccess, mutate } = useCreateCriterionMutation()
  const [name, setName] = useState('')
  const [propertyName, setPropertyName] = useState('')
  const [numberValue, setNumberValue] = useState<'' | number>('')
  const [possibleNumberValues, setPossibleNumberValues] = useState<number[]>([])
  const [stringValue, setStringValue] = useState('')
  const [possibleStringValues, setPossibleStringValues] = useState<string[]>([])

  const handleAddNumberValue = () => {
    setPossibleNumberValues(prev => {
      const newValues = [...prev, Number(numberValue)]

      setNumberValue('')

      return newValues
    })
  }
  const handleAddStringValue = () => {
    setPossibleStringValues(prev => {
      const newValues = [...prev, stringValue]

      setStringValue('')

      return newValues
    })
  }
  const [filterType, setFilterType] = useState<FilterOption>({
    label: 'диапазон чисел',
    value: 'numberFromTo',
  })

  useEffect(() => {
    setPossibleNumberValues([])
    setPossibleStringValues([])
  }, [filterType.value])

  const CloseModal = () => {
    setAddCriteriaModal(false)
  }
  const handleMutate = () => {
    mutate({
      categoryId,
      filterType: filterType.value,
      name,
      possibleNumberValues,
      possibleStringValues,
      propertyName,
    })
  }

  return (
    <MiniModal setViewModal={setAddCriteriaModal} viewModal={addCriteriaModal}>
      <AbsoluteLoader isLoading={isLoading} />

      <H3 style={{ marginBottom: 20 }}>Создать новый критерий для категории</H3>
      <TextField
        inputProps={{
          onChange: e => setName(e.target.value),
          placeholder: 'Название группы критериев',
          value: name,
        }}
        style={{
          borderRadius: '15px',
          flexGrow: 1,
          height: '48px',
          paddingLeft: '16px',
          width: '100%',
        }}
        fullwidth
      />
      <br />
      <TextField
        inputProps={{
          onChange: e => setPropertyName(e.target.value),
          placeholder: 'Название критерия',
          value: propertyName,
        }}
        style={{
          borderRadius: '15px',
          flexGrow: 1,
          height: '48px',
          paddingLeft: '16px',
          width: '100%',
        }}
        fullwidth
      />
      <br />
      <MediumText style={{ width: '100%' }}>Вариант критериев</MediumText>
      <div style={{ width: '100%', zIndex: '1000' }}>
        <StyledSelect
          classNamePrefix={'Select'}
          // defaultInputValue={'Выбор'}
          onChange={(e: any) => setFilterType({ label: e.label, value: e.value })}
          options={filterOption}
          value={filterType}
        />
      </div>
      <br />
      {(filterType.value === 'numberFromTo' || filterType.value === 'numberOneOf') && (
        <>
          <TextField
            inputProps={{
              onChange: e => setNumberValue(Number(e.target.value)),
              placeholder: 'числовой критерий',
              type: 'number',
              value: numberValue,
            }}
            style={{
              borderRadius: '15px',
              flexGrow: 1,
              height: '48px',
              paddingLeft: '16px',
              width: '100%',
            }}
            fullwidth
          />
          <br />
          <FlexRowBetween style={{ alignItems: 'center', gap: '24px' }}>
            <StyledButton onClick={handleAddNumberValue} size={'sm'}>
              добавить число
            </StyledButton>
            <TagsFieldNew
              selectedTags={possibleNumberValues}
              setSelectedTags={setPossibleNumberValues}
              tags={possibleNumberValues.map(n => ({ id: n, name: String(n) }))}
            />
          </FlexRowBetween>
        </>
      )}
      {(filterType.value === 'stringExact' ||
        filterType.value === 'stringTerm' ||
        filterType.value === 'stringOneOf') && (
        <>
          <TextField
            inputProps={{
              onChange: e => setStringValue(e.target.value),
              placeholder: 'строковый критерий',
              value: stringValue,
            }}
            style={{
              borderRadius: '15px',
              flexGrow: 1,
              height: '48px',
              paddingLeft: '16px',
              width: '100%',
            }}
            fullwidth
          />
          <br />
          <FlexRowBetween style={{ alignItems: 'center', gap: '24px' }}>
            <StyledButton onClick={handleAddStringValue} size={'sm'}>
              добавить слово
            </StyledButton>
            <TagsFieldNew
              selectedTags={possibleStringValues}
              setSelectedTags={setPossibleStringValues}
              tags={possibleStringValues.map(n => ({ id: n, name: n }))}
            />
          </FlexRowBetween>
        </>
      )}
      <br />
      <ContentModal>
        <FlexRowBetween>
          <StyledButton onClick={CloseModal} variant={'outline'}>
            Отмена
          </StyledButton>
          <StyledButton
            disabled={isLoading}
            onClick={handleMutate}
            size={'xl'}
            style={{ width: '100%' }}
          >
            Добавить критерий
          </StyledButton>
        </FlexRowBetween>
      </ContentModal>
    </MiniModal>
  )
}
