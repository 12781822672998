import { SORTING_TYPE } from '../api/deprecated/productService/ProductService'
import { ProductsListOrderType } from '../api/generated'

export const sortingSelectors = [
  {
    name: 'По дате',
    value: ProductsListOrderType.byDate,
  },
  // {
  //   name: 'По заказам',
  //   value: SORTING_TYPE.ByAOrders,
  // },
  {
    name: 'По рейтингу',
    value: ProductsListOrderType.byRating,
  },
  {
    name: 'По умолчанию',
    value: ProductsListOrderType.byDefault,
  },
  {
    name: 'По стоимости залога',
    value: ProductsListOrderType.byRentsCount,
  },
  // {
  //   name: 'По цене за 3 дня',
  //   value: SORTING_TYPE.By3DaysAPrice,
  // },
  {
    name: 'По цене за 1 день',
    value: ProductsListOrderType.byDayPrice,
  },
  {
    name: 'По цене за неделю',
    value: ProductsListOrderType.byWeekPrice,
  },
  {
    name: 'По цене за месяц',
    value: ProductsListOrderType.byMonthPrice,
  },
]
