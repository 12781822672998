import React, { useState } from 'react'

import styled from 'styled-components/macro'

import { SetStarsRating } from '../../../shared/ui-kit/actual/SetStarsRating'
import { TextArea } from '../../../shared/ui-kit/actual/TextArea'
import {
  FlexRowStart,
  MediumText,
  SmallTextGrey,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { H2, H5 } from '../../../shared/ui-kit/titles'
import { Image, ImagesWrapper } from './OrderCard'
import { RatingHead } from './RentalRating'

const FormWrapper = styled.form`
  align-self: stretch;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`

export const ProductRating = ({ setRatingItem, setViewModal }) => {
  const [isSending, setIsSending] = useState(false)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const handleClick = () => {
    setIsSending(true)
    setRatingItem('rental')
  }

  return (
    <FormWrapper onClick={e => e.preventDefault()}>
      <div>
        <RatingHead>Оцените аренду товара</RatingHead>
        <FlexRowStart style={{ gap: '16px' }}>
          <ImagesWrapper>
            <Image alt={''} src={'/images/mock-items/mock-image-7.jpg'} />
          </ImagesWrapper>
          <div>
            <SmallTextGrey>12-14 декабря</SmallTextGrey>
            <H5>Bershka Mom Jeans</H5>
            <MediumText>Размер S</MediumText>
          </div>
        </FlexRowStart>

        <SetStarsRating rating={rating} setRating={setRating} />
        <br />
        <TextArea
          inputProps={{
            placeholder: 'Комментарий',
            rows: 7,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
          style={{ marginBottom: '8px', width: '100%' }}
          value={comment}
          fullwidth
        />
        <br />
        <StyledButton disabled={!rating || isSending} onClick={handleClick} fullwidth>
          {isSending ? 'Отзыв отправлен' : 'Оставить отзыв'}
        </StyledButton>
      </div>
      <StyledButton onClick={() => setViewModal(false)} variant={'secondary'} fullwidth>
        К заказам
      </StyledButton>
    </FormWrapper>
  )
}
