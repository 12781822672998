import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  ApproveBySellerCommand,
  CancelByBuyerCommand,
  CancelBySellerCommand,
  CreateRentCommand,
  CreateRentResponse,
  GenerateQrCodeCommand,
  GenerateQrCodeResponse,
  GetProductRentPeriodsQuery,
  GetProductRentPeriodsResponse,
  GetProductRentPriceQuery,
  GetProductRentPriceResponse,
  GetRentDefectFinesListQuery,
  GetRentDefectFinesListResponse,
  GetRentInfoResponse,
  GetRentsListQuery,
  GetRentsListResponse,
  PostApiRentsArriveBackToSellerBody,
  PostApiRentsArriveToBuyerBody,
  PostApiRentsCompleteWithClaimByBuyerBody,
  PostApiRentsCompleteWithClaimBySellerBody,
  PostApiRentsSendBackToSellerBody,
  PostApiRentsSendToBuyerBody,
  RejectBySellerCommand,
  ValidateQrCodeCommand,
  ValidateQrCodeResponse,
  postApiRentsApproveBySeller,
  postApiRentsArriveBackToSeller,
  postApiRentsArriveToBuyer,
  postApiRentsCancelByBuyer,
  postApiRentsCancelBySeller,
  postApiRentsCompleteWithClaimByBuyer,
  postApiRentsCompleteWithClaimBySeller,
  postApiRentsCreateRent,
  postApiRentsGenerateQrCode,
  postApiRentsGetProductRentPeriods,
  postApiRentsGetProductRentPrice,
  postApiRentsGetRentDefectFinesList,
  postApiRentsGetRentInfo,
  postApiRentsGetRentsList,
  postApiRentsRejectBySeller,
  postApiRentsSendBackToSeller,
  postApiRentsSendToBuyer,
  postApiRentsValidateQrCode,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useCreateRentMutation(
  options?: UseMutationOptions<CreateRentResponse, unknown, CreateRentCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CreateRentCommand) => {
      return postApiRentsCreateRent(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка создания аренды')
    },
    onSuccess: () => {
      toast.success('Вы успешно заказали аренду')
    },
    ...options,
  })
}

export function useRentsArriveToBuyerMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiRentsArriveToBuyerBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiRentsArriveToBuyerBody) => {
      return postApiRentsArriveToBuyer(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки фотографий')
    },
    onSuccess: () => {
      toast.success('Вы подтвердили начало аренды')
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}
export function useRentsSendBackToSellerMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiRentsSendBackToSellerBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiRentsSendBackToSellerBody) => {
      return postApiRentsSendBackToSeller(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки фотографий')
    },
    onSuccess: () => {
      toast.success('Вы подтвердили возврат товара арендодателю')
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}
export function useRentsArriveBackToSellerMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiRentsArriveBackToSellerBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiRentsArriveBackToSellerBody) => {
      return postApiRentsArriveBackToSeller(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки фотографий')
    },
    onSuccess: () => {
      toast.success('Вы подтвердили возврат товара от арендатора')
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}

export function useSendToBuyerMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiRentsSendToBuyerBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiRentsSendToBuyerBody) => {
      return postApiRentsSendToBuyer(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки фотографий')
    },
    onSuccess: () => {
      toast.success('Фотографии успешно отправлены')
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}
export function useCompleteWithClaimBySellerMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiRentsCompleteWithClaimBySellerBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiRentsSendToBuyerBody) => {
      return postApiRentsCompleteWithClaimBySeller(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки фотографий для жалобы')
    },
    onSuccess: () => {
      toast.success('Фотографии для жалобы успешно отправлены')
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}
export function useCompleteWithClaimByBuyerMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiRentsCompleteWithClaimByBuyerBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiRentsSendToBuyerBody) => {
      return postApiRentsCompleteWithClaimByBuyer(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки фотографий для жалобы')
    },
    onSuccess: () => {
      toast.success('Фотографии для жалобы успешно отправлены')
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}

export function useGetRentInfoQuery(
  rentId: string,
  options?: UseQueryOptions<unknown, unknown, GetRentInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка данных об аренде')
      if (e.response.status == 403) {
        window.location.href = '/'
        toast.error(e.response?.data?.message || 'Ошибка данных об аренде')
      }
    },
    queryFn: () => postApiRentsGetRentInfo({ rentId: Number(rentId) }),
    queryKey: ['get_rent_info', rentId],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGetRentDefectFinesListQuery(
  rentId: string,
  options?: UseQueryOptions<unknown, unknown, GetRentDefectFinesListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка данных о списке дефектов')
    },
    queryFn: () => postApiRentsGetRentDefectFinesList({ rentId: Number(rentId) }),
    queryKey: ['get_rent_defects_list', rentId],
    ...options,
  })
}

export function useRentsGetRentsListQuery(
  params: GetRentsListQuery,
  options?: UseQueryOptions<unknown, unknown, GetRentsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки данных об арендах')
      window.location.href = '/'
    },
    queryFn: () => postApiRentsGetRentsList(params),
    queryKey: ['get_all_rents_info', JSON.stringify(params)],
    refetchInterval: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    ...options,
  })
}

export function useGetRentPriceMutation(
  options?: UseMutationOptions<
    GetProductRentPriceResponse,
    unknown,
    GetProductRentPriceQuery,
    unknown
  >
) {
  return useMutation({
    mutationFn: (data: GetProductRentPriceQuery) => {
      return postApiRentsGetProductRentPrice(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки данных о цене аренды')
    },

    ...options,
  })
}
export function useRentsGetProductRentPeriodsMutation(
  options?: UseMutationOptions<
    GetProductRentPeriodsResponse,
    unknown,
    GetProductRentPeriodsQuery,
    unknown
  >
) {
  return useMutation({
    mutationFn: (data: GetProductRentPeriodsQuery) => {
      return postApiRentsGetProductRentPeriods(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(
        e.response?.data?.message || 'Ошибка загрузки данных о недоступных периодах аренды'
      )
    },

    ...options,
  })
}

export function useApproveBySellerMutation(
  options?: UseMutationOptions<unknown, unknown, ApproveBySellerCommand, unknown>
) {
  return useMutation({
    mutationFn: async (data: ApproveBySellerCommand) => {
      return postApiRentsApproveBySeller(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(
        e.response?.data?.message ||
          'Возможно заказ отменен арендатором. Если ошибка повторится, напишите в техподдержку.'
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}

export function useRentsGenerateQrCodeMutation(
  options?: UseMutationOptions<GenerateQrCodeResponse, unknown, GenerateQrCodeCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: GenerateQrCodeCommand) => {
      return postApiRentsGenerateQrCode(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при генерации QR-кода')
    },

    ...options,
  })
}
export function useRentsValidateQrCodeMutation(
  options?: UseMutationOptions<ValidateQrCodeResponse, unknown, ValidateQrCodeCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ValidateQrCodeCommand) => {
      return postApiRentsValidateQrCode(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при проверке QR-кода')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_rent_info'] })
    },
    ...options,
  })
}
export function useRentsCancelByBuyerMutation(
  options?: UseMutationOptions<unknown, unknown, CancelByBuyerCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CancelByBuyerCommand) => {
      return postApiRentsCancelByBuyer(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при отмене аренды, попробуйте еще раз')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })
      toast.info('Заказ успешно отменен')
    },
    ...options,
  })
}
export function useRentsCancelBySellerMutation(
  options?: UseMutationOptions<unknown, unknown, CancelBySellerCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CancelByBuyerCommand) => {
      return postApiRentsCancelBySeller(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при отмене аренды, попробуйте еще раз')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })
      toast.info('Доставка успешно отменена')
    },
    ...options,
  })
}
export function useRentsRejectBySellerMutation(
  options?: UseMutationOptions<unknown, unknown, RejectBySellerCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RejectBySellerCommand) => {
      return postApiRentsRejectBySeller(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при отмене аренды, попробуйте еще раз')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })
      toast.info('Доставка успешно отменена')
    },
    ...options,
  })
}
