import React, { useState } from 'react'

import { useRentsSendBackToSellerMutation } from '../../../../entities/rent/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { IncomingPhotos } from '../../../../shared/ui-kit/actual/IncomingPhotos'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumn, MediumText, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H3, H4 } from '../../../../shared/ui-kit/titles'
import { useSendPhotos } from '../../model/useSendPhotos'
import { AddPhotos } from '../AddPhotos'
import { StepWrapper } from '../StepWrapper'

type Props = {
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const Step6 = ({ rentInfo, setStep }: Props) => {
  const { isLoading, isSuccess, mutate } = useRentsSendBackToSellerMutation()

  const { arePhotosSelected, handleSubmit, setArePhotosSelected, setImageFiles } = useSendPhotos({
    isSuccess,
    mutate,
    setStep,
  })

  return (
    <StepWrapper>
      <AbsoluteLoader isLoading={isLoading} />
      <FlexColumn>
        <H3>Сфотографируйте товар</H3>
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Сделайте фотографии по инструкции, даже если не видите дефектов. В противном случае
          платформа вправе считать, что они были причинены по вашей вине
        </MediumText>

        <H4>Фото товара до использования</H4>
        <IncomingPhotos data={rentInfo?.beforeRentSellerImagesUrls} />

        <H4>Ваши фото</H4>
        <AddPhotos
          arePhotosSelected={arePhotosSelected}
          setArePhotosSelected={setArePhotosSelected}
          setImageFiles={setImageFiles}
        />
      </FlexColumn>

      {arePhotosSelected ? (
        <StyledButton disabled={isLoading} onClick={handleSubmit} fullwidth>
          Далее
        </StyledButton>
      ) : (
        <StyledButton variant={'secondary'} disabled fullwidth>
          До отправки сделайте 6 фото
        </StyledButton>
      )}
      <br />
    </StepWrapper>
  )
}
