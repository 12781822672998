import styled, { CSSProperties } from 'styled-components/macro'

import { useSubscribe, useUnSubscribe } from '../../entities/subscribe'
import { useProfileInfo } from '../../features/profile/model/use-profile-info'
import { colors } from '../constants/colors'
import { StarsRating } from './actual/StarsRating'
import {
  FilterTitle,
  FlexColumnStart,
  FlexRowBetween,
  FlexRowStart,
  NeutralLink,
  StyledButton,
} from './projectComponents'

export enum ProfileSnippetVariant {
  default = 'default',
  minimized = 'minimized',
}

const AvatarPlaceholder = styled.div<{ variant?: ProfileSnippetVariant }>`
  width: ${props => (props.variant === ProfileSnippetVariant.default ? '60px' : '33px')};
  margin-right: 16px;
  height: ${props => (props.variant === ProfileSnippetVariant.default ? '60px' : '33px')};
  background: ${colors.darkGrayText};
  border-radius: ${props => (props.variant === ProfileSnippetVariant.default ? '8px' : '100%')};
`

const ProfileFullName = styled(FilterTitle)<{ withRating?: boolean }>`
  font-size: ${props => (props.withRating ? '12px' : '16px')};
  font-weight: ${props => (props.withRating ? '500' : '700')};
  color: ${props => (props.withRating ? colors.darkGrayText : colors.black)};
`

const ProfileUserName = styled.span`
  font-size: 13px;
  color: ${colors.darkGrayText};
`

const AvatarCover = styled.img`
  object-fit: cover;
  border-radius: inherit;
  width: inherit;
  height: inherit;
`

interface ProfileSnippetProps {
  additionalStyles?: CSSProperties
  authorId?: string
  authorImageUrl?: string
  authorLogin?: string
  authorName?: string
  currentUser?: {
    avatarLink: string
    fullName: string
    id: string
    isSubscribed: boolean
    userName: string
  } | null
  rating?: number
  variant?: ProfileSnippetVariant
}

const ProfileSnippet = ({
  additionalStyles,
  authorId,
  authorImageUrl,
  authorLogin,
  authorName,
  currentUser,
  rating,
  variant = ProfileSnippetVariant.default,
}: ProfileSnippetProps) => {
  const { avatarLink, fullName, id, isSubscribed, userName } = currentUser
  const { userInfo } = useProfileInfo()

  const subscribed = isSubscribed
  const subscribeMutation = useSubscribe()
  const unSubscribeMutation = useUnSubscribe()
  const subscribe = () =>
    !subscribed
      ? subscribeMutation.mutate({ userId: authorId })
      : unSubscribeMutation.mutate({ userId: authorId })
  const iThisUser = authorId === userInfo?.id

  return (
    <FlexRowBetween style={{ alignItems: 'center', gap: '12px', ...additionalStyles }}>
      <NeutralLink to={`/users/${authorId || id}`}>
        <FlexRowStart>
          <AvatarPlaceholder variant={variant}>
            <AvatarCover src={authorImageUrl || avatarLink || '/images/buttons/user-avatar.png'} />
          </AvatarPlaceholder>
          <FlexColumnStart>
            {!!rating && <StarsRating rating={rating} small />}
            <ProfileFullName withRating={!!rating}>{authorName || fullName}</ProfileFullName>
            {variant === ProfileSnippetVariant.default ? (
              <ProfileUserName>@{authorLogin || userName}</ProfileUserName>
            ) : null}
          </FlexColumnStart>
        </FlexRowStart>
      </NeutralLink>
      {variant === ProfileSnippetVariant.default && !iThisUser ? (
        <StyledButton
          disabled={subscribeMutation.isLoading || unSubscribeMutation.isLoading}
          onClick={subscribe}
          size={'sm'}
        >
          {' '}
          {subscribed ? 'Отменить подписку' : 'Подписаться'}
        </StyledButton>
      ) : null}
    </FlexRowBetween>
  )
}

export default ProfileSnippet
