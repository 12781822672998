import React, { ReactNode, useEffect } from 'react'
import Slider from 'react-slick'

import styled from 'styled-components/macro'

interface ISliderProps {
  arrows?: boolean
  autoplaySpeed?: number
  dots?: boolean
  infinite?: boolean
  initialSlide?: number
  items: ReactNode[]
  sliderProps?: any
  slidesToScroll?: number
  slidesToShow: number
  speed?: number
}

const SliderWrapper = styled('div')`
  width: 100%;
  .slick-list {
    overflow: hidden;
  }
  /* Slider */
  .slick-slider {
    margin-bottom: 8px;
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    /* overflow: hidden; */

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  /* Arrows */
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: auto;
    background: #fff;
    bottom: 16px;
    z-index: 999;
  }

  .slick-prev {
    left: 14px;
  }

  .slick-next {
    right: 14px;
  }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 10px;
    height: 10px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }

  /* Custom Slick Dots */
  .slick-dots li {
    width: 9px;
    height: 9px;
    background: #e4e2ee;
    border-radius: 50%;
  }
  .slick-dots .slick-active {
    background: #008c8c;
  }
`

function SampleNextArrow(props) {
  const { className, onClick, style } = props

  return (
    <div className={className} onClick={onClick}>
      <svg
        fill={'none'}
        height={'16'}
        viewBox={'0 0 10 16'}
        width={'10'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <path
          d={'M1.5 1L8.5 8L1.5 15'}
          stroke={`${className.includes('slick-disabled') ? '#A0A6A6' : '#151B1A'}`}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeWidth={'2'}
        />
      </svg>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, onClick } = props

  return (
    <div className={className} onClick={onClick}>
      <svg
        fill={'none'}
        height={'16'}
        viewBox={'0 0 10 16'}
        width={'10'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <path
          d={'M8.5 15L1.5 8L8.5 1'}
          stroke={`${className.includes('slick-disabled') ? '#A0A6A6' : '#151B1A'}`}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeWidth={'2'}
        />
      </svg>
    </div>
  )
}

export const SliderComponent: React.FC<ISliderProps> = ({
  arrows = true,
  autoplaySpeed = 3000,
  dots = false,
  infinite = false,
  initialSlide = 0,
  items,
  sliderProps,
  slidesToScroll = 1,
  slidesToShow,
  speed = 500,
}) => {
  const sliderSettings = {
    arrows,
    autoplaySpeed,
    dots,
    infinite,
    initialSlide,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll,
    slidesToShow,
    speed,
    ...sliderProps,
  }
  const slider = React.useRef(null)

  useEffect(() => {
    slider.current.slickGoTo(initialSlide)
  }, [initialSlide])

  return (
    <SliderWrapper>
      <Slider
        ref={slider}
        {...sliderSettings}
        style={{ marginBottom: dots ? '30px' : 0, position: 'relative' }}
      >
        {items.map((item, idx) => {
          return <div key={idx}>{item}</div>
        })}
      </Slider>
    </SliderWrapper>
  )
}
