import React, { ComponentPropsWithoutRef, ReactNode, useId, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { FlexColumn, FlexRow, StyledInput } from '../projectComponents'
import { StyledInputProps, TINPUT_SIZE } from './StyledInput'
import { EyeOffSVG } from './svg/EyeOffSVG copy'
import { EyeSVG } from './svg/EyeSVG'

const SIZE_VARIANTS = {
  md: 159.5,
  sm: 74.227,
  xl: 327,
}
const Wrapper = styled(FlexRow)<{
  active?: boolean
  error?: boolean
  fullwidth?: boolean
  hasLabel?: boolean
  size?: TINPUT_SIZE
}>`
  max-width: ${({ fullwidth, size }) => (fullwidth ? '100%' : `${SIZE_VARIANTS[size]}px`)};
  width: 100%;
  height: 50px;
  background-color: ${colors.lightGray};
  border-radius: 10px;
  padding: 0px 15px 0px 20px;
  align-items: center;
  box-sizing: border-box;
  gap: 8px;
  position: relative;

  .input__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    label {
      display: ${({ active }) => (active ? 'block' : 'none')};
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      padding: 0;
      color: ${colors.grey};
      color: ${({ error }) => error && colors.red};
    }

    input {
      margin: 0;
      padding: 0;
      height: ${({ active }) => (active ? 'auto' : '100%')};
      width: calc(100% - 20px);
      color: ${({ error }) => error && colors.red};
      text-align: ${({ size }) => (size === 'sm' ? 'center' : 'start')};

      ::placeholder {
        color: ${colors.middleGray};
      }
    }
  }
`
const ErrorText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 208.333% */
  color: ${colors.red};
  align-self: start;
  margin: 0;
`

const Container = styled(FlexColumn)<{ fullwidth?: boolean; size?: TINPUT_SIZE }>`
  min-width: ${({ size }) => SIZE_VARIANTS[size]}px;
  width: ${({ fullwidth }) => fullwidth && '100%'};
  gap: 4px;
  box-sizing: border-box;
  position: relative;
`

const SideWrapper = styled.div`
  min-width: fit-content;
  display: flex;

  img,
  svg {
    width: 24px;
    height: 24px;
  }
`

type TextFieldProps = {
  dispatchLeft?: () => void
  dispatchRight?: () => void
  error?: string
  fullwidth?: boolean
  inputProps?: ComponentPropsWithoutRef<'input'> & StyledInputProps
  label?: string
  left?: ReactNode
  right?: ReactNode
  size?: TINPUT_SIZE
} & ComponentPropsWithoutRef<'div'>

const PhoneCode = styled.div`
  position: absolute;
  top: 14.5px;
  left: -10px;
  color: ${colors.gray};
`

export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref?: React.Ref<HTMLDivElement>) => {
    const {
      dispatchLeft,
      dispatchRight,
      error,
      fullwidth,
      inputProps,
      label,
      left,
      right,
      size = 'xl',
      ...other
    } = props
    const id = useId()
    const [active, setActive] = useState(false || inputProps?.type === 'date')
    const [inputType, setInputType] = useState(inputProps?.type)

    return (
      <Container {...other} fullwidth={fullwidth} size={size}>
        <Wrapper
          active={active}
          error={!!error}
          fullwidth={fullwidth}
          hasLabel={!!label}
          onBlur={() => label && setActive(Boolean(inputProps.value))}
          onFocus={() => label && setActive(true)}
          size={size}
        >
          {left && <SideWrapper onClick={dispatchLeft}>{left}</SideWrapper>}

          <div className={'input__wrapper'}>
            {label && (
              <label htmlFor={id} style={{ marginLeft: inputProps.type === 'tel' && 20 }}>
                {label}
              </label>
            )}
            <StyledInput
              {...inputProps}
              id={id}
              placeholder={active ? '' : inputProps?.placeholder}
              style={{ marginLeft: inputProps.type === 'tel' && 20 }}
              type={inputType}
            />
            {inputProps.type === 'tel' && <PhoneCode>+7</PhoneCode>}
          </div>

          {right && <SideWrapper onClick={dispatchRight}>{right}</SideWrapper>}
          {inputProps.type === 'password' && inputType === 'password' && (
            <SideWrapper onClick={() => setInputType('text')} style={{ cursor: 'pointer' }}>
              <EyeSVG fill={error ? colors.red : colors.primary} />
            </SideWrapper>
          )}
          {inputProps.type === 'password' && inputType === 'text' && (
            <SideWrapper onClick={() => setInputType('password')} style={{ cursor: 'pointer' }}>
              <EyeOffSVG fill={error ? colors.red : colors.primary} />
            </SideWrapper>
          )}
        </Wrapper>
        {error && <ErrorText>{error}</ErrorText>}
      </Container>
    )
  }
)
