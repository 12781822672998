import { toast } from 'react-custom-alert'
import { UseMutationOptions, useMutation } from 'react-query'

import { AxiosError } from 'axios'

import {
  AuthResponse,
  SendAuthCodeCommand,
  SendCodeResponse,
  SignInEmailCommand,
  SignInPhoneByCodeCommand,
  SignInPhoneByPasswordCommand,
  postApiAuthSendAuthCode,
  postApiAuthSignInEmail,
  postApiAuthSignInPhoneByCode,
  postApiAuthSignInPhoneByPassword,
} from '../../shared/api/generated'

export function useEmailSignInMutation(
  options?: UseMutationOptions<AuthResponse, unknown, SignInEmailCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SignInEmailCommand) => {
      return postApiAuthSignInEmail(data)
    },
    ...options,
  })
}
export function usePhoneSignInMutation(
  options?: UseMutationOptions<AuthResponse, unknown, SignInPhoneByPasswordCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SignInPhoneByPasswordCommand) => {
      return postApiAuthSignInPhoneByPassword(data)
    },
    ...options,
  })
}
export function useSMSSignInMutation(
  options?: UseMutationOptions<AuthResponse, unknown, SignInPhoneByCodeCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SendAuthCodeCommand) => {
      return postApiAuthSignInPhoneByCode(data)
    },

    ...options,
  })
}
export function useSendAuthSMSMutation(
  options?: UseMutationOptions<SendCodeResponse, unknown, SendAuthCodeCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SendAuthCodeCommand) => {
      return postApiAuthSendAuthCode(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      if (e.request?.status == 429) {
        toast.error('Слишком много запросов')
      } else {
        toast.error(e.response?.data?.message || 'Ошибка отправки СМС')
      }
    },
    onSuccess: () => {
      toast.success('СМС c кодом отправлена на ваш телефон')
    },
    ...options,
  })
}
