import React from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { ReviewListItemDto } from '../../../../shared/api/generated'
import { StarsRating } from '../../../../shared/ui-kit/actual/StarsRating'
import { breakpoints } from '../../../../shared/ui-kit/breakPoints'
import { Spacer } from '../../../../shared/ui-kit/deprecated/Spacer'
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  GoldStatus,
  MainRateBig,
  MainRateSmall,
  RateTablo,
  RateTabloWrapper,
  StatusBar,
  StatusTitle,
} from '../../../../shared/ui-kit/projectComponents'
import { getDateAgo } from '../../../../shared/utils/dateAgo'
import displayStringByAmount, {
  calculateStarAmount,
  calculateStarPercent,
} from '../../../../shared/utils/displayStringByAmount'

const ItemContent = styled(FlexColumn)`
  width: clamp(${breakpoints.mobileMin}, 100%, ${breakpoints.desktopMin});
  padding-bottom: 150px;
`
const BackButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const RateHeader = styled(FlexRowBetween)`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
`

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
`
const UserComment = styled.p`
  margin-left: 70px;
  text-align: left;
  width: calc(100% - 30px);
  padding: 0;
  font-size: 14px;
`
const UserDate = styled.p`
  font-size: 12px;
  color: #757575;
  margin: 0;
`
const UserName = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`
const UserRatingItem = styled(FlexColumn)`
  margin-top: 30px;
  width: calc(100% - 30px);
`

interface RatingPageProps {
  avgRate: number
  isSeller?: boolean
  itemData: ReviewListItemDto[]
  setOpenRating: any
}

export const MarketItemRates = ({
  avgRate,
  isSeller,
  itemData,
  setOpenRating,
}: RatingPageProps) => {
  return (
    <ItemContent>
      <RateHeader>
        <BackButton onClick={() => setOpenRating(false)}>
          <img
            alt={''}
            src={'/images/buttons/arrow-right-black.svg'}
            style={{ transform: 'rotate(180deg)', width: '100%' }}
          />
        </BackButton>
        <p style={{ textAlign: 'center', width: 'calc(100% + 24px)' }}>
          Отзывы на {isSeller ? 'продавца' : 'товар'}
        </p>
      </RateHeader>

      <Spacer />

      <FlexRowBetween style={{ alignItems: 'center', justifyContent: 'start' }}>
        <RateTabloWrapper>
          <RateTablo>
            <MainRateBig>{avgRate}</MainRateBig>
            <MainRateSmall>/ 5</MainRateSmall>
          </RateTablo>
          <div style={{ fontSize: 14 }}>
            {itemData.length} {displayStringByAmount('отзыв', itemData.length)}
          </div>
        </RateTabloWrapper>
        <FlexColumn>
          {[5, 4, 3, 2, 1].map((item, id) => (
            <FlexRow key={id} style={{ margin: 4 }}>
              <StarsRating rating={5 - id} />
              <StatusBar>
                <GoldStatus style={{ width: `${calculateStarPercent(5 - id, itemData)}px` }} />
              </StatusBar>
              <StatusTitle>{calculateStarAmount(5 - id, itemData)}</StatusTitle>
            </FlexRow>
          ))}
        </FlexColumn>
      </FlexRowBetween>
      {itemData.length > 0 &&
        itemData.map((item, idx) => (
          <UserRatingItem key={idx}>
            <FlexRowBetween style={{ alignItems: 'start' }}>
              <FlexRow style={{ alignItems: 'start' }}>
                <UserAvatar
                  alt={''}
                  src={item.buyerAvatarImageUrl || '/images/buttons/user-avatar.png'}
                />
                <FlexColumn style={{ alignItems: 'start' }}>
                  <UserName>{item.buyerFullName}.</UserName>
                  <FlexRow style={{ marginTop: 6 }}>
                    <StarsRating rating={item.rating} />
                  </FlexRow>
                </FlexColumn>
              </FlexRow>
              <UserDate>{getDateAgo(item.createOn)}</UserDate>
            </FlexRowBetween>
            <UserComment>{item.content}</UserComment>
          </UserRatingItem>
        ))}

      {/* <ReviewsList
        header={false}
        reviews={itemData}
        reviewsCount={itemData?.length || 0}
        userRating={avgRate}
      /> */}
    </ItemContent>
  )
}
