import { useGetReviewsListQuery } from '../entities/reviews/queries'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { ReviewComponent } from '../shared/ui-kit/deprecated/AdminComponents/ReviewComponent'
import { FlexRowBetween } from '../shared/ui-kit/projectComponents'
import { Head } from '../shared/ui-kit/titles'

const AdminReviewsPage = () => {
  const { data: allReviews, isLoading } = useGetReviewsListQuery({
    take: 10000,
  })
  const reviewsItems = allReviews?.items

  return (
    <div>
      <>
        <AbsoluteLoader isLoading={isLoading} />
        <div style={{ alignSelf: 'start' }}>
          <br />
          <ArrowBackButton />
        </div>
        <Head>Отзывы</Head>

        <FlexRowBetween
          style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}
        >
          <p style={{ alignSelf: 'flex-start', fontSize: 15, marginLeft: '15px' }}>
            Всего транзакций: {allReviews?.totalCount}
          </p>

          {/* <StyledButton onClick={resetTerms} size={'sm'}>
            Сбросить фильтр
          </StyledButton> */}
        </FlexRowBetween>
        {reviewsItems &&
          reviewsItems?.map(review => (
            <ReviewComponent key={`${review.createOn}+${review.authorId}`} review={review} />
          ))}
      </>
    </div>
  )
}

export default AdminReviewsPage
