import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  ApproveClaimCommand,
  GetClaimInfoQuery,
  GetClaimInfoResponse,
  GetClaimsListQuery,
  GetClaimsListResponse,
  RejectClaimCommand,
  postApiClaimsApproveClaim,
  postApiClaimsGetClaimInfo,
  postApiClaimsGetClaimsList,
  postApiClaimsRejectClaim,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useGetClaimsListQuery(
  data: GetClaimsListQuery,
  options?: UseQueryOptions<unknown, unknown, GetClaimsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки списка жалоб')
    },
    queryFn: () => postApiClaimsGetClaimsList(data),
    queryKey: ['get_claims_list', JSON.stringify(data)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGetClaimInfoQuery(
  data: GetClaimInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetClaimInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки жалобы')
    },
    queryFn: () => postApiClaimsGetClaimInfo(data),
    queryKey: ['get_claim', JSON.stringify(data)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useApproveClaimMutation(
  options?: UseMutationOptions<unknown, unknown, ApproveClaimCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ApproveClaimCommand) => {
      return postApiClaimsApproveClaim(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удовлетворения жалобы')
    },
    onSuccess: () => {
      toast.success('Жалоба удовлетворена')
      queryClient.invalidateQueries({ queryKey: ['get_claim'] })
      queryClient.invalidateQueries({ queryKey: ['get_claims_list'] })
    },
    ...options,
  })
}
export function useRejectClaimMutation(
  options?: UseMutationOptions<unknown, unknown, RejectClaimCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RejectClaimCommand) => {
      return postApiClaimsRejectClaim(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отклонения жалобы')
    },
    onSuccess: () => {
      toast.success('Жалоба отклонена')
      queryClient.invalidateQueries({ queryKey: ['get_claim'] })
      queryClient.invalidateQueries({ queryKey: ['get_claims_list'] })
    },
    ...options,
  })
}
