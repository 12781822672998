import styled from 'styled-components/macro'

import { useArticlesGetArticlesListQuery } from '../../../../entities/articles/queries'
import { ArticleCard, ArticleVariant } from '../../../../features/article'
import { breakpoints } from '../../breakPoints'
import { FlexColumn, FlexColumnStart, FlexRowWrap } from '../../projectComponents'
import { AbsoluteLoader } from './AbsoluteLoader'
import { MarketTitleComponent } from './MarketTitleComponent'

interface MarketUsefulArticlesProps {
  authorId?: string
  take?: number
  title?: string
  variant?: ArticleVariant
}
const CardsWrapper = styled(FlexRowWrap)`
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 36px;
  @media (${breakpoints.tabletMin} <= width <= ${breakpoints.desktopMin}) {
    align-self: center;
    justify-content: center;

    width: 330px;
  }
  @media (width < ${breakpoints.mobileMin}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const CardsBlock = styled(FlexColumn)`
  /* width: 100%; */
  align-self: center;
  justify-self: center;
`

export const MarketUsefulArticles = ({
  authorId,
  take,
  title,
  variant = ArticleVariant.Card,
}: MarketUsefulArticlesProps) => {
  const { data, isLoading } = useArticlesGetArticlesListQuery({
    authorId: authorId || null,
    take: take || 3,
  })

  return (
    <>
      {data?.items?.length > 0 && <MarketTitleComponent link={'/blog'} title={title} />}
      <AbsoluteLoader isLoading={isLoading} />
      <CardsBlock>
        <CardsWrapper>
          {data?.items?.length > 0 &&
            data?.items.map((item, idx) => (
              <ArticleCard item={item} key={item.id} variant={variant} />
            ))}
        </CardsWrapper>
      </CardsBlock>
    </>
  )
}
