import { GetProductInfoResponse, ProductListItem } from '../../../api/generated'

export const ExistedPrice = ({
  productInfo,
}: {
  productInfo: GetProductInfoResponse | ProductListItem
}) => {
  if (productInfo?.dayPrice) {
    return <>{productInfo?.dayPrice}₽ в день</>
  }
  if (productInfo?.threeDaysPrice) {
    return <>{productInfo?.threeDaysPrice}₽ за 3 дня</>
  }
  if (productInfo?.weekPrice) {
    return <>{productInfo?.weekPrice}₽ за неделю</>
  }
  if (productInfo?.monthPrice) {
    return <>{productInfo?.monthPrice}₽ за месяц</>
  } else {
    return null
  }
}
