import React, { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { ChatListItem as ChatListsItem } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { FlexColumnStart } from '../../../../shared/ui-kit/projectComponents'
import { deleteLastDoteInDate } from '../../../../shared/utils/deleteLastDote'

const Wrapper = styled(FlexColumnStart)`
  width: 100%;
`

const LinkWrap = styled(Link)`
  text-decoration: none;
  display: flex;
  gap: 16px;
  max-width: 861px;
  width: 100%;
`

const MessageImgWrapper = styled.div`
  max-width: 68px;
  max-height: 87px;
  position: relative;
`

const MessageImg = styled.img`
  width: 62px;
  height: 81px;
  object-fit: cover;
  border-radius: 6px;
`

const UserAvatarWrapper = styled.div`
  position: absolute;
  top: -6px;
  left: -6px;
  background: ${colors.white};
  padding: 2.5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProfileImg = styled.img`
  width: 21px;
  height: 21px;
  object-fit: cover;
  border-radius: inherit;
`

const MessageBody = styled.div`
  width: 100%;
`

const MessageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  flex: 1;
`

const MessageUserName = styled.h4`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  color: ${colors.black};
  text-align: left;
`

const MessageDate = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ababab;
  text-align: right;
`

const MessageProductDescription = styled.div`
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 4%;
  line-height: 24px;
  color: ${colors.grey};
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

const MessageProductName = styled.p`
  margin: 0;

  @media all and (max-width: 800px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
  }
`

const MessageProductPrice = styled.span`
  color: ${colors.primary};
`

const ShortMessage = styled.p`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 4%;
  line-height: 19px;
  color: #ababab;
  max-width: 600px;
  margin: 2px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media all and (max-width: 1100px) {
    width: 189px;
  }
`

interface MessageItemProps {
  message: ChatListsItem
  userId: number | string
}

export const MessageItem = memo(({ message, userId }: MessageItemProps): React.JSX.Element => {
  const currentUserIsBuyer = useMemo(() => message?.buyerId === userId, [message.buyerId, userId])
  const displayName = useMemo(
    () => (currentUserIsBuyer ? message?.sellerFullName : message?.buyerFullName),
    [currentUserIsBuyer, message.sellerFullName, message.buyerFullName]
  )
  const messageDate = useMemo(
    () => deleteLastDoteInDate(message?.lastMessage?.messageDate) || '...',
    [message?.lastMessage?.messageDate]
  )

  return (
    <Wrapper>
      <LinkWrap to={`/messages/${message?.rentId}`}>
        <MessageImgWrapper>
          <MessageImg src={message.productImagesUrls[0]} />
          <UserAvatarWrapper>
            <ProfileImg
              src={message?.lastMessage?.senderAvatarUrl || '/images/bottom-menu/profile-light.svg'}
            />
          </UserAvatarWrapper>
        </MessageImgWrapper>
        <MessageBody>
          <MessageTitleWrapper>
            <MessageUserName>{displayName}</MessageUserName>
            <MessageDate>{messageDate}</MessageDate>
          </MessageTitleWrapper>
          <MessageProductDescription>
            <MessageProductName>{message?.productName}</MessageProductName>
            <MessageProductPrice>
              {message?.productPledge.toLocaleString('ru-RU')}₽
            </MessageProductPrice>
          </MessageProductDescription>
          <ShortMessage>{message?.lastMessage?.message || 'Сообщений пока нет'}</ShortMessage>
        </MessageBody>
      </LinkWrap>
    </Wrapper>
  )
})
