import styled from 'styled-components/macro'

interface MessageStatusProps {
  messageStatus?: boolean
}

const SentIcon = styled.img`
  width: 14px;
`

const MessageStatus = ({ messageStatus }: MessageStatusProps) => {
  return (
    <>
      {messageStatus && <SentIcon src={'/images/chat/MessageStatusSent.svg'} />}
      {!messageStatus && <SentIcon src={'/images/chat/MessageStatusRead.svg'} />}
    </>
  )
}

export default MessageStatus
