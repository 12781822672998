import { ComponentPropsWithoutRef } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'

const CheckboxWrapper = styled.div`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  .cbx {
    position: relative;
    display: block;
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid ${colors.primary} 2px;
    background-color: ${colors.white};
    /* background-image: linear-gradient(${colors.primary}, #00b9b9); */
    box-shadow:
      inset 0 1px 1px rgba(255, 255, 255, 0.15),
      inset 0 -1px 1px rgba(0, 0, 0, 0.15);
    transition: all 0.15s ease;
  }
  .cbx svg {
    position: absolute;
    top: 3px;
    left: 3px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 17;
    stroke-dashoffset: 17;
    transform: translate3d(0, 0, 0);
  }
  .cbx + span {
    float: left;
    margin-left: 6px;
  }
  user-select: none;

  label {
    display: inline-block;
    cursor: pointer;
  }
  input[type='checkbox'] {
    display: none;
    visibility: hidden;
    padding: 15px;
  }
  input[type='checkbox']:checked + .cbx {
    background-color: #00b9b9;
    background-image: linear-gradient(#00b9b9, ${colors.primary});
    border: none;
  }
  input[type='checkbox']:checked + .cbx svg {
    stroke-dashoffset: 0;
    transition: all 0.15s ease;
  }
`

interface CheckboxInputProps extends ComponentPropsWithoutRef<'input'> {}

export const StyledCheckbox = ({ checked, onChange }: CheckboxInputProps) => {
  return (
    <CheckboxWrapper>
      <label>
        <input checked={checked} onChange={onChange} type={'checkbox'} />
        <span className={'cbx'}>
          <svg height={'11px'} viewBox={'0 0 12 11'} width={'12px'}>
            <polyline points={'1 6.29411765 4.5 10 11 1'}></polyline>
          </svg>
        </span>
      </label>
    </CheckboxWrapper>
  )
}
