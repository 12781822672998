import React, { useEffect, useMemo, useState } from 'react'
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import { UseMutationResult } from 'react-query'

import { differenceInYears } from 'date-fns'
import styled from 'styled-components/macro'

import { AuthResponse, LegalStatus, SignUpCommand } from '../../../../shared/api/generated'
import {
  LEGAL_STATUSES_OPTIONS,
  SelectLabelItem,
} from '../../../../shared/constants/legalStatusList'
import {
  FULL_NAME_VALIDATION,
  NAME_VALIDATION,
  NO_CYRILLIC,
  PASSWORD_VALIDATION,
} from '../../../../shared/constants/regex'
import { CITY_NAMES } from '../../../../shared/constants/russiaCities'
import {
  invalidPassword,
  passwordMax,
  passwordMin,
  passwordRequired,
} from '../../../../shared/constants/validationMessages'
import { StyledSelect } from '../../../../shared/ui-kit/SelectWrapper'
import { TextArea } from '../../../../shared/ui-kit/actual/TextArea'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { FlexColumnStart, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { RegisterTitle } from './../headers/RegisterTitle'

const Wrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 800px);
  margin: 0 auto;
`
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 24px;
  width: 100%;
`

type Props = {
  clearErrors: UseFormClearErrors<
    {
      passwordConfirm: string
    } & SignUpCommand
  >
  errors: FieldErrors<{ passwordConfirm: string } & SignUpCommand>
  getValues: UseFormGetValues<{ passwordConfirm: string } & SignUpCommand>
  handleSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>
  isValid: boolean
  register: UseFormRegister<{ passwordConfirm: string } & SignUpCommand>
  setValue: UseFormSetValue<{ passwordConfirm: string } & SignUpCommand>
  signUpMutation: UseMutationResult<AuthResponse, unknown, SignUpCommand, unknown>
}
export const FourthStep = ({
  clearErrors,
  errors,
  getValues,
  handleSubmit,
  isValid,
  register,
  setValue,
  signUpMutation,
}: Props) => {
  const [legalStatus, setLegalStatus] = useState<SelectLabelItem>({
    label: 'Юридический статус',
    value: '1',
  })
  const [birthDateView, setBirthDateView] = useState(false)
  const handleTypeSelect = e => {
    setLegalStatus({ label: e.label, value: e.value })
    setValue('legalStatus', e.value as LegalStatus)
    if (e.value === 'legalEntity') {
      setBirthDateView(false)
      setValue('birthDate', null)
    } else {
      setBirthDateView(true)
    }
  }

  const cities: SelectLabelItem[] = useMemo(
    () => CITY_NAMES.map(city => ({ label: city, value: city })),
    []
  )
  const [cityValue, setCityValue] = useState<SelectLabelItem>({
    label: 'Укажите город',
    value: 'city',
  })

  const setCity = e => {
    setCityValue({ label: e.label, value: e.value })
    setValue('city', e.value)
  }

  console.log(errors)

  return (
    <Wrapper>
      <RegisterTitle title={'Личные данные'} />
      <FormWrapper onSubmit={handleSubmit}>
        <TextField
          inputProps={{
            placeholder: 'Придумайте индивидуальный никнейм',
            type: 'text',
            ...register('userName', {
              maxLength: {
                message: 'Максимум 18 символов',
                value: 18,
              },
              minLength: {
                message: 'Минимум 2 символа',
                value: 2,
              },
              pattern: {
                message: 'Только латинские буквы и цифры',
                value: NAME_VALIDATION,
              },
              required: 'Поле никнейм обязательно для заполнения',
            }),
          }}
          error={errors.userName?.message?.toString()}
          label={'Введите Никнейм, используйте латинские буквы'}
          fullwidth
        />
        <StyledSelect
          classNamePrefix={'Select'}
          noOptionsMessage={() => <div>Не найдено</div>}
          onChange={handleTypeSelect}
          options={LEGAL_STATUSES_OPTIONS}
          value={legalStatus}
        />
        <TextField
          inputProps={{
            placeholder:
              getValues('legalStatus') === 'legalEntity'
                ? 'Название организации с формой собственности (например "ООО Рассвет")'
                : 'Полное имя',
            type: 'text',
            ...register('fullName', {
              maxLength: {
                message: 'Максимум 50 символов',
                value: 50,
              },
              minLength: {
                message: 'Минимум 2 символов',
                value: 2,
              },
              pattern: {
                message: 'Полное имя должно содержать только буквы',
                value: FULL_NAME_VALIDATION,
              },
              required: 'Полное имя обязательно для заполнения',
            }),
          }}
          label={
            getValues('legalStatus') === 'legalEntity'
              ? 'Введите название организации с формой собственности (например "ООО Рассвет")'
              : 'Введите полное имя'
          }
          error={errors.fullName?.message?.toString()}
          fullwidth
        />
        <TextArea
          inputProps={{
            placeholder: 'О себе или об организации',
            ...register('description', {}),
          }}
          fullwidth
        />
        {birthDateView && (
          <TextField
            inputProps={{
              placeholder: 'Дата рождения',
              type: 'date',
              ...register('birthDate', {
                required: 'Дата рождения обязательна',
                validate: date => {
                  const age = differenceInYears(new Date(), new Date(date))

                  if (age < 18) {
                    return 'Для пользователей старше 18 лет'
                  } else if (age > 100) {
                    return 'Не старше 100 лет'
                  }
                },
              }),
            }}
            error={errors.birthDate?.message?.toString()}
            label={'Дата рождения'}
            fullwidth
          />
        )}
        <StyledSelect
          classNamePrefix={'Select'}
          noOptionsMessage={() => <div>Не найдено</div>}
          onChange={setCity}
          options={cities}
          value={cityValue}
        />
        <TextField
          inputProps={{
            placeholder: 'Пароль',
            type: 'password',
            ...register('password', {
              minLength: {
                message: passwordMin,
                value: 6,
              },
              pattern: {
                message: invalidPassword,
                value: PASSWORD_VALIDATION,
              },
              required: passwordRequired,
              validate: value => {
                if (value.match(NO_CYRILLIC)) {
                  clearErrors('password')

                  return
                }

                return invalidPassword
              },
            }),
          }}
          error={errors.password?.message?.toString()}
          label={'Введите пароль'}
          fullwidth
        />
        <TextField
          inputProps={{
            placeholder: 'Подтверждение пароля',
            type: 'password',
            ...register('passwordConfirm', {
              validate: value => value === getValues('password') || 'Пароли должны совпадать',
            }),
          }}
          error={errors.passwordConfirm?.message?.toString()}
          label={'Подтвердите пароль'}
          fullwidth
        />
        <StyledButton disabled={!isValid || signUpMutation.isLoading} fullwidth>
          Регистрация
        </StyledButton>
      </FormWrapper>
    </Wrapper>
  )
}
