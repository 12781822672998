import { useCallback, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { RentList } from '../../../../features/incoming-rents'
import Breadcrumbs from '../../../../features/market/ui/sections/BreadCrumbs'
import { MessageList, MessageProfileCard } from '../../../../features/messages'
import { colors } from '../../../../shared/constants/colors'
import { useModal } from '../../../../shared/hooks/useModal'
import { BottomMenuComponent } from '../../../../shared/ui-kit/actual/BottomMenuComponent'
import { CalendarDatePicker } from '../../../../shared/ui-kit/actual/CalendarDatePicker'
import { MainHeader } from '../../../../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../../../../shared/ui-kit/actual/layouts/BasePageLayout'
import { MessageLayout } from '../../../../shared/ui-kit/actual/layouts/MessageLayout'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import { RightModal } from '../../../../shared/ui-kit/actual/modals/RightModal'
import { MainMenuWindow } from '../../../../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import { devices } from '../../../../shared/ui-kit/breakPoints'
import {
  BaseWrapper,
  DesktopView,
  FlexColumn,
  FlexRowBetween,
  ImgButton,
  LoginText,
  MobileTabletView,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { dateToday } from '../../../../shared/utils/dateToday'
import { MessageHeader } from '../MessageHeader/MessageHeader'
import { MessageTabs } from '../MessageTabs/MessageTabs'

const LayoutWrapper = styled(FlexColumn)`
  margin-top: 36px;
  overflow-y: scroll;
  width: 100%;
  box-sizing: border-box;
  @media screen and (${devices.desktopMin}) {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    wrap: no-wrap;
    gap: 53px;
    align-items: start;
    justify-content: space-between;
  }
`

const FinishRentModalWrapper = styled.div`
  /* height: 425px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const FinishRentModalIcon = styled.img`
  width: 45px;
  height: 45px;
`

const FinishRentModalTitle = styled.h4`
  margin: 20px 0 8px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${colors.black};
`

const FinishRentModalDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: ${colors.grey};
  max-width: 208px;
  text-align: center;
`

const ActionBtn = styled(StyledButton)`
  display: none;
  @media all and (max-width: 480px) {
    display: block;
    max-width: 327px;
    width: 100%;
    border-radius: 10px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 550px;
  }
`

export const MessagePage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = Number(searchParams.get('activeTab'))
  const setActiveTab = useCallback(
    (activeTab: number) => {
      setSearchParams({ activeTab: String(activeTab) })
    },
    [setSearchParams]
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const toggleModal = useCallback(() => {
    setIsModalOpen(prev => !prev)
  }, [])

  const leftHeaderContent = useMemo(
    () => (
      <Link to={'/'}>
        <img alt={'home-link'} src={'/images/allsharinglogo.svg'} width={39} />
      </Link>
    ),
    []
  )

  const rightHeaderContent = useMemo(
    () => <ImgButton alt={''} onClick={toggleModal} src={'/images/buttons/menu-button.svg'} />,
    [toggleModal]
  )

  const headContent = useMemo(
    () => <MessageTabs activeTab={activeTab} setActiveTab={setActiveTab} />,
    [activeTab, setActiveTab]
  )

  const [startDateString, setStartDateString] = useState(dateToday())
  const [endDateString, setEndDateString] = useState(dateToday())
  const [intervalLength, setIntervalLength] = useState(0)
  const { setViewModal, viewModal } = useModal()
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState({ img: '', message: '', title: '' })

  return (
    <>
      <MobileTabletView>
        <MessageHeader
          center={<LoginText>Чаты</LoginText>}
          left={leftHeaderContent}
          right={rightHeaderContent}
        />
        <BottomMenuComponent />
        <MessageLayout
          body={
            activeTab === 0 ? (
              <MessageList />
            ) : (
              <RentList
                endDateString={'2030-01-01'}
                setIsSecondModalOpen={setIsSecondModalOpen}
                setModal={setViewModal}
                setModalMessage={setModalMessage}
                startDateString={startDateString}
              />
            )
          }
          head={headContent}
        />
        <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
          <MainMenuWindow handleModalClose={handleModalClose} />
        </RightModal>
      </MobileTabletView>

      <DesktopView>
        <BasePageLayout>
          <MainHeader />
          <BaseWrapper>
            <FlexRowBetween style={{ alignItems: 'center' }}>
              <Breadcrumbs />
            </FlexRowBetween>
            <LayoutWrapper>
              <div>
                <div style={{ marginBottom: '32px', width: '330px' }}>
                  <MessageTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                </div>
                {activeTab === 0 ? (
                  <MessageProfileCard />
                ) : (
                  <CalendarDatePicker
                    setEndDateString={setEndDateString}
                    setIntervalLength={setIntervalLength}
                    setStartDateString={setStartDateString}
                    isSuccess
                  />
                )}
              </div>
              {activeTab === 0 ? (
                <MessageList />
              ) : (
                <RentList
                  endDateString={endDateString}
                  setIsSecondModalOpen={setIsSecondModalOpen}
                  setModal={setViewModal}
                  setModalMessage={setModalMessage}
                  startDateString={startDateString}
                />
              )}
            </LayoutWrapper>
          </BaseWrapper>
        </BasePageLayout>
      </DesktopView>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        Принят заказ
      </BaseModal>
      <BaseModal setViewModal={setIsSecondModalOpen} viewModal={isSecondModalOpen}>
        <FinishRentModalWrapper>
          <FinishRentModalIcon src={modalMessage.img} />
          <FinishRentModalTitle>{modalMessage.title}</FinishRentModalTitle>
          <FinishRentModalDescription>{modalMessage.message}</FinishRentModalDescription>
          <ActionBtn onClick={() => setIsSecondModalOpen(false)}>К заказам</ActionBtn>
        </FinishRentModalWrapper>
      </BaseModal>
    </>
  )
}
