import { useState } from 'react'

import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'

import { RentListItem } from '../../../shared/api/generated'
import { NoProducts } from '../../../shared/ui-kit/actual/NoProducts'
import { devices } from '../../../shared/ui-kit/breakPoints'
import { FlexRow } from '../../../shared/ui-kit/projectComponents'
import { dateToString } from '../../../shared/utils/dateToString'
import { OrderCard } from './OrderCard'

const mockOrders = {
  dayPrice: 0,
  image: '/images/mock-items/mock-image-7.jpg',
  imageIcon: '/images/mock-items/mock-brand-1.png',
  imagesIds: ['3fa85f64-5717-4562-b3fc-2c963f66afa6'],
  interval: '12-14 декабря',
  isPremium: true,
  monthPrice: 0,
  name: 'Bershka Mom Jeans',
  numberOfMessages: 2,
  pledge: 10000,
  price: 1500,
  productId: nanoid(),
  threeDaysPrice: 0,
  weekPrice: 0,
}

// const mockOrders = null
export const OrdersWrapper = styled(FlexRow)`
  gap: 24px;
  flex-wrap: wrap;
  @media screen and (${devices.desktopMin}) {
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 56px;
  }
`
export type Direction = 'receive' | 'send'
type Props = {
  active: RentListItem[]
  direction: Direction
}
export const CurrentOrders = ({ active, direction }: Props) => {
  const [selectedOrders, setSelectedOrders] = useState<number[]>([])
  const handleCheckbox = (id: number) => {
    if (selectedOrders.includes(id)) {
      setSelectedOrders(selectedOrders.filter(orderId => orderId !== id))
    } else {
      setSelectedOrders([...selectedOrders, id])
    }
  }

  return (
    <OrdersWrapper>
      {active.length ? (
        <>
          {active.map((order, idx) => (
            <OrderCard
              direction={direction}
              handleCheckbox={() => handleCheckbox(order.rentId)}
              image={mockOrders.image}
              imageIcon={mockOrders.imageIcon}
              interval={dateToString(order.periodFrom) + ' - ' + dateToString(order.periodTo)}
              isSelected={selectedOrders.includes(order.rentId)}
              key={order.rentId}
              name={order.productName}
              numberOfMessages={order.unreadMessagesCount}
              pledge={order.pledge}
              price={order.price}
              productId={order.productId}
              productImageUrl={order.productImagesUrls[0]}
              rentId={order.rentId}
              sellerImageUrl={order.sellerImageUrl}
            />
          ))}
        </>
      ) : (
        <NoProducts imgPath={'/images/interface/EmptyBag.svg'} message={'У вас еще нет заказов'} />
      )}
    </OrdersWrapper>
  )
}
