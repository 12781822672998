import { ReviewListItemDto } from '../../../shared/api/generated'
import { ProfileSwitchTabs } from '../../../shared/ui-kit/actual/ProfileSwitchTabs'
import { ItemRates } from '../../../shared/ui-kit/actual/components/ItemRates'
import { FlexColumnStart } from '../../../shared/ui-kit/projectComponents'

type Props = {
  activeTab: number
  reviews: ReviewListItemDto[]
  setActiveTab: (activeTab: number) => void
}

export const UserReviewDesktopHead = ({ activeTab, reviews, setActiveTab }: Props) => {
  return (
    <FlexColumnStart style={{ gap: '32px' }}>
      <ProfileSwitchTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabHead0={'Арендатор'}
        tabHead1={'Арендодатель'}
      />
      {!activeTab ? <ItemRates reviews={reviews} /> : <ItemRates reviews={reviews} />}
    </FlexColumnStart>
  )
}
