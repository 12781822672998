import React from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { IDepositResponse } from '../../../api/deprecated/paymentService/PaymentService'
import { colors } from '../../../constants/colors'
import { symbols } from '../../../constants/symbols'
import { FlexColumn, FlexRow } from '../../projectComponents'

const Block = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  justify-content: start;
  height: 140px;
  p {
    font-size: 15px;
    margin: 0;
    text-align: start;
    width: 100%;
  }
`
const UserAvatar = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 100%;
  margin-right: 30px;
`

interface OperationComponentProps {
  operation: IDepositResponse
}
export const OperationComponent: React.FC<OperationComponentProps> = ({ operation }) => {
  const getDate = (date: string) => format(new Date(date), 'dd MMMM HH:mm', { locale: ru })

  return (
    <>
      <Block>
        <FlexRow style={{ justifyContent: 'start', width: '100%' }}>
          <UserAvatar
            alt={''}
            src={operation.user.avatarLink || '/images/buttons/user-avatar.png'}
          />
          <FlexColumn>
            <p
              style={{
                color: operation.isDeposit ? colors.greenSuccess : colors.redFail,
                fontWeight: 500,
              }}
            >
              {!operation.isDeposit && '- '}
              {operation.amount} {symbols.ruble}
            </p>
            <p style={{ fontSize: 14, marginTop: 6 }}>
              {operation.user.firstName} {operation.user.lastName}
            </p>
            <p style={{ fontSize: 14, marginTop: 6 }}>Создано: {getDate(operation.createdAt)}</p>
            <p style={{ fontSize: 14 }}>Исполнено: {getDate(operation.createdAt)}</p>
          </FlexColumn>
        </FlexRow>
        <p style={{ fontSize: 12, marginTop: 15 }}>user ID: {operation.id}</p>
        <p style={{ fontSize: 12 }}>yookassa ID: {operation.yookassaId}</p>
      </Block>
    </>
  )
}
