import { useContext } from 'react'

import styled from 'styled-components/macro'

import { ReviewListItemDto } from '../../../shared/api/generated'
import { colors } from '../../../shared/constants/colors'
import { primaryStarSVG } from '../../../shared/constants/images'
import ProfileStorage from '../../../shared/storage/ProfileStorage/ProfileStorage'
import { ItemRates } from '../../../shared/ui-kit/actual/components/ItemRates'
import { ButtonColumn } from '../../../shared/ui-kit/actual/components/buttonColumn'
import {
  BtnRowWrapper,
  BtnWrapper,
  FlexColumnStart,
} from '../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../shared/ui-kit/titles'

const ContentWrapper = styled(FlexColumnStart)`
  gap: 8px;
  margin-bottom: 24px;
`

const RatesWrapper = styled(FlexColumnStart)`
  gap: 24px;
  margin-bottom: 32px;
  margin-top: 32px;
`
const Subtitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.grey};
`

interface MyReviewsComponentProps {
  buyerRating: number
  buyerReviews: ReviewListItemDto[]
  sellerRating: number
  sellerReviews: ReviewListItemDto[]
  userName?: string
}

const UserReviewsComponentHeader = ({
  buyerRating,
  buyerReviews,
  sellerRating,
  sellerReviews,
  userName,
}: MyReviewsComponentProps) => {
  return (
    <FlexColumnStart>
      <ContentWrapper>
        {userName ? <H3>Рейтинг пользователя {userName}</H3> : <H3>Ваш рейтинг</H3>}
        {!userName && (
          <Subtitle>
            Рейтинг составляется на основе оценок и отзывов полученных в ходе ваших заказов. Чем вы
            вежливее и аккуратнее, тем выше ваш рейтинг
          </Subtitle>
        )}
      </ContentWrapper>
      <BtnWrapper>
        <BtnRowWrapper>
          <ButtonColumn
            top={
              <>
                {' '}
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{buyerRating}
              </>
            }
            bottom={<>Арендатор</>}
            isHeight
          />
          <ButtonColumn
            top={
              <>
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{sellerRating}
              </>
            }
            bottom={<>Арендодатель</>}
            isHeight
          />
        </BtnRowWrapper>
      </BtnWrapper>
      <RatesWrapper>
        <H3>Отзывы и оценки</H3>
        <ItemRates reviews={[...(buyerReviews || []), ...(sellerReviews || [])]} />
      </RatesWrapper>
    </FlexColumnStart>
  )
}

export default UserReviewsComponentHeader
