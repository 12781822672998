import React, { useContext, useState } from 'react'

import { formatDistance } from 'date-fns'
import styled from 'styled-components/macro'

import { ReviewListItemDto } from '../../../shared/api/generated'
import { colors } from '../../../shared/constants/colors'
import { primaryStarSVG } from '../../../shared/constants/images'
import ProfileStorage from '../../../shared/storage/ProfileStorage/ProfileStorage'
import { ItemRates } from '../../../shared/ui-kit/actual/components/ItemRates'
import { ButtonColumn } from '../../../shared/ui-kit/actual/components/buttonColumn'
import {
  BtnRowWrapper,
  BtnWrapper,
  FlexColumnStart,
} from '../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../shared/ui-kit/titles'
import UserReviewsComponentHeader from './UserReviewsComponentHeader'
import UserReviewsWithSwitchTabs from './UserReviewsWithSwitchTabs'

const ContentWrapper = styled(FlexColumnStart)`
  gap: 8px;
  margin-bottom: 24px;
`

const RatesWrapper = styled(FlexColumnStart)`
  gap: 24px;
  margin-bottom: 32px;
  margin-top: 32px;
`
const Subtitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.grey};
`

interface MyReviewsComponentProps {
  buyerRating: number
  buyerReviews: ReviewListItemDto[]
  sellerRating: number
  sellerReviews: ReviewListItemDto[]
  userName?: string
}

const MyReviewsComponent = ({
  buyerRating,
  buyerReviews,
  sellerRating,
  sellerReviews,
  userName,
}: MyReviewsComponentProps) => {
  // TODO: добавить аватар после бэкенда, добавить фотки после бэкенда?
  // TODO: проверить работоспособность после добавления отзывов на бэке
  // TODO: добавить пагинацию по требованию

  return (
    <FlexColumnStart>
      <UserReviewsComponentHeader
        buyerRating={buyerRating}
        buyerReviews={buyerReviews}
        sellerRating={sellerRating}
        sellerReviews={sellerReviews}
        userName={userName}
      />
      <UserReviewsWithSwitchTabs buyerReviews={buyerReviews} sellerReviews={sellerReviews} />
    </FlexColumnStart>
  )
}

export default MyReviewsComponent
