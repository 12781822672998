import React, { useMemo, useState } from 'react'
import { FieldErrors, UseFormSetValue } from 'react-hook-form'

import styled from 'styled-components/macro'

import {
  AddUserAddressCommand,
  PostApiProductsCreateProductBody,
} from '../../../../shared/api/generated'
import { SelectLabelItem } from '../../../../shared/constants/legalStatusList'
import { CITY_NAMES } from '../../../../shared/constants/russiaCities'
import { StyledSelect } from '../../../../shared/ui-kit/SelectWrapper'
import { CitiesField } from '../../../../shared/ui-kit/actual/CitiesField'
import { CheckBox } from '../../../../shared/ui-kit/actual/checkbox'
import { FlexRow } from '../../../../shared/ui-kit/projectComponents'
import { H2, H4 } from '../../../../shared/ui-kit/titles'
const Wrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    max-width: 100%;
  }
`

const NewAddressSection = styled.div`
  position: relative;
`

type Props = {
  checkedAllRussia: boolean
  errors: FieldErrors<PostApiProductsCreateProductBody>
  selectedCities: string[]
  setCheckedAllRussia: React.Dispatch<React.SetStateAction<boolean>>
  setDisabledBtn: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedCities: React.Dispatch<React.SetStateAction<string[]>>
  setValue: UseFormSetValue<PostApiProductsCreateProductBody>
  submitAddress: (data: AddUserAddressCommand) => Promise<void>
}
export const AddItemDelivery = ({
  checkedAllRussia,
  errors,
  selectedCities,
  setCheckedAllRussia,
  setDisabledBtn,
  setSelectedCities,
  setValue,
  submitAddress,
}: Props) => {
  const cities: SelectLabelItem[] = useMemo(
    () => CITY_NAMES.map(city => ({ label: city, value: city })),
    []
  )
  const [cityValue, setCityValue] = useState<SelectLabelItem>({
    label: 'Выберите города доставки',
    value: 'city',
  })

  const setCity = e => {
    setCityValue({ label: e.label, value: e.value })
    const newSelectedCities = [...selectedCities]

    if (!selectedCities.includes(e.value)) {
      newSelectedCities.push(e.value)
      setSelectedCities(newSelectedCities)
    }

    setValue('DeliveryCities', newSelectedCities)
    setValue('DeliveryType', 'countryWithSelection')
  }
  const deleteCity = city => {
    setCityValue({ label: city, value: city })
    const newSelectedCities = selectedCities.filter(c => c !== city)

    setSelectedCities(newSelectedCities)

    setValue('DeliveryCities', newSelectedCities)
    setValue('DeliveryType', 'countryWithSelection')
  }

  const handleSetCheckedAllRussia = () => {
    setCheckedAllRussia(prev => {
      if (!prev) {
        setValue('DeliveryType', 'russiaAny')
        setSelectedCities([])
        setValue('DeliveryCities', [])
        setCityValue({
          label: 'Выберите города доставки',
          value: 'city',
        })
      } else {
        setValue('DeliveryType', 'notAvailable')
      }

      return !prev
    })
  }

  return (
    <Wrapper>
      <H2>Доставка</H2>

      <FlexRow style={{ gap: '16px', justifyContent: 'flex-start' }}>
        <CheckBox checked={checkedAllRussia} setChecked={handleSetCheckedAllRussia} />
        <H4 style={{ margin: 0 }}>Доставка по всей России</H4>
      </FlexRow>

      <NewAddressSection>
        <StyledSelect
          classNamePrefix={'Select'}
          isDisabled={checkedAllRussia}
          noOptionsMessage={() => <div>Не найдено</div>}
          onChange={setCity}
          options={cities}
          value={cityValue}
        />
        <br />
        <CitiesField
          cities={selectedCities}
          deleteCity={deleteCity}
          setSelectedCities={setSelectedCities}
        />
      </NewAddressSection>
    </Wrapper>
  )
}
