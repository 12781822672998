import React from 'react'

import { FilterByCategoriesBodyWrapper } from '../entities/filterByCategories'
import FilterCategoriesHeader from '../entities/filterByCategories/ui/head/BlogFilterHeader'
import BottomButtonComponent from '../shared/ui-kit/actual/BottomButtonComponent'
import PageLayout from '../shared/ui-kit/actual/layouts/PageLayout'

const BlogFilterPage: React.FC = () => {
  return (
    <PageLayout
      body={<FilterByCategoriesBodyWrapper />}
      footer={<BottomButtonComponent>Показать 115 статей</BottomButtonComponent>}
      head={<FilterCategoriesHeader />}
    />
  )
}

export default BlogFilterPage
