import { css } from 'styled-components/macro'
export const breakpoints = {
  desktopMax: '1600px',
  desktopMiddle: '1280px',
  desktopMin: '800px',
  mobileMax: '449px',
  mobileMin: '320px',
  tabletMax: '799px',
  tabletMin: '450px',
}

export const devices = {
  desktopMax: `(width >= ${breakpoints.desktopMax})`,
  desktopMiddle: `(width >= ${breakpoints.desktopMiddle})`,
  desktopMin: `(width >= ${breakpoints.desktopMin})`,
  mobileMax: `(width >= ${breakpoints.mobileMax})`,
  mobileMin: `(width >=  ${breakpoints.mobileMin})`,
  tabletMax: `(width >= ${breakpoints.tabletMax})`,
  tabletMin: `(width >=  ${breakpoints.tabletMin})`,
}

export const desktopMiddleView = css`
  @media (width < ${breakpoints.desktopMiddle}) {
    display: none;
  }
`
export const mobileTabletDesktopMinView = css`
  @media (width >= ${breakpoints.desktopMiddle}) {
    display: none;
  }
`
export const desktopView = css`
  @media (width < ${breakpoints.desktopMin}) {
    display: none;
  }
`
export const tabletDesktopView = css`
  @media (width < ${breakpoints.tabletMin}) {
    display: none;
  }
`
export const mobileTabletView = css`
  @media (width >= ${breakpoints.desktopMin}) {
    display: none;
  }
`
export const tabletOnlyView = css`
  @media (width >= ${breakpoints.desktopMin}) {
    display: none;
  }
  @media (width <= ${breakpoints.tabletMin}) {
    display: none;
  }
`
export const mobileView = css`
  @media (width >= ${breakpoints.tabletMin}) {
    display: none;
  }
`
