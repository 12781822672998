import { useSearchParams } from 'react-router-dom'

export const useQuerySteps = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const step = Number(searchParams.get('step'))
  const setStep = (value?: number) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    if (value) {
      updatedSearchParams.set('step', String(value))
    } else {
      updatedSearchParams.set('step', '0')
    }
    setSearchParams(updatedSearchParams.toString())
  }

  return { setStep, step }
}
