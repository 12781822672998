import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { GetRentInfoResponse, ReviewType } from '../../../../shared/api/generated'
import { FlexColumn, MediumTextL, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'
import { FeedbackForm } from '../FeedbackForm'

type Props = {
  back: () => void
  rentInfo: GetRentInfoResponse
}

export const Step9 = ({ back, rentInfo }: Props) => {
  const [rating, setRating] = useState(0)
  const [ratingGoods, setRatingGoods] = useState(0)
  const [comment, setComment] = useState('')
  const [commentGoods, setCommentGoods] = useState('')
  const { rentId } = useParams()
  const rentIdNumber = parseInt(rentId)

  return (
    <FlexColumn>
      <img
        alt={''}
        src={'/images/buttons/check-white-on-bg.svg'}
        style={{ height: 40, marginTop: '100px', width: 40 }}
      />
      <br />
      <H2>Все в порядке</H2>
      <MediumTextL>Товар успешно возвращен арендодателю</MediumTextL>
      <br />
      {!rentInfo.hasReviewAboutSeller && (
        <>
          <H2 style={{ marginBottom: '16px', textAlign: 'center' }}>Оцените арендодателя</H2>
          <FeedbackForm
            comment={comment}
            rating={rating}
            rentId={rentIdNumber}
            reviewType={ReviewType.aboutSeller}
            setComment={setComment}
            setRating={setRating}
          />
        </>
      )}
      {!rentInfo.hasReviewAboutProduct && (
        <>
          <H2 style={{ marginBottom: '16px', textAlign: 'center' }}>Оцените товар</H2>
          <FeedbackForm
            comment={commentGoods}
            rating={ratingGoods}
            rentId={rentIdNumber}
            reviewType={ReviewType.aboutProduct}
            setComment={setCommentGoods}
            setRating={setRatingGoods}
          />
        </>
      )}
      <StyledButton onClick={back} fullwidth>
        На главную
      </StyledButton>
    </FlexColumn>
  )
}
