import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { ArticleListItemDto, GetArticleInfoResponse } from '../../../../shared/api/generated'
import { devices } from '../../../../shared/ui-kit/breakPoints'
import {
  DesktopView,
  FlexRow,
  FlexRowGap,
  MobileTabletView,
} from '../../../../shared/ui-kit/projectComponents'
import { shortString } from '../../../../shared/utils/truncate'

export enum ArticleVariant {
  Banner = 'banner',
  Card = 'card',
  Long = 'long',
}

const ArticleCardWrapper = styled(FlexRowGap)<{ variant?: ArticleVariant }>`
  width: ${props => (props.variant === ArticleVariant.Card ? '100%' : '289px')};
  @media screen and (${devices.desktopMin}) {
    max-width: 600px;
  }
  ${props => props.variant === ArticleVariant.Long && `width: 100%`}
`
const ArticleLink = styled(Link)<{ variant?: ArticleVariant }>`
  text-decoration: none;
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${props => (props.variant === ArticleVariant.Card ? 'row' : 'column')};

  flex-wrap: wrap;
  ${props => props.variant === ArticleVariant.Long && `flex-direction: column-reverse;`};
  max-width: 350px;
  min-width: 350px;
`

const ArticleCoverPlaceholder = styled.div<{ variant?: ArticleVariant }>`
  width: ${props => (props.variant === ArticleVariant.Card ? '100px' : '100%')};
  border-radius: 18px;
`

const ArticleCover = styled.img<{ variant?: ArticleVariant }>`
  object-fit: cover;
  border-radius: 18px;
  width: ${({ variant }) => (variant === ArticleVariant.Card ? '100px' : 'auto')};
  height: ${({ variant }) =>
    (variant === ArticleVariant.Card && '100px') || (ArticleVariant.Banner && '145px') || '200px'};
`

const ArticleTitle = styled.h2<{ variant?: ArticleVariant }>`
  font-size: ${props => (props.variant === ArticleVariant.Card ? '14px' : '18px')};
  margin: 0;
  margin-bottom: ${props => (props.variant === ArticleVariant.Long ? '8px' : '0')};
  font-weight: 700;
  max-width: ${props => (props.variant === ArticleVariant.Card ? '210px' : '100%')};
  color: #272728;
`

const ArticleText = styled.div<{ variant?: ArticleVariant }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`

const ArticleStatsList = styled(FlexRow)`
  margin-top: 4px;
  gap: 12px;
  justify-content: start;
`

const ArticleContent = styled.div<{ variant?: ArticleVariant }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: ${props => (props.variant === ArticleVariant.Banner ? '100%' : 'auto')};
`

const ArticleStatsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  span {
    font-size: 11px;
    color: #6e6e70;
  }
`

const ArticleIcon = styled.img`
  width: 15px;
  height: 15px;
`

interface ArticleCardProps {
  item: ArticleListItemDto
  variant?: ArticleVariant
}

const ArticleCard = ({ item, variant = ArticleVariant.Card }: ArticleCardProps) => {
  // const { articleId, commentsCount, content, imageUrl, sharesCount, title, viewsCount } = article
  const shortTitle = item?.title && shortString(item?.title, 50)

  return (
    // <ArticleCardWrapper variant={variant}>
    <ArticleLink to={`/article/${item?.id}`} variant={variant}>
      <ArticleCoverPlaceholder variant={variant}>
        <ArticleCover alt={'cover'} src={item?.imageUrl} variant={variant} />
        <DesktopView>
          <ArticleStatsList>
            <ArticleStatsItem>
              <ArticleIcon src={'/images/interface/views-eye.svg'} />
              <span>{item?.viewsCount}</span>
            </ArticleStatsItem>
            <ArticleStatsItem>
              <ArticleIcon src={'/images/interface/comment.svg'} />
              <span>{item?.commentsCount}</span>
            </ArticleStatsItem>
            <ArticleStatsItem>
              <ArticleIcon src={'/images/interface/share.svg'} />
              <span>{item?.sharesCount}</span>
            </ArticleStatsItem>
          </ArticleStatsList>
        </DesktopView>
      </ArticleCoverPlaceholder>
      <ArticleContent variant={variant}>
        <ArticleText>
          <ArticleTitle variant={variant}>{shortTitle}</ArticleTitle>
          {/* {item?. && variant !== ArticleVariant.Card && (
              <ArticleDescription>{item?.}</ArticleDescription>
            )} */}
        </ArticleText>
        <MobileTabletView>
          <ArticleStatsList>
            <ArticleStatsItem>
              <ArticleIcon src={'/images/interface/views-eye.svg'} />
              <span>{item?.viewsCount}</span>
            </ArticleStatsItem>
            <ArticleStatsItem>
              <ArticleIcon src={'/images/interface/comment.svg'} />
              <span>{item?.commentsCount}</span>
            </ArticleStatsItem>
            <ArticleStatsItem>
              <ArticleIcon src={'/images/interface/share.svg'} />
              <span>{item?.sharesCount}</span>
            </ArticleStatsItem>
          </ArticleStatsList>
        </MobileTabletView>
      </ArticleContent>
    </ArticleLink>
    // </ArticleCardWrapper>
  )
}

export default ArticleCard
