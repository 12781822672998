import React from 'react'

import styled from 'styled-components/macro'

import { SwitchTabs } from '../../../../shared/ui-kit/actual/SwitchTabs'
import { DesktopView, MobileTabletView } from '../../../../shared/ui-kit/projectComponents'

const Desktop = styled(DesktopView)`
  width: auto;
`

interface ChatTabsProps {
  activeTab?: number
  setActiveTab?: (activeTab: number) => void
}

export const ChatTabs = ({ activeTab, setActiveTab }: ChatTabsProps): React.JSX.Element => {
  return (
    <>
      <MobileTabletView>
        <SwitchTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabHead0={''}
          tabHead1={'Новые заказы'}
        />
      </MobileTabletView>
      <Desktop>
        <div style={{ marginTop: '22px' }}>
          <SwitchTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabHead0={'Чаты'}
            tabHead1={'Новые заказы'}
          />
        </div>
      </Desktop>
    </>
  )
}
