import { BlockTitle } from '../../../../shared/ui-kit/actual/InfoButton'
import { Desc, FlexColumn, StyledButton } from '../../../../shared/ui-kit/projectComponents'

const Unsuccess = ({ handleCloseModal }: { handleCloseModal: () => void }) => {
  return (
    <>
      <FlexColumn style={{ flex: 1, width: '100%' }}>
        <img alt={''} height={40} src={'/images/interface/close-err.svg'} width={40} />
        <BlockTitle topSpace={'sm'}>Что-то пошло не так</BlockTitle>
        <Desc style={{ color: '#838589', textAlign: 'center' }}>
          Попробуйте оформить заказ еще раз
        </Desc>
      </FlexColumn>
      <StyledButton
        onClick={handleCloseModal}
        style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
        variant={'primary'}
      >
        К товару
      </StyledButton>
    </>
  )
}

export default Unsuccess
