import React, { FC } from 'react'

import styled from 'styled-components/macro'

import { ProductTagDto } from '../../api/generated'
import { colors } from '../../constants/colors'
import { FlexColumn, FlexRow, FlexRowWrap } from '../projectComponents'

type TagsFieldProps = {
  selectedTags: any[]
  setSelectedTags: (val: any) => void
  tags?: any[]
}

const TagWrapper = styled(FlexRow)`
  padding: 9px 12px;
  align-items: center;
  border-radius: 7.556px;
  background-color: ${colors.white};
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 13.2px */
  color: ${colors.black};
  user-select: none;
  img {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
`
const Wrapper = styled(FlexColumn)`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: auto;
`

const MenuWrapper = styled(FlexRowWrap)`
  justify-content: start;
  align-items: start;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  background-color: ${colors.lightGray};
  border-radius: 15px;
  padding: 10px;
  z-index: 100;
  gap: 6px;
`
const ItemWrapper = styled(FlexRow)`
  cursor: pointer;
  border-radius: 15px;
  padding: 14px 18px;
  box-sizing: border-box;
  align-items: start;
  text-align: start;
  justify-content: start;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }

  :hover {
    background-color: ${colors.white};
    transition: all ease-in 0.3s;
  }
`

export const TagsFieldNew: FC<TagsFieldProps> = ({ selectedTags, setSelectedTags, tags }) => {
  const toggleTags = id => {
    if (selectedTags.includes(id)) {
      const newTags = selectedTags.filter(val => val !== id)

      setSelectedTags(newTags)
    } else {
      setSelectedTags([...selectedTags, id])
    }
  }
  const isIdSelected = (id: any) => selectedTags.includes(id)

  return (
    <Wrapper>
      <MenuWrapper>
        {tags?.map((item, idx) => (
          <TagWrapper
            style={{
              background: isIdSelected(item.id) && colors.primary,
              color: isIdSelected(item.id) && colors.white,
            }}
            key={`${item.name}${idx}`}
            onClick={() => toggleTags(item.id)}
          >
            {item.name}
          </TagWrapper>
        ))}
        {(tags?.length === 0 || !tags) && (
          <ItemWrapper>
            <span>Нет тегов</span>
          </ItemWrapper>
        )}
      </MenuWrapper>
    </Wrapper>
  )
}
