import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  CreatePaymentCommand,
  CreatePaymentResponse,
  CreatePayoutCommand,
  GetApiTransactionsGetTransactionsParams,
  GetOperationsListQuery,
  GetOperationsListResponse,
  GetTransactionsResponse,
  getApiTransactionsGetTransactions,
  postApiOperationsGetOperationsList,
  postApiTransactionsCreatePayment,
  postApiTransactionsCreatePayout,
} from '../../shared/api/generated'

export function useTransactionsCreatePaymentMutation(
  options?: UseMutationOptions<CreatePaymentResponse, unknown, CreatePaymentCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CreatePaymentCommand) => {
      return postApiTransactionsCreatePayment(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при переходе к пополнению баланса')
    },

    ...options,
  })
}
export function useTransactionsCreatePayoutMutation(
  options?: UseMutationOptions<unknown, unknown, CreatePayoutCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CreatePayoutCommand) => {
      return postApiTransactionsCreatePayout(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка вывода средств')
    },

    ...options,
  })
}

export function useGetTransactionsQuery(
  data: GetApiTransactionsGetTransactionsParams,
  options?: UseQueryOptions<unknown, unknown, GetTransactionsResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки списка транзакций')
    },
    queryFn: () => getApiTransactionsGetTransactions(data),
    queryKey: ['get_all_transactions', JSON.stringify(data)],
    ...options,
  })
}

export function useGetOperationsListQuery(
  data: GetOperationsListQuery,
  options?: UseQueryOptions<unknown, unknown, GetOperationsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки списка операций')
    },
    queryFn: () => postApiOperationsGetOperationsList(data),
    queryKey: ['get_all_operations', JSON.stringify(data)],
    ...options,
  })
}
