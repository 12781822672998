import React from 'react'
import { Link, useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { RentListItem } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import useDateRangeFormat from '../../../../shared/hooks/useDateRangeFormat'
import { BtnRowWrapper, StyledButton } from '../../../../shared/ui-kit/projectComponents'

const RentItemWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const RentItemTopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media all and (max-width: 800px) {
    align-items: start;
  }
`

const MessageImgWrapper = styled.div`
  max-width: 68px;
  max-height: 87px;
  position: relative;
`
const MessageImg = styled.img`
  width: 67px;
  height: 105px;
  border-radius: 6px;
  object-fit: cover;

  @media all and (max-width: 800px) {
    width: 62px;
    height: 62px;
  }
`

const UserAvatarWrapper = styled.div`
  position: absolute;
  top: -6px;
  left: -6px;
  background: ${colors.white};
  padding: 2.5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 800px) {
    z-index: 0;
  }
`

const ProfileImg = styled.img`
  width: 21px;
  height: 21px;
  object-fit: cover;
  border-radius: inherit;
`

const RentItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const RentDataPriceWrapper = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  color: ${colors.primary};

  @media all and (max-width: 800px) {
    font-size: 11px;
    line-height: 0;
    margin: 3px 0 10px 0;
  }
`

const RentDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media all and (max-width: 800px) {
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }
`

const ProductDetails = styled.div`
  width: 169px;
`

const ProductDetailsName = styled.h4`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.59px;
  color: ${colors.black};
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media all and (max-width: 800px) {
    font-size: 16px;
    line-height: 25.3px;
  }
`

const ProductDetailsBuyerName = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 23.36px;
  color: ${colors.grey};

  @media all and (max-width: 800px) {
    font-size: 13.63px;
    line-height: 25.3px;
  }
`

const DeliveryAddress = styled.div``

const DeliveryTitle = styled(ProductDetailsName)``

const DeliveryInfoAddress = styled(ProductDetailsBuyerName)`
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.primary};

  @media all and (max-width: 800px) {
    max-width: 227px;
  }
`

const RentItemBottomSection = styled.div``

const BtnWrapper = styled(BtnRowWrapper)`
  justify-content: start;
  align-items: center;
`
const ActionBtn = styled(StyledButton)`
  max-width: 240px;
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  line-height: 19px;
`

const BtnChat = styled(StyledButton)`
  width: 50px;
  height: 50px;
`

interface RentItemProps {
  rentData?: RentListItem
  setActiveTab?: (activeTab: number) => void
  setModal?: () => void
}

export const RentItem = ({ rentData, setModal }: RentItemProps): React.JSX.Element => {
  const dataRenge = useDateRangeFormat(rentData?.periodFrom, rentData?.periodTo)
  const { rentId } = useParams()

  const deliveryType = rentData?.deliveryType === 'pickup' ? 'Самовывоз' : 'Доставка'

  return (
    <RentItemWrapper>
      <Link style={{ textDecoration: 'none' }} to={`/item/${rentData?.productId}`}>
        <RentItemTopSection>
          <MessageImgWrapper>
            <MessageImg src={rentData?.productImagesUrls[0]} />
            <UserAvatarWrapper>
              <ProfileImg
                src={rentData?.buyerImageUrl || '/images/bottom-menu/profile-light.svg'}
              />
            </UserAvatarWrapper>
          </MessageImgWrapper>
          <RentItemContent>
            <RentDataPriceWrapper>
              {dataRenge} •{' '}
              {rentData?.price !== 0
                ? `${rentData?.price.toLocaleString('ru-RU')}₽`
                : 'Цена не указана'}
            </RentDataPriceWrapper>
            <RentDetails>
              <ProductDetails>
                <ProductDetailsName>{rentData?.productName}</ProductDetailsName>
                <ProductDetailsBuyerName>{rentData?.buyerName}</ProductDetailsBuyerName>
              </ProductDetails>
              <DeliveryAddress>
                <DeliveryTitle>{deliveryType}</DeliveryTitle>
                <DeliveryInfoAddress>
                  {rentData?.address?.name || 'Адрес не указан'}
                </DeliveryInfoAddress>
              </DeliveryAddress>
            </RentDetails>
          </RentItemContent>
        </RentItemTopSection>
      </Link>
      <RentItemBottomSection>
        <BtnWrapper>
          <ActionBtn onClick={setModal} variant={'warning'}>
            Отклонить
          </ActionBtn>
          <ActionBtn onClick={setModal}>Принять</ActionBtn>
          {!rentId && (
            <Link style={{ width: 'auto' }} to={`/messages/${rentData?.rentId}`}>
              <BtnChat variant={'secondary'}>
                <img alt={'chat'} src={'/images/buttons/Chat.svg'} />
              </BtnChat>
            </Link>
          )}
        </BtnWrapper>
      </RentItemBottomSection>
    </RentItemWrapper>
  )
}
