import styled from 'styled-components/macro'

import { useGetUserProfileQuery } from '../../../../entities/userProfile/queries'
import { GetArticleInfoResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import ProfileSnippet from '../../../../shared/ui-kit/ProfileSnippet'
import ProfileSnippetNotAuth from '../../../../shared/ui-kit/ProfileSnippetNotAuth'
import {
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  MobileTabletView,
} from '../../../../shared/ui-kit/projectComponents'
import { dateToString } from '../../../../shared/utils/dateToString'

const SectionWrapper = styled(FlexColumnStart)`
  width: 100%;
  margin-bottom: 32px;
`

const ArticleCoverPlaceholder = styled.div`
  border-radius: 12px;
  width: inherit;
  height: 200px;
  background-color: ${colors.lightGray};
  margin-bottom: 32px;
`

const ArticleCover = styled.img`
  height: inherit;
  object-fit: contain;
  width: inherit;
  border-radius: inherit;
`

const ArticleTitle = styled.h2`
  font-size: 16px;
  color: ${colors.black};
  margin: 0;
  margin-bottom: 8px;
  margin-top: 17px;
`

const ArticleDescription = styled.p`
  font-size: 13px;
  letter-spacing: 0.04em;
  line-height: 171%;
  margin: 0;
  color: ${colors.grey};
  font-weight: 500;
`

const ArticleStat = styled(FlexRow)`
  gap: 4px;

  span {
    font-size: 10px;
    color: ${colors.grey};
  }
`

const ArticleContent = styled(FlexColumnStart)`
  margin-top: 17px;
  width: calc(100% - 16px);
  span {
    font-size: 12px;
    color: ${colors.grey};
    font-weight: 500;
  }
`

interface ArticleSectionProps {
  data: GetArticleInfoResponse
}
const ArticleSectionNotAuth = ({ data }: ArticleSectionProps) => {
  if (!data) {
    return <div>Статья не найдена</div>
  }

  return (
    <SectionWrapper>
      <ArticleCoverPlaceholder>
        <ArticleCover src={data?.imageUrl} />
      </ArticleCoverPlaceholder>
      <ArticleContent>
        <ProfileSnippetNotAuth
          authorId={data?.authorId}
          authorImageUrl={data?.authorImageUrl}
          authorLogin={data?.authorLogin}
          authorName={data?.authorName}
        />
        <br />
        <FlexRowBetween style={{ marginBottom: 8 }}>
          <span>{dateToString(data?.createdOn)}</span>
          <FlexRow style={{ gap: 12 }}>
            <ArticleStat>
              <img alt={'views'} src={'/images/interface/views-eye.svg'} />
              <span>{data?.viewsCount}</span>
            </ArticleStat>
            <ArticleStat>
              <img alt={'comments'} src={'/images/interface/comment.svg'} />
              <span>{data?.commentsCount}</span>
            </ArticleStat>
            <ArticleStat>
              <img alt={'shared'} src={'/images/interface/share.svg'} />
              <span>{data?.sharesCount}</span>
            </ArticleStat>
          </FlexRow>
        </FlexRowBetween>
        <ArticleTitle>{data?.title}</ArticleTitle>
        <ArticleDescription>{data?.content}</ArticleDescription>
      </ArticleContent>
    </SectionWrapper>
  )
}

export default ArticleSectionNotAuth
