import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { FlexColumn } from '../../projectComponents'

const Block = styled(FlexColumn)`
  width: 103px;
  /* height: 130px; */
  img {
    width: 103px;
    height: 103px;
    border-radius: 12px;
  }
  p {
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
  }
`
const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none !important;
  color: ${colors.black};
`
const CategoryImg = styled.img`
  max-width: 162px;
  height: 162px;
  object-fit: cover;
`

interface CategoryButtonProps {
  id: number | string
  image?: null | string
  name: string
}

export const CategoryButtonAdmin: React.FC<CategoryButtonProps> = ({ id, image, name }) => {
  return (
    <Block>
      <StyledLink to={String(id)}>
        <CategoryImg alt={name} src={image} />
        <p>{name}</p>
      </StyledLink>
    </Block>
  )
}
