import styled from 'styled-components/macro'

import { useSubscribe, useUnSubscribe } from '../../../../entities/subscribe'
import { useGetUserProfileQuery } from '../../../../entities/userProfile/queries'
import { AccountType } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { primaryStarSVG } from '../../../../shared/constants/images'
import { ButtonColumn } from '../../../../shared/ui-kit/actual/components/buttonColumn'
import {
  AvatarLarge,
  BoldNumber,
  BtnRowWrapper,
  BtnWrapper,
  FlexColumn,
  OpacityText,
  RatingWrapper,
  RelativeWrapper,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import getAvgRating from '../../../../shared/utils/getAvgRating'
import { useProfileInfo } from '../../model/use-profile-info'

const StyledHeader = styled.h3`
  max-width: 272px;
  color: ${colors.black};
  font-size: 21px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin: 0;
`

const SubHeader = styled(StyledHeader)`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`

const UserNameWrapper = styled(FlexColumn)`
  gap: 8px;
  margin-top: 32px;
  color: #fff;
  margin-bottom: 20px;
`

const SubscribersWrapper = styled.div<{ $activeTab: number }>`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(2, 20px);
  column-gap: 50px;
  justify-items: center;
  transition: 0.2s ease-out;
  height: ${({ $activeTab }) => ($activeTab ? '40px' : '0')};
  transform: ${({ $activeTab }) => ($activeTab ? 'none' : 'scaleY(0%)')};
`

type Props = {
  aboutItself: string
  accountType: AccountType
  avatarLink: string
  buyerRating: number
  fullName: string
  isSubscribed: boolean
  sellerRating: number
  setRatingModal: (viewModal: boolean) => void
  subscribersCount: number
  subscriptionsCount: number
  userId: string
}

export const UserDesktopHeader = ({
  aboutItself,
  accountType,
  avatarLink,
  buyerRating,
  fullName,
  isSubscribed,
  sellerRating,
  setRatingModal,
  subscribersCount,
  subscriptionsCount,
  userId,
}: Props) => {
  const avgRating = getAvgRating(buyerRating, sellerRating)
  const { userInfo } = useProfileInfo()

  const subscribed = isSubscribed
  const subscribeMutation = useSubscribe()
  const unSubscribeMutation = useUnSubscribe()
  const subscribe = () =>
    !subscribed
      ? subscribeMutation.mutate({ userId: userId })
      : unSubscribeMutation.mutate({ userId: userId })
  const iThisUser = userId === userInfo?.id

  return (
    <FlexColumn style={{ minWidth: '290px' }}>
      <RelativeWrapper>
        <AvatarLarge src={avatarLink || '/images/bottom-menu/profile-light.svg'} />
        <RatingWrapper roleType={accountType}>
          <img alt={''} src={'/images/interface/whiteStar.svg'} />
          <span>{avgRating.toFixed(1)}</span>
        </RatingWrapper>
      </RelativeWrapper>

      <UserNameWrapper>
        <StyledHeader>{fullName}</StyledHeader>
        <SubHeader>{aboutItself}</SubHeader>
      </UserNameWrapper>
      <SubscribersWrapper $activeTab={1}>
        <BoldNumber>{subscribersCount}</BoldNumber>
        <BoldNumber>{subscriptionsCount}</BoldNumber>
        <OpacityText>Подписчиков</OpacityText>
        <OpacityText>Подписок</OpacityText>
      </SubscribersWrapper>
      {!iThisUser && (
        <StyledButton
          disabled={subscribeMutation.isLoading || unSubscribeMutation.isLoading}
          onClick={subscribe}
          style={{ marginBottom: 16, marginTop: 16 }}
          fullwidth
        >
          {subscribed ? 'Отменить подписку' : 'Подписаться'}
        </StyledButton>
      )}
      <BtnWrapper>
        <BtnRowWrapper onClick={() => setRatingModal(true)}>
          <ButtonColumn
            top={
              <>
                {' '}
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{buyerRating?.toFixed(1)}
              </>
            }
            bottom={<>Арендатор</>}
          />
          <ButtonColumn
            top={
              <>
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{sellerRating?.toFixed(1)}
              </>
            }
            bottom={<>Арендодатель</>}
          />
        </BtnRowWrapper>
      </BtnWrapper>
    </FlexColumn>
  )
}
