import React from 'react'
import { UseFormGetValues } from 'react-hook-form'

import styled from 'styled-components/macro'

import { PostApiProductsCreateProductBody, UserAddressDto } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { BlockTitle } from '../../../../shared/ui-kit/actual/InfoButton'
import { Paragraph } from '../../../../shared/ui-kit/actual/Paragraph'
import { SliderComponent } from '../../../../shared/ui-kit/actual/Slider'
import { LocationInfo } from '../../../../shared/ui-kit/actual/components/LocationInfo'
import {
  FlexColumn,
  FlexColumnBetween,
  FlexColumnStart,
  FlexRowBetween,
  FlexRowGap,
  MediumTextL,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H2, H3 } from '../../../../shared/ui-kit/titles'

const Page = styled(FlexColumn)`
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: start;
  align-items: start;

  @media (min-width: 700px) {
    height: auto;
    min-height: 100vh;
    align-self: center;
    align-items: center;
  }
`

const Wrapper = styled(FlexColumn)`
  width: 100%;
`

const TableWrapper = styled.div`
  width: -webkit-fill-available;
  padding: 18px 20px;
  background-color: #f8f8fa;
  border-radius: 10px;
`

type Props = {
  getValues: UseFormGetValues<PostApiProductsCreateProductBody>
  imageUrls: string[]
  selectedAddress?: UserAddressDto
  setNewProduct: (e: any) => void
  setViewModal: React.Dispatch<React.SetStateAction<boolean>>
}
export const ViewProductModal = ({
  getValues,
  imageUrls,
  selectedAddress,
  setNewProduct,
  setViewModal,
}: Props) => {
  return (
    <>
      <Page>
        <H2 style={{ alignSelf: 'flex-start' }}>Все верно?</H2>
        <br />
        {getValues('IsPremium') && <H3 style={{ color: colors.primary }}>Премиум товар</H3>}
        <Wrapper>
          {!!imageUrls.length && (
            <SliderComponent
              items={imageUrls.map((image, index) => (
                <img
                  alt={'product'}
                  height={425}
                  key={index}
                  src={image}
                  style={{ objectFit: 'contain' }}
                  width={'100%'}
                />
              ))}
              slidesToShow={1}
              dots
            />
          )}

          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'sm'}>
            {getValues('Name')}
          </BlockTitle>
          <FlexColumnStart style={{ alignSelf: 'flex-start' }}>
            <Paragraph>Залог {getValues('Pledge')} ₽</Paragraph>
          </FlexColumnStart>
          <br />
          <TableWrapper>
            <Paragraph style={{ marginBottom: '16px' }}>
              В данной таблице указаны варианты аренды и цены
            </Paragraph>
            <FlexColumnBetween style={{ gap: '8px' }}>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>1 день</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {getValues('DayPrice')} ₽
                </Paragraph>
              </FlexRowBetween>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>3 дня</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {getValues('ThreeDaysPrice')} ₽
                </Paragraph>
              </FlexRowBetween>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>Неделя</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {getValues('WeekPrice')} ₽
                </Paragraph>
              </FlexRowBetween>
              <FlexRowBetween style={{ alignItems: 'center' }}>
                {' '}
                <Paragraph style={{ color: colors.black }}>Месяц</Paragraph>{' '}
                <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                  {getValues('MonthPrice')} ₽
                </Paragraph>
              </FlexRowBetween>
            </FlexColumnBetween>
          </TableWrapper>
          <br />
          <TableWrapper>
            <Paragraph style={{ marginBottom: '16px' }}>
              В данной таблице указаны штрафы за дефекты
            </Paragraph>
            {getValues('DefectFines')?.length && (
              <>
                <FlexColumnBetween style={{ gap: '8px' }}>
                  {getValues('DefectFines')?.map((defect, idx) => (
                    <FlexRowBetween key={idx} style={{ alignItems: 'center' }}>
                      {' '}
                      <Paragraph style={{ color: colors.black, maxWidth: '85%' }}>
                        {defect.defectName}
                      </Paragraph>{' '}
                      <Paragraph
                        style={{
                          color: colors.primary,
                          fontWeight: 600,
                          whiteSpace: 'nowrap',
                          width: 'max-content',
                        }}
                      >
                        {defect.amount} ₽
                      </Paragraph>
                    </FlexRowBetween>
                  ))}
                </FlexColumnBetween>
              </>
            )}
          </TableWrapper>

          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
            О товаре
          </BlockTitle>
          <Paragraph style={{ width: '100%' }}>{getValues('Description')}</Paragraph>

          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
            Доставка
          </BlockTitle>
          <div style={{ alignSelf: 'flex-start' }}>
            {getValues('DeliveryType') === 'countryWithSelection' && (
              <FlexColumnStart>
                <Paragraph>В городах: </Paragraph>
                <MediumTextL>{getValues('DeliveryCities').join(', ')}</MediumTextL>
              </FlexColumnStart>
            )}
            {getValues('DeliveryType') === 'russiaAny' && (
              <FlexRowBetween>
                <Paragraph>В пределах РФ</Paragraph>
              </FlexRowBetween>
            )}
            {!getValues('DeliveryType') && (
              <FlexRowBetween>
                <Paragraph>Варианты доставки не указаны арендодателем</Paragraph>
              </FlexRowBetween>
            )}
          </div>
          <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
            Самовывоз
          </BlockTitle>
          {getValues('PickupAddressId') ? (
            <LocationInfo pickupAddress={selectedAddress} noMap />
          ) : (
            <Paragraph style={{ alignSelf: 'flex-start' }}>
              Адрес самовывоза не указан арендодателем
            </Paragraph>
          )}
          <br />
          <FlexRowGap>
            <StyledButton onClick={() => setViewModal(false)} variant={'secondary'}>
              Редактировать
            </StyledButton>
            <StyledButton onClick={setNewProduct} fullwidth>
              Подтвердить публикацию объявления{' '}
            </StyledButton>
          </FlexRowGap>
        </Wrapper>
      </Page>
    </>
  )
}
