import { useEffect } from 'react'

import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components/macro'

import { useRentsGenerateQrCodeMutation } from '../../../../entities/rent/queries'
import { colors } from '../../../../shared/constants/colors'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  FlexColumn,
  MediumText,
  NeutralLink,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H3, H4 } from '../../../../shared/ui-kit/titles'
import { StepWrapper } from '../StepWrapper'

const ImageWrapper = styled.div`
  width: 280px;
  height: 280px;

  margin: 24px 0;
  border-radius: 11px;
  background-color: ${colors.lightGray};
  padding: 22px;
`

type Props = {
  refetch: () => void
  rentId: number
  sellerId: string
}
export const Step2 = ({ refetch, rentId, sellerId }: Props) => {
  const { data, isLoading, mutate } = useRentsGenerateQrCodeMutation()

  useEffect(() => {
    mutate({ rentId })
  }, [mutate, rentId])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 5000)

    return () => clearInterval(interval)
  }, [refetch])

  return (
    <StepWrapper>
      <AbsoluteLoader isLoading={isLoading} />
      <FlexColumn>
        <H3>Заказ отправлен</H3>
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Покажите QR-код тому, кто доставит ваш заказ
        </MediumText>
        <ImageWrapper>{data?.code && <QRCodeSVG size={240} value={data?.code} />}</ImageWrapper>
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Или передайте этот строковый код тому, кто доставит ваш заказ (регистр букв имеет
          значение)
        </MediumText>
        <H4>{data?.code}</H4>
      </FlexColumn>
      <NeutralLink to={`/users/${sellerId}`}>
        <StyledButton fullwidth>Об арендодателе</StyledButton>
      </NeutralLink>
    </StepWrapper>
  )
}
