import { useContext } from 'react'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { useGetUserProfileMutation } from '../../../../entities/userProfile/queries'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import {
  FlexColumnBetween,
  FlexRowGap,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { usePayment } from '../../../hooks/use-payment'
import { BankRequisites } from './BankRequisites'
import { PaymentBlock } from './PaymentBlock'

const Box = styled.div`
  background: #f8f8fa;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 22px 26px;
  .title {
    color: #008c8c;
    font-size: 24px;
    font-weight: 600;
    line-height: 25.36px;
    margin: 0;
    padding: 0;
  }
  .desc {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.04px;
    margin: 0;
    padding: 0;
    opacity: 0.2;
  }
`

const AddPaymentBalance = () => {
  const ProfileStore = useContext(ProfileStorage)

  const { mutate: addUserProfile } = useGetUserProfileMutation({
    onError: error => {
      console.log('profile loading error', error)
    },
    onSuccess: data => {
      ProfileStore.setProfile(data)
    },
  })
  const {
    amount,
    createPayment,
    data,
    handleSetAmount,
    isPaymentLoading,
    safariButton,
    setAmount,
    setSafariButton,
  } = usePayment()

  // Нужно чтобы обновить баланс пользователя в профиле

  return (
    <FlexColumnBetween style={{ height: '100%', padding: '24px 12px 12px' }}>
      <div style={{ flex: '1 0', width: '100%' }}>
        <Box>
          <FlexRowGap style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div>
              <h3 className={'title'}>{ProfileStore.profile.balanceUser.accountBalance} ₽</h3>
              <p className={'desc'}>Баланс</p>
            </div>
            <StyledButton
              onClick={() => {
                addUserProfile({ userId: ProfileStore?.userInfo?.id })
              }}
              size={'sm'}
            >
              Обновить
            </StyledButton>
          </FlexRowGap>
        </Box>
        {ProfileStore.getUserInfo().legalStatus === 'individual' ||
        ProfileStore.getUserInfo().legalStatus === 'selfEmployed' ? (
          <>
            <br />
            <PaymentBlock
              amount={amount}
              confirmationUrl={data?.confirmationUrl}
              createPayment={createPayment}
              handleSetAmount={handleSetAmount}
              isPaymentLoading={isPaymentLoading}
              safariButton={safariButton}
              setAmount={setAmount}
              setSafariButton={setSafariButton}
            />
          </>
        ) : (
          <BankRequisites />
        )}
      </div>
    </FlexColumnBetween>
  )
}

export default observer(AddPaymentBalance)
