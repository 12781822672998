import { createContext } from 'react'

import { action, autorun, makeAutoObservable } from 'mobx'

import { GetProductsListQuery, ProductsListOrderType } from '../../api/generated'

class FilterStorage {
  sorting: GetProductsListQuery =
    // localStorage.sorting
    //   ? JSON.parse(localStorage.sorting)
    //   :
    { dayPriceFrom: 0, dayPriceTo: 0, premiumOnly: false }
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    // autorun(() => {
    //   localStorage.sorting = JSON.stringify(this.sorting)
    // })
  }
  @action clear() {
    this.sorting = {}
  }

  @action
  setDayPriceFrom(data: number) {
    this.sorting.dayPriceFrom = data
  }

  @action
  setDayPriceTo(data: number) {
    this.sorting.dayPriceTo = data
  }

  @action
  setHighRatingOnly(data: boolean) {
    data ? (this.sorting.ratingFrom = 4) : (this.sorting.ratingFrom = 0)
  }

  @action
  setOrderBy(data: ProductsListOrderType) {
    this.sorting.orderType = data
  }

  @action
  setPremiumOnly(data: boolean) {
    this.sorting.premiumOnly = data
  }

  @action
  setSorting(data: GetProductsListQuery) {
    this.sorting = data
  }

  get isHighRatingOnly() {
    return this.sorting.ratingFrom > 3
  }
  get isPremiumOnly() {
    return this.sorting.premiumOnly
  }

  get orderType() {
    return this.sorting.orderType
  }
}

export default createContext(new FilterStorage())
