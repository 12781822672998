import styled from 'styled-components/macro'

import { InputSwitchWithTitle } from '../../../../shared/ui-kit/actual/InputSwitchWithTitle'
import { devices } from '../../../../shared/ui-kit/breakPoints'
import { StyledButton } from '../../../../shared/ui-kit/projectComponents'

const BlockWrapperBottom = styled.div`
  width: 100%;
  @media screen and (${devices.desktopMin}) {
    width: auto;
    align-self: flex-start;
  }
`

export const SubmitBlock = ({
  handleNextStep,
  isValid,
  onlyPremium,
  togglePremium,
}: {
  handleNextStep?: () => void
  isValid: boolean
  onlyPremium: boolean
  togglePremium: () => void
}) => {
  return (
    <BlockWrapperBottom>
      <br />
      <InputSwitchWithTitle
        subtitle={'Ваше объявление увидят только пользователи с премиум-подпиской'}
        switchOn={onlyPremium}
        title={'Премиум товары'}
        toggleSwitch={togglePremium}
      />
      <StyledButton
        disabled={!isValid}
        onClick={() => handleNextStep()}
        style={{ marginTop: '32px' }}
        variant={'primary'}
        fullwidth
      >
        Далее
      </StyledButton>
    </BlockWrapperBottom>
  )
}
