export const ROUTES_TRANSLATE = {
  // '6': 'Автомобили',
  // '7': 'Аксессуары',
  // '8': 'Водный транспорт и авиация',
  // '9': 'Для бизнеса',
  // '10': 'Для дома и дачи',
  // '11': 'Инструменты',
  // '12': 'Люди',
  // '13': 'Недвижимость',
  // '14': 'Одежда',
  // '15': 'Путешествия',
  // '16': 'Развлечения',
  // '17': 'Спец. техника',
  // '18': 'Спорт',
  // '19': 'Творчество',
  // '20': 'Техника',
  // '21': 'Товары для детей',
  // '22': 'Цифровая техника',
  // '23': 'Другое',
  // '24': 'Для фотосессий',
  // '25': 'Животные',
  // '26': 'Здоровье и медицина',
  add: 'Добавление товара',
  article: 'Блог',
  blog: 'Блог',
  blouse: 'Кофты и худи',
  cars: 'Автомобили',
  category: 'Категории',
  clothes: 'Одежда',
  digitalTech: 'Цифровая техника',
  dresses: 'Платья',
  jeans: 'Джинсы',
  login: 'Вход',
  messages: 'Чаты',
  motors: 'Техника',
  orders: 'Заказы',
  people: 'Люди',
  profile: 'Профиль',
  realEstate: 'Недвижимость',
  register: 'Регистрация',
  review: 'Отзывы',
  search: 'Поиск',
  sections: 'Разделы',
  tools: 'Инструменты',
  users: 'Пользователи',
} as const

export type RoutesTranslate = typeof ROUTES_TRANSLATE

export const ROUTES_IDS = {
  // key: 1,
  // key: 2,
  blouse: 6,
  // key: 3,
  dresses: 5,
  jeans: 4,
  // key: 7,
  // key: 8,
  // key: 9,
  // value: 'Спорткар',
  // value: 'Внедорожник',
  // value: 'Ретро',
  // value: 'Джинсы',
  // value: 'Платья',
  // value: 'Пальто',
  // value: 'Квартира',
  // value: 'Комната',
  // value: 'Коттедж',
} as const
