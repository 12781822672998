import React, { ReactNode } from 'react'

import styled from 'styled-components/macro'

import { FlexRowBetween } from '../projectComponents'

type BOTTOM_SPACE_VARIANT = 'md' | 'sm' | 'xl'

interface BlockProps {
  bottomSpace?: BOTTOM_SPACE_VARIANT
}

const Block = styled(FlexRowBetween)<BlockProps>`
  margin-bottom: ${({ bottomSpace }) =>
    (bottomSpace === 'xl' && '32px') || (bottomSpace === 'md' && '24px') || '16px'};
  p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    opacity: 0.5;
  }
`
const ArrowImage = styled.div`
  width: 24px;
  height: 24px;
  img {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }
`

interface BackArrowWithTitleButtonProps {
  bottomSpace?: BOTTOM_SPACE_VARIANT
  center?: ReactNode
  onClick: () => void
  title?: string
}

export const BackArrowWithTitleButton: React.FC<BackArrowWithTitleButtonProps> = ({
  bottomSpace,
  center,
  onClick,
  title,
}) => {
  return (
    <Block bottomSpace={bottomSpace} className={'back_arrow_title_wrapper'} onClick={onClick}>
      <ArrowImage>
        <img alt={'arrow-back'} src={'/images/buttons/arrow-right-black.svg'} />
      </ArrowImage>
      {!!title && <p>{title}</p>}
      {center}
      <div style={{ width: 24 }} />
    </Block>
  )
}
