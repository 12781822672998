export const invalidPassword =
  'Пароль должен состоять из латинских букв, содержать хотя бы одну цифру, одну заглавную и строчную букву'
export const passwordRequired = 'Пароль обязателен'
export const passwordMax = 'Пароль не должен превышать 20 символов'
export const passwordMin = 'Пароль должен содержать не менее 6 символов'
export const emailRequired = 'Email обязателен'
export const emailLabel = 'Электронная почта'
export const invalidEmail = 'Неверный формат email'
export const titleRequired = 'Отсутствует заголовок'
export const textRequired = 'Отсутствует текст'
