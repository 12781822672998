import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useCompleteWithClaimBySellerMutation } from '../../../../entities/rent/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { IncomingPhotos } from '../../../../shared/ui-kit/actual/IncomingPhotos'
import { TextArea } from '../../../../shared/ui-kit/actual/TextArea'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumn, MediumText, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H3, H4 } from '../../../../shared/ui-kit/titles'
import { useSendPhotos } from '../../model/useSendPhotos'
import { AddPhotos } from '../AddPhotos'
import { DefectsTable } from '../DefectsTable'
import { StepWrapper } from '../StepWrapper'

type Props = {
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const Step11 = ({ rentInfo, setStep }: Props) => {
  const [comment, setComment] = useState('')
  const [defectIds, setDefectsIds] = useState<number[]>([])
  const {
    formState: { errors, isValid },
    register,
  } = useForm<{ message: string }>({
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const {
    isLoading: isWithClaimLoading,
    isSuccess: isWithClaimSuccess,
    mutate: claimMutate,
  } = useCompleteWithClaimBySellerMutation()

  const {
    arePhotosSelected: arePhotosWithClaimSelected,
    handleSubmit: handleSubmitWithClaim,
    isCompressing: isWithClaimCompressing,
    setArePhotosSelected: setArePhotosWithClaimSelected,
    setImageFiles: setImageWithClaimFiles,
  } = useSendPhotos({
    defectIds,
    isSuccess: isWithClaimSuccess,
    message: comment,
    mutate: claimMutate,
    nextStep: 7,
    setStep,
  })

  return (
    <StepWrapper>
      <AbsoluteLoader isLoading={isWithClaimLoading || isWithClaimCompressing} />
      <FlexColumn>
        <H3>Вы действительно хотите оставить жалобу?</H3>
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Вы можете сделать это только при выявлении дефектов, оставленных арендатором. Опишите их в
          форме ниже и прикрепите фото и видео доказательства{' '}
        </MediumText>
        <H4>Фото товара до использования</H4>
        <IncomingPhotos data={rentInfo?.beforeRentSellerImagesUrls} />
        <H4>Фото оставленных дефектов (не менее 6) </H4>

        <AddPhotos
          arePhotosSelected={arePhotosWithClaimSelected}
          secondPhotoButtonNote={'Изменить фотографии'}
          setArePhotosSelected={setArePhotosWithClaimSelected}
          setImageFiles={setImageWithClaimFiles}
        />
      </FlexColumn>

      <FlexColumn style={{ gap: '8px' }}>
        <DefectsTable
          defectIds={defectIds}
          rentId={rentInfo.rentId}
          setDefectsIds={setDefectsIds}
        />
        <TextArea
          inputProps={{
            ...register('message', {
              minLength: {
                message: 'Минимум 8 символов',
                value: 8,
              },
              required: 'Опишите дефект',
            }),
            placeholder: 'Описание дефектов и причины жалобы (обязательно к заполнению)',
            rows: 7,
          }}
          error={errors.message?.message?.toString()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
          style={{ marginBottom: '8px', width: '100%' }}
          value={comment}
          fullwidth
        />
        <StyledButton
          disabled={!isValid || !arePhotosWithClaimSelected || isWithClaimLoading}
          onClick={handleSubmitWithClaim}
          fullwidth
        >
          Отправить жалобу
        </StyledButton>
        <StyledButton onClick={() => setStep(6)} variant={'secondary'} fullwidth>
          Назад
        </StyledButton>
      </FlexColumn>
    </StepWrapper>
  )
}
