import React, { useEffect, useState } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'

import {
  useAuthCheckEmailRegistrationCodeMutation,
  useAuthSendRegistrationCodeByEmailMutation,
} from '../../../../entities/register/queries'
import { SignUpCommand } from '../../../../shared/api/generated'
import { useCodeInput } from '../../../../shared/hooks/use-code-input'
import { useInterval } from '../../../../shared/hooks/use-interval'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  CodeCheckFormWrapper,
  CodeFormWrapper,
  CodeText,
  CodeWrapper,
  FlexRowBetween,
  MediumText,
  OneNumberInput,
  StyledButton,
  Subtitle,
} from '../../../../shared/ui-kit/projectComponents'
import { formattedTimer } from '../../../../shared/utils/formatedTimer'
import { RegisterTitle } from '../headers/RegisterTitle'

type Props = {
  getValues: UseFormGetValues<{ passwordConfirm: string } & SignUpCommand>
  setStep: React.Dispatch<React.SetStateAction<number>>
  setValue: UseFormSetValue<{ passwordConfirm: string } & SignUpCommand>
}

export const ThirdStep = ({ getValues, setStep, setValue }: Props) => {
  const { data, error, isLoading, isSuccess, mutate } = useAuthCheckEmailRegistrationCodeMutation()
  const {
    isLoading: isResendLoading,
    isSuccess: isResendSuccess,
    mutate: resendMutate,
  } = useAuthSendRegistrationCodeByEmailMutation()

  const email = getValues('email')

  useEffect(() => {
    resendMutate({ email })
  }, [email, resendMutate])

  const { btnDisabled, code, onChange, onKeyDown, refs, refsReset } = useCodeInput()

  const { newInterval } = useInterval({ isResendSuccess, seconds: 60 })

  const onSubmit = e => {
    e.preventDefault()
    const codeStr = code.join('')

    setValue('emailConfirmationCode', codeStr)
    mutate({ code: codeStr, email })
  }

  const [oneUnsuccess, setOneUnsuccess] = useState(false)

  useEffect(() => {
    if (!btnDisabled && !oneUnsuccess) {
      const codeStr = code.join('')

      setValue('emailConfirmationCode', codeStr)
      mutate({ code: codeStr, email })
    }
  }, [btnDisabled, code, email, mutate, oneUnsuccess, setValue])

  useEffect(() => {
    if (isSuccess && data.isCodeValid) {
      setStep(prev => prev + 1)
    } else if (error || data?.isCodeValid === false) {
      setOneUnsuccess(true)
    }
  }, [data?.isCodeValid, error, isSuccess, setStep])

  return (
    <CodeCheckFormWrapper>
      <AbsoluteLoader isLoading={isLoading || isResendLoading} />

      <div>
        <RegisterTitle title={'Электронная почта'} />
        <MediumText>
          Подтвердите почту
          <br />
          {email}
        </MediumText>
      </div>
      <CodeFormWrapper onSubmit={onSubmit}>
        <Subtitle>Код подтверждения</Subtitle>
        <CodeWrapper>
          {Object.values(refs).map((ref, idx) => (
            <OneNumberInput
              onChange={e => {
                onChange(idx, e)
              }}
              key={idx}
              maxLength={1}
              onKeyDown={e => onKeyDown(idx, e)}
              ref={ref}
              type={'tel'}
            />
          ))}
        </CodeWrapper>
        {newInterval !== 0 && (
          <FlexRowBetween>
            <CodeText>Запросить новый код через</CodeText>
            <CodeText>{formattedTimer(newInterval)}</CodeText>
          </FlexRowBetween>
        )}
        {newInterval === 0 && (
          <StyledButton
            onClick={() => {
              resendMutate({ email })
              refsReset()
            }}
            disabled={isLoading || isResendLoading}
            type={'button'}
            variant={'secondary'}
            fullwidth
          >
            Запросить новый код
          </StyledButton>
        )}
        <StyledButton
          disabled={btnDisabled || isLoading || isResendLoading}
          onClick={onSubmit}
          fullwidth
        >
          Продолжить
        </StyledButton>
      </CodeFormWrapper>
    </CodeCheckFormWrapper>
  )
}
