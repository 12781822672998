import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import CategoryProductsList from '../../features/category/ui/CategoryProductsList'
import FilterStorage from '../../shared/storage/FilterStorage/FilterStorage'
import { BottomMenuComponent } from '../../shared/ui-kit/actual/BottomMenuComponent'
import { MainHeader } from '../../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../../shared/ui-kit/actual/layouts/BasePageLayout'
import SortModalWindow from '../../shared/ui-kit/actual/modalsContent/SortModalWindow'
import { BaseWrapper } from '../../shared/ui-kit/projectComponents'

const BottomWrapper = styled.div`
  @media (min-width: 280px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: white;
  }
`

const CategoryPage = () => {
  const { setOrderBy, sorting } = useContext(FilterStorage)

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const [openSortMenu, setOpenSortMenu] = useState(false)

  return (
    <>
      <BasePageLayout>
        <MainHeader />
        <BaseWrapper>
          <CategoryProductsList setOpenSortMenu={setOpenSortMenu} />
          <BottomWrapper>
            <BottomMenuComponent />
          </BottomWrapper>
          <SortModalWindow
            handleMenu={() => {
              setOpenSortMenu(prev => !prev)
            }}
            openMenu={openSortMenu}
            selectedSort={sorting.orderType}
          />
        </BaseWrapper>
      </BasePageLayout>
    </>
  )
}

export default observer(CategoryPage)
