import React, { useState } from 'react'

import { useTransactionsCreatePaymentMutation } from '../../entities/transactions/queries'

export const usePayment = () => {
  const [amount, setAmount] = useState<number | string>('')
  const handleSetAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSafariButton(false)

    setAmount(e.target.value)
  }
  const [safariButton, setSafariButton] = useState(false)
  const {
    data,
    isLoading: isPaymentLoading,
    mutate: createPayment,
  } = useTransactionsCreatePaymentMutation({
    onSuccess: data => {
      if (
        navigator.userAgent.indexOf('Safari') != -1 ||
        navigator.userAgent.indexOf('Firefox') != -1
      ) {
        setSafariButton(true)

        return
      }
      setAmount('')
      window.open(data.confirmationUrl, '_blank', 'rel=noopener noreferrer')
    },
  })

  return {
    amount,
    createPayment,
    data,
    handleSetAmount,
    isPaymentLoading,
    safariButton,
    setAmount,
    setSafariButton,
  }
}
