import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import { ProductDeliveryType } from '../../../../shared/api/generated'
import { useAddAddress } from '../../../../shared/hooks/use-add-address'
import { useAddresses } from '../../../../shared/hooks/use-addresses'
import { InputSelect } from '../../../../shared/ui-kit/actual/InputSelect/InputSelect'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { AddressRow } from '../../../../shared/ui-kit/actual/components/AddressRow'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import { FlexRowBetween, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { verticalScroll } from '../../../../shared/ui-kit/scroll'
import { H2 } from '../../../../shared/ui-kit/titles'
import { useItemAddressEditForm } from '../../model/use-item-address-edit-form'
import { EditItemDelivery } from './EditItemDelivery'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
`

const Wrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    max-width: 100%;
  }
`

const AddressesList = styled.div`
  width: 100%;
  max-height: 300px;
  height: auto;
  overflow: auto;
  ${verticalScroll};
`

const NewAddressSection = styled.div`
  position: relative;
`

type Props = {
  categoryId: number
  deliveryCities: string[]
  deliveryPrice: number
  deliveryType: ProductDeliveryType
  id: number
  name: string
  pickupAddressId: number
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}
export const ItemAddressEdit = ({
  categoryId,
  deliveryCities,
  deliveryPrice,
  deliveryType,
  id,
  name,
  pickupAddressId,
  setViewModal,
  viewModal,
}: Props) => {
  const {
    clearErrors,
    errors,
    getValues,
    handleSubmit,
    isLoading,
    isSuccess,
    isValid,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useItemAddressEditForm()

  useEffect(() => {
    id && setValue('id', id)
    deliveryCities.length && setValue('deliveryCities', deliveryCities)
    deliveryPrice && setValue('deliveryPrice', deliveryPrice)
    deliveryType && setValue('deliveryType', deliveryType)
    pickupAddressId && setValue('pickupAddressId', pickupAddressId)
  }, [categoryId, deliveryCities, deliveryPrice, deliveryType, id, pickupAddressId, setValue])

  const closeModal = () => {
    setViewModal(false)
    setTimeout(() => {
      setValue('deliveryCities', deliveryCities)
      setValue('deliveryPrice', deliveryPrice)
      setValue('deliveryType', deliveryType)
      setValue('pickupAddressId', pickupAddressId)
    }, 1000)
  }

  useEffect(() => {
    isSuccess && setViewModal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])
  const { addresses, addressesLoading, createAddress, isSelectAddressLoading } = useAddresses()
  const {
    handleChangeAddressInput,
    handleSelectOption,
    inputClickHandler,
    inputValue,
    isLoadingAddress,
    isLoadingNewAddress,
    saveNewAddress,
    selectIsOpen,
    variantsAddresses,
  } = useAddAddress(createAddress)
  const [deliveryAddressId, setDeliveryAddressId] = useState(pickupAddressId)

  const [checkedAllRussia, setChecked] = useState(deliveryType === 'russiaAny')
  const [selectedCities, setSelectedCities] = useState(deliveryCities)

  return (
    <BaseModal setViewModal={closeModal} viewModal={viewModal}>
      <AbsoluteLoader isLoading={isLoading} />
      <Container>
        <EditItemDelivery
          checkedAllRussia={checkedAllRussia}
          selectedCities={selectedCities}
          setCheckedAllRussia={setChecked}
          setSelectedCities={setSelectedCities}
          setValue={setValue}
        />
        <hr style={{ height: '2px', width: '100%' }} />
        <Wrapper>
          <H2>Самовывоз</H2>
          <AddressesList>
            {addressesLoading || isSelectAddressLoading || isLoadingNewAddress ? (
              <PageLoader />
            ) : (
              addresses?.map(address => (
                <AddressRow
                  setSelectedAddress={id => {
                    setDeliveryAddressId(id)
                    setValue('pickupAddressId', id)
                  }}
                  address={address}
                  isLoading={addressesLoading}
                  isSelected={address.id === deliveryAddressId}
                  key={address.id}
                />
              ))
            )}
          </AddressesList>
          <NewAddressSection>
            <TextField
              inputProps={{
                onChange: e => handleChangeAddressInput(e.target.value),
                onClick: inputClickHandler,
                placeholder: 'Добавить новый адрес самовывоза',
                type: 'text',
                value: inputValue,
              }}
              label={'Адрес'}
              size={'md'}
              fullwidth
            />
            {inputValue.trim().length > 1 && selectIsOpen ? (
              <InputSelect
                isLoadingAddress={isLoadingAddress}
                selectOptions={variantsAddresses}
                setSelected={handleSelectOption}
              />
            ) : null}
            <StyledButton
              disabled={inputValue.trim().length < 5}
              onClick={saveNewAddress}
              style={{ marginTop: '50px' }}
              variant={'secondary'}
            >
              Добавить адрес самовывоза
            </StyledButton>
          </NewAddressSection>
        </Wrapper>
        <FlexRowBetween style={{ gap: '12px' }}>
          <StyledButton onClick={closeModal} type={'button'} variant={'outline'} fullwidth>
            Отменить
          </StyledButton>
          <StyledButton onClick={handleSubmit} fullwidth>
            Подтвердить
          </StyledButton>
        </FlexRowBetween>
      </Container>
    </BaseModal>
  )
}
