import { ReactNode, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { useModal } from '../../hooks/useModal'
import { verticalScroll } from '../scroll'
import { MiniModal } from './modals/MiniModal'

const InfoButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 1900;
  display: inline;
`
const InfoWrapper = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  background-color: ${colors.lightGray};
  border-radius: 10px;
  width: 360px;
  height: 400px;
  padding: 12px 16px;
  display: ${({ isHovered }) => (isHovered ? 'block' : 'none')};
  z-index: 2000;
  cursor: text;
  left: -60px;
  overflow: auto;
  bottom: 30px;
  box-shadow: 0px 0px 14px 0px #0101011a;
  & > p {
    text-align: left;
  }
  ${verticalScroll}
`

export const InfoDefects = ({
  content,
  title,
}: {
  content?: ReactNode | string
  title?: string
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleOnHover = () => {
    setIsHovered(true)
  }
  const handleOffHover = () => {
    setIsHovered(false)
  }

  const { setViewModal, viewModal } = useModal()

  const handleOpenModal = () => {
    if (content) {
      setViewModal(true)
    }
  }

  return (
    <>
      <InfoButtonWrapper>
        {title ? (
          <span onClick={handleOpenModal} style={{ color: colors.primary }}>
            {title}
          </span>
        ) : (
          <img alt={''} onClick={handleOpenModal} src={'/images/buttons/info.svg'} />
        )}
        <MiniModal setViewModal={setViewModal} viewModal={viewModal} minWidth>
          <p>{content}</p>
        </MiniModal>
      </InfoButtonWrapper>
    </>
  )
}
