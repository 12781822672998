import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-custom-alert'
import { useNavigate, useParams } from 'react-router-dom'

import { AxiosError } from 'axios'
import jwtDecode from 'jwt-decode'

import { useSMSSignInMutation, useSendAuthSMSMutation } from '../../../entities/auth/queries'
import { queryClient } from '../../../shared/api/query-client'
import { useCodeInput } from '../../../shared/hooks/use-code-input'
import { useInterval } from '../../../shared/hooks/use-interval'
import UserStorage, { UserFromJWT } from '../../../shared/storage/UserStorage/UserStorage'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  CodeCheckFormWrapper,
  CodeFormWrapper,
  CodeText,
  CodeWrapper,
  FlexRowBetween,
  MediumText,
  OneNumberInput,
  StyledButton,
  Subtitle,
} from '../../../shared/ui-kit/projectComponents'
import { formattedTimer } from '../../../shared/utils/formatedTimer'
import { RegisterTitle } from '../../register/ui/headers/RegisterTitle'

export const SMSSignInForm = () => {
  const navigate = useNavigate()
  const { phone } = useParams()
  const store = useContext(UserStorage)

  const {
    isLoading: isResendLoading,
    isSuccess: isResendSuccess,
    mutate: resendMutate,
  } = useSendAuthSMSMutation()

  const { data, error, isLoading, isSuccess, mutate } = useSMSSignInMutation({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка проверки СМС кода')
    },
    onSuccess: data => {
      store.setTokens({ accessToken: data?.accessToken, refreshToken: data?.refreshToken })
      const user = jwtDecode(data?.accessToken) as UserFromJWT

      store.setUser(user)
      store.setIsAuth(true)
      queryClient.invalidateQueries({ queryKey: ['get_my_profile_data'] })
      navigate('/')
    },
  })

  const { btnDisabled, code, onChange, onKeyDown, refs, refsReset } = useCodeInput()

  const { newInterval } = useInterval({ isResendSuccess, seconds: 60 })

  const onSubmit = e => {
    e.preventDefault()
    const codeStr = code.join('')

    mutate({ code: codeStr, phone })
  }

  useEffect(() => {
    if (isSuccess) {
      navigate('/')
    } else if (error) {
      setOneUnsuccess(true)
    }
  }, [error, isSuccess, navigate])

  const [oneUnsuccess, setOneUnsuccess] = useState(false)

  useEffect(() => {
    if (!btnDisabled && !oneUnsuccess) {
      const codeStr = code.join('')

      mutate({ code: codeStr, phone })
    }
  }, [btnDisabled, code, mutate, oneUnsuccess, phone])

  return (
    <CodeCheckFormWrapper>
      <AbsoluteLoader isLoading={isLoading || isResendLoading} />

      <div>
        <RegisterTitle title={'Телефон'} />
        <MediumText>
          Подтвердите номер телефона
          <br />
          {phone}
        </MediumText>
      </div>
      <CodeFormWrapper onSubmit={onSubmit}>
        <Subtitle>Код подтверждения</Subtitle>
        <CodeWrapper>
          {Object.values(refs).map((ref, idx) => (
            <OneNumberInput
              onChange={e => {
                onChange(idx, e)
              }}
              key={idx}
              maxLength={1}
              onKeyDown={e => onKeyDown(idx, e)}
              ref={ref}
              type={'tel'}
            />
          ))}
        </CodeWrapper>
        {newInterval !== 0 && (
          <FlexRowBetween>
            <CodeText>Запросить код через</CodeText>
            <CodeText>{formattedTimer(newInterval)}</CodeText>
          </FlexRowBetween>
        )}
        {newInterval === 0 && (
          <StyledButton
            onClick={() => {
              resendMutate({ phone })
              refsReset()
            }}
            disabled={isLoading || isResendLoading}
            type={'button'}
            variant={'secondary'}
            fullwidth
          >
            Запросить новый код
          </StyledButton>
        )}
        <StyledButton
          disabled={btnDisabled || isLoading || isResendLoading}
          onClick={onSubmit}
          fullwidth
        >
          Войти
        </StyledButton>
      </CodeFormWrapper>
    </CodeCheckFormWrapper>
  )
}
