import React, { useEffect } from 'react'

import styled from 'styled-components/macro'

import { GetProductInfoResponse, RentProductDeliveryType } from '../../../../shared/api/generated'
import { useAddAddress } from '../../../../shared/hooks/use-add-address'
import { useAddresses } from '../../../../shared/hooks/use-addresses'
import { InputSelect } from '../../../../shared/ui-kit/actual/InputSelect/InputSelect'
import { Paragraph } from '../../../../shared/ui-kit/actual/Paragraph'
import { SwitchTabs } from '../../../../shared/ui-kit/actual/SwitchTabs'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { AddressRow } from '../../../../shared/ui-kit/actual/components/AddressRow'
import { LocationInfo } from '../../../../shared/ui-kit/actual/components/LocationInfo'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumnBetween,
  FlexColumnStart,
  FlexRowBetween,
  MediumTextL,
  StyledButton,
  Title,
} from '../../../../shared/ui-kit/projectComponents'
import { H4 } from '../../../../shared/ui-kit/titles'

const AddressesList = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
`

const NewAddressSection = styled(FlexColumnBetween)`
  gap: 150px;
  position: relative;
  width: 100%;
`

interface SecondStepProps {
  activeTab: number
  currentStage: number
  deliveryAddressId: number
  productInfo: GetProductInfoResponse
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  setCurrentStage: (it: number) => void
  setDeliveryAddressId: React.Dispatch<React.SetStateAction<number>>
  setDeliveryType: (deliveryType: RentProductDeliveryType) => void
}

const SecondStep = ({
  activeTab,
  currentStage,
  deliveryAddressId,
  productInfo,
  setActiveTab,
  setCurrentStage,
  setDeliveryAddressId,
  setDeliveryType,
}: SecondStepProps) => {
  const { addresses, addressesLoading, createAddress, defaultAddressId, isSelectAddressLoading } =
    useAddresses()

  useEffect(() => {
    defaultAddressId && setDeliveryAddressId(defaultAddressId)
  }, [defaultAddressId, setDeliveryAddressId])

  const {
    handleChangeAddressInput,
    handleSelectOption,
    inputClickHandler,
    inputValue,
    isLoadingAddress,
    isLoadingNewAddress,
    saveNewAddress,
    selectIsOpen,
    variantsAddresses,
  } = useAddAddress(createAddress)

  return (
    <div style={{ height: '100%', paddingBottom: '64px' }}>
      <div style={{ width: '100%' }}>
        <Title style={{ marginBottom: '24px' }}>Выберите вариант получения</Title>
        <SwitchTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabHead0={'Самовывоз'}
          tabHead1={'Доставка'}
        />
        <div style={{ margin: '24px 0 8px' }}>
          {activeTab === 0 ? (
            <FlexColumnBetween style={{ gap: '32px' }}>
              {productInfo?.pickupAddress ? (
                <>
                  <TextField
                    inputProps={{
                      defaultValue: productInfo?.pickupAddress?.address,
                      disabled: true,
                      readOnly: true,
                    }}
                    right={<img alt={''} src={'/images/buttons/check-white-on-bg.svg'} />}
                    fullwidth
                  />
                  <LocationInfo pickupAddress={productInfo?.pickupAddress} />
                </>
              ) : (
                <Paragraph style={{ alignSelf: 'flex-start' }}>
                  Адрес самовывоза не указан арендодателем
                </Paragraph>
              )}

              <StyledButton
                onClick={() => {
                  setDeliveryType('pickup')
                  setCurrentStage(currentStage <= 3 ? currentStage + 1 : currentStage)
                }}
                disabled={!productInfo?.pickupAddress}
                style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
                variant={'primary'}
              >
                Заказать
              </StyledButton>
            </FlexColumnBetween>
          ) : (
            <FlexColumnBetween>
              {productInfo?.deliveryType === 'countryWithSelection' && (
                <FlexColumnStart style={{ alignSelf: 'flex-start' }}>
                  <Paragraph>Доставка доступна в городах: </Paragraph>
                  <MediumTextL>{productInfo.deliveryCities.join(', ')}</MediumTextL>
                </FlexColumnStart>
              )}{' '}
              {productInfo?.deliveryType === 'russiaAny' && (
                <FlexRowBetween style={{ alignSelf: 'flex-start' }}>
                  <Paragraph>В пределах РФ</Paragraph>
                </FlexRowBetween>
              )}
              {productInfo?.deliveryType === 'notAvailable' && (
                <FlexRowBetween style={{ alignSelf: 'flex-start' }}>
                  <Paragraph>Варианты доставки не указаны арендодателем</Paragraph>
                </FlexRowBetween>
              )}
              <H4>Ваш адрес для доставки</H4>
              <br />
              <FlexColumnBetween>
                <AbsoluteLoader isLoading={isSelectAddressLoading} />
                <AddressesList>
                  {addressesLoading && !isLoadingNewAddress ? (
                    <PageLoader />
                  ) : (
                    addresses?.map(address => (
                      <AddressRow
                        address={address}
                        isLoading={isSelectAddressLoading}
                        isSelected={address.id === deliveryAddressId}
                        key={address.id}
                        setSelectedAddress={setDeliveryAddressId}
                      />
                    ))
                  )}
                </AddressesList>
                <br />
                <NewAddressSection>
                  <TextField
                    inputProps={{
                      onChange: e => handleChangeAddressInput(e.target.value),
                      onClick: inputClickHandler,
                      placeholder: 'Введите новый адрес',
                      type: 'text',
                      value: inputValue,
                    }}
                    label={'Адрес'}
                    size={'md'}
                    fullwidth
                  />
                  {inputValue.trim().length > 1 && selectIsOpen ? (
                    <InputSelect
                      isLoadingAddress={isLoadingAddress}
                      selectOptions={variantsAddresses}
                      setSelected={handleSelectOption}
                    />
                  ) : null}
                  <StyledButton
                    disabled={inputValue.trim().length < 5}
                    onClick={saveNewAddress}
                    variant={'secondary'}
                    fullwidth
                  >
                    Добавить адрес
                  </StyledButton>
                </NewAddressSection>
              </FlexColumnBetween>
              <StyledButton
                onClick={() => {
                  setDeliveryType('delivery')
                  setCurrentStage(currentStage <= 3 ? currentStage + 1 : currentStage)
                }}
                disabled={productInfo?.deliveryType === 'notAvailable'}
                style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
                variant={'primary'}
              >
                Заказать
              </StyledButton>
            </FlexColumnBetween>
          )}
        </div>
      </div>
    </div>
  )
}

export default SecondStep
