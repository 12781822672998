import styled from 'styled-components/macro'

import { colors } from '../../../shared/constants/colors'
import { FlexRowGap } from '../../../shared/ui-kit/projectComponents'

const Wrapper = styled(FlexRowGap)`
  justify-content: space-between;
  .top {
    font-size: 11px;
    font-weight: 500;
    line-height: 23px;
    color: #008c8c;
  }
  .head {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    color: ${colors.black};
  }
  .bottom {
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    color: ${colors.gray};
  }
`

export const RentItem = () => {
  return (
    <Wrapper>
      <div>
        <div className={'top'}>Сдаю • 25 декабря • 15 000₽</div>
        <div className={'head'}>Bershka Mom Jeans</div>
        <div className={'bottom'}>Work • Greendale County, Colo...</div>
      </div>
      <img alt={''} src={'/images/interface/messageIconBg.svg'} />
    </Wrapper>
  )
}
