import { useState } from 'react'

import { useRentsGetRentsListQuery } from '../../../entities/rent'

interface useFiltredProps {
  endDateString: string
  startDateString: string
  userId?: string
}

export function useGetRentsAllList({ endDateString, startDateString, userId }: useFiltredProps) {
  const [take, setTake] = useState(120)

  const {
    data: fullData,
    isError,
    isLoading,
    isSuccess,
  } = useRentsGetRentsListQuery({
    // periodFrom: startDateString,
    // periodTo: endDateString,
    rentStatuses: ['created'],
    sellerId: userId,
    take,
    userId,
  })

  const data = fullData?.items || []

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    userId,
  }
}
