import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { FlexColumnStart } from '../../projectComponents'
import { verticalScroll } from '../../scroll'

const ModalBackground = styled.div<{ $viewModal: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100lvh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 2000;
  transition: 0.4s ease-in;
  visibility: ${({ $viewModal }) => ($viewModal ? '' : 'hidden')};
  opacity: ${({ $viewModal }) => ($viewModal ? '1' : '0')};

  & > div {
    transform: ${({ $viewModal }) => ($viewModal ? 'translateX(0)' : 'translateX(370px)')};
    transition: 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  }
`

const ModalWrapper = styled(FlexColumnStart)`
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 16px;
  width: 370px;
  min-height: 100%;
  box-sizing: border-box;
  padding: 28px 24px;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  gap: 24px;
  overflow: auto;
  ${verticalScroll}
`
const CloseWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 30px;
  cursor: pointer;
  z-index: 2100;
`

type Props = {
  children?: ReactNode
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}

const preventClick = e => {
  e.stopPropagation()
}

export const RightModal = ({ children, setViewModal, viewModal }: Props) => {
  return createPortal(
    <ModalBackground $viewModal={viewModal} onClick={() => setViewModal(false)}>
      <ModalWrapper onClick={preventClick}>
        <CloseWrapper onClick={() => setViewModal(false)}>
          <img alt={'close'} height={20} src={'/images/buttons/close.png'} width={20} />
        </CloseWrapper>
        {children}
      </ModalWrapper>
    </ModalBackground>,
    document.body
  )
}
