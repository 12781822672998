import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import styled from 'styled-components/macro'

import {
  useUpdatePasswordMutation,
  useUsersGetUserInfoQuery,
} from '../../../entities/ownProfile/queries'
import { LegalStatus, UpdatePasswordCommand } from '../../../shared/api/generated'
import {
  LEGAL_NAMES_LIST,
  LEGAL_STATUSES_OPTIONS,
  SelectLabelItem,
} from '../../../shared/constants/legalStatusList'
import {
  FULL_NAME_VALIDATION,
  NAME_VALIDATION,
  NO_CYRILLIC,
  PASSWORD_VALIDATION,
  PASSWORD_VALIDATION_LOGIN,
} from '../../../shared/constants/regex'
import { CITY_NAMES } from '../../../shared/constants/russiaCities'
import {
  invalidPassword,
  passwordMin,
  passwordRequired,
} from '../../../shared/constants/validationMessages'
import { useModal } from '../../../shared/hooks/useModal'
import { StyledSelect } from '../../../shared/ui-kit/SelectWrapper'
import { TextArea } from '../../../shared/ui-kit/actual/TextArea'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { MiniModal } from '../../../shared/ui-kit/actual/modals/MiniModal'
import { FlexRowBetween, MediumText, StyledButton } from '../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../shared/ui-kit/titles'
import { useProfileInfo } from '../../profile/model/use-profile-info'
import { useUpdateProfile } from '../model/use-update-profile'

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
`
const UpdatePasswordModal = ({
  setViewModal,
  viewModal,
}: {
  setViewModal: (v: boolean) => void
  viewModal: boolean
}) => {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<{ passwordConfirm: string } & UpdatePasswordCommand>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })
  const { isLoading, isSuccess, mutate } = useUpdatePasswordMutation()

  const submit = handleSubmit(data => {
    const { newPassword, oldPassword } = data

    mutate({ newPassword, oldPassword })
  })

  useEffect(() => {
    if (isSuccess) {
      setViewModal(false)
    }
  }, [isSuccess, setViewModal])

  useEffect(() => {
    if (!viewModal) {
      reset()
    }
  }, [reset, viewModal])

  return (
    <MiniModal setViewModal={setViewModal} viewModal={viewModal} minWidth>
      <AbsoluteLoader isLoading={isLoading} />
      <H3>Обновить пароль</H3>
      <br />
      <FormWrapper onSubmit={submit}>
        <MediumText>Текущий пароль</MediumText>
        <TextField
          inputProps={{
            placeholder: 'Старый пароль',
            type: 'password',
            ...register('oldPassword', {
              minLength: {
                message: passwordMin,
                value: 6,
              },
              pattern: {
                message: invalidPassword,
                value: PASSWORD_VALIDATION_LOGIN,
              },
              required: passwordRequired,
            }),
          }}
          error={errors.oldPassword?.message?.toString()}
          label={'Введите текущий пароль'}
          fullwidth
        />
        <br />
        <MediumText>Дважды введите новый пароль</MediumText>
        <TextField
          inputProps={{
            placeholder: 'Новый пароль',
            type: 'password',
            ...register('newPassword', {
              minLength: {
                message: passwordMin,
                value: 6,
              },
              pattern: {
                message: invalidPassword,
                value: PASSWORD_VALIDATION,
              },
              required: passwordRequired,
              validate: value => {
                if (value.match(NO_CYRILLIC)) {
                  clearErrors('newPassword')

                  return
                }

                return invalidPassword
              },
            }),
          }}
          error={errors.newPassword?.message?.toString()}
          label={'Введите новый пароль'}
          fullwidth
        />
        <TextField
          inputProps={{
            placeholder: 'Подтверждение нового пароля',
            type: 'password',
            ...register('passwordConfirm', {
              validate: value => value === getValues('newPassword') || 'Пароли должны совпадать',
            }),
          }}
          error={errors.passwordConfirm?.message?.toString()}
          label={'Подтвердите новый пароль'}
          fullwidth
        />
        <FlexRowBetween style={{ gap: '12px' }}>
          <StyledButton onClick={() => setViewModal(false)} type={'button'} variant={'outline'}>
            Отмена
          </StyledButton>
          <StyledButton disabled={!isValid} variant={'primary'} fullwidth>
            Подтвердить
          </StyledButton>
        </FlexRowBetween>
      </FormWrapper>
    </MiniModal>
  )
}

export const PersonalDataForm = () => {
  const { errors, handleSubmit, isValid, register, setValue, signUpMutation } = useUpdateProfile()
  const { userId, userInfo } = useProfileInfo()
  const { data } = useUsersGetUserInfoQuery(userId)
  const { setViewModal, viewModal } = useModal()

  const cities: SelectLabelItem[] = useMemo(
    () => CITY_NAMES.map(city => ({ label: city, value: city })),
    []
  )
  const [cityValue, setCityValue] = useState<SelectLabelItem>({
    label: 'Укажите город',
    value: 'city',
  })

  const setCity = e => {
    setCityValue({ label: e.label, value: e.value })
    setValue('city', e.value)
  }

  const [legalStatus, setLegalStatus] = useState<SelectLabelItem>({
    label: 'Юридический статус',
    value: '1',
  })

  const handleTypeSelect = e => {
    setLegalStatus({ label: e.label, value: e.value })
    setValue('legalStatus', e.value as LegalStatus)
  }

  useEffect(() => {
    setValue('city', data?.city)
    setValue('legalStatus', data?.legalStatus)
    setValue('userId', userInfo.id)
    setValue('accountName', data?.accountName)
    setValue('fullName', data?.fullName)
    setValue('description', data?.aboutItself)
    setValue('birthDate', data?.birthDate)
    data?.legalStatus &&
      setLegalStatus({ label: LEGAL_NAMES_LIST[data?.legalStatus], value: data?.legalStatus })
    data?.city && setCityValue({ label: data?.city, value: data?.city })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <FormWrapper onSubmit={handleSubmit}>
        <TextField
          inputProps={{
            placeholder: 'Никнейм',
            type: 'text',
            ...register('accountName', {
              maxLength: {
                message: 'Максимум 18 символов',
                value: 18,
              },
              minLength: {
                message: 'Минимум 2 символов',
                value: 2,
              },
              pattern: {
                message: 'Только латинские буквы и цифры',
                value: NAME_VALIDATION,
              },
              required: 'Поле никнейм обязательно для заполнения',
            }),
          }}
          error={errors.accountName?.message?.toString()}
          label={'Введите никнейм, используйте латинские буквы'}
          fullwidth
        />

        <TextField
          inputProps={{
            placeholder: data?.fullName || 'Полное имя',
            type: 'text',
            ...register('fullName', {
              maxLength: {
                message: 'Максимум 50 символов',
                value: 50,
              },
              minLength: {
                message: 'Минимум 2 символов',
                value: 2,
              },
              pattern: {
                message: 'Полное имя должно содержать только буквы',
                value: FULL_NAME_VALIDATION,
              },
              required: 'Полное имя обязательно для заполнения',
            }),
          }}
          error={errors.fullName?.message?.toString()}
          label={'Введите полное имя'}
          fullwidth
        />
        <TextArea
          inputProps={{
            defaultValue: `${data?.aboutItself || ''}`,
            placeholder: 'О себе',
            ...register('description', {}),
            rows: 7,
          }}
          fullwidth
        />

        <StyledSelect
          classNamePrefix={'Select'}
          noOptionsMessage={() => <div>Не найдено</div>}
          onChange={setCity}
          options={cities}
          value={cityValue}
        />
        <StyledSelect
          classNamePrefix={'Select'}
          noOptionsMessage={() => <div>Не найдено</div>}
          onChange={handleTypeSelect}
          options={LEGAL_STATUSES_OPTIONS}
          value={legalStatus}
          isDisabled
        />
        <TextField
          inputProps={{
            defaultValue: data?.phoneNumber,
            disabled: true,
            placeholder: 'Номер телефона',
            readOnly: true,
          }}
          label={'Номер телефона'}
          right={<img alt={''} src={'/images/buttons/check-white-on-bg.svg'} />}
          fullwidth
        />
        <TextField
          inputProps={{
            defaultValue: data?.email,
            disabled: true,
            placeholder: 'Почта',
            readOnly: true,
          }}
          label={'Почта'}
          right={<img alt={''} src={'/images/buttons/check-white-on-bg.svg'} />}
          fullwidth
        />
        <TextField
          inputProps={{
            defaultValue: '●●●●●●●●●●●',
            disabled: true,
            placeholder: 'Пароль',
            readOnly: true,
          }}
          label={'Пароль'}
          onClick={() => setViewModal(true)}
          right={<img alt={''} src={'/images/buttons/Edit-small.svg'} />}
          style={{ cursor: 'pointer' }}
          fullwidth
        />
        <StyledButton disabled={signUpMutation.isLoading || !!Object.keys(errors).length} fullwidth>
          Обновить профиль
        </StyledButton>
      </FormWrapper>
      <UpdatePasswordModal setViewModal={setViewModal} viewModal={viewModal} />
    </>
  )
}
