import { apiClient } from '../../serverConstants'

export enum DocumentStatus {
  Approved = 'Approved',
  NotUploaded = 'NotUploaded',
  Pending = 'Pending',
  Rejected = 'Rejected',
}
export enum DOCUMENT_STATUS {
  'Approved' = 2,
  'NotUploaded' = 0,
  'Pending' = 1,
  'Rejected' = 3,
}

export interface IResponsePasport {
  city: string
  firstName: string
  id: string
  lastName: string
  legalStatus: string
  middleName: string
  passport: {
    departmentCode: string
    id: number
    issuedAt: string
    issuedBy: string
    links: string[]
    number: string
    registrationAddress: string
    series: string
    status: DOCUMENT_STATUS
  }
  phone: string
  registeredAt: string
  userName: string
}

export interface IRequestPasport {
  FirstName?: string
  LastName?: string
  MiddleName?: string
  PassportRequest: {
    DepartmentCode?: string
    Images?: any[]
    IssuedAt?: string
    IssuedBy?: string
    Number?: string
    RegistrationAddress?: string
    Series?: string
  }
}

class DocumentService {
  async getPassport(token: string, documentStatus: string) {
    const response = await apiClient.get(`/documents/passport`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        documentStatus: documentStatus,
      },
    })

    return response.data
  }

  async updateStatusPassport(documentId: number, documentStatus: DocumentStatus, token: string) {
    const response = await apiClient.patch(
      `/documents/passport?newStatus=${documentStatus}`,
      [documentId],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }

  async uploadPassport(passport: FormData, token: string) {
    const response = await apiClient.post(`/documents/passport`, passport, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  }
}

export default new DocumentService()
