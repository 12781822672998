import React, { useState } from 'react'

import styled from 'styled-components/macro'

import { useUsersPagination } from '../features/admin/model/use-users-pagination'
import { AdminUsersTableBody } from '../features/admin/ui/AdminUsersTableBody'
import { AdminUsersTableHead } from '../features/admin/ui/AdminUsersTableHead'
import { DeleteUserModal } from '../features/admin/ui/DeleteUserModal'
import { SetRolesModal } from '../features/admin/ui/SetRolesModal'
import { UserBalanceModal } from '../features/admin/ui/UserBalanceModal'
import { colors } from '../shared/constants/colors'
import { useDebounce } from '../shared/hooks/use-debounce'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { devices } from '../shared/ui-kit/breakPoints'
import {
  FlexColumn,
  FlexRowBetween,
  PaginationWrapper,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H4, Head } from '../shared/ui-kit/titles'

const Table = styled.table`
  font-size: 14px;
  text-align: left;
  border-collapse: collapse;
  min-width: 90vw;
  align-self: flex-start;
  margin-inline: 12px;
  @media screen and (${devices.desktopMiddle}) {
    align-self: unset;
    margin: 0;
  }

  td,
  th {
    padding: 8px 8px 8px 0;
    min-width: 40;
    max-width: 250px;
    overflow: auto;
    font-size: 14px;
  }

  th {
    border-bottom: 1px solid ${colors.black};
  }
`

export const AdminUsersListPage = () => {
  const {
    debounceNameTerm: debounceFullNameTerm,
    nameTerm: fullNameTerm,
    setNameTerm: setFullNameTerm,
  } = useDebounce()
  const {
    debounceNameTerm: debounceUserNameTerm,
    nameTerm: userNameTerm,
    setNameTerm: setUserNameTerm,
  } = useDebounce()
  const {
    debounceNameTerm: debounceEmailTerm,
    nameTerm: emailTerm,
    setNameTerm: setEmailTerm,
  } = useDebounce()
  const {
    debounceNameTerm: debouncePhoneTerm,
    nameTerm: phoneTerm,
    setNameTerm: setPhoneTerm,
  } = useDebounce()

  const resetTerms = () => {
    setFullNameTerm('')
    setUserNameTerm('')
    setEmailTerm('')
    setPhoneTerm('')
    setPage(0)
  }

  const { allUsers, isLoadingAllUsers, page, refetch, setPage, totalPages } = useUsersPagination({
    emailTerm: debounceEmailTerm,
    fullNameTerm: debounceFullNameTerm,
    phoneTerm: debouncePhoneTerm,
    userNameTerm: debounceUserNameTerm,
  })

  const handleNameTerm = (
    fullName: string,
    setNameTerm: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setNameTerm(fullName)
    if (page > 0) {
      setPage(0)
    }
  }

  const [userId, setUserId] = useState<null | string>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [rolesOpen, setRolesOpen] = useState(false)
  const [paymentsOpen, setPaymentsOpen] = useState(false)

  return (
    <FlexColumn>
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      <Head style={{ margin: 0 }}>Список пользователей</Head>
      <H4 style={{ alignSelf: 'center', margin: 0, textAlign: 'center' }}>
        Выберете пользователя для редактирования его данных
      </H4>
      <br />

      <FlexRowBetween
        style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}
      >
        <p style={{ fontSize: 15, margin: 0, padding: 0 }}>Пользователей: {allUsers?.totalCount}</p>
        <StyledButton onClick={resetTerms} size={'sm'}>
          Сбросить поиск
        </StyledButton>
      </FlexRowBetween>

      <Table>
        <AdminUsersTableHead
          EmailSearch={
            <input onChange={e => handleNameTerm(e.target.value, setEmailTerm)} value={emailTerm} />
          }
          FullNameSearch={
            <input
              onChange={e => handleNameTerm(e.target.value, setFullNameTerm)}
              type={'search'}
              value={fullNameTerm}
            />
          }
          PhoneSearch={
            <input
              onChange={e => handleNameTerm(e.target.value, setPhoneTerm)}
              style={{ width: '100px' }}
              type={'search'}
              value={phoneTerm}
            />
          }
          UserNameSearch={
            <input
              onChange={e => handleNameTerm(e.target.value, setUserNameTerm)}
              type={'search'}
              value={userNameTerm}
            />
          }
        />
        <AdminUsersTableBody
          isLoading={isLoadingAllUsers}
          setDeleteOpen={setDeleteOpen}
          setPaymentsOpen={setPaymentsOpen}
          setRolesOpen={setRolesOpen}
          setUserId={setUserId}
          users={allUsers?.items}
        />
      </Table>

      <PaginationWrapper>
        <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
      </PaginationWrapper>
      {/* <SortModal
        handleMenu={handleUserModal}
        openMenu={openUserSortModal}
        selectedType={userSortType}
        selectors={userSortSelectors}
        setSelectedSort={setUserSortTypes}
      /> */}
      {userId && (
        <>
          <DeleteUserModal deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} userId={userId} />
          <SetRolesModal
            refetch={refetch}
            rolesOpen={rolesOpen}
            setRolesOpen={setRolesOpen}
            userId={userId}
          />
          <UserBalanceModal
            paymentsOpen={paymentsOpen}
            refetch={refetch}
            setPaymentsOpen={setPaymentsOpen}
            userId={userId}
          />
        </>
      )}
    </FlexColumn>
  )
}
