import { useContext, useState } from 'react'

import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'

import { useGetPaymentMethodsQuery } from '../../../entities/payments/queries'
import { BankCardListItemDto } from '../../../shared/api/generated'
import { useModal } from '../../../shared/hooks/useModal'
import UserStorage from '../../../shared/storage/UserStorage/UserStorage'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  AddPayCardModal,
  CardsList,
  DeletePayCardModal,
  NoContent,
} from '../../../shared/ui-kit/actual/components/PayCardsList'
import {
  FlexColumnBetween,
  FlexRowGap,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
const Wrapper = styled(FlexColumnBetween)``
// const mockData: BankCardListItemDto[] = [
//   { cardNumber: '2200300566993373', id: nanoid(), paymentSystem: 'Mir' },
//   { cardNumber: '5123760359128497', id: nanoid(), paymentSystem: 'Mastercard' },
//   { cardNumber: '4817760359138345', id: nanoid(), paymentSystem: 'Visa' },
// ]

// const mockData = []

export const PersonalDataPay = () => {
  const { userId } = useContext(UserStorage)

  const { data, isLoading } = useGetPaymentMethodsQuery({ userId })
  const { setViewModal: setViewAddModal, viewModal: viewAddModal } = useModal()
  const { setViewModal: setViewDeleteModal, viewModal: viewDeleteModal } = useModal()
  const handleOpenAddModal = () => setViewAddModal(true)
  const handleOpenDeleteModal = () => setViewDeleteModal(true)
  const [selectedId, setSelectedId] = useState('')
  const [selectedCardNumber, setSelectedCardNumber] = useState('')

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />

      <Wrapper>
        {data?.bankCards?.length ? (
          <>
            <CardsList
              data={data}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              setSelectedNumber={setSelectedCardNumber}
            />
            <FlexRowGap style={{ paddingTop: '16px' }}>
              <StyledButton
                disabled={!selectedId}
                onClick={handleOpenDeleteModal}
                variant={'secondary'}
                fullwidth
              >
                Удалить
              </StyledButton>
              <StyledButton onClick={handleOpenAddModal} fullwidth>
                Добавить
              </StyledButton>
            </FlexRowGap>
          </>
        ) : (
          <>
            <NoContent />
            <StyledButton onClick={handleOpenAddModal} fullwidth>
              Добавить
            </StyledButton>
          </>
        )}
      </Wrapper>
      <AddPayCardModal setViewModal={setViewAddModal} viewModal={viewAddModal}></AddPayCardModal>
      <DeletePayCardModal
        selectedCardId={selectedId}
        selectedCardNumber={selectedCardNumber}
        setViewModal={setViewDeleteModal}
        viewModal={viewDeleteModal}
      ></DeletePayCardModal>
    </>
  )
}
