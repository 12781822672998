import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../../shared/constants/colors'
import { InputImageNotCompress } from '../../../shared/ui-kit/actual/InputImageNotCompress'
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { horizontalScroll } from '../../../shared/ui-kit/scroll'

const cubes = [
  '/images/interface/passport-transparent.svg',
  '/images/interface/passport-transparent.svg',
  '/images/interface/passport-transparent.svg',
  '/images/interface/passport-transparent.svg',
  '/images/interface/passport-transparent.svg',
  '/images/interface/passport-transparent.svg',
]

const Wrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
`

const ImageWrapper = styled(FlexRow)`
  width: 100%;
  min-height: 207px;
  overflow: auto;
  margin-top: 16px;
  border-radius: 11px;
  background-color: ${colors.lightGray};
`

const SmallImagesWrapper = styled(FlexRow)<{ $alignEnd?: boolean }>`
  column-gap: clamp(6px, 2vw, 24px);
  margin-bottom: 8px;
  width: 100%;
  padding: 5px;
  justify-content: ${({ $alignEnd }) => ($alignEnd ? 'flex-end' : 'flex-start')};
  overflow: auto;
  ${horizontalScroll};
`

const SmallImageWrapper = styled(FlexColumn)<{ $isActive?: boolean }>`
  position: relative;
  max-width: 60px;
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin-top: 8px;
  border-radius: 11px;
  background-color: ${colors.lightGray};
  cursor: pointer;
  overflow: hidden;
  outline: ${({ $isActive }) => ($isActive ? `thick double  ${colors.primary}` : 'none')};
  & > img:nth-child(2) {
    position: absolute;
  }
`

type Props = {
  arePhotosSelected?: boolean
  setArePhotosSelected?: React.Dispatch<React.SetStateAction<boolean>>
  setImageFiles?: React.Dispatch<React.SetStateAction<File[]>>
}
export const PassportPhotosAdd = ({
  arePhotosSelected,
  setArePhotosSelected,
  setImageFiles,
}: Props) => {
  const [activeId, setActiveId] = useState(0)
  const [parentImageUrl, setParentImageUrl] = useState('')
  const [imageUrls, setImageUrls] = useState(['', '', '', '', '', ''])
  const ref = useRef(null)

  const handleChange = (file: File) => {
    setImageFiles(prevState =>
      prevState.map((img, idx) => {
        if (idx === activeId) {
          return file
        } else {
          return img
        }
      })
    )
  }
  const scrollToLast = () => {
    const lastChildElement = ref.current?.lastElementChild

    lastChildElement?.scrollIntoView({ behavior: 'smooth' })
  }
  const deleteImage = () => {
    if (activeId < 6) {
      setImageUrls(prevState =>
        prevState.map((img, idx) => {
          if (idx === activeId) {
            return ''
          } else {
            return img
          }
        })
      )
    } else {
      setImageUrls(prevState => prevState.filter((img, idx) => idx !== activeId || !img))
      setImageFiles(prevState => prevState.filter((img, idx) => idx !== activeId || !img))
    }
  }
  const handleUrlChange = (url: string) => {
    setImageUrls(prevState =>
      prevState.map((img, idx) => {
        if (idx === activeId) {
          return url
        } else {
          return img
        }
      })
    )
    const timeout = setTimeout(() => {
      setParentImageUrl(url)
      clearInterval(timeout)
    }, 500)
  }
  const handleSetActiveId = (id: number) => {
    setActiveId(id)
    setParentImageUrl(imageUrls[id])
  }

  useEffect(() => {
    const nextImageIdx = imageUrls.findIndex(i => !i)

    if (nextImageIdx !== -1) {
      setActiveId(nextImageIdx)
    } else if (imageUrls.length < 10) {
      const idx = imageUrls.length

      setImageUrls(prev => [...prev, ''])
      setImageFiles(prev => [...prev, null])
      setActiveId(idx)
    } else if (imageUrls.length === 10) {
      setActiveId(9)
    }
    if (activeId > 6) {
      scrollToLast()
    }
    if (imageUrls.filter(img => img).length >= 6) {
      setArePhotosSelected(true)
    } else {
      setArePhotosSelected(false)
    }
    setParentImageUrl(imageUrls[activeId])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrls])

  return (
    <Wrapper>
      <ImageWrapper>
        <InputImageNotCompress
          defaultImageNode={
            <img
              alt={''}
              height={84}
              src={cubes[activeId]}
              style={{ borderRadius: '0' }}
              width={84}
            />
          }
          handleChange={handleChange}
          handleUrlChange={handleUrlChange}
          parentImageUrl={parentImageUrl}
          variant={'full'}
        />
      </ImageWrapper>
      <SmallImagesWrapper ref={ref}>
        {imageUrls.map((image, idx) => (
          <SmallImageWrapper
            $isActive={idx === activeId}
            key={image + idx}
            onClick={() => (imageUrls[0] ? handleSetActiveId(idx) : () => {})}
          >
            {image ? (
              <>
                <img alt={''} src={image} style={{ height: '60px', objectFit: 'cover' }} />
                <img
                  alt={''}
                  src={cubes[idx]}
                  style={{ objectFit: 'contain', opacity: 0.7 }}
                  width={25}
                />
              </>
            ) : (
              <img alt={''} src={cubes[idx]} style={{ objectFit: 'contain' }} width={25} />
            )}
          </SmallImageWrapper>
        ))}
      </SmallImagesWrapper>
      <FlexRowBetween style={{ gap: '8px' }}>
        {arePhotosSelected ? (
          <InputImageNotCompress
            defaultImageNode={
              <StyledButton type={'button'} variant={'secondary'} fullwidth>
                {imageUrls[activeId] || imageUrls.length === 10
                  ? 'Изменить фото'
                  : 'Сделать еще фото'}
              </StyledButton>
            }
            handleChange={handleChange}
            handleUrlChange={handleUrlChange}
            variant={'full'}
          />
        ) : (
          <InputImageNotCompress
            defaultImageNode={
              <StyledButton type={'button'} fullwidth>
                {!imageUrls[0]
                  ? 'Загрузить главный разворот (2-3 стр)'
                  : 'Загрузить другие страницы'}
              </StyledButton>
            }
            handleChange={handleChange}
            handleUrlChange={handleUrlChange}
            variant={'full'}
          />
        )}
        <StyledButton
          onClick={deleteImage}
          style={{ flex: '0 1' }}
          type={'button'}
          variant={'secondary'}
        >
          <img alt={''} src={'/images/buttons/delete.svg'} />
        </StyledButton>
      </FlexRowBetween>
    </Wrapper>
  )
}
