import React from 'react'

import styled from 'styled-components/macro'

import { useGetRentInfoQuery } from '../../../../entities/rent'
import { PageLoader } from '../../deprecated/PageLoader'
import { FlexColumn } from '../../projectComponents'
import { H3 } from '../../titles'
import { SliderComponent } from '../Slider'
const Wrapper = styled(FlexColumn)`
  width: 100%;

  @media (min-width: 280px) {
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px;
    padding-bottom: 36px;
  }
  @media (min-width: 1100px) {
    justify-content: center;
    padding: 0px;
  }
`

export const AdminClaimPhotos = ({ rentId }: { rentId: number }) => {
  const { data, isLoading } = useGetRentInfoQuery(String(rentId))

  return (
    <Wrapper>
      {isLoading && <PageLoader />}
      <H3>До отправки (фото от арендодателя)</H3>
      {data && (
        <SliderComponent
          items={data?.beforeRentSellerImagesUrls?.map((image, index) => (
            <img
              alt={'product'}
              height={425}
              key={index}
              src={image}
              style={{ objectFit: 'contain' }}
              width={'100%'}
            />
          ))}
          slidesToShow={1}
          dots
        />
      )}
      <H3>До использования (фото от арендатора)</H3>
      {data && (
        <SliderComponent
          items={data?.beforeRentBuyerImagesUrls?.map((image, index) => (
            <img
              alt={'product'}
              height={425}
              key={index}
              src={image}
              style={{ objectFit: 'contain' }}
              width={'100%'}
            />
          ))}
          slidesToShow={1}
          dots
        />
      )}
      <H3>После использования (фото от арендатора)</H3>
      {data && (
        <SliderComponent
          items={data?.afterRentBuyerImagesUrls?.map((image, index) => (
            <img
              alt={'product'}
              height={425}
              key={index}
              src={image}
              style={{ objectFit: 'contain' }}
              width={'100%'}
            />
          ))}
          slidesToShow={1}
          dots
        />
      )}
      <H3>После возврата (фото от арендодателя)</H3>
      {data && (
        <SliderComponent
          items={data?.afterRentSellerImagesUrls?.map((image, index) => (
            <img
              alt={'product'}
              height={425}
              key={index}
              src={image}
              style={{ objectFit: 'contain' }}
              width={'100%'}
            />
          ))}
          slidesToShow={1}
          dots
        />
      )}
    </Wrapper>
  )
}
