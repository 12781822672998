import { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { menuLinks } from '../../constants/menuLinks'
import { mobileTabletView } from '../breakPoints'
import { FlexRow } from '../projectComponents'

const MenuWrapper = styled(FlexRow)`
  position: fixed;
  bottom: -1px;
  left: 0px;
  margin: 0;
  border-top: 1px solid ${colors.lightGray};
  background-color: white;
  width: 100vw;
  justify-content: center;
  align-items: center;
  border-radius: 30px 30px 0px 0px;
  z-index: 2000;
  box-shadow: 0px -5px 60px 0px rgba(0, 0, 0, 0.05);
  ${mobileTabletView}
`

const MenuContent = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 19px 37px 20px 37px;
`

const StyledLink = styled(Link)<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 40px;
  row-gap: 4px;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  img,
  svg {
    width: 20px;
    height: 20px;
  }

  p {
    color: ${({ $isActive }) => ($isActive ? colors.primary : colors.grey)};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 15px */
    margin: 0;
  }
`

export const BottomMenuComponent = () => {
  return (
    <MenuWrapper>
      <MenuContent>
        {menuLinks.map((item, idx) => (
          <MenuItem {...item} key={idx} />
        ))}
      </MenuContent>
    </MenuWrapper>
  )
}

const MenuItem: FC<{
  img: string
  imgActive: string
  name: string
  target?: string
  url: string
}> = ({ img, imgActive, name, target, url }) => {
  const location = useLocation()
  const [isActive, setIsActive] = useState(location.pathname === url)

  useEffect(() => {
    setIsActive(location.pathname === url)
  }, [location, url])

  return (
    <StyledLink $isActive={isActive} onClick={() => setIsActive(true)} target={target} to={url}>
      <img alt={''} src={isActive ? imgActive : img} />
      <p>{name}</p>
    </StyledLink>
  )
}
