import { ChangeEvent, ComponentPropsWithoutRef, FC, ReactNode } from 'react'
import { toast } from 'react-custom-alert'

import styled, { css } from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { filesCompression } from '../../utils/filesCompression'

type InputImageProps = {
  defaultImageNode?: ReactNode
  defaultImageUrl?: string
  disabled?: boolean
  handleChange?: (f: File) => void
  handleUrlChange: (url: string) => void
  inputProps?: ComponentPropsWithoutRef<'input'>
  parentImageUrl?: string
  variant?: TImageVariant
} & ComponentPropsWithoutRef<'div'>

export type TImageVariant = 'circle' | 'full' | 'horizontal' | 'square' | 'vertical'

const Wrapper = styled.div<{ disabled?: boolean; variant?: TImageVariant }>`
  ${({ variant }) =>
    ({
      circle: css`
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 1px 0 10px -3px rgba(0, 0, 0, 0.25);
      `,
      full: css`
        width: 100%;
        height: 100%;
        border-radius: 12px;
      `,
      horizontal: css`
        width: 327px;
        height: 165px;
        border-radius: 12px;
      `,
      square: css`
        width: 130px;
        height: 130px;
        border-radius: 12px;
      `,
      vertical: css`
        width: 327px;
        height: 424px;
        border-radius: 12px;
      `,
    })[variant]};

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGray};
  position: relative;

  input {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    cursor: pointer;
  }

  .__img {
    width: 100%;
    height: 100%;
  }
`

export const InputImageControlled: FC<InputImageProps> = props => {
  const {
    defaultImageNode,
    defaultImageUrl,
    disabled,
    handleChange,
    handleUrlChange,
    inputProps,
    parentImageUrl,
    variant = 'horizontal',
    ...other
  } = props

  const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files } = event.target
      const uploadableFile = files[0]
      const { compressedFile, size } = await filesCompression(uploadableFile)
      const url = URL.createObjectURL(compressedFile)

      if (size <= 5) {
        handleUrlChange(url)
        handleChange(compressedFile)
      } else {
        toast.error('Используйте файл до 5 мб')
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Wrapper {...other} disabled={disabled} variant={variant}>
      {parentImageUrl ? (
        <img alt={' '} className={'__img'} src={parentImageUrl} />
      ) : (
        defaultImageNode || (
          <img alt={' '} className={'empty__img'} src={'/images/interface/img.svg'} />
        )
      )}
      <input
        {...inputProps}
        accept={'.jpg,.jpeg,.png'}
        disabled={disabled}
        onChange={handleFile}
        type={'file'}
      />
    </Wrapper>
  )
}
