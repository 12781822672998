import { ChangeEvent, useCallback, useState } from 'react'
import { toast } from 'react-custom-alert'

import debounce from 'lodash.debounce'

import { AddUserAddressCommand } from '../api/generated'
import fetchGeoCode from '../api/geo-code-api'
import { queryClient } from '../api/query-client'
import { DEBOUNCE_TIMEOUT } from '../constants/transition'
type Props = (data: AddUserAddressCommand) => Promise<void>

export const useAddAddress = (createAddress: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [isLoadingNewAddress, setIsLoadingNewAddress] = useState(false)

  const saveNewAddress = async e => {
    e.preventDefault()
    setIsLoadingNewAddress(true)

    if (inputValue.length > 6) {
      try {
        const [data, point] = await fetchGeoCode(inputValue)
        const [longitude, latitude] = point.split(' ')
        const address =
          data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
            .GeocoderMetaData.Address.Components
        const fullLineAddress =
          data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
            .GeocoderMetaData.Address.formatted
        const postCode =
          data?.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
            .GeocoderMetaData.AddressDetails?.Country?.AdministrativeArea?.Locality?.Thoroughfare
            ?.Premise?.PostalCode?.PostalCodeNumber || ''

        const newAddress = {
          address: fullLineAddress,
          city: address[2].name,
          country: address[0].name,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          name: inputValue,
          postcode: postCode,
        }

        await createAddress(newAddress)
        setInputValue('')
        queryClient.invalidateQueries({ queryKey: ['get_user_addresses'] })
      } catch (error) {
        toast.error('Ошибка при отправке нового адреса')
      } finally {
        setIsLoadingNewAddress(false)
      }
    }
  }

  const [selectIsOpen, setSelectIsOpen] = useState(true)

  const inputClickHandler = () => {
    setSelectIsOpen(true)
  }

  const handleSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.textContent)
    setSelectIsOpen(prev => !prev)
  }

  const [isLoadingAddress, setIsLoadingAddress] = useState(false)
  const [variantsAddresses, setVariantAddresses] = useState([])

  const fetchAddress = async (searchValue: string) => {
    setIsLoadingAddress(true)

    if (searchValue.length >= 3) {
      try {
        const [data] = await fetchGeoCode(searchValue)

        setVariantAddresses(data?.response?.GeoObjectCollection?.featureMember)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingAddress(false)
      }
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedFetcher = useCallback(
    debounce(({ searchValue }) => {
      void fetchAddress(searchValue)
    }, DEBOUNCE_TIMEOUT),
    []
  )

  const handleChangeAddressInput = searchValue => {
    setInputValue(searchValue)
    debouncedFetcher({ searchValue })
  }

  return {
    handleChangeAddressInput,
    handleSelectOption,
    inputClickHandler,
    inputValue,
    isLoadingAddress,
    isLoadingNewAddress,
    saveNewAddress,
    selectIsOpen,
    variantsAddresses,
  }
}
