import styled from 'styled-components/macro'

import {
  EMAIL_VALIDATION,
  NO_CYRILLIC,
  PASSWORD_VALIDATION_LOGIN,
} from '../../../shared/constants/regex'
import {
  emailLabel,
  emailRequired,
  invalidEmail,
  invalidPassword,
  passwordMax,
  passwordMin,
  passwordRequired,
} from '../../../shared/constants/validationMessages'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import {
  FlexColumnStart,
  MediumText,
  NeutralLink,
  SmallTextGrey,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { useEmailSignInForm } from '../model/use-email-signin-form'

const Wrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 500px);
  margin: 0 auto;
`
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 24px;
  width: 100%;
`

export const EmailSignInForm = () => {
  const {
    errors,
    handleSubmit,
    isValid,
    register,

    signInMutation,
  } = useEmailSignInForm()

  return (
    <Wrapper>
      <FormWrapper onSubmit={handleSubmit}>
        <TextField
          inputProps={{
            placeholder: 'Введите email',
            type: 'email',
            ...register('email', {
              pattern: {
                message: invalidEmail,
                value: EMAIL_VALIDATION,
              },
              required: emailRequired,
            }),
          }}
          error={errors.email?.message?.toString()}
          label={emailLabel}
          fullwidth
        />
        <TextField
          inputProps={{
            placeholder: 'Введите пароль',
            type: 'password',
            ...register('password', {
              minLength: {
                message: passwordMin,
                value: 6,
              },
              pattern: {
                message: invalidPassword,
                value: PASSWORD_VALIDATION_LOGIN,
              },
              required: passwordRequired,
            }),
          }}
          error={errors.password?.message?.toString()}
          label={'Пароль'}
          fullwidth
        />
        <SmallTextGrey style={{ alignSelf: 'end' }}>
          <NeutralLink to={'/password-forgot'}>Забыли пароль?</NeutralLink>
        </SmallTextGrey>
        <StyledButton disabled={!isValid || signInMutation.isLoading} fullwidth>
          Войти
        </StyledButton>
      </FormWrapper>
      <br />
      <MediumText style={{ alignSelf: 'center' }}>или</MediumText>
      <br />
      <NeutralLink to={'/login-phone'}>
        <StyledButton variant={'secondary'} fullwidth>
          Вход по номеру телефона
        </StyledButton>
      </NeutralLink>
    </Wrapper>
  )
}
