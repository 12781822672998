import styled from 'styled-components/macro'

import { useAddAddress } from '../../../shared/hooks/use-add-address'
import { useAddresses } from '../../../shared/hooks/use-addresses'
import { InputSelect } from '../../../shared/ui-kit/actual/InputSelect/InputSelect'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { AddressRow } from '../../../shared/ui-kit/actual/components/AddressRow'
import { PageLoader } from '../../../shared/ui-kit/deprecated/PageLoader'
import { FlexColumnBetween, StyledButton } from '../../../shared/ui-kit/projectComponents'

const AddressesList = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
`

const NewAddressSection = styled(FlexColumnBetween)`
  gap: 150px;
  position: relative;
  width: 100%;
`

export const PersonalDataAddress = () => {
  const { addresses, addressesLoading, createAddress, handleChecked, isSelectAddressLoading } =
    useAddresses()

  const {
    handleChangeAddressInput,
    handleSelectOption,
    inputClickHandler,
    inputValue,
    isLoadingAddress,
    isLoadingNewAddress,
    saveNewAddress,
    selectIsOpen,
    variantsAddresses,
  } = useAddAddress(createAddress)

  return (
    <FlexColumnBetween>
      <AbsoluteLoader isLoading={isSelectAddressLoading} />
      <AddressesList>
        {addressesLoading && !isLoadingNewAddress ? (
          <PageLoader />
        ) : (
          addresses?.map(address => (
            <AddressRow
              address={address}
              isLoading={isSelectAddressLoading}
              isSelected={address.isDefault}
              key={address.id}
              setSelectedAddress={handleChecked}
            />
          ))
        )}
      </AddressesList>
      <br />
      <NewAddressSection>
        <TextField
          inputProps={{
            onChange: e => handleChangeAddressInput(e.target.value),
            onClick: inputClickHandler,
            placeholder: 'Введите новый адрес',
            type: 'text',
            value: inputValue,
          }}
          label={'Адрес'}
          size={'md'}
          fullwidth
        />
        {inputValue.trim().length > 1 && selectIsOpen ? (
          <InputSelect
            isLoadingAddress={isLoadingAddress}
            selectOptions={variantsAddresses}
            setSelected={handleSelectOption}
          />
        ) : null}
        <StyledButton
          disabled={inputValue.trim().length < 5}
          onClick={saveNewAddress}
          variant={'secondary'}
          fullwidth
        >
          Добавить адрес
        </StyledButton>
      </NewAddressSection>
    </FlexColumnBetween>
  )
}
