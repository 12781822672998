import styled from 'styled-components/macro'

import { RentStatus } from '../../../shared/api/generated'
import { colors } from '../../../shared/constants/colors'
import {
  FlexColumnBetween,
  FlexColumnStart,
  FlexRowBetween,
  FlexRowStart,
  NeutralLink,
  SmallText,
  SmallTextGrey,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { H5 } from '../../../shared/ui-kit/titles'
import { Image, ImageIcon, ImagesWrapper } from './OrderCard'

type OrderCardProps = {
  handleCheckbox?: () => void
  image?: string
  imageIcon?: string
  interval?: string
  isSelected?: boolean
  name?: string
  numberOfMessages?: number
  pledge?: number
  price?: number
  productId: number
  rentId: number
  status?: RentStatus
}

const Wrapper = styled(FlexRowBetween)`
  width: 340px;
  /* height: 136px; */
  position: relative;
  gap: 16px;
  padding: 0 0 24px;
  border-bottom: solid 1px ${colors.lightGrey};
`

const ContentWrapper = styled(FlexColumnStart)`
  height: 100%;
  width: 100%;
  justify-content: space-between;
  & > div {
    margin-bottom: 12px;
  }
`

export const ArchiveCardBuyer = ({
  handleCheckbox,
  image,
  imageIcon,
  interval,
  isSelected,
  name,
  numberOfMessages,
  pledge,
  price,
  productId,
  rentId,
  status,
}: OrderCardProps) => {
  return (
    <Wrapper>
      {/* <StyledCheckboxWrapper>
        <CheckBox checked={isSelected} setChecked={handleCheckbox} />
      </StyledCheckboxWrapper> */}
      <ImagesWrapper>
        <NeutralLink to={`/item/${productId}`}>
          <Image alt={name} src={image || '/images/interface/EmptyBox.svg'} />
          <ImageIcon alt={'brand'} src={imageIcon || '/images/buttons/user-avatar-small.svg'} />
        </NeutralLink>
      </ImagesWrapper>
      <ContentWrapper>
        <div>
          {status === 'cancelledByBuyer' && (
            <SmallText style={{ color: colors.red }}>Отменен заказчиком</SmallText>
          )}
          {(status === 'cancelledBySeller' || status === 'rejectedBySeller') && (
            <SmallText style={{ color: colors.red }}>Отменен арендодателем</SmallText>
          )}
          {(status === 'completedWithClaimByBuyer' || status === 'completedWithClaimBySeller') && (
            <SmallText style={{ color: colors.red }}>Возвращен из-за дефекта</SmallText>
          )}
          {status === 'completed' && (
            <SmallText style={{ color: colors.primary }}>Успешно арендован</SmallText>
          )}
          <H5>{name}</H5>
          <SmallTextGrey>{interval}</SmallTextGrey>
        </div>
        <FlexColumnBetween style={{ gap: '6px', width: '100%' }}>
          {status === 'completed' && (
            <SmallTextGrey style={{ alignSelf: 'flex-end', color: colors.primary }}>
              Залог {pledge}₽
            </SmallTextGrey>
          )}
          <FlexRowBetween style={{ alignItems: 'flex-end', width: '100%' }}>
            <FlexRowStart style={{ gap: '8px' }}>
              {(status === 'completedWithClaimByBuyer' ||
                status === 'completedWithClaimBySeller' ||
                status === 'completed') && (
                <NeutralLink to={`/delivery/receive/${rentId}`}>
                  <StyledButton size={'s'} variant={'secondary'}>
                    <img alt={'chat'} src={'/images/buttons/star-empty.svg'} />
                  </StyledButton>
                </NeutralLink>
              )}
              <NeutralLink to={`/item/${productId}`}>
                <StyledButton size={'s'} style={{ position: 'relative' }} variant={'secondary'}>
                  <img alt={'chat'} src={'/images/buttons/cart-gray.svg'} />
                </StyledButton>
              </NeutralLink>
            </FlexRowStart>
            {status === 'completed' && <SmallTextGrey>Потрачено {price}₽</SmallTextGrey>}
          </FlexRowBetween>
        </FlexColumnBetween>
      </ContentWrapper>
    </Wrapper>
  )
}
