import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { useGetClaimInfoQuery } from '../entities/claims/queries'
import { useGetWithdrawalRequestInfoQuery } from '../entities/payments/queries'
import { colors } from '../shared/constants/colors'
import { LEGAL_NAMES_LIST } from '../shared/constants/legalStatusList'
import { operationsStatuses } from '../shared/constants/transactions'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { Paragraph } from '../shared/ui-kit/actual/Paragraph'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { AdminApproveClaimModal } from '../shared/ui-kit/actual/components/AdminApproveClaimModal'
import { AdminApproveWithdrawalModal } from '../shared/ui-kit/actual/components/AdminApproveWithdrawalModal'
import { AdminClaimPhotos } from '../shared/ui-kit/actual/components/AdminClaimPhotos'
import { translatesObj } from '../shared/ui-kit/actual/components/AdminClaimsTable'
import { AdminRejectClaimModal } from '../shared/ui-kit/actual/components/AdminRejectClaimModal'
import { AdminRejectWithdrawalModal } from '../shared/ui-kit/actual/components/AdminRejectWithdrawalModal'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { Spacer } from '../shared/ui-kit/deprecated/Spacer'
import {
  BasePage,
  FlexColumn,
  FlexColumnBetween,
  FlexRow,
  FlexRowBetween,
  FlexRowGap,
  FlexRowWrap,
  MediumText,
  MediumTextBold,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H3, H4, H5 } from '../shared/ui-kit/titles'

const DataElement = styled(FlexColumn)<{ $isWarning?: boolean }>`
  justify-content: start;
  align-items: start;
  align-self: start;
  margin: 5px;
  background-color: ${props => (props.$isWarning ? colors.lightRed : colors.lightGray)};
  border-radius: 15px;
  padding: 10px 15px;
`
const DataTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin: 8px 0 4px;
`
const DataInfo = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  text-align: left;
`
const BorderLine = styled.div`
  width: calc(100% - 32px);
  margin: 20px 0;
  border: 0.5px solid ${colors.lightGray};
`

const AdminWithdrawalPage = () => {
  const params = useParams()
  const withdrawalRequestId = params['withdrawalRequestId']
  const { data, isLoading } = useGetWithdrawalRequestInfoQuery({
    withdrawalRequestId,
  })

  const [rejectModal, setRejectModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)

  return (
    <BasePage>
      <AbsoluteLoader isLoading={isLoading} />
      <MainHeader />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      {data && (
        <>
          <FlexRowWrap
            style={{
              alignItems: 'flex-start',
              alignSelf: 'flex-start',
              gap: '24px',
              justifyContent: 'flex-start',
            }}
          >
            <DataElement $isWarning>
              <DataTitle>Запрос от</DataTitle>
              <DataInfo>
                <Link to={`/users/${data.user.id}`}>{data.user.userName}</Link> <br />{' '}
                <Link to={`/admin/users/${data.user.id}`}>{data.user.fullName}</Link> <br />{' '}
              </DataInfo>
            </DataElement>
            <DataElement>
              <DataInfo>
                Дата создания: {format(new Date(data.createdOn), 'dd MMMM HH:mm', { locale: ru })}
              </DataInfo>
              <DataInfo>Статус: {operationsStatuses[data.status] || data.status} </DataInfo>
              {data.comment && <DataInfo>Комментарий: {data.comment} </DataInfo>}
            </DataElement>
            <DataElement>
              <DataTitle>Сумма:</DataTitle>
              <DataInfo>{data.withdrawalAmount} </DataInfo>
              <DataTitle>Баланс пользователя</DataTitle>
              <DataInfo>Баланс до операции: {data.balanceBeforeWithdrawal}</DataInfo>
              <DataInfo>
                Баланс после{' '}
                {data.status === 'created' || data.status === 'approved' ? 'операции:' : 'отмены: '}{' '}
                {data.balanceAfterWithdrawal}
              </DataInfo>
            </DataElement>
          </FlexRowWrap>
          <BorderLine />
          <DataElement>
            <H5>{data.bankAccount.bankName}</H5>
            <MediumText>Расчетный счет: {data.bankAccount.paymentAccount}</MediumText>
            <MediumText>Корреспондентский счет: {data.bankAccount.correspondentBank}</MediumText>
            <MediumText>БИК: {data.bankAccount.bankIdentificationCode}</MediumText>
            <MediumText>ИНН: {data.bankAccount.inn}</MediumText>
            {data.bankAccount.kpp && <MediumText>КПП: {data.bankAccount.kpp}</MediumText>}
            <MediumText style={{ color: colors.primary }}>
              {data.bankAccount.companyName}
            </MediumText>
            <MediumText>{LEGAL_NAMES_LIST[data.bankAccount.legalStatus]}</MediumText>
          </DataElement>
          <BorderLine />
          {data.status === 'created' && (
            <FlexRowGap style={{ width: '100%' }}>
              <StyledButton onClick={() => setRejectModal(true)} size={'xl'} variant={'danger'}>
                Отклонить заявку
              </StyledButton>
              <StyledButton onClick={() => setApproveModal(true)} size={'xl'}>
                Подтвердить вывод
              </StyledButton>
            </FlexRowGap>
          )}

          <Spacer />
          <AdminRejectWithdrawalModal
            rejectModal={rejectModal}
            setOpen={setRejectModal}
            withdrawalRequestId={data.withdrawalRequestId}
          />
          <AdminApproveWithdrawalModal
            approveModal={approveModal}
            setOpen={setApproveModal}
            withdrawalRequestId={data.withdrawalRequestId}
          />
        </>
      )}
    </BasePage>
  )
}

export default AdminWithdrawalPage
