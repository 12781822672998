import { FC, ReactNode } from 'react'

import styled from 'styled-components/macro'

import { devices } from '../../breakPoints'
import { FlexColumn } from '../../projectComponents'
import { BottomMenuComponent } from '../BottomMenuComponent'

const Wrapper = styled(FlexColumn)`
  margin-top: 36px;
  overflow-y: scroll;
  width: 100%;
  box-sizing: border-box;
  @media screen and (${devices.desktopMin}) {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    wrap: no-wrap;
    gap: 50px;
    align-items: start;
    justify-content: space-between;
  }
`

export const ProfileLayout: FC<{ body?: ReactNode; head?: ReactNode }> = ({ body, head }) => {
  return (
    <>
      <Wrapper>
        {head}
        {body}
      </Wrapper>
      <BottomMenuComponent />
    </>
  )
}
