import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  ArchiveProductCommand,
  DeleteProductCommand,
  GetArchivedProductInfoQuery,
  GetArchivedProductInfoResponse,
  GetProductInfoQuery,
  GetProductInfoResponse,
  RestoreProductCommand,
  UpdateProductDeliveryCommand,
  UpdateProductInfoCommand,
  UpdateProductPriceCommand,
  postApiProductsArchiveProduct,
  postApiProductsDeleteProduct,
  postApiProductsGetArchivedProductInfo,
  postApiProductsGetProductInfo,
  postApiProductsRestoreProduct,
  postApiProductsUpdateProductDelivery,
  postApiProductsUpdateProductInfo,
  postApiProductsUpdateProductPrice,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useGetMarketItemQuery(
  params: GetProductInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetProductInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки товара')
    },
    queryFn: () => postApiProductsGetProductInfo(params),
    queryKey: ['get_market_item', String(params.productId)],
    refetchOnWindowFocus: false,
    retry: 1,
    // retryDelay: 1000,
    ...options,
  })
}

export function useGetArchivedProductInfoQuery(
  params: GetArchivedProductInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetArchivedProductInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки товара')
    },
    queryFn: () => postApiProductsGetArchivedProductInfo(params),
    queryKey: ['get_archive_market_item', String(params.productId)],
    refetchOnWindowFocus: false,
    retry: 1,
    ...options,
  })
}

export function useDeleteProductMutation(
  options?: UseMutationOptions<unknown, unknown, DeleteProductCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: DeleteProductCommand) => {
      return postApiProductsDeleteProduct(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления объявления')
    },
    onSuccess: () => {
      toast.info('Вы удалили объявление')
      queryClient.invalidateQueries({ queryKey: 'get_products' })
      queryClient.invalidateQueries({ queryKey: 'get_archive_market_item' })
      queryClient.invalidateQueries({ queryKey: 'get_market_item' })
      queryClient.invalidateQueries({ queryKey: 'get_archive_products' })
    },
    ...options,
  })
}

export function useProductsArchiveProductMutation(
  options?: UseMutationOptions<unknown, unknown, ArchiveProductCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ArchiveProductCommand) => {
      return postApiProductsArchiveProduct(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка архивации объявления')
    },
    onSuccess: () => {
      toast.info('Вы скрыли объявление')
      queryClient.invalidateQueries({ queryKey: 'get_archive_market_item' })
      queryClient.invalidateQueries({ queryKey: 'get_market_item' })
      queryClient.invalidateQueries({ queryKey: 'get_products' })
      queryClient.invalidateQueries({ queryKey: 'get_archive_products' })
    },
    ...options,
  })
}

export function useProductsRestoreProductMutation(
  options?: UseMutationOptions<unknown, unknown, RestoreProductCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RestoreProductCommand) => {
      return postApiProductsRestoreProduct(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка возврата объявления из корзины')
    },
    onSuccess: () => {
      toast.info('Вы вернули объявление в общий доступ')
      queryClient.invalidateQueries({ queryKey: 'get_archive_market_item' })
      queryClient.invalidateQueries({ queryKey: 'get_market_item' })
      queryClient.invalidateQueries({ queryKey: 'get_products' })
      queryClient.invalidateQueries({ queryKey: 'get_archive_products' })
    },
    ...options,
  })
}

export function useUpdateProductInfoMutation(
  options?: UseMutationOptions<unknown, unknown, UpdateProductInfoCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: UpdateProductInfoCommand) => {
      return postApiProductsUpdateProductInfo(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка редактирования объявления')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_market_item'] })
    },
    ...options,
  })
}
export function useUpdateProductPriceMutation(
  options?: UseMutationOptions<unknown, unknown, UpdateProductPriceCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: UpdateProductPriceCommand) => {
      return postApiProductsUpdateProductPrice(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка редактирования объявления')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_market_item'] })
    },
    ...options,
  })
}
export function useUpdateProductDeliveryMutation(
  options?: UseMutationOptions<unknown, unknown, UpdateProductDeliveryCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: UpdateProductDeliveryCommand) => {
      return postApiProductsUpdateProductDelivery(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка редактирования объявления')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_market_item'] })
    },
    ...options,
  })
}
