import React from 'react'
import { MutateOptions } from 'react-query'
import { useLocation } from 'react-router-dom'

import { CreatePaymentCommand, CreatePaymentResponse } from '../../../api/generated'
import { symbols } from '../../../constants/symbols'
import { InlineLoader } from '../../deprecated/InlineLoader'
import { FlexRow, StyledButton, StyledInput, StyledLink } from '../../projectComponents'
type Props = {
  amount: number | string
  confirmationUrl: string
  createPayment: (
    variables: CreatePaymentCommand,
    options?: MutateOptions<CreatePaymentResponse, unknown, CreatePaymentCommand, unknown>
  ) => void
  handleSetAmount: (e: React.ChangeEvent<HTMLInputElement>) => void
  isPaymentLoading: boolean
  safariButton: boolean
  setAmount: (amount: number | string) => void
  setSafariButton: (prev: boolean) => void
}
export const PaymentBlock = ({
  amount,
  confirmationUrl,
  createPayment,
  handleSetAmount,
  isPaymentLoading,
  safariButton,
  setAmount,
  setSafariButton,
}: Props) => {
  const location = useLocation()

  const returnUrl = location.pathname + location.search

  return (
    <FlexRow style={{ gap: '24px', justifyContent: 'center' }}>
      <div>
        <StyledInput
          min={0}
          onChange={handleSetAmount}
          style={{ borderRadius: '10px', height: '30px', padding: '20px', width: '120px' }}
          type={'number'}
          value={amount}
        />{' '}
        {symbols.ruble}
      </div>
      {safariButton ? (
        <StyledLink
          onClick={() =>
            setTimeout(() => {
              setSafariButton(false)
              setAmount('')
            }, 500)
          }
          style={{ margin: 0, width: '130px' }}
          target={'_blank'}
          to={confirmationUrl || ''}
        >
          Заплатить
        </StyledLink>
      ) : (
        <StyledButton
          onClick={() => {
            createPayment({ amount: +amount, returnUrl })
          }}
          disabled={!amount || isPaymentLoading}
          size={'sm'}
          style={{ margin: 0, width: '130px' }}
          variant={'outline'}
        >
          {/* {isPaymentLoading ? <InlineLoader /> : 'Пополнить'} */}
          {isPaymentLoading ? <InlineLoader /> : 'Пополнить'}
        </StyledButton>
      )}
    </FlexRow>
  )
}
