import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useRejectClaimMutation } from '../../../../entities/claims/queries'
import { FlexColumn, FlexRowBetween, StyledButton } from '../../projectComponents'
import { H3 } from '../../titles'
import { MiniModal } from '../modals/MiniModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const ContentModal = styled(FlexColumn)`
  padding-bottom: 20px;
  width: 100%;
`

interface RejectClaimModalProps {
  claimId: number
  rejectModal: boolean
  setOpen: (v: boolean) => void
}

export const AdminRejectClaimModal = ({ claimId, rejectModal, setOpen }: RejectClaimModalProps) => {
  const navigate = useNavigate()

  const { isLoading, isSuccess, mutate } = useRejectClaimMutation()
  const CloseModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    isSuccess && navigate(-1)
  }, [isSuccess, navigate])

  return (
    <MiniModal setViewModal={setOpen} viewModal={rejectModal} minWidth>
      <AbsoluteLoader isLoading={isLoading} />
      <br />
      <H3 style={{ marginBottom: 20 }}>Отклонить жалобу и не выплачивать компенсацию?</H3>

      <ContentModal>
        <FlexRowBetween style={{ gap: '24px' }}>
          <StyledButton onClick={CloseModal} size={'xl'} variant={'outline'}>
            Отмена
          </StyledButton>
          <StyledButton
            onClick={() => {
              mutate({ claimId })
            }}
            disabled={isLoading}
            size={'xl'}
            variant={'danger'}
          >
            Отклонить жалобу
          </StyledButton>
        </FlexRowBetween>
      </ContentModal>
    </MiniModal>
  )
}
