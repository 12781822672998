/* eslint-disable perfectionist/sort-imports */
/* eslint-disable max-lines */
/* eslint-disable no-redeclare */
/* eslint-disable no-duplicate-imports */
/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * DI.AllSharing.Backend
 * OpenAPI spec version: 1.0
 */
import { createInstance } from './serverConstants'
import type { BodyType } from './serverConstants'
export type PostApiUtilsAddDataFromExcelBody = {
  formFile?: Blob
}

export type PostApiUsersUpdateUserAvatarBody = {
  avatarImage?: Blob
  userId?: string
}

export type GetApiUserAddressesGetUserAddressInfoParams = {
  AddressId?: number
}

export type GetApiUserAddressesGetUserAddressesParams = {
  UserId?: string
}

export type GetApiTransactionsGetTransactionsParams = {
  From?: string
  OrderType?: TransactionsOrderType
  PaymentStatuses?: PaymentStatus[]
  PayoutStatuses?: PayoutStatus[]
  Skip?: number
  Take?: number
  To?: string
  Type?: TransactionType
  UserId?: string
}

export type PostApiSectionsUpdateSectionImageBody = {
  image?: Blob
  sectionId?: number
}

export type PostApiRentsCompleteWithClaimByBuyerBody = {
  ImageBackSide?: Blob
  ImageDownSide?: Blob
  ImageFrontSide?: Blob
  ImageLeftSide?: Blob
  ImageRightSide?: Blob
  ImageUpSide?: Blob
  Message?: string
  OtherImages?: Blob[]
  RentId?: number
}

export type PostApiRentsCompleteWithClaimBySellerBody = {
  ImageBackSide?: Blob
  ImageDownSide?: Blob
  ImageFrontSide?: Blob
  ImageLeftSide?: Blob
  ImageRightSide?: Blob
  ImageUpSide?: Blob
  Message?: string
  OtherImages?: Blob[]
  RentId?: number
  SelectedDefectFinesIds?: number[]
}

export type PostApiRentsArriveBackToSellerBody = {
  ImageBackSide?: Blob
  ImageDownSide?: Blob
  ImageFrontSide?: Blob
  ImageLeftSide?: Blob
  ImageRightSide?: Blob
  ImageUpSide?: Blob
  OtherImages?: Blob[]
  RentId?: number
}

export type PostApiRentsSendBackToSellerBody = {
  ImageBackSide?: Blob
  ImageDownSide?: Blob
  ImageFrontSide?: Blob
  ImageLeftSide?: Blob
  ImageRightSide?: Blob
  ImageUpSide?: Blob
  OtherImages?: Blob[]
  RentId?: number
}

export type PostApiRentsArriveToBuyerBody = {
  ImageBackSide?: Blob
  ImageDownSide?: Blob
  ImageFrontSide?: Blob
  ImageLeftSide?: Blob
  ImageRightSide?: Blob
  ImageUpSide?: Blob
  OtherImages?: Blob[]
  RentId?: number
}

export type PostApiRentsSendToBuyerBody = {
  ImageBackSide?: Blob
  ImageDownSide?: Blob
  ImageFrontSide?: Blob
  ImageLeftSide?: Blob
  ImageRightSide?: Blob
  ImageUpSide?: Blob
  OtherImages?: Blob[]
  RentId?: number
}

export type GetApiProductsGetProductTagsListParams = {
  NameTerm?: string
  SectionId?: number
  Skip?: number
  Take?: number
}

export type PostApiProductsCreateProductBody = {
  CategoryId?: number
  DayPrice?: number
  DefectFines?: DefectFineDto[]
  DeliveryCities?: string[]
  DeliveryPrice?: number
  DeliveryType?: ProductDeliveryType
  Description?: string
  Images?: Blob[]
  IsPremium?: boolean
  MonthPrice?: number
  Name?: string
  PickupAddressId?: number
  Pledge?: number
  ProductTagsIds?: number[]
  ThreeDaysPrice?: number
  WeekPrice?: number
}

export type GetApiFilesGetFilesListParams = {
  FileNameTerm?: string
  OwnerId?: string
  Skip?: number
  Take?: number
  Types?: FileType[]
}

export type PostApiFilesUploadFileBody = {
  file?: Blob
}

export type PostApiFilesUploadFileParams = {
  type?: FileType
}

export type PostApiDocumentsAddLegalEntityInfoBody = {
  Address?: string
  BankIdentificationCode?: string
  BudgetClassificationCode?: string
  CharterFirstPage?: Blob
  CompanyName?: string
  CorrespondentBank?: string
  EstablishmentAgreement?: Blob
  EstablishmentProtocol?: Blob
  Inn?: string
  InnCertificate?: Blob
  Kpp?: string
  LegalEntityType?: string
  PaymentAccount?: string
  RegistrationNumber?: string
  USRLESheet?: Blob
  UserId?: string
}

export type PostApiDocumentsAddPassportBody = {
  BirthDate?: string
  FullName?: string
  IssuedBy?: string
  IssuedOn?: string
  MainImage?: Blob
  Number?: string
  OtherImages?: Blob[]
  PlaceOfResidence?: string
  Series?: string
  SubdivisionCode?: string
  UserId?: string
}

export type PostApiChatsSendChatMessageBody = {
  ChatId?: number
  Files?: Blob[]
  Message?: string
}

export type GetApiChatsGetChatMessagesParams = {
  ChatId?: number
  Skip?: number
  Take?: number
}

export type GetApiChatsGetChatsListParams = {
  RentId?: number
  Skip?: number
  Take?: number
  UserId?: string
}

export type PostApiCategoriesUpdateCategoryImageBody = {
  categoryId?: number
  image?: Blob
}

export type PostApiCategoriesCreateCategoryBody = {
  Image?: Blob
  Name?: string
  ParentCategoryId?: number
  SectionId?: number
}

export type GetApiAuthCheckEmailRegistrationCodeParams = {
  code?: string
  email?: string
}

export type GetApiAuthCheckPhoneRegistrationCodeParams = {
  code?: string
  phone?: string
}

export type PostApiArticlesCreateArticleBody = {
  ArticlesTagsIds?: number[]
  CategoryId?: number
  Content?: string
  Image?: Blob
  SectionId?: number
  Title?: string
}

export interface WithdrawalRequestDto {
  bankAccountId?: string
  /** @nullable */
  comment?: null | string
  createdOn?: string
  status?: OperationStatus
  /** @nullable */
  updatedOn?: null | string
  userId?: string
  userName?: string
  withdrawalAmount?: number
  withdrawalRequestId?: string
}

export interface ViewChatMessageCommand {
  messageId?: number
}

export interface ViewArticleCommand {
  articleId?: number
}

export interface VerifyPassportCommand {
  passportId?: string
}

export interface VerifyLegalEntityInfoCommand {
  legalEntityInfoId?: string
}

export type VerificationStatus = (typeof VerificationStatus)[keyof typeof VerificationStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationStatus = {
  undefined: 'undefined',
  verificationError: 'verificationError',
  verificationInProgress: 'verificationInProgress',
  verificationRejected: 'verificationRejected',
  verified: 'verified',
} as const

export interface VerifiedDocumentDto {
  id?: string
  /** @nullable */
  rejectComment?: null | string
  /** @nullable */
  verificationError?: null | string
  verificationStatus?: VerificationStatus
}

export interface ValidateQrCodeResponse {
  isValid?: boolean
}

export interface ValidateQrCodeCommand {
  code?: string
  rentId?: number
}

export interface UsersStatistic {
  activeUsersCount?: number
  activeUsersSummaryBalance?: number
  activeUsersSummaryBonusBalance?: number
}

export interface UserSubscriptionDto {
  description?: string
  fullName?: string
  userId?: string
}

export interface UserSubscriberDto {
  description?: string
  fullName?: string
  isSubscribed?: boolean
  userId?: string
}

export interface UserReferralDto {
  fullName?: string
  userId?: string
  userName?: string
}

export interface UserListItem {
  /** @nullable */
  avatarImageUrl?: null | string
  email?: string
  fullName?: string
  id?: string
  phone?: string
  roles?: RoleType[]
  userName?: string
}

export interface UserAddressDto {
  address?: string
  city?: string
  country?: string
  id?: number
  isDefault?: boolean
  latitude?: number
  longitude?: number
  name?: string
  postcode?: string
}

export interface UploadFileResponse {
  fileId?: string
}

export interface UpdateUserRolesCommand {
  rolesIds?: string[]
  userId?: string
}

export interface UpdateUserInfoCommand {
  accountName?: string
  /** @nullable */
  birthDate?: null | string
  city?: string
  description?: string
  fullName?: string
  legalStatus?: LegalStatus
  userId?: string
}

export interface UpdateSectionCommand {
  name?: string
  sectionId?: number
}

export interface UpdateReviewCommand {
  content?: string
  rating?: number
  reviewId?: number
}

export interface UpdateProductPriceCommand {
  /** @nullable */
  dayPrice?: null | number
  id?: number
  /** @nullable */
  monthPrice?: null | number
  pledge?: number
  /** @nullable */
  threeDaysPrice?: null | number
  /** @nullable */
  weekPrice?: null | number
}

export interface UpdateProductInfoCommand {
  categoryId?: number
  description?: string
  id?: number
  name?: string
}

export interface UpdateProductDeliveryCommand {
  deliveryCities?: string[]
  /** @nullable */
  deliveryPrice?: null | number
  deliveryType?: ProductDeliveryType
  id?: number
  /** @nullable */
  pickupAddressId?: null | number
}

export interface UpdatePasswordCommand {
  newPassword?: string
  oldPassword?: string
}

export interface UpdateCategoryCommand {
  categoryId?: number
  name?: string
}

export interface UpdateArticleCommand {
  articleId?: number
  articleTags?: number[]
  categoryId?: number
  content?: string
  sectionId?: number
  title?: string
}

export interface UnsubscribeCommand {
  userId?: string
}

export type TransactionsOrderType =
  (typeof TransactionsOrderType)[keyof typeof TransactionsOrderType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionsOrderType = {
  byAscendingAmount: 'byAscendingAmount',
  byAscendingDate: 'byAscendingDate',
  byDefault: 'byDefault',
  byDescendingAmount: 'byDescendingAmount',
  byDescendingDate: 'byDescendingDate',
} as const

export type TransactionType = (typeof TransactionType)[keyof typeof TransactionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionType = {
  payment: 'payment',
  payout: 'payout',
} as const

export interface TransactionOwnerDto {
  fullName?: string
  id?: string
  userName?: string
}

export interface TransactionDto {
  amount?: number
  createdAt?: string
  id?: string
  owner?: TransactionOwnerDto
  status?: string
  type?: TransactionType
}

export interface SubscribeCommand {
  userId?: string
}

export interface StringTermFilter {
  criterionId?: number
  /** @nullable */
  value?: null | string
}

export interface StringOneOfFilter {
  criterionId?: number
  /** @nullable */
  values?: null | string[]
}

export interface StringExactFilter {
  criterionId?: number
  /** @nullable */
  value?: null | string
}

export type SortOrder = (typeof SortOrder)[keyof typeof SortOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortOrder = {
  asc: 'asc',
  desc: 'desc',
} as const

export interface SignUpCommand {
  /** @nullable */
  birthDate?: null | string
  city?: string
  description?: string
  email?: string
  emailConfirmationCode?: string
  fullName?: string
  legalStatus?: LegalStatus
  password?: string
  phoneConfirmationCode?: string
  phoneNumber?: string
  /** @nullable */
  referralCode?: null | string
  userName?: string
}

export interface SignInPhoneByPasswordCommand {
  password?: string
  phone?: string
}

export interface SignInPhoneByCodeCommand {
  code?: string
  phone?: string
}

export interface SignInEmailCommand {
  email?: string
  password?: string
}

export interface ShareArticleCommand {
  articleId?: number
}

export interface SendRegistrationCodeBySmsCommand {
  phone?: string
}

export interface SendRegistrationCodeByEmailCommand {
  email?: string
}

export interface SendCodeResponse {
  nextCodeRequestTime?: string
}

export interface SendAuthCodeCommand {
  phone?: string
}

export interface SectionListItemDto {
  id?: number
  /** @nullable */
  imageUrl?: null | string
  name?: string
  productsCount?: number
}

export type RoleType = (typeof RoleType)[keyof typeof RoleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleType = {
  admin: 'admin',
  blogManager: 'blogManager',
  chatManager: 'chatManager',
  premium: 'premium',
  productManager: 'productManager',
  rentManager: 'rentManager',
  requestManager: 'requestManager',
  reviewManager: 'reviewManager',
  roleManager: 'roleManager',
  undefined: 'undefined',
  user: 'user',
  verified: 'verified',
} as const

export interface RoleDto {
  id?: string
  type?: RoleType
}

export interface ReviewsStatistic {
  fiveStars?: number
  fourStars?: number
  oneStar?: number
  threeStars?: number
  twoStars?: number
}

export type ReviewType = (typeof ReviewType)[keyof typeof ReviewType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewType = {
  aboutBuyer: 'aboutBuyer',
  aboutProduct: 'aboutProduct',
  aboutSeller: 'aboutSeller',
  undefined: 'undefined',
} as const

export interface ReviewListItemDto {
  authorId?: string
  /** @nullable */
  buyerAvatarImageUrl?: null | string
  buyerFullName?: string
  buyerId?: string
  buyerUserName?: string
  content?: string
  createOn?: string
  productId?: number
  productImagesUrls?: string[]
  productName?: string
  rating?: number
  reviewId?: number
  reviewType?: ReviewType
  /** @nullable */
  sellerAvatarImageUrl?: null | string
  sellerFullName?: string
  sellerId?: string
  sellerUserName?: string
}

export interface RestoreProductCommand {
  productId?: number
}

export interface ResetPasswordCommand {
  newPassword?: string
  resetToken?: string
  userId?: string
}

export interface RentsStatistic {
  activeRentsCount?: number
}

export type RentStatus = (typeof RentStatus)[keyof typeof RentStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RentStatus = {
  approvedBySeller: 'approvedBySeller',
  cancelledByBuyer: 'cancelledByBuyer',
  cancelledBySeller: 'cancelledBySeller',
  completed: 'completed',
  completedWithClaimByBuyer: 'completedWithClaimByBuyer',
  completedWithClaimBySeller: 'completedWithClaimBySeller',
  created: 'created',
  inUseByBuyer: 'inUseByBuyer',
  qrCodeGenerated: 'qrCodeGenerated',
  qrCodeValidated: 'qrCodeValidated',
  rejectedBySeller: 'rejectedBySeller',
  sentBackToSeller: 'sentBackToSeller',
  sentToBuyer: 'sentToBuyer',
} as const

export type RentProductDeliveryType =
  (typeof RentProductDeliveryType)[keyof typeof RentProductDeliveryType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RentProductDeliveryType = {
  delivery: 'delivery',
  pickup: 'pickup',
  undefined: 'undefined',
} as const

export interface RentPeriod {
  periodEnd?: string
  periodStart?: string
}

export interface RentListItem {
  address?: UserAddressDto
  buyerId?: string
  /** @nullable */
  buyerImageUrl?: null | string
  buyerName?: string
  deliveryType?: RentProductDeliveryType
  hasReviewAboutBuyer?: boolean
  hasReviewAboutProduct?: boolean
  hasReviewAboutSeller?: boolean
  periodFrom?: string
  periodTo?: string
  pledge?: number
  price?: number
  productId?: number
  productImagesUrls?: string[]
  productName?: string
  rentId?: number
  sellerId?: string
  /** @nullable */
  sellerImageUrl?: null | string
  sellerName?: string
  status?: RentStatus
  unreadMessagesCount?: number
}

export interface RentDefectFineRentDto {
  amount?: number
  defectName?: string
  id?: number
}

export interface RemoveUserAddressCommand {
  id?: number
}

export interface RemovePassportCommand {
  passportId?: string
}

export interface RemoveLegalEntityInfoCommand {
  legalEntityInfoId?: string
}

export interface RemoveBankCardCommand {
  bankCardId?: string
  ownerId?: string
}

export interface RemoveBankAccountCommand {
  bankAccountId?: string
  ownerId?: string
}

export interface RejectWithdrawalRequestCommand {
  comment?: string
  withdrawalRequestId?: string
}

export interface RejectPassportVerificationCommand {
  /** @nullable */
  comment?: null | string
  passportId?: string
}

export interface RejectLegalEntityInfoVerificationCommand {
  /** @nullable */
  comment?: null | string
  legalEntityInfoId?: string
}

export interface RejectClaimCommand {
  claimId?: number
}

export interface RejectBySellerCommand {
  rentId?: number
}

export interface RefreshTokenCommand {
  accessToken?: string
  refreshToken?: string
}

export interface ProductsStatistic {
  activeProductsCount?: number
}

export type ProductsListOrderType =
  (typeof ProductsListOrderType)[keyof typeof ProductsListOrderType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductsListOrderType = {
  byDate: 'byDate',
  byDayPrice: 'byDayPrice',
  byDefault: 'byDefault',
  byMonthPrice: 'byMonthPrice',
  byRating: 'byRating',
  byRentsCount: 'byRentsCount',
  byWeekPrice: 'byWeekPrice',
} as const

export interface ProductTagDto {
  id?: number
  name?: string
}

export type ProductStatus = (typeof ProductStatus)[keyof typeof ProductStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductStatus = {
  active: 'active',
  archived: 'archived',
  deleted: 'deleted',
  undefined: 'undefined',
} as const

export interface ProductOwnerDto {
  /** @nullable */
  avatarUrl?: null | string
  fullName?: string
  id?: string
  userName?: string
}

export interface ProductListItem {
  createdOn?: string
  /** @nullable */
  dayPrice?: null | number
  description?: string
  imagesUrls?: string[]
  isPremium?: boolean
  /** @nullable */
  monthPrice?: null | number
  name?: string
  pledge?: number
  productId?: number
  rating?: number
  reviewsCount?: number
  status?: ProductStatus
  /** @nullable */
  threeDaysPrice?: null | number
  /** @nullable */
  weekPrice?: null | number
}

export type ProductDeliveryType = (typeof ProductDeliveryType)[keyof typeof ProductDeliveryType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductDeliveryType = {
  city: 'city',
  countryWithSelection: 'countryWithSelection',
  notAvailable: 'notAvailable',
  russiaAny: 'russiaAny',
} as const

export interface PickupAddressDto {
  address?: string
  city?: string
  country?: string
  id?: number
  latitude?: number
  longitude?: number
  name?: string
  postcode?: string
}

export type PayoutStatus = (typeof PayoutStatus)[keyof typeof PayoutStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayoutStatus = {
  canceled: 'canceled',
  pending: 'pending',
  succeeded: 'succeeded',
} as const

export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentStatus = {
  canceled: 'canceled',
  pending: 'pending',
  succeeded: 'succeeded',
  waitingForCapture: 'waitingForCapture',
} as const

export interface PassportsListItem {
  fullName?: string
  number?: string
  ownerId?: string
  passportId?: string
  /** @nullable */
  rejectComment?: null | string
  series?: string
  userName?: string
  /** @nullable */
  verificationError?: null | string
  verificationStatus?: VerificationStatus
}

export interface OperationUserDto {
  fullName?: string
  id?: string
  userName?: string
}

export type OperationStatus = (typeof OperationStatus)[keyof typeof OperationStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperationStatus = {
  approved: 'approved',
  cancelled: 'cancelled',
  created: 'created',
  rejected: 'rejected',
  undefined: 'undefined',
} as const

export interface OperationListItem {
  accountOwner?: OperationUserDto
  balanceDiff?: number
  bonusBalanceDiff?: number
  changeType?: BalanceChangeType
  /** @nullable */
  comment?: null | string
  createdOn?: string
  operationId?: string
  status?: OperationStatus
}

export interface NumberOneOfFilter {
  criterionId?: number
  values?: number[]
}

export interface NumberFromToFilter {
  criterionId?: number
  /** @nullable */
  valueFrom?: null | number
  /** @nullable */
  valueTo?: null | number
}

export interface NotificationObject {
  id?: string
  status?: string
}

export interface MakeUserAddressDefaultCommand {
  id?: number
}

export type LegalStatus = (typeof LegalStatus)[keyof typeof LegalStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalStatus = {
  individual: 'individual',
  legalEntity: 'legalEntity',
  selfEmployed: 'selfEmployed',
  soleProprietor: 'soleProprietor',
} as const

export interface LegalEntityInfosListItem {
  companyName?: string
  inn?: string
  /** @nullable */
  kpp?: null | string
  legalEntityInfoId?: string
  legalEntityType?: string
  ownerId?: string
  /** @nullable */
  rejectComment?: null | string
  userName?: string
  /** @nullable */
  verificationError?: null | string
  verificationStatus?: VerificationStatus
}

export interface HandlePostbackCommand {
  event?: string
  object?: NotificationObject
}

export interface GroupedCriteriaDto {
  criteria?: CriterionDto[]
  groupName?: string
}

export interface GetWithdrawalRequestsListResponse {
  items?: WithdrawalRequestDto[]
  totalCount?: number
}

export interface GetWithdrawalRequestsListQuery {
  skip?: number
  take?: number
  /** @nullable */
  userId?: null | string
}

export interface GetWithdrawalRequestInfoResponse {
  actor?: OperationUserDto
  balanceAfterWithdrawal?: number
  balanceBeforeWithdrawal?: number
  bankAccount?: BankAccountDto
  /** @nullable */
  comment?: null | string
  createdOn?: string
  status?: OperationStatus
  /** @nullable */
  updatedOn?: null | string
  user?: OperationUserDto
  withdrawalAmount?: number
  withdrawalRequestId?: string
}

export interface GetWithdrawalRequestInfoQuery {
  withdrawalRequestId?: string
}

export interface GetUsersListResponse {
  items?: UserListItem[]
  totalCount?: number
}

export interface GetUsersListQuery {
  /** @nullable */
  emailTerm?: null | string
  /** @nullable */
  fullNameTerm?: null | string
  /** @nullable */
  phoneTerm?: null | string
  skip?: number
  take?: number
  /** @nullable */
  userNameTerm?: null | string
}

export interface GetUserSubscriptionsResponse {
  items?: UserSubscriptionDto[]
  totalCount?: number
}

export interface GetUserSubscriptionsQuery {
  skip?: number
  take?: number
  userId?: string
}

export interface GetUserSubscribersResponse {
  items?: UserSubscriberDto[]
  totalCount?: number
}

export interface GetUserSubscribersQuery {
  skip?: number
  take?: number
  userId?: string
}

export interface GetUserReferralsResponse {
  items?: UserReferralDto[]
  totalCount?: number
}

export interface GetUserReferralsQuery {
  skip?: number
  take?: number
  userId?: string
}

export interface GetUserRatingResponse {
  rating?: number
  ratingBuyer?: number
  ratingSeller?: number
  reviewsCount?: number
  reviewsStatistic?: ReviewsStatistic
}

export interface GetUserRatingQuery {
  userId?: string
}

export interface GetUserProfileResponse {
  aboutItself?: string
  accountName?: string
  accountType?: AccountType
  /** @nullable */
  avatarLink?: null | string
  balanceUser?: BalanceUserDto
  buyerRating?: number
  fullName?: string
  id?: string
  isSubscribed?: boolean
  sellerRating?: number
  subscribersCount?: number
  subscriptionsCount?: number
}

export interface GetUserProfileQuery {
  userId?: string
}

export interface GetUserInfoResponse {
  aboutItself?: string
  accountName?: string
  accountType?: AccountType
  /** @nullable */
  birthDate?: null | string
  city?: string
  email?: string
  fullName?: string
  id?: string
  legalEntityInfo?: VerifiedDocumentDto
  legalStatus?: LegalStatus
  newRentsAsSellerCount?: number
  passport?: VerifiedDocumentDto
  phoneNumber?: string
  /** @nullable */
  profileImageUrl?: null | string
  /** @nullable */
  referrerId?: null | string
  roles?: RoleDto[]
}

export interface GetUserInfoQuery {
  userId?: string
}

export interface GetUserAddressesResponse {
  addresses?: UserAddressDto[]
}

export interface GetUserAddressInfoResponse {
  address?: string
  city?: string
  country?: string
  id?: number
  isDefault?: boolean
  latitude?: number
  longitude?: number
  name?: string
  ownerId?: string
  postcode?: string
}

export interface GetTransactionsResponse {
  items?: TransactionDto[]
  totalCount?: number
}

export interface GetSectionsListResponse {
  sections?: SectionListItemDto[]
}

export interface GetSectionsListQuery {
  [key: string]: any
}

export interface GetSectionInfoResponse {
  categoriesCount?: number
  id?: number
  name?: string
  /** @nullable */
  sectionImageUrl?: null | string
}

export interface GetSectionInfoQuery {
  id?: number
}

export interface GetRolesResponse {
  roles?: RoleDto[]
}

export interface GetReviewsListResponse {
  items?: ReviewListItemDto[]
  totalCount?: number
}

export interface GetReviewsListQuery {
  /** @nullable */
  buyerId?: null | string
  /** @nullable */
  productId?: null | number
  reviewType?: ReviewType
  /** @nullable */
  sellerId?: null | string
  skip?: number
  take?: number
  /** @nullable */
  userId?: null | string
}

export interface GetRentsListResponse {
  items?: RentListItem[]
  totalCount?: number
}

export interface GetRentsListQuery {
  /** @nullable */
  buyerId?: null | string
  /** @nullable */
  periodFrom?: null | string
  /** @nullable */
  periodTo?: null | string
  /** @nullable */
  rentStatuses?: RentStatus[] | null
  /** @nullable */
  sellerId?: null | string
  skip?: number
  take?: number
  /** @nullable */
  userId?: null | string
}

export interface GetRentInfoResponse {
  address?: UserAddressDto
  afterRentBuyerImagesUrls?: string[]
  afterRentSellerImagesUrls?: string[]
  beforeRentBuyerImagesUrls?: string[]
  beforeRentSellerImagesUrls?: string[]
  buyerId?: string
  buyerName?: string
  deliveryType?: RentProductDeliveryType
  hasReviewAboutBuyer?: boolean
  hasReviewAboutProduct?: boolean
  hasReviewAboutSeller?: boolean
  periodFrom?: string
  periodTo?: string
  pledge?: number
  price?: number
  productId?: number
  productName?: string
  rentId?: number
  sellerId?: string
  sellerName?: string
  status?: RentStatus
  unreadMessagesCount?: number
}

export interface GetRentInfoQuery {
  rentId?: number
}

export interface GetRentDefectFinesListResponse {
  items?: RentDefectFineRentDto[]
}

export interface GetRentDefectFinesListQuery {
  rentId?: number
}

export interface GetProductsListResponse {
  items?: ProductListItem[]
  totalCount?: number
}

export interface GetProductsListQuery {
  /** @nullable */
  categoryId?: null | number
  /** @nullable */
  dayPriceFrom?: null | number
  /** @nullable */
  dayPriceTo?: null | number
  /** @nullable */
  numberFromToFilters?: NumberFromToFilter[] | null
  /** @nullable */
  numberOneOfFilters?: NumberOneOfFilter[] | null
  orderType?: ProductsListOrderType
  /** @nullable */
  ownerId?: null | string
  premiumOnly?: boolean
  /** @nullable */
  productNameTerm?: null | string
  /** @nullable */
  productTagsIds?: null | number[]
  /** @nullable */
  ratingFrom?: null | number
  /** @nullable */
  ratingTo?: null | number
  /** @nullable */
  sectionId?: null | number
  skip?: number
  sortOrder?: SortOrder
  /** @nullable */
  stringExactFilters?: StringExactFilter[] | null
  /** @nullable */
  stringOneOfFilters?: StringOneOfFilter[] | null
  /** @nullable */
  stringTermFilters?: StringTermFilter[] | null
  take?: number
}

export interface GetProductTagsListResponse {
  items?: ProductTagDto[]
  totalCount?: number
}

export interface GetProductRentPriceResponse {
  price?: number
}

export interface GetProductRentPriceQuery {
  periodFrom?: string
  periodTo?: string
  productId?: number
}

export interface GetProductRentPeriodsResponse {
  periods?: RentPeriod[]
}

export interface GetProductRentPeriodsQuery {
  /** @nullable */
  periodFrom?: null | string
  /** @nullable */
  periodTo?: null | string
  productId?: number
}

export interface GetProductInfoResponse {
  categoryId?: number
  categoryName?: string
  createdOn?: string
  /** @nullable */
  dayPrice?: null | number
  defectFines?: DefectFineExtendedDto[]
  deliveryCities?: string[]
  /** @nullable */
  deliveryPrice?: null | number
  deliveryType?: ProductDeliveryType
  description?: string
  id?: number
  imagesUrls?: string[]
  isPremium?: boolean
  /** @nullable */
  monthPrice?: null | number
  name?: string
  owner?: ProductOwnerDto
  pickupAddress?: PickupAddressDto
  pledge?: number
  productTags?: ProductTagDto[]
  rating?: number
  rentCyclesCount?: number
  reviewsCount?: number
  sectionId?: number
  sectionName?: string
  status?: ProductStatus
  /** @nullable */
  threeDaysPrice?: null | number
  /** @nullable */
  weekPrice?: null | number
}

export interface GetProductInfoQuery {
  productId?: number
}

export interface GetPaymentMethodsResponse {
  bankAccounts?: BankAccountDto[]
  bankCards?: BankCardListItemDto[]
}

export interface GetPaymentMethodsQuery {
  userId?: string
}

export interface GetPassportsListResponse {
  items?: PassportsListItem[]
  totalCount?: number
}

export interface GetPassportsListQuery {
  skip?: number
  take?: number
  /** @nullable */
  verificationStatuses?: VerificationStatus[] | null
}

export interface GetPassportInfoResponse {
  birthDate?: string
  fullName?: string
  issuedBy?: string
  issuedOn?: string
  /** @nullable */
  mainImageUrl?: null | string
  number?: string
  otherImagesUrls?: string[]
  ownerId?: string
  passportId?: string
  placeOfResidence?: string
  /** @nullable */
  rejectComment?: null | string
  series?: string
  subdivisionCode?: string
  userName?: string
  /** @nullable */
  verificationError?: null | string
  verificationStatus?: VerificationStatus
}

export interface GetPassportInfoQuery {
  /** @nullable */
  passportId?: null | string
  /** @nullable */
  userId?: null | string
}

export interface GetOperationsListResponse {
  items?: OperationListItem[]
  totalCount?: number
}

export interface GetOperationsListQuery {
  /** @nullable */
  rentId?: null | number
  skip?: number
  take?: number
  /** @nullable */
  userId?: null | string
}

export interface GetOperationInfoResponse {
  accountOwner?: OperationUserDto
  actor?: OperationUserDto
  balance?: number
  balanceDiff?: number
  bonusBalance?: number
  bonusBalanceDiff?: number
  changeType?: BalanceChangeType
  createdOn?: string
  id?: string
  /** @nullable */
  rentId?: null | number
  status?: OperationStatus
  /** @nullable */
  transactionId?: null | string
  /** @nullable */
  updatedOn?: null | string
}

export interface GetOperationInfoQuery {
  operationId?: string
}

export interface GetLegalEntityInfosListResponse {
  items?: LegalEntityInfosListItem[]
  totalCount?: number
}

export interface GetLegalEntityInfosListQuery {
  skip?: number
  take?: number
  /** @nullable */
  verificationStatuses?: VerificationStatus[] | null
}

export interface GetLegalEntityInfoResponse {
  address?: string
  bankIdentificationCode?: string
  /** @nullable */
  budgetClassificationCode?: null | string
  /** @nullable */
  charterFirstPageUrl?: null | string
  companyName?: string
  correspondentBank?: string
  /** @nullable */
  establishmentAgreementUrl?: null | string
  /** @nullable */
  establishmentProtocolUrl?: null | string
  inn?: string
  /** @nullable */
  innCertificateUrl?: null | string
  /** @nullable */
  kpp?: null | string
  legalEntityInfoId?: string
  legalEntityType?: string
  ownerId?: string
  paymentAccount?: string
  registrationNumber?: string
  /** @nullable */
  rejectComment?: null | string
  userName?: string
  /** @nullable */
  usrleSheetUrl?: null | string
  /** @nullable */
  verificationError?: null | string
  verificationStatus?: VerificationStatus
}

export interface GetLegalEntityInfoQuery {
  /** @nullable */
  legalEntityInfoId?: null | string
  /** @nullable */
  userId?: null | string
}

export interface GetGeneralStatisticResponse {
  products?: ProductsStatistic
  rents?: RentsStatistic
  users?: UsersStatistic
}

export interface GetFilesListResponse {
  items?: FileListItem[]
  totalCount?: number
}

export interface GetFileInfoResponse {
  /** @nullable */
  fileExtension?: null | string
  fileName?: string
  /** @nullable */
  fileUrl?: null | string
  id?: string
  ownerFullName?: string
  ownerId?: string
  type?: FileType
}

export interface GetFileInfoQuery {
  fileId?: string
}

export interface GetCurrentUserResponse {
  id?: string
  name?: string
  roles?: RoleType[]
}

export interface GetClaimsListResponse {
  items?: ClaimsListItem[]
  totalCount?: number
}

export interface GetClaimsListQuery {
  /** @nullable */
  buyerId?: null | string
  /** @nullable */
  productId?: null | number
  /** @nullable */
  sellerId?: null | string
  skip?: number
  /** @nullable */
  statuses?: ClaimStatus[] | null
  take?: number
}

export interface GetClaimInfoResponse {
  authorFullName?: string
  authorId?: string
  authorUserName?: string
  buyerFullName?: string
  buyerId?: string
  buyerUserName?: string
  claimId?: number
  claimStatus?: ClaimStatus
  claimType?: ClaimType
  createdOn?: string
  /** @nullable */
  messageByBuyer?: null | string
  /** @nullable */
  messageBySeller?: null | string
  productId?: number
  productName?: string
  rentId?: number
  rentPledge?: number
  rentPrice?: number
  /** @nullable */
  selectedDefectFines?: RentDefectFineRentDto[] | null
  readonly selectedDefectsAmount?: number
  sellerFullName?: string
  sellerId?: string
  sellerUserName?: string
}

export interface GetClaimInfoQuery {
  claimId?: number
}

export interface GetChatsListResponse {
  items?: ChatListItem[]
  totalCount?: number
}

export interface GetChatMessagesResponse {
  items?: ChatMessageExtended[]
  totalCount?: number
}

export interface GetChatInfoResponse {
  buyerFullName?: string
  buyerId?: string
  /** @nullable */
  buyerProfileImageUrl?: null | string
  buyerUserName?: string
  chatId?: number
  lastMessage?: ChatMessageExtended
  productId?: number
  productImagesUrls?: string[]
  productName?: string
  productPledge?: number
  productPrice?: number
  rentId?: number
  sellerFullName?: string
  sellerId?: string
  /** @nullable */
  sellerProfileImageUrl?: null | string
  sellerUserName?: string
}

export interface GetChatInfoQuery {
  chatId?: number
}

export interface GetCategoryInfoResponse {
  /** @nullable */
  categoryImageUrl?: null | string
  childCategories?: number
  id?: number
  name?: string
  /** @nullable */
  parentCategoryId?: null | number
  productsCount?: number
  sectionId?: number
}

export interface GetCategoryInfoQuery {
  id?: number
}

export interface GetCategoryCriteriaResponse {
  groupedCriteria?: GroupedCriteriaDto[]
}

export interface GetCategoryCriteriaQuery {
  categoryId?: number
}

export interface GetCategoriesListResponse {
  items?: CategoriesListItemDto[]
  totalCount?: number
}

export interface GetCategoriesListQuery {
  /** @nullable */
  disableCategoryFilter?: boolean | null
  /** @nullable */
  parentCategoryId?: null | number
  /** @nullable */
  sectionId?: null | number
  skip?: number
  take?: number
  /** @nullable */
  term?: null | string
}

export interface GetBankCardInfoResponse {
  cardNumber?: string
  id?: string
  paymentSystem?: string
}

export interface GetBankCardInfoQuery {
  bankCardId?: string
}

export interface GetBankAccountTopUpRequisitesResponse {
  address?: string
  bankName?: string
  bic?: string
  companyName?: string
  correspondentBank?: string
  currency?: string
  inn?: string
  kpp?: string
  paymentAccount?: string
}

export interface GetArticlesListResponse {
  items?: ArticleListItemDto[]
  totalCount?: number
}

export interface GetArticlesListQuery {
  /** @nullable */
  authorId?: null | string
  /** @nullable */
  categoryId?: null | number
  /** @nullable */
  sectionId?: null | number
  skip?: number
  /** @nullable */
  tagsIds?: null | number[]
  take?: number
}

export interface GetArticleTagsListResponse {
  items?: ArticleTagDto[]
  totalCount?: number
}

export interface GetArticleTagsListQuery {
  /** @nullable */
  nameTerm?: null | string
  skip?: number
  take?: number
}

export interface GetArticleInfoResponse {
  articleId?: number
  authorId?: string
  /** @nullable */
  authorImageUrl?: null | string
  authorLogin?: string
  authorName?: string
  commentsCount?: number
  content?: string
  createdOn?: string
  /** @nullable */
  imageUrl?: null | string
  sharesCount?: number
  title?: string
  viewsCount?: number
}

export interface GetArticleInfoQuery {
  articleId?: number
}

export interface GetArticleCommentsResponse {
  items?: ArticleCommentDto[]
  totalCount?: number
}

export interface GetArticleCommentsQuery {
  articleId?: number
  skip?: number
  take?: number
}

export interface GetArchivedProductsListResponse {
  items?: ArchivedProductListItem[]
  totalCount?: number
}

export interface GetArchivedProductsListQuery {
  /** @nullable */
  categoryId?: null | number
  /** @nullable */
  dayPriceFrom?: null | number
  /** @nullable */
  dayPriceTo?: null | number
  /** @nullable */
  numberFromToFilters?: NumberFromToFilter[] | null
  /** @nullable */
  numberOneOfFilters?: NumberOneOfFilter[] | null
  orderType?: ProductsListOrderType
  /** @nullable */
  ownerId?: null | string
  premiumOnly?: boolean
  /** @nullable */
  productNameTerm?: null | string
  /** @nullable */
  productTagsIds?: null | number[]
  /** @nullable */
  ratingFrom?: null | number
  /** @nullable */
  ratingTo?: null | number
  /** @nullable */
  sectionId?: null | number
  skip?: number
  sortOrder?: SortOrder
  /** @nullable */
  stringExactFilters?: StringExactFilter[] | null
  /** @nullable */
  stringOneOfFilters?: StringOneOfFilter[] | null
  /** @nullable */
  stringTermFilters?: StringTermFilter[] | null
  take?: number
}

export interface GetArchivedProductInfoResponse {
  categoryId?: number
  categoryName?: string
  /** @nullable */
  comment?: null | string
  createdOn?: string
  /** @nullable */
  dayPrice?: null | number
  defectFines?: DefectFineExtendedDto[]
  deliveryCities?: string[]
  /** @nullable */
  deliveryPrice?: null | number
  deliveryType?: ProductDeliveryType
  description?: string
  id?: number
  imagesUrls?: string[]
  isPremium?: boolean
  /** @nullable */
  monthPrice?: null | number
  name?: string
  owner?: ProductOwnerDto
  pickupAddress?: PickupAddressDto
  pledge?: number
  productTags?: ProductTagDto[]
  rating?: number
  rentCyclesCount?: number
  reviewsCount?: number
  sectionId?: number
  sectionName?: string
  status?: ProductStatus
  /** @nullable */
  threeDaysPrice?: null | number
  /** @nullable */
  weekPrice?: null | number
}

export interface GetArchivedProductInfoQuery {
  productId?: number
}

export interface GenerateQrCodeResponse {
  code?: string
}

export interface GenerateQrCodeCommand {
  rentId?: number
}

export interface GenerateAndGetReferralCodeResponse {
  referralCode?: string
}

export interface GenerateAndGetReferralCodeCommand {
  userId?: string
}

export interface ForgotPasswordCommand {
  email?: string
}

export type FileType = (typeof FileType)[keyof typeof FileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileType = {
  articleImage: 'articleImage',
  categoryImage: 'categoryImage',
  chatMessageImage: 'chatMessageImage',
  documentImage: 'documentImage',
  other: 'other',
  productImage: 'productImage',
  rentImage: 'rentImage',
  sectionImage: 'sectionImage',
  userAvatar: 'userAvatar',
} as const

export interface FileListItem {
  /** @nullable */
  fileExtension?: null | string
  fileName?: string
  id?: string
  ownerFullName?: string
  ownerId?: string
  type?: FileType
}

export interface EditCriterionCommand {
  criterionId?: number
  filterType?: CriterionFilterType
  name?: string
  /** @nullable */
  possibleNumberValues?: null | number[]
  /** @nullable */
  possibleStringValues?: null | string[]
  /** @nullable */
  propertyName?: null | string
}

export interface DeleteUserCommand {
  userId?: string
}

export interface DeleteSectionCommand {
  id?: number
}

export interface DeleteReviewCommand {
  reviewId?: number
}

export interface DeleteProductTagCommand {
  tagId?: number
}

export interface DeleteProductCommand {
  /** @nullable */
  comment?: null | string
  productId?: number
}

export interface DeleteFileCommand {
  fileId?: string
}

export interface DeleteDataCommand {
  [key: string]: any
}

export interface DeleteCriterionCommand {
  criterionId?: number
}

export interface DeleteCategoryCommand {
  id?: number
}

export interface DeleteArticleTagCommand {
  id?: number
}

export interface DeleteArticleCommand {
  articleId?: number
}

export interface DefectFineExtendedDto {
  amount?: number
  defectName?: string
  id?: number
}

export interface DefectFineDto {
  amount?: number
  defectName?: string
}

export type CriterionFilterType = (typeof CriterionFilterType)[keyof typeof CriterionFilterType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CriterionFilterType = {
  numberFromTo: 'numberFromTo',
  numberOneOf: 'numberOneOf',
  stringExact: 'stringExact',
  stringOneOf: 'stringOneOf',
  stringTerm: 'stringTerm',
} as const

export interface CriterionDto {
  filterType?: CriterionFilterType
  id?: number
  name?: string
  /** @nullable */
  possibleNumberValues?: null | number[]
  /** @nullable */
  possibleStringValues?: null | string[]
  /** @nullable */
  propertyName?: null | string
}

export interface CreateWithdrawalRequestCommand {
  bankAccountId?: string
  withdrawalAmount?: number
}

export interface CreateSectionResponse {
  sectionId?: number
}

export interface CreateSectionCommand {
  /** @nullable */
  fileId?: null | string
  name?: string
}

export interface CreateReviewCommand {
  content?: string
  rating?: number
  rentId?: number
  reviewType?: ReviewType
}

export interface CreateRentResponse {
  rentId?: number
}

export interface CreateRentCommand {
  deliveryType?: RentProductDeliveryType
  periodFrom?: string
  periodTo?: string
  productId?: number
  /** @nullable */
  userAddressId?: null | number
}

export interface CreateProductTagResponse {
  tagId?: number
}

export interface CreateProductTagCommand {
  name?: string
  sectionId?: number
}

export interface CreateProductResponse {
  productId?: number
}

export interface CreatePayoutCommand {
  amount?: number
  bankCardId?: string
}

export interface CreatePaymentResponse {
  confirmationUrl?: string
}

export interface CreatePaymentCommand {
  amount?: number
  /** @nullable */
  returnUrl?: null | string
}

export interface CreateCriterionCommand {
  categoryId?: number
  filterType?: CriterionFilterType
  name?: string
  /** @nullable */
  possibleNumberValues?: null | number[]
  /** @nullable */
  possibleStringValues?: null | string[]
  /** @nullable */
  propertyName?: null | string
}

export interface CreateArticleTagResponse {
  tagId?: number
}

export interface CreateArticleTagCommand {
  name?: string
}

export interface CommentArticleCommand {
  articleId?: number
  content?: string
  /** @nullable */
  relatedCommentId?: null | number
}

export type ClaimType = (typeof ClaimType)[keyof typeof ClaimType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimType = {
  byBuyer: 'byBuyer',
  bySeller: 'bySeller',
} as const

export type ClaimStatus = (typeof ClaimStatus)[keyof typeof ClaimStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimStatus = {
  approved: 'approved',
  created: 'created',
  rejected: 'rejected',
} as const

export interface ClaimsListItem {
  authorFullName?: string
  authorId?: string
  authorUserName?: string
  claimId?: number
  claimStatus?: ClaimStatus
  claimType?: ClaimType
  createdOn?: string
  productId?: number
  productName?: string
  rentId?: number
}

export interface CheckRegistrationCodeResponse {
  isCodeValid?: boolean
}

export interface ChatMessageExtended {
  filesUrls?: string[]
  isViewed?: boolean
  message?: string
  messageDate?: string
  messageId?: number
  /** @nullable */
  senderAvatarUrl?: null | string
  senderFullName?: string
  senderId?: string
  senderUserName?: string
}

export interface ChatMessageDto {
  isViewed?: boolean
  message?: string
  messageDate?: string
  messageId?: number
  /** @nullable */
  senderAvatarUrl?: null | string
  senderFullName?: string
  senderId?: string
  senderUserName?: string
}

export interface ChatListItem {
  /** @nullable */
  buyerAvatarUrl?: null | string
  buyerFullName?: string
  buyerId?: string
  buyerUserName?: string
  chatId?: number
  lastMessage?: ChatMessageDto
  productId?: number
  productImagesUrls?: string[]
  productName?: string
  productPledge?: number
  productPrice?: number
  rentId?: number
  /** @nullable */
  sellerAvatarUrl?: null | string
  sellerFullName?: string
  sellerId?: string
  sellerUserName?: string
}

export interface ChangeUserBalanceCommand {
  amount?: number
  bonusAmount?: number
  /** @nullable */
  comment?: null | string
  userId?: string
}

export interface CategoriesListItemDto {
  categoryId?: number
  categoryName?: string
  hasChildCategories?: boolean
  /** @nullable */
  imageUrl?: null | string
  sectionId?: number
}

export interface CancelBySellerCommand {
  /** @nullable */
  comment?: null | string
  rentId?: number
}

export interface CancelByBuyerCommand {
  rentId?: number
}

export interface BankCardListItemDto {
  cardNumber?: string
  id?: string
  paymentSystem?: string
}

export interface BankAccountDto {
  bankIdentificationCode?: string
  /** @nullable */
  bankName?: null | string
  companyName?: string
  correspondentBank?: string
  id?: string
  inn?: string
  /** @nullable */
  kpp?: null | string
  legalStatus?: LegalStatus
  paymentAccount?: string
}

export interface BalanceUserDto {
  accountBalance?: number
  bonusBalance?: number
}

export type BalanceChangeType = (typeof BalanceChangeType)[keyof typeof BalanceChangeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BalanceChangeType = {
  manualChange: 'manualChange',
  payment: 'payment',
  payout: 'payout',
  rentCancel: 'rentCancel',
  rentCompletion: 'rentCompletion',
  rentCreation: 'rentCreation',
  rentPledgeReturn: 'rentPledgeReturn',
  undefined: 'undefined',
  withdrawalRequest: 'withdrawalRequest',
} as const

export interface AuthResponse {
  accessToken?: string
  refreshToken?: string
}

export interface ArticleTagDto {
  /** @nullable */
  articleId?: null | number
  id?: number
  name?: string
}

export interface ArticleListItemDto {
  commentsCount?: number
  id?: number
  /** @nullable */
  imageUrl?: null | string
  sharesCount?: number
  title?: string
  viewsCount?: number
}

export interface ArticleCommentDto {
  authorId?: string
  authorName?: string
  /** @nullable */
  authorProfileImage?: null | string
  commentId?: number
  content?: string
  replies?: ArticleCommentDto[]
}

export interface ArchivedProductListItem {
  /** @nullable */
  comment?: null | string
  createdOn?: string
  /** @nullable */
  dayPrice?: null | number
  description?: string
  imagesUrls?: string[]
  isPremium?: boolean
  /** @nullable */
  monthPrice?: null | number
  name?: string
  pledge?: number
  productId?: number
  rating?: number
  reviewsCount?: number
  status?: ProductStatus
  /** @nullable */
  threeDaysPrice?: null | number
  /** @nullable */
  weekPrice?: null | number
}

export interface ArchiveProductCommand {
  /** @nullable */
  comment?: null | string
  productId?: number
}

export interface ArchiveArticleCommand {
  articleId?: number
}

export interface ApproveWithdrawalRequestCommand {
  /** @nullable */
  comment?: null | string
  withdrawalRequestId?: string
}

export interface ApproveClaimCommand {
  claimId?: number
}

export interface ApproveBySellerCommand {
  rentId?: number
}

export interface AddUserAddressCommand {
  address?: string
  city?: string
  country?: string
  latitude?: number
  longitude?: number
  name?: string
  postcode?: string
}

export interface AddBankCardCommand {
  cardNumber?: string
  ownerId?: string
  paymentSystem?: string
}

export interface AddBankAccountCommand {
  bankIdentificationCode?: string
  /** @nullable */
  bankName?: null | string
  companyName?: string
  correspondentBank?: string
  inn?: string
  /** @nullable */
  kpp?: null | string
  legalStatus?: LegalStatus
  ownerId?: string
  paymentAccount?: string
}

export type AccountType = (typeof AccountType)[keyof typeof AccountType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountType = {
  notVerified: 'notVerified',
  premium: 'premium',
  undefined: 'undefined',
  verified: 'verified',
} as const

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const postApiArticlesGetArticlesList = (
  getArticlesListQuery: BodyType<GetArticlesListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetArticlesListResponse>(
    {
      data: getArticlesListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/getArticlesList`,
    },
    options
  )
}

export const postApiArticlesGetArticleComments = (
  getArticleCommentsQuery: BodyType<GetArticleCommentsQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetArticleCommentsResponse>(
    {
      data: getArticleCommentsQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/getArticleComments`,
    },
    options
  )
}

export const postApiArticlesCreateArticle = (
  postApiArticlesCreateArticleBody: BodyType<PostApiArticlesCreateArticleBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiArticlesCreateArticleBody.Title !== undefined) {
    formData.append('Title', postApiArticlesCreateArticleBody.Title)
  }
  if (postApiArticlesCreateArticleBody.Content !== undefined) {
    formData.append('Content', postApiArticlesCreateArticleBody.Content)
  }
  if (postApiArticlesCreateArticleBody.SectionId !== undefined) {
    formData.append('SectionId', postApiArticlesCreateArticleBody.SectionId.toString())
  }
  if (postApiArticlesCreateArticleBody.CategoryId !== undefined) {
    formData.append('CategoryId', postApiArticlesCreateArticleBody.CategoryId.toString())
  }
  if (postApiArticlesCreateArticleBody.ArticlesTagsIds !== undefined) {
    postApiArticlesCreateArticleBody.ArticlesTagsIds.forEach(value =>
      formData.append('ArticlesTagsIds', String(value))
    )
  }
  if (postApiArticlesCreateArticleBody.Image !== undefined) {
    formData.append('Image', postApiArticlesCreateArticleBody.Image)
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Articles/createArticle`,
    },
    options
  )
}

export const postApiArticlesUpdateArticle = (
  updateArticleCommand: BodyType<UpdateArticleCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateArticleCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/updateArticle`,
    },
    options
  )
}

export const postApiArticlesDeleteArticle = (
  deleteArticleCommand: BodyType<DeleteArticleCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteArticleCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/deleteArticle`,
    },
    options
  )
}

export const postApiArticlesGetArticleInfo = (
  getArticleInfoQuery: BodyType<GetArticleInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetArticleInfoResponse>(
    {
      data: getArticleInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/getArticleInfo`,
    },
    options
  )
}

export const postApiArticlesViewArticle = (
  viewArticleCommand: BodyType<ViewArticleCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: viewArticleCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/viewArticle`,
    },
    options
  )
}

export const postApiArticlesShareArticle = (
  shareArticleCommand: BodyType<ShareArticleCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: shareArticleCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/shareArticle`,
    },
    options
  )
}

export const postApiArticlesArchiveArticle = (
  archiveArticleCommand: BodyType<ArchiveArticleCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: archiveArticleCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/archiveArticle`,
    },
    options
  )
}

export const postApiArticlesCommentArticle = (
  commentArticleCommand: BodyType<CommentArticleCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: commentArticleCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/commentArticle`,
    },
    options
  )
}

export const postApiArticlesGetArticleTagList = (
  getArticleTagsListQuery: BodyType<GetArticleTagsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetArticleTagsListResponse>(
    {
      data: getArticleTagsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/getArticleTagList`,
    },
    options
  )
}

export const postApiArticlesCreateArticleTag = (
  createArticleTagCommand: BodyType<CreateArticleTagCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<CreateArticleTagResponse>(
    {
      data: createArticleTagCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/createArticleTag`,
    },
    options
  )
}

export const postApiArticlesDeleteArticleTag = (
  deleteArticleTagCommand: BodyType<DeleteArticleTagCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteArticleTagCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Articles/deleteArticleTag`,
    },
    options
  )
}

export const postApiAuthSignInEmail = (
  signInEmailCommand: BodyType<SignInEmailCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<AuthResponse>(
    {
      data: signInEmailCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/signIn/email`,
    },
    options
  )
}

export const postApiAuthSignInPhoneByCode = (
  signInPhoneByCodeCommand: BodyType<SignInPhoneByCodeCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<AuthResponse>(
    {
      data: signInPhoneByCodeCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/signIn/phone/byCode`,
    },
    options
  )
}

export const postApiAuthSignInPhoneByPassword = (
  signInPhoneByPasswordCommand: BodyType<SignInPhoneByPasswordCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<AuthResponse>(
    {
      data: signInPhoneByPasswordCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/signIn/phone/byPassword`,
    },
    options
  )
}

export const postApiAuthSendAuthCode = (
  sendAuthCodeCommand: BodyType<SendAuthCodeCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<SendCodeResponse>(
    {
      data: sendAuthCodeCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/sendAuthCode`,
    },
    options
  )
}

export const postApiAuthRefreshToken = (
  refreshTokenCommand: BodyType<RefreshTokenCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<AuthResponse>(
    {
      data: refreshTokenCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/refreshToken`,
    },
    options
  )
}

export const postApiAuthSignUp = (
  signUpCommand: BodyType<SignUpCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<AuthResponse>(
    {
      data: signUpCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/signUp`,
    },
    options
  )
}

export const postApiAuthResetPassword = (
  resetPasswordCommand: BodyType<ResetPasswordCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: resetPasswordCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/resetPassword`,
    },
    options
  )
}

export const postApiAuthForgotPassword = (
  forgotPasswordCommand: BodyType<ForgotPasswordCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: forgotPasswordCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/forgotPassword`,
    },
    options
  )
}

export const getApiAuthGetCurrentUser = (options?: SecondParameter<typeof createInstance>) => {
  return createInstance<GetCurrentUserResponse>(
    { method: 'GET', url: `/api/Auth/getCurrentUser` },
    options
  )
}

export const postApiAuthSendRegistrationCodeBySms = (
  sendRegistrationCodeBySmsCommand: BodyType<SendRegistrationCodeBySmsCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<SendCodeResponse>(
    {
      data: sendRegistrationCodeBySmsCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/sendRegistrationCodeBySms`,
    },
    options
  )
}

export const postApiAuthSendRegistrationCodeByEmail = (
  sendRegistrationCodeByEmailCommand: BodyType<SendRegistrationCodeByEmailCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<SendCodeResponse>(
    {
      data: sendRegistrationCodeByEmailCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Auth/sendRegistrationCodeByEmail`,
    },
    options
  )
}

export const getApiAuthCheckPhoneRegistrationCode = (
  params?: GetApiAuthCheckPhoneRegistrationCodeParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<CheckRegistrationCodeResponse>(
    { method: 'GET', params, url: `/api/Auth/checkPhoneRegistrationCode` },
    options
  )
}

export const getApiAuthCheckEmailRegistrationCode = (
  params?: GetApiAuthCheckEmailRegistrationCodeParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<CheckRegistrationCodeResponse>(
    { method: 'GET', params, url: `/api/Auth/checkEmailRegistrationCode` },
    options
  )
}

export const postApiCategoriesGetCategoryInfo = (
  getCategoryInfoQuery: BodyType<GetCategoryInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetCategoryInfoResponse>(
    {
      data: getCategoryInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Categories/getCategoryInfo`,
    },
    options
  )
}

export const postApiCategoriesGetCategoriesList = (
  getCategoriesListQuery: BodyType<GetCategoriesListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetCategoriesListResponse>(
    {
      data: getCategoriesListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Categories/getCategoriesList`,
    },
    options
  )
}

export const postApiCategoriesCreateCategory = (
  postApiCategoriesCreateCategoryBody: BodyType<PostApiCategoriesCreateCategoryBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiCategoriesCreateCategoryBody.SectionId !== undefined) {
    formData.append('SectionId', postApiCategoriesCreateCategoryBody.SectionId.toString())
  }
  if (postApiCategoriesCreateCategoryBody.Name !== undefined) {
    formData.append('Name', postApiCategoriesCreateCategoryBody.Name)
  }
  if (postApiCategoriesCreateCategoryBody.ParentCategoryId !== undefined) {
    formData.append(
      'ParentCategoryId',
      postApiCategoriesCreateCategoryBody.ParentCategoryId.toString()
    )
  }
  if (postApiCategoriesCreateCategoryBody.Image !== undefined) {
    formData.append('Image', postApiCategoriesCreateCategoryBody.Image)
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Categories/createCategory`,
    },
    options
  )
}

export const postApiCategoriesUpdateCategory = (
  updateCategoryCommand: BodyType<UpdateCategoryCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateCategoryCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Categories/updateCategory`,
    },
    options
  )
}

export const postApiCategoriesUpdateCategoryImage = (
  postApiCategoriesUpdateCategoryImageBody: BodyType<PostApiCategoriesUpdateCategoryImageBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiCategoriesUpdateCategoryImageBody.categoryId !== undefined) {
    formData.append('categoryId', postApiCategoriesUpdateCategoryImageBody.categoryId.toString())
  }
  if (postApiCategoriesUpdateCategoryImageBody.image !== undefined) {
    formData.append('image', postApiCategoriesUpdateCategoryImageBody.image)
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Categories/updateCategoryImage`,
    },
    options
  )
}

export const postApiCategoriesDeleteCategory = (
  deleteCategoryCommand: BodyType<DeleteCategoryCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteCategoryCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Categories/deleteCategory`,
    },
    options
  )
}

export const getApiChatsGetChatsList = (
  params?: GetApiChatsGetChatsListParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetChatsListResponse>(
    { method: 'GET', params, url: `/api/Chats/getChatsList` },
    options
  )
}

export const postApiChatsGetChatInfo = (
  getChatInfoQuery: BodyType<GetChatInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetChatInfoResponse>(
    {
      data: getChatInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Chats/getChatInfo`,
    },
    options
  )
}

export const getApiChatsGetChatMessages = (
  params?: GetApiChatsGetChatMessagesParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetChatMessagesResponse>(
    { method: 'GET', params, url: `/api/Chats/getChatMessages` },
    options
  )
}

export const postApiChatsViewChatMessage = (
  viewChatMessageCommand: BodyType<ViewChatMessageCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: viewChatMessageCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Chats/viewChatMessage`,
    },
    options
  )
}

export const postApiChatsSendChatMessage = (
  postApiChatsSendChatMessageBody: BodyType<PostApiChatsSendChatMessageBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiChatsSendChatMessageBody.ChatId !== undefined) {
    formData.append('ChatId', postApiChatsSendChatMessageBody.ChatId.toString())
  }
  if (postApiChatsSendChatMessageBody.Message !== undefined) {
    formData.append('Message', postApiChatsSendChatMessageBody.Message)
  }
  if (postApiChatsSendChatMessageBody.Files !== undefined) {
    postApiChatsSendChatMessageBody.Files.forEach(value => formData.append('Files', value))
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Chats/sendChatMessage`,
    },
    options
  )
}

export const postApiClaimsGetClaimsList = (
  getClaimsListQuery: BodyType<GetClaimsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetClaimsListResponse>(
    {
      data: getClaimsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Claims/getClaimsList`,
    },
    options
  )
}

export const postApiClaimsGetClaimInfo = (
  getClaimInfoQuery: BodyType<GetClaimInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetClaimInfoResponse>(
    {
      data: getClaimInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Claims/getClaimInfo`,
    },
    options
  )
}

export const postApiClaimsApproveClaim = (
  approveClaimCommand: BodyType<ApproveClaimCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: approveClaimCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Claims/approveClaim`,
    },
    options
  )
}

export const postApiClaimsRejectClaim = (
  rejectClaimCommand: BodyType<RejectClaimCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: rejectClaimCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Claims/rejectClaim`,
    },
    options
  )
}

export const postApiCriteriaCreateCriterion = (
  createCriterionCommand: BodyType<CreateCriterionCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: createCriterionCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Criteria/createCriterion`,
    },
    options
  )
}

export const postApiCriteriaEditCriterion = (
  editCriterionCommand: BodyType<EditCriterionCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: editCriterionCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Criteria/editCriterion`,
    },
    options
  )
}

export const postApiCriteriaDeleteCriterion = (
  deleteCriterionCommand: BodyType<DeleteCriterionCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteCriterionCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Criteria/deleteCriterion`,
    },
    options
  )
}

export const postApiCriteriaGetCategoryCriteria = (
  getCategoryCriteriaQuery: BodyType<GetCategoryCriteriaQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetCategoryCriteriaResponse>(
    {
      data: getCategoryCriteriaQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Criteria/getCategoryCriteria`,
    },
    options
  )
}

export const postApiDocumentsAddPassport = (
  postApiDocumentsAddPassportBody: BodyType<PostApiDocumentsAddPassportBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiDocumentsAddPassportBody.UserId !== undefined) {
    formData.append('UserId', postApiDocumentsAddPassportBody.UserId)
  }
  if (postApiDocumentsAddPassportBody.FullName !== undefined) {
    formData.append('FullName', postApiDocumentsAddPassportBody.FullName)
  }
  if (postApiDocumentsAddPassportBody.BirthDate !== undefined) {
    formData.append('BirthDate', postApiDocumentsAddPassportBody.BirthDate)
  }
  if (postApiDocumentsAddPassportBody.Series !== undefined) {
    formData.append('Series', postApiDocumentsAddPassportBody.Series)
  }
  if (postApiDocumentsAddPassportBody.Number !== undefined) {
    formData.append('Number', postApiDocumentsAddPassportBody.Number)
  }
  if (postApiDocumentsAddPassportBody.IssuedBy !== undefined) {
    formData.append('IssuedBy', postApiDocumentsAddPassportBody.IssuedBy)
  }
  if (postApiDocumentsAddPassportBody.SubdivisionCode !== undefined) {
    formData.append('SubdivisionCode', postApiDocumentsAddPassportBody.SubdivisionCode)
  }
  if (postApiDocumentsAddPassportBody.IssuedOn !== undefined) {
    formData.append('IssuedOn', postApiDocumentsAddPassportBody.IssuedOn)
  }
  if (postApiDocumentsAddPassportBody.PlaceOfResidence !== undefined) {
    formData.append('PlaceOfResidence', postApiDocumentsAddPassportBody.PlaceOfResidence)
  }
  if (postApiDocumentsAddPassportBody.MainImage !== undefined) {
    formData.append('MainImage', postApiDocumentsAddPassportBody.MainImage)
  }
  if (postApiDocumentsAddPassportBody.OtherImages !== undefined) {
    postApiDocumentsAddPassportBody.OtherImages.forEach(value =>
      formData.append('OtherImages', value)
    )
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Documents/addPassport`,
    },
    options
  )
}

export const postApiDocumentsAddLegalEntityInfo = (
  postApiDocumentsAddLegalEntityInfoBody: BodyType<PostApiDocumentsAddLegalEntityInfoBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiDocumentsAddLegalEntityInfoBody.UserId !== undefined) {
    formData.append('UserId', postApiDocumentsAddLegalEntityInfoBody.UserId)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.LegalEntityType !== undefined) {
    formData.append('LegalEntityType', postApiDocumentsAddLegalEntityInfoBody.LegalEntityType)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.CompanyName !== undefined) {
    formData.append('CompanyName', postApiDocumentsAddLegalEntityInfoBody.CompanyName)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.Inn !== undefined) {
    formData.append('Inn', postApiDocumentsAddLegalEntityInfoBody.Inn)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.Kpp !== undefined) {
    formData.append('Kpp', postApiDocumentsAddLegalEntityInfoBody.Kpp)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.RegistrationNumber !== undefined) {
    formData.append('RegistrationNumber', postApiDocumentsAddLegalEntityInfoBody.RegistrationNumber)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.Address !== undefined) {
    formData.append('Address', postApiDocumentsAddLegalEntityInfoBody.Address)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.BankIdentificationCode !== undefined) {
    formData.append(
      'BankIdentificationCode',
      postApiDocumentsAddLegalEntityInfoBody.BankIdentificationCode
    )
  }
  if (postApiDocumentsAddLegalEntityInfoBody.PaymentAccount !== undefined) {
    formData.append('PaymentAccount', postApiDocumentsAddLegalEntityInfoBody.PaymentAccount)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.CorrespondentBank !== undefined) {
    formData.append('CorrespondentBank', postApiDocumentsAddLegalEntityInfoBody.CorrespondentBank)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.BudgetClassificationCode !== undefined) {
    formData.append(
      'BudgetClassificationCode',
      postApiDocumentsAddLegalEntityInfoBody.BudgetClassificationCode
    )
  }
  if (postApiDocumentsAddLegalEntityInfoBody.CharterFirstPage !== undefined) {
    formData.append('CharterFirstPage', postApiDocumentsAddLegalEntityInfoBody.CharterFirstPage)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.EstablishmentAgreement !== undefined) {
    formData.append(
      'EstablishmentAgreement',
      postApiDocumentsAddLegalEntityInfoBody.EstablishmentAgreement
    )
  }
  if (postApiDocumentsAddLegalEntityInfoBody.EstablishmentProtocol !== undefined) {
    formData.append(
      'EstablishmentProtocol',
      postApiDocumentsAddLegalEntityInfoBody.EstablishmentProtocol
    )
  }
  if (postApiDocumentsAddLegalEntityInfoBody.USRLESheet !== undefined) {
    formData.append('USRLESheet', postApiDocumentsAddLegalEntityInfoBody.USRLESheet)
  }
  if (postApiDocumentsAddLegalEntityInfoBody.InnCertificate !== undefined) {
    formData.append('InnCertificate', postApiDocumentsAddLegalEntityInfoBody.InnCertificate)
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Documents/addLegalEntityInfo`,
    },
    options
  )
}

export const postApiDocumentsVerifyPassport = (
  verifyPassportCommand: BodyType<VerifyPassportCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: verifyPassportCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/verifyPassport`,
    },
    options
  )
}

export const postApiDocumentsVerifyLegalEntityInfo = (
  verifyLegalEntityInfoCommand: BodyType<VerifyLegalEntityInfoCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: verifyLegalEntityInfoCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/verifyLegalEntityInfo`,
    },
    options
  )
}

export const postApiDocumentsRejectPassportVerification = (
  rejectPassportVerificationCommand: BodyType<RejectPassportVerificationCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: rejectPassportVerificationCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/rejectPassportVerification`,
    },
    options
  )
}

export const postApiDocumentsRejectLegalEntityInfoVerification = (
  rejectLegalEntityInfoVerificationCommand: BodyType<RejectLegalEntityInfoVerificationCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: rejectLegalEntityInfoVerificationCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/rejectLegalEntityInfoVerification`,
    },
    options
  )
}

export const postApiDocumentsRemovePassport = (
  removePassportCommand: BodyType<RemovePassportCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: removePassportCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/removePassport`,
    },
    options
  )
}

export const postApiDocumentsRemoveLegalEntityInfo = (
  removeLegalEntityInfoCommand: BodyType<RemoveLegalEntityInfoCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: removeLegalEntityInfoCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/removeLegalEntityInfo`,
    },
    options
  )
}

export const postApiDocumentsGetPassportInfo = (
  getPassportInfoQuery: BodyType<GetPassportInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetPassportInfoResponse>(
    {
      data: getPassportInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/getPassportInfo`,
    },
    options
  )
}

export const postApiDocumentsGetLegalEntityInfo = (
  getLegalEntityInfoQuery: BodyType<GetLegalEntityInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetLegalEntityInfoResponse>(
    {
      data: getLegalEntityInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/getLegalEntityInfo`,
    },
    options
  )
}

export const postApiDocumentsGetPassportsList = (
  getPassportsListQuery: BodyType<GetPassportsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetPassportsListResponse>(
    {
      data: getPassportsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/getPassportsList`,
    },
    options
  )
}

export const postApiDocumentsGetLegalEntityInfosList = (
  getLegalEntityInfosListQuery: BodyType<GetLegalEntityInfosListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetLegalEntityInfosListResponse>(
    {
      data: getLegalEntityInfosListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Documents/getLegalEntityInfosList`,
    },
    options
  )
}

export const postApiFilesUploadFile = (
  postApiFilesUploadFileBody: BodyType<PostApiFilesUploadFileBody>,
  params?: PostApiFilesUploadFileParams,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiFilesUploadFileBody.file !== undefined) {
    formData.append('file', postApiFilesUploadFileBody.file)
  }

  return createInstance<UploadFileResponse>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      params,
      url: `/api/Files/uploadFile`,
    },
    options
  )
}

export const getApiFilesGetFileFileId = (
  fileId: string,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>({ method: 'GET', url: `/api/Files/getFile/${fileId}` }, options)
}

export const postApiFilesDeleteFile = (
  deleteFileCommand: BodyType<DeleteFileCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteFileCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Files/deleteFile`,
    },
    options
  )
}

export const getApiFilesGetFilesList = (
  params?: GetApiFilesGetFilesListParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetFilesListResponse>(
    { method: 'GET', params, url: `/api/Files/getFilesList` },
    options
  )
}

export const postApiFilesGetFileInfo = (
  getFileInfoQuery: BodyType<GetFileInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetFileInfoResponse>(
    {
      data: getFileInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Files/getFileInfo`,
    },
    options
  )
}

export const postApiOperationsGetOperationInfo = (
  getOperationInfoQuery: BodyType<GetOperationInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetOperationInfoResponse>(
    {
      data: getOperationInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Operations/getOperationInfo`,
    },
    options
  )
}

export const postApiOperationsGetOperationsList = (
  getOperationsListQuery: BodyType<GetOperationsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetOperationsListResponse>(
    {
      data: getOperationsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Operations/getOperationsList`,
    },
    options
  )
}

export const postApiOperationsCreateWithdrawalRequest = (
  createWithdrawalRequestCommand: BodyType<CreateWithdrawalRequestCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: createWithdrawalRequestCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Operations/createWithdrawalRequest`,
    },
    options
  )
}

export const postApiOperationsApproveWithdrawalRequest = (
  approveWithdrawalRequestCommand: BodyType<ApproveWithdrawalRequestCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: approveWithdrawalRequestCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Operations/approveWithdrawalRequest`,
    },
    options
  )
}

export const postApiOperationsRejectWithdrawalRequest = (
  rejectWithdrawalRequestCommand: BodyType<RejectWithdrawalRequestCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: rejectWithdrawalRequestCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Operations/rejectWithdrawalRequest`,
    },
    options
  )
}

export const postApiOperationsGetWithdrawalRequestsList = (
  getWithdrawalRequestsListQuery: BodyType<GetWithdrawalRequestsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetWithdrawalRequestsListResponse>(
    {
      data: getWithdrawalRequestsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Operations/getWithdrawalRequestsList`,
    },
    options
  )
}

export const postApiOperationsGetWithdrawalRequestInfo = (
  getWithdrawalRequestInfoQuery: BodyType<GetWithdrawalRequestInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetWithdrawalRequestInfoResponse>(
    {
      data: getWithdrawalRequestInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Operations/getWithdrawalRequestInfo`,
    },
    options
  )
}

export const postApiPaymentsGetPaymentMethods = (
  getPaymentMethodsQuery: BodyType<GetPaymentMethodsQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetPaymentMethodsResponse>(
    {
      data: getPaymentMethodsQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Payments/getPaymentMethods`,
    },
    options
  )
}

export const postApiPaymentsGetBankCardInfo = (
  getBankCardInfoQuery: BodyType<GetBankCardInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetBankCardInfoResponse>(
    {
      data: getBankCardInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Payments/getBankCardInfo`,
    },
    options
  )
}

export const postApiPaymentsAddBankCard = (
  addBankCardCommand: BodyType<AddBankCardCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: addBankCardCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Payments/addBankCard`,
    },
    options
  )
}

export const postApiPaymentsRemoveBankCard = (
  removeBankCardCommand: BodyType<RemoveBankCardCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: removeBankCardCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Payments/removeBankCard`,
    },
    options
  )
}

export const postApiPaymentsAddBankAccount = (
  addBankAccountCommand: BodyType<AddBankAccountCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: addBankAccountCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Payments/addBankAccount`,
    },
    options
  )
}

export const postApiPaymentsRemoveBankAccount = (
  removeBankAccountCommand: BodyType<RemoveBankAccountCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: removeBankAccountCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Payments/removeBankAccount`,
    },
    options
  )
}

export const getApiPaymentsGetBankAccountTopUpRequisites = (
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetBankAccountTopUpRequisitesResponse>(
    { method: 'GET', url: `/api/Payments/getBankAccountTopUpRequisites` },
    options
  )
}

export const postApiProductsGetProductsList = (
  getProductsListQuery: BodyType<GetProductsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetProductsListResponse>(
    {
      data: getProductsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/getProductsList`,
    },
    options
  )
}

export const postApiProductsGetArchivedProductsList = (
  getArchivedProductsListQuery: BodyType<GetArchivedProductsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetArchivedProductsListResponse>(
    {
      data: getArchivedProductsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/getArchivedProductsList`,
    },
    options
  )
}

export const postApiProductsCreateProduct = (
  postApiProductsCreateProductBody: BodyType<PostApiProductsCreateProductBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiProductsCreateProductBody.Name !== undefined) {
    formData.append('Name', postApiProductsCreateProductBody.Name)
  }
  if (postApiProductsCreateProductBody.IsPremium !== undefined) {
    formData.append('IsPremium', postApiProductsCreateProductBody.IsPremium.toString())
  }
  if (postApiProductsCreateProductBody.Description !== undefined) {
    formData.append('Description', postApiProductsCreateProductBody.Description)
  }
  if (postApiProductsCreateProductBody.Pledge !== undefined) {
    formData.append('Pledge', postApiProductsCreateProductBody.Pledge.toString())
  }
  if (postApiProductsCreateProductBody.DayPrice !== undefined) {
    formData.append('DayPrice', postApiProductsCreateProductBody.DayPrice.toString())
  }
  if (postApiProductsCreateProductBody.ThreeDaysPrice !== undefined) {
    formData.append('ThreeDaysPrice', postApiProductsCreateProductBody.ThreeDaysPrice.toString())
  }
  if (postApiProductsCreateProductBody.WeekPrice !== undefined) {
    formData.append('WeekPrice', postApiProductsCreateProductBody.WeekPrice.toString())
  }
  if (postApiProductsCreateProductBody.MonthPrice !== undefined) {
    formData.append('MonthPrice', postApiProductsCreateProductBody.MonthPrice.toString())
  }
  if (postApiProductsCreateProductBody.DeliveryType !== undefined) {
    formData.append('DeliveryType', postApiProductsCreateProductBody.DeliveryType)
  }
  if (postApiProductsCreateProductBody.DeliveryCities !== undefined) {
    postApiProductsCreateProductBody.DeliveryCities.forEach(value =>
      formData.append('DeliveryCities', value)
    )
  }
  if (postApiProductsCreateProductBody.DeliveryPrice !== undefined) {
    formData.append('DeliveryPrice', postApiProductsCreateProductBody.DeliveryPrice.toString())
  }
  if (postApiProductsCreateProductBody.PickupAddressId !== undefined) {
    formData.append('PickupAddressId', postApiProductsCreateProductBody.PickupAddressId.toString())
  }
  if (postApiProductsCreateProductBody.DefectFines !== undefined) {
    postApiProductsCreateProductBody.DefectFines.forEach((value, idx) => {
      formData.append(`DefectFines[${idx}].amount`, String(value.amount))
      formData.append(`DefectFines[${idx}].defectName`, value.defectName)
    })
  }
  if (postApiProductsCreateProductBody.CategoryId !== undefined) {
    formData.append('CategoryId', postApiProductsCreateProductBody.CategoryId.toString())
  }
  if (postApiProductsCreateProductBody.ProductTagsIds !== undefined) {
    postApiProductsCreateProductBody.ProductTagsIds.forEach(value =>
      formData.append('ProductTagsIds', String(value))
    )
  }
  if (postApiProductsCreateProductBody.Images !== undefined) {
    postApiProductsCreateProductBody.Images.forEach(value => formData.append('Images', value))
  }

  return createInstance<CreateProductResponse>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Products/createProduct`,
    },
    options
  )
}

export const postApiProductsArchiveProduct = (
  archiveProductCommand: BodyType<ArchiveProductCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: archiveProductCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/archiveProduct`,
    },
    options
  )
}

export const postApiProductsRestoreProduct = (
  restoreProductCommand: BodyType<RestoreProductCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: restoreProductCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/restoreProduct`,
    },
    options
  )
}

export const postApiProductsDeleteProduct = (
  deleteProductCommand: BodyType<DeleteProductCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteProductCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/deleteProduct`,
    },
    options
  )
}

export const postApiProductsGetProductInfo = (
  getProductInfoQuery: BodyType<GetProductInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetProductInfoResponse>(
    {
      data: getProductInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/getProductInfo`,
    },
    options
  )
}

export const postApiProductsGetArchivedProductInfo = (
  getArchivedProductInfoQuery: BodyType<GetArchivedProductInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetArchivedProductInfoResponse>(
    {
      data: getArchivedProductInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/getArchivedProductInfo`,
    },
    options
  )
}

export const postApiProductsUpdateProductInfo = (
  updateProductInfoCommand: BodyType<UpdateProductInfoCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateProductInfoCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/updateProductInfo`,
    },
    options
  )
}

export const postApiProductsUpdateProductPrice = (
  updateProductPriceCommand: BodyType<UpdateProductPriceCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateProductPriceCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/updateProductPrice`,
    },
    options
  )
}

export const postApiProductsUpdateProductDelivery = (
  updateProductDeliveryCommand: BodyType<UpdateProductDeliveryCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateProductDeliveryCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/updateProductDelivery`,
    },
    options
  )
}

export const getApiProductsGetProductTagsList = (
  params?: GetApiProductsGetProductTagsListParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetProductTagsListResponse>(
    { method: 'GET', params, url: `/api/Products/getProductTagsList` },
    options
  )
}

export const postApiProductsCreateProductTag = (
  createProductTagCommand: BodyType<CreateProductTagCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<CreateProductTagResponse>(
    {
      data: createProductTagCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/createProductTag`,
    },
    options
  )
}

export const postApiProductsDeleteProductTag = (
  deleteProductTagCommand: BodyType<DeleteProductTagCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteProductTagCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Products/deleteProductTag`,
    },
    options
  )
}

export const postApiRentsGetRentsList = (
  getRentsListQuery: BodyType<GetRentsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetRentsListResponse>(
    {
      data: getRentsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/getRentsList`,
    },
    options
  )
}

export const postApiRentsGetRentInfo = (
  getRentInfoQuery: BodyType<GetRentInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetRentInfoResponse>(
    {
      data: getRentInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/getRentInfo`,
    },
    options
  )
}

export const postApiRentsCreateRent = (
  createRentCommand: BodyType<CreateRentCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<CreateRentResponse>(
    {
      data: createRentCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/createRent`,
    },
    options
  )
}

export const postApiRentsGetProductRentPrice = (
  getProductRentPriceQuery: BodyType<GetProductRentPriceQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetProductRentPriceResponse>(
    {
      data: getProductRentPriceQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/getProductRentPrice`,
    },
    options
  )
}

export const postApiRentsGetRentDefectFinesList = (
  getRentDefectFinesListQuery: BodyType<GetRentDefectFinesListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetRentDefectFinesListResponse>(
    {
      data: getRentDefectFinesListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/getRentDefectFinesList`,
    },
    options
  )
}

export const postApiRentsGetProductRentPeriods = (
  getProductRentPeriodsQuery: BodyType<GetProductRentPeriodsQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetProductRentPeriodsResponse>(
    {
      data: getProductRentPeriodsQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/getProductRentPeriods`,
    },
    options
  )
}

export const postApiRentsGenerateQrCode = (
  generateQrCodeCommand: BodyType<GenerateQrCodeCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GenerateQrCodeResponse>(
    {
      data: generateQrCodeCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/generateQrCode`,
    },
    options
  )
}

export const postApiRentsValidateQrCode = (
  validateQrCodeCommand: BodyType<ValidateQrCodeCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<ValidateQrCodeResponse>(
    {
      data: validateQrCodeCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/validateQrCode`,
    },
    options
  )
}

export const postApiRentsApproveBySeller = (
  approveBySellerCommand: BodyType<ApproveBySellerCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: approveBySellerCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/approveBySeller`,
    },
    options
  )
}

export const postApiRentsCancelByBuyer = (
  cancelByBuyerCommand: BodyType<CancelByBuyerCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: cancelByBuyerCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/cancelByBuyer`,
    },
    options
  )
}

export const postApiRentsCancelBySeller = (
  cancelBySellerCommand: BodyType<CancelBySellerCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: cancelBySellerCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/cancelBySeller`,
    },
    options
  )
}

export const postApiRentsRejectBySeller = (
  rejectBySellerCommand: BodyType<RejectBySellerCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: rejectBySellerCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Rents/rejectBySeller`,
    },
    options
  )
}

export const postApiRentsSendToBuyer = (
  postApiRentsSendToBuyerBody: BodyType<PostApiRentsSendToBuyerBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiRentsSendToBuyerBody.RentId !== undefined) {
    formData.append('RentId', postApiRentsSendToBuyerBody.RentId.toString())
  }
  if (postApiRentsSendToBuyerBody.ImageUpSide !== undefined) {
    formData.append('ImageUpSide', postApiRentsSendToBuyerBody.ImageUpSide)
  }
  if (postApiRentsSendToBuyerBody.ImageDownSide !== undefined) {
    formData.append('ImageDownSide', postApiRentsSendToBuyerBody.ImageDownSide)
  }
  if (postApiRentsSendToBuyerBody.ImageLeftSide !== undefined) {
    formData.append('ImageLeftSide', postApiRentsSendToBuyerBody.ImageLeftSide)
  }
  if (postApiRentsSendToBuyerBody.ImageRightSide !== undefined) {
    formData.append('ImageRightSide', postApiRentsSendToBuyerBody.ImageRightSide)
  }
  if (postApiRentsSendToBuyerBody.ImageBackSide !== undefined) {
    formData.append('ImageBackSide', postApiRentsSendToBuyerBody.ImageBackSide)
  }
  if (postApiRentsSendToBuyerBody.ImageFrontSide !== undefined) {
    formData.append('ImageFrontSide', postApiRentsSendToBuyerBody.ImageFrontSide)
  }
  if (postApiRentsSendToBuyerBody.OtherImages !== undefined) {
    postApiRentsSendToBuyerBody.OtherImages.forEach(value => formData.append('OtherImages', value))
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Rents/sendToBuyer`,
    },
    options
  )
}

export const postApiRentsArriveToBuyer = (
  postApiRentsArriveToBuyerBody: BodyType<PostApiRentsArriveToBuyerBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiRentsArriveToBuyerBody.RentId !== undefined) {
    formData.append('RentId', postApiRentsArriveToBuyerBody.RentId.toString())
  }
  if (postApiRentsArriveToBuyerBody.ImageUpSide !== undefined) {
    formData.append('ImageUpSide', postApiRentsArriveToBuyerBody.ImageUpSide)
  }
  if (postApiRentsArriveToBuyerBody.ImageDownSide !== undefined) {
    formData.append('ImageDownSide', postApiRentsArriveToBuyerBody.ImageDownSide)
  }
  if (postApiRentsArriveToBuyerBody.ImageLeftSide !== undefined) {
    formData.append('ImageLeftSide', postApiRentsArriveToBuyerBody.ImageLeftSide)
  }
  if (postApiRentsArriveToBuyerBody.ImageRightSide !== undefined) {
    formData.append('ImageRightSide', postApiRentsArriveToBuyerBody.ImageRightSide)
  }
  if (postApiRentsArriveToBuyerBody.ImageBackSide !== undefined) {
    formData.append('ImageBackSide', postApiRentsArriveToBuyerBody.ImageBackSide)
  }
  if (postApiRentsArriveToBuyerBody.ImageFrontSide !== undefined) {
    formData.append('ImageFrontSide', postApiRentsArriveToBuyerBody.ImageFrontSide)
  }
  if (postApiRentsArriveToBuyerBody.OtherImages !== undefined) {
    postApiRentsArriveToBuyerBody.OtherImages.forEach(value =>
      formData.append('OtherImages', value)
    )
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Rents/arriveToBuyer`,
    },
    options
  )
}

export const postApiRentsSendBackToSeller = (
  postApiRentsSendBackToSellerBody: BodyType<PostApiRentsSendBackToSellerBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiRentsSendBackToSellerBody.RentId !== undefined) {
    formData.append('RentId', postApiRentsSendBackToSellerBody.RentId.toString())
  }
  if (postApiRentsSendBackToSellerBody.ImageUpSide !== undefined) {
    formData.append('ImageUpSide', postApiRentsSendBackToSellerBody.ImageUpSide)
  }
  if (postApiRentsSendBackToSellerBody.ImageDownSide !== undefined) {
    formData.append('ImageDownSide', postApiRentsSendBackToSellerBody.ImageDownSide)
  }
  if (postApiRentsSendBackToSellerBody.ImageLeftSide !== undefined) {
    formData.append('ImageLeftSide', postApiRentsSendBackToSellerBody.ImageLeftSide)
  }
  if (postApiRentsSendBackToSellerBody.ImageRightSide !== undefined) {
    formData.append('ImageRightSide', postApiRentsSendBackToSellerBody.ImageRightSide)
  }
  if (postApiRentsSendBackToSellerBody.ImageBackSide !== undefined) {
    formData.append('ImageBackSide', postApiRentsSendBackToSellerBody.ImageBackSide)
  }
  if (postApiRentsSendBackToSellerBody.ImageFrontSide !== undefined) {
    formData.append('ImageFrontSide', postApiRentsSendBackToSellerBody.ImageFrontSide)
  }
  if (postApiRentsSendBackToSellerBody.OtherImages !== undefined) {
    postApiRentsSendBackToSellerBody.OtherImages.forEach(value =>
      formData.append('OtherImages', value)
    )
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Rents/sendBackToSeller`,
    },
    options
  )
}

export const postApiRentsArriveBackToSeller = (
  postApiRentsArriveBackToSellerBody: BodyType<PostApiRentsArriveBackToSellerBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiRentsArriveBackToSellerBody.RentId !== undefined) {
    formData.append('RentId', postApiRentsArriveBackToSellerBody.RentId.toString())
  }
  if (postApiRentsArriveBackToSellerBody.ImageUpSide !== undefined) {
    formData.append('ImageUpSide', postApiRentsArriveBackToSellerBody.ImageUpSide)
  }
  if (postApiRentsArriveBackToSellerBody.ImageDownSide !== undefined) {
    formData.append('ImageDownSide', postApiRentsArriveBackToSellerBody.ImageDownSide)
  }
  if (postApiRentsArriveBackToSellerBody.ImageLeftSide !== undefined) {
    formData.append('ImageLeftSide', postApiRentsArriveBackToSellerBody.ImageLeftSide)
  }
  if (postApiRentsArriveBackToSellerBody.ImageRightSide !== undefined) {
    formData.append('ImageRightSide', postApiRentsArriveBackToSellerBody.ImageRightSide)
  }
  if (postApiRentsArriveBackToSellerBody.ImageBackSide !== undefined) {
    formData.append('ImageBackSide', postApiRentsArriveBackToSellerBody.ImageBackSide)
  }
  if (postApiRentsArriveBackToSellerBody.ImageFrontSide !== undefined) {
    formData.append('ImageFrontSide', postApiRentsArriveBackToSellerBody.ImageFrontSide)
  }
  if (postApiRentsArriveBackToSellerBody.OtherImages !== undefined) {
    postApiRentsArriveBackToSellerBody.OtherImages.forEach(value =>
      formData.append('OtherImages', value)
    )
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Rents/arriveBackToSeller`,
    },
    options
  )
}

export const postApiRentsCompleteWithClaimBySeller = (
  postApiRentsCompleteWithClaimBySellerBody: BodyType<PostApiRentsCompleteWithClaimBySellerBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiRentsCompleteWithClaimBySellerBody.Message !== undefined) {
    formData.append('Message', postApiRentsCompleteWithClaimBySellerBody.Message)
  }
  if (postApiRentsCompleteWithClaimBySellerBody.SelectedDefectFinesIds !== undefined) {
    postApiRentsCompleteWithClaimBySellerBody.SelectedDefectFinesIds.forEach(value =>
      formData.append('SelectedDefectFinesIds', String(value))
    )
  }
  if (postApiRentsCompleteWithClaimBySellerBody.RentId !== undefined) {
    formData.append('RentId', postApiRentsCompleteWithClaimBySellerBody.RentId.toString())
  }
  if (postApiRentsCompleteWithClaimBySellerBody.ImageUpSide !== undefined) {
    formData.append('ImageUpSide', postApiRentsCompleteWithClaimBySellerBody.ImageUpSide)
  }
  if (postApiRentsCompleteWithClaimBySellerBody.ImageDownSide !== undefined) {
    formData.append('ImageDownSide', postApiRentsCompleteWithClaimBySellerBody.ImageDownSide)
  }
  if (postApiRentsCompleteWithClaimBySellerBody.ImageLeftSide !== undefined) {
    formData.append('ImageLeftSide', postApiRentsCompleteWithClaimBySellerBody.ImageLeftSide)
  }
  if (postApiRentsCompleteWithClaimBySellerBody.ImageRightSide !== undefined) {
    formData.append('ImageRightSide', postApiRentsCompleteWithClaimBySellerBody.ImageRightSide)
  }
  if (postApiRentsCompleteWithClaimBySellerBody.ImageBackSide !== undefined) {
    formData.append('ImageBackSide', postApiRentsCompleteWithClaimBySellerBody.ImageBackSide)
  }
  if (postApiRentsCompleteWithClaimBySellerBody.ImageFrontSide !== undefined) {
    formData.append('ImageFrontSide', postApiRentsCompleteWithClaimBySellerBody.ImageFrontSide)
  }
  if (postApiRentsCompleteWithClaimBySellerBody.OtherImages !== undefined) {
    postApiRentsCompleteWithClaimBySellerBody.OtherImages.forEach(value =>
      formData.append('OtherImages', value)
    )
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Rents/completeWithClaimBySeller`,
    },
    options
  )
}

export const postApiRentsCompleteWithClaimByBuyer = (
  postApiRentsCompleteWithClaimByBuyerBody: BodyType<PostApiRentsCompleteWithClaimByBuyerBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiRentsCompleteWithClaimByBuyerBody.Message !== undefined) {
    formData.append('Message', postApiRentsCompleteWithClaimByBuyerBody.Message)
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.RentId !== undefined) {
    formData.append('RentId', postApiRentsCompleteWithClaimByBuyerBody.RentId.toString())
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.ImageUpSide !== undefined) {
    formData.append('ImageUpSide', postApiRentsCompleteWithClaimByBuyerBody.ImageUpSide)
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.ImageDownSide !== undefined) {
    formData.append('ImageDownSide', postApiRentsCompleteWithClaimByBuyerBody.ImageDownSide)
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.ImageLeftSide !== undefined) {
    formData.append('ImageLeftSide', postApiRentsCompleteWithClaimByBuyerBody.ImageLeftSide)
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.ImageRightSide !== undefined) {
    formData.append('ImageRightSide', postApiRentsCompleteWithClaimByBuyerBody.ImageRightSide)
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.ImageBackSide !== undefined) {
    formData.append('ImageBackSide', postApiRentsCompleteWithClaimByBuyerBody.ImageBackSide)
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.ImageFrontSide !== undefined) {
    formData.append('ImageFrontSide', postApiRentsCompleteWithClaimByBuyerBody.ImageFrontSide)
  }
  if (postApiRentsCompleteWithClaimByBuyerBody.OtherImages !== undefined) {
    postApiRentsCompleteWithClaimByBuyerBody.OtherImages.forEach(value =>
      formData.append('OtherImages', value)
    )
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Rents/completeWithClaimByBuyer`,
    },
    options
  )
}

export const postApiReviewsCreateReview = (
  createReviewCommand: BodyType<CreateReviewCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: createReviewCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Reviews/createReview`,
    },
    options
  )
}

export const postApiReviewsGetReviewsList = (
  getReviewsListQuery: BodyType<GetReviewsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetReviewsListResponse>(
    {
      data: getReviewsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Reviews/getReviewsList`,
    },
    options
  )
}

export const postApiReviewsDeleteReview = (
  deleteReviewCommand: BodyType<DeleteReviewCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteReviewCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Reviews/deleteReview`,
    },
    options
  )
}

export const postApiReviewsUpdateReview = (
  updateReviewCommand: BodyType<UpdateReviewCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateReviewCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Reviews/updateReview`,
    },
    options
  )
}

export const getApiRolesGetRolesList = (options?: SecondParameter<typeof createInstance>) => {
  return createInstance<GetRolesResponse>(
    { method: 'GET', url: `/api/Roles/getRolesList` },
    options
  )
}

export const postApiRolesUpdateUserRoles = (
  updateUserRolesCommand: BodyType<UpdateUserRolesCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateUserRolesCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Roles/updateUserRoles`,
    },
    options
  )
}

export const postApiSectionsGetSectionInfo = (
  getSectionInfoQuery: BodyType<GetSectionInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetSectionInfoResponse>(
    {
      data: getSectionInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Sections/getSectionInfo`,
    },
    options
  )
}

export const postApiSectionsGetSectionsList = (
  getSectionsListQuery: BodyType<GetSectionsListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetSectionsListResponse>(
    {
      data: getSectionsListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Sections/getSectionsList`,
    },
    options
  )
}

export const postApiSectionsCreateSection = (
  createSectionCommand: BodyType<CreateSectionCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<CreateSectionResponse>(
    {
      data: createSectionCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Sections/createSection`,
    },
    options
  )
}

export const postApiSectionsUpdateSection = (
  updateSectionCommand: BodyType<UpdateSectionCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateSectionCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Sections/updateSection`,
    },
    options
  )
}

export const postApiSectionsUpdateSectionImage = (
  postApiSectionsUpdateSectionImageBody: BodyType<PostApiSectionsUpdateSectionImageBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiSectionsUpdateSectionImageBody.sectionId !== undefined) {
    formData.append('sectionId', postApiSectionsUpdateSectionImageBody.sectionId.toString())
  }
  if (postApiSectionsUpdateSectionImageBody.image !== undefined) {
    formData.append('image', postApiSectionsUpdateSectionImageBody.image)
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Sections/updateSectionImage`,
    },
    options
  )
}

export const postApiSectionsDeleteSection = (
  deleteSectionCommand: BodyType<DeleteSectionCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteSectionCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Sections/deleteSection`,
    },
    options
  )
}

export const getApiStatisticsGetGeneralStatistic = (
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetGeneralStatisticResponse>(
    { method: 'GET', url: `/api/Statistics/getGeneralStatistic` },
    options
  )
}

export const postApiTransactionsCreatePayment = (
  createPaymentCommand: BodyType<CreatePaymentCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<CreatePaymentResponse>(
    {
      data: createPaymentCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Transactions/createPayment`,
    },
    options
  )
}

export const postApiTransactionsCreatePayout = (
  createPayoutCommand: BodyType<CreatePayoutCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: createPayoutCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Transactions/createPayout`,
    },
    options
  )
}

export const getApiTransactionsGetTransactions = (
  params?: GetApiTransactionsGetTransactionsParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetTransactionsResponse>(
    { method: 'GET', params, url: `/api/Transactions/getTransactions` },
    options
  )
}

export const postApiTransactionsHandlePostback = (
  handlePostbackCommand: BodyType<HandlePostbackCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: handlePostbackCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Transactions/handlePostback`,
    },
    options
  )
}

export const postApiUserAddressesAddUserAddress = (
  addUserAddressCommand: BodyType<AddUserAddressCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<UserAddressDto>(
    {
      data: addUserAddressCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/UserAddresses/addUserAddress`,
    },
    options
  )
}

export const postApiUserAddressesMakeUserAddressDefault = (
  makeUserAddressDefaultCommand: BodyType<MakeUserAddressDefaultCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: makeUserAddressDefaultCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/UserAddresses/makeUserAddressDefault`,
    },
    options
  )
}

export const deleteApiUserAddressesRemoveUserAddress = (
  removeUserAddressCommand: BodyType<RemoveUserAddressCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: removeUserAddressCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
      url: `/api/UserAddresses/removeUserAddress`,
    },
    options
  )
}

export const getApiUserAddressesGetUserAddresses = (
  params?: GetApiUserAddressesGetUserAddressesParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserAddressesResponse>(
    { method: 'GET', params, url: `/api/UserAddresses/getUserAddresses` },
    options
  )
}

export const getApiUserAddressesGetUserAddressInfo = (
  params?: GetApiUserAddressesGetUserAddressInfoParams,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserAddressInfoResponse>(
    { method: 'GET', params, url: `/api/UserAddresses/getUserAddressInfo` },
    options
  )
}

export const postApiUsersGetUsersList = (
  getUsersListQuery: BodyType<GetUsersListQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUsersListResponse>(
    {
      data: getUsersListQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/getUsersList`,
    },
    options
  )
}

export const postApiUsersGetUserInfo = (
  getUserInfoQuery: BodyType<GetUserInfoQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserInfoResponse>(
    {
      data: getUserInfoQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/getUserInfo`,
    },
    options
  )
}

export const postApiUsersGetUserProfile = (
  getUserProfileQuery: BodyType<GetUserProfileQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserProfileResponse>(
    {
      data: getUserProfileQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/getUserProfile`,
    },
    options
  )
}

export const postApiUsersGetUserSubscriptions = (
  getUserSubscriptionsQuery: BodyType<GetUserSubscriptionsQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserSubscriptionsResponse>(
    {
      data: getUserSubscriptionsQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/getUserSubscriptions`,
    },
    options
  )
}

export const postApiUsersGetUserSubscribers = (
  getUserSubscribersQuery: BodyType<GetUserSubscribersQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserSubscribersResponse>(
    {
      data: getUserSubscribersQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/getUserSubscribers`,
    },
    options
  )
}

export const postApiUsersGetUserReferrals = (
  getUserReferralsQuery: BodyType<GetUserReferralsQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserReferralsResponse>(
    {
      data: getUserReferralsQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/getUserReferrals`,
    },
    options
  )
}

export const postApiUsersGenerateAndGetReferralCode = (
  generateAndGetReferralCodeCommand: BodyType<GenerateAndGetReferralCodeCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GenerateAndGetReferralCodeResponse>(
    {
      data: generateAndGetReferralCodeCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/generateAndGetReferralCode`,
    },
    options
  )
}

export const postApiUsersUnsubscribe = (
  unsubscribeCommand: BodyType<UnsubscribeCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: unsubscribeCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/unsubscribe`,
    },
    options
  )
}

export const postApiUsersSubscribe = (
  subscribeCommand: BodyType<SubscribeCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: subscribeCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/subscribe`,
    },
    options
  )
}

export const postApiUsersUpdateUserInfo = (
  updateUserInfoCommand: BodyType<UpdateUserInfoCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updateUserInfoCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/updateUserInfo`,
    },
    options
  )
}

export const postApiUsersUpdatePassword = (
  updatePasswordCommand: BodyType<UpdatePasswordCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: updatePasswordCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/updatePassword`,
    },
    options
  )
}

export const postApiUsersDeleteUser = (
  deleteUserCommand: BodyType<DeleteUserCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteUserCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/deleteUser`,
    },
    options
  )
}

export const postApiUsersUpdateUserAvatar = (
  postApiUsersUpdateUserAvatarBody: BodyType<PostApiUsersUpdateUserAvatarBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiUsersUpdateUserAvatarBody.userId !== undefined) {
    formData.append('userId', postApiUsersUpdateUserAvatarBody.userId)
  }
  if (postApiUsersUpdateUserAvatarBody.avatarImage !== undefined) {
    formData.append('avatarImage', postApiUsersUpdateUserAvatarBody.avatarImage)
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Users/updateUserAvatar`,
    },
    options
  )
}

export const postApiUsersChangeUserBalance = (
  changeUserBalanceCommand: BodyType<ChangeUserBalanceCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: changeUserBalanceCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/changeUserBalance`,
    },
    options
  )
}

export const postApiUsersGetUserRating = (
  getUserRatingQuery: BodyType<GetUserRatingQuery>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<GetUserRatingResponse>(
    {
      data: getUserRatingQuery,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Users/getUserRating`,
    },
    options
  )
}

export const postApiUtilsAddDataFromExcel = (
  postApiUtilsAddDataFromExcelBody: BodyType<PostApiUtilsAddDataFromExcelBody>,
  options?: SecondParameter<typeof createInstance>
) => {
  const formData = new FormData()

  if (postApiUtilsAddDataFromExcelBody.formFile !== undefined) {
    formData.append('formFile', postApiUtilsAddDataFromExcelBody.formFile)
  }

  return createInstance<void>(
    {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `/api/Utils/addDataFromExcel`,
    },
    options
  )
}

export const postApiUtilsDeleteAllData = (
  deleteDataCommand: BodyType<DeleteDataCommand>,
  options?: SecondParameter<typeof createInstance>
) => {
  return createInstance<void>(
    {
      data: deleteDataCommand,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `/api/Utils/deleteAllData`,
    },
    options
  )
}

export type PostApiArticlesGetArticlesListResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesGetArticlesList>>
>
export type PostApiArticlesGetArticleCommentsResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesGetArticleComments>>
>
export type PostApiArticlesCreateArticleResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesCreateArticle>>
>
export type PostApiArticlesUpdateArticleResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesUpdateArticle>>
>
export type PostApiArticlesDeleteArticleResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesDeleteArticle>>
>
export type PostApiArticlesGetArticleInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesGetArticleInfo>>
>
export type PostApiArticlesViewArticleResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesViewArticle>>
>
export type PostApiArticlesShareArticleResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesShareArticle>>
>
export type PostApiArticlesArchiveArticleResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesArchiveArticle>>
>
export type PostApiArticlesCommentArticleResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesCommentArticle>>
>
export type PostApiArticlesGetArticleTagListResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesGetArticleTagList>>
>
export type PostApiArticlesCreateArticleTagResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesCreateArticleTag>>
>
export type PostApiArticlesDeleteArticleTagResult = NonNullable<
  Awaited<ReturnType<typeof postApiArticlesDeleteArticleTag>>
>
export type PostApiAuthSignInEmailResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthSignInEmail>>
>
export type PostApiAuthSignInPhoneByCodeResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthSignInPhoneByCode>>
>
export type PostApiAuthSignInPhoneByPasswordResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthSignInPhoneByPassword>>
>
export type PostApiAuthSendAuthCodeResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthSendAuthCode>>
>
export type PostApiAuthRefreshTokenResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthRefreshToken>>
>
export type PostApiAuthSignUpResult = NonNullable<Awaited<ReturnType<typeof postApiAuthSignUp>>>
export type PostApiAuthResetPasswordResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthResetPassword>>
>
export type PostApiAuthForgotPasswordResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthForgotPassword>>
>
export type GetApiAuthGetCurrentUserResult = NonNullable<
  Awaited<ReturnType<typeof getApiAuthGetCurrentUser>>
>
export type PostApiAuthSendRegistrationCodeBySmsResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthSendRegistrationCodeBySms>>
>
export type PostApiAuthSendRegistrationCodeByEmailResult = NonNullable<
  Awaited<ReturnType<typeof postApiAuthSendRegistrationCodeByEmail>>
>
export type GetApiAuthCheckPhoneRegistrationCodeResult = NonNullable<
  Awaited<ReturnType<typeof getApiAuthCheckPhoneRegistrationCode>>
>
export type GetApiAuthCheckEmailRegistrationCodeResult = NonNullable<
  Awaited<ReturnType<typeof getApiAuthCheckEmailRegistrationCode>>
>
export type PostApiCategoriesGetCategoryInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiCategoriesGetCategoryInfo>>
>
export type PostApiCategoriesGetCategoriesListResult = NonNullable<
  Awaited<ReturnType<typeof postApiCategoriesGetCategoriesList>>
>
export type PostApiCategoriesCreateCategoryResult = NonNullable<
  Awaited<ReturnType<typeof postApiCategoriesCreateCategory>>
>
export type PostApiCategoriesUpdateCategoryResult = NonNullable<
  Awaited<ReturnType<typeof postApiCategoriesUpdateCategory>>
>
export type PostApiCategoriesUpdateCategoryImageResult = NonNullable<
  Awaited<ReturnType<typeof postApiCategoriesUpdateCategoryImage>>
>
export type PostApiCategoriesDeleteCategoryResult = NonNullable<
  Awaited<ReturnType<typeof postApiCategoriesDeleteCategory>>
>
export type GetApiChatsGetChatsListResult = NonNullable<
  Awaited<ReturnType<typeof getApiChatsGetChatsList>>
>
export type PostApiChatsGetChatInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiChatsGetChatInfo>>
>
export type GetApiChatsGetChatMessagesResult = NonNullable<
  Awaited<ReturnType<typeof getApiChatsGetChatMessages>>
>
export type PostApiChatsViewChatMessageResult = NonNullable<
  Awaited<ReturnType<typeof postApiChatsViewChatMessage>>
>
export type PostApiChatsSendChatMessageResult = NonNullable<
  Awaited<ReturnType<typeof postApiChatsSendChatMessage>>
>
export type PostApiClaimsGetClaimsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiClaimsGetClaimsList>>
>
export type PostApiClaimsGetClaimInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiClaimsGetClaimInfo>>
>
export type PostApiClaimsApproveClaimResult = NonNullable<
  Awaited<ReturnType<typeof postApiClaimsApproveClaim>>
>
export type PostApiClaimsRejectClaimResult = NonNullable<
  Awaited<ReturnType<typeof postApiClaimsRejectClaim>>
>
export type PostApiCriteriaCreateCriterionResult = NonNullable<
  Awaited<ReturnType<typeof postApiCriteriaCreateCriterion>>
>
export type PostApiCriteriaEditCriterionResult = NonNullable<
  Awaited<ReturnType<typeof postApiCriteriaEditCriterion>>
>
export type PostApiCriteriaDeleteCriterionResult = NonNullable<
  Awaited<ReturnType<typeof postApiCriteriaDeleteCriterion>>
>
export type PostApiCriteriaGetCategoryCriteriaResult = NonNullable<
  Awaited<ReturnType<typeof postApiCriteriaGetCategoryCriteria>>
>
export type PostApiDocumentsAddPassportResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsAddPassport>>
>
export type PostApiDocumentsAddLegalEntityInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsAddLegalEntityInfo>>
>
export type PostApiDocumentsVerifyPassportResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsVerifyPassport>>
>
export type PostApiDocumentsVerifyLegalEntityInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsVerifyLegalEntityInfo>>
>
export type PostApiDocumentsRejectPassportVerificationResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsRejectPassportVerification>>
>
export type PostApiDocumentsRejectLegalEntityInfoVerificationResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsRejectLegalEntityInfoVerification>>
>
export type PostApiDocumentsRemovePassportResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsRemovePassport>>
>
export type PostApiDocumentsRemoveLegalEntityInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsRemoveLegalEntityInfo>>
>
export type PostApiDocumentsGetPassportInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsGetPassportInfo>>
>
export type PostApiDocumentsGetLegalEntityInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsGetLegalEntityInfo>>
>
export type PostApiDocumentsGetPassportsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsGetPassportsList>>
>
export type PostApiDocumentsGetLegalEntityInfosListResult = NonNullable<
  Awaited<ReturnType<typeof postApiDocumentsGetLegalEntityInfosList>>
>
export type PostApiFilesUploadFileResult = NonNullable<
  Awaited<ReturnType<typeof postApiFilesUploadFile>>
>
export type GetApiFilesGetFileFileIdResult = NonNullable<
  Awaited<ReturnType<typeof getApiFilesGetFileFileId>>
>
export type PostApiFilesDeleteFileResult = NonNullable<
  Awaited<ReturnType<typeof postApiFilesDeleteFile>>
>
export type GetApiFilesGetFilesListResult = NonNullable<
  Awaited<ReturnType<typeof getApiFilesGetFilesList>>
>
export type PostApiFilesGetFileInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiFilesGetFileInfo>>
>
export type PostApiOperationsGetOperationInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiOperationsGetOperationInfo>>
>
export type PostApiOperationsGetOperationsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiOperationsGetOperationsList>>
>
export type PostApiOperationsCreateWithdrawalRequestResult = NonNullable<
  Awaited<ReturnType<typeof postApiOperationsCreateWithdrawalRequest>>
>
export type PostApiOperationsApproveWithdrawalRequestResult = NonNullable<
  Awaited<ReturnType<typeof postApiOperationsApproveWithdrawalRequest>>
>
export type PostApiOperationsRejectWithdrawalRequestResult = NonNullable<
  Awaited<ReturnType<typeof postApiOperationsRejectWithdrawalRequest>>
>
export type PostApiOperationsGetWithdrawalRequestsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiOperationsGetWithdrawalRequestsList>>
>
export type PostApiOperationsGetWithdrawalRequestInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiOperationsGetWithdrawalRequestInfo>>
>
export type PostApiPaymentsGetPaymentMethodsResult = NonNullable<
  Awaited<ReturnType<typeof postApiPaymentsGetPaymentMethods>>
>
export type PostApiPaymentsGetBankCardInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiPaymentsGetBankCardInfo>>
>
export type PostApiPaymentsAddBankCardResult = NonNullable<
  Awaited<ReturnType<typeof postApiPaymentsAddBankCard>>
>
export type PostApiPaymentsRemoveBankCardResult = NonNullable<
  Awaited<ReturnType<typeof postApiPaymentsRemoveBankCard>>
>
export type PostApiPaymentsAddBankAccountResult = NonNullable<
  Awaited<ReturnType<typeof postApiPaymentsAddBankAccount>>
>
export type PostApiPaymentsRemoveBankAccountResult = NonNullable<
  Awaited<ReturnType<typeof postApiPaymentsRemoveBankAccount>>
>
export type GetApiPaymentsGetBankAccountTopUpRequisitesResult = NonNullable<
  Awaited<ReturnType<typeof getApiPaymentsGetBankAccountTopUpRequisites>>
>
export type PostApiProductsGetProductsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsGetProductsList>>
>
export type PostApiProductsGetArchivedProductsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsGetArchivedProductsList>>
>
export type PostApiProductsCreateProductResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsCreateProduct>>
>
export type PostApiProductsArchiveProductResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsArchiveProduct>>
>
export type PostApiProductsRestoreProductResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsRestoreProduct>>
>
export type PostApiProductsDeleteProductResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsDeleteProduct>>
>
export type PostApiProductsGetProductInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsGetProductInfo>>
>
export type PostApiProductsGetArchivedProductInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsGetArchivedProductInfo>>
>
export type PostApiProductsUpdateProductInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsUpdateProductInfo>>
>
export type PostApiProductsUpdateProductPriceResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsUpdateProductPrice>>
>
export type PostApiProductsUpdateProductDeliveryResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsUpdateProductDelivery>>
>
export type GetApiProductsGetProductTagsListResult = NonNullable<
  Awaited<ReturnType<typeof getApiProductsGetProductTagsList>>
>
export type PostApiProductsCreateProductTagResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsCreateProductTag>>
>
export type PostApiProductsDeleteProductTagResult = NonNullable<
  Awaited<ReturnType<typeof postApiProductsDeleteProductTag>>
>
export type PostApiRentsGetRentsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsGetRentsList>>
>
export type PostApiRentsGetRentInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsGetRentInfo>>
>
export type PostApiRentsCreateRentResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsCreateRent>>
>
export type PostApiRentsGetProductRentPriceResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsGetProductRentPrice>>
>
export type PostApiRentsGetRentDefectFinesListResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsGetRentDefectFinesList>>
>
export type PostApiRentsGetProductRentPeriodsResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsGetProductRentPeriods>>
>
export type PostApiRentsGenerateQrCodeResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsGenerateQrCode>>
>
export type PostApiRentsValidateQrCodeResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsValidateQrCode>>
>
export type PostApiRentsApproveBySellerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsApproveBySeller>>
>
export type PostApiRentsCancelByBuyerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsCancelByBuyer>>
>
export type PostApiRentsCancelBySellerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsCancelBySeller>>
>
export type PostApiRentsRejectBySellerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsRejectBySeller>>
>
export type PostApiRentsSendToBuyerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsSendToBuyer>>
>
export type PostApiRentsArriveToBuyerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsArriveToBuyer>>
>
export type PostApiRentsSendBackToSellerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsSendBackToSeller>>
>
export type PostApiRentsArriveBackToSellerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsArriveBackToSeller>>
>
export type PostApiRentsCompleteWithClaimBySellerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsCompleteWithClaimBySeller>>
>
export type PostApiRentsCompleteWithClaimByBuyerResult = NonNullable<
  Awaited<ReturnType<typeof postApiRentsCompleteWithClaimByBuyer>>
>
export type PostApiReviewsCreateReviewResult = NonNullable<
  Awaited<ReturnType<typeof postApiReviewsCreateReview>>
>
export type PostApiReviewsGetReviewsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiReviewsGetReviewsList>>
>
export type PostApiReviewsDeleteReviewResult = NonNullable<
  Awaited<ReturnType<typeof postApiReviewsDeleteReview>>
>
export type PostApiReviewsUpdateReviewResult = NonNullable<
  Awaited<ReturnType<typeof postApiReviewsUpdateReview>>
>
export type GetApiRolesGetRolesListResult = NonNullable<
  Awaited<ReturnType<typeof getApiRolesGetRolesList>>
>
export type PostApiRolesUpdateUserRolesResult = NonNullable<
  Awaited<ReturnType<typeof postApiRolesUpdateUserRoles>>
>
export type PostApiSectionsGetSectionInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiSectionsGetSectionInfo>>
>
export type PostApiSectionsGetSectionsListResult = NonNullable<
  Awaited<ReturnType<typeof postApiSectionsGetSectionsList>>
>
export type PostApiSectionsCreateSectionResult = NonNullable<
  Awaited<ReturnType<typeof postApiSectionsCreateSection>>
>
export type PostApiSectionsUpdateSectionResult = NonNullable<
  Awaited<ReturnType<typeof postApiSectionsUpdateSection>>
>
export type PostApiSectionsUpdateSectionImageResult = NonNullable<
  Awaited<ReturnType<typeof postApiSectionsUpdateSectionImage>>
>
export type PostApiSectionsDeleteSectionResult = NonNullable<
  Awaited<ReturnType<typeof postApiSectionsDeleteSection>>
>
export type GetApiStatisticsGetGeneralStatisticResult = NonNullable<
  Awaited<ReturnType<typeof getApiStatisticsGetGeneralStatistic>>
>
export type PostApiTransactionsCreatePaymentResult = NonNullable<
  Awaited<ReturnType<typeof postApiTransactionsCreatePayment>>
>
export type PostApiTransactionsCreatePayoutResult = NonNullable<
  Awaited<ReturnType<typeof postApiTransactionsCreatePayout>>
>
export type GetApiTransactionsGetTransactionsResult = NonNullable<
  Awaited<ReturnType<typeof getApiTransactionsGetTransactions>>
>
export type PostApiTransactionsHandlePostbackResult = NonNullable<
  Awaited<ReturnType<typeof postApiTransactionsHandlePostback>>
>
export type PostApiUserAddressesAddUserAddressResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserAddressesAddUserAddress>>
>
export type PostApiUserAddressesMakeUserAddressDefaultResult = NonNullable<
  Awaited<ReturnType<typeof postApiUserAddressesMakeUserAddressDefault>>
>
export type DeleteApiUserAddressesRemoveUserAddressResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiUserAddressesRemoveUserAddress>>
>
export type GetApiUserAddressesGetUserAddressesResult = NonNullable<
  Awaited<ReturnType<typeof getApiUserAddressesGetUserAddresses>>
>
export type GetApiUserAddressesGetUserAddressInfoResult = NonNullable<
  Awaited<ReturnType<typeof getApiUserAddressesGetUserAddressInfo>>
>
export type PostApiUsersGetUsersListResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGetUsersList>>
>
export type PostApiUsersGetUserInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGetUserInfo>>
>
export type PostApiUsersGetUserProfileResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGetUserProfile>>
>
export type PostApiUsersGetUserSubscriptionsResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGetUserSubscriptions>>
>
export type PostApiUsersGetUserSubscribersResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGetUserSubscribers>>
>
export type PostApiUsersGetUserReferralsResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGetUserReferrals>>
>
export type PostApiUsersGenerateAndGetReferralCodeResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGenerateAndGetReferralCode>>
>
export type PostApiUsersUnsubscribeResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersUnsubscribe>>
>
export type PostApiUsersSubscribeResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersSubscribe>>
>
export type PostApiUsersUpdateUserInfoResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersUpdateUserInfo>>
>
export type PostApiUsersUpdatePasswordResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersUpdatePassword>>
>
export type PostApiUsersDeleteUserResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersDeleteUser>>
>
export type PostApiUsersUpdateUserAvatarResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersUpdateUserAvatar>>
>
export type PostApiUsersChangeUserBalanceResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersChangeUserBalance>>
>
export type PostApiUsersGetUserRatingResult = NonNullable<
  Awaited<ReturnType<typeof postApiUsersGetUserRating>>
>
export type PostApiUtilsAddDataFromExcelResult = NonNullable<
  Awaited<ReturnType<typeof postApiUtilsAddDataFromExcel>>
>
export type PostApiUtilsDeleteAllDataResult = NonNullable<
  Awaited<ReturnType<typeof postApiUtilsDeleteAllData>>
>
