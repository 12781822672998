import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { ProductListItem } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { ExistedPrice } from '../../../../shared/ui-kit/actual/components/ExistedPrice'
import { FlexColumn } from '../../../../shared/ui-kit/projectComponents'

const CardBlock = styled(FlexColumn)`
  width: 160px;
  text-align: left;
  padding: 4px;
  justify-content: flex-start;
  h3 {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-top: 12px;
    color: ${colors.black};
  }
  h4 {
    width: 100%;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.grey};
  }
`
const CardWrapper = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
`
const MainImage = styled.img`
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 12px;
`
const BrandImage = styled(FlexColumn)`
  position: absolute;
  bottom: 2px;
  height: 40px;
  width: 40px;
  max-width: 40px;
  background: #fff;
  border-radius: 100%;
  margin: 8px;
  img {
    width: 25px;
    height: 25px;
  }
`
const BrandAvatarImage = styled(FlexColumn)`
  position: absolute;
  bottom: 2px;
  height: 40px;
  width: 40px;
  max-width: 40px;
  background: #fff;
  border-radius: 100%;
  margin: 8px;
  img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

interface PromoCardProps {
  brandImage?: string
  description?: string
  iconImage?: string
  image: string
  link: string
  product?: ProductListItem
  title: string
}
export const PromoCard = ({
  brandImage,
  description,
  iconImage,
  image,
  link,
  product,
  title,
}: PromoCardProps) => {
  return (
    <CardBlock>
      <StyledLink to={link}>
        <CardWrapper>
          <MainImage src={image || '/images/interface/EmptyBox.svg'} />
          {brandImage ? (
            <BrandAvatarImage>
              <img alt={'brand image'} src={brandImage} />
            </BrandAvatarImage>
          ) : (
            <BrandImage>
              <img src={iconImage || '/images/bottom-menu/cart.svg'} />
            </BrandImage>
          )}
        </CardWrapper>
        <h3>{title}</h3>
        <h4>{description}</h4>
        {/* {price && price > 0 ? <h4>{price}₽ в день</h4> : null} */}
        {product && (
          <h4>
            <ExistedPrice productInfo={product} />
          </h4>
        )}
      </StyledLink>
    </CardBlock>
  )
}
