import { useRef, useState } from 'react'

import styled from 'styled-components/macro'

import { useUserAddressesRemoveUserAddressMutation } from '../../../entities/userAddress/queries'
import { colors } from '../../../shared/constants/colors'
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside'
import { FlexColumnBetween } from '../../../shared/ui-kit/projectComponents'

const DropDownMenu = styled(FlexColumnBetween)`
  position: absolute;
  z-index: 1000;
  right: 7px;
  top: 20px;
  background-color: ${colors.white};
  row-gap: 8px;
  padding: 12px;
  border-radius: 7px;
  box-shadow: 2px 2px 3px 2px #0000000f;
`
const DropDownItem = styled.button<{ $color?: string }>`
  color: ${({ $color }) => ($color ? $color : colors.dark)};
  background-color: ${colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  border: none;
  :hover {
    color: ${colors.white};
    background-color: ${colors.red};
  }
`

export const DropDownMini = ({ addressId }: { addressId: number }) => {
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const { mutate } = useUserAddressesRemoveUserAddressMutation()

  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <div id={'drop-down'} style={{ position: 'relative' }}>
      <button style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        <img
          alt={''}
          onClick={() => setIsOpen(prev => !prev)}
          src={'/images/buttons/three-dots.svg'}
        />
      </button>
      {isOpen && (
        <DropDownMenu ref={ref}>
          {/* <DropDownItem onClick={() => setIsOpen(prev => !prev)}>Редактировать</DropDownItem> */}
          <DropDownItem
            onClick={() => {
              setIsOpen(prev => !prev)
              mutate({ id: addressId })
            }}
            $color={colors.red}
          >
            Удалить адрес
          </DropDownItem>
        </DropDownMenu>
      )}
    </div>
  )
}
