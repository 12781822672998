import styled from 'styled-components/macro'

import { SectionListItemDto } from '../../../../shared/api/generated'
import { RadioInput } from '../../../../shared/ui-kit/actual/RadioInput'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumnStart,
  FlexRow,
  HeaderTitle,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'

const Wrapper = styled(FlexColumnStart)`
  width: 100%;
  gap: 24px;
  .left {
    align-items: flex-start;
  }

  .center {
    align-items: center;
  }

  .right {
    align-items: right;
  }
`

const SideWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const HeaderWrapper = styled(FlexRow)`
  width: 100%;
`

interface CategoriesModalProps {
  handleSetOrder?: (value: string) => void
  onCloseModal: () => void
  selectedSort: string
  setSelectedSort: (it: any) => void
  sortingCategories: SectionListItemDto[]
}

const SectionsWrapper = styled(FlexColumnStart)`
  width: inherit;
`

const CategoriesModal = ({
  handleSetOrder,
  onCloseModal,
  selectedSort,
  sortingCategories,
}: CategoriesModalProps) => {
  const propagationHandler = e => {
    e.stopPropagation()
  }

  return (
    <Wrapper onClick={propagationHandler}>
      <HeaderWrapper>
        <SideWrapper className={'left'}>
          <StyledButton
            onClick={onCloseModal}
            style={{ padding: '0', width: 'fit-content' }}
            variant={'neutral'}
            isIcoBtn
          >
            <img alt={''} src={'/images/buttons/arrow-left.svg'} />
          </StyledButton>
        </SideWrapper>
        <SideWrapper className={'center'}>
          <HeaderTitle>Разделы</HeaderTitle>
        </SideWrapper>
        <SideWrapper className={'right'}> </SideWrapper>
      </HeaderWrapper>
      <SectionsWrapper>
        {sortingCategories.length > 0 ? (
          sortingCategories.map((selector, idx) => (
            <RadioInput
              handleSetOrder={() => handleSetOrder(String(selector.id))}
              key={idx}
              selectedSort={selectedSort}
              selector={{ name: selector.name, value: String(selector.id) }}
            />
          ))
        ) : (
          <PageLoader />
        )}
      </SectionsWrapper>
    </Wrapper>
  )
}

export default CategoriesModal
