import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { useGetMarketItemQuery } from '../entities/marketItem/queries'
import { colors } from '../shared/constants/colors'
import { symbols } from '../shared/constants/symbols'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { SliderComponent } from '../shared/ui-kit/actual/Slider'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { AdminArchiveItemModal } from '../shared/ui-kit/actual/components/AdminArchiveItemModal'
import { AdminDeleteItemModal } from '../shared/ui-kit/actual/components/AdminDeleteItemModal'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { Spacer } from '../shared/ui-kit/deprecated/Spacer'
import { BasePage, FlexColumn, FlexRowGap, StyledButton } from '../shared/ui-kit/projectComponents'

const DataElement = styled(FlexColumn)`
  width: calc(100% - 30px);
  justify-content: start;
  align-items: start;
  margin: 5px;
`
const DataTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin: 8px 0 4px;
`
const DataInfo = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  text-align: left;
`
const BorderLine = styled.div`
  width: calc(100% - 32px);
  margin: 20px 0;
  border: 0.5px solid ${colors.lightGray};
`

const AdminProductPage: React.FC = () => {
  const params = useParams()
  const productId = params['productId']
  const { data: product, isLoading } = useGetMarketItemQuery({ productId: +productId })
  const createDate =
    product?.createdOn &&
    format(new Date(product?.createdOn), 'dd MMMM HH:mm', {
      locale: ru,
    })

  const showProductInfo = [
    {
      info: product?.name,
      title: 'Название',
    },
    {
      info: product?.description,
      title: 'Описание',
    },
    // {
    //   info: product?.address,
    //   title: 'Адрес',
    // },
    // {
    //   info: sectionsList && sectionsList.find(s => s.id === product?.sectionId)?.name,
    //   title: 'Секция',
    // },
    {
      info: createDate,
      title: 'Создан',
    },
    {
      info: product?.productTags?.map(p => p.name).join(', '),
      title: 'Тэги',
    },
  ]
  const showProductInfo2 = [
    {
      info: product?.pledge,
      title: 'Залог',
    },
    {
      info: `${product?.dayPrice} ${symbols.ruble}`,
      title: 'Цена в день',
    },
    {
      info: `${product?.threeDaysPrice} ${symbols.ruble}`,
      title: 'Цена за 3 дня',
    },
    {
      info: `${product?.weekPrice} ${symbols.ruble}`,
      title: 'Цена за неделю',
    },
    {
      info: `${product?.monthPrice} ${symbols.ruble}`,
      title: 'Цена в месяц',
    },
  ]
  const showProductInfo3 = [
    // {
    //   info: product?.orders,
    //   title: 'Количество заказов',
    // },
    // {
    //   info: product?.rating,
    //   title: 'Рейтинг',
    // },
    // {
    //   info: product?.reviewsCount,
    //   title: 'Количество отзывов',
    // },
  ]
  const showProductInfo4 = [
    // {
    //   info: product?.isRemoved ? 'Да' : 'Нет',
    //   title: 'Товар удален',
    // },
    // {
    //   info: product?.isRented ? 'Да' : 'Нет',
    //   title: 'Товар арендован',
    // },
  ]
  const showProductInfo5 = [
    {
      info: product?.owner?.userName,
      title: 'Ник владельца',
    },
    {
      info: product?.owner?.fullName,
      title: 'ФИО владельца',
    },
    {
      info: product?.owner?.id,
      title: 'ID владельца',
    },
  ]
  const [deleteModal, setDeleteModal] = useState(false)
  const [archiveModal, setArchiveModal] = useState(false)

  return (
    <BasePage>
      <AbsoluteLoader isLoading={isLoading} />
      <MainHeader />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      {product && (
        <>
          <SliderComponent
            items={product?.imagesUrls?.map((image, index) => (
              <img
                alt={'product'}
                height={425}
                key={index}
                src={image}
                style={{ objectFit: 'contain' }}
                width={'100%'}
              />
            ))}
            slidesToShow={1}
            dots
          />

          <BorderLine />
          {showProductInfo.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />
          {showProductInfo2.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />
          {showProductInfo3.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />
          {showProductInfo4.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          {showProductInfo5.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />

          <FlexRowGap style={{ width: '100%' }}>
            <StyledButton onClick={() => setDeleteModal(true)} variant={'danger'}>
              Удалить
            </StyledButton>
            <StyledButton onClick={() => setArchiveModal(true)} size={'xl'} variant={'warning'}>
              Скрыть в архиве
            </StyledButton>
          </FlexRowGap>

          <Spacer />
          <AdminDeleteItemModal
            deleteModal={deleteModal}
            productId={+productId}
            setOpen={setDeleteModal}
          />
          <AdminArchiveItemModal
            archiveModal={archiveModal}
            productId={+productId}
            setOpen={setArchiveModal}
          />
        </>
      )}
    </BasePage>
  )
}

export default AdminProductPage
