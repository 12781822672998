import { useState } from 'react'

import { useRentsGetRentsListQuery } from '../../../entities/rent'

interface useFiltredProps {
  periodFrom?: string
  periodTo?: string
  userId?: string
}

export function useTodayRentsList({ periodFrom, periodTo, userId }: useFiltredProps) {
  const [take, setTake] = useState(120)

  const {
    data: fullData,
    isError,
    isLoading,
    isSuccess,
  } = useRentsGetRentsListQuery({
    periodFrom,
    periodTo,
    rentStatuses: ['created'],
    sellerId: userId,
    take,
    userId,
  })

  const filteredData = fullData?.items || []

  return {
    filteredData,
    fullData,
    isError,
    isLoading,
    isSuccess,
    userId,
  }
}
