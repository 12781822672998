import { useContext, useState } from 'react'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { FilterByCategoriesSection } from '../../../../entities/filterByCategories'
import FilterStorage from '../../../storage/FilterStorage/FilterStorage'
import { FlexColumnStart, HeaderTitle, Modal } from '../../projectComponents'
import { Head } from '../../titles'
import { InputSwitchWithTitle } from '../InputSwitchWithTitle'
import { FilterButtonPanel } from './filterComponents/FilterButtonPanel'
import { FilterPricePanel } from './filterComponents/FilterPricePanel'
import { FilterSellerPanel } from './filterComponents/FilterSellerPanel'
import { SelectColorComponent } from './filterComponents/SelectColorComponent'

const WrapperColumn = styled(FlexColumnStart)`
  height: 100vh;
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  justify-content: flex-start;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 2;
`

const colorsMockData = [
  '#0E0E10',
  '#4B7EFF',
  '#AE7250',
  '#DF9A7A',
  '#C9AB4C',
  '#2EA690',
  '#9F9F9F',
  '#163564',
]
const tagsMockData = ['Короткие', 'Длинные', 'Яркие', 'Миди', 'Хит сезона']
const sizesMockData = ['42', '44', '46', '48', '50', '52', '54', '56']
const termsMockData = ['день', '2 дня', '3 дня', '5 дней', 'неделя', '2 недели', 'месяц']
const sellersMockData = [
  { imgUrl: '/images/mock-items/brand-1.png', name: 'Bershka' },
  { imgUrl: '/images/mock-items/brand-2.jpg', name: 'Zara' },
  { imgUrl: '/images/mock-items/brand-3.jpg', name: 'H&M' },
]

const FilterWindowNew = ({
  handleFilterModalOpen,
  isSearch,
}: {
  handleFilterModalOpen: () => void
  isSearch?: boolean
}) => {
  const { isHighRatingOnly, isPremiumOnly, setHighRatingOnly, setPremiumOnly, sorting } =
    useContext(FilterStorage)

  const toggleHightRatingOnly = () => {
    setHighRatingOnly(!(sorting.ratingFrom > 3))
  }
  const togglePremium = () => {
    setPremiumOnly(!sorting.premiumOnly)
  }
  // TODO: цвета скорей всего с бека будут приходить доступные
  const [colorIds, setColorIds] = useState([])
  // TODO: размеры или иные параметры скорей всего с бэка будут приходить доступные
  const [sizesId, setSizesId] = useState([])
  const [tagsId, setTagsId] = useState([])
  const [termsId, setTermsId] = useState([])
  const [sellersId, setSellersId] = useState([])

  return (
    <WrapperColumn>
      <Head>Фильтры</Head>
      <br />
      <FlexColumnStart style={{ paddingBottom: 32, width: '100%' }}>
        <FlexColumnStart style={{ gap: '32px 0', width: '100%' }}>
          <InputSwitchWithTitle
            subtitle={'Только товары с рейтингом 4 и 5 звезд'}
            switchOn={isHighRatingOnly}
            title={'Высокий рейтинг'}
            toggleSwitch={toggleHightRatingOnly}
          />
          <InputSwitchWithTitle
            subtitle={'От пользователей с премиум-подпиской'}
            switchOn={isPremiumOnly}
            title={'Премиум товары'}
            toggleSwitch={togglePremium}
          />
          <FilterByCategoriesSection
            handleFilterModalOpen={handleFilterModalOpen}
            isSearch={isSearch}
          />
          <FilterPricePanel title={'Цена за сутки'} />
          {/* <FilterButtonPanel
            buttons={termsMockData}
            selectedIds={termsId}
            setSelectedIds={setTermsId}
            title={'Срок аренды'}
          />
          <SelectColorComponent
            colorIds={colorIds}
            colors={colorsMockData}
            setColorIds={setColorIds}
          /> */}
          {/* <FilterButtonPanel
            buttons={sizesMockData}
            selectedIds={sizesId}
            setSelectedIds={setSizesId}
            title={'Размер'}
          />
          <FilterButtonPanel
            buttons={tagsMockData}
            selectedIds={tagsId}
            setSelectedIds={setTagsId}
            title={'Теги'}
          /> */}
          {/* <FilterSellerPanel
            buttons={sellersMockData}
            selectedIds={sellersId}
            setSelectedIds={setSellersId}
            title={'Рекомендуемые продавцы'}
          /> */}
        </FlexColumnStart>
      </FlexColumnStart>
    </WrapperColumn>
  )
}

export default observer(FilterWindowNew)
