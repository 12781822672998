import { observer } from 'mobx-react-lite'

import { EmailSignInForm } from '../features/auth/ui/EmailSignIn'
import { SignInTitle } from '../features/auth/ui/SignInTitle'
import { SigninFooter } from '../features/auth/ui/SigninFooter'
import { AuthLayout } from '../shared/ui-kit/actual/layouts/AuthLayout'

const AuthPage = () => {
  return (
    <AuthLayout
      header={
        <SignInTitle style={{ textAlign: 'center' }} title={'Добро пожаловать в Allsharing'} />
      }
      footer={<SigninFooter />}
      form={<EmailSignInForm />}
    />
  )
}

export default observer(AuthPage)
