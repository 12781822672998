import React from 'react'

import { SectionListItemDto } from '../../../../shared/api/generated'
import { SectionItem } from '../../../../shared/ui-kit/actual/SectionItem'
import { MarketTitleComponent } from '../../../../shared/ui-kit/actual/components/MarketTitleComponent'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumn,
  SectionsPageWrapper,
  StyledLinkPrimary,
} from '../../../../shared/ui-kit/projectComponents'

interface MarketSectionsProps {
  isLoading: boolean
  sections: any
}

export const MarketSections: React.FC<MarketSectionsProps> = ({ isLoading, sections }) => {
  return (
    <>
      <MarketTitleComponent link={'/sections'} title={'Разделы'} />
      <SectionsPageWrapper>
        {sections.map((section: SectionListItemDto) => (
          <SectionItem
            img={section.imageUrl}
            key={section.id}
            title={section.name}
            to={`/sections/${section.id}`}
          />
        ))}
        {!isLoading && (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
      <StyledLinkPrimary style={{ marginTop: '10px', width: 'min(80vw, 350px)' }} to={'/sections'}>
        Все разделы
      </StyledLinkPrimary>
    </>
  )
}
