import { FC, ReactNode } from 'react'

import { FlexRow, FlexRowGap } from '../projectComponents'
import { StyledCheckbox } from './StyledCheckbox'

export const CheckBox: FC<{
  checked: boolean
  label?: ReactNode | string
  setChecked: (e) => void
}> = ({ checked, label, setChecked }) => {
  return (
    <FlexRow style={{ alignItems: 'center', gap: '10px', justifyContent: 'start' }}>
      <StyledCheckbox checked={checked} onChange={() => setChecked(prev => !prev)} />
      {label && <label>{label}</label>}
    </FlexRow>
  )
}
