import { useSearchParams } from 'react-router-dom'

export const useQueryModal = (modalName: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const viewModal = searchParams.get(modalName) === 'true'
  const setViewModal = (value: boolean) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    if (value) {
      updatedSearchParams.set(modalName, 'true')
    } else {
      updatedSearchParams.set(modalName, 'false')
    }
    setSearchParams(updatedSearchParams.toString())
  }

  return { setViewModal, viewModal }
}
