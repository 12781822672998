import { useEffect, useMemo, useState } from 'react'

import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'

import {
  useGetCategoriesListQuery,
  useGetSubCategoriesListMutate,
} from '../../../../entities/categoriesList/queries'
import { useGetProductTagsList } from '../../../../entities/createProduct/queries'
import { useGetSectionsListQuery } from '../../../../entities/sectionList'
import { PostApiProductsCreateProductBody, UserAddressDto } from '../../../../shared/api/generated'
import { useModal } from '../../../../shared/hooks/useModal'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import { devices } from '../../../../shared/ui-kit/breakPoints'
import { StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { useCreateProductForm } from '../../model/use-add-form'
import FirstStep from '../FirstStep'
import { SecondStep } from '../SecondStep'
import { CategoryAndTagsSelect } from './CategoryAndTagsSelect'
import { DefectsItem } from './DefectsItem'
import { IntervalsItem } from './IntervalsItem'
import { ViewProductModal } from './ViewProductModal'

const Wrapper = styled.div`
  align-self: center;
  margin-top: 16px;

  width: clamp(300px, 100%, 800px);
  @media screen and (${devices.desktopMiddle}) {
    width: 100%;
    align-self: stretch;
  }
`

const StyledForm = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media screen and (${devices.desktopMiddle}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 48px;
    button {
      max-width: 100%;
    }
  }
`

export const StyledButtonSubmit = styled(StyledButton)`
  @media screen and (width < 920px) {
    align-self: center;
  }
`

interface AddItemContainerProps {
  currentStage: number
  handleNextStep: () => void
}

const mockIntervals: {
  key: number
  option: keyof PostApiProductsCreateProductBody
  price: string
  value: string
}[] = [
  { key: 0, option: 'DayPrice', price: '', value: '1 день' },
  { key: 1, option: 'ThreeDaysPrice', price: '', value: '3 дня' },
  { key: 2, option: 'WeekPrice', price: '', value: '7 дней' },
  { key: 3, option: 'MonthPrice', price: '', value: '30 дней' },
]
const initialDefects = [{ amount: '', defectName: '', id: nanoid() }]

export const AddItemFormContainer = ({ currentStage, handleNextStep }: AddItemContainerProps) => {
  const [disabledBtn, setDisabledBtn] = useState(true)
  const {
    clearErrors,
    createProductMutation,
    errors,
    getValues,
    handleSubmit,
    isCompressing,
    isValid,
    register,
    setError,
    setValue,
    submitAddress,
    trigger,
  } = useCreateProductForm()

  const [imageUrls, setImageUrls] = useState<string[]>([])
  const setNewProduct = e => {
    e.preventDefault()
    handleSubmit(e)
  }

  const { data: sectionData, isLoading: isSectionsLoading } = useGetSectionsListQuery({})
  const sections = useMemo(
    () =>
      sectionData?.sections ? sectionData.sections.map(it => ({ key: it.id, value: it.name })) : [],
    [sectionData]
  )
  const [sectionId, setSectionId] = useState<number>(0)
  const [selectedId, setSelectedId] = useState(0)
  const [selectedCategoryId, setSelectedCategoryId] = useState(0)
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0)

  const { data: categoriesData, isLoading: isCategoriesLoading } = useGetCategoriesListQuery({
    disableCategoryFilter: false,
    sectionId,
    take: 500,
  })
  const categories = useMemo(
    () =>
      categoriesData?.items?.length
        ? categoriesData?.items?.map(it => ({
            hasChildCategories: it.hasChildCategories,
            key: it.categoryId,
            value: it.categoryName,
          }))
        : [],
    [categoriesData]
  )

  // первичное значение, если пользователь не выбирает категорию, оставляет по умолчанию
  // useEffect(() => {
  //   if (categories?.length) {
  //     setValue('CategoryId', 0)
  //   }
  // }, [categories, setValue])
  // useEffect(() => {
  //   if (selectedCategoryId == 0 || sectionId == 0) {
  //     setValue('CategoryId', 0)
  //   }
  // }, [sectionId, setValue, selectedCategoryId])

  const [parentCategoryId, setParentCategoryId] = useState<number>()
  const {
    data: subCategoriesListData,
    mutate,
    reset,
  } = useGetSubCategoriesListMutate({
    disableCategoryFilter: false,
    parentCategoryId,
    take: 500,
  })
  const subCategories = useMemo(
    () =>
      subCategoriesListData?.items?.length
        ? subCategoriesListData.items.map(it => ({ key: it.categoryId, value: it.categoryName }))
        : [],
    [subCategoriesListData]
  )

  const categorySelected = () => {
    if (subCategories.length && selectedSubCategoryId) {
      return true
    }
    if (!subCategories.length) {
      return true
    }

    return false
  }

  // useEffect(() => {
  //   if (subCategories?.length) {
  //     setValue('CategoryId', subCategories[0].key)
  //   } else {
  //     setValue('CategoryId', 0)
  //   }
  // }, [subCategories, setValue])

  // useEffect(() => {
  //   sectionData?.sections[0]?.id && setSectionId(sectionData?.sections[0]?.id)
  // }, [sectionData?.sections])
  useEffect(() => {
    if (parentCategoryId) {
      mutate({})
    } else {
      reset()
    }
  }, [mutate, parentCategoryId, reset, setValue])
  useEffect(() => {
    if (categoriesData?.[0]?.hasChildCategories) {
      setParentCategoryId(categoriesData[0]?.categoryId)
    } else {
      setParentCategoryId(0)
    }
  }, [categoriesData])
  const [oneDayRequired, setOneDayRequired] = useState(true)
  const { data: tagsData, isLoading } = useGetProductTagsList({
    SectionId: sectionId,
    Skip: 0,
    Take: 100,
  })
  const tags = tagsData?.items
  const [selectedTags, setSelectedTags] = useState([])

  useEffect(() => {
    setValue('ProductTagsIds', selectedTags)
  }, [selectedTags, setValue])

  const [intervals, setIntervals] = useState(mockIntervals.slice(0, 1))
  const [selectedIndexes, setSelectedIndexes] = useState([])

  useEffect(() => {
    setSelectedIndexes(() => {
      return intervals.reduce((acc, val) => {
        return [...acc, val.key]
      }, [])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervals])

  const [defects, setDefects] = useState(initialDefects)
  const { setViewModal, viewModal } = useModal()
  const [selectedAddress, setSelectedAddress] = useState<UserAddressDto>()

  const [selectedCities, setSelectedCities] = useState<string[]>([])
  const [checkedAllRussia, setChecked] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState<null | number>(null)
  const [onlyPremium, setOnlyPremium] = useState(false)

  return (
    <Wrapper>
      <AbsoluteLoader
        isLoading={
          isCompressing ||
          createProductMutation?.isLoading ||
          isCategoriesLoading ||
          isSectionsLoading ||
          isLoading
        }
      />
      <StyledForm>
        {currentStage === 0 ? (
          <FirstStep
            CategoryAndTagsSelect={
              <CategoryAndTagsSelect
                categories={categories}
                parentCategoryId={parentCategoryId}
                sections={sections}
                selectedCategoryId={selectedCategoryId}
                selectedId={selectedId}
                selectedSubCategoryId={selectedSubCategoryId}
                selectedTags={selectedTags}
                setOneDayRequired={setOneDayRequired}
                setParentCategoryId={setParentCategoryId}
                setSectionId={setSectionId}
                setSelectedCategoryId={setSelectedCategoryId}
                setSelectedId={setSelectedId}
                setSelectedSubCategoryId={setSelectedSubCategoryId}
                setSelectedTags={setSelectedTags}
                setValue={setValue}
                subCategories={subCategories}
                tags={tags}
              />
            }
            DefectsItem={
              <DefectsItem defects={defects} setDefects={setDefects} setValue={setValue} />
            }
            IntervalsItem={
              <IntervalsItem
                clearErrors={clearErrors}
                errors={errors}
                getValues={getValues}
                intervals={intervals}
                mockIntervals={mockIntervals}
                oneDayRequired={oneDayRequired}
                register={register}
                selectedIndexes={selectedIndexes}
                setError={setError}
                setIntervals={setIntervals}
                setSelectedIndexes={setSelectedIndexes}
                setValue={setValue}
                trigger={trigger}
              />
            }
            categorySelected={categorySelected}
            createProductMutation={createProductMutation}
            errors={errors}
            getValues={getValues}
            handleNextStep={handleNextStep}
            imageUrls={imageUrls}
            isValid={isValid}
            onlyPremium={onlyPremium}
            register={register}
            setImageUrls={setImageUrls}
            setOnlyPremium={setOnlyPremium}
            setValue={setValue}
          />
        ) : (
          <section>
            <SecondStep
              checkedAllRussia={checkedAllRussia}
              errors={errors}
              handleNextStep={handleNextStep}
              selectedAddressId={selectedAddressId}
              selectedCities={selectedCities}
              setCheckedAllRussia={setChecked}
              setDisabledBtn={setDisabledBtn}
              setSelectedAddress={setSelectedAddress}
              setSelectedAddressId={setSelectedAddressId}
              setSelectedCities={setSelectedCities}
              setValue={setValue}
              submitAddress={submitAddress}
            />
            <StyledButtonSubmit
              disabled={
                !isValid ||
                !!Object.keys(errors).length ||
                createProductMutation.isLoading ||
                disabledBtn
              }
              onClick={() => setViewModal(true)}
              style={{ paddingInline: '96px', width: 'fit-content' }}
              variant={'primary'}
            >
              Опубликовать товар
            </StyledButtonSubmit>
          </section>
        )}
      </StyledForm>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        {viewModal && (
          <ViewProductModal
            getValues={getValues}
            imageUrls={imageUrls}
            selectedAddress={selectedAddress}
            setNewProduct={setNewProduct}
            setViewModal={setViewModal}
          />
        )}
      </BaseModal>
    </Wrapper>
  )
}
