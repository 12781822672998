import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { FlexRow } from '../projectComponents'

const PointsRow = styled(FlexRow)`
  width: 100%;
  margin-top: 16px;
  align-items: center;
  column-gap: clamp(0.5rem, 2vw, 3rem);
  margin-bottom: 10px;
`
const CustomStepper = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: ${props => (props.active ? colors.primary : colors.lightPrimary)};
`

type Props = {
  activeStep: number
  totalSteps: number
}
export const StepsRow = ({ activeStep, totalSteps }: Props) => {
  return (
    <>
      {activeStep < totalSteps ? (
        <PointsRow>
          {Array(totalSteps)
            .fill(0)
            .map((_, idx) => {
              return <CustomStepper active={idx <= activeStep} key={idx} />
            })}
        </PointsRow>
      ) : null}
    </>
  )
}
