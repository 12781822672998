import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useGetUserProfileQuery } from '../../../../entities/userProfile/queries'
import { colors } from '../../../../shared/constants/colors'
import { useModal } from '../../../../shared/hooks/useModal'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import {
  AvatarLarge,
  BtnRowWrapper,
  BtnWrapper,
  FlexColumn,
  RatingWrapper,
  RelativeWrapper,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import getAvgRating from '../../../../shared/utils/getAvgRating'
import { TagsModal } from '../../../profile/ui/tagsModal/TagsModal'
import { useRoleType } from '../../model/user-role-type'

const StyledHeader = styled.h3`
  max-width: 272px;
  color: ${colors.black};
  font-size: 21px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin: 0;
`

const SubHeader = styled(StyledHeader)`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`

const UserNameWrapper = styled(FlexColumn)`
  gap: 8px;
  margin-top: 32px;
  color: #fff;
  margin-bottom: 20px;
`

export const MessageProfileCard = (): React.JSX.Element => {
  const user = JSON.parse(localStorage.getItem('user'))
  const { data } = useGetUserProfileQuery(user?.UserId)

  const navigate = useNavigate()

  const sellerRating = useMemo(() => data?.sellerRating || 0, [data?.sellerRating])
  const buyerRating = useMemo(() => data?.buyerRating || 0, [data?.buyerRating])
  const avgRating = useMemo(
    () => getAvgRating(buyerRating, sellerRating),
    [buyerRating, sellerRating]
  )

  const { setViewModal, viewModal } = useModal()

  const handleNavigate = useCallback(() => navigate('/add'), [navigate])

  return (
    <>
      <FlexColumn style={{ minWidth: '290px' }}>
        <RelativeWrapper>
          <AvatarLarge src={data?.avatarLink || '/images/bottom-menu/profile-light.svg'} />
          <RatingWrapper onClick={() => setViewModal(true)} roleType={data?.accountType}>
            <img alt={''} src={'/images/interface/whiteStar.svg'} />
            <span>{avgRating?.toFixed(1)}</span>
          </RatingWrapper>
        </RelativeWrapper>
        <UserNameWrapper>
          <StyledHeader>{data?.fullName}</StyledHeader>
          <SubHeader>{data?.aboutItself}</SubHeader>
        </UserNameWrapper>
        <BtnWrapper>
          {/* <BtnRowWrapper>
            <StyledButton onClick={handleNavigate} fullwidth>
              Сдать в аренду
            </StyledButton>
          </BtnRowWrapper> */}
        </BtnWrapper>
      </FlexColumn>
      {/* <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        <TagsModal accountStatus={'danger'} setViewModal={setViewModal} />
      </BaseModal> */}
    </>
  )
}
