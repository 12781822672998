import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useCreateProductTagMutation,
  useDeleteProductTagMutation,
  useGetProductTagsList,
} from '../entities/createProduct/queries'
import { useSectionsGetSectionInfoQuery } from '../entities/sectionList/queries'
import { useModal } from '../shared/hooks/useModal'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { TextField } from '../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BaseModal } from '../shared/ui-kit/actual/modals/BaseModal'
import { MiniModal } from '../shared/ui-kit/actual/modals/MiniModal'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  BasePage,
  FlexColumn,
  FlexColumnBetween,
  FlexRowBetween,
  SectionsPageWrapper,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H1, H2 } from '../shared/ui-kit/titles'

export const AdminEditTagsPage: React.FC = () => {
  const params = useParams()
  const sectionId = params['sectionId']
  const { data: sectionData } = useSectionsGetSectionInfoQuery({ id: Number(sectionId) })
  const { data, isLoading } = useGetProductTagsList({
    SectionId: Number(sectionId),
    Take: 300,
  })
  const { isLoading: isCreateLoading, isSuccess, mutate } = useCreateProductTagMutation()
  const { mutate: deleteMutate } = useDeleteProductTagMutation()
  const { setViewModal, viewModal } = useModal()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<Number>()
  const openDeleteModal = (id: number) => {
    setDeleteOpen(true)
    setDeleteId(id)
  }

  const [name, setName] = useState('')
  const handleNameChange = (name: string) => setName(name)
  const openModal = () => {
    setViewModal(true)
    setName('')
  }
  const disabledBtn = !name
  const handleAddCategory = e => {
    e.preventDefault()
    mutate({ name: name, sectionId: Number(sectionId) })
  }
  const handleDeleteCategory = () => {
    deleteMutate({ tagId: Number(deleteId) })
    setDeleteOpen(false)
    setDeleteId(null)
  }

  useEffect(() => {
    isSuccess && setViewModal(false)
  }, [isSuccess, setViewModal])

  return (
    <BasePage>
      <MainHeader />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>

      <H1>
        Редактирование тегов в разделе <br />
        {sectionData?.name}
      </H1>
      <br />
      <StyledButton onClick={openModal} size={'xl'}>
        Добавить тег
      </StyledButton>
      <br />
      <SectionsPageWrapper>
        {!isLoading ? (
          data.items.map((category, idx) => (
            <FlexColumn key={idx}>
              <p>{category.name}</p>
              <br />
              <StyledButton
                onClick={() => openDeleteModal(category.id)}
                size={'sm'}
                variant={'warning'}
              >
                Удалить тег
              </StyledButton>
            </FlexColumn>
          ))
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        <AbsoluteLoader isLoading={isCreateLoading} />
        <form onSubmit={handleAddCategory}>
          <FlexColumnBetween style={{ alignItems: 'center' }}>
            <br />
            <TextField
              inputProps={{
                onChange: e => handleNameChange(e.target.value),
                placeholder: 'Название категории',
                type: 'text',
                value: name,
              }}
              label={'Введите название категории'}
              fullwidth
            />
            <br />
            <StyledButton disabled={disabledBtn}>Создать</StyledButton>
          </FlexColumnBetween>
        </form>
      </BaseModal>
      <MiniModal setViewModal={() => setDeleteOpen(false)} viewModal={deleteOpen}>
        <FlexColumn>
          <br />
          <H2>Вы уверены, что хотите удалить тег?</H2>
          <br />
          <FlexRowBetween>
            <StyledButton onClick={() => setDeleteOpen(false)} variant={'secondary'}>
              НЕТ
            </StyledButton>
            <StyledButton onClick={handleDeleteCategory} variant={'warning'}>
              ДА
            </StyledButton>
          </FlexRowBetween>
          <br />
        </FlexColumn>
      </MiniModal>
    </BasePage>
  )
}
