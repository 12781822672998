import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { ProfileHeader } from '../../../../shared/ui-kit/actual/ProfileHeader'
import { HeaderTitle } from '../../../../shared/ui-kit/projectComponents'

const HeaderBtn = styled.button`
  border: none;
  display: block;
  outline: none;
  background: none;
  cursor: pointer;
`

const BlogHeader = () => {
  return (
    <>
      <ProfileHeader
        left={
          <Link to={'/profile'}>
            <img alt={''} src={'/images/bottom-menu/profile-light.svg'} />
          </Link>
        }
        right={
          <HeaderBtn>
            <img alt={''} src={'/images/interface/sections.svg'} />
          </HeaderBtn>
        }
        center={<HeaderTitle>Блог</HeaderTitle>}
      />
    </>
  )
}

export default BlogHeader
