import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

const Header = styled.div`
  z-index: 10;
  width: calc(100vw - 48px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
`

const Logo = styled.img`
  width: 39px;
  height: 39px;
`
const PageTitle = styled.p`
  padding: 0;
  margin: 0;
  font-size: 18px;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 24px;
  left: 0;
  opacity: 0.5;
  font-weight: 600;
`

const HeaderComponent: React.FC = () => {
  const location = useLocation()
  const pageTitle =
    (location.pathname === '/profile' && 'Профиль') ||
    (location.pathname === '/blog' && 'Блог') ||
    (location.pathname === '/article' && 'Блог') ||
    ''
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Header>
      <Link to={'/'}>
        <Logo alt={''} src={'/images/allsharinglogo.svg'} />
      </Link>
      <PageTitle>{pageTitle}</PageTitle>
    </Header>
  )
}

export default observer(HeaderComponent)
