import React, { useContext, useState } from 'react'
import { useMutation } from 'react-query'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import DocumentService, {
  DOCUMENT_STATUS,
  DocumentStatus,
  IResponsePasport,
} from '../../../api/deprecated/documentService/DocumentService'
import { colors } from '../../../constants/colors'
import { LEGAL_STATUS_LIST } from '../../../constants/legalStatusList'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { FlexColumn, FlexRow, Modal, StyledButton } from '../../projectComponents'
import { PageLoader } from '../PageLoader'

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  img {
    width: 16px;
    height: 16px;
  }
`

const DataElement = styled(FlexColumn)`
  width: calc(100% - 30px);
  justify-content: start;
  align-items: start;
  margin: 5px;
`
const DataTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin: 8px 0 4px;
`
const DataInfo = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  text-align: left;
`
const BorderLine = styled.div`
  width: calc(100% - 32px);
  margin: 20px 0;
  border: 0.5px solid ${colors.lightGray};
`

const MainImage = styled.div`
  width: 100%;
  max-width: 600px;
  height: 375px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGrayBg};
  margin-top: 100px;
  @media (max-width: 700px) {
    width: 100%;
    height: 320px;
    max-width: calc(100% - 20px);
  }

  img {
    @media (min-width: 270px) {
      width: 100%;
      height: auto;
      max-height: 320px;
    }
    @media (min-width: 1100px) {
      width: 100%;
      height: auto;
      max-height: 375px;
    }
  }
`
const ControlWrapper = styled(FlexRow)`
  width: 100%;
  flex-wrap: wrap;
  align-items: start;
  height: 75px;
  margin: 10px;
  justify-content: start;
`

interface SemiImageButtonProps {
  clicked: boolean
}

const SemiImageButton = styled.div<SemiImageButtonProps>`
  cursor: pointer;
  margin: 2px;
  border: 2px solid ${props => (props.clicked ? colors.primary : '#fff')};
  height: 70px;
  width: 17%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGrayBg};

  @media (min-width: 1100px) {
    width: 100px;
    height: 100px;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 100px;
  }
`
const SemiImage = styled.img`
  width: 100%;
  height: 100%;
`

interface PassportModalProps {
  passport: IResponsePasport
  refetch: () => void
  setOpen: any
}

const PassportModal: React.FC<PassportModalProps> = ({ passport, refetch, setOpen }) => {
  const registerDate = format(new Date(passport?.registeredAt), 'dd MMMM HH:mm', { locale: ru })
  const legalStatusId = parseInt(passport.legalStatus.toString()) - 1

  const handleGetDocumentStatus = (status: DOCUMENT_STATUS) => {
    switch (status) {
      case DOCUMENT_STATUS.NotUploaded:
        return 'Не загружено'
      case DOCUMENT_STATUS.Pending:
        return 'На рассмотрениии'
      case DOCUMENT_STATUS.Approved:
        return 'Одобрено'
      case DOCUMENT_STATUS.Rejected:
        return 'Отклонено'
    }
  }

  const showProductInfo = [
    {
      info: registerDate,
      title: 'Дата регистрации',
    },
    {
      info: LEGAL_STATUS_LIST[legalStatusId],
      title: 'Легальный статус',
    },
    {
      info: passport.userName,
      title: 'Имя пользователя',
    },
    {
      info: passport.phone,
      title: 'Номер телефона',
    },
  ]
  const showProductInfo2 = [
    {
      info: passport.lastName,
      title: 'Фамилия',
    },
    {
      info: passport.firstName,
      title: 'Имя',
    },
    {
      info: passport.middleName,
      title: 'Отчество',
    },
    {
      info: passport.city,
      title: 'Город',
    },
  ]
  const showProductInfo3 = [
    {
      info: passport.passport.departmentCode,
      title: 'Код подразделения',
    },
    {
      info: passport.passport.issuedBy,
      title: 'Паспорт выдан',
    },
    {
      info: passport.passport.issuedAt,
      title: 'Дата выдачи',
    },
    {
      info: passport.passport.series,
      title: 'Серия паспорта',
    },
    {
      info: passport.passport.number,
      title: 'Номер паспорта',
    },
    {
      info: passport.passport.registrationAddress,
      title: 'Адрес регистрации',
    },
  ]
  const showProductInfo4 = [
    {
      info: passport.passport.id,
      title: 'ID заявки',
    },
    {
      info: passport.id,
      title: 'ID пользователя',
    },
    {
      info: handleGetDocumentStatus(passport.passport.status),
      title: 'Статус заявки',
    },
  ]
  const [selectedImg, setSelectedImg] = useState(0)
  const store = useContext(UserStorage)

  const [mutateStatus, setMutateStatus] = useState('BASE')
  const updateDocumentMutation = useMutation({
    mutationFn: (newDocumentStatus: DocumentStatus) => {
      return DocumentService.updateStatusPassport(
        passport.passport.id,
        newDocumentStatus,
        store.accessToken
      )
    },
    onError: error => {
      console.log('Bad status: ', error)
      setMutateStatus('BAD')
    },
    onSuccess: (data, variables, context) => {
      console.log('SUCCESS')
      setMutateStatus('GOOD')
    },
  })
  const CloseModal = () => {
    setOpen(false)
    if (mutateStatus === 'GOOD') {
      refetch()
    }
  }

  return (
    <Modal>
      {updateDocumentMutation.isLoading && (
        <FlexColumn style={{ width: '100%' }}>
          <PageLoader />
        </FlexColumn>
      )}
      {!updateDocumentMutation.isLoading && (mutateStatus === 'GOOD' || mutateStatus === 'BAD') && (
        <FlexColumn style={{ height: '100%', width: '100%' }}>
          <CloseButton onClick={CloseModal}>
            <img alt={''} src={'/images/buttons/close.png'} />
          </CloseButton>
          <p>{mutateStatus === 'GOOD' ? 'Данные успешно изменены' : 'Произошла ошибка'}</p>
        </FlexColumn>
      )}
      {mutateStatus === 'BASE' && !updateDocumentMutation.isLoading && (
        <FlexColumn style={{ width: '100%' }}>
          <CloseButton onClick={() => setOpen(false)}>
            <img alt={''} src={'/images/buttons/close.png'} />
          </CloseButton>
          <MainImage>
            <img alt={''} src={passport.passport.links[selectedImg]} />
          </MainImage>
          <ControlWrapper>
            {passport.passport.links.map((item, id) => (
              <SemiImageButton
                clicked={selectedImg === id}
                key={id}
                onClick={() => setSelectedImg(id)}
              >
                <SemiImage alt={''} src={item} />
              </SemiImageButton>
            ))}
          </ControlWrapper>
          <BorderLine />
          {showProductInfo.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />
          {showProductInfo2.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />
          {showProductInfo3.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />
          {showProductInfo4.map((ui, idx) => (
            <DataElement key={idx}>
              <DataTitle>{ui.title} </DataTitle>
              <DataInfo>{ui.info} </DataInfo>
            </DataElement>
          ))}
          <BorderLine />
          <FlexRow style={{ width: '100%' }}>
            <StyledButton
              onClick={() => {
                updateDocumentMutation.mutate(DocumentStatus.Approved)
              }}
              style={{ width: '50%' }}
            >
              Одобрить
            </StyledButton>
            <StyledButton
              onClick={() => {
                updateDocumentMutation.mutate(DocumentStatus.Rejected)
              }}
              style={{ background: colors.redFail, width: '50%' }}
            >
              Отклонить
            </StyledButton>
          </FlexRow>
        </FlexColumn>
      )}
    </Modal>
  )
}

export default PassportModal
