import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetMarketItemQuery } from '../entities/marketItem/queries'
import { useGetReviewsListQuery } from '../entities/reviews/queries'
import { useGetUserProfileMutation } from '../entities/userProfile/queries'
import { ItemComponent } from '../features/marketItem/ui/ItemComponents/ItemComponent'
import { MarketItemRates } from '../features/marketItem/ui/components/MarketItemRates'
import { GetUserProfileResponse, ReviewType } from '../shared/api/generated'
import { BottomMenuComponent } from '../shared/ui-kit/actual/BottomMenuComponent'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import { FlexColumn, LoaderWrapper } from '../shared/ui-kit/projectComponents'
import getAvgRating from '../shared/utils/getAvgRating'

export const MarketItemPage = () => {
  const params = useParams()
  const itemId = params['itemId']

  const [openRating, setOpenRating] = useState(false)
  const [openOwnerRating, setOpenOwnerRating] = useState(false)
  const { data: productInfo, isError, isLoading } = useGetMarketItemQuery({ productId: +itemId })
  const { data: productReviews } = useGetReviewsListQuery({
    productId: +itemId,
    reviewType: ReviewType.aboutProduct,
    skip: 0,
    take: 500,
  })

  // const { data: userReviews } = useGetReviewsListQuery({
  //   reviewType: ReviewType.aboutSeller,
  //   sellerId: productInfo?.owner?.id,
  //   skip: 0,
  //   take: 150,
  // })

  const [sellerInfo, setSellerInfo] = useState<GetUserProfileResponse>()
  const { mutate: addUserProfile } = useGetUserProfileMutation({
    onError: error => {
      console.log('profile error', error)
    },
    onSuccess: data => {
      setSellerInfo(data)
    },
  })

  useEffect(() => {
    productInfo?.owner?.id && addUserProfile({ userId: productInfo?.owner?.id })
  }, [addUserProfile, productInfo?.owner?.id])
  const navigate = useNavigate()

  useEffect(() => {
    isError && navigate(-1)
  }, [isError, navigate])

  const sellerRating = sellerInfo?.sellerRating || 0
  const buyerRating = sellerInfo?.buyerRating || 0
  const avgSellerRating = getAvgRating(buyerRating, sellerRating)

  return (
    <>
      {isLoading ? (
        <LoaderWrapper>
          <PageLoader />
        </LoaderWrapper>
      ) : (
        <FlexColumn style={{ width: '100%' }}>
          {openRating && (
            <MarketItemRates
              avgRate={productInfo?.rating}
              itemData={productReviews?.items}
              setOpenRating={setOpenRating}
            />
          )}
          {/* {openOwnerRating && (
            <MarketItemRates
              avgRate={avgSellerRating}
              itemData={productReviews?.items}
              setOpenRating={setOpenOwnerRating}
              isSeller
            />
          )} */}
          {!openRating && !openOwnerRating && productInfo && (
            <ItemComponent
              avgSellerRating={avgSellerRating}
              productInfo={productInfo}
              productReviews={productReviews}
              setOpenOwnerRating={setOpenOwnerRating}
              setOpenRating={setOpenRating}
            />
          )}
          <BottomMenuComponent />
        </FlexColumn>
      )}
    </>
  )
}
