import { useState } from 'react'
import { Link } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { useGetReviewsListQuery } from '../../entities/reviews/queries'
import Breadcrumbs from '../../features/market/ui/sections/BreadCrumbs'
import { useProfileInfo } from '../../features/profile/model/use-profile-info'
import { ProfileBodyDesktopWrapper } from '../../features/profile/ui/body/ProfileBodyDesktopWrapper'
import { ProfileBodyWrapper } from '../../features/profile/ui/body/ProfileBodyWrapper'
import { ProfileDesktopHeader } from '../../features/profile/ui/head/ProfileDesktopHeader'
import { ProfileGoodsHeader } from '../../features/profile/ui/head/ProfileGoodsHeader'
import { TagsModal } from '../../features/profile/ui/tagsModal/TagsModal'
import { useDisableBodyScroll } from '../../shared/hooks/use-disable-body-scroll'
import { useModal } from '../../shared/hooks/useModal'
import { ProfileHeader } from '../../shared/ui-kit/actual/ProfileHeader'
import { AbsoluteLoader } from '../../shared/ui-kit/actual/components/AbsoluteLoader'
import AddPaymentBalance from '../../shared/ui-kit/actual/components/AddPaymentBalance'
import PayoutBusinessModal from '../../shared/ui-kit/actual/components/PayoutBusinessModal'
import PayoutIndividualModal from '../../shared/ui-kit/actual/components/PayoutIndividualModal'
import { StyledButtonSettings } from '../../shared/ui-kit/actual/components/StyledButtonWithIcon'
import { MainHeader } from '../../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../../shared/ui-kit/actual/layouts/BasePageLayout'
import { ProfileLayout } from '../../shared/ui-kit/actual/layouts/ProfileLayout'
import { BaseModal } from '../../shared/ui-kit/actual/modals/BaseModal'
import { DesktopModal } from '../../shared/ui-kit/actual/modals/DesktopModal'
import { MiniModal } from '../../shared/ui-kit/actual/modals/MiniModal'
import { MobileModal } from '../../shared/ui-kit/actual/modals/MobileModal'
import { RightModal } from '../../shared/ui-kit/actual/modals/RightModal'
import { MainMenuWindow } from '../../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import {
  BaseWrapper,
  DesktopView,
  FlexRowBetween,
  ImgButton,
  LoginText,
  MobileTabletView,
} from '../../shared/ui-kit/projectComponents'
import { shortString } from '../../shared/utils/truncate'
import MyReviewsComponent from './components/MyReviewsComponent'
import UserReviewsComponentHeader from './components/UserReviewsComponentHeader'
import UserReviewsWithSwitchTabs from './components/UserReviewsWithSwitchTabs'

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { setViewModal, viewModal } = useModal()
  const { myProfile, userInfo } = useProfileInfo()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const { data: buyerReviews, isLoading: isLoadingBuyerReviews } = useGetReviewsListQuery({
    buyerId: userInfo.id,
    reviewType: 'aboutBuyer',
    skip: 0,
    take: 150,
  })
  const buyerReviewsItems = buyerReviews?.items

  const { data: sellerReviews, isLoading: isLoadingSellerReviews } = useGetReviewsListQuery({
    reviewType: 'aboutSeller',
    sellerId: userInfo.id,
    skip: 0,
    take: 150,
  })
  const sellerReviewsItems = sellerReviews?.items
  const [ratingModal, setRatingModal] = useState(false)
  const [balanceModal, setBalanceModal] = useState(false)
  const [payoutModal, setPayoutModal] = useState(false)

  useDisableBodyScroll(ratingModal || payoutModal)

  return (
    <>
      <AbsoluteLoader isLoading={isLoadingBuyerReviews || isLoadingSellerReviews} />
      {ratingModal && (
        <>
          <MobileModal setViewModal={setRatingModal} viewModal={ratingModal}>
            <MyReviewsComponent
              buyerRating={myProfile.buyerRating}
              buyerReviews={buyerReviewsItems}
              sellerRating={myProfile.sellerRating}
              sellerReviews={sellerReviewsItems}
            />
          </MobileModal>
          <DesktopModal setViewModal={setRatingModal} viewModal={ratingModal}>
            <ProfileLayout
              body={
                <UserReviewsWithSwitchTabs
                  buyerReviews={buyerReviewsItems}
                  sellerReviews={sellerReviewsItems}
                />
              }
              head={
                <UserReviewsComponentHeader
                  buyerRating={myProfile.buyerRating}
                  buyerReviews={buyerReviewsItems}
                  sellerRating={myProfile.sellerRating}
                  sellerReviews={sellerReviewsItems}
                />
              }
            />
          </DesktopModal>
        </>
      )}
      <MobileTabletView>
        <ProfileHeader
          left={
            <Link to={'personal-data'}>
              <StyledButtonSettings />
            </Link>
          }
          right={
            <ImgButton
              alt={''}
              onClick={() => setIsModalOpen(prev => !prev)}
              src={'/images/buttons/menu-button.svg'}
            />
          }
          center={<LoginText>@{shortString(userInfo?.accountName, 20)}</LoginText>}
        />
        <ProfileLayout
          body={
            <ProfileBodyWrapper
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setBalanceModal={setBalanceModal}
              setPayoutModal={setPayoutModal}
              setRatingModal={setRatingModal}
            />
          }
          head={<ProfileGoodsHeader activeTab={activeTab} setViewModal={setViewModal} />}
        />
        <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
          <MainMenuWindow handleModalClose={handleModalClose} />
        </RightModal>
      </MobileTabletView>

      <DesktopView>
        <BasePageLayout>
          <MainHeader />
          <BaseWrapper>
            <FlexRowBetween style={{ alignItems: 'center' }}>
              <Breadcrumbs />
              <Link to={'personal-data'}>
                <StyledButtonSettings />
              </Link>
            </FlexRowBetween>

            <ProfileLayout
              head={
                <ProfileDesktopHeader
                  setBalanceModal={setBalanceModal}
                  setPayoutModal={setPayoutModal}
                  setRatingModal={setRatingModal}
                  setViewModal={setViewModal}
                  isProfilePage
                />
              }
              body={<ProfileBodyDesktopWrapper />}
            />
          </BaseWrapper>
        </BasePageLayout>
      </DesktopView>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        <TagsModal accountType={userInfo.accountType} setViewModal={setViewModal} />
      </BaseModal>
      <MiniModal setViewModal={setBalanceModal} viewModal={balanceModal} minWidth>
        <AddPaymentBalance />
      </MiniModal>
      {(userInfo.legalStatus === 'individual' || userInfo.legalStatus === 'selfEmployed') && (
        <PayoutIndividualModal setViewModal={setPayoutModal} viewModal={payoutModal} />
      )}
      {(userInfo.legalStatus === 'soleProprietor' || userInfo.legalStatus === 'legalEntity') && (
        <PayoutBusinessModal setViewModal={setPayoutModal} viewModal={payoutModal} />
      )}
    </>
  )
}

export default observer(ProfilePage)
