import React from 'react'

import { useGetSectionsListQuery } from '../entities/sectionList'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { SectionItem } from '../shared/ui-kit/actual/SectionItem'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import { BasePage, FlexColumn, SectionsPageWrapper } from '../shared/ui-kit/projectComponents'
import { H1, H2, Head } from '../shared/ui-kit/titles'

export const AdminAddSectionPage: React.FC = () => {
  const { data, isLoading } = useGetSectionsListQuery({})

  return (
    <BasePage>
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      <Head>Добавление разделов</Head>
      <H2>Выберете раздел для добавления категории</H2>
      <br />
      <SectionsPageWrapper>
        {!isLoading ? (
          data.sections.map(section => (
            <SectionItem
              img={section.imageUrl}
              key={section.id}
              productCount={section.productsCount}
              title={section.name}
              to={`/admin/add-categories/${section.id}`}
            />
          ))
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
    </BasePage>
  )
}
