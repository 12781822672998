import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useAuthForgotPasswordMutation } from '../../../entities/passwordRestore/queries'
import { ForgotPasswordCommand } from '../../../shared/api/generated'
import { EMAIL_VALIDATION } from '../../../shared/constants/regex'
import {
  emailLabel,
  emailRequired,
  invalidEmail,
} from '../../../shared/constants/validationMessages'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { FlexColumnStart, StyledButton } from '../../../shared/ui-kit/projectComponents'

const Wrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 800px);
  margin: 0 auto;
`
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 24px;
  width: 100%;
`

export const PasswordForgot = () => {
  const navigate = useNavigate()
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<ForgotPasswordCommand>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })

  const { isLoading, isSuccess, mutate } = useAuthForgotPasswordMutation()
  const resetPassword = async (data: ForgotPasswordCommand) => {
    mutate(data)
  }

  useEffect(() => {
    isSuccess && navigate('/login')
  }, [isSuccess, navigate])

  return (
    <Wrapper>
      <FormWrapper onSubmit={handleSubmit(resetPassword)}>
        <TextField
          inputProps={{
            placeholder: 'Введите email',
            type: 'email',
            ...register('email', {
              pattern: {
                message: invalidEmail,
                value: EMAIL_VALIDATION,
              },
              required: emailRequired,
            }),
          }}
          error={errors.email?.message?.toString()}
          label={emailLabel}
          fullwidth
        />

        <StyledButton disabled={!isValid || isLoading} fullwidth>
          Отправить ссылку для восстановления пароля
        </StyledButton>
      </FormWrapper>
    </Wrapper>
  )
}
