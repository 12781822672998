import React from 'react'

import styled from 'styled-components/macro'

import { FlexColumnStart, FlexRow } from '../../projectComponents'

const WrapperColumn = styled(FlexColumnStart)`
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  justify-content: flex-start;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 100;
`

interface SortModalProps {
  handleMenu: () => void
  openMenu: boolean
  selectedType: string
  selectors: any
  setSelectedSort: (it: any) => void
}

const SortModal: React.FC<SortModalProps> = ({
  handleMenu,
  openMenu,
  selectedType,
  selectors,
  setSelectedSort,
}) => {
  if (openMenu) {
    return (
      <WrapperColumn>
        <FlexRow
          style={{
            alignItems: 'center',
            boxSizing: 'border-box',
            height: 'fit-content',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <p style={{ fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto' }}>Сортировка</p>
          <img
            alt={'close'}
            height={20}
            onClick={handleMenu}
            src={'/images/buttons/close.png'}
            style={{ cursor: 'pointer' }}
            width={20}
          />
        </FlexRow>
        <FlexColumnStart>
          <p style={{ fontWeight: '' }}>Сортировать по:</p>
          <FlexColumnStart>
            {selectors.map((it, idx) => (
              <FlexRow key={idx} style={{ alignItems: 'center', marginBottom: 10 }}>
                <div style={{ cursor: 'pointer', height: 30, marginRight: 10, width: 30 }}>
                  <input
                    onChange={() => {
                      setSelectedSort(it.type)
                    }}
                    checked={it.type === selectedType}
                    id={it.type}
                    name={it.type}
                    style={{ opacity: 0, position: 'absolute' }}
                    type={'radio'}
                    value={it.type}
                  />
                  <img
                    alt={'selector'}
                    height={30}
                    src={`/images/buttons/${selectedType === it.type ? 'active_radio.svg' : 'disabled_radio.svg'}`}
                    style={{}}
                    width={30}
                  />
                </div>
                <label htmlFor={it.value}>{it.title}</label>
              </FlexRow>
            ))}
          </FlexColumnStart>
        </FlexColumnStart>
      </WrapperColumn>
    )
  }
}

export default SortModal
