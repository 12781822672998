import { FC, ReactNode } from 'react'

import styled from 'styled-components/macro'

import { breakpoints, devices } from '../../breakPoints'
import { FlexColumn } from '../../projectComponents'
import { BottomMenuComponent } from '../BottomMenuComponent'

const Wrapper = styled(FlexColumn)`
  margin: 0 auto;
  width: clamp(${breakpoints.mobileMin}, 100%, ${breakpoints.desktopMiddle});
  box-sizing: border-box;
  @media screen and (${devices.desktopMin}) {
    align-items: flex-start;
  }
`

export const ProfileRentLayout: FC<{ body?: ReactNode; head?: ReactNode }> = ({ body, head }) => {
  return (
    <Wrapper>
      {head}
      {body}
      <BottomMenuComponent />
    </Wrapper>
  )
}
