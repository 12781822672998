import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { useGetTransactionsQuery } from '../entities/transactions/queries'
import { TransactionDto, TransactionType } from '../shared/api/generated'
import { transactionsStatuses, transactionsTypes } from '../shared/constants/transactions'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  FlexColumnBetween,
  FlexRowBetween,
  FlexRowGap,
  StyledButton,
  Table,
  Th,
} from '../shared/ui-kit/projectComponents'
import { Head } from '../shared/ui-kit/titles'
import { shortString, truncateString } from '../shared/utils/truncate'

const TransactionsList = ({
  setUserId,
  transactions,
}: {
  setUserId: React.Dispatch<React.SetStateAction<string>>
  transactions: TransactionDto[]
}) => (
  <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}>
    {' '}
    <Table>
      <thead>
        <tr>
          <Th>Дата</Th>
          <Th style={{ maxWidth: '200px' }}>Пользователь</Th>
          <Th>Сумма</Th>
          <Th>Тип</Th>
          <Th>Статус</Th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(t => (
          <tr key={t.id}>
            <td>{format(new Date(t.createdAt), 'dd MMMM HH:mm', { locale: ru })}</td>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '150px' }}
                to={`/users/${t.owner.id}`}
              >
                {shortString(t.owner.userName, 18)}
              </Link>{' '}
              <Link
                style={{ display: 'inline-block', minWidth: '100px' }}
                to={`/admin/users/${t.owner.id}`}
              >
                {truncateString(t.owner.id, 8)}
              </Link>
              <button onClick={() => setUserId(t.owner.id)}>только этот пользователь</button>
            </td>
            <td>{t.amount}</td>
            <td>{transactionsTypes[t.type]}</td>
            <td>{transactionsStatuses[t.status] || t.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </FlexRowBetween>
)

export const AdminTransactionsPage = () => {
  const [userId, setUserId] = useState<null | string>(null)
  const [from, setFrom] = useState<string>()
  const [to, setTo] = useState<string>()
  const [type, setType] = useState<TransactionType | null>()
  const [itemsPerPage, setItemsPerPage] = useState(24)
  const [page, setPage] = useState(0)
  const { data, isLoading } = useGetTransactionsQuery({
    From: from ? new Date(from).toISOString() : null,
    Skip: page * itemsPerPage,
    Take: itemsPerPage,
    To: to ? new Date(to).toISOString() : null,
    Type: type,
    UserId: userId,
  })
  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])
  useEffect(() => {
    setPage(0)
  }, [from, to, userId, type])

  const resetTerms = () => {
    setUserId(null)
    setFrom('')
    setTo('')
    setType(null)
  }

  return (
    <FlexColumnBetween>
      <AbsoluteLoader isLoading={isLoading} />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      <Head>Транзакции</Head>

      <FlexRowBetween
        style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}
      >
        <p style={{ alignSelf: 'flex-start', fontSize: 15, marginLeft: '15px' }}>
          Всего транзакций: {data?.totalCount}
        </p>

        <StyledButton onClick={resetTerms} size={'sm'}>
          Сбросить фильтр
        </StyledButton>
      </FlexRowBetween>
      <FlexRowGap>
        <div>
          Период от:&nbsp;
          <input onChange={e => setFrom(e.target.value)} type={'date'} value={from} />
        </div>
        <div>
          Период до:&nbsp;
          <input onChange={e => setTo(e.target.value)} type={'date'} value={to} />
        </div>
        <div>
          Тип транзакции:&nbsp;
          <select onChange={e => setType(e.target.value as TransactionType)} value={type}>
            <option value={''}>Выбрать</option>
            <option value={'payment'}>Пополнение</option>
            <option value={'payout'}>Вывод</option>
          </select>
        </div>
      </FlexRowGap>

      {data && data.items?.length && (
        <TransactionsList setUserId={setUserId} transactions={data.items} />
      )}
      {data && <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />}
    </FlexColumnBetween>
  )
}
