import { ComponentPropsWithoutRef, ReactNode, useEffect, useState } from 'react'

import styled from 'styled-components/macro'

type BubbleContainerProps = {
  $show: boolean
}
const BubbleContainer = styled.div<BubbleContainerProps>`
  position: fixed;
  right: 24px;
  bottom: 96px;
  transform: ${({ $show }) => ($show ? 'translateY(0)' : 'translateY(300px)')};
  transition: 0.2s cubic-bezier(0.87, 0, 0.13, 1);
`

type BubbleButtonContainerProps = {
  children?: ReactNode
}

export const BubbleButtonContainer = ({
  children,
  ...wrapperProps
}: BubbleButtonContainerProps & ComponentPropsWithoutRef<'div'>) => {
  const [show, setShow] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(100)

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShow(false)
    } else if (!show) {
      setShow(true)
    }

    setLastScrollY(window.scrollY)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.addEventListener('scroll', controlNavbar)
    }, 200)

    return () => {
      window.removeEventListener('scroll', controlNavbar)
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY])

  return (
    <BubbleContainer {...wrapperProps} $show={show}>
      {children}
    </BubbleContainer>
  )
}
