import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useUsersGetUserInfoQuery } from '../entities/ownProfile/queries'
import {
  useGetOperationsListQuery,
  useGetTransactionsQuery,
} from '../entities/transactions/queries'
import { useGetUserProfileQuery } from '../entities/userProfile/queries'
import { DeleteUserModal } from '../features/admin/ui/DeleteUserModal'
import { SetRolesModal } from '../features/admin/ui/SetRolesModal'
import { UserBalanceModal } from '../features/admin/ui/UserBalanceModal'
import { OperationsList } from '../features/profilePersonalData/ui/PersonalDataOperations'
import { TransactionsList } from '../features/profilePersonalData/ui/PersonalDataTransactions'
import { colors } from '../shared/constants/colors'
import { symbols } from '../shared/constants/symbols'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { Spacer } from '../shared/ui-kit/deprecated/Spacer'
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  MediumText,
  Modal,
  StyledButton,
  StyledLink,
} from '../shared/ui-kit/projectComponents'
import { H3 } from '../shared/ui-kit/titles'

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  img {
    width: 16px;
    height: 16px;
  }
`

const UserAvatar = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 1px solid ${colors.lightGray};
  object-fit: cover;
`
const DataElement = styled(FlexRow)`
  justify-content: start;
  margin: 5px;
`
const DataTitle = styled.p`
  font-size: 13px;
  margin: 0 18px 0 0;
  font-weight: 600;
  text-transform: uppercase;
`
const DataInfo = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  text-align: left;
`
const BorderLine = styled.div`
  width: calc(100% - 32px);
  margin: 20px 0;
  border: 0.5px solid ${colors.lightGray};
`
const DataComponent = ({ ui }: { ui: { info: string; title: string } }) => (
  <DataElement>
    <DataTitle>{ui.title} </DataTitle>
    <DataInfo>{ui.info} </DataInfo>
  </DataElement>
)

const AdminUserPage = () => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const {
    data: userProfile,
    isLoading: isProfileLoading,
    refetch: refetchProfile,
  } = useGetUserProfileQuery(userId)
  const { data: userInfo, isLoading: isInfoLoading, refetch } = useUsersGetUserInfoQuery(userId)

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [rolesOpen, setRolesOpen] = useState(false)
  const [paymentsOpen, setPaymentsOpen] = useState(false)

  const showedUserInfo = [
    {
      info: userProfile?.accountName,
      title: 'Юзернейм',
    },
    {
      info: userProfile?.fullName,
      title: 'ФИО',
    },
    // {
    //   info: userProfile?.city,
    //   title: 'Город',
    // },
    // {
    //   info: LEGAL_STATUS_LIST[legalStatusId],
    //   title: 'Статус',
    // },
    // {
    //   info: passportStatus,
    //   title: 'Паспорт',
    // },
  ]
  const showedUserInfo2 = [
    {
      info: `${userProfile?.buyerRating} / 5`,
      title: 'Рейтинг клиента',
    },
    // {
    //   info: userProfile?.rating.clientReviewsCount,
    //   title: 'Отзывов',
    // },
    {
      info: `${userProfile?.sellerRating} / 5`,
      title: 'Рейтинг арендодателя',
    },
    // {
    //   info: userProfile?.rating.lessorReviewsCount,
    //   title: 'Отзывов',
    // },
  ]
  const showedUserInfo3 = [
    {
      info: `${userProfile?.balanceUser.accountBalance} ${symbols.ruble}`,
      title: 'Баланс',
    },
    {
      info: `${userProfile?.balanceUser.bonusBalance} SHC`,
      title: 'Бонусный Баланс',
    },
  ]
  const showedUserInfo4 = [
    {
      info: userProfile?.id,
      title: 'ID',
    },
    // {
    //   info: userProfile?.canAddProducts ? 'Нет' : 'Да',
    //   title: 'Блокировка',
    // },
  ]
  const showedUserInfo5 = [
    {
      info: userInfo?.roles?.map(role => role.type).join(', '),
      title: 'Роли пользователя',
    },
    // {
    //   info: userProfile?.canAddProducts ? 'Нет' : 'Да',
    //   title: 'Блокировка',
    // },
  ]

  const isLoading = isInfoLoading || isProfileLoading

  const { data: transactionsData, isLoading: isTransactionsLoading } = useGetTransactionsQuery({
    Take: 3000,
    UserId: userId,
  })
  const { data: operationsData, isLoading: isOperationsLoading } = useGetOperationsListQuery({
    take: 3000,
    userId,
  })

  return (
    <>
      <AbsoluteLoader isLoading={isLoading || isTransactionsLoading} />
      <Modal>
        <FlexColumn style={{ width: '100%' }}>
          <CloseButton onClick={() => navigate(-1)}>
            <img alt={''} src={'/images/buttons/close.png'} />
          </CloseButton>
          <Spacer />
          <FlexColumnStart>
            <Spacer />
            <UserAvatar
              alt={''}
              src={userProfile?.avatarLink || '/images/buttons/user-avatar.png'}
            />
            <BorderLine />
            {showedUserInfo.map((ui, idx) => (
              <DataComponent key={idx} ui={ui} />
            ))}
            <BorderLine />
            {showedUserInfo2.map((ui, idx) => (
              <DataComponent key={idx} ui={ui} />
            ))}
            <BorderLine />
            {showedUserInfo3.map((ui, idx) => (
              <DataComponent key={idx} ui={ui} />
            ))}
            <BorderLine />
            {showedUserInfo5.map((ui, idx) => (
              <DataComponent key={idx} ui={ui} />
            ))}
            <BorderLine />
            {showedUserInfo4.map((ui, idx) => (
              <DataComponent key={idx} ui={ui} />
            ))}
          </FlexColumnStart>
          <BorderLine />
          <FlexRow style={{ gap: 16, width: '100%' }}>
            <StyledButton
              onClick={() => setDeleteOpen(true)}
              style={{ background: colors.redFail, width: '100%' }}
            >
              Удалить пользователя
            </StyledButton>
            <StyledButton onClick={() => setPaymentsOpen(true)} style={{ width: '100%' }}>
              Изменить баланс
            </StyledButton>
            <StyledButton
              onClick={() => setRolesOpen(true)}
              style={{ width: '100%' }}
              variant={'outline'}
            >
              Изменить роли
            </StyledButton>
            <StyledButton
              disabled={!userInfo?.passport}
              onClick={() => navigate(`/admin/passports/${userId}`)}
            >
              Паспорт
            </StyledButton>
          </FlexRow>
          <Spacer />
        </FlexColumn>
        <br />
        {transactionsData?.items?.length > 0 && (
          <>
            <H3>Транзакции</H3>
            <TransactionsList transactions={transactionsData?.items} />
          </>
        )}{' '}
        {transactionsData?.items?.length == 0 && (
          <MediumText>У пользователя еще нет транзакций</MediumText>
        )}
        <br />
        {operationsData?.items?.length > 0 && (
          <>
            <H3>Операции</H3>
            <OperationsList operations={operationsData?.items} />
          </>
        )}{' '}
        {operationsData?.items?.length == 0 && (
          <MediumText>У пользователя еще нет операций</MediumText>
        )}
      </Modal>
      <DeleteUserModal
        deleteOpen={deleteOpen}
        navigate={navigate}
        setDeleteOpen={setDeleteOpen}
        userId={userId}
      />
      <SetRolesModal
        refetch={refetch}
        rolesOpen={rolesOpen}
        setRolesOpen={setRolesOpen}
        userId={userId}
      />
      <UserBalanceModal
        paymentsOpen={paymentsOpen}
        refetch={refetchProfile}
        setPaymentsOpen={setPaymentsOpen}
        userId={userId}
      />
    </>
  )
}

export default AdminUserPage
