import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components/macro'

import { ROUTES_TRANSLATE, RoutesTranslate } from '../../constants/routesTranslate'
import { usePathnames } from '../../hooks/usePathnames'
import { breakpoints, mobileView } from '../breakPoints'
import { FlexRow, FlexRowBetween } from '../projectComponents'
import { H1 } from '../titles'
import { ArrowBackButton, ArrowBackButtonCommand } from './ArrowBackButton'

type BOTTOM_SPACE_VARIANT = 'md' | 'none' | 'sm' | 'xl'

interface BlockProps {
  bottomSpace?: BOTTOM_SPACE_VARIANT
}

const Block = styled(FlexRowBetween)<BlockProps>`
  align-items: center;
  margin: 24px 0 12px;
  ${mobileView}/* margin-bottom: ${({ bottomSpace }) =>
    (bottomSpace === 'xl' && '32px') ||
    (bottomSpace === 'md' && '24px') ||
    (bottomSpace === 'none' && '0') ||
    '16px'};

    p {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      opacity: 0.5;
    } */
`

interface BackArrowWithTitleLinkProps {
  bottomSpace?: BOTTOM_SPACE_VARIANT
  link?: string
  rightEl?: ReactNode
  title?: string
}
interface BackArrowWithTitleCommandProps {
  bottomSpace?: BOTTOM_SPACE_VARIANT
  onClick?: () => void
  rightEl?: ReactNode
  title?: string
}

export const BackArrowWithTitleLink = ({ link, rightEl, title }: BackArrowWithTitleLinkProps) => {
  const { titleFromLocation } = usePathnames()

  return (
    <Block>
      <ArrowBackButton link={link} />
      <FlexRow style={{ flexGrow: 1 }}>
        <H1>{title || titleFromLocation}</H1>
      </FlexRow>
      {rightEl}
    </Block>
  )
}
export const BackArrowWithTitleCommand = ({
  onClick,
  rightEl,
  title,
}: BackArrowWithTitleCommandProps) => {
  const { titleFromLocation } = usePathnames()

  return (
    <Block>
      <ArrowBackButtonCommand onClick={onClick} />
      <FlexRow style={{ flexGrow: 1 }}>
        <H1>{title || titleFromLocation}</H1>
      </FlexRow>
      {rightEl}
    </Block>
  )
}
