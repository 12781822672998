import React from 'react'

import styled from 'styled-components/macro'

import { ReviewListItemDto } from '../../../shared/api/generated'
import { ItemRates } from '../../../shared/ui-kit/actual/components/ItemRates'
const Wrapper = styled.div`
  margin-left: 36px;
  width: 100%;
`

interface ItemRatesProps {
  reviews: ReviewListItemDto[]
}

export const UserReviewHead: React.FC<ItemRatesProps> = ({ reviews }) => {
  return (
    <Wrapper>
      <ItemRates reviews={reviews} />
    </Wrapper>
  )
}
