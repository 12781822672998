import { useContext, useEffect, useMemo, useState } from 'react'

import { useGetChatsListQuery } from '../../../entities/chat'
import { queryClient } from '../../../shared/api/query-client'
import UserStorage from '../../../shared/storage/UserStorage/UserStorage'

export function useGetMessageList() {
  const { user } = useContext(UserStorage)
  const userId = useMemo(() => user.UserId, [user])

  const [take, setTake] = useState(120)

  useEffect(() => {
    setTake(120)
    queryClient.invalidateQueries({ queryKey: ['get_chats_list'] })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient])

  const { data, isError, isLoading, isSuccess } = useGetChatsListQuery({
    Take: take,
    UserId: userId,
  })

  return useMemo(
    () => ({ data, isError, isLoading, isSuccess, userId }),
    [data, isError, isLoading, isSuccess, userId]
  )
}
