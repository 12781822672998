import { useEffect, useState } from 'react'

import { format, isSameMonth } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'

const useDateRangeFormat = (fromDateString: string, toDateString: string): string => {
  const [dateRange, setDateRange] = useState<string>('')

  useEffect(() => {
    if (
      fromDateString &&
      toDateString &&
      /^\d{4}-\d{2}-\d{2}$/.test(fromDateString) &&
      /^\d{4}-\d{2}-\d{2}$/.test(toDateString)
    ) {
      const fromDate = new Date(fromDateString)
      const toDate = new Date(toDateString)

      const startDay = fromDate.getDate()
      const endDay = toDate.getDate()
      const startMonth = format(fromDate, 'MMMM', { locale: ruLocale }).toLowerCase()
      const endMonth = format(toDate, 'MMMM', { locale: ruLocale }).toLowerCase()

      const formattedDate = isSameMonth(fromDate, toDate)
        ? `${startDay}-${endDay} ${startMonth}`
        : `${startDay} ${startMonth}-${endDay} ${endMonth}`

      setDateRange(formattedDate)
    } else {
      setDateRange('Invalid Date Format')
    }
  }, [fromDateString, toDateString])

  return dateRange
}

export default useDateRangeFormat
