import { useLocation } from 'react-router-dom'

export const useShare = ({ shareFn, text, title }) => {
  const { pathname } = useLocation()

  const shareData = {
    text,
    title,
    url: `https://allsharing.su${pathname}`,
  }

  const share = async () => {
    try {
      await navigator.share(shareData).then(() => {
        shareFn && shareFn()
      })
    } catch (err) {
      console.error('Не удалось поделиться ссылкой')
    }
  }

  return { share }
}
