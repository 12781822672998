import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { MainLogoLink } from '../../../../shared/ui-kit/actual/MainLogoLink'
import { ProfileHeader } from '../../../../shared/ui-kit/actual/ProfileHeader'
import { FlexColumn, LoginText, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { shortString } from '../../../../shared/utils/truncate'
import { useProfileInfo } from '../../../profile/model/use-profile-info'

const StyledButtonWithIcon = () => (
  <StyledButton variant={'neutral'} isIcoBtn>
    <img alt={''} src={'/images/buttons/Setting.svg'} />
  </StyledButton>
)
const Wrapper = styled(FlexColumn)`
  position: absolute;
  top: 0;
  width: 100vw;
`

export const ProfileRentHeader = () => {
  const { userInfo } = useProfileInfo()

  return (
    <Wrapper>
      <ProfileHeader
        center={<LoginText>@{shortString(userInfo?.accountName, 20)}</LoginText>}
        left={<MainLogoLink />}
        right={<StyledButtonWithIcon />}
      />
    </Wrapper>
  )
}
