import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useCategoriesCreateCategoryMutation,
  useCategoriesDeleteCategoryMutation,
  useCategoriesUpdateCategoryImageMutation,
} from '../entities/admin/queries'
import { useGetCategoriesListQuery } from '../entities/categoriesList'
import { useSectionsGetSectionInfoQuery } from '../entities/sectionList/queries'
import { useModal } from '../shared/hooks/useModal'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { InputImageControlled } from '../shared/ui-kit/actual/InputImageControlled'
import { TextField } from '../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { CategoryButtonAdmin } from '../shared/ui-kit/actual/components/CategoryButtonAdmin'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BaseModal } from '../shared/ui-kit/actual/modals/BaseModal'
import { MiniModal } from '../shared/ui-kit/actual/modals/MiniModal'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  BasePage,
  FlexColumn,
  FlexColumnBetween,
  FlexRowBetween,
  SectionsPageWrapper,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H1, H2 } from '../shared/ui-kit/titles'

export const AdminAddCategoryPage: React.FC = () => {
  const params = useParams()
  const sectionId = params['sectionId']
  const { data: sectionData } = useSectionsGetSectionInfoQuery({ id: Number(sectionId) })
  const { data, isLoading } = useGetCategoriesListQuery({
    sectionId: Number(sectionId),
    take: 300,
  })
  const { isLoading: isCreateLoading, isSuccess, mutate } = useCategoriesCreateCategoryMutation()
  const { isLoading: isUpdateLoading, mutate: updateMutate } =
    useCategoriesUpdateCategoryImageMutation()
  const { mutate: deleteMutate } = useCategoriesDeleteCategoryMutation()
  const { setViewModal, viewModal } = useModal()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<Number>()
  const openDeleteModal = (id: number) => {
    setDeleteOpen(true)
    setDeleteId(id)
  }
  const [updateOpen, setUpdateOpen] = useState(false)
  const [updateId, setUpdateId] = useState<Number>()

  const [name, setName] = useState('')
  const handleNameChange = (name: string) => setName(name)
  const [imageUrl, setImageUrl] = useState('')
  const handleUrlChange = (url: string) => setImageUrl(url)
  const [imageFile, setImageFile] = useState<File>()
  const handleFileChange = (file: File) => setImageFile(file)
  const openModal = () => {
    setViewModal(true)
    setImageFile(null)
    setImageUrl('')
    setName('')
  }
  const openUpdateModal = (id: number, name: string) => {
    setUpdateOpen(true)
    setUpdateId(id)
    setImageFile(null)
    setImageUrl('')
    setName(name)
  }
  const disabledBtn = !name || !imageFile
  const handleAddCategory = e => {
    e.preventDefault()
    mutate({ Image: imageFile, Name: name, SectionId: Number(sectionId) })
  }
  const handleDeleteCategory = () => {
    deleteMutate({ id: Number(deleteId) })
    setDeleteOpen(false)
    setDeleteId(null)
  }
  const handleUpdateCategory = e => {
    e.preventDefault()
    updateMutate({ categoryId: Number(updateId), image: imageFile })
    setUpdateOpen(false)
    setName('')
  }

  useEffect(() => {
    isSuccess && setViewModal(false)
  }, [isSuccess, setViewModal])

  return (
    <BasePage>
      <MainHeader />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>

      <H1>
        Создание категорий в разделе <br />
        {sectionData?.name}
      </H1>
      <br />
      <StyledButton onClick={openModal} size={'xl'}>
        Создать категорию
      </StyledButton>
      <br />
      <SectionsPageWrapper>
        {!isLoading ? (
          data.items.map((category, idx) => (
            <FlexColumn key={idx}>
              <CategoryButtonAdmin
                id={category.categoryId}
                image={category.imageUrl}
                name={category.categoryName}
              />
              <br />
              <StyledButton
                onClick={() => openUpdateModal(category.categoryId, category.categoryName)}
                size={'sm'}
                variant={'secondary'}
              >
                Обновить изображение
              </StyledButton>
              <br />
              <StyledButton
                onClick={() => openDeleteModal(category.categoryId)}
                size={'sm'}
                variant={'warning'}
              >
                Удалить категорию
              </StyledButton>
            </FlexColumn>
          ))
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        <AbsoluteLoader isLoading={isCreateLoading} />
        <form onSubmit={handleAddCategory}>
          <FlexColumnBetween style={{ alignItems: 'center' }}>
            <br />
            <InputImageControlled
              handleChange={handleFileChange}
              handleUrlChange={handleUrlChange}
              parentImageUrl={isLoading ? null : imageUrl}
              variant={'square'}
            />
            <br />
            <TextField
              inputProps={{
                onChange: e => handleNameChange(e.target.value),
                placeholder: 'Название категории',
                type: 'text',
                value: name,
              }}
              label={'Введите название категории'}
              fullwidth
            />
            <br />
            <StyledButton disabled={disabledBtn}>Создать</StyledButton>
          </FlexColumnBetween>
        </form>
      </BaseModal>
      <BaseModal setViewModal={() => setUpdateOpen(false)} viewModal={updateOpen}>
        <AbsoluteLoader isLoading={isUpdateLoading} />
        <form onSubmit={handleUpdateCategory}>
          <FlexColumnBetween style={{ alignItems: 'center' }}>
            <br />
            <H2>{name}</H2>
            <br />
            <InputImageControlled
              handleChange={handleFileChange}
              handleUrlChange={handleUrlChange}
              parentImageUrl={isLoading ? null : imageUrl}
              variant={'square'}
            />

            <br />
            <StyledButton disabled={!imageFile || isUpdateLoading}>обновить</StyledButton>
          </FlexColumnBetween>
        </form>
      </BaseModal>
      <MiniModal setViewModal={() => setDeleteOpen(false)} viewModal={deleteOpen}>
        <FlexColumn>
          <br />
          <H2>Вы уверены, что хотите удалить категорию?</H2>
          <br />
          <FlexRowBetween>
            <StyledButton onClick={() => setDeleteOpen(false)} variant={'secondary'}>
              НЕТ
            </StyledButton>
            <StyledButton onClick={handleDeleteCategory} variant={'warning'}>
              ДА
            </StyledButton>
          </FlexRowBetween>
          <br />
        </FlexColumn>
      </MiniModal>
    </BasePage>
  )
}
