import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useGetPassportInfoQuery } from '../entities/documents/queries'
import {
  VerifiedSign,
  VerifiedText,
  verifiedImages,
  verifiedTexts,
} from '../features/profilePersonalData/ui/PersonalDataPassport'
import { colors } from '../shared/constants/colors'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { SliderComponent } from '../shared/ui-kit/actual/Slider'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { AdminPassportRejectModal } from '../shared/ui-kit/actual/components/AdminPassportRejectModal'
import { AdminPassportVerifyModal } from '../shared/ui-kit/actual/components/AdminPassportVerifyModal'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { Spacer } from '../shared/ui-kit/deprecated/Spacer'
import { BasePage, FlexColumn, FlexRowGap, StyledButton } from '../shared/ui-kit/projectComponents'
import { H1 } from '../shared/ui-kit/titles'
import { dateToString } from '../shared/utils/dateToString'

const DataElement = styled(FlexColumn)`
  width: calc(100% - 30px);
  justify-content: start;
  align-items: start;
  margin: 5px;
`
const DataTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin: 8px 0 4px;
`
const DataInfo = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  text-align: left;
`
const BorderLine = styled.div`
  width: calc(100% - 32px);
  margin: 20px 0;
  border: 0.5px solid ${colors.lightGray};
`

const AdminPassportPage = () => {
  const params = useParams()
  const userId = params['userId']

  console.log(userId)
  const { data, isError, isLoading } = useGetPassportInfoQuery({ userId })
  const navigate = useNavigate()

  useEffect(() => {
    isError && navigate(-1)
  }, [isError, navigate])
  const [rejectModal, setRejectModal] = useState(false)
  const [verifyModal, setVerifyModal] = useState(false)

  return (
    <BasePage>
      <AbsoluteLoader isLoading={isLoading} />
      <MainHeader />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      <H1>
        Паспорт <Link to={`/admin/users/${data?.ownerId}`}>пользователя</Link>
      </H1>
      <br />
      {data && (
        <>
          <SliderComponent
            items={[
              <img
                alt={'product'}
                height={425}
                key={99}
                src={data?.mainImageUrl}
                style={{ objectFit: 'contain' }}
                width={'100%'}
              />,
              ...data.otherImagesUrls.map((image, index) => (
                <img
                  alt={'product'}
                  height={425}
                  key={index}
                  src={image}
                  style={{ objectFit: 'contain' }}
                  width={'100%'}
                />
              )),
            ]}
            slidesToShow={1}
            dots
          />

          <BorderLine />

          <DataElement>
            <DataTitle>ФИО</DataTitle>
            <DataInfo>{data.fullName} </DataInfo>
          </DataElement>
          <BorderLine />
          <DataElement>
            <DataTitle>Дата рождения</DataTitle>
            <DataInfo>{dateToString(data.birthDate)} </DataInfo>
          </DataElement>
          <BorderLine />
          <DataElement>
            <DataTitle>Серия и номер</DataTitle>
            <DataInfo>
              {data.series} {data.number}
            </DataInfo>
          </DataElement>
          <BorderLine />
          <DataElement>
            <DataTitle>Выдан</DataTitle>
            <DataInfo>{data.issuedBy} </DataInfo>
          </DataElement>
          <BorderLine />
          <DataElement>
            <DataTitle>Код подразделения</DataTitle>
            <DataInfo>{data.subdivisionCode} </DataInfo>
          </DataElement>
          <BorderLine />
          <DataElement>
            <DataTitle>Дата выдачи</DataTitle>
            <DataInfo>{dateToString(data.issuedOn)} </DataInfo>
          </DataElement>
          <BorderLine />
          <DataElement>
            <DataTitle>Прописан</DataTitle>
            <DataInfo>{data.placeOfResidence} </DataInfo>
          </DataElement>
          <BorderLine />
          <FlexRowGap style={{ width: '100%' }}>
            <VerifiedSign
              $verificationStatus={data.verificationStatus}
              style={{ position: 'static' }}
            >
              <img alt={''} src={verifiedImages[data.verificationStatus]} />
              <VerifiedText>{verifiedTexts[data.verificationStatus]}</VerifiedText>
            </VerifiedSign>
            <StyledButton
              disabled={data.verificationStatus === 'verified'}
              onClick={() => setVerifyModal(true)}
              size={'xl'}
              variant={'primary'}
            >
              Верифицировать паспорт
            </StyledButton>
            <StyledButton
              disabled={data.verificationStatus === 'verificationRejected'}
              onClick={() => setRejectModal(true)}
              size={'xl'}
              variant={'warning'}
            >
              Отклонить верификацию
            </StyledButton>
          </FlexRowGap>

          <Spacer />

          <AdminPassportVerifyModal
            passportId={data.passportId}
            setOpen={setVerifyModal}
            viewModal={verifyModal}
          />

          <AdminPassportRejectModal
            passportId={data.passportId}
            setOpen={setRejectModal}
            viewModal={rejectModal}
          />
        </>
      )}
    </BasePage>
  )
}

export default AdminPassportPage
