import { useContext, useEffect, useState } from 'react'

import { useUsersGetUserInfoQuery } from '../../../entities/ownProfile/queries'
import { GetUserInfoResponse } from '../../../shared/api/generated'
import { ProductResponseDto, ReviewResponseDto } from '../../../shared/api/generated-old'
import ProfileStorage from '../../../shared/storage/ProfileStorage/ProfileStorage'
import UserStorage from '../../../shared/storage/UserStorage/UserStorage'

export function useProfileInfo() {
  const store = useContext(UserStorage)

  const ProfileStore = useContext(ProfileStorage)
  const [userInfo, setUserInfo] = useState<GetUserInfoResponse>(ProfileStore.getUserInfo())
  // const [avatar, setAvatar] = useState(ProfileStore.getUserInfo().profileImageUrl)

  // const getUserInfo = usePostApiUsersGetUserInfo({
  //   onError: error => {
  //     console.log('profile error', error)
  //   },
  //   onSuccess: data => {
  //     ProfileStore.setUserInfo(data)
  //     setUserInfo(data)
  //   },
  // })
  const { data, isSuccess } = useUsersGetUserInfoQuery(store?.userId || null)

  useEffect(() => {
    if (data && isSuccess) {
      ProfileStore.setUserInfo(data)
      setUserInfo(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess])

  // const getProfileDataQuery = useProfileQuery({
  //   onSuccess: data => {
  //     setUserInfo(data)
  //     ProfileStore.setUserInfo(data)
  //     setAvatar(data?.avatarLink)
  //   },
  // })

  const [myProducts, setMyProducts] = useState<ProductResponseDto[]>([])

  // const getMyProductsQuery = useGetMyProductsQuery({
  //   onSuccess: (data: GetProductsOwnResult) => {
  //     setMyProducts(data)
  //   },
  // })

  const [myReviews, setMyReviews] = useState<ReviewResponseDto[]>([])
  // const getOwnReviewsQuery = useGetOwnReviewsQuery(userInfo.id, {
  //   onSuccess: data => {
  //     setMyReviews(data)
  //   },
  // })

  const [openClientRate, setOpenClientRate] = useState(false)
  const [openLessorRate, setOpenLessorRate] = useState(false)

  return {
    // getMyProductsQuery,
    getUserInfo: data,
    // getUserProfile,
    // getProfileDataQuery,
    myProducts,
    myProfile: ProfileStore.getProfile(),
    myReviews,
    openClientRate,
    openLessorRate,
    setOpenClientRate,
    setOpenLessorRate,
    userId: store?.userId,
    userInfo,
  }
}
