import { useState } from 'react'

import { RentListItem } from '../../../shared/api/generated'
import { NoProducts } from '../../../shared/ui-kit/actual/NoProducts'
import { dateToString } from '../../../shared/utils/dateToString'
import { ArchiveCardBuyer } from './ArchiveCardBuyer'
import { ArchiveCardSeller } from './ArchiveCardSeller'
import { OrdersWrapper } from './CurrentOrders'

type Props = {
  finished: RentListItem[]
}
export const ArchiveOrdersBuyer = ({ finished }: Props) => {
  const [selectedOrders, setSelectedOrders] = useState<number[]>([])
  const handleCheckbox = (id: number) => {
    if (selectedOrders.includes(id)) {
      setSelectedOrders(selectedOrders.filter(orderId => orderId !== id))
    } else {
      setSelectedOrders([...selectedOrders, id])
    }
  }

  return (
    <OrdersWrapper>
      {finished.length > 0 ? (
        <>
          {finished.map(order => (
            <ArchiveCardBuyer
              handleCheckbox={() => handleCheckbox(order.rentId)}
              image={order.productImagesUrls[0]}
              imageIcon={order.sellerImageUrl}
              interval={dateToString(order.periodFrom) + ' - ' + dateToString(order.periodTo)}
              isSelected={selectedOrders.includes(order.rentId)}
              key={order.rentId}
              name={order.productName}
              numberOfMessages={order.unreadMessagesCount}
              pledge={order.pledge}
              price={order.price}
              productId={order.productId}
              rentId={order.rentId}
              status={order.status}
            />
          ))}
        </>
      ) : (
        <NoProducts
          imgPath={'/images/interface/EmptyBag.svg'}
          message={'У вас еще нет завершенных заказов'}
        />
      )}
    </OrdersWrapper>
  )
}
export const ArchiveOrdersSeller = ({ finished }: Props) => {
  const [selectedOrders, setSelectedOrders] = useState<number[]>([])
  const handleCheckbox = (id: number) => {
    if (selectedOrders.includes(id)) {
      setSelectedOrders(selectedOrders.filter(orderId => orderId !== id))
    } else {
      setSelectedOrders([...selectedOrders, id])
    }
  }

  return (
    <OrdersWrapper>
      {finished.length > 0 ? (
        <>
          {finished.map(order => (
            <ArchiveCardSeller
              handleCheckbox={() => handleCheckbox(order.rentId)}
              image={order.productImagesUrls[0]}
              imageIcon={order.sellerImageUrl}
              interval={dateToString(order.periodFrom) + ' - ' + dateToString(order.periodTo)}
              isSelected={selectedOrders.includes(order.rentId)}
              key={order.rentId}
              name={order.productName}
              numberOfMessages={order.unreadMessagesCount}
              pledge={order.pledge}
              price={order.price}
              productId={order.productId}
              rentId={order.rentId}
              status={order.status}
            />
          ))}
        </>
      ) : (
        <NoProducts
          imgPath={'/images/interface/EmptyBag.svg'}
          message={'У вас еще нет завершенных заказов'}
        />
      )}
    </OrdersWrapper>
  )
}
