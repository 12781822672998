import { useParams } from 'react-router-dom'

import SectionTemplate from './model/SectionTemplate'

const SectionPage = () => {
  const { sectionId } = useParams()

  return <SectionTemplate key={sectionId} />
}

export default SectionPage
