export function deleteLastDoteInDate(str: string) {
  const date = new Date(str)

  if (isNaN(date.getTime())) {
    return '...'
  }

  const options = { day: 'numeric', month: 'short' } as const

  const formatter = new Intl.DateTimeFormat('ru-RU', options)
  let formattedDate = formatter.format(date)

  if (formattedDate.endsWith('.')) {
    formattedDate = formattedDate.slice(0, -1)
  }

  return formattedDate
}
