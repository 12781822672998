import { css } from 'styled-components/macro'

import { colors } from '../constants/colors'

export const horizontalScroll = css`
  &::-webkit-scrollbar {
    height: 5px;
    background-color: ${colors.lightGray};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.lightGrayBg};
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    opacity: 0;
    background-color: ${colors.white};
  }
`

export const verticalScroll = css`
  &::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    background-color: ${colors.white};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.mediumGray};
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: ${colors.white};
  }
`
export const hideScroll = css`
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`
