import React, { memo, useContext, useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import { RentListItem } from '../../../../shared/api/generated'
import { queryClient } from '../../../../shared/api/query-client'
import { colors } from '../../../../shared/constants/colors'
import { useModal } from '../../../../shared/hooks/useModal'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { NoProducts } from '../../../../shared/ui-kit/actual/NoProducts'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { desktopView } from '../../../../shared/ui-kit/breakPoints'
import { ErrorText } from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'
import { dateToday } from '../../../../shared/utils/dateToday'
import { useGetRentsAllList } from '../../model/use-get-rents-all'
import { useTodayRentsList } from '../../model/use-get-rents-list'
import { RentItem } from '../RentItem/RentItem'
import { RentModal } from '../RentModal/RentModal'

const RentsTodayWrapper = styled.div`
  @media all and (max-width: 800px) {
    display: none;
  }
`

const RentsHead = styled(H2)`
  margin: 0 0 24px 0;
  ${desktopView}
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 22px;
  @media all and (max-width: 800px) {
    margin: 0 0 100px 0;
    padding: 32px 21px;
  }
`
const SpaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const ConfirmBanner = styled.p`
  padding: 18px 20px;
  background: ${colors.lightGray};
  margin: 0;
  border-radius: 10.53px;
  font-size: 13px;
  font-weight: 500;
  line-height: 23px;
  color: ${colors.grey};
  @media all and (min-width: 800px) {
    display: none;
  }
`

interface RentListProps {
  endDateString?: string
  setIsSecondModalOpen?: (isSecondModalOpen: boolean) => void
  setModal?: (viewModal: boolean) => void
  setModalMessage?: React.Dispatch<
    React.SetStateAction<{
      img: string
      message: string
      title: string
    }>
  >
  startDateString?: string
}

export const RentList = memo(
  ({
    endDateString = dateToday(),
    setIsSecondModalOpen,
    setModal,
    setModalMessage,
    startDateString = dateToday(),
  }: RentListProps) => {
    const { setViewModal, viewModal } = useModal()
    const [selectedRent, setSelectedRent] = useState<RentListItem | null>(null)
    const { user } = useContext(UserStorage)
    const userId = user.UserId

    const toggleModalVisibility = () => {
      setViewModal(prevIsModalOpen => !prevIsModalOpen)
    }

    const handleRentItemClick = (rent: RentListItem) => {
      setSelectedRent(rent)
      setViewModal(true)
    }

    useEffect(() => {
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const endDateFuture = endDateString === dateToday() ? '2030-01-01' : endDateString
    // All
    const {
      data: allData,
      isError: isErrorAll,
      isLoading: isLoadingAll,
    } = useGetRentsAllList({ endDateString: endDateFuture, startDateString, userId })

    // Today
    const {
      fullData: filteredData,
      isError: isErrorFiltered,
      isLoading: isLoadingFiltered,
    } = useTodayRentsList({
      periodFrom: dateToday(),
      periodTo: dateToday(),
      userId,
    })

    if (isLoadingAll || isLoadingFiltered) {
      return <AbsoluteLoader isLoading />
    }

    if (isErrorAll || isErrorFiltered) {
      return <ErrorText>Error loading messages</ErrorText>
    }

    if (!filteredData?.items?.length && !allData?.length) {
      return (
        <NoProducts
          imgPath={'/images/chat/NotOrders.svg'}
          message={'У вас нет новых заказов'}
          style={{ flex: 1 }}
        />
      )
    }

    return (
      <Wrapper>
        {viewModal && (
          <RentModal
            isModalOpen={viewModal}
            selectedRent={selectedRent}
            setIsModalOpen={toggleModalVisibility}
            setIsSecondModalOpen={setIsSecondModalOpen}
            setModal={setModal}
            setModalMessage={setModalMessage}
          />
        )}
        {allData?.length > 0 && (
          <ConfirmBanner>
            Подтверждая, вы утверждаете, что можете выделить данный товар в нужные даты и на нужный
            период
          </ConfirmBanner>
        )}

        {filteredData?.items?.length > 0 && (
          <RentsTodayWrapper>
            <RentsHead>На сегодняшний день</RentsHead>
            <SpaceContainer>
              {filteredData?.items?.map(d => (
                <RentItem key={d?.rentId} rentData={d} setModal={() => handleRentItemClick(d)} />
              ))}
            </SpaceContainer>
          </RentsTodayWrapper>
        )}

        <div>
          {allData?.length > 0 ? (
            <>
              <RentsHead>Все новые заказы</RentsHead>
              <SpaceContainer>
                {allData?.map(d => (
                  <RentItem key={d?.rentId} rentData={d} setModal={() => handleRentItemClick(d)} />
                ))}
              </SpaceContainer>
            </>
          ) : (
            <RentsHead style={{ fontSize: '14px' }}>
              В выбранный период нет аренд. Попробуйте выбрать другой период.
            </RentsHead>
          )}
        </div>
      </Wrapper>
    )
  }
)
