import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useGetProductsListQuery } from '../../../../entities/productsList/queries'
import { useGetReviewsListQuery } from '../../../../entities/reviews/queries'
import { GetReviewsListResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { goods } from '../../../../shared/constants/mockData'
import { PaginationComponent } from '../../../../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { ItemRates } from '../../../../shared/ui-kit/actual/components/ItemRates'
import { MarketUsefulArticles } from '../../../../shared/ui-kit/actual/components/MarketUsefulArticles'
import {
  CategoriesTitleH1,
  FlexColumn,
  NeutralLink,
  PaginationWrapper,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { GoodsDesktopList } from '../GoodsDesktopList'

const Wrapper = styled(FlexColumn)`
  flex-grow: 1;
  background-color: ${colors.white};
  gap: 24px;
`

type Props = {
  buyerReviews: GetReviewsListResponse
  sellerReviews: GetReviewsListResponse
}
export const UserDesktopWrapper = ({ buyerReviews, sellerReviews }: Props) => {
  const { userId } = useParams()
  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const { data, isLoading } = useGetProductsListQuery({
    ownerId: userId,
    skip: page * itemsPerPage,
    take: itemsPerPage,
  })
  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )
  // TODO: везде в отзывах стоит запрос на take 50 надо будет потом заменить, для MVP это окей

  const buyerReviewsItems = buyerReviews?.items

  const sellerReviewsItems = sellerReviews?.items
  const userReviews = [...(buyerReviewsItems || []), ...(sellerReviewsItems || [])]

  return (
    <Wrapper>
      <AbsoluteLoader isLoading={isLoading} />
      <CategoriesTitleH1 style={{ width: '100%' }}>Объявления</CategoriesTitleH1>
      {data?.items && (
        <>
          <H3>Объявления пользователя</H3>
          <GoodsDesktopList
            items={data?.items}
            messageNoProducts={'У пользователя еще нет объявлений'}
          />

          <PaginationWrapper>
            <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
          </PaginationWrapper>
          <MarketUsefulArticles authorId={userId} take={30} title={'Статьи пользователя'} />
          <FlexColumn style={{ alignSelf: 'flex-start', gap: '12px' }}>
            <CategoriesTitleH1 style={{ width: '100%' }}>Отзывы</CategoriesTitleH1>
            <ItemRates reviews={userReviews} />
            <NeutralLink to={'review'}>
              <StyledButton variant={'secondary'} fullwidth>
                Все отзывы
              </StyledButton>
            </NeutralLink>
          </FlexColumn>
        </>
      )}
    </Wrapper>
  )
}
