import styled from 'styled-components/macro'

import { colors } from '../constants/colors'
import { devices } from './breakPoints'

export const Head = styled.h1`
  color: ${colors.black};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const H1 = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #000000;
`
export const H2 = styled.h2`
  font-size: 24px;
  margin: 0;
  font-weight: 700;
  color: ${colors.black};
`
export const H3 = styled.h3`
  font-size: 21px;
  align-self: flex-start;
  margin: 0;
  /* width: clamp(300px, 100%, 800px); */
  font-weight: 700;
  color: ${colors.black};
  @media screen and (${devices.mobileMax}) {
    font-size: 24px;
  }
`
export const H4 = styled.h4`
  font-size: 18px;
  align-self: flex-start;
  margin: 20px 0 0;
  font-weight: 700;
  color: ${colors.black};
  @media screen and (${devices.mobileMax}) {
    font-size: 21px;
  }
`
export const H5 = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: ${colors.black};
`
