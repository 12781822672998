import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  AddBankAccountCommand,
  AddBankCardCommand,
  ApproveWithdrawalRequestCommand,
  CreateWithdrawalRequestCommand,
  GetBankAccountTopUpRequisitesResponse,
  GetBankCardInfoQuery,
  GetBankCardInfoResponse,
  GetPaymentMethodsQuery,
  GetPaymentMethodsResponse,
  GetWithdrawalRequestInfoQuery,
  GetWithdrawalRequestInfoResponse,
  GetWithdrawalRequestsListQuery,
  GetWithdrawalRequestsListResponse,
  RejectWithdrawalRequestCommand,
  RemoveBankAccountCommand,
  RemoveBankCardCommand,
  getApiPaymentsGetBankAccountTopUpRequisites,
  postApiOperationsApproveWithdrawalRequest,
  postApiOperationsCreateWithdrawalRequest,
  postApiOperationsGetWithdrawalRequestInfo,
  postApiOperationsGetWithdrawalRequestsList,
  postApiOperationsRejectWithdrawalRequest,
  postApiPaymentsAddBankAccount,
  postApiPaymentsAddBankCard,
  postApiPaymentsGetBankCardInfo,
  postApiPaymentsGetPaymentMethods,
  postApiPaymentsRemoveBankAccount,
  postApiPaymentsRemoveBankCard,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useGetPaymentMethodsQuery(
  data: GetPaymentMethodsQuery,
  options?: UseQueryOptions<unknown, unknown, GetPaymentMethodsResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки списка банковских карт')
    },
    queryFn: () => postApiPaymentsGetPaymentMethods(data),
    queryKey: ['get_payments_methods'],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGetBankCardInfoQuery(
  data: GetBankCardInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetBankCardInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки данных банковской карты')
    },
    queryFn: () => postApiPaymentsGetBankCardInfo(data),
    queryKey: ['get_bank_card_info'],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGetWithdrawalRequestsListQuery(
  data: GetWithdrawalRequestsListQuery,
  options?: UseQueryOptions<unknown, unknown, GetWithdrawalRequestsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки списка запросов на вывод')
    },
    queryFn: () => postApiOperationsGetWithdrawalRequestsList(data),
    queryKey: ['get_withdrawal_requests_list', JSON.stringify(data)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGetWithdrawalRequestInfoQuery(
  data: GetWithdrawalRequestInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetWithdrawalRequestInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки запроса на вывод')
    },
    queryFn: () => postApiOperationsGetWithdrawalRequestInfo(data),
    queryKey: ['get_withdrawal_request', JSON.stringify(data)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGetBankAccountTopUpRequisitesQuery(
  options?: UseQueryOptions<unknown, unknown, GetBankAccountTopUpRequisitesResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки реквизитов на платформы')
    },
    queryFn: () => getApiPaymentsGetBankAccountTopUpRequisites(),
    queryKey: ['get_bank_account_requisites'],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useApproveWithdrawalRequestMutation(
  options?: UseMutationOptions<unknown, unknown, ApproveWithdrawalRequestCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ApproveWithdrawalRequestCommand) => {
      return postApiOperationsApproveWithdrawalRequest(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при подтверждении операции')
    },
    onSuccess: () => {
      toast.success('Операция вывода успешно подтверждена')
      queryClient.invalidateQueries({ queryKey: ['get_withdrawal_request'] })
      queryClient.invalidateQueries({ queryKey: ['get_withdrawal_requests_list'] })
    },
    ...options,
  })
}
export function useRejectWithdrawalRequestMutation(
  options?: UseMutationOptions<unknown, unknown, RejectWithdrawalRequestCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RejectWithdrawalRequestCommand) => {
      return postApiOperationsRejectWithdrawalRequest(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при отказе в операции')
    },
    onSuccess: () => {
      toast.success('Операция успешно отклонена')
      queryClient.invalidateQueries({ queryKey: ['get_withdrawal_request'] })
      queryClient.invalidateQueries({ queryKey: ['get_withdrawal_requests_list'] })
    },
    ...options,
  })
}
export function useAddBankCardMutation(
  options?: UseMutationOptions<unknown, unknown, AddBankCardCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: AddBankCardCommand) => {
      return postApiPaymentsAddBankCard(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка подключения новой банковской карты')
    },
    onSuccess: () => {
      toast.success('Карта успешно добавлена')
      queryClient.invalidateQueries({ queryKey: ['get_payments_methods'] })
    },
    ...options,
  })
}
export function useAddBankAccountMutation(
  options?: UseMutationOptions<unknown, unknown, AddBankAccountCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: AddBankAccountCommand) => {
      return postApiPaymentsAddBankAccount(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка регистрации банковских реквизитов')
    },
    onSuccess: () => {
      toast.success('Банковские реквизиты успешно добавлены')
      queryClient.invalidateQueries({ queryKey: ['get_payments_methods'] })
    },
    ...options,
  })
}
export function useRemoveBankCardMutation(
  options?: UseMutationOptions<unknown, unknown, RemoveBankCardCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RemoveBankCardCommand) => {
      return postApiPaymentsRemoveBankCard(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления данных банковской карты')
    },
    onSuccess: () => {
      toast.success('Карта успешно удалена')
      queryClient.invalidateQueries({ queryKey: ['get_payments_methods'] })
    },
    ...options,
  })
}
export function useRemoveBankAccountMutation(
  options?: UseMutationOptions<unknown, unknown, RemoveBankAccountCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RemoveBankAccountCommand) => {
      return postApiPaymentsRemoveBankAccount(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления данных банковского счета')
    },
    onSuccess: () => {
      toast.success('Банковский счет успешно удален')
      queryClient.invalidateQueries({ queryKey: ['get_payments_methods'] })
    },
    ...options,
  })
}
export function useCreateWithdrawalRequestMutation(
  options?: UseMutationOptions<unknown, unknown, CreateWithdrawalRequestCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CreateWithdrawalRequestCommand) => {
      return postApiOperationsCreateWithdrawalRequest(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки запроса на вывода')
    },
    onSuccess: () => {
      toast.success('Заявка на вывод успешно отправлена')
      queryClient.invalidateQueries({ queryKey: ['get_payments_methods'] })
    },
    ...options,
  })
}
