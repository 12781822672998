import React from 'react'

import styled from 'styled-components/macro'

import { useCreateReviewMutation } from '../../../entities/reviews/queries'
import { ReviewType } from '../../../shared/api/generated'
import { SetStarsRating } from '../../../shared/ui-kit/actual/SetStarsRating'
import { TextArea } from '../../../shared/ui-kit/actual/TextArea'
import { StyledButton } from '../../../shared/ui-kit/projectComponents'

const FormWrapper = styled.form`
  align-self: stretch;
  margin-bottom: 40px;
`

type Props = {
  comment: string
  rating: number
  rentId: number
  reviewType: ReviewType
  setComment: React.Dispatch<React.SetStateAction<string>>
  setRating: React.Dispatch<React.SetStateAction<number>>
}
export const FeedbackForm = ({
  comment,
  rating,
  rentId,
  reviewType,
  setComment,
  setRating,
}: Props) => {
  const createReviewMutation = useCreateReviewMutation({
    onSuccess: () => {},
  })

  return (
    <FormWrapper onClick={e => e.preventDefault()}>
      <SetStarsRating rating={rating} setRating={setRating} />
      <br />
      <TextArea
        inputProps={{
          placeholder: 'Комментарий',
          rows: 7,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
        style={{ marginBottom: '8px', width: '100%' }}
        value={comment}
        fullwidth
      />
      <br />
      <StyledButton
        onClick={() =>
          createReviewMutation.mutate({
            content: comment,
            rating: rating,
            rentId: rentId,
            reviewType: reviewType,
          })
        }
        disabled={!rating || createReviewMutation.isLoading || createReviewMutation.isSuccess}
        variant={'secondary'}
        fullwidth
      >
        {(createReviewMutation.isSuccess && 'Отзыв отправлен') ||
          (createReviewMutation.isLoading && 'Отзыв отправляется') ||
          'Оставить отзыв'}
      </StyledButton>
    </FormWrapper>
  )
}
