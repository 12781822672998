import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  JsonHubProtocol,
} from '@microsoft/signalr'

import { useGetChatMessage } from '../../../entities/message'
import { ChatMessageExtended } from '../../../shared/api/generated'
import { queryClient } from '../../../shared/api/query-client'
import { SERVER_URL } from '../../../shared/api/serverConstants'
import UserStorage from '../../../shared/storage/UserStorage/UserStorage'

export const useChatConnection = () => {
  const [takeAndSkip] = useState({ skip: 0, take: 10000 })
  const endRef = useRef(null)
  const chatContainerRef = useRef(null)
  const { rentId } = useParams()
  const chatId = useMemo(() => Number(rentId), [rentId])
  const [messageData, setMessageData] = useState<ChatMessageExtended[]>([])
  const [connectionIsLoading, setConnectionIsLoading] = useState(true)
  const connectionRef = useRef<HubConnection | null>(null)
  const isUnmountedRef = useRef(false)

  const { data, isLoading } = useGetChatMessage({
    ChatId: chatId,
    Skip: takeAndSkip.skip,
    Take: takeAndSkip.take,
  })

  const scrollToBottom = useCallback(() => {
    endRef.current?.scrollIntoView()
  }, [])
  const accessToken = JSON.parse(localStorage.accessToken)

  useEffect(() => {
    scrollToBottom()
  }, [messageData, scrollToBottom])

  useEffect(() => {
    const joinChat = async () => {
      if (connectionRef.current) {
        return
      }
      const protocol = new JsonHubProtocol()

      console.log('Creating new connection')
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${SERVER_URL}/hubs/chat`, {
          accessTokenFactory: async () => accessToken,
          // skipNegotiation: false,
          // transport: HttpTransportType.ServerSentEvents,
        })
        .withHubProtocol(protocol)
        .build()

      newConnection.on('ReceiveMessage', (newMessage: ChatMessageExtended) => {
        setMessageData(prevMessages => [newMessage, ...prevMessages])
        queryClient.invalidateQueries({ queryKey: ['get_messages'] })
      })

      try {
        await newConnection.start()
        if (isUnmountedRef.current) {
          await newConnection.stop()
          console.log('Connection stopped immediately due to unmounting.')

          return
        }
        await newConnection.invoke('Subscribe', chatId)
        setConnectionIsLoading(false)
        connectionRef.current = newConnection
        console.log('Connection started')
      } catch (error) {
        console.error('Ошибка:', error)
      }
    }

    joinChat()

    return () => {
      isUnmountedRef.current = true
      if (connectionRef.current) {
        console.log('Stopping connection')
        connectionRef.current.off('ReceiveMessage')
        connectionRef.current
          .stop()
          .then(() => {
            console.log('Connection stopped successfully.')
            connectionRef.current = null
          })
          .catch(error => console.error('Error stopping connection:', error))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, accessToken, queryClient])

  useEffect(() => {
    if (data) {
      setMessageData(data?.items || [])
      if (chatContainerRef.current) {
        const { clientHeight, scrollHeight, scrollTop } = chatContainerRef.current

        if (scrollTop + clientHeight === scrollHeight) {
          endRef.current?.scrollIntoView()
        }
      }
    }
  }, [data])

  return { chatContainerRef, connectionIsLoading, endRef, isLoading, messageData }
}
