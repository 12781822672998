import { ReactNode, useContext, useMemo, useState } from 'react'

import { useRentsGetRentsListQuery } from '../../../../entities/rent'
import { primaryStarSVG } from '../../../../shared/constants/images'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import { PaginationComponent } from '../../../../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { MarketUsefulArticles } from '../../../../shared/ui-kit/actual/components/MarketUsefulArticles'
import {
  BoldNumber,
  BtnRowWrapper,
  BtnWrapper,
  OpacityText,
  PaginationWrapper,
  StyledBtnColumn,
  StyledBtnSmall,
  StyledLink,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { useProfileInfo } from '../../model/use-profile-info'
import { RentGoodsDesktopList } from '../RentGoodsDesktopList'

const ButtonColumn = ({
  bottom,
  onClick,
  top,
}: {
  bottom: ReactNode
  onClick?: () => void
  top: ReactNode
}) => (
  <StyledBtnColumn
    onClick={() => {
      onClick ? onClick() : null
    }}
    variant={'secondary'}
    fullwidth
  >
    <BoldNumber>{top}</BoldNumber>
    <OpacityText>{bottom}</OpacityText>
  </StyledBtnColumn>
)

type Props = {
  setBalanceModal: (viewModal: boolean) => void
  setPayoutModal: (value: boolean) => void
  setRatingModal: (value: boolean) => void
}

export const ProfileAbout = ({ setBalanceModal, setPayoutModal, setRatingModal }: Props) => {
  const { userInfo } = useProfileInfo()

  const [rentsPage, setRentsPage] = useState(0)
  const [rentsItemsPerPage, setRentsItemsPerPage] = useState(12)
  const { data: rentsData, isLoading: isRentsLoading } = useRentsGetRentsListQuery({
    buyerId: userInfo.id,
    skip: rentsPage * rentsItemsPerPage,
    take: rentsItemsPerPage,
    userId: userInfo.id,
  })

  const totalRentsPages = useMemo(
    () => (rentsData ? Math.ceil(rentsData?.totalCount / rentsItemsPerPage) : 1),
    [rentsData, rentsItemsPerPage]
  )

  const userStore = useContext(ProfileStorage)
  const userProfile = userStore.getProfile()

  return (
    <>
      <BtnWrapper>
        <AbsoluteLoader isLoading={isRentsLoading} />
        <BtnRowWrapper onClick={() => setRatingModal(true)}>
          <ButtonColumn
            top={
              <>
                {' '}
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{userProfile?.buyerRating.toFixed(1) || 0}
              </>
            }
            bottom={<>Арендатор</>}
          />
          <ButtonColumn
            top={
              <>
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{userProfile?.sellerRating.toFixed(1) || 0}
              </>
            }
            bottom={<>Арендодатель</>}
          />
          {/* <ImageSlices frequentlyProducts={frequentlyProducts} /> */}
        </BtnRowWrapper>
        <BtnRowWrapper>
          <ButtonColumn
            bottom={<>Баланс</>}
            onClick={() => setBalanceModal(true)}
            top={<>{userProfile?.balanceUser?.accountBalance || 0} ₽</>}
          />
          <ButtonColumn
            bottom={<>Бонусы</>}
            top={<>{userProfile?.balanceUser?.bonusBalance || 0} SHC</>}
          />
          <StyledBtnSmall onClick={() => setPayoutModal(true)} variant={'secondary'}>
            <img alt={''} src={'/images/interface/Wallet.svg'} />
          </StyledBtnSmall>
        </BtnRowWrapper>
      </BtnWrapper>

      {rentsData && (
        <>
          <H3>Мои заказы</H3>
          <RentGoodsDesktopList
            iconSrc={'/images/buttons/star-transparent.svg'}
            items={rentsData.items}
            messageNoProducts={'У вас еще нет заказов'}
          />
          <PaginationWrapper>
            <PaginationComponent
              maxPages={totalRentsPages}
              pageId={rentsPage}
              setPageId={setRentsPage}
            />
          </PaginationWrapper>
        </>
      )}

      {/* <H3>Избранное</H3>
      {frequentlyProducts && (
        <GoodsDesktopList
          goods={frequentlyProducts}
          iconSrc={'/images/buttons/Heart.svg'}
          isLoading={isLoading}
          items={[]}
          messageNoProducts={'У вас еще нет избранных товаров'}
        />
      )} */}

      <MarketUsefulArticles authorId={userInfo.id} take={30} title={'Мои статьи'} />
      <StyledLink style={{ width: '100%' }} to={'/blog'}>
        Все статьи
      </StyledLink>
    </>
  )
}
