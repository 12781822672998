import styled from 'styled-components/macro'

import { useArticlesShareArticleMutation } from '../../../../entities/articles/queries'
import { useGetUserProfileQuery } from '../../../../entities/userProfile/queries'
import { GetArticleInfoResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { useShare } from '../../../../shared/hooks/use-share'
import ProfileSnippet from '../../../../shared/ui-kit/ProfileSnippet'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { LineBreaks } from '../../../../shared/ui-kit/actual/components/LineBreaks'
import {
  DesktopView,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  MobileTabletView,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { dateToString } from '../../../../shared/utils/dateToString'
import { useProfileInfo } from '../../../profile/model/use-profile-info'

const SectionWrapper = styled(FlexColumnStart)`
  width: 100%;
  margin-bottom: 32px;
`

const ArticleCoverPlaceholder = styled.div`
  border-radius: 12px;
  width: inherit;
  height: 200px;
  background-color: ${colors.lightGray};
  margin-bottom: 32px;
`

const ArticleCover = styled.img`
  height: inherit;
  object-fit: contain;
  width: inherit;
  border-radius: inherit;
`

const ArticleTitle = styled.h2`
  font-size: 16px;
  color: ${colors.black};
  margin: 0;
  margin-bottom: 8px;
  margin-top: 17px;
`

const ArticleDescription = styled.div`
  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 171%;
  margin: 0;
  color: ${colors.grey};
  font-weight: 500;
`

const ArticleStat = styled(FlexRow)`
  gap: 4px;

  span {
    font-size: 10px;
    color: ${colors.grey};
  }
`

const ArticleContent = styled(FlexColumnStart)`
  margin-top: 17px;
  width: calc(100% - 16px);
  span {
    font-size: 12px;
    color: ${colors.grey};
    font-weight: 500;
  }
`

interface ArticleSectionProps {
  data: GetArticleInfoResponse
  handleOpenDeleteModal?: () => void
  isDeleteLoading?: boolean
}
const ArticleSection = ({ data, handleOpenDeleteModal, isDeleteLoading }: ArticleSectionProps) => {
  const { data: userInfo, isLoading } = useGetUserProfileQuery(data?.authorId)

  const { userId } = useProfileInfo()
  const currentUser = {
    avatarLink: userInfo?.avatarLink,
    fullName: userInfo?.fullName,
    id: userInfo?.id,
    isSubscribed: userInfo?.isSubscribed,
    userName: userInfo?.accountName,
  }
  const iThisUser = userId === data.authorId

  return (
    <SectionWrapper>
      <AbsoluteLoader isLoading={isLoading} />

      <ArticleCoverPlaceholder>
        <ArticleCover src={data?.imageUrl} />
      </ArticleCoverPlaceholder>
      <ArticleContent>
        <MobileTabletView>
          <FlexRowBetween>
            <ProfileSnippet
              additionalStyles={{ marginBottom: 8 }}
              authorId={data?.authorId}
              authorImageUrl={data?.authorImageUrl}
              authorLogin={data?.authorLogin}
              authorName={data?.authorName}
              currentUser={currentUser}
            />
            {iThisUser && (
              <StyledButton
                disabled={isDeleteLoading}
                onClick={handleOpenDeleteModal}
                variant={'warning'}
              >
                Удалить статью
              </StyledButton>
            )}
          </FlexRowBetween>
        </MobileTabletView>
        <FlexRowBetween style={{ margin: '16px 0 12px' }}>
          <span>{dateToString(data?.createdOn)}</span>
          <FlexRow style={{ gap: 12 }}>
            <ArticleStat>
              <img alt={'views'} src={'/images/interface/views-eye.svg'} />
              <span>{data?.viewsCount}</span>
            </ArticleStat>
            <ArticleStat>
              <img alt={'comments'} src={'/images/interface/comment.svg'} />
              <span>{data?.commentsCount}</span>
            </ArticleStat>
            <ArticleStat>
              <img alt={'shared'} src={'/images/interface/share.svg'} />
              <span>{data?.sharesCount}</span>
            </ArticleStat>
          </FlexRow>
        </FlexRowBetween>
        <ArticleTitle>{data?.title}</ArticleTitle>
        <ArticleDescription>
          <LineBreaks>{data?.content}</LineBreaks>
        </ArticleDescription>
      </ArticleContent>
    </SectionWrapper>
  )
}

export default ArticleSection
