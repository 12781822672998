import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetUserAddressesResponse,
  MakeUserAddressDefaultCommand,
  RemoveUserAddressCommand,
  UserAddressDto,
  deleteApiUserAddressesRemoveUserAddress,
  getApiUserAddressesGetUserAddresses,
  postApiUserAddressesAddUserAddress,
  postApiUserAddressesMakeUserAddressDefault,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useGetUserAddresses(
  userId: string,
  options?: UseQueryOptions<unknown, unknown, GetUserAddressesResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки адресов')
      if (e.response?.status === 403) {
        window.location.href = '/'
        toast.error(e.response?.data?.message || 'Ошибка данных об аренде')
      }
    },
    queryFn: () => getApiUserAddressesGetUserAddresses({ UserId: userId }),
    queryKey: ['get_user_addresses', userId],
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useCreateUserAddress(
  options?: UseMutationOptions<unknown, unknown, UserAddressDto, unknown>
) {
  return useMutation({
    mutationFn: (data: UserAddressDto) => {
      return postApiUserAddressesAddUserAddress(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка добавления адреса')
    },
    ...options,
  })
}

export function useUserAddressesMakeUserAddressDefaultMutation(
  prevList: GetUserAddressesResponse,
  options?: UseMutationOptions<unknown, unknown, MakeUserAddressDefaultCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: MakeUserAddressDefaultCommand) => {
      return postApiUserAddressesMakeUserAddressDefault(data)
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(['get_user_addresses'], () => context)
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка подтверждения адреса по умолчанию')
    },
    // optimistic update
    onMutate: async (data: MakeUserAddressDefaultCommand) => {
      await queryClient.cancelQueries({ queryKey: ['get_user_addresses', data?.id] })

      queryClient.setQueryData(['get_user_addresses', data?.id], () => {
        const addresses = prevList.addresses
        const newAddresses: UserAddressDto[] = [...addresses].map(address => {
          if (address.id === data.id) {
            address.isDefault = true
          } else {
            address.isDefault = false
          }

          return address
        })

        return { ...prevList, addresses: newAddresses }
      })

      return { ...prevList }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['get_user_addresses'] })
    },
    onSuccess: () => {
      toast.info('Вы выбрали адрес по умолчанию')
      queryClient.invalidateQueries({ queryKey: ['get_user_addresses'] })
    },
    ...options,
  })
}

export function useUserAddressesRemoveUserAddressMutation(
  options?: UseMutationOptions<unknown, unknown, RemoveUserAddressCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RemoveUserAddressCommand) => {
      return deleteApiUserAddressesRemoveUserAddress(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления адреса')
    },
    onSuccess: () => {
      toast.info('Вы удалили адрес')
      queryClient.invalidateQueries({ queryKey: ['get_user_addresses'] })
    },
    ...options,
  })
}
