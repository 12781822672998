import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetPassportInfoQuery,
  GetPassportInfoResponse,
  GetPassportsListQuery,
  GetPassportsListResponse,
  PostApiDocumentsAddPassportBody,
  RejectPassportVerificationCommand,
  RemovePassportCommand,
  VerifyPassportCommand,
  postApiDocumentsAddPassport,
  postApiDocumentsGetPassportInfo,
  postApiDocumentsGetPassportsList,
  postApiDocumentsRejectPassportVerification,
  postApiDocumentsRemovePassport,
  postApiDocumentsVerifyPassport,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useDocumentsAddPassportMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiDocumentsAddPassportBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiDocumentsAddPassportBody) => {
      return postApiDocumentsAddPassport(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки данных паспорта')
    },
    onSuccess: () => {
      toast.success('Данные паспорта добавлены для верификации')
    },
    ...options,
  })
}
export function useDocumentsVerifyPassportMutation(
  options?: UseMutationOptions<unknown, unknown, VerifyPassportCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: VerifyPassportCommand) => {
      return postApiDocumentsVerifyPassport(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка верификации паспорта')
    },
    onSuccess: () => {
      toast.success('Данные паспорта прошли верификацию')
      queryClient.invalidateQueries({ queryKey: ['get_passport_info'] })
      queryClient.invalidateQueries({ queryKey: ['get_passports_list'] })
    },
    ...options,
  })
}
export function useDocumentsRejectPassportVerificationMutation(
  options?: UseMutationOptions<unknown, unknown, RejectPassportVerificationCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RejectPassportVerificationCommand) => {
      return postApiDocumentsRejectPassportVerification(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отказа в верификации паспорта')
    },
    onSuccess: () => {
      toast.success('Данные паспорта не прошли верификацию')
      queryClient.invalidateQueries({ queryKey: ['get_passport_info'] })
      queryClient.invalidateQueries({ queryKey: ['get_passports_list'] })
    },
    ...options,
  })
}
export function useDocumentsRemovePassportMutation(
  options?: UseMutationOptions<unknown, unknown, RemovePassportCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: RemovePassportCommand) => {
      return postApiDocumentsRemovePassport(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления паспорта')
    },
    onSuccess: () => {
      toast.success('Данные паспорта успешно удалены')
    },
    ...options,
  })
}
export function useGetPassportInfoQuery(
  data: GetPassportInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetPassportInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки паспорта')
    },
    queryFn: () => postApiDocumentsGetPassportInfo(data),
    queryKey: ['get_passport_info', JSON.stringify(data)],
    refetchOnWindowFocus: false,
    retry: 1,
    ...options,
  })
}
export function useGetPassportsListQuery(
  data: GetPassportsListQuery,
  options?: UseQueryOptions<unknown, unknown, GetPassportsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки списка паспортов')
    },
    queryFn: () => postApiDocumentsGetPassportsList(data),
    queryKey: ['get_passports_list', JSON.stringify(data)],
    refetchOnWindowFocus: false,
    retry: 1,
    ...options,
  })
}
