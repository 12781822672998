import { toast } from 'react-custom-alert'
import { UseMutationOptions, useMutation } from 'react-query'

import { AxiosError } from 'axios'

import {
  SubscribeCommand,
  postApiUsersSubscribe,
  postApiUsersUnsubscribe,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useSubscribe(options?: UseMutationOptions<SubscribeCommand>) {
  return useMutation({
    mutationFn: (data: SubscribeCommand) => {
      return postApiUsersSubscribe(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка подписки')
    },
    onSuccess: () => {
      toast.success('Вы успешно подписались')
      queryClient.invalidateQueries({ queryKey: ['get_user_profile_data'] })
    },
  })
}

export function useUnSubscribe(options?: UseMutationOptions<SubscribeCommand>) {
  return useMutation({
    mutationFn: (data: SubscribeCommand) => {
      return postApiUsersUnsubscribe(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отмены подписки')
    },
    onSuccess: () => {
      toast.success('Вы успешно отменили подписку')
      queryClient.invalidateQueries({ queryKey: ['get_user_profile_data'] })
    },
  })
}
