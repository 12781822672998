import React, { ReactNode } from 'react'

import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'

import { GetUserProfileResponse, ReviewListItemDto } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { primaryStarSVG } from '../../../../shared/constants/images'
import { ImageSlices } from '../../../../shared/ui-kit/actual/ImageSlices'
import { ItemRates } from '../../../../shared/ui-kit/actual/components/ItemRates'
import { MarketUsefulArticles } from '../../../../shared/ui-kit/actual/components/MarketUsefulArticles'
import {
  BoldNumber,
  BtnRowWrapper,
  BtnWrapper,
  FlexRowGap,
  NeutralLink,
  OpacityText,
  StyledBtnColumn,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'

const MediumText = styled.div`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
`

const SmallText = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.gray};
  font-size: 10px;
  font-weight: 500;
`
const SmallTextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

const ButtonColumn = ({ bottom, top }: { bottom: ReactNode; top: ReactNode }) => (
  <StyledBtnColumn variant={'secondary'} fullwidth>
    <BoldNumber>{top}</BoldNumber>
    <OpacityText>{bottom}</OpacityText>
  </StyledBtnColumn>
)

// toggle to empty myProducts for testing <NoProducts/> blank component
// const myProducts = []

// const frequentlyProducts = [
//   {
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-3.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-6.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-5.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-6.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
// ]

interface ItemRatesProps {
  setRatingModal: (value: boolean) => void
  userData: GetUserProfileResponse
  userReviews: ReviewListItemDto[]
}

export const UserAbout: React.FC<ItemRatesProps> = ({ setRatingModal, userData, userReviews }) => {
  return (
    <>
      <BtnWrapper>
        <BtnRowWrapper onClick={() => setRatingModal(true)}>
          <ButtonColumn
            top={
              <>
                {' '}
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{userData?.buyerRating.toFixed(1)}
              </>
            }
            bottom={<>Арендатор</>}
          />
          <ButtonColumn
            top={
              <>
                <img alt={''} src={primaryStarSVG} />
                &nbsp;{userData?.sellerRating.toFixed(1)}
              </>
            }
            bottom={<>Арендодатель</>}
          />
          {/* <ImageSlices frequentlyProducts={frequentlyProducts} /> */}
        </BtnRowWrapper>
      </BtnWrapper>

      <MarketUsefulArticles authorId={userData.id} take={30} title={'Статьи пользователя'} />

      <ItemRates reviews={userReviews} />
      {/* <StyledButton fullwidth>Написать отзыв</StyledButton> */}
      <NeutralLink to={'review'}>
        <StyledButton variant={'secondary'} fullwidth>
          Все отзывы
        </StyledButton>
      </NeutralLink>
    </>
  )
}
