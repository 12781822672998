import styled from 'styled-components/macro'

import { goldStarImg, noBgStarImg } from '../../constants/images'
import { FlexRow } from '../projectComponents'

const Wrapper = styled(FlexRow)<{ small?: boolean }>`
  ${({ small }) =>
    small &&
    `
  transform: scale(0.67);
  transform-origin: left;
  `}
`

export const StarsRating = ({ rating, small }: { rating: number; small?: boolean }) => {
  return (
    <Wrapper small={small}>
      {[1, 2, 3, 4, 5].map((star, idx) => {
        return (
          <img
            alt={'star'}
            key={idx}
            src={idx++ >= rating ? noBgStarImg : goldStarImg}
            style={{ width: 14 }}
          />
        )
      })}
    </Wrapper>
  )
}
