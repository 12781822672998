import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useShare } from '../../../../shared/hooks/use-share'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import { ProfileHeader } from '../../../../shared/ui-kit/actual/ProfileHeader'
import {
  FlexRow,
  FlexRowBetween,
  HeaderTitle,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'

const BtnsWrapper = styled(FlexRow)`
  gap: 12px;
`

const IconBtn = styled(StyledButton)`
  padding: 0;
  width: 24px;
  height: 24px;
`

interface ArticlePageHeaderProps {
  onOpenModal?: () => void
  shareArticle?: () => void
}
const RateHeader = styled(FlexRowBetween)`
  align-items: center;
  width: calc(100% - 32px);
`
const ArticlePageHeader = ({ onOpenModal, shareArticle }: ArticlePageHeaderProps) => {
  const reactRouterNavigate = useNavigate()
  const UserInfo = useContext(ProfileStorage)
  const onPageBackHandler = () => {
    reactRouterNavigate(-1)
  }

  const onShowModalHandler = () => {
    onOpenModal()
  }

  const { share } = useShare({
    shareFn: shareArticle,
    text: 'Почитай интересную статью',
    title: 'Интересная статья',
  })

  return (
    <RateHeader>
      <ProfileHeader
        left={
          <IconBtn fullwidth={false} onClick={onPageBackHandler} variant={'neutral'} isIcoBtn>
            <img alt={''} src={'/images/buttons/arrow-left.svg'} />
          </IconBtn>
        }
        right={
          <BtnsWrapper>
            <IconBtn fullwidth={false} onClick={onShowModalHandler} variant={'neutral'} isIcoBtn>
              <img alt={''} src={'/images/buttons/report.svg'} />
            </IconBtn>
            <IconBtn fullwidth={false} onClick={share} variant={'neutral'} isIcoBtn>
              <img alt={''} src={'/images/buttons/share-primary.svg'} />
            </IconBtn>
          </BtnsWrapper>
        }
        center={<HeaderTitle>{UserInfo?.userInfo?.accountName || 'Неизвестный Гость'}</HeaderTitle>}
      />
    </RateHeader>
  )
}

export default ArticlePageHeader
