import { useEffect } from 'react'

import styled from 'styled-components/macro'

import { TextArea } from '../../../../shared/ui-kit/actual/TextArea'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import { FlexRowBetween, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { useItemInfoEditForm } from '../../model/use-item-info-edit-form'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

type Props = {
  categoryId: number
  description: string
  id: number
  name: string
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}
export const ItemInfoEdit = ({
  categoryId,
  description,
  id,
  name,
  setViewModal,
  viewModal,
}: Props) => {
  const {
    clearErrors,
    createProduct,
    errors,
    getValues,
    handleSubmit,
    isLoading,
    isSuccess,
    isValid,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useItemInfoEditForm()

  useEffect(() => {
    id && setValue('id', id)
    categoryId && setValue('categoryId', categoryId)
  }, [categoryId, id, setValue])

  const closeModal = () => {
    setViewModal(false)
    setTimeout(() => {
      setValue('description', description)
      setValue('name', name)
    }, 1000)
  }

  useEffect(() => {
    isSuccess && setViewModal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <BaseModal setViewModal={closeModal} viewModal={viewModal}>
      <AbsoluteLoader isLoading={isLoading} />
      <Form onSubmit={handleSubmit}>
        <TextField
          inputProps={{
            defaultValue: name,
            placeholder: 'Название товара',
            type: 'text',
            ...register('name', {
              minLength: {
                message: 'Минимум 3 символов',
                value: 3,
              },
              required: 'Название товара обязательно для заполнения',
            }),
          }}
          error={errors.name?.message?.toString()}
          label={'Название товара'}
          size={'md'}
          fullwidth
        />
        <TextArea
          inputProps={{
            defaultValue: description,
            placeholder: 'Описание',
            rows: 7,
            ...register('description', {
              minLength: {
                message: 'Минимум 6 символов',
                value: 6,
              },
              required: 'Описание товара обязательно для заполнения',
            }),
          }}
          error={errors.description?.message?.toString()}
          fullwidth
        />{' '}
        <FlexRowBetween style={{ gap: '12px' }}>
          <StyledButton onClick={closeModal} type={'button'} variant={'outline'} fullwidth>
            Отменить
          </StyledButton>
          <StyledButton fullwidth>Подтвердить</StyledButton>
        </FlexRowBetween>
      </Form>
    </BaseModal>
  )
}
