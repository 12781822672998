import React from 'react'

import styled from 'styled-components/macro'

import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { StyledBtnSmall } from '../../../../shared/ui-kit/actual/StyledButton'
import {
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  MediumText,
  MediumTextPrimary,
  NeutralLink,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { dateToString } from '../../../../shared/utils/dateToString'
import { GoodsView } from '../GoodsView'
import { StepWrapper } from '../StepWrapper'

type Props = {
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}
const ContentWrapper = styled.div`
  width: fit-content;
  margin-top: 24px;
  border-radius: 11px;
  background-color: ${colors.lightGray};
  padding: 22px;
`

export const Step5 = ({ rentInfo, setStep }: Props) => {
  return (
    <StepWrapper>
      <FlexColumnStart>
        <H3>Товар проверен</H3>
        <MediumText>
          Аренда закончится: {dateToString(rentInfo?.periodTo)} <br /> Будьте аккуратны, приятного
          использования
        </MediumText>
        <GoodsView rentInfo={rentInfo} />
        <ContentWrapper>
          <FlexRow style={{ gap: '8px' }}>
            <img
              alt={'Close'}
              src={'/images/buttons/check-white-on-bg.svg'}
              style={{ height: 20, width: 20 }}
            />
            <MediumTextPrimary>Договор подписан</MediumTextPrimary>
          </FlexRow>
        </ContentWrapper>
      </FlexColumnStart>
      <FlexRowBetween style={{ gap: '8px' }}>
        <StyledButton onClick={() => setStep(prev => prev + 1)} fullwidth>
          Вернуть заказ
        </StyledButton>
        <NeutralLink style={{ width: 'min-content' }} to={`/messages/${rentInfo.rentId}`}>
          <StyledBtnSmall variant={'secondary'}>
            <img alt={''} src={'/images/buttons/Chat.svg'} style={{ height: 20, width: 20 }} />
          </StyledBtnSmall>
        </NeutralLink>
      </FlexRowBetween>
    </StepWrapper>
  )
}
