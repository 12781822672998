import React, { useContext, useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { useGetPaymentMethodsQuery } from '../../../../entities/payments/queries'
import { useTransactionsCreatePayoutMutation } from '../../../../entities/transactions/queries'
import { useGetUserProfileMutation } from '../../../../entities/userProfile/queries'
import {
  AddPayCardModal,
  CardsList,
  DeletePayCardModal,
  NoContent,
} from '../../../../shared/ui-kit/actual/components/PayCardsList'
import { symbols } from '../../../constants/symbols'
import { useModal } from '../../../hooks/useModal'
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import {
  Desc,
  FlexColumn,
  FlexColumnBetween,
  FlexRowGap,
  MediumText,
  StyledButton,
  StyledInput,
} from '../../projectComponents'
import { H3 } from '../../titles'
import { BlockTitle } from '../InfoButton'
import { StepsRow } from '../StepsRow'
import { BaseModal } from '../modals/BaseModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const Wrapper = styled(FlexColumnBetween)`
  min-height: 60vh;
`
const CardsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
`

const Box = styled.div`
  background: #f8f8fa;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 22px 0;
  .title {
    color: #008c8c;
    font-size: 24px;
    font-weight: 600;
    line-height: 25.36px;
    margin: 0;
    padding: 0;
  }
  .desc {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.04px;
    margin: 0;
    padding: 0;
    opacity: 0.2;
  }
`

export const Step1 = ({
  selectedCardNumber,
  selectedId,
  setSelectedCardNumber,
  setSelectedId,
  setStep,
}: {
  selectedCardNumber: string
  selectedId: string
  setSelectedCardNumber: (val: string) => void
  setSelectedId: (val: string) => void
  setStep: (val: number) => void
}) => {
  const { setViewModal: setViewAddModal, viewModal: viewAddModal } = useModal()
  const { setViewModal: setViewDeleteModal, viewModal: viewDeleteModal } = useModal()
  const handleOpenAddModal = () => setViewAddModal(true)
  const handleOpenDeleteModal = () => setViewDeleteModal(true)
  const { userId } = useContext(UserStorage)

  const { data, isLoading } = useGetPaymentMethodsQuery({ userId })

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <Wrapper>
        <H3>Выберете карту для вывода</H3>
        <MediumText>
          Обращаем ваше внимание на то что платформа Allsharing не является налоговым агентом.
        </MediumText>
        {data?.bankCards?.length ? (
          <>
            <CardsListWrapper>
              <CardsList
                data={data}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                setSelectedNumber={setSelectedCardNumber}
              />
              <FlexRowGap style={{ paddingTop: '16px' }}>
                <StyledButton
                  disabled={!selectedId}
                  onClick={handleOpenDeleteModal}
                  variant={'secondary'}
                  fullwidth
                >
                  Удалить
                </StyledButton>
                <StyledButton onClick={handleOpenAddModal} fullwidth>
                  Добавить
                </StyledButton>
              </FlexRowGap>
            </CardsListWrapper>
            <StyledButton disabled={!selectedId} onClick={() => setStep(1)} fullwidth>
              Далее
            </StyledButton>
          </>
        ) : (
          <>
            <NoContent />
            <StyledButton onClick={handleOpenAddModal} fullwidth>
              Добавить
            </StyledButton>
          </>
        )}
      </Wrapper>
      <AddPayCardModal setViewModal={setViewAddModal} viewModal={viewAddModal}></AddPayCardModal>
      <DeletePayCardModal
        selectedCardId={selectedId}
        selectedCardNumber={selectedCardNumber}
        setViewModal={setViewDeleteModal}
        viewModal={viewDeleteModal}
      ></DeletePayCardModal>
    </>
  )
}

const Step2 = ({
  selectedCardNumber,
  selectedId,
  setStep,
}: {
  selectedCardNumber: string
  selectedId: string
  setStep: (val: number) => void
}) => {
  const ProfileStore = useContext(ProfileStorage)

  // Нужно чтобы обновить баланс пользователя в профиле
  const { mutate: addUserProfile } = useGetUserProfileMutation({
    onError: error => {
      console.log('profile loading error', error)
    },
    onSuccess: data => {
      ProfileStore.setProfile(data)
    },
  })

  const [amount, setAmount] = useState<number | string>('')
  const handleSetAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
  }
  const { isError, isLoading, isSuccess, mutate } = useTransactionsCreatePayoutMutation()

  const createPayout = () => {
    mutate({ amount: +amount, bankCardId: selectedId })
  }

  useEffect(() => {
    isSuccess && setStep(2)
  }, [isSuccess, setStep])
  useEffect(() => {
    isError && setStep(3)
  }, [isError, setStep])

  return (
    <FlexColumnBetween style={{ minHeight: '60vh', padding: '24px 12px 12px' }}>
      <AbsoluteLoader isLoading={isLoading} />
      <H3>Введите сумму</H3>
      <br />
      <div style={{ flex: '1 0', width: '100%' }}>
        <Box>
          <FlexRowGap style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div>
              <h3 className={'title'}>{ProfileStore.profile.balanceUser.accountBalance} ₽</h3>
              <p className={'desc'}>Баланс</p>
            </div>
            <StyledButton
              onClick={() => {
                addUserProfile({ userId: ProfileStore?.userInfo?.id })
              }}
              size={'sm'}
            >
              Обновить
            </StyledButton>
          </FlexRowGap>
        </Box>
        <br />
        <MediumText>
          На карту <strong>{selectedCardNumber}</strong>
        </MediumText>
        <br />
        <StyledInput
          max={ProfileStore.profile.balanceUser.accountBalance}
          min={0}
          onChange={handleSetAmount}
          placeholder={'Сумма вывода в рублях'}
          style={{ borderRadius: '10px', height: '30px', padding: '20px', width: '95% ' }}
          type={'number'}
          value={amount}
        />{' '}
        {symbols.ruble}
        <br />
      </div>
      <br />
      <StyledButton
        disabled={
          isLoading ||
          !amount ||
          +amount <= 0 ||
          +amount > ProfileStore.profile.balanceUser.accountBalance
        }
        onClick={createPayout}
        fullwidth
      >
        Вывести
      </StyledButton>
    </FlexColumnBetween>
  )
}

export const Step3 = ({
  setViewModal,
}: {
  setViewModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <FlexColumnBetween style={{ minHeight: '60vh', padding: '24px 12px 12px' }}>
      {/* <button onClick={() => setCurrentStage(5)}>Ошибка заказа</button> */}
      <FlexColumn style={{ flex: 1, marginBottom: '50px', width: '100%' }}>
        <img alt={''} height={40} src={'/images/buttons/checkbox-active.svg'} width={40} />
        <BlockTitle topSpace={'sm'}>Успешно!</BlockTitle>
        <br />
        <Desc style={{ color: '#838589', textAlign: 'center' }}>
          Скоро деньги поступят на указанный счет
        </Desc>
      </FlexColumn>
      <StyledButton
        onClick={() => setViewModal(false)}
        style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
        variant={'primary'}
      >
        К профилю
      </StyledButton>
    </FlexColumnBetween>
  )
}

export const Step4 = ({
  setViewModal,
}: {
  setViewModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <FlexColumnBetween style={{ minHeight: '60vh', padding: '24px 12px 12px' }}>
      {/* <button onClick={() => setCurrentStage(5)}>Ошибка заказа</button> */}
      <FlexColumn style={{ flex: 1, marginBottom: '50px', width: '100%' }}>
        <img alt={''} height={40} src={'/images/interface/close-err.svg'} width={40} />
        <BlockTitle topSpace={'sm'}>Что-то пошло не так</BlockTitle>
        <br />
        <Desc style={{ color: '#838589', textAlign: 'center' }}>
          Попробуйте еще раз. Проверьте <br />
          правильность введенных данных
        </Desc>
      </FlexColumn>
      <StyledButton
        onClick={() => setViewModal(false)}
        style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
        variant={'primary'}
      >
        К профилю
      </StyledButton>
    </FlexColumnBetween>
  )
}

const PayoutIndividualModal = ({
  setViewModal,
  viewModal,
}: {
  setViewModal: React.Dispatch<React.SetStateAction<boolean>>
  viewModal: boolean
}) => {
  const [step, setStep] = useState(0)
  const [selectedId, setSelectedId] = useState('')
  const [selectedCardNumber, setSelectedCardNumber] = useState('')

  useEffect(() => {
    if (!viewModal) {
      setStep(0)
      setSelectedId('')
      setSelectedCardNumber('')
    }
  }, [viewModal])

  return (
    <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
      {step < 2 && <StepsRow activeStep={step} totalSteps={2} />}

      {
        [
          <Step1
            key={step}
            selectedCardNumber={selectedCardNumber}
            selectedId={selectedId}
            setSelectedCardNumber={setSelectedCardNumber}
            setSelectedId={setSelectedId}
            setStep={setStep}
          />,
          <Step2
            key={step}
            selectedCardNumber={selectedCardNumber}
            selectedId={selectedId}
            setStep={setStep}
          />,
          <Step3 key={step} setViewModal={setViewModal} />,
          <Step4 key={step} setViewModal={setViewModal} />,
        ][step]
      }
    </BaseModal>
  )
}

export default observer(PayoutIndividualModal)
