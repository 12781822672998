import React, { useState } from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { IResponsePasport } from '../../../api/deprecated/documentService/DocumentService'
import { FlexColumn, FlexColumnStart, FlexRow } from '../../projectComponents'
import PassportModal from './PassportModal'

const Block = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  justify-content: start;
  height: 150px;

  p {
    font-size: 15px;
    margin: 0;
    text-align: start;
    width: 100%;
  }
`
const PassportImg = styled.img`
  width: 100px;
  height: 100px;
`

interface PassportComponentProps {
  passport: IResponsePasport
  refetch: () => void
}

export const PassportComponent: React.FC<PassportComponentProps> = ({ passport, refetch }) => {
  const getDate = (date: string) => format(new Date(date), 'dd MMMM HH:mm', { locale: ru })
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <>
      {modalOpened && (
        <PassportModal passport={passport} refetch={refetch} setOpen={setModalOpened} />
      )}
      <Block onClick={() => setModalOpened(true)}>
        <p style={{ fontSize: 14, marginBottom: 16, marginTop: 5, textAlign: 'center' }}>
          {getDate(passport.registeredAt)}
        </p>
        <FlexRow style={{ justifyContent: 'start', width: '100%' }}>
          <PassportImg alt={''} src={passport.passport.links[0]} style={{ marginRight: 20 }} />
          <FlexColumnStart>
            <p>{passport.lastName}</p>
            <p>{passport.firstName}</p>
            <p>{passport.middleName}</p>
          </FlexColumnStart>
        </FlexRow>
      </Block>
    </>
  )
}
