import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

const ArrowImage = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
`

interface BottomMenuComponentProps {
  link?: string
}

export const ArrowForwardButton: React.FC<BottomMenuComponentProps> = ({ link }) => {
  const navigate = useNavigate()

  return (
    <ArrowImage
      onClick={() => {
        link && navigate(link)
      }}
    >
      <img alt={'arrow-back'} src={'/images/buttons/arrow-right-black.svg'} />
    </ArrowImage>
  )
}
