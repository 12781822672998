import { ComponentPropsWithoutRef, FC, ReactNode } from 'react'

import styled from 'styled-components/macro'

import { devices } from '../../breakPoints'
import { FlexColumn } from '../../projectComponents'
import { BottomMenuComponent } from '../BottomMenuComponent'

const Wrapper = styled(FlexColumn)`
  overflow-y: scroll;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 92px;
  @media screen and (${devices.desktopMin}) {
    margin-bottom: 0;
  }
`

interface PageLayoutProps {
  body?: ReactNode
  footer?: ReactNode
  head?: ReactNode
  withBottomMenu?: boolean
}

const PageLayout: FC<ComponentPropsWithoutRef<'div'> & PageLayoutProps> = ({
  body,
  footer,
  head,
  withBottomMenu = false,
  ...wraperProps
}) => {
  return (
    <Wrapper {...wraperProps}>
      {head}
      {body}
      {footer}
      {withBottomMenu && <BottomMenuComponent />}
    </Wrapper>
  )
}

export default PageLayout
