import { useNavigate, useSearchParams } from 'react-router-dom'

export const useQueryString = (query: string, baseUrl?: string) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const value = searchParams.get(query) || ''
  const setValue = (value?: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    if (value) {
      updatedSearchParams.set(query, value)
    } else {
      updatedSearchParams.set(query, '')
    }
    if (baseUrl) {
      navigate(`${baseUrl}?${updatedSearchParams.toString()}`)
    } else {
      setSearchParams(updatedSearchParams.toString())
    }
  }

  return { setValue, value }
}
