import { formatDistance } from 'date-fns'
import styled from 'styled-components/macro'

import { useGetMarketItemQuery } from '../../../entities/marketItem/queries'
import { useGetUserProfileQuery } from '../../../entities/userProfile/queries'
import { ReviewListItemDto } from '../../../shared/api/generated'
import { colors } from '../../../shared/constants/colors'
import ProfileSnippet, { ProfileSnippetVariant } from '../../../shared/ui-kit/ProfileSnippet'
import { ArrowForwardButton } from '../../../shared/ui-kit/actual/ArrowForwardButton'
import { devices } from '../../../shared/ui-kit/breakPoints'
import {
  FlexColumn,
  FlexRowBetween,
  FlexRowStart,
  MediumText,
} from '../../../shared/ui-kit/projectComponents'
import { H5 } from '../../../shared/ui-kit/titles'
import { getDateAgo } from '../../../shared/utils/dateAgo'

const CommentHeader = styled(FlexRowBetween)`
  align-items: last baseline;
  margin-bottom: 10px;
  width: 100%;
  span {
    font-size: 12px;
    font-weight: 400;
    color: ${colors.grey};
    min-width: 95px;
  }
`
const ReviewImage = styled.img`
  height: 76px;
  width: 76px;
  border-radius: 8px;
  object-fit: cover;
`
const ItemInfoWrapper = styled(FlexRowBetween)`
  align-items: center;
  width: 100%;
  /* @media screen and (${devices.desktopMin}) {
    max-width: 350px;
  } */
`

export const RenterReviewCard = ({
  isBuyer,
  review,
}: {
  isBuyer?: boolean
  review: ReviewListItemDto
}) => {
  // const { data: userInfo } = useGetUserProfileQuery(isBuyer ? review.sellerId : review.buyerId)
  // TODO: добавить картинку товара после обновы на бэке

  const userData = {
    avatarLink: isBuyer ? review.sellerAvatarImageUrl : review.buyerAvatarImageUrl,
    fullName: isBuyer ? review.sellerFullName : review.buyerFullName,
    id: isBuyer ? review.sellerId : review.buyerId,
    isSubscribed: false,
    userName: isBuyer ? review.sellerUserName : review.buyerUserName,
  }

  // const { data } = useGetMarketItemQuery({ productId: review?.productId })

  return (
    <FlexColumn
      style={{
        alignItems: 'start',
        borderBottom: 'solid 1px #acacac',
        paddingBottom: '12px',
        width: '100%',
      }}
    >
      <CommentHeader>
        <ProfileSnippet
          currentUser={userData}
          rating={review.rating}
          variant={ProfileSnippetVariant.minimized}
        />
        <span style={{ textAlign: 'right' }}>{getDateAgo(review?.createOn)}</span>
      </CommentHeader>

      <MediumText style={{ marginBottom: 16, textAlign: 'left', width: '100%' }}>
        {review?.content}
      </MediumText>

      <ItemInfoWrapper>
        <FlexRowStart style={{ gap: '16px' }}>
          <ReviewImage alt={'renter-review'} src={review?.productImagesUrls?.[0]} />
          <div>
            <H5>{review?.productName}</H5>
          </div>
        </FlexRowStart>
        <ArrowForwardButton link={`/item/${review?.productId}`} />
      </ItemInfoWrapper>
    </FlexColumn>
  )
}
