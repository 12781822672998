import { useState } from 'react'

import styled from 'styled-components/macro'

import { useModal } from '../../../shared/hooks/useModal'
import { NoProducts } from '../../../shared/ui-kit/actual/NoProducts'
import { BaseModal } from '../../../shared/ui-kit/actual/modals/BaseModal'
import {
  FlexColumn,
  FlexColumnBetween,
  FlexRowGap,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { DocsBusinessAddForm } from './DocsBusinessAddForm'
import { DocsBusinessAddStatus } from './DocsBusinessAddStatus'
import PassportAddForm from './PassportAddForm'
import { PassportAddStatus } from './PassportAddStatus'

const Wrapper = styled(FlexColumnBetween)``

const ListWrapper = styled(FlexColumn)`
  width: 100%;
  overflow: auto;
  column-gap: 8px;
  padding-bottom: 4px;
`
const verifiedBgColors = { pending: '#FAFAF8', rejected: '#FFF4F4', success: '#F8F8FA' }
const verifiedColors = { pending: '#898C00', rejected: '#FD7163', success: '#008C8C' }
const verifiedImages = {
  pending: '/images/buttons/pending-clock.svg',
  rejected: '/images/buttons/reject.svg',
  success: '/images/buttons/check-white-on-bg.svg',
}
const verifiedTexts = {
  pending: 'В процессе верификации',
  rejected: 'Отказано в верификации',
  success: 'Верифицирован ',
}

const VerifiedSign = styled(FlexRowGap)<{ $verifiedState: string }>`
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: fit-content;
  height: 50px;
  padding: 10px 18px 10px 18px;
  border-radius: 15px;
  color: ${({ $verifiedState }) => verifiedColors[$verifiedState]};
  background-color: ${({ $verifiedState }) => verifiedBgColors[$verifiedState]};
  gap: 8px;
`
const VerifiedText = styled.div`
  font-size: 16px;
  font-weight: 600;
`
const NoContentWrapper = styled(FlexColumnBetween)`
  width: 100%;
  height: 100%;
  row-gap: 8px;
`
const mockData = {
  passportImg: '/images/mock-items/my-profile/passport-example.png',
  verifiedState: 'pending',
}
// const mockData = { passportImg: '/images/mock-items/my-profile/passport-example.png', verifiedState: 'pending' }
// const mockData = { passportImg: '/images/mock-items/my-profile/passport-example.png', verifiedState: 'rejected' }

// const mockData = null

const CardList = ({ data: { passportImg, verifiedState }, setViewModal }) => (
  <>
    <ListWrapper>
      <div style={{ position: 'relative' }}>
        <img alt={''} src={passportImg} />
        <VerifiedSign $verifiedState={verifiedState}>
          <img alt={''} src={verifiedImages[verifiedState]} />
          <VerifiedText>{verifiedTexts[verifiedState]}</VerifiedText>
        </VerifiedSign>
      </div>
    </ListWrapper>

    <StyledButton
      onClick={() => setViewModal(true)}
      style={{ marginTop: '16px' }}
      variant={'secondary'}
      fullwidth
    >
      Изменить
    </StyledButton>
  </>
)
const NoContent = ({ setViewModal }) => (
  <NoContentWrapper>
    <NoProducts
      imgPath={'/images/interface/passport-transparent.svg'}
      message={'Вы еще не добавили регистрационные документы'}
    />
    <StyledButton onClick={() => setViewModal(true)} fullwidth>
      Добавить
    </StyledButton>
  </NoContentWrapper>
)

export type PassportAddStepStatus = 'edit' | 'error' | 'success'
export const PersonalDataBusinessDocs = () => {
  const { setViewModal, viewModal } = useModal()
  const [status, setStatus] = useState<PassportAddStepStatus>('edit')

  return (
    <>
      <Wrapper>
        {!mockData ? (
          <CardList data={mockData} setViewModal={setViewModal} />
        ) : (
          <NoContent setViewModal={setViewModal} />
        )}
      </Wrapper>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        {status === 'edit' ? (
          <DocsBusinessAddForm setStatus={setStatus} />
        ) : (
          <DocsBusinessAddStatus
            setStatus={setStatus}
            setViewModal={setViewModal}
            status={status}
          />
        )}
      </BaseModal>
    </>
  )
}
