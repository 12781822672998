import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { useDisableBodyScroll } from '../../../hooks/use-disable-body-scroll'
import { devices } from '../../breakPoints'
import { CloseModalBtn, FlexColumn, FlexColumnStart } from '../../projectComponents'
import { verticalScroll } from '../../scroll'

export const ModalBackground = styled.div<{ $viewModal: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 103vh;
  height: 103lvh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: flex-end;
  justify-content: center;
  z-index: 2100;
  transition: 0.4s ease-in;
  visibility: ${({ $viewModal }) => ($viewModal ? '' : 'hidden')};
  opacity: ${({ $viewModal }) => ($viewModal ? '1' : '0')};

  & > div {
    transform: ${({ $viewModal }) => ($viewModal ? 'translateY(0)' : 'translateY(100vh)')};
    transition: 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  }
  @media screen and (${devices.tabletMin}) {
    height: 100vh;
    height: 100lvh;
    & > div {
      transform: ${({ $viewModal }) => ($viewModal ? 'translateY(0)' : 'translateY(100vh)')};
    }
  }
`
const ModalFullHeight = styled(FlexColumn)`
  width: clamp(300px, 100%, 600px);
  height: 103vh;
  height: 103lvh;
  justify-content: flex-end;
  @media screen and (${devices.tabletMin}) {
    justify-content: center;
    height: 100vh;
    height: 100lvh;
  }
`

export const ModalWrapper = styled(FlexColumnStart)`
  position: relative;
  width: clamp(300px, 100%, 600px);
  border-radius: 24px 24px 0 0;
  background-color: ${colors.white};
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  height: 93vh;
  height: 93lvh;
  justify-content: flex-start;
  padding: 24px 0 48px;
  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 12px;
    justify-content: flex-start;
    align-items: stretch;
    margin: 16px 0 72px;
    gap: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    ${verticalScroll}
    @media screen and (${devices.tabletMin}) {
      padding: 36px;
      margin: 16px 0;
    }
  }
  @media screen and (${devices.tabletMin}) {
    height: fit-content;
    max-height: 90vh;
    border-radius: 24px;
    padding: 24px 0 24px;
  }
`

type Props = {
  children?: ReactNode
  height?: string
  setViewModal: (viewModal: boolean) => void
  style?: React.CSSProperties
  viewModal: boolean
  width?: string
}

const preventClick = e => {
  e.stopPropagation()
}

export const BaseModal = ({ children, height, setViewModal, style, viewModal, width }: Props) => {
  useDisableBodyScroll(viewModal)

  return createPortal(
    <ModalBackground
      onClick={() => {
        setViewModal(false)
      }}
      $viewModal={viewModal}
      style={style}
    >
      <ModalFullHeight>
        <ModalWrapper onClick={preventClick} style={{ height: `${height}px`, width: `${width}px` }}>
          <>
            <div>{children}</div>
            <CloseModalBtn
              onClick={() => {
                setViewModal(false)
              }}
            >
              <img
                alt={'Close'}
                src={'/images/buttons/close.png'}
                style={{ height: 20, width: 20 }}
              />
            </CloseModalBtn>
          </>
        </ModalWrapper>
      </ModalFullHeight>
    </ModalBackground>,
    document.body
  )
}
