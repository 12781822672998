import React, { useState } from 'react'

import styled from 'styled-components/macro'

import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { TextArea } from '../../../../shared/ui-kit/actual/TextArea'
import {
  Dot,
  DotsWrapper,
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  ImageWrapper,
  MediumText,
  MediumTextL,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'

type Props = {
  back: () => void
  rentInfo: GetRentInfoResponse
}

export const Step9 = ({ back, rentInfo }: Props) => {
  const [comment, setComment] = useState('')
  const [activeId, setActiveId] = useState(0)
  const [isSending, setIsSending] = useState(false)
  const images = rentInfo?.beforeRentBuyerImagesUrls
  const handleToggleImage = () => {
    if (activeId < images.length - 1) {
      setActiveId(activeId + 1)
    } else {
      setActiveId(0)
    }
  }

  return (
    <>
      <FlexColumn style={{ alignItems: 'center' }}>
        <img
          alt={''}
          src={'/images/interface/close-err.svg'}
          style={{ height: 40, marginTop: '100px', width: 40 }}
        />
        <br />
        <H2>Арендатор выявил дефекты</H2>
        {/* <br />
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Дырка на левом колене, также торчат нитки из всех швов. Качество товара не соответствует
          заявленному в описании
        </MediumText> */}
        <br />
        <ImageWrapper onClick={handleToggleImage}>
          <img alt={''} src={images[activeId]} />
        </ImageWrapper>
        <br />
        <DotsWrapper>
          {images.map((_, idx) => (
            <Dot $selected={idx === activeId} key={idx} onClick={() => setActiveId(idx)} />
          ))}
        </DotsWrapper>
        <br />
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Оставьте свой комментарий, если вы не согласны с жалобой и платформа его рассмотрит
        </MediumText>
        <TextArea
          inputProps={{
            placeholder: 'Комментарий',
            rows: 7,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
          style={{ margin: '8px', width: '100%' }}
          value={comment}
          fullwidth
        />
        <br />
        <StyledButton
          disabled={!comment || isSending}
          onClick={() => setIsSending(true)}
          style={{ marginBottom: '16px' }}
          variant={'secondary'}
          fullwidth
        >
          Оставить комментарий
        </StyledButton>
        <StyledButton onClick={back} fullwidth>
          На главную
        </StyledButton>
      </FlexColumn>
    </>
  )
}
