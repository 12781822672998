import { Link } from 'react-router-dom'

import { Logo } from '../projectComponents'

export const LogoAllSharing = () => {
  return (
    <Link style={{ paddingTop: '3px' }} to={'/'}>
      <Logo alt={''} src={'/images/allsharinglogo.svg'} />
    </Link>
  )
}
