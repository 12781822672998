import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import {
  useGetArchivedProductsList,
  useGetProductsListQuery,
} from '../../../../entities/productsList/queries'
import FilterStorage from '../../../../shared/storage/FilterStorage/FilterStorage'
import { PaginationComponent } from '../../../../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { H3 } from '../../../../shared/ui-kit/titles'
import { useProfileInfo } from '../../model/use-profile-info'
import { GoodsDesktopList } from '../GoodsDesktopList'
import { MessengerBubbleButton } from '../MessengerBubbleButton'

const PaginationWrapper = styled.div`
  margin-top: 32px;

  @media all and (max-width: 800px) {
    margin-bottom: 35px;
  }
`

export const ProfileGoods = () => {
  const navigate = useNavigate()

  const { userInfo } = useProfileInfo()

  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const { data, isLoading } = useGetProductsListQuery({
    ownerId: userInfo.id,
    skip: page * itemsPerPage,
    take: itemsPerPage,
  })
  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )

  const [archivePage, setArchivePage] = useState(0)
  const [archiveItemsPerPage, setArchiveItemsPerPage] = useState(12)
  const { data: archiveData, isLoading: isArchiveLoading } = useGetArchivedProductsList({
    ownerId: userInfo.id,
    skip: archivePage * archiveItemsPerPage,
    take: archiveItemsPerPage,
  })

  const totalArchivePages = useMemo(
    () => (archiveData ? Math.ceil(archiveData?.totalCount / archiveItemsPerPage) : 1),
    [archiveData, archiveItemsPerPage]
  )

  return (
    <>
      <AbsoluteLoader isLoading={isLoading || isArchiveLoading} />

      {/* <BtnWrapper>
        <BtnRowWrapper>
          <StyledButton onClick={() => navigate('/profile/rent')} fullwidth>
            Сдать в аренду
          </StyledButton>
          <StyledBtnSmall>
            <img alt={''} src={'/images/buttons/Calendar.svg'} />
          </StyledBtnSmall>
          <StyledBtnSmall variant={'secondary'}>
            <img alt={''} src={'/images/buttons/exclamation.svg'} />
          </StyledBtnSmall>
        </BtnRowWrapper>
        <BtnRowWrapper>
          <StyledButton variant={'secondary'} fullwidth>
            Запросы аренды
          </StyledButton>
          <StyledBtnSmall onClick={() => navigate('/')} variant={'secondary'}>
            <img alt={''} src={'/images/buttons/HandbagSimple.svg'} />
          </StyledBtnSmall>
        </BtnRowWrapper>
      </BtnWrapper>
      <MainSearchComponent onClickFilter={handleFilter} setIsFixed={() => null} noLogo />
      <SortModalWindow
        handleMenu={handleFilter}
        openMenu={openMenu}
        selectedSort={orderType}
        setSelectedSort={setOrderBy}
      /> */}
      {data && (
        <GoodsDesktopList items={data.items} messageNoProducts={'У вас еще нет объявлений'} />
      )}
      <PaginationWrapper>
        <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
      </PaginationWrapper>
      {archiveData && (
        <>
          <H3>Архивные объявления</H3>
          <GoodsDesktopList
            items={archiveData?.items}
            messageNoProducts={'У вас еще нет архивных объявлений'}
            isArchive
          />
        </>
      )}
      <PaginationWrapper>
        <PaginationComponent
          maxPages={totalArchivePages}
          pageId={archivePage}
          setPageId={setArchivePage}
        />
      </PaginationWrapper>
      {/* <FrequentlyViewHead
        imageLink={frequentlyProducts[0].imageLinks[0]}
        name={frequentlyProducts[0].name}
      />
      {frequentlyProducts && data && (
        <GoodsDesktopList
          goods={frequentlyProducts}
          isLoading={isLoading}
          items={[]}
          messageNoProducts={'У вас еще нет данных Товаров'}
        />
      )} */}

      {/* <StyledLinkPrimary style={{ whiteSpace: 'nowrap' }} to={'/add'}>
        Добавить объявление
      </StyledLinkPrimary> */}
      <MessengerBubbleButton content={<div>+</div>} onClick={() => navigate('/add')} />
    </>
  )
}
export const ProfileGoodsBody = observer(ProfileGoods)
