import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { GetRentInfoResponse, ReviewType } from '../../../../shared/api/generated'
import { TextArea } from '../../../../shared/ui-kit/actual/TextArea'
import {
  Dot,
  DotsWrapper,
  FlexColumn,
  ImageWrapper,
  MediumText,
  MediumTextL,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H2, H3, H4 } from '../../../../shared/ui-kit/titles'
import { FeedbackForm } from '../FeedbackForm'

type Props = {
  back: () => void
  rentInfo: GetRentInfoResponse
}

export const Step12 = ({ back, rentInfo }: Props) => {
  // const [rating, setRating] = useState(0)
  // const rentIdNumber = parseInt(rentId)
  const [comment, setComment] = useState('')
  const { rentId } = useParams()
  const [activeId, setActiveId] = useState(0)
  const [isSending, setIsSending] = useState(false)
  const images = rentInfo?.afterRentSellerImagesUrls
  const handleToggleImage = () => {
    if (activeId < images.length - 1) {
      setActiveId(activeId + 1)
    } else {
      setActiveId(0)
    }
  }

  return (
    <>
      <FlexColumn style={{ alignItems: 'center' }}>
        <img
          alt={''}
          src={'/images/interface/close-err.svg'}
          style={{ height: 40, marginTop: '100px', width: 40 }}
        />
        <br />
        <H4 style={{ textAlign: 'center' }}>Арендодатель выявил дефекты после использования</H4>
        <MediumTextL style={{ textAlign: 'center' }}>
          Не переживайте, платформа объективно рассмотрит его обращение
        </MediumTextL>
        <br />
        <ImageWrapper onClick={handleToggleImage}>
          <img alt={''} src={images[activeId]} />
        </ImageWrapper>
        <br />
        <DotsWrapper>
          {images.map((_, idx) => (
            <Dot $selected={idx === activeId} key={idx} onClick={() => setActiveId(idx)} />
          ))}
        </DotsWrapper>
        <br />
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Оставьте свой комментарий, если вы не согласны с жалобой и платформа его рассмотрит
        </MediumText>
        <br />
        <TextArea
          inputProps={{
            placeholder: 'Комментарий',
            rows: 7,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
          style={{ margin: '8px', width: '100%' }}
          value={comment}
          fullwidth
        />
        <br />

        <StyledButton
          disabled={!comment || isSending}
          onClick={() => setIsSending(true)}
          style={{ marginBottom: '16px' }}
          variant={'secondary'}
          fullwidth
        >
          Оставить комментарий
        </StyledButton>
        <StyledButton onClick={back} fullwidth>
          На главную
        </StyledButton>
      </FlexColumn>
    </>
  )
}
