import { Link } from 'react-router-dom'

import Linkify from 'linkify-react'
import styled from 'styled-components/macro'

import { useGetUserProfileQuery } from '../../../../entities/userProfile/queries'
import { ChatMessageExtended } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { formatedTime } from '../../../../shared/utils/formatedTime'
import MessageStatus from '../MessageStatus/MessageStatus'

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media all and (max-width: 800px) {
    padding: 0 24px 0 24px;
  }
`

const ChatMessageContainer = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  justify-content: ${({ isMyMessage }) => (isMyMessage ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
  position: relative;
  gap: 8px;
`

const MessageBubble = styled.div<{ isMyMessage: boolean }>`
  background-color: ${({ isMyMessage }) => (isMyMessage ? colors.secondary : colors.lightGray)};
  color: ${({ isMyMessage }) => (isMyMessage ? colors.primary : colors.black)};
  padding: 18px 20px;
  border-radius: 8px;
  min-width: 80px;
  max-width: 80vw;
  position: relative;
  display: flex;
  flex-direction: column;
`

const MessageText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 8px;
  word-wrap: break-word;
`

const MessageImg = styled.img`
  width: auto;
  min-width: 40vw;
  object-fit: contain;
  height: fit-content;
  margin-bottom: 8px;
  border-radius: 8px;
`

const Avatar = styled.img<{ isMyMessage: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`

const MessageInfo = styled.div`
  position: absolute;
  bottom: 8px;
  right: 15px;
  display: flex;
  align-items: center;
`

const TimeStamp = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #ababab;
  margin-left: 2px;
`

interface MessageInfoProps {
  messageInfo: ChatMessageExtended
}

const Message = ({ messageInfo }: MessageInfoProps) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const { data } = useGetUserProfileQuery(user?.UserId)

  const isMyMessage = messageInfo?.senderId?.trim().toLowerCase() === data?.id?.trim().toLowerCase()

  return (
    <MessagesContainer>
      <ChatMessageContainer isMyMessage={isMyMessage}>
        {!isMyMessage && (
          <Link to={`/users/${messageInfo?.senderId}`}>
            <Avatar
              alt={'Avatar'}
              isMyMessage={isMyMessage}
              src={messageInfo?.senderAvatarUrl || '/images/bottom-menu/profile-light.svg'}
            />
          </Link>
        )}
        <MessageBubble isMyMessage={isMyMessage}>
          <MessageText>
            <Linkify options={{ target: '_blank ' }}>{messageInfo?.message}</Linkify>
          </MessageText>
          {messageInfo?.filesUrls[0] && (
            <MessageImg alt={'File'} src={messageInfo?.filesUrls[0]} width={'auto'} />
          )}
          <MessageInfo>
            {isMyMessage && <MessageStatus messageStatus={messageInfo?.isViewed} />}
            <TimeStamp>{formatedTime(messageInfo?.messageDate)}</TimeStamp>
          </MessageInfo>
        </MessageBubble>
        {isMyMessage && (
          <Avatar
            alt={'Avatar'}
            isMyMessage={isMyMessage}
            src={data?.avatarLink || '/images/bottom-menu/profile-light.svg'}
          />
        )}
      </ChatMessageContainer>
    </MessagesContainer>
  )
}

export default Message
