import { FC, useState } from 'react'

import { GetProductInfoResponse, RentProductDeliveryType } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { useAddresses } from '../../../../shared/hooks/use-addresses'
import { BlockTitle } from '../../../../shared/ui-kit/actual/InfoButton'
import { InfoDefects } from '../../../../shared/ui-kit/actual/InfoDefects'
import { Paragraph } from '../../../../shared/ui-kit/actual/Paragraph'
import { CheckBox } from '../../../../shared/ui-kit/actual/checkbox'
import { LocationInfo } from '../../../../shared/ui-kit/actual/components/LocationInfo'
import {
  Desc,
  FlexColumn,
  FlexColumnBetween,
  FlexRowBetween,
  NeutralLink,
  StyledButton,
  SubTitle,
  Title,
} from '../../../../shared/ui-kit/projectComponents'
import { ProductInfo } from '../components/ProductsInfo'

const ThirdStep: FC<{
  currentStage: number
  deliveryAddressId: number
  deliveryType: RentProductDeliveryType
  handleCreateRent: () => void
  price: number
  productInfo: GetProductInfoResponse
  setCurrentStage: (it: number) => void
}> = ({ currentStage, deliveryAddressId, deliveryType, price, productInfo, setCurrentStage }) => {
  const [condition, setCondition] = useState(false)
  const [conditionPublic, setConditionPublic] = useState(false)
  const { addresses } = useAddresses()
  const defaultAddress = addresses.find(address => deliveryAddressId === address.id)

  return (
    <>
      <div style={{ width: '100%' }}>
        <Title style={{ marginBottom: '24px' }}>Информация о заказе</Title>
        <ProductInfo
          img={productInfo?.imagesUrls?.[0] || `/images/interface/EmptyBag.svg`}
          name={productInfo?.name}
          price={productInfo?.pledge}
        />
        {deliveryType === 'delivery' && (
          <>
            <BlockTitle
              bottomSpace={'sm'}
              style={{ marginBottom: '8px', width: '100%' }}
              topSpace={'xl'}
            >
              Доставка
            </BlockTitle>
            {<LocationInfo pickupAddress={defaultAddress} />}
          </>
        )}
        {deliveryType === 'pickup' && (
          <>
            <BlockTitle
              bottomSpace={'sm'}
              style={{ marginBottom: '8px', width: '100%' }}
              topSpace={'xl'}
            >
              Самовывоз
            </BlockTitle>
            {<LocationInfo pickupAddress={productInfo?.pickupAddress} />}
          </>
        )}
      </div>
      <div style={{ paddingBottom: '64px', width: '100%' }}>
        <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
          К оплате
        </BlockTitle>
        <FlexColumn style={{ borderBottom: '1px solid #E0E5E6', gap: '8px', paddingBottom: '8px' }}>
          <FlexRowBetween>
            <SubTitle style={{ color: '#6E6E70' }}>Возвращаемый залог</SubTitle>
            <Desc>{productInfo?.pledge}₽</Desc>
          </FlexRowBetween>
          <FlexRowBetween>
            <SubTitle style={{ color: '#6E6E70' }}>Стоимость аренды</SubTitle>
            <Desc>{price}₽</Desc>
          </FlexRowBetween>
          <FlexRowBetween>
            <SubTitle>Стоимость доставки</SubTitle>
            <Desc style={{ color: '#008C8C' }}>не определена</Desc>
          </FlexRowBetween>
        </FlexColumn>
        <FlexRowBetween style={{ marginBottom: '12px', marginTop: '24px' }}>
          <Title>Итого</Title>
          <Desc style={{ color: '#008C8C' }}>{productInfo?.pledge + price} ₽</Desc>
        </FlexRowBetween>

        <CheckBox
          label={
            <Paragraph>
              С{' '}
              <InfoDefects
                content={
                  productInfo?.defectFines?.length > 0 && (
                    <>
                      <FlexColumnBetween style={{ gap: '8px', marginTop: '16px' }}>
                        {productInfo?.defectFines?.map((defect, idx) => (
                          <FlexRowBetween key={idx} style={{ alignItems: 'center', gap: '24px' }}>
                            {' '}
                            <Paragraph style={{ color: colors.black, maxWidth: '85%' }}>
                              {defect.defectName}
                            </Paragraph>{' '}
                            <Paragraph
                              style={{
                                color: colors.primary,
                                fontWeight: 600,
                                whiteSpace: 'nowrap',
                                width: 'max-content',
                              }}
                            >
                              {defect.amount} ₽
                            </Paragraph>
                          </FlexRowBetween>
                        ))}
                      </FlexColumnBetween>
                    </>
                  )
                }
                title={'условиями'}
              />{' '}
              по дефектам ознакомлен и согласен{' '}
            </Paragraph>
          }
          checked={condition}
          setChecked={setCondition}
        />
        <br />
        <CheckBox
          label={
            <Paragraph>
              С{' '}
              <NeutralLink
                style={{ color: '#008C8C' }}
                target={'_blank'}
                to={'https://landing.allsharing.su/rules/terms-of-use-ru.html'}
              >
                условиями
              </NeutralLink>{' '}
              публичной оферты согласен
            </Paragraph>
          }
          checked={conditionPublic}
          setChecked={setConditionPublic}
        />

        <StyledButton
          disabled={!condition || !conditionPublic}
          onClick={() => setCurrentStage(currentStage <= 3 ? currentStage + 1 : currentStage)}
          style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
          variant={'primary'}
        >
          Далее
        </StyledButton>
      </div>
    </>
  )
}

export default ThirdStep
