import { Dispatch, SetStateAction } from 'react'

import { RentListItem } from '../../../shared/api/generated'
import { PaginationComponent } from '../../../shared/ui-kit/actual/PaginationComponent'
import { ProfileSwitchTabs } from '../../../shared/ui-kit/actual/ProfileSwitchTabs'
import { WrapperMobile } from '../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../shared/ui-kit/titles'
import { ArchiveOrdersBuyer, ArchiveOrdersSeller } from './ArchiveOrders'
import { CurrentOrders } from './CurrentOrders'

type Props = {
  active: RentListItem[]
  activeSale: RentListItem[]
  activeTab: number
  buyerActivePage: number
  buyerFinishedPage: number
  finished: RentListItem[]
  finishedSale: RentListItem[]
  sellerActivePage: number
  sellerFinishedPage: number
  setActiveTab: (activeTab: number) => void
  setBuyerActivePage: Dispatch<SetStateAction<number>>
  setBuyerFinishedPage: Dispatch<SetStateAction<number>>
  setSellerActivePage: Dispatch<SetStateAction<number>>
  setSellerFinishedPage: Dispatch<SetStateAction<number>>
  totalBuyerActivePages: number
  totalBuyerFinishedPages: number
  totalSellerActivePages: number
  totalSellerFinishedPages: number
}

export const OrdersBodyWrapper = ({
  active,
  activeSale,
  activeTab,
  buyerActivePage,
  buyerFinishedPage,
  finished,
  finishedSale,
  sellerActivePage,
  sellerFinishedPage,
  setActiveTab,
  setBuyerActivePage,
  setBuyerFinishedPage,
  setSellerActivePage,
  setSellerFinishedPage,
  totalBuyerActivePages,
  totalBuyerFinishedPages,
  totalSellerActivePages,
  totalSellerFinishedPages,
}: Props) => {
  return (
    <WrapperMobile>
      <ProfileSwitchTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabHead0={'Текущие'}
        tabHead1={'Архив'}
      />
      {!activeTab ? (
        <>
          <H2>В аренде у Вас</H2>
          <CurrentOrders active={active} direction={'receive'} />
          <PaginationComponent
            maxPages={totalBuyerActivePages}
            pageId={buyerActivePage}
            setPageId={setBuyerActivePage}
          />
          <br />
          <H2>Вы сдали в аренду</H2>
          <CurrentOrders active={activeSale} direction={'send'} />
          <PaginationComponent
            maxPages={totalSellerActivePages}
            pageId={sellerActivePage}
            setPageId={setSellerActivePage}
          />
        </>
      ) : (
        <>
          <H2>Вы брали в аренду</H2>
          <ArchiveOrdersBuyer finished={finished} />
          <PaginationComponent
            maxPages={totalBuyerFinishedPages}
            pageId={buyerFinishedPage}
            setPageId={setBuyerFinishedPage}
          />
          <br />
          <H2>Вы сдавали в аренду</H2>
          <ArchiveOrdersSeller finished={finishedSale} />
          <PaginationComponent
            maxPages={totalSellerFinishedPages}
            pageId={sellerFinishedPage}
            setPageId={setSellerFinishedPage}
          />
        </>
      )}
    </WrapperMobile>
  )
}
