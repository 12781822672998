import { RentStatus } from '../../../../shared/api/generated'
import { FlexColumn, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'

type Props = {
  back: () => void
  status: RentStatus
}

export const Step10 = ({ back, status }: Props) => {
  return (
    <FlexColumn style={{ alignItems: 'center' }}>
      <img
        alt={''}
        src={'/images/interface/close-err.svg'}
        style={{ height: 40, marginTop: '100px', width: 40 }}
      />
      <br />
      {status === 'cancelledByBuyer' ? <H2>Арендатор отменил заказ</H2> : <H2>Заказ отменен</H2>}
      <br />

      <StyledButton onClick={back} style={{ marginBottom: '32px' }} fullwidth>
        На главную
      </StyledButton>
    </FlexColumn>
  )
}
