import { useEffect, useState } from 'react'
import { toast } from 'react-custom-alert'
import { useNavigate, useParams } from 'react-router-dom'

import { AxiosError } from 'axios'
import { observer } from 'mobx-react-lite'

import { useGetRentInfoQuery } from '../../entities/rent/queries'
import { Step1 } from '../../features/delivery/ui/send/Step1'
import { Step2 } from '../../features/delivery/ui/send/Step2'
import { Step3 } from '../../features/delivery/ui/send/Step3'
import { Step4 } from '../../features/delivery/ui/send/Step4'
import { Step5 } from '../../features/delivery/ui/send/Step5'
import { Step6 } from '../../features/delivery/ui/send/Step6'
import { Step7 } from '../../features/delivery/ui/send/Step7'
import { Step8 } from '../../features/delivery/ui/send/Step8'
import { Step9 } from '../../features/delivery/ui/send/Step9'
import { Step10 } from '../../features/delivery/ui/send/Step10'
import { Step11 } from '../../features/delivery/ui/send/Step11'
import { ProfileRentHeader } from '../../features/profieRent/ui/head/ProfileRentHeader'
import { StepsRow } from '../../shared/ui-kit/actual/StepsRow'
import { NotModal } from '../../shared/ui-kit/actual/modals/NotModal'
import { PageLoader } from '../../shared/ui-kit/deprecated/PageLoader'
import { LoaderWrapper } from '../../shared/ui-kit/projectComponents'

const DeliverySendPage = () => {
  const navigate = useNavigate()
  const back = () => {
    navigate('/')
  }
  const [step, setStep] = useState(0)
  const { rentId } = useParams()
  const { data, error, isLoading, isSuccess, refetch } = useGetRentInfoQuery(rentId)

  useEffect(() => {
    if (error) {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response.data?.message)
      back()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  useEffect(() => {
    if (
      isSuccess &&
      (data?.status === 'cancelledBySeller' ||
        data?.status === 'cancelledByBuyer' ||
        data?.status === 'rejectedBySeller')
    ) {
      setStep(9)
    }
    if (isSuccess && data?.status === 'created') {
      setStep(0)
    }
    if (isSuccess && data?.status === 'approvedBySeller') {
      setStep(1)
    }
    if (isSuccess && (data?.status === 'qrCodeGenerated' || data?.status === 'sentToBuyer')) {
      setStep(2)
    }
    if (isSuccess && data?.status === 'qrCodeValidated') {
      setStep(3)
    }
    if (isSuccess && data?.status === 'inUseByBuyer') {
      setStep(3)
    }
    if (isSuccess && data?.status === 'sentBackToSeller') {
      setStep(4)
    }
    if (isSuccess && data?.status === 'completed') {
      setStep(5)
    }
    if (isSuccess && data?.status === 'completedWithClaimBySeller') {
      setStep(7)
    }
    if (isSuccess && data?.status === 'completedWithClaimByBuyer') {
      setStep(8)
    }
  }, [data?.status, isSuccess, navigate])

  return (
    <>
      {isLoading ? (
        <LoaderWrapper>
          <PageLoader />
        </LoaderWrapper>
      ) : (
        <>
          <ProfileRentHeader />
          <NotModal>
            <StepsRow activeStep={step} totalSteps={6} />
            {/* <div>
              <button onClick={() => setStep(prev => (prev > 0 ? prev - 1 : 0))}>Prev</button>
              <button onClick={() => setStep(prev => (prev < 8 ? prev + 1 : 8))}>Next</button>
            </div> */}
            {
              [
                <Step1
                  back={back}
                  key={'step1'}
                  refetch={refetch}
                  rentInfo={data}
                  setStep={setStep}
                />,
                <Step2 back={back} key={'step2'} refetch={refetch} setStep={setStep} />,
                <Step3 key={'step3'} rentInfo={data} setStep={setStep} />,
                <Step4 key={'step4'} refetch={refetch} rentInfo={data} setStep={setStep} />,
                <Step5 key={'step5'} rentInfo={data} setStep={setStep} />,
                <Step6 key={'step6'} rentInfo={data} setStep={setStep} />,
                <Step7 back={back} key={'step7'} />,
                <Step8 back={back} key={'step8'} rentInfo={data} />,
                <Step9 back={back} key={'step9'} rentInfo={data} />,
                <Step10 back={back} key={'step10'} status={data?.status} />,
                <Step11 key={'step5'} rentInfo={data} setStep={setStep} />,
              ][step]
            }
          </NotModal>
        </>
      )}
    </>
  )
}

export default observer(DeliverySendPage)
