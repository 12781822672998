import { useMemo, useState } from 'react'

import styled from 'styled-components/macro'

import {
  useGetArchivedProductsList,
  useGetProductsListQuery,
} from '../../../../entities/productsList/queries'
import { useRentsGetRentsListQuery } from '../../../../entities/rent'
import { colors } from '../../../../shared/constants/colors'
import { PaginationComponent } from '../../../../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { MarketUsefulArticles } from '../../../../shared/ui-kit/actual/components/MarketUsefulArticles'
import {
  CategoriesTitleH1,
  FlexColumn,
  FlexRowBetween,
  PaginationWrapper,
  StyledLinkPrimary,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { generatePagesArray } from '../../../../shared/utils/paginationHelper'
import { useProfileInfo } from '../../model/use-profile-info'
import { GoodsDesktopList } from '../GoodsDesktopList'
import { RentGoodsDesktopList } from '../RentGoodsDesktopList'

const Wrapper = styled(FlexColumn)`
  flex-grow: 1;
  background-color: ${colors.white};
  gap: 24px;
`

export const ProfileBodyDesktopWrapper = () => {
  const { userInfo } = useProfileInfo()

  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const { data, isLoading } = useGetProductsListQuery({
    ownerId: userInfo.id,
    skip: page * itemsPerPage,
    take: itemsPerPage,
  })
  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )

  const [archivePage, setArchivePage] = useState(0)
  const [archiveItemsPerPage, setArchiveItemsPerPage] = useState(12)
  const { data: archiveData, isLoading: isArchiveLoading } = useGetArchivedProductsList({
    ownerId: userInfo.id,
    skip: archivePage * archiveItemsPerPage,
    take: archiveItemsPerPage,
  })

  const totalArchivePages = useMemo(
    () => (archiveData ? Math.ceil(archiveData?.totalCount / archiveItemsPerPage) : 1),
    [archiveData, archiveItemsPerPage]
  )

  const [rentsPage, setRentsPage] = useState(0)
  const [rentsItemsPerPage, setRentsItemsPerPage] = useState(12)
  const { data: rentsData, isLoading: isRentsLoading } = useRentsGetRentsListQuery({
    buyerId: userInfo.id,
    skip: rentsPage * rentsItemsPerPage,
    take: rentsItemsPerPage,
    userId: userInfo.id,
  })

  const totalRentsPages = useMemo(
    () => (rentsData ? Math.ceil(rentsData?.totalCount / rentsItemsPerPage) : 1),
    [rentsData, rentsItemsPerPage]
  )

  return (
    <Wrapper>
      <AbsoluteLoader isLoading={isLoading || isRentsLoading || isArchiveLoading} />

      <FlexRowBetween style={{ gap: '50px' }}>
        <CategoriesTitleH1>Товары</CategoriesTitleH1>
        <StyledLinkPrimary
          style={{ paddingInline: '20px', whiteSpace: 'nowrap', width: 'auto' }}
          to={'/add'}
        >
          Добавить объявление
        </StyledLinkPrimary>
      </FlexRowBetween>
      {data?.items && (
        <>
          <H3>Мои объявления</H3>
          <GoodsDesktopList items={data?.items} messageNoProducts={'У вас еще нет объявлений'} />
        </>
      )}
      <PaginationWrapper>
        <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
      </PaginationWrapper>

      {archiveData && (
        <>
          <H3>Архивные объявления</H3>
          <GoodsDesktopList
            items={archiveData?.items}
            messageNoProducts={'У вас еще нет архивных объявлений'}
            isArchive
          />
        </>
      )}
      <PaginationWrapper>
        <PaginationComponent
          maxPages={totalArchivePages}
          pageId={archivePage}
          setPageId={setArchivePage}
        />
      </PaginationWrapper>
      {rentsData && (
        <>
          <H3>Мои заказы</H3>
          <RentGoodsDesktopList
            iconSrc={'/images/buttons/star-transparent.svg'}
            items={rentsData?.items}
            messageNoProducts={'У вас еще нет заказов'}
          />
        </>
      )}
      <PaginationWrapper>
        <PaginationComponent
          maxPages={totalRentsPages}
          pageId={rentsPage}
          setPageId={setRentsPage}
        />
      </PaginationWrapper>
      <MarketUsefulArticles authorId={userInfo.id} take={30} title={'Мои статьи'} />
      {/* {favorites && !!favorites.length && (
        <CategoriesTitleH1 style={{ width: '100%' }}>Избранное</CategoriesTitleH1>
      )}
      {goods && data?.items && (
        <GoodsDesktopList
          goods={goods}
          isLoading={isLoading}
          items={[]}
          messageNoProducts={'У вас еще нет избранного'}
        />
      )} */}
    </Wrapper>
  )
}
