import React, { useContext, useEffect, useState } from 'react'

import { differenceInYears } from 'date-fns'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { usePostApiUsersGetUserInfo } from '../../../entities/ownProfile/queries'
import {
  FULL_NAME_VALIDATION,
  NUMBER_VALIDATION,
  SUBDIVISIONCODE_VALIDATION,
} from '../../../shared/constants/regex'
import ProfileStorage from '../../../shared/storage/ProfileStorage/ProfileStorage'
import { TextArea } from '../../../shared/ui-kit/actual/TextArea'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexRow, StyledButton } from '../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../shared/ui-kit/titles'
import { useAddPassportForm } from '../model/use-add-passport'
import { PassportPhotosAdd } from './PassportPhotosAdd'
import { PassportAddStepStatus } from './PersonalDataPassport'

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<PassportAddStepStatus>>
}
const PassportAddForm = ({ setStatus }: Props) => {
  const {
    errors,
    handleSubmit,
    isAddPassportError,
    isAddPassportSuccess,
    isLoading,
    isValid,
    register,
    reset,
    setImageFiles,
  } = useAddPassportForm()
  const [arePhotosSelected, setArePhotosSelected] = useState(false)

  const ProfileStore = useContext(ProfileStorage)
  const { mutate } = usePostApiUsersGetUserInfo({
    onError: error => {
      console.log('profile error', error)
    },
    onSuccess: data => {
      ProfileStore.setUserInfo(data)
    },
  })

  useEffect(() => {
    if (isAddPassportSuccess) {
      mutate({ userId: ProfileStore.userInfo.id })
      setStatus('success')
      reset()
    } else if (isAddPassportError) {
      setStatus('error')
    }
  }, [ProfileStore.userInfo.id, isAddPassportError, isAddPassportSuccess, mutate, reset, setStatus])
  useEffect(() => {
    isAddPassportError && setStatus('error')
  }, [isAddPassportError, setStatus])

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <AbsoluteLoader isLoading={isLoading} />
      <H3 style={{ marginTop: '8px' }}>Данные паспорта</H3>
      <PassportPhotosAdd
        arePhotosSelected={arePhotosSelected}
        setArePhotosSelected={setArePhotosSelected}
        setImageFiles={setImageFiles}
      />
      <TextField
        inputProps={{
          placeholder: 'ФИО',
          ...register('FullName', {
            maxLength: {
              message: 'Максимум 150 символов',
              value: 150,
            },
            minLength: {
              message: 'Минимум 2 символов',
              value: 2,
            },
            pattern: {
              message: 'ФИО содержать только буквы',
              value: FULL_NAME_VALIDATION,
            },
            required: 'ФИО обязательно для заполнения',
          }),
        }}
        error={errors.FullName?.message?.toString()}
        label={'ФИО'}
        fullwidth
      />
      <TextField
        inputProps={{
          placeholder: 'Дата рождения',
          type: 'date',
          ...register('BirthDate', {
            required: 'Дата рождения обязательна',
            validate: date => {
              const age = differenceInYears(new Date(), new Date(date))

              if (age < 18) {
                return 'Для пользователей старше 18 лет'
              } else if (age > 100) {
                return 'Не старше 100 лет'
              }
            },
          }),
        }}
        error={errors.BirthDate?.message?.toString()}
        label={'Дата рождения'}
        fullwidth
      />
      <FlexRow style={{ gap: '8px' }}>
        <TextField
          inputProps={{
            placeholder: 'Серия паспорта',
            ...register('Series', {
              maxLength: {
                message: 'Максимум 4 символов',
                value: 4,
              },
              minLength: {
                message: 'Минимум 4 символов',
                value: 4,
              },
              pattern: {
                message: 'серия паспорта содержит только цифры',
                value: NUMBER_VALIDATION,
              },
              required: 'Серия паспорта обязательна для заполнения',
            }),
            maxLength: 4,
            minLength: 4,
          }}
          error={errors.Series?.message?.toString()}
          label={'Серия паспорта'}
          style={{ minWidth: 'unset', width: '40%' }}
        />
        <TextField
          inputProps={{
            placeholder: 'Номер паспорта',
            ...register('Number', {
              maxLength: {
                message: 'Максимум 6 символов',
                value: 6,
              },
              minLength: {
                message: 'Минимум 6 символов',
                value: 6,
              },
              pattern: {
                message: 'Номер паспорта паспорта содержит только цифры',
                value: NUMBER_VALIDATION,
              },
              required: 'Номер паспорта паспорта обязателен для заполнения',
            }),
            maxLength: 6,
            minLength: 6,
          }}
          error={errors.Number?.message?.toString()}
          label={'Номер паспорта'}
          style={{ minWidth: 'unset', width: '60%' }}
        />
      </FlexRow>
      <TextArea
        inputProps={{
          placeholder: 'Выдан кем',
          ...register('IssuedBy', {
            maxLength: {
              message: 'Максимум 350 символов',
              value: 350,
            },
            minLength: {
              message: 'Минимум 2 символов',
              value: 2,
            },
            required: 'Поле обязательно для заполнения',
          }),
        }}
        error={errors.IssuedBy?.message?.toString()}
        fullwidth
      />
      <TextField
        inputProps={{
          placeholder: 'Код подразделения',
          ...register('SubdivisionCode', {
            maxLength: {
              message: 'Максимум 25 символов',
              value: 25,
            },
            minLength: {
              message: 'Минимум 2 символов',
              value: 2,
            },
            pattern: {
              message: 'Код подразделения содержит только цифры и символы',
              value: SUBDIVISIONCODE_VALIDATION,
            },
            required: 'Код подразделения обязателен для заполнения',
          }),
        }}
        error={errors.SubdivisionCode?.message?.toString()}
        fullwidth
      />
      <TextField
        inputProps={{
          placeholder: 'Дата выдачи',
          type: 'date',
          ...register('IssuedOn', {
            required: 'Дата выдачи обязательна',
          }),
        }}
        error={errors.IssuedOn?.message?.toString()}
        label={'Дата выдачи'}
        fullwidth
      />
      <TextArea
        inputProps={{
          placeholder: 'Прописан',
          ...register('PlaceOfResidence', {
            maxLength: {
              message: 'Максимум 350 символов',
              value: 350,
            },
            minLength: {
              message: 'Минимум 2 символов',
              value: 2,
            },
            required: 'Поле обязательно для заполнения',
          }),
        }}
        error={errors.PlaceOfResidence?.message?.toString()}
        fullwidth
      />
      <StyledButton disabled={!isValid || isLoading} fullwidth>
        Подтвердить
      </StyledButton>
      <StyledButton
        onClick={() => setStatus('error')}
        type={'button'}
        variant={'secondary'}
        fullwidth
      >
        Отмена
      </StyledButton>
    </FormWrapper>
  )
}

export default observer(PassportAddForm)
