import styled from 'styled-components/macro'

import { colors } from '../constants/colors'
import { devices } from './breakPoints'

export const AccordionWrapper = styled.div`
  @import '@radix-ui/colors/black-alpha.css';
  @import '@radix-ui/colors/mauve.css';
  @import '@radix-ui/colors/violet.css';

  h3 {
    all: unset;
  }
  button {
    border: none;
    cursor: pointer;
  }

  .AccordionRoot {
    border-radius: 6px;
    width: clamp(300px, 90vw, 730px);
    background-color: var(--mauve-6);
    box-shadow: 0 2px 10px var(--black-a4);
    @media screen and (${devices.desktopMin}) {
      width: 350px;
    }
  }

  .AccordionItem {
    overflow: hidden;
    margin-top: 1px;
  }

  .AccordionItem:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .AccordionItem:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .AccordionItem:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px var(--mauve-12);
  }

  .AccordionHeader {
    display: flex;
    cursor: pointer;
    border-bottom: solid 1.5px ${colors.lightGray};
    &[data-state='open'] {
      border-bottom: none;
    }
  }

  .AccordionTrigger {
    font-family: inherit;
    background-color: transparent;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    line-height: 1;
    color: var(--violet-11);
    box-shadow: 0 1px 0 var(--mauve-6);
    background-color: white;
    font-size: 18px;
    font-weight: 600;
    color: ${colors.black};
    & > div {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }

  .AccordionTrigger:hover {
    background-color: var(--mauve-2);
  }

  .AccordionContent {
    overflow: hidden;
    font-size: 15px;
    color: var(--mauve-11);
    background-color: var(--mauve-2);
    @media screen and (${devices.desktopMin}) {
      /* z-index: 3000; */
    }
  }
  .AccordionContent[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
    @media screen and (${devices.desktopMin}) {
      animation: none;
      display: none;
    }
  }
  .AccordionContent[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
    @media screen and (${devices.desktopMin}) {
      animation: none;
    }
  }

  .AccordionContentText {
    padding: 15px 0;
  }

  .AccordionChevron {
    color: var(--violet-10);
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  .AccordionTrigger[data-state='open'] > .AccordionChevron {
    transform: rotate(180deg);
    @media screen and (${devices.desktopMin}) {
      transform: rotate(90deg);
    }
  }
  .AccordionTrigger > .AccordionChevron {
    @media screen and (${devices.desktopMin}) {
      transform: rotate(-90deg);
    }
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`
