import { FC } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { FlexColumn, FlexRowBetween } from '../projectComponents'

const StyledSwitch = styled(FlexRowBetween)``

const StyledSwitchItem = styled(FlexColumn)<{ active?: boolean }>`
  width: 50%;
  border-bottom: 2.5px ${colors.darkGrey} solid;
  border-bottom-color: ${({ active }) => (active ? colors.primary : colors.black)};
  color: ${colors.black};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  padding-bottom: 11px;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 18.48px */
  letter-spacing: 0.2px;
  cursor: pointer;
`

export const SwitchTabs: FC<{
  activeTab: number
  setActiveTab: (e: any) => void
  tabHead0: string
  tabHead1: string
}> = ({ activeTab, setActiveTab, tabHead0, tabHead1 }) => {
  return (
    <StyledSwitch>
      <StyledSwitchItem active={activeTab === 0} onClick={() => setActiveTab(0)}>
        {tabHead0}
      </StyledSwitchItem>
      <StyledSwitchItem active={activeTab === 1} onClick={() => setActiveTab(1)}>
        {tabHead1}
      </StyledSwitchItem>
    </StyledSwitch>
  )
}
