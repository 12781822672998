import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  CreateCriterionCommand,
  DeleteCriterionCommand,
  EditCriterionCommand,
  GetCategoryCriteriaQuery,
  GetCategoryCriteriaResponse,
  postApiCriteriaCreateCriterion,
  postApiCriteriaDeleteCriterion,
  postApiCriteriaEditCriterion,
  postApiCriteriaGetCategoryCriteria,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useCreateCriterionMutation(
  options?: UseMutationOptions<unknown, unknown, CreateCriterionCommand>
) {
  return useMutation({
    mutationFn: (data: CreateCriterionCommand) => {
      return postApiCriteriaCreateCriterion(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка создания нового критерия для категории')
    },
    onSuccess: () => {
      toast.success('Новый критерий категории был успешно создан')
      queryClient.invalidateQueries({ queryKey: ['get_category_criteria_list'] })
    },
    ...options,
  })
}
export function useEditCriterionMutation(
  options?: UseMutationOptions<unknown, unknown, EditCriterionCommand>
) {
  return useMutation({
    mutationFn: (data: EditCriterionCommand) => {
      return postApiCriteriaEditCriterion(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при редактировании критерия для категории')
    },
    onSuccess: () => {
      toast.success('Критерий категории был успешно отредактирован')
      queryClient.invalidateQueries({ queryKey: ['get_category_criteria_list'] })
    },
    ...options,
  })
}
export function useDeleteCriterionMutation(
  options?: UseMutationOptions<unknown, unknown, DeleteCriterionCommand>
) {
  return useMutation({
    mutationFn: (data: DeleteCriterionCommand) => {
      return postApiCriteriaDeleteCriterion(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка при удалении критерия для категории')
    },
    onSuccess: () => {
      toast.success('Критерий категории был успешно удален')
      queryClient.invalidateQueries({ queryKey: ['get_category_criteria_list'] })
    },
    ...options,
  })
}
export function useGetCategoryCriteria(
  params: GetCategoryCriteriaQuery,
  options?: UseQueryOptions<unknown, unknown, GetCategoryCriteriaResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки критериев для категории')
    },
    queryFn: () => postApiCriteriaGetCategoryCriteria(params),
    queryKey: ['get_category_criteria_list', JSON.stringify(params)],
    refetchOnWindowFocus: false,
    retry: 1,
    ...options,
  })
}
