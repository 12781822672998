import { ReviewListItemDto } from '../../../api/generated'
import displayStringByAmount, {
  calculateStarAmount,
  calculateStarPercent,
} from '../../../utils/displayStringByAmount'
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  GoldStatus,
  MainRateBig,
  MainRateSmall,
  RateTablo,
  RateTabloWrapper,
  StatusBar,
  StatusTitle,
} from '../../projectComponents'
import { StarsRating } from '../StarsRating'

interface ItemRatesProps {
  reviews: ReviewListItemDto[]
}

export const ItemRates = ({ reviews }: ItemRatesProps) => {
  const reviewsCount = reviews?.length || 0
  const totalRating = reviews.reduce((acc, rate) => acc + rate.rating, 0) / reviewsCount || 0

  return (
    <FlexRowBetween style={{ alignItems: 'center', justifyContent: 'start' }}>
      <RateTabloWrapper>
        <RateTablo>
          <MainRateBig>{totalRating?.toFixed(1)}</MainRateBig>
          <MainRateSmall>/ 5</MainRateSmall>
        </RateTablo>
        <div style={{ fontSize: 14 }}>
          {reviewsCount} {displayStringByAmount('отзыв', reviewsCount)}
        </div>
      </RateTabloWrapper>
      <FlexColumn>
        {[5, 4, 3, 2, 1].map((item, id) => (
          <FlexRow key={id} style={{ margin: 4 }}>
            <StarsRating rating={5 - id} />
            <StatusBar>
              <GoldStatus style={{ width: `${calculateStarPercent(5 - id, reviews)}px` }} />
            </StatusBar>
            <StatusTitle>{calculateStarAmount(5 - id, reviews)}</StatusTitle>
          </FlexRow>
        ))}
      </FlexColumn>
    </FlexRowBetween>
  )
}
