import { useState } from 'react'

import { formatDistance } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { ReviewItem } from '../../../features/marketItem/ui/components/ReviewItem'
import { ReviewListItemDto } from '../../../shared/api/generated'
import { ProfileSwitchTabs } from '../../../shared/ui-kit/actual/ProfileSwitchTabs'
import { FlexColumnStart } from '../../../shared/ui-kit/projectComponents'
import { getDateAgo } from '../../../shared/utils/dateAgo'

const Wrapper = styled(FlexColumnStart)`
  row-gap: 24px;
  width: 100%;
`

interface MyReviewsComponentProps {
  buyerReviews: ReviewListItemDto[]
  sellerReviews: ReviewListItemDto[]
}

const UserReviewsWithSwitchTabs = ({ buyerReviews, sellerReviews }: MyReviewsComponentProps) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <FlexColumnStart style={{ width: '100%' }}>
      <ProfileSwitchTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabHead0={'Арендатор'}
        tabHead1={'Арендодатель'}
      />
      <br />
      <Wrapper>
        {(!activeTab &&
          buyerReviews?.map((review, idx) => (
            <ReviewItem
              avatar={review?.sellerAvatarImageUrl}
              dateAgo={getDateAgo(review.createOn)}
              desc={review.content}
              key={review.reviewId}
              name={review.sellerFullName}
              rating={review.rating}
            />
          ))) ||
          null}
        {(activeTab &&
          sellerReviews.map((review, idx) => (
            <ReviewItem
              avatar={review?.buyerAvatarImageUrl}
              dateAgo={getDateAgo(review?.createOn)}
              desc={review?.content}
              key={review?.reviewId}
              name={review?.buyerFullName}
              rating={review?.rating}
            />
          ))) ||
          null}
      </Wrapper>
    </FlexColumnStart>
  )
}

export default UserReviewsWithSwitchTabs
