import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { PageLoader } from '../../deprecated/PageLoader'

const Select = styled.ul`
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  background: ${colors.lightGray};
  margin: 0;
  padding: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  z-index: 111;
  max-height: 150px;
  height: auto;
  overflow: auto;
  border-radius: 10px;
  li {
    list-style: none;
    padding: 10px;

    :hover {
      background: ${colors.primary};
      cursor: pointer;
      transition: cubic-bezier(1, 0, 0, 1) 0.3s all;
      color: white;
    }
  }
`

interface InputSelectProps {
  isLoadingAddress?: boolean
  selectOptions: {
    GeoObject: {
      Point: { pos: string }
      metaDataProperty: { GeocoderMetaData: { Address: { formatted: string } } }
    }
  }[]
  setSelected?: any
}

export const InputSelect = ({ isLoadingAddress, selectOptions, setSelected }: InputSelectProps) => {
  return (
    <Select>
      {isLoadingAddress ? (
        <PageLoader />
      ) : (
        selectOptions?.map(option => (
          <li key={option.GeoObject.Point.pos} onClick={setSelected}>
            {option.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted}
          </li>
        ))
      )}
    </Select>
  )
}
