import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import {
  useArticlesGetArticleInfoQuery,
  useArticlesShareArticleMutation,
  useArticlesViewArticleMutation,
  useDeleteArticleMutation,
} from '../../entities/articles/queries'
import { ArticleVariant } from '../../features/article'
import ArticlePageHeader from '../../features/article/ui/Components/ArticlePageHeader'
import ArticleReportModal from '../../features/article/ui/Components/ArticleReportModal'
import ArticleSection from '../../features/article/ui/Sections/ArticleSection'
import ArticleSectionNotAuth from '../../features/article/ui/Sections/ArticleSectionNotAuth'
import { BlogDesktopHeader } from '../../features/blog/ui/head/BlogDesktopHeader'
import { NewArticleModal } from '../../features/blog/ui/head/NewArticleModal'
import Breadcrumbs from '../../features/market/ui/sections/BreadCrumbs'
import { MessengerBubbleButton } from '../../features/profile/ui/MessengerBubbleButton'
import { useShare } from '../../shared/hooks/use-share'
import { useModal } from '../../shared/hooks/useModal'
import UserStorage from '../../shared/storage/UserStorage/UserStorage'
import { AbsoluteLoader } from '../../shared/ui-kit/actual/components/AbsoluteLoader'
import { LoginOrSignupComponent } from '../../shared/ui-kit/actual/components/LoginOrSignupComponent'
import { MarketUsefulArticles } from '../../shared/ui-kit/actual/components/MarketUsefulArticles'
import { MainHeader } from '../../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../../shared/ui-kit/actual/layouts/BasePageLayout'
import { ProfileLayout } from '../../shared/ui-kit/actual/layouts/ProfileLayout'
import { BaseModal } from '../../shared/ui-kit/actual/modals/BaseModal'
import { MiniModal } from '../../shared/ui-kit/actual/modals/MiniModal'
import { devices } from '../../shared/ui-kit/breakPoints'
import {
  BaseWrapper,
  DesktopView,
  FlexColumn,
  FlexColumnStart,
  FlexRowBetween,
  MobileTabletView,
  StyledButton,
  StyledLink,
  TabletDesktopView,
} from '../../shared/ui-kit/projectComponents'
import { H2 } from '../../shared/ui-kit/titles'
import { shortString } from '../../shared/utils/truncate'

const SectionWrapper = styled(FlexColumn)`
  width: 100%;
  padding: 0 12px;
  @media screen and (${devices.desktopMin}) {
    padding: 0;
    width: calc(100% - 48px);
  }
`
const IconBtn = styled(StyledButton)`
  padding: 0;
  width: 24px;
  height: 24px;
  margin-left: auto;
`
const ArticlePage = () => {
  const { articleId } = useParams()
  const { isAuth } = useContext(UserStorage)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [articleModal, setArticleModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const onShowModal = () => {
    setIsOpenModal(true)
  }
  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  const {
    data: articleData,
    isLoading,
    isSuccess,
  } = useArticlesGetArticleInfoQuery({
    articleId: +articleId,
  })
  const { mutate } = useArticlesViewArticleMutation()
  const { mutate: shareMutate } = useArticlesShareArticleMutation()

  const {
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
    mutate: deleteMutate,
  } = useDeleteArticleMutation()
  const navigate = useNavigate()

  useEffect(() => {
    isDeleteSuccess && navigate(-1)
  }, [isDeleteSuccess, navigate])

  const handleDeleteArticle = () => {
    deleteMutate({ articleId: +articleId })
  }

  const handleOpenDeleteModal = () => {
    setDeleteModal(true)
  }

  useEffect(() => {
    isAuth && articleId && mutate({ articleId: +articleId })
  }, [articleId, isAuth, mutate])

  const { setViewModal, viewModal } = useModal()
  const shareArticle = () => {
    shareMutate({ articleId: +articleId })
  }
  const { share } = useShare({
    shareFn: shareArticle,
    text: 'Почитай интересную статью',
    title: 'Интересная статья',
  })

  return (
    <>
      <AbsoluteLoader isLoading={isLoading || isDeleteLoading} />
      <DesktopView>
        <BasePageLayout>
          <MainHeader />
          <TabletDesktopView>
            <FlexRowBetween style={{ alignItems: 'center' }}>
              <Breadcrumbs lastName={shortString(articleData?.title, 60)} />
              <IconBtn fullwidth={false} onClick={share} variant={'neutral'} isIcoBtn>
                <img alt={''} src={'/images/buttons/share-primary.svg'} />
              </IconBtn>
            </FlexRowBetween>
          </TabletDesktopView>
          <BaseWrapper>
            <br />
            <ProfileLayout
              body={
                <SectionWrapper>
                  {articleData && isSuccess && isAuth && <ArticleSection data={articleData} />}
                  {articleData && isSuccess && !isAuth && (
                    <ArticleSectionNotAuth data={articleData} />
                  )}
                  {/* <ArticleComments isAuth={isAuth} /> */}
                  <MarketUsefulArticles title={'Похожие статьи'} variant={ArticleVariant.Long} />
                  <br />
                  <FlexColumnStart style={{ width: '100%' }}>
                    <StyledLink style={{ width: 300 }} to={'/blog'}>
                      Все статьи
                    </StyledLink>
                  </FlexColumnStart>
                </SectionWrapper>
              }
              head={
                isAuth && articleData?.authorId ? (
                  <BlogDesktopHeader
                    authorId={articleData?.authorId}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    isDeleteLoading={isDeleteLoading}
                    setArticleModal={setArticleModal}
                    setViewModal={setViewModal}
                  />
                ) : (
                  <LoginOrSignupComponent />
                )
              }
            />
          </BaseWrapper>
        </BasePageLayout>
      </DesktopView>
      <MobileTabletView>
        <ArticlePageHeader onOpenModal={onShowModal} shareArticle={shareArticle} />
        <SectionWrapper>
          {articleData && isSuccess && isAuth && (
            <ArticleSection
              data={articleData}
              handleOpenDeleteModal={handleOpenDeleteModal}
              isDeleteLoading={isDeleteLoading}
            />
          )}
          {articleData && isSuccess && !isAuth && <ArticleSectionNotAuth data={articleData} />}
          {/* <ArticleComments isAuth={isAuth} /> */}
          <MarketUsefulArticles title={'Похожие статьи'} />
          <br />
          <FlexColumnStart>
            <StyledLink style={{ width: 300 }} to={'/blog'}>
              Все статьи
            </StyledLink>
          </FlexColumnStart>
        </SectionWrapper>
        {isAuth && (
          <MessengerBubbleButton
            onClick={() => {
              setArticleModal(true)
            }}
            content={<img src={'/images/buttons/Edit-white.svg'} />}
          />
        )}
      </MobileTabletView>
      <NewArticleModal articleModal={articleModal} setArticleModal={setArticleModal} />

      <BaseModal setViewModal={setIsOpenModal} viewModal={isOpenModal}>
        <ArticleReportModal data={articleData} onCloseModal={onCloseModal} />
      </BaseModal>
      {/* <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
          <TagsModal accountStatus={'danger'} setViewModal={setViewModal} />
        </BaseModal> */}
      <MiniModal setViewModal={() => setDeleteModal(false)} viewModal={deleteModal}>
        <FlexColumn>
          <br />
          <H2>Вы уверены, что хотите удалить статью</H2>
          <br />
          <FlexRowBetween>
            <StyledButton onClick={() => setDeleteModal(false)} variant={'secondary'}>
              НЕТ
            </StyledButton>
            <StyledButton onClick={handleDeleteArticle} variant={'warning'}>
              ДА
            </StyledButton>
          </FlexRowBetween>
          <br />
        </FlexColumn>
      </MiniModal>
    </>
  )
}

export default ArticlePage
