import { ArticleSchema } from '../../features/article'
import { SORTING_TYPE } from '../api/deprecated/productService/ProductService'

export const mockDataArticles: ArticleSchema[] = [
  {
    id: 2,
    image: '/images/mock-items/marketplace/articles/4.png',
    link: '/article/2',
    numberComments: 5,
    numberShared: 3,
    numberView: 150,
    title: 'Как обезопасить свой товар от недобросовестного арендатора?',
  },
  {
    id: 6,
    image: '/images/mock-items/marketplace/articles/1.png',
    link: '/article/6',
    numberComments: 5,
    numberShared: 3,
    numberView: 100,
    title: 'От машины до обуви: как арендовать все',
  },
  {
    id: 7,
    image: '/images/mock-items/marketplace/articles/2.png',
    link: '/article/7',
    numberComments: 5,
    numberShared: 3,
    numberView: 100,
    title: '10 вопросов арендатору: ответы и новый опрос',
  },
  {
    id: 8,
    image: '/images/mock-items/marketplace/articles/3.png',
    link: '/article/8',
    numberComments: 5,
    numberShared: 3,
    numberView: 100,
    title: 'План для старта на платформе',
  },
]
export const mockSections = [
  { id: 'realEstate', name: 'Недвижимость' },
  { id: 'motors', name: 'Техника' },
  { id: 'digitalTech', name: 'Цифровая техника' },
  { id: 'clothes', name: 'Одежда' },
  { id: 'cars', name: 'Автомобили' },
  { id: 'people', name: 'Люди' },
]
export const goods = [
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/2.png',
    link: '/',
    title: 'Cartoon Netwrok',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/bershka-jeans.jpg',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/2.png',
    link: '/',
    title: 'Cartoon Netwrok',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/bershka-jeans.jpg',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    image: '/images/mock-items/marketplace/for-you/bershka-jeans.jpg',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
]
export const dresses = [
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/1.jpg',
    link: '/',
    price: 1000,
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/3.jpg',
    link: '/',
    price: 1000,
    title: 'Cartoon Netwrok',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/4.jpg',
    link: '/',
    price: 1000,
    title: 'Bershka Printed',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/2.jpg',
    price: 1000,
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/3.jpg',
    link: '/',
    price: 1000,
    title: 'Bershka Printed',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/2.jpg',
    link: '/',
    price: 1000,
    title: 'Cartoon Netwrok',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/1.jpg',
    link: '/',
    price: 1000,
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/4.jpg',
    link: '/',
    price: 1000,
    title: 'Bershka Printed',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/3.jpg',
    link: '/',
    price: 1000,
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Платье',
    image: '/images/mock-items/dresses/2.jpg',
    link: '/',
    price: 1000,
    title: 'Bershka Printed',
  },
]
export const favorites = [
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/2.png',
    link: '/',
    title: 'Cartoon Netwrok',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/bershka-jeans.jpg',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/2.png',
    link: '/',
    title: 'Cartoon Netwrok',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/bershka-jeans.jpg',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/1.png',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
  {
    brandImage: '',
    description: 'Одежда',
    iconImage: '/images/buttons/Heart.svg',
    image: '/images/mock-items/marketplace/for-you/bershka-jeans.jpg',
    link: '/',
    title: 'Lacoste x Polaroid',
  },
]
export const sortingCategories = [
  {
    name: 'Все категории',
    value: SORTING_TYPE.ByCategory,
  },
  {
    name: 'Инструменты',
    value: SORTING_TYPE.Tools,
  },
  {
    name: 'Автомобили',
    value: SORTING_TYPE.Cars,
  },
  {
    name: 'Одежда',
    value: SORTING_TYPE.Clothes,
  },
  {
    name: 'Недвижимость',
    value: SORTING_TYPE.RealEstate,
  },
  {
    name: 'Техника',
    value: SORTING_TYPE.Motors,
  },
  {
    name: 'Цифровая техника',
    value: SORTING_TYPE.DigitalTech,
  },
  {
    name: 'Люди',
    value: SORTING_TYPE.People,
  },
] as const

export type SortingCategories = typeof sortingCategories
