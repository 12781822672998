import { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { useGetProductsListQuery } from '../entities/productsList/queries'
import Breadcrumbs from '../features/market/ui/sections/BreadCrumbs'
import { GoodsDesktopList } from '../features/profile/ui/GoodsDesktopList'
import { SortOrder } from '../shared/api/generated'
import { useModal } from '../shared/hooks/useModal'
import FilterStorage from '../shared/storage/FilterStorage/FilterStorage'
import { BackArrowWithTitleLink } from '../shared/ui-kit/actual/BackArrowWithTitleLink'
import { FilterButton } from '../shared/ui-kit/actual/FilterButton'
import { LogoAllSharing } from '../shared/ui-kit/actual/LogoAllsharing'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { SortButton } from '../shared/ui-kit/actual/SortButton'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../shared/ui-kit/actual/layouts/BasePageLayout'
import { RightModal } from '../shared/ui-kit/actual/modals/RightModal'
import FilterWindowNew from '../shared/ui-kit/actual/modalsContent/FilterWindowNew'
import SortModalWindow from '../shared/ui-kit/actual/modalsContent/SortModalWindow'
import { MainSearchComponent } from '../shared/ui-kit/deprecated/MainSearchComponent'
import {
  DesktopTitle,
  FilterSubWrapper,
  FilterWrapper,
  MobileView,
} from '../shared/ui-kit/projectComponents'

const SearchPage = () => {
  const [searchParams] = useSearchParams()
  const productNameTerm = searchParams.get('searchValue')
  const SectionId = searchParams.get('sectionId')
  const [itemsPerPage, setItemsPerPage] = useState(24)
  const [page, setPage] = useState(0)

  const [openSortMenu, setOpenSortMenu] = useState(false)
  const { sorting } = useContext(FilterStorage)
  const [sortOrder, setSortOder] = useState<SortOrder>('asc')
  const toggleSortOrder = () => {
    setSortOder(sortOrder === 'asc' ? 'desc' : 'asc')
  }
  const { setViewModal, viewModal } = useModal()

  const handleFilterModalOpen = () => {
    setViewModal(prev => !prev)
  }
  const { data, isLoading } = useGetProductsListQuery({
    dayPriceFrom: sorting.dayPriceFrom || null,
    dayPriceTo: sorting.dayPriceTo || null,
    orderType: sorting.orderType,
    premiumOnly: sorting.premiumOnly,
    productNameTerm,
    ratingFrom: sorting.ratingFrom,
    sectionId: Number(SectionId) || null,
    skip: page * itemsPerPage,
    sortOrder: sortOrder,
    take: itemsPerPage,
  })

  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  return (
    <>
      <BasePageLayout>
        <AbsoluteLoader isLoading={isLoading} />
        <MainHeader />
        <MobileView>
          <BackArrowWithTitleLink rightEl={<LogoAllSharing />} title={'Поиск'} />
          <MainSearchComponent setIsFixed={() => null} hideFilterButton noLogo />
        </MobileView>
        <br />
        <Breadcrumbs />
        <FilterWrapper>
          <DesktopTitle>Поиск</DesktopTitle>
          <FilterSubWrapper>
            <SortButton
              onClickSort={() => {
                setOpenSortMenu(prev => !prev)
              }}
              selectedSort={sorting.orderType || 'byDefault'}
              sortOrder={sortOrder}
              toggleSort={toggleSortOrder}
            />
            <FilterButton onClickFilter={handleFilterModalOpen} />
          </FilterSubWrapper>
        </FilterWrapper>
        <br />
        {data && (
          <GoodsDesktopList
            isLoading={isLoading}
            items={data?.items}
            messageNoProducts={'Ничего не нашлось. Попробуйте изменить запрос'}
          />
        )}
        {data && <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />}
      </BasePageLayout>
      <RightModal setViewModal={setViewModal} viewModal={viewModal}>
        <FilterWindowNew handleFilterModalOpen={handleFilterModalOpen} isSearch />
      </RightModal>
      <SortModalWindow
        handleMenu={() => {
          setOpenSortMenu(prev => !prev)
        }}
        openMenu={openSortMenu}
        selectedSort={sorting.orderType}
      />
    </>
  )
}

export default observer(SearchPage)
