import { useGetRentInfoQuery } from '../../../entities/rent'

interface UseGetRentInfoProps {
  rentId: string
}
export function useGetRentInfo(rentId: string) {
  const { data, isError, isLoading, isSuccess } = useGetRentInfoQuery(rentId)

  return { data, isLoading, isSuccess }
}
