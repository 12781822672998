import React from 'react'
import { Oval } from 'react-loader-spinner'

import { colors } from '../../constants/colors'
import { FlexColumn } from '../projectComponents'

interface PageLoaderProps {
  height?: number
  width?: number
}

export const PageLoader = ({ height = 60, width = 60 }: PageLoaderProps) => {
  return (
    <FlexColumn>
      <Oval
        ariaLabel={'oval-loading'}
        color={colors.primary}
        height={height}
        secondaryColor={colors.primaryActive}
        strokeWidth={2}
        strokeWidthSecondary={2}
        width={width}
        wrapperClass={''}
        wrapperStyle={{}}
        visible
      />
    </FlexColumn>
  )
}
