import React from 'react'

import styled from 'styled-components/macro'

import { SortOrder } from '../../api/generated'
import { sortingSelectors } from '../../constants/sortingSelectors'
import { FlexRow } from '../projectComponents'

const Block = styled(FlexRow)<{ sortOrder: SortOrder }>`
  cursor: pointer;
  margin-right: 12px;

  img {
    height: 24px;
    width: 24px;
    transform: ${({ sortOrder }) => (sortOrder === 'desc' ? 'rotate(0.5turn)' : 'rotate(0)')};
  }

  p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-right: 15px;
  }
`

interface SortProps {
  onClickSort: () => void
  selectedSort: string
  sortOrder: SortOrder
  toggleSort: () => void
}

export const SortButton: React.FC<SortProps> = ({
  onClickSort,
  selectedSort,
  sortOrder,
  toggleSort,
}) => {
  const title = sortingSelectors.find(selector => selector.value === selectedSort)?.name

  return (
    <Block sortOrder={sortOrder}>
      <p onClick={onClickSort}>{title}</p>
      {'  '} <img alt={''} onClick={toggleSort} src={'/images/buttons/sort-down.svg'} />
    </Block>
  )
}
