import { observer } from 'mobx-react-lite'

import { SMSSignInForm } from '../features/auth/ui/SMSSignIn'
import { SigninFooter } from '../features/auth/ui/SigninFooter'
import { AuthLayout } from '../shared/ui-kit/actual/layouts/AuthLayout'

const AuthPageSMS = () => {
  return <AuthLayout footer={<SigninFooter />} form={<SMSSignInForm />} />
}

export default observer(AuthPageSMS)
