import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { ClaimsListItem } from '../../../api/generated'
import { shortString } from '../../../utils/truncate'
import { FlexRowBetween, Table, Th } from '../../projectComponents'

export const translatesObj = {
  approved: 'принята',
  byBuyer: 'от арендатора',
  bySeller: 'от арендодателя',
  created: 'создана',
  rejected: 'отклонена',
}

export const AdminClaimsTable = ({ claims }: { claims: ClaimsListItem[] }) => (
  <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}>
    {' '}
    <Table>
      <thead>
        <tr>
          <Th style={{ maxWidth: '200px' }}></Th>
          <Th>Дата</Th>
          <Th>Описание</Th>
          <Th>Заявитель</Th>
          <Th>Тип жалобы</Th>
          <Th>Статус жалобы</Th>
        </tr>
      </thead>
      <tbody>
        {claims.map(p => (
          <tr key={p.claimId}>
            <td>
              <Link style={{ display: 'inline-block', minWidth: '150px' }} to={`${p.claimId}`}>
                К жалобе
              </Link>{' '}
            </td>
            <td>{format(new Date(p.createdOn), 'dd MMMM HH:mm', { locale: ru })}</td>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '150px' }}
                to={`/admin/product/${p.productId}`}
              >
                {shortString(p.productName, 36)}
              </Link>{' '}
            </td>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '150px' }}
                to={`/admin/users/${p.authorId}`}
              >
                {shortString(p.authorUserName, 36)}
              </Link>
            </td>
            <td>{translatesObj[p.claimType]}</td>
            <td>{translatesObj[p.claimStatus]}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </FlexRowBetween>
)
