import { useContext, useState } from 'react'

import styled from 'styled-components/macro'

import { useModal } from '../../../../shared/hooks/useModal'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { ProfileHeader } from '../../../../shared/ui-kit/actual/ProfileHeader'
import { LoginOrSignupComponent } from '../../../../shared/ui-kit/actual/components/LoginOrSignupComponent'
import { MarketUsefulArticles } from '../../../../shared/ui-kit/actual/components/MarketUsefulArticles'
import { MainHeader } from '../../../../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../../../../shared/ui-kit/actual/layouts/BasePageLayout'
import { ProfileLayout } from '../../../../shared/ui-kit/actual/layouts/ProfileLayout'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import { RightModal } from '../../../../shared/ui-kit/actual/modals/RightModal'
import { MainMenuWindow } from '../../../../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import {
  DesktopView,
  FlexColumn,
  FlexRowBetween,
  ImgButton,
  MobileTabletView,
  NeutralLink,
} from '../../../../shared/ui-kit/projectComponents'
import { ArticleVariant } from '../../../article'
import Breadcrumbs from '../../../market/ui/sections/BreadCrumbs'
import { MessengerBubbleButton } from '../../../profile/ui/MessengerBubbleButton'
import { BlogDesktopHeader } from '../head/BlogDesktopHeader'
import { NewArticleModal } from '../head/NewArticleModal'

const BlogBodyWrapper = () => {
  const { isAuth, userId } = useContext(UserStorage)
  const { setViewModal, viewModal } = useModal()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [articleModal, setArticleModal] = useState(false)

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <DesktopView>
        <BasePageLayout>
          <MainHeader />
          <FlexRowBetween style={{ alignItems: 'center' }}>
            <Breadcrumbs />
          </FlexRowBetween>
          <ProfileLayout
            body={
              <FlexColumn style={{ width: '100%' }}>
                <MarketUsefulArticles take={30} title={'Для вас'} variant={ArticleVariant.Long} />

                {/* {isAuth && <BlogForYou />} */}
                {/* <BlogPopular />
                <BlogLatest /> */}
              </FlexColumn>
            }
            head={
              isAuth ? (
                <BlogDesktopHeader
                  authorId={userId}
                  setArticleModal={setArticleModal}
                  setViewModal={setViewModal}
                  isArticlesPage
                />
              ) : (
                <LoginOrSignupComponent />
              )
            }
          />
        </BasePageLayout>
      </DesktopView>
      <MobileTabletView style={{ paddingInline: '10px' }}>
        <ProfileHeader
          left={
            <NeutralLink to={'/profile'}>
              <img src={'/images/buttons/user-avatar-small.svg'} />
            </NeutralLink>
          }
          right={
            <ImgButton
              alt={''}
              onClick={() => setIsModalOpen(prev => !prev)}
              src={'/images/buttons/menu-button.svg'}
            />
          }
          center={'Блог'}
        />
        <FlexColumn>
          <MarketUsefulArticles take={30} title={'Для вас'} />
        </FlexColumn>
        {isAuth && (
          <MessengerBubbleButton
            onClick={() => {
              setArticleModal(true)
            }}
            content={<img src={'/images/buttons/Edit-white.svg'} />}
          />
        )}
        {/* {isAuth && <BlogForYou />} */}
        {/* <BlogPopular />
        <BlogLatest /> */}
      </MobileTabletView>
      <NewArticleModal articleModal={articleModal} setArticleModal={setArticleModal} />

      {/* <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        <TagsModal accountStatus={'danger'} setViewModal={setViewModal} />
      </BaseModal> */}
      <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
        <MainMenuWindow handleModalClose={handleModalClose} />
      </RightModal>
    </>
  )
}

export default BlogBodyWrapper
