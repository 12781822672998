import React, { ComponentPropsWithoutRef, FC, useMemo, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  MediumTextL,
  StyledInput,
} from '../projectComponents'

type CitiesFieldProps = {
  cities?: string[]
  deleteCity?: (city) => void
  selectedTags?: string[]
  setSelectedCities?: React.Dispatch<React.SetStateAction<string[]>>
}

const TagWrapper = styled(FlexRow)`
  padding: 9px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 7.556px;
  background-color: ${colors.white};

  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 13.2px */
    color: ${colors.black};
  }

  img {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
`

const InputWrapper = styled(FlexRow)`
  width: 100%;
  padding: 14px 16px;
  min-height: 50px;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 16px;
  height: auto;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: ${colors.lightGray};

  input {
    width: fit-content;
    box-sizing: border-box;
  }
`

const Wrapper = styled(FlexColumn)`
  position: relative;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
  height: auto;
`

export const CitiesField = (props: CitiesFieldProps) => {
  const { cities = [], deleteCity, selectedTags = [], setSelectedCities } = props

  return (
    <Wrapper>
      <InputWrapper>
        {!cities.length && <MediumTextL>Города доставки не выбраны</MediumTextL>}
        {cities?.map((tag, idx) => (
          <CityItem
            deleteCity={deleteCity}
            key={idx}
            name={tag}
            selectedTags={selectedTags}
            setSelectedCities={setSelectedCities}
          />
        ))}
      </InputWrapper>
    </Wrapper>
  )
}

const CityItem: FC<
  {
    deleteCity?: (city) => void
    name: string
    selectedTags?: string[]
    setSelectedCities?: React.Dispatch<React.SetStateAction<string[]>>
  } & ComponentPropsWithoutRef<'div'>
> = props => {
  const { deleteCity, name, selectedTags, setSelectedCities, ...other } = props

  return (
    <TagWrapper>
      <span>{name}</span>
      <img onClick={() => deleteCity(name)} src={'/images/buttons/close.png'} />
    </TagWrapper>
  )
}
