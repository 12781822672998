import React from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { generatePagesArray } from '../../utils/paginationHelper'
import { FlexRow } from '../projectComponents'

interface PageButtonProps {
  isSelected: boolean
}

const PageWrapper = styled(FlexRow)`
  align-self: center;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  @media (min-width: 768px) {
    align-self: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
  }
`

const PageButton = styled.button<PageButtonProps>`
  margin: 4px;
  margin-top: 20px;
  background: ${({ isSelected }) => (isSelected ? colors.primary : colors.lightGray)};
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.black)};
  min-width: 41px;
  width: auto;
  height: 41px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
`

interface PaginationComponentProps {
  maxPages: number
  pageId: number
  pagesNum?: number[]
  setPageId: (page: number) => void
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  maxPages,
  pageId,
  pagesNum,
  setPageId,
}) => {
  if (!pagesNum) {
    pagesNum = generatePagesArray(maxPages, pageId, 100)
  }
  if (pagesNum?.length > 1) {
    return (
      <PageWrapper>
        {pageId !== 0 && (
          <PageButton
            onClick={() => {
              setPageId(pageId - 1)
            }}
            isSelected={pageId === 0}
          >
            <img src={'/images/buttons/arrow-left-primary.svg'} />
          </PageButton>
        )}

        {pagesNum
          .filter(
            (page, idx) => Math.abs(page - pageId) <= (pageId < 2 || pageId > maxPages - 2 ? 3 : 2)
          )
          .map((page, id) => (
            <PageButton
              onClick={() => {
                setPageId(page - 1)
              }}
              isSelected={page === pageId + 1}
              key={`${page}+${id}`}
            >
              {page}
            </PageButton>
          ))}

        {pageId < maxPages - 1 && (
          <PageButton
            onClick={() => {
              setPageId(pageId + 1)
            }}
            isSelected={pageId === maxPages}
          >
            <img src={'/images/buttons/arrow-right-primary.svg'} />
          </PageButton>
        )}
      </PageWrapper>
    )
  }
}
