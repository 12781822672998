import { useForm } from 'react-hook-form'

import { useUpdateProductPriceMutation } from '../../../entities/marketItem/queries'
import { UpdateProductPriceCommand } from '../../../shared/api/generated'

export function useItemPricesEditForm() {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<UpdateProductPriceCommand>({
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const { isLoading, isSuccess, mutate } = useUpdateProductPriceMutation({})

  const createProduct = async (data: UpdateProductPriceCommand) => {
    if (!data.dayPrice) {
      data.dayPrice = null
    }
    if (!data.threeDaysPrice) {
      data.threeDaysPrice = null
    }
    if (!data.weekPrice) {
      data.weekPrice = null
    }
    if (!data.monthPrice) {
      data.monthPrice = null
    }
    mutate(data)
  }

  return {
    clearErrors,
    createProduct,
    errors,
    getValues,
    handleSubmit: handleSubmit(createProduct),
    isLoading,
    isSuccess,
    isValid,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  }
}
