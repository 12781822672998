import { ReactNode } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { desktopView } from '../../breakPoints'
import { CloseModalBtn, FlexColumnStart } from '../../projectComponents'

const ModalBackground = styled.div<{ $viewModal: boolean }>`
  width: 100%;
  min-height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 2100;
  visibility: ${({ $viewModal }) => ($viewModal ? '' : 'hidden')};
  ${desktopView}
`

const ModalWrapper = styled(FlexColumnStart)`
  justify-content: flex-start;
  align-items: stretch;
  width: clamp(300px, 100%, 1000px);
  box-sizing: border-box;
  padding: 36px 64px;
  padding-top: 75px;
  border-radius: 15px;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  gap: 4px;
  overflow: auto;
  z-index: 2100;
  height: 650px;
  position: relative;
`

type Props = {
  children?: ReactNode
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}

const preventClick = e => {
  e.stopPropagation()
}

export const DesktopModal = ({ children, setViewModal, viewModal }: Props) => {
  return (
    <ModalBackground
      onClick={() => {
        setViewModal(false)
      }}
      $viewModal={viewModal}
    >
      <ModalWrapper onClick={preventClick}>
        <CloseModalBtn
          onClick={() => {
            setViewModal(false)
          }}
        >
          <img alt={'Close'} src={'/images/buttons/close.png'} style={{ height: 20, width: 20 }} />
        </CloseModalBtn>
        {children}
      </ModalWrapper>
    </ModalBackground>
  )
}
