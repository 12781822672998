import { ReviewListItemDto } from '../../../shared/api/generated'
import { FlexColumnStart } from '../../../shared/ui-kit/projectComponents'
import { RenterReviewCard } from './RenterReviewCard'

export const RenterReviewTab = ({
  isBuyer,
  reviews,
}: {
  isBuyer?: boolean
  reviews: ReviewListItemDto[]
}) => {
  return (
    <FlexColumnStart style={{ gap: 32, width: '100%' }}>
      {reviews?.map(item => (
        <RenterReviewCard isBuyer={isBuyer} key={item.reviewId} review={item} />
      ))}
    </FlexColumnStart>
  )
}
