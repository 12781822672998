import { useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { useGetProductsListQuery } from '../../../../entities/productsList/queries'
import FilterStorage from '../../../../shared/storage/FilterStorage/FilterStorage'
import { PaginationComponent } from '../../../../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import SortModalWindow from '../../../../shared/ui-kit/actual/modalsContent/SortModalWindow'
import { PaginationWrapper } from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { GoodsDesktopList } from '../GoodsDesktopList'
// import { MessengerBubbleButton } from '../MessengerBubbleButton'

//mock data

// toggle to empty myProducts for testing <NoProducts/> blank component
// const myProducts = []
// const myProducts = [
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-1.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-2.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-3.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-4.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
// ]
// const frequentlyProducts = [
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-3.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-6.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-5.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
//   {
//     brandImage: '',
//     id: nanoid(),
//     imageLinks: ['/images/mock-items/my-profile/car-6.jpg'],
//     name: 'Volkswagen',
//     priceDay: 5000,
//   },
// ]

const UserGoods = () => {
  const navigate = useNavigate()
  const { orderType, setOrderBy } = useContext(FilterStorage)

  const [openMenu, setOpenMenu] = useState(false)

  const handleFilter = () => {
    setOpenMenu(!openMenu)
  }
  const { userId } = useParams()

  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const { data, isLoading } = useGetProductsListQuery({
    ownerId: userId,
    skip: page * itemsPerPage,
    take: itemsPerPage,
  })
  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      {/* <MainSearchComponent onClickFilter={handleFilter} setIsFixed={() => null} noLogo /> */}
      <SortModalWindow
        handleMenu={handleFilter}
        openMenu={openMenu}
        selectedSort={orderType}
        setSelectedSort={setOrderBy}
      />

      {data?.items && (
        <>
          <H3>Объявления пользователя</H3>
          <GoodsDesktopList
            items={data?.items}
            messageNoProducts={'У пользователя еще нет объявлений'}
          />
        </>
      )}
      <PaginationWrapper>
        <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
      </PaginationWrapper>

      {/* <FrequentlyViewHead
        imageLink={frequentlyProducts[0].imageLinks[0]}
        name={frequentlyProducts[0].name}
      /> */}
      {/* {frequentlyProducts && (
        <GoodsDesktopList
          isLoading={isLoading}
          items={[]}
          messageNoProducts={'У пользователя еще нет таких товаров'}
        />
      )} */}
      {/* <MessengerBubbleButton
        content={<img src={'/images/buttons/Edit-white.svg'} />}
        onClick={() => navigate('/messages')}
      /> */}
    </>
  )
}

export const UserGoodsBody = observer(UserGoods)
