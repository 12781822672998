import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  DeleteArticleCommand,
  GetArticleInfoQuery,
  GetArticleInfoResponse,
  GetArticlesListQuery,
  GetArticlesListResponse,
  PostApiArticlesCreateArticleBody,
  ShareArticleCommand,
  ViewArticleCommand,
  postApiArticlesCreateArticle,
  postApiArticlesDeleteArticle,
  postApiArticlesGetArticleInfo,
  postApiArticlesGetArticlesList,
  postApiArticlesShareArticle,
  postApiArticlesViewArticle,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useArticlesGetArticleInfoQuery(
  params: GetArticleInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetArticleInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки статьи')
    },
    queryFn: () => postApiArticlesGetArticleInfo(params),
    queryKey: ['get_article_item', String(params.articleId)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useArticlesGetArticlesListQuery(
  params: GetArticlesListQuery,
  options?: UseQueryOptions<unknown, unknown, GetArticlesListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки статей')
    },
    queryFn: () => postApiArticlesGetArticlesList(params),
    queryKey: ['get_articles_list', JSON.stringify(params)],
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useArticlesViewArticleMutation(
  options?: UseMutationOptions<unknown, unknown, ViewArticleCommand>
) {
  return useMutation({
    mutationFn: (data: ViewArticleCommand) => {
      return postApiArticlesViewArticle(data)
    },
    ...options,
  })
}
export function useArticlesShareArticleMutation(
  options?: UseMutationOptions<unknown, unknown, ShareArticleCommand>
) {
  return useMutation({
    mutationFn: (data: ShareArticleCommand) => {
      return postApiArticlesShareArticle(data)
    },
    ...options,
  })
}
export function useDeleteArticleMutation(
  options?: UseMutationOptions<unknown, unknown, DeleteArticleCommand>
) {
  return useMutation({
    mutationFn: (data: DeleteArticleCommand) => {
      return postApiArticlesDeleteArticle(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка удаления статьи')
    },
    onSuccess: () => {
      toast.success('Статья успешно удалена')
      queryClient.invalidateQueries({ queryKey: ['get_articles_list'] })
    },
    ...options,
  })
}
export function useCreateArticle(
  options?: UseMutationOptions<unknown, unknown, PostApiArticlesCreateArticleBody>
) {
  return useMutation({
    mutationFn: (data: PostApiArticlesCreateArticleBody) => {
      return postApiArticlesCreateArticle(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка создания статьи')
    },
    onSuccess: () => {
      toast.success('Статья была успешно создана')
      queryClient.invalidateQueries({ queryKey: ['get_articles_list'] })
    },
    ...options,
  })
}
