import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetCategoryInfoQuery,
  GetCategoryInfoResponse,
  GetSectionInfoQuery,
  GetSectionInfoResponse,
  GetSectionsListResponse,
  SectionListItemDto,
  postApiCategoriesGetCategoryInfo,
  postApiSectionsGetSectionInfo,
  postApiSectionsGetSectionsList,
} from '../../shared/api/generated'

export function useGetSectionsListQuery(
  params: SectionListItemDto,
  options?: UseQueryOptions<unknown, unknown, GetSectionsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки секций')
    },
    queryFn: () => postApiSectionsGetSectionsList(params),
    queryKey: ['get_sections', ...Object.values(params).map(String)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useSectionsGetSectionInfoQuery(
  params: GetSectionInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetSectionInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки названия секции')
    },
    queryFn: () => postApiSectionsGetSectionInfo(params),
    queryKey: ['get_section_name', String(params.id)],
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetSectionInfoMutate(
  params: GetSectionInfoQuery,
  options?: UseMutationOptions<GetSectionInfoResponse, unknown, unknown, unknown>
) {
  return useMutation({
    mutationFn: () => {
      return postApiSectionsGetSectionInfo(params)
    },
    ...options,
  })
}
export function useCategoriesGetCategoryInfoQuery(
  params: GetCategoryInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetCategoryInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки названия категории')
    },
    queryFn: () => postApiCategoriesGetCategoryInfo(params),
    queryKey: ['get_category_name', String(params.id)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
