import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useAuthResetPasswordMutation } from '../../../entities/passwordRestore/queries'
import { ResetPasswordCommand } from '../../../shared/api/generated'
import { PASSWORD_VALIDATION } from '../../../shared/constants/regex'
import {
  invalidPassword,
  passwordMax,
  passwordMin,
  passwordRequired,
} from '../../../shared/constants/validationMessages'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { FlexColumnStart, MediumText, StyledButton } from '../../../shared/ui-kit/projectComponents'

const Wrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 800px);
  margin: 0 auto;
`
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 24px;
  width: 100%;
`

type ResetCommandWithPasswordConfirm = { passwordConfirm: string } & ResetPasswordCommand
export const PasswordReset = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const {
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<ResetCommandWithPasswordConfirm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    const token = searchParams?.get('token')
    const userId = searchParams?.get('userId')

    if (token && userId) {
      setValue('resetToken', token)
      setValue('userId', userId)
    }
  }, [searchParams, setValue])

  const { isLoading, isSuccess, mutate } = useAuthResetPasswordMutation()
  const resetPassword = async (data: ResetPasswordCommand) => {
    mutate(data)
  }

  useEffect(() => {
    isSuccess && navigate('/login')
  }, [isSuccess, navigate])

  return (
    <Wrapper>
      <MediumText>Придумайте новый пароль</MediumText>
      <br />
      <FormWrapper onSubmit={handleSubmit(resetPassword)}>
        <TextField
          inputProps={{
            placeholder: 'Пароль',
            type: 'password',
            ...register('newPassword', {
              minLength: {
                message: passwordMin,
                value: 6,
              },
              pattern: {
                message: invalidPassword,
                value: PASSWORD_VALIDATION,
              },
              required: passwordRequired,
            }),
          }}
          error={errors.newPassword?.message?.toString()}
          label={'Введите пароль'}
          fullwidth
        />
        <TextField
          inputProps={{
            placeholder: 'Подтверждение пароля',
            type: 'password',
            ...register('passwordConfirm', {
              validate: value => value === getValues('newPassword') || 'Пароли должны совпадать',
            }),
          }}
          error={errors.passwordConfirm?.message?.toString()}
          label={'Подтвердите пароль'}
          fullwidth
        />

        <StyledButton disabled={!isValid || isLoading} fullwidth>
          Завершить восстановление пароля
        </StyledButton>
      </FormWrapper>
    </Wrapper>
  )
}
