import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { useModal } from '../../hooks/useModal'
import { Dot, DotsWrapper, FlexRow } from '../projectComponents'
import { MiniModal } from './modals/MiniModal'

const LessorImagesWrapper = styled(FlexRow)`
  margin-top: 16px;
  column-gap: 6px;
  position: relative;
  width: fit-content;
  align-self: center;
  & > img {
    cursor: pointer;
    border-radius: 8px;
    &:nth-child(5) {
      filter: blur(1px);
    }
  }
  & > div {
    position: absolute;
    top: 18px;
    right: 25px;
    font-size: 18px;
    font-weight: 600;
    color: ${colors.white};
    text-shadow:
      1px 0px 0px ${colors.black},
      -1px 0px 0px ${colors.black},
      0px 1px 0px ${colors.black},
      0px -1px 0px ${colors.black};
  }
`
const ImageForModal = styled.img`
  position: relative;
  max-height: 400px;
`

const ImagesToggler = styled.div`
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%) scale(200%);
  & > div > div {
    box-shadow:
      1px 0px 0px ${colors.lightPrimary},
      -1px 0px 0px ${colors.lightPrimary},
      0px 1px 0px ${colors.lightPrimary},
      0px -1px 0px ${colors.lightPrimary};
  }
`

export const IncomingPhotos = ({ data }) => {
  const [activePhotoIdx, setActivePhotoIdx] = useState(0)
  const { setViewModal, viewModal } = useModal()
  const handleClick = index => {
    setActivePhotoIdx(index)
    setViewModal(true)
  }
  const handleNextImage = index => {
    if (index < data?.length - 1) {
      setActivePhotoIdx(index + 1)
    } else {
      setActivePhotoIdx(0)
    }
  }

  useEffect(() => {
    !viewModal && setActivePhotoIdx(0)
  }, [viewModal, setViewModal])

  return (
    <>
      <LessorImagesWrapper>
        {data?.map((url, index) => {
          const l = data?.length

          if (index < 5) {
            return (
              <img
                alt={''}
                height={64}
                key={index}
                onClick={() => handleClick(index)}
                src={url}
                style={{ maxHeight: '64px', objectFit: 'cover' }}
                width={64}
              />
            )
          } else if (index === l - 1) {
            return <div key={index}>+{l - 4}</div>
          } else {
            return null
          }
        })}
      </LessorImagesWrapper>
      <MiniModal setViewModal={setViewModal} viewModal={viewModal}>
        <ImageForModal
          alt={''}
          onClick={() => handleNextImage(activePhotoIdx)}
          src={data[activePhotoIdx]}
          style={{ objectFit: 'cover', width: '100%' }}
        />
        <ImagesToggler>
          <DotsWrapper>
            {data?.map((_, idx) => (
              <Dot
                $selected={idx === activePhotoIdx}
                key={idx}
                onClick={() => setActivePhotoIdx(idx)}
              />
            ))}
          </DotsWrapper>
        </ImagesToggler>
      </MiniModal>
    </>
  )
}
