import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GenerateAndGetReferralCodeResponse,
  GetUserInfoQuery,
  GetUserInfoResponse,
  PostApiUsersUpdateUserAvatarBody,
  UpdatePasswordCommand,
  UpdateUserInfoCommand,
  postApiUsersGenerateAndGetReferralCode,
  postApiUsersGetUserInfo,
  postApiUsersUpdatePassword,
  postApiUsersUpdateUserAvatar,
  postApiUsersUpdateUserInfo,
} from '../../shared/api/generated'
import {
  ReviewResponseDto,
  UserResponseDto,
  getReviewsUserUserId,
  getUsers,
} from '../../shared/api/generated-old'
import { queryClient } from '../../shared/api/query-client'

export function useProfileQuery(
  options?: UseQueryOptions<UserResponseDto, unknown, UserResponseDto, string[]>
) {
  return useQuery({
    onError: err => {
      console.log('ERROR: ', err)
    },
    queryFn: getUsers,
    queryKey: ['get_profile_data'],
    refetchOnWindowFocus: false,
    ...options,
  })
}

// export function useGetMyProductsQuery(
//   options?: UseQueryOptions<ProductResponseDto[], unknown, ProductResponseDto[], string[]>
// ) {
//   return useQuery({
//     keepPreviousData: true,
//     onError: err => {
//       console.log('ERROR: ', err)
//     },
//     queryFn: getProductsOwn,
//     queryKey: ['get_my_products'],
//     refetchOnWindowFocus: false,
//     ...options,
//   })
// }

export function useGetOwnReviewsQuery(
  userId: string,
  options?: UseQueryOptions<ReviewResponseDto[], unknown, ReviewResponseDto[], string[]>
) {
  return useQuery({
    onError: err => {
      console.log('ERROR')
    },
    queryFn: () => getReviewsUserUserId(userId),
    queryKey: ['get_own_reviews'],
    ...options,
  })
}

export function usePostApiUsersGetUserInfo(
  options?: UseMutationOptions<GetUserInfoResponse, unknown, GetUserInfoQuery, unknown>
) {
  return useMutation({
    mutationFn: (data: GetUserInfoQuery) => {
      return postApiUsersGetUserInfo(data)
    },
    ...options,
  })
}
export function useUsersGetUserInfoQuery(
  userId: string,
  options?: UseQueryOptions<unknown, unknown, GetUserInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      console.error(e.response?.data?.message || 'Ошибка загрузки профиля')
    },
    queryFn: () => postApiUsersGetUserInfo({ userId }),
    queryKey: ['get_my_profile_data', userId],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGenerateAndGetReferralCodeQuery(
  userId: string,
  options?: UseQueryOptions<unknown, unknown, GenerateAndGetReferralCodeResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки реферального кода')
    },
    queryFn: () => postApiUsersGenerateAndGetReferralCode({ userId }),
    queryKey: ['get_my_referral_code', userId],
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useUsersUpdateUserAvatarMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiUsersUpdateUserAvatarBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiUsersUpdateUserAvatarBody) => {
      return postApiUsersUpdateUserAvatar(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка обновления фото пользователя')
    },
    onSuccess: () => {
      toast.success('Вы успешно обновили фото')
      queryClient.invalidateQueries({ queryKey: ['get_my_profile_data'] })
    },
    ...options,
  })
}
export function useUsersUpdateUserInfoMutation(
  options?: UseMutationOptions<unknown, unknown, UpdateUserInfoCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: UpdateUserInfoCommand) => {
      return postApiUsersUpdateUserInfo(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка обновления Вашего профиля')
    },
    onSuccess: () => {
      toast.success('Вы успешно обновили профиль')
      queryClient.invalidateQueries({ queryKey: ['get_my_profile_data'] })
    },
    ...options,
  })
}
export function useUpdatePasswordMutation(
  options?: UseMutationOptions<unknown, unknown, UpdatePasswordCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: UpdatePasswordCommand) => {
      return postApiUsersUpdatePassword(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка обновления пароля')
    },
    onSuccess: () => {
      toast.success('Вы успешно обновили пароль')
    },
    ...options,
  })
}
