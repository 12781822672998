import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useDocumentsVerifyPassportMutation } from '../../../../entities/documents/queries'
import { FlexColumn, FlexRowBetween, StyledButton } from '../../projectComponents'
import { H3 } from '../../titles'
import { TextArea } from '../TextArea'
import { MiniModal } from '../modals/MiniModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const ContentModal = styled(FlexColumn)`
  padding-bottom: 20px;
  width: 100%;
`

interface Props {
  passportId: string
  setOpen: (v: boolean) => void
  viewModal: boolean
}

export const AdminPassportVerifyModal = ({ passportId, setOpen, viewModal }: Props) => {
  const { isLoading, isSuccess, mutate } = useDocumentsVerifyPassportMutation()

  useEffect(() => {
    isSuccess && setOpen(false)
  }, [isSuccess, setOpen])

  return (
    <MiniModal setViewModal={setOpen} viewModal={viewModal}>
      <AbsoluteLoader isLoading={isLoading} />

      <H3 style={{ marginBottom: 20 }}>Верифицировать паспорт?</H3>

      <br />
      <ContentModal>
        <FlexRowBetween>
          <StyledButton onClick={() => setOpen(false)} variant={'outline'}>
            Отмена
          </StyledButton>
          <StyledButton
            onClick={() => {
              mutate({ passportId })
            }}
            disabled={isLoading}
            size={'xl'}
            style={{ width: '100%' }}
            variant={'primary'}
          >
            Верифицировать
          </StyledButton>
        </FlexRowBetween>
      </ContentModal>
    </MiniModal>
  )
}
