import { MutableRefObject, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import styled from 'styled-components/macro'

import { useViewChatMessageMutation } from '../../../../entities/chat/queries'
import { ChatMessageExtended } from '../../../../shared/api/generated'
import { queryClient } from '../../../../shared/api/query-client'
import Message from '../Message/Message'

const ChatBlock = styled.div`
  max-height: 80vh;
  width: 100%;
  position: relative;
  flex-grow: 1;
  overflow: auto;
`

const BgChat = styled.div`
  background-attachment: fixed;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background: white;
  height: 100%;
`

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  gap: 42px;
  overflow: auto;
`

interface MessageContainerProps {
  containerRef?: MutableRefObject<any> | null
  data?: ChatMessageExtended[]
  endRef?: MutableRefObject<any> | null
}
const MessageContainer = ({ containerRef, data, endRef }: MessageContainerProps) => {
  const { mutate } = useViewChatMessageMutation()

  useEffect(() => {
    const lastMessage = data[0]

    if (lastMessage?.messageId) {
      mutate({ messageId: lastMessage?.messageId })
      setTimeout(() => {
        queryClient.resetQueries({ queryKey: ['get_all_rents_info'] })
      }, 5000)
    }
  }, [data, mutate])

  return (
    <ChatBlock ref={containerRef}>
      <BgChat>
        <FlexContainer>
          <FlexWrapper>
            {data &&
              data
                .slice()
                .reverse()
                .map(message => <Message key={message.messageId} messageInfo={message} />)}
          </FlexWrapper>
        </FlexContainer>
        <div ref={endRef}></div>
      </BgChat>
    </ChatBlock>
  )
}

export default MessageContainer
