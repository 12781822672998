import { useEffect } from 'react'

import { Html5Qrcode } from 'html5-qrcode'
import styled from 'styled-components/macro'

type Props = {
  isScannerActive: boolean
  qrMessage: string
  setIsScannerActive: (value: boolean) => void
  setQrMessage: (message: string) => void
}

const Scanner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto !important;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }
  #qr-shaded-region {
    border-width: 25px !important;
  }
`
const QRCodeContainer = styled.div`
  position: relative;
  width: 375px;
  height: 375px;
  border-radius: 7px;
  overflow: hidden;
`
const StartButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 10px 20px;
  background-color: #d81414;
  border: 0;
  border-radius: 7px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
`

export const QRCodeScanner = ({
  isScannerActive,
  qrMessage,
  setIsScannerActive,
  setQrMessage,
}: Props) => {
  useEffect(() => {
    const config = { fps: 10, qrbox: 350 }

    const html5QrCode = new Html5Qrcode('qrCodeContainer')

    const qrScanerStop = () => {
      if (html5QrCode && html5QrCode.isScanning) {
        html5QrCode
          .stop()
          .then(ignore => console.log('Scaner stop'))
          .catch(err => console.log('Scaner error'))
      }
    }

    const qrCodeSuccess = decodedText => {
      setQrMessage(decodedText)
      setIsScannerActive(false)
    }
    const qrCodeError = e => {
      // console.log(e)
      // setIsScannerActive(false)
    }

    if (isScannerActive) {
      html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccess, qrCodeError)
      setQrMessage('')
    } else {
      qrScanerStop()
    }

    return () => {
      qrScanerStop()
    }
  }, [isScannerActive, setIsScannerActive, setQrMessage])

  return (
    <Scanner>
      <QRCodeContainer id={'qrCodeContainer'} />
      <StartButton onClick={() => setIsScannerActive(!isScannerActive)}>
        {isScannerActive ? 'Закрыть' : 'Включить'}
      </StartButton>
    </Scanner>
  )
}
