const getNounDeclension = (quantity: number): string => {
  const cases = ['позиции', 'позиций']

  if (quantity > 1) {
    return cases[1]
  }

  return cases[0]
}

export { getNounDeclension }
