export const PASSWORD_VALIDATION = /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^\S+$).*$)/
// export const PASSWORD_VALIDATION = /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(^\S+$).*$)/

// временно без цифр из-за пароля админа
export const PASSWORD_VALIDATION_LOGIN = /(^(?=.*[a-z])(?=.*[A-Z])(^\S+$).*$)/
// export const PASSWORD_VALIDATION_LOGIN = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]/gm
export const NO_CYRILLIC = /^[^а-яёА-ЯЁ]*$/
export const EMAIL_VALIDATION = /^[\SA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z\S]{2,4}$/i

export const NAME_VALIDATION = /^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$/

export const FULL_NAME_VALIDATION = /^[a-zA-Zа-яёА-ЯЁ0-9_-\s\S]+( [a-zA-Zа-яёА-ЯЁ0-9_-\s\S]+)*$/

export const PHONE_NUMBER_VALIDATION = /^[+0-9]+$/
export const NUMBER_VALIDATION = /^[0-9]+$/
export const SUBDIVISIONCODE_VALIDATION = /^[-0-9]+$/
