import { toast } from 'react-custom-alert'
import { UseMutationOptions, useMutation } from 'react-query'

import { AxiosError } from 'axios'

import {
  AuthResponse,
  CheckRegistrationCodeResponse,
  GetApiAuthCheckEmailRegistrationCodeParams,
  GetApiAuthCheckPhoneRegistrationCodeParams,
  SendRegistrationCodeByEmailCommand,
  SendRegistrationCodeBySmsCommand,
  SignUpCommand,
  getApiAuthCheckEmailRegistrationCode,
  getApiAuthCheckPhoneRegistrationCode,
  postApiAuthSendRegistrationCodeByEmail,
  postApiAuthSendRegistrationCodeBySms,
  postApiAuthSignUp,
} from '../../shared/api/generated'
import {
  PostAuthCodeVerifyParams,
  PostAuthSignupBody,
  postAuthCodeVerify,
  postAuthSignup,
} from '../../shared/api/generated-old'

export function useSignupMutation(
  options?: UseMutationOptions<any, unknown, PostAuthSignupBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostAuthSignupBody) => {
      return postAuthSignup(data)
    },
    ...options,
  })
}
export function useSignUpPhoneEmailMutation(
  options?: UseMutationOptions<AuthResponse, unknown, SignUpCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SignUpCommand) => {
      if (data.referralCode) {
        return postApiAuthSignUp(data)
      } else {
        data.referralCode = null

        return postApiAuthSignUp(data)
      }
    },

    ...options,
  })
}

export function useVerifyCodeMutation(
  options?: UseMutationOptions<any, unknown, PostAuthCodeVerifyParams, unknown>
) {
  return useMutation({
    mutationFn: (data: PostAuthCodeVerifyParams) => postAuthCodeVerify(data),
    ...options,
  })
}
export function useSendRegistrationCodeBySmsMutation(
  options?: UseMutationOptions<unknown, unknown, SendRegistrationCodeBySmsCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SendRegistrationCodeBySmsCommand) => {
      return postApiAuthSendRegistrationCodeBySms(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      if (e.request?.status == 429) {
        toast.error('Слишком много запросов')
      } else {
        toast.error(e.response?.data?.message || 'Ошибка отправки СМС')
      }
    },
    onSuccess: () => {
      toast.success('СМС c кодом отправлена на ваш телефон')
    },
    ...options,
  })
}

export function useAuthSendRegistrationCodeByEmailMutation(
  options?: UseMutationOptions<unknown, unknown, SendRegistrationCodeByEmailCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SendRegistrationCodeByEmailCommand) => {
      return postApiAuthSendRegistrationCodeByEmail(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки кода на email')
    },
    onSuccess: () => {
      toast.success('Код отправлен на ваш email')
    },
    ...options,
  })
}
export function useAuthCheckPhoneRegistrationCodeMutation(
  options?: UseMutationOptions<
    CheckRegistrationCodeResponse,
    unknown,
    GetApiAuthCheckPhoneRegistrationCodeParams,
    unknown
  >
) {
  return useMutation({
    mutationFn: (data: GetApiAuthCheckPhoneRegistrationCodeParams) => {
      return getApiAuthCheckPhoneRegistrationCode(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка проверки СМС кода')
    },
    onSuccess: data => {
      if (data.isCodeValid) {
        toast.success('СМС код подтвержден')
      } else {
        toast.error('CMC код не прошел проверку')
      }
    },
    ...options,
  })
}
export function useAuthCheckEmailRegistrationCodeMutation(
  options?: UseMutationOptions<
    CheckRegistrationCodeResponse,
    unknown,
    GetApiAuthCheckEmailRegistrationCodeParams,
    unknown
  >
) {
  return useMutation({
    mutationFn: (data: GetApiAuthCheckEmailRegistrationCodeParams) => {
      return getApiAuthCheckEmailRegistrationCode(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка проверки email кода')
    },
    onSuccess: data => {
      if (data.isCodeValid) {
        toast.success('Email код подтвержден')
      } else {
        toast.error('Email код не прошел проверку')
      }
    },
    ...options,
  })
}

export function useEmailSignUpMutation(
  options?: UseMutationOptions<AuthResponse, unknown, SignUpCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: SignUpCommand) => {
      return postApiAuthSignUp(data)
    },
    ...options,
  })
}
