import styled, { css } from 'styled-components/macro'

import { colors } from '../../constants/colors'

interface StyledButtonProps {
  fullwidth?: boolean
  isDisabled?: boolean //deprecated
  isIcoBtn?: boolean
  mini?: boolean //deprecated
  size?: TBUTTON_SIZE_VARIANT
  variant?: TBUTTON_COlOR_VARIANT
}

const TBUTTON_COlOR_VARIANT = {
  danger: 'danger',
  disabled: 'disabled',
  light: 'light',
  neutral: 'neutral',
  outline: 'outline',
  primary: 'primary',
  secondary: 'secondary',
  warning: 'warning',
} as const

// eslint-disable-next-line no-redeclare
export type TBUTTON_COlOR_VARIANT = keyof typeof TBUTTON_COlOR_VARIANT

export type TBUTTON_SIZE_VARIANT = 'md' | 's' | 'sm' | 'small' | 'xl' | 'xs' | 'xxl'

export const BTN_COLOR_VARIANTS = (variant: TBUTTON_COlOR_VARIANT) =>
  ({
    danger: css`
      color: ${colors.white};
      background-color: ${colors.redFail};
    `,
    disabled: css`
      color: ${colors.grey};
      background-color: ${colors.lightGray};
      :hover {
        opacity: 1;
        transition: none;
      }
    `,
    light: css`
      color: ${colors.grey};
      background-color: ${colors.white};
    `,
    neutral: css`
      background-color: transparent;
    `,
    outline: css`
      color: ${colors.primary};
      background-color: ${colors.white};
      border: solid 1px ${colors.primary};
    `,
    primary: css`
      color: ${colors.white};
      background-color: ${colors.primary};
    `,
    secondary: css`
      color: ${colors.primary};
      background-color: ${colors.secondary};
    `,
    warning: css`
      color: ${colors.red};
      background-color: ${colors.lightRed};
    `,
  })[variant]

export const BTN_SIZE_VARIANTS = (variant: TBUTTON_SIZE_VARIANT) => {
  return {
    md: css`
      max-width: 160px;
      width: 100%;
      min-height: 50px;
      height: max-content;
      padding: 10px 18px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      border-radius: 15px;
    `,
    s: css`
      height: 34px;
      width: 34px;
      padding: 6px 6px;
      font-size: 10.769px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 16.154px */
      border-radius: 10.096px;
    `,
    sm: css`
      height: 34px;
      padding: 6.731px 16.115px;
      font-size: 10.769px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 16.154px */
      border-radius: 10.096px;
    `,
    small: css`
      min-height: 34px !important;
      height: 34px !important;
      min-width: 34px !important;
      width: 34px !important;
      font-weight: 600;
      border-radius: 10.096px;
    `,
    xl: css`
      max-width: 327px;
      width: 100%;
      height: 50px;
      padding: 10px 18px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      border-radius: 15px;
    `,
    xs: css`
      max-width: 99px;
      width: fit-content;
      height: fit-content;
      padding: 0;
      font-size: 10.769px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 16.154px */
      border-radius: 10.096px;
    `,
    xxl: css`
      max-width: 160px;
      width: 100%;
      height: 50px;
      padding: 10px 18px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      border-radius: 15px;
    `,
  }[variant]
}

export const ICON_BUTTON_STYLES = () => css`
  width: fit-content;
  height: fit-content;
  min-height: 40px;
  min-width: 40px;
  border-radius: 100px;
`
export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  text-decoration: none;

  ${({ variant }) => BTN_COLOR_VARIANTS(variant || 'primary')}
  ${({ size }) => BTN_SIZE_VARIANTS(size || 'md')}
  ${({ mini }) => mini && BTN_SIZE_VARIANTS('sm')}
  ${({ isIcoBtn }) => isIcoBtn && ICON_BUTTON_STYLES}

  max-width: ${({ fullwidth }) => fullwidth && 'clamp(300px, 100%, 800px)'};

  :disabled {
    ${BTN_COLOR_VARIANTS('disabled')}
    cursor: not-allowed;
  }

  :hover {
    opacity: 0.6;
    transition: all ease-in 0.2s;
  }
`

export const StyledBtnSmall = styled(StyledButton)`
  flex: 0 1;
`
