import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetApiChatsGetChatsListParams,
  GetChatsListResponse,
  ViewChatMessageCommand,
  getApiChatsGetChatsList,
  postApiChatsViewChatMessage,
} from '../../shared/api/generated'

export function useGetChatsListQuery(
  params: GetApiChatsGetChatsListParams,
  options?: UseQueryOptions<unknown, unknown, GetChatsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки чатов')
    },
    queryFn: () => getApiChatsGetChatsList(params),
    queryKey: ['get_chats_list', ...Object.values(params).map(String)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useViewChatMessageMutation(
  options?: UseMutationOptions<unknown, unknown, ViewChatMessageCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ViewChatMessageCommand) => {
      return postApiChatsViewChatMessage(data)
    },

    ...options,
  })
}
