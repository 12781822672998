import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ReviewType } from '../../../../shared/api/generated'
import { FlexColumn, MediumTextL, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'
import { FeedbackForm } from '../FeedbackForm'

type Props = {
  back: () => void
}

export const Step7 = ({ back }: Props) => {
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const { rentId } = useParams()
  const rentIdNumber = parseInt(rentId)

  return (
    <FlexColumn>
      <img
        alt={''}
        src={'/images/buttons/check-white-on-bg.svg'}
        style={{ height: 40, marginTop: '100px', width: 40 }}
      />
      <br />
      <H2>Все в порядке</H2>
      <MediumTextL>Товар успешно возвращен вам</MediumTextL>
      <br />
      <H2 style={{ marginBottom: '16px', textAlign: 'center' }}>Оцените арендатора</H2>
      <FeedbackForm
        comment={comment}
        rating={rating}
        rentId={rentIdNumber}
        reviewType={ReviewType.aboutBuyer}
        setComment={setComment}
        setRating={setRating}
      />
      <StyledButton onClick={back} fullwidth>
        На главную
      </StyledButton>
    </FlexColumn>
  )
}
