import React, { ReactNode, useContext } from 'react'

import UserStorage from '../../../storage/UserStorage/UserStorage'
import { BasePage, DesktopView, NeutralLink } from '../../projectComponents'
import { BubbleButtonContainer } from '../BubbleButtonContainer'

export const BasePageLayout = ({
  children,
  style,
}: {
  children: ReactNode
  style?: React.CSSProperties
}) => {
  const { isAuth } = useContext(UserStorage)

  return (
    <BasePage style={style}>
      {children}
      {isAuth && (
        <DesktopView>
          <BubbleButtonContainer className={'buble_btn_container'} style={{ bottom: '36px' }}>
            <NeutralLink target={'_blank'} to={'https://t.me/Allsharing_support'}>
              <img height={50} src={'/images/buttons/headphones.svg'} width={50} />
            </NeutralLink>
          </BubbleButtonContainer>
        </DesktopView>
      )}
    </BasePage>
  )
}
