import { createContext } from 'react'

import jwtDecode from 'jwt-decode'
import { action, autorun, makeAutoObservable } from 'mobx'

import AuthService from '../../api/deprecated/authService/AuthService'

export interface IUser {
  avatar?: File | null | string
  city: string
  code: string
  firstName: string
  lastName: string
  legalStatus: string
  phone: string
  userName: string
}

export interface IResponse {
  accessToken: string
  refreshToken: string
}

export type UserFromJWT = {
  IssuedAt: string
  UserId: string
  UserName: string
  UserRole: string
  exp: number
  nbf: number
}
class UserStorage {
  accessToken: null | string = localStorage.accessToken
    ? JSON.parse(localStorage.accessToken)
    : null
  isAuth: boolean | null = localStorage.isAuth ? JSON.parse(localStorage.isAuth) : false
  refreshToken: null | string = localStorage.refreshToken
    ? JSON.parse(localStorage.refreshToken)
    : null
  user: UserFromJWT | null = localStorage.user ? JSON.parse(localStorage.user) : null

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    autorun(() => {
      localStorage.user = JSON.stringify(this.user)
      localStorage.accessToken = JSON.stringify(this.accessToken)
      localStorage.refreshToken = JSON.stringify(this.refreshToken)
      localStorage.isAuth = JSON.stringify(this.isAuth)
    })
  }

  @action logout() {
    localStorage.clear()
    this.user = null
    this.accessToken = null
    this.refreshToken = null
    this.isAuth = false
  }

  @action setIsAuth(status: boolean) {
    this.isAuth = status
  }

  @action setTokens(response: IResponse) {
    this.accessToken = response.accessToken
    this.refreshToken = response.refreshToken
  }

  @action setUser(data: UserFromJWT) {
    this.user = data
  }
  get userId() {
    return this.user?.UserId
  }
}

export default createContext(new UserStorage())
