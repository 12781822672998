import { memo, useMemo } from 'react'

import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import { apiOwnKeys } from 'mobx/dist/internal'
import styled from 'styled-components/macro'

const MapWrapper = styled.div<{ height: number; width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  @media all and (max-width: 800px) {
    width: 327px;
    height: 107px;
  }
`

interface MapContainerProps {
  additionalControls?: string[]
  coordinates?: number[]
  height?: number
  isPlacemark?: boolean
  onMapClick?: (event: any) => void
  onPlacemarkClick?: (event: any) => void
  width?: number
  zoom?: number
}

export const MapContainer = memo(
  ({
    additionalControls = [],
    coordinates = [],
    height = 307,
    isPlacemark,
    onMapClick,
    onPlacemarkClick,
    width = 358,
    zoom = 16,
  }: MapContainerProps) => {
    const modules = useMemo(
      () => ['control.ZoomControl', 'control.FullscreenControl', ...additionalControls],
      [additionalControls]
    )

    return (
      <MapWrapper height={height} width={width}>
        <YMaps>
          <Map
            defaultState={{
              center: coordinates,
              controls: ['zoomControl', 'fullscreenControl'],
              zoom,
            }}
            height={'100%'}
            modules={modules}
            onClick={onMapClick}
            width={'100%'}
          >
            {isPlacemark && <Placemark defaultGeometry={coordinates} onClick={onPlacemarkClick} />}
          </Map>
        </YMaps>
      </MapWrapper>
    )
  }
)
