import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { useDisableBodyScroll } from '../../../hooks/use-disable-body-scroll'
import { CloseModalBtn, FlexColumn } from '../../projectComponents'
import { verticalScroll } from '../../scroll'

const ModalBackground = styled.div<{ $viewModal: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100lvh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  z-index: 2200;
  transition: 0.4s ease-in;
  visibility: ${({ $viewModal }) => ($viewModal ? '' : 'hidden')};
  opacity: ${({ $viewModal }) => ($viewModal ? '1' : '0')};

  & > div {
    transform: ${({ $viewModal }) => ($viewModal ? 'translateY(0)' : 'translateY(100vh)')};
    transition: 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  }
`

const ModalWrapper = styled(FlexColumn)<{ minWidth?: boolean }>`
  position: relative;
  max-height: 95vh;
  padding: 24px;
  width: ${props => (props.minWidth ? `fit-content` : `clamp(300px, 90%, 800px)`)};
  box-sizing: border-box;
  border-radius: 24px;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  overflow: auto;
  /* ${verticalScroll} */
`

type Props = {
  children?: ReactNode
  minWidth?: boolean
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}

const preventClick = e => {
  e.stopPropagation()
}

export const MiniModal = ({ children, minWidth, setViewModal, viewModal }: Props) => {
  useDisableBodyScroll(viewModal)

  return createPortal(
    <ModalBackground
      onClick={() => {
        setViewModal(false)
      }}
      $viewModal={viewModal}
    >
      <ModalWrapper minWidth={minWidth} onClick={preventClick}>
        {children}
        <CloseModalBtn
          onClick={() => {
            setViewModal(false)
          }}
        >
          <img alt={'Close'} src={'/images/buttons/close.png'} style={{ height: 20, width: 20 }} />
        </CloseModalBtn>
      </ModalWrapper>
    </ModalBackground>,
    document.body
  )
}
