import { ProfileSwitchTabs } from '../../../../shared/ui-kit/actual/ProfileSwitchTabs'
import { WrapperMobile } from '../../../../shared/ui-kit/projectComponents'
import { ProfileAbout } from './ProfileAbout'
import { ProfileGoodsBody } from './ProfileGoodsBody'

type Props = {
  activeTab: number
  setActiveTab: (activeTab: number) => void
  setBalanceModal: (viewModal: boolean) => void
  setPayoutModal: (value: boolean) => void
  setRatingModal: (value: boolean) => void
}

export const ProfileBodyWrapper = ({
  activeTab,
  setActiveTab,
  setBalanceModal,
  setPayoutModal,
  setRatingModal,
}: Props) => {
  return (
    <WrapperMobile>
      <ProfileSwitchTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabHead0={'Товары'}
        tabHead1={'Обо мне'}
      />
      {!activeTab ? (
        <ProfileGoodsBody />
      ) : (
        <ProfileAbout
          setBalanceModal={setBalanceModal}
          setPayoutModal={setPayoutModal}
          setRatingModal={setRatingModal}
        />
      )}
    </WrapperMobile>
  )
}
