import { ReviewListItemDto } from '../../../shared/api/generated'
import { ProfileSwitchTabs } from '../../../shared/ui-kit/actual/ProfileSwitchTabs'
import { WrapperMobile } from '../../../shared/ui-kit/projectComponents'
import { RenterReviewTab } from './RenterReviewTab'

type Props = {
  activeTab: number
  buyerReviewsItems: ReviewListItemDto[]
  sellerReviewsItems: ReviewListItemDto[]
  setActiveTab: (activeTab: number) => void
}

export const UserReviewWrapper = ({
  activeTab,
  buyerReviewsItems,
  sellerReviewsItems,
  setActiveTab,
}: Props) => {
  return (
    <WrapperMobile>
      <ProfileSwitchTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabHead0={'Арендатор'}
        tabHead1={'Арендодатель'}
      />
      {!activeTab ? (
        <RenterReviewTab reviews={buyerReviewsItems} isBuyer />
      ) : (
        <RenterReviewTab reviews={sellerReviewsItems} />
      )}
    </WrapperMobile>
  )
}
