/* eslint-disable perfectionist/sort-enums */
import { apiClient } from '../../serverConstants'

export enum DELIVERY_TYPE {
  City = 1,
  Country = 2,
  None = 0,
}

export enum SORTING_TYPE {
  //new Api
  byDefault = 'byDefault',
  byDate = 'byDate',
  byRentsCount = 'byRentsCount',
  byRating = 'byRating',
  byDayPrice = 'byDayPrice',
  byWeekPrice = 'byWeekPrice',
  byMonthPrice = 'byMonthPrice',

  //old Api
  By3DaysAPrice = 'By3DaysAPrice',
  By3DaysDPrice = 'By3DaysDPrice',
  ByADate = 'ByADate',
  ByAOrders = 'ByAOrders',
  ByARating = 'ByARating',
  ByCategory = '',
  ByDDate = 'ByDDate',
  ByDOrders = 'ByDOrders',
  ByDRating = 'ByDRating',
  ByDayAPrice = 'ByDayAPrice',
  ByDayDPrice = 'ByDayDPrice',
  ByMonthAPrice = 'ByMonthAPrice',
  ByMonthDPrice = 'ByMonthDPrice',
  ByWeekAPrice = 'ByWeekAPrice',
  ByWeekDPrice = 'ByWeekDPrice',
  Cars = 'cars',
  Clothes = 'clothes',
  Default = 'Default',
  Electronics = 'Electronics',
  DigitalTech = 'digitalTech',
  Motors = 'motors',
  People = 'people',
  RealEstate = 'realEstate',
  Tools = 'tools',
}

export interface IProductRequest {
  Address: string
  City: string
  DeliveryPrice: number
  DeliveryType: DELIVERY_TYPE
  Description: string
  Images: any[]
  Name: string
  Pledge: number
  Price3Days: number
  PriceDay: number
  PriceMonth: number
  PriceWeek: number
  SectionId: number
  Tags: any[]
}

export interface IProductSearchRequest {
  city?: string
  deliveryType?: null | number
  name?: string
  offset?: number
  ownerId?: string
  price3DaysFrom?: number
  price3DaysUpTo?: number
  priceDayFrom?: number
  priceDayUpTo?: number
  priceMonthFrom?: number
  priceMonthUpTo?: number
  priceWeekFrom?: number
  priceWeekUpTo?: number
  ratingFrom?: number
  sectionId?: number
  showDeleted?: boolean
  sortType: SORTING_TYPE

  tags?: string[]
}

export interface IProductSearchResponse {
  address: string
  categoryId: number
  city: string
  createdAt: string
  deliveryPrice: number
  deliveryType: DELIVERY_TYPE
  description: string
  id: string
  imageLinks: string[]
  isRemoved: boolean
  isRented: boolean
  name: string
  orders: number
  owner: {
    avatarLink: string
    firstName: string
    id: string
    lastName: string
  }
  pledge: number
  price3Days: number
  priceDay: number
  priceMonth: number
  priceWeek: number
  rating: number
  reviewsCount: number
  sectionId: number
  tags: string[]
  votes: number
}

export const getBlob = async (data: any) => {
  return await fetch(data).then(r => r.blob())
}

class ProductService {
  async createProduct(newProduct: FormData, token?: string) {
    const response = await apiClient.post(`/products`, newProduct, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  }

  async deleteMyProduct(id: string, token: string) {
    const response = await apiClient.delete(`/products/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  }

  async getAllProducts(token: string, productSortType: string) {
    const response = await apiClient.get(
      `/products/all?ShowDeleted=true&SortType=${productSortType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }

  // async getMyProducts(token: string) {
  //   const response = await apiClient.get(`/products/own`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })

  //   return response.data
  // }

  async getProductById(id: string) {
    const response = await apiClient.get(`/products/${id}`)

    return response.data
  }

  async searchProduct(searchedProduct: IProductSearchRequest) {
    const response = await apiClient.post(`/products/search`, {
      ...searchedProduct,
    })

    return response.data
  }
}

export default new ProductService()
