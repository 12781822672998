import { useEffect, useState } from 'react'

import { UseMutateFunction } from '@tanstack/react-query'
import moment from 'moment'

import {
  GetProductInfoResponse,
  GetProductRentPriceQuery,
  GetProductRentPriceResponse,
} from '../../../../shared/api/generated'
import { CalendarDatePicker } from '../../../../shared/ui-kit/actual/CalendarDatePicker'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumnStart,
  FlexRowBetween,
  LoaderWrapper,
  StyledButton,
  SubTitle,
  Title,
} from '../../../../shared/ui-kit/projectComponents'
import { ProductInfo } from '../components/ProductsInfo'

const FirstStep = ({
  currentStage,
  endDateString,
  getProductInfoQuery,
  getRentPrice,
  isLoading,
  isPriceLoaded,
  isPriceLoading,
  price,
  reset,
  setCurrentStage,
  setEndDateString,
  setStartDateString,
  startDateString,
}: {
  currentStage: number
  endDateString: string
  getProductInfoQuery: GetProductInfoResponse
  getRentPrice: UseMutateFunction<
    GetProductRentPriceResponse,
    unknown,
    GetProductRentPriceQuery,
    unknown
  >
  isLoading: boolean
  isPriceLoaded: boolean
  isPriceLoading: boolean
  price: number
  reset: () => void
  setCurrentStage: (it: number) => void
  setEndDateString: (it: string) => void
  setStartDateString: (it: string) => void
  startDateString: string
}) => {
  // const [isSuccess, setIsSuccess] = useState(true)

  const [intervalLength, setIntervalLength] = useState(0)

  // const { data: excludeIntervals, mutate: getPeriodsMutate } =
  //   useRentsGetProductRentPeriodsMutation()

  // useEffect(() => {
  //   getPeriodsMutate({ productId: getProductInfoQuery.id })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // mock data
  // const excludeIntervals = {
  //   periods: [
  //     {
  //       periodEnd: '2024-05-20',
  //       periodStart: '2024-05-10',
  //     },
  //   ],
  // }
  // const excludeDateIntervals = useMemo(() => {
  //   const result = excludeIntervals?.periods.map(interval => ({
  //     end: new Date(interval.periodEnd),
  //     start: new Date(interval.periodStart),
  //   }))

  //   return result || []
  // }, [excludeIntervals])

  useEffect(() => {
    if (startDateString && endDateString && getProductInfoQuery.id) {
      getRentPrice({
        periodFrom: startDateString,
        periodTo: endDateString,
        productId: getProductInfoQuery.id,
      })
    } else {
      reset()
    }
  }, [startDateString, endDateString, getProductInfoQuery.id, getRentPrice, reset])

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <PageLoader />
        </LoaderWrapper>
      )}
      <AbsoluteLoader isLoading={isPriceLoading} />
      <FlexColumnStart style={{ paddingBottom: '64px', width: '100%' }}>
        {/* <button onClick={() => setIsSuccess(prev => !prev)}>
          {isSuccess ? 'Даты заказа недоступны' : 'Даты заказа доступны'}
        </button> */}

        <Title>Выберите даты аренды</Title>
        <div style={{ margin: '24px 0' }}>
          <ProductInfo
            img={getProductInfoQuery.imagesUrls[0] || '/images/interface/EmptyBox.svg'}
            name={getProductInfoQuery.name}
            price={getProductInfoQuery.pledge}
            // size={'S'}
          />
        </div>
        <div style={{ alignSelf: 'center' }}>
          <CalendarDatePicker
            // endDateString={endDateString}
            minDate={moment().toDate()}
            setEndDateString={setEndDateString}
            setIntervalLength={setIntervalLength}
            setStartDateString={setStartDateString}
            // startDateString={startDateString}
            // excludeDateIntervals={excludeDateIntervals}
            isSuccess
          />
          {/* {!isSuccess && (
            <ErrorText hasError>Данная дата занята. Пожалуйста, попробуйте другую</ErrorText>
          )} */}
        </div>
        <FlexRowBetween style={{ marginTop: '36px' }}>
          <Title>К оплате</Title>
          <SubTitle style={{ fontWeight: 600 }}>{price || '0'} ₽</SubTitle>
        </FlexRowBetween>
        <StyledButton
          onClick={() => {
            setCurrentStage(currentStage <= 3 ? currentStage + 1 : currentStage)
          }}
          disabled={!isPriceLoaded || isLoading || isPriceLoading}
          style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
          variant={'primary'}
        >
          Далее
        </StyledButton>
      </FlexColumnStart>
    </>
  )
}

export default FirstStep
