import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'
import { useHookFormMask } from 'use-mask-input'

import { useSendAuthSMSMutation } from '../../../entities/auth/queries'
import { PASSWORD_VALIDATION_LOGIN } from '../../../shared/constants/regex'
import {
  invalidPassword,
  passwordMax,
  passwordMin,
} from '../../../shared/constants/validationMessages'
import { PHONE_RULES } from '../../../shared/rules/PhoneRules'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumnStart, MediumText, StyledButton } from '../../../shared/ui-kit/projectComponents'
import { convertToPhonNumber } from '../../../shared/utils/convertToPhonNumber'
import { usePhoneSignInForm } from '../model/use-phone-signin-form'

const Wrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 500px);
  margin: 0 auto;
`
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 24px;
  width: 100%;
`

export const PhoneSignInForm = () => {
  const navigate = useNavigate()

  const { errors, getValues, handleSubmit, isValid, register, signInMutation } =
    usePhoneSignInForm()
  const registerWithMask = useHookFormMask(register)
  const phone = getValues('phone')
  const {
    isLoading: isResendLoading,
    isSuccess: isResendSuccess,
    mutate: resendMutate,
  } = useSendAuthSMSMutation()

  const handleSendCode = () => {
    resendMutate({ phone })
  }

  useEffect(() => {
    isResendSuccess && navigate(phone)
  }, [isResendLoading, isResendSuccess, navigate, phone])

  return (
    <Wrapper>
      <AbsoluteLoader isLoading={isResendLoading} />

      <FormWrapper onSubmit={handleSubmit}>
        <TextField
          inputProps={{
            ...registerWithMask('phone', ['(999)999-9999', '9999999999'], {
              ...PHONE_RULES,
              minLength: {
                message: 'Номер обязателен',
                value: 12,
              },
              setValueAs: convertToPhonNumber,
            }),
            placeholder: 'Телефон',
            type: 'tel',
          }}
          error={errors.phone?.message}
          label={'Начните с кода оператора, не используйте пробелы'}
          style={{ position: 'relative' }}
          fullwidth
        />
        <TextField
          inputProps={{
            placeholder: 'Введите пароль',
            type: 'password',
            ...register('password', {
              minLength: {
                message: passwordMin,
                value: 6,
              },
              pattern: {
                message: invalidPassword,
                value: PASSWORD_VALIDATION_LOGIN,
              },
              required: 'Пароль не нужен для входа по СМС',
            }),
          }}
          error={errors.password?.message?.toString()}
          label={'Пароль'}
          fullwidth
        />
        <StyledButton disabled={!isValid || signInMutation.isLoading} fullwidth>
          Войти используя пароль
        </StyledButton>
      </FormWrapper>
      <br />
      <MediumText style={{ alignSelf: 'center' }}>или</MediumText>
      <br />
      <StyledButton
        disabled={!!errors.phone?.message || signInMutation.isLoading}
        onClick={handleSendCode}
        variant={'secondary'}
        fullwidth
      >
        Запросите СМС код на свой телефон
      </StyledButton>
    </Wrapper>
  )
}
