export const colors = {
  black: '#272728',
  blueGray: '#94A3B8',
  dark: '#222222',
  darkBlue: '#0C1A30',
  darkGray: '#3a3939',
  darkGrayText: '#A0A6A6',
  darkGrey: '#838589',
  disabledSwitch: '#E4E2E2',
  gray: '#757575',
  greenSuccess: '#50c878',
  grey: '#6E6E70',
  lightBlueBg: '#dbf2f8',
  lightGray: '#F8F8FA',
  lightGrayBg: '#F7F8FA',
  lightGrey: '#E0E5E6',
  lightPrimary: '#D3CEF6',
  lightRed: '#FFF4F4',
  mediumBlue: '#028ab4',
  mediumGray: '#e8e8e8',
  mediumGrey: '#E0E5E6',
  middleGray: '#C4C5C4',
  //actual
  primary: '#008C8C',

  //deprecated
  primaryActive: '#008080',
  primaryBg: '#77C0C1',
  red: '#FD7163',
  redFail: '#ff4c5b',
  redTransparent: 'rgba(253,113,99,0.2)',
  secondary: '#E8F5F5',
  white: '#FFFFFF',
  yellowGreen: '#82C515',
}
