import React from 'react'

import { UserListItem } from '../../../shared/api/generated'
import AdminUsersTableRow from './AdminUserTableRow'

type Props = {
  isLoading: boolean
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPaymentsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setRolesOpen: React.Dispatch<React.SetStateAction<boolean>>
  setUserId: React.Dispatch<React.SetStateAction<string>>
  users: UserListItem[]
}
const EmptyTableBody = ({ text }: { text: string }) => (
  <tr>
    <td style={{ width: '40px' }}>
      <img
        alt={''}
        src={'/images/buttons/user-avatar.png'}
        style={{ borderRadius: '100%', height: 30, objectFit: 'cover', width: 30 }}
      />
    </td>
    <td style={{ minWidth: '250px' }}>{text}</td>
  </tr>
)

export const AdminUsersTableBody = ({
  isLoading,
  setDeleteOpen,
  setPaymentsOpen,
  setRolesOpen,
  setUserId,
  users,
}: Props) => {
  return (
    <tbody>
      {isLoading ? (
        <EmptyTableBody text={'Загрузка списка пользователей...'} />
      ) : (
        <>
          {users?.map(user => (
            <AdminUsersTableRow
              key={user.id}
              setDeleteOpen={setDeleteOpen}
              setPaymentsOpen={setPaymentsOpen}
              setRolesOpen={setRolesOpen}
              setUserId={setUserId}
              user={user}
            />
          ))}
        </>
      )}
      {users && !users?.length && <EmptyTableBody text={'Пользователи не найдены '} />}
    </tbody>
  )
}
