import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import creditCardType from 'credit-card-type'
import styled from 'styled-components/macro'
import { useHookFormMask } from 'use-mask-input'

import {
  useAddBankCardMutation,
  useRemoveBankCardMutation,
} from '../../../../entities/payments/queries'
import { AddBankCardCommand, GetPaymentMethodsResponse } from '../../../api/generated'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { truncateString } from '../../../utils/truncate'
import {
  FlexColumn,
  FlexColumnBetween,
  FlexRowBetween,
  FlexRowGap,
  StyledButton,
} from '../../projectComponents'
import { horizontalScroll } from '../../scroll'
import { H4 } from '../../titles'
import { NoProducts } from '../NoProducts'
import { PayCard } from '../PayCard'
import { TextField } from '../TextField'
import { MiniModal } from '../modals/MiniModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const ListWrapper = styled(FlexRowGap)`
  width: 100%;
  overflow: auto;
  column-gap: 8px;
  padding-bottom: 4px;
  ${horizontalScroll}
`

const NoContentWrapper = styled(FlexColumnBetween)`
  width: 100%;
  height: 100%;
  row-gap: 8px;
`
const cardColors = { Mastercard: '#FFE2F2', Mir: '#FFF1E0', Visa: '#E2ECFF' }
// const mockData: BankCardListItemDto[] = [
//   { cardNumber: '2200300566993373', id: nanoid(), paymentSystem: 'Mir' },
//   { cardNumber: '5123760359128497', id: nanoid(), paymentSystem: 'Mastercard' },
//   { cardNumber: '4817760359138345', id: nanoid(), paymentSystem: 'Visa' },
// ]

// const mockData = []
type InnerComponentsProps = {
  data: GetPaymentMethodsResponse
  selectedId?: string
  setSelectedId?: (selectedId: string) => void
  setSelectedNumber?: (selectedNumber: string) => void
}
export const CardsList = ({
  data,
  selectedId,
  setSelectedId,
  setSelectedNumber,
}: InnerComponentsProps) => {
  return (
    <ListWrapper>
      {data?.bankCards?.map(card => (
        <PayCard
          onClick={() => {
            setSelectedId(card.id)
            setSelectedNumber(card.cardNumber)
          }}
          cardNumber={truncateString(card.cardNumber, 7)}
          color={cardColors[card.paymentSystem]}
          isSelected={selectedId === card.id}
          key={card.id}
          paymentSystem={card.paymentSystem}
        />
      ))}
    </ListWrapper>
  )
}

export const NoContent = () => (
  <NoContentWrapper>
    <NoProducts
      imgPath={'/images/interface/coins-transparent.svg'}
      message={'У вас еще нет методов оплаты'}
      style={{ height: '225px' }}
    />
  </NoContentWrapper>
)

type PayCardModalProps = {
  selectedCardId?: string
  selectedCardNumber?: string
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}
export const DeletePayCardModal = ({
  selectedCardId,
  selectedCardNumber,
  setViewModal,
  viewModal,
}: PayCardModalProps) => {
  const { userId } = useContext(UserStorage)

  const { isLoading, isSuccess, mutate } = useRemoveBankCardMutation()
  const handleDeleteCard = () => {
    mutate({ bankCardId: selectedCardId, ownerId: userId })
  }

  useEffect(() => {
    if (isSuccess) {
      setViewModal(false)
    }
  }, [isSuccess, setViewModal])

  return (
    <MiniModal setViewModal={setViewModal} viewModal={viewModal} minWidth>
      <AbsoluteLoader isLoading={isLoading} />

      <FlexColumn>
        <br />
        <H4>Вы уверены, что хотите удалить карту {selectedCardNumber}?</H4>
        <br />
        <FlexRowBetween style={{ gap: '24px' }}>
          <StyledButton onClick={() => setViewModal(false)} variant={'outline'} fullwidth>
            Отмена
          </StyledButton>
          <StyledButton
            disabled={isLoading}
            onClick={handleDeleteCard}
            variant={'warning'}
            fullwidth
          >
            Удалить
          </StyledButton>
        </FlexRowBetween>
        <br />
      </FlexColumn>
    </MiniModal>
  )
}
export const AddPayCardModal = ({ setViewModal, viewModal }: PayCardModalProps) => {
  const { userId } = useContext(UserStorage)
  const { isLoading, isSuccess, mutate } = useAddBankCardMutation()

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
  } = useForm<AddBankCardCommand>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })
  const registerWithMask = useHookFormMask(register)

  useEffect(() => {
    if (isSuccess) {
      setViewModal(false)
      setValue('cardNumber', '')
      setCardNumber('')
    }
  }, [isSuccess, setValue, setViewModal])

  const [cardNumber, setCardNumber] = useState('')
  const numberCardNumber = useMemo(
    () =>
      cardNumber
        .split('')
        .filter(char => char !== ' ' && !isNaN(+char))
        .join(''),
    [cardNumber]
  )

  const handleAddCard = handleSubmit((data: AddBankCardCommand) => {
    data.paymentSystem = creditCardType(numberCardNumber)?.[0]?.niceType || 'Unknown'
    data.cardNumber = numberCardNumber
    data.ownerId = userId
    mutate(data)
  })

  return (
    <MiniModal setViewModal={setViewModal} viewModal={viewModal} minWidth>
      <AbsoluteLoader isLoading={isLoading} />
      <FlexColumn>
        <br />
        <H4>Введите номер банковской карты</H4>
        <br />

        <TextField
          inputProps={{
            ...registerWithMask('cardNumber', ['9999 9999 9999 9999', '9999999999999999'], {
              maxLength: 19,
              minLength: 13,
            }),
            autoComplete: 'cc-number',
            name: 'cardnumber',
            onChange: e => setCardNumber(e.target.value),
            placeholder: `Номер карты`,
            type: 'string',
            value: cardNumber,
          }}
          label={`Введите номер новой карты`}
          size={'xl'}
          fullwidth
        />
        <br />
        <FlexRowBetween style={{ gap: '24px' }}>
          <StyledButton onClick={() => setViewModal(false)} variant={'outline'} fullwidth>
            Отмена
          </StyledButton>
          <StyledButton
            disabled={isLoading || numberCardNumber.length < 14}
            onClick={handleAddCard}
            variant={'primary'}
            fullwidth
          >
            Подтвердить
          </StyledButton>
        </FlexRowBetween>
        <br />
      </FlexColumn>
    </MiniModal>
  )
}
