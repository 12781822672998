import styled from 'styled-components/macro'

import { queryClient } from '../../../shared/api/query-client'
import { colors } from '../../../shared/constants/colors'
import {
  FlexColumnBetween,
  FlexColumnStart,
  FlexRowBetween,
  FlexRowStart,
  NeutralLink,
  SmallTextGrey,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { H5 } from '../../../shared/ui-kit/titles'
import { Direction } from './CurrentOrders'

const Wrapper = styled(FlexRowBetween)`
  width: 340px;
  position: relative;
  gap: 16px;
  padding: 0 0 24px;
  border-bottom: solid 1px ${colors.lightGrey};
`

export const ImagesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
export const Image = styled.img`
  width: 94px;
  height: 106px;
  margin: 6px 0 0;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
`
export const ImageIcon = styled.img`
  position: absolute;
  top: -3px;
  left: -8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`
const StyledCheckboxWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: -8px;
`
const ContentWrapper = styled(FlexColumnStart)`
  height: 100%;
  width: 100%;
  justify-content: space-between;
`
const MessagesCounter = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  background-color: ${colors.primary};
  color: ${colors.white};
  width: 15px;
  height: 15px;
`

type OrderCardProps = {
  direction: Direction
  handleCheckbox?: () => void
  image?: string
  imageIcon?: string
  interval?: string
  isSelected?: boolean
  name?: string
  numberOfMessages?: number
  pledge?: number
  price?: number
  productId?: number
  productImageUrl?: string
  rentId?: number
  sellerImageUrl?: string
}

export const OrderCard = ({
  direction,
  handleCheckbox,
  image,
  imageIcon,
  interval,
  isSelected,
  name,
  numberOfMessages,
  pledge,
  price,
  productId,
  productImageUrl,
  rentId,
  sellerImageUrl,
}: OrderCardProps) => {
  return (
    <Wrapper>
      {/* <StyledCheckboxWrapper>
        <CheckBox checked={isSelected} setChecked={handleCheckbox} />
      </StyledCheckboxWrapper> */}
      <ImagesWrapper>
        <NeutralLink to={`/item/${productId}`}>
          <Image alt={name} src={productImageUrl || '/images/interface/EmptyBox.svg'} />
          <ImageIcon
            alt={'brand'}
            src={sellerImageUrl || '/images/buttons/user-avatar-small.svg'}
          />
        </NeutralLink>
      </ImagesWrapper>
      <ContentWrapper>
        <div>
          <NeutralLink to={`/delivery/${direction}/${rentId}`}>
            <H5>{name}</H5>
            <SmallTextGrey>{interval}</SmallTextGrey>
          </NeutralLink>
        </div>
        <FlexColumnBetween style={{ gap: '6px', marginTop: '12px', width: '100%' }}>
          <SmallTextGrey style={{ alignSelf: 'flex-end', color: colors.primary }}>
            Залог {pledge}₽
          </SmallTextGrey>
          <FlexRowBetween style={{ alignItems: 'flex-end', width: '100%' }}>
            <FlexRowStart style={{ gap: '8px' }}>
              {/* <StyledButton size={'s'} variant={'secondary'}>
                <img alt={'chat'} src={'/images/buttons/delete-black.svg'} />
              </StyledButton> */}
              <NeutralLink to={`/messages/${rentId}`}>
                <StyledButton size={'s'} style={{ position: 'relative' }} variant={'secondary'}>
                  {numberOfMessages > 0 && <MessagesCounter>{numberOfMessages}</MessagesCounter>}
                  <img alt={'chat'} src={'/images/buttons/Chat.svg'} />
                </StyledButton>
              </NeutralLink>
            </FlexRowStart>
            <SmallTextGrey>Потрачено {price}₽</SmallTextGrey>
          </FlexRowBetween>
        </FlexColumnBetween>
      </ContentWrapper>
    </Wrapper>
  )
}
