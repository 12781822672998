import React from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../../../shared/constants/colors'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { breakpoints, desktopView } from '../../../../shared/ui-kit/breakPoints'
import { FlexColumnStart } from '../../../../shared/ui-kit/projectComponents'
import { useGetMessageList } from '../../model/use-message-item'
import { MessageItem } from '../MessageItem/MessageItem'

const MessageWrapper = styled(FlexColumnStart)`
  width: 100%;

  @media all and (max-width: ${breakpoints.desktopMin}) {
    padding: 32px 21px;
    margin-bottom: 100px;
  }
`

const MessageTitle = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: ${colors.black};
  ${desktopView}
`

const Messages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 500px;
  overflow: auto;
  padding-top: 5px;
  padding-left: 5px;

  @media all and (max-width: 800px) {
    height: auto;
  }
`

const ErrorText = styled.p`
  color: ${colors.red};
  font-size: 16px;
`

export const MessageList = () => {
  const {
    data: messages,
    isError: messagesError,
    isLoading: messagesIsLoading,
    isSuccess: messagesIsSuccess,
    userId,
  } = useGetMessageList()

  if (messagesIsLoading) {
    return <AbsoluteLoader isLoading={messagesIsLoading} />
  }

  if (messagesError) {
    return <ErrorText>Error loading messages</ErrorText>
  }

  if (!messagesIsSuccess || !messages) {
    return null
  }

  return (
    <MessageWrapper>
      <MessageTitle>Диалоги</MessageTitle>
      <Messages>
        {messages.items?.map(message => (
          <MessageItem key={message?.chatId} message={message} userId={userId} />
        ))}
      </Messages>
    </MessageWrapper>
  )
}
