import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  ChangeUserBalanceCommand,
  DeleteCategoryCommand,
  DeleteUserCommand,
  GetGeneralStatisticResponse,
  GetRolesResponse,
  PostApiCategoriesCreateCategoryBody,
  PostApiCategoriesUpdateCategoryImageBody,
  UpdateUserRolesCommand,
  getApiRolesGetRolesList,
  getApiStatisticsGetGeneralStatistic,
  postApiCategoriesCreateCategory,
  postApiCategoriesDeleteCategory,
  postApiCategoriesUpdateCategoryImage,
  postApiRolesUpdateUserRoles,
  postApiUsersChangeUserBalance,
  postApiUsersDeleteUser,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useCategoriesCreateCategoryMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiCategoriesCreateCategoryBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiCategoriesCreateCategoryBody) => {
      return postApiCategoriesCreateCategory(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка создания категории')
    },
    onSuccess: () => {
      toast.success('Категория успешно создана')
      queryClient.invalidateQueries({ queryKey: ['get_categories'] })
    },
    ...options,
  })
}
export function useCategoriesUpdateCategoryImageMutation(
  options?: UseMutationOptions<unknown, unknown, PostApiCategoriesUpdateCategoryImageBody, unknown>
) {
  return useMutation({
    mutationFn: (data: PostApiCategoriesUpdateCategoryImageBody) => {
      return postApiCategoriesUpdateCategoryImage(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка обновления категории')
    },
    onSuccess: () => {
      toast.success('Категория успешно обновлена')
      queryClient.invalidateQueries({ queryKey: ['get_categories'] })
    },
    ...options,
  })
}
export function useCategoriesDeleteCategoryMutation(
  options?: UseMutationOptions<unknown, unknown, DeleteCategoryCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: DeleteCategoryCommand) => {
      return postApiCategoriesDeleteCategory(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления категории')
    },
    onSuccess: () => {
      toast.success('Категория успешно удалена')
      queryClient.invalidateQueries({ queryKey: ['get_categories'] })
    },
    ...options,
  })
}

export function useDeleteUserMutation(
  options?: UseMutationOptions<unknown, unknown, DeleteUserCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: DeleteUserCommand) => {
      return postApiUsersDeleteUser(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления пользователя')
    },
    onSuccess: () => {
      toast.success('Пользователь успешно удален')
      queryClient.invalidateQueries({ queryKey: ['get_all_users_list'] })
    },
    ...options,
  })
}

export function useUpdateUserRolesMutation(
  options?: UseMutationOptions<unknown, unknown, UpdateUserRolesCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: UpdateUserRolesCommand) => {
      return postApiRolesUpdateUserRoles(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка изменения ролей пользователя')
    },
    onSuccess: () => {
      toast.success('Роли пользователя успешно обновлены')
      queryClient.invalidateQueries({ queryKey: ['get_user_profile_data', 'get_my_profile_data'] })
    },
    ...options,
  })
}

export function useUsersChangeUserBalanceMutation(
  options?: UseMutationOptions<unknown, unknown, ChangeUserBalanceCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ChangeUserBalanceCommand) => {
      return postApiUsersChangeUserBalance(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка изменения баланса пользователя')
    },
    onSuccess: () => {
      toast.success('Баланс пользователя успешно обновлен')
      queryClient.invalidateQueries({ queryKey: ['get_my_profile_data'] })
      queryClient.invalidateQueries({ queryKey: ['get_user_profile_data'] })
      queryClient.invalidateQueries({ queryKey: ['get_all_transactions'] })
      queryClient.invalidateQueries({ queryKey: ['get_all_operations'] })
    },
    ...options,
  })
}
export function useGetRolesListMutation(
  options?: UseMutationOptions<GetRolesResponse, unknown, unknown, unknown>
) {
  return useMutation({
    mutationFn: () => {
      return getApiRolesGetRolesList()
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки списка ролей')
    },

    ...options,
  })
}

// export function useGetRolesListQuery(
//   options?: UseQueryOptions<unknown, unknown, GetRolesResponse, string[]>
// ) {
//   return useQuery({
//     onError: error => {
//       const e = error as AxiosError<{ message: string }>

//       toast.warning(e.response?.data?.message || 'Ошибка загрузки списка ролей')
//     },
//     queryFn: () => getApiRolesGetRolesList(),
//     queryKey: ['get_users_roles'],
//     ...options,
//   })
// }
export function useGetGeneralStatisticQuery(
  options?: UseQueryOptions<unknown, unknown, GetGeneralStatisticResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки общей статистики')
    },
    queryFn: () => getApiStatisticsGetGeneralStatistic(),
    queryKey: ['get_users_roles'],
    ...options,
  })
}
