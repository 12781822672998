import { useState } from 'react'

import { observer } from 'mobx-react-lite'

import { ProfileRentWrapper } from '../features/profieRent/ui/body/ProfileRentWrapper'
import { ProfileRentHeader } from '../features/profieRent/ui/head/ProfileRentHeader'
import { ProfileRentLayout } from '../shared/ui-kit/actual/layouts/ProfileRentLayout'

const ProfileRentPage = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <ProfileRentLayout
      body={<ProfileRentWrapper activeTab={activeTab} setActiveTab={setActiveTab} />}
      head={<ProfileRentHeader />}
    />
  )
}

export default observer(ProfileRentPage)
