import { toast } from 'react-custom-alert'

import imageCompression from 'browser-image-compression'

export async function filesCompression(imageFile) {
  // console.log('originalFile instanceof Blob', imageFile instanceof Blob) // true
  // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`)

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }

  try {
    const compressedBlob = await imageCompression(imageFile, options)
    const size = compressedBlob.size / 1024 / 1024

    // console.log('compressedBlob instanceof Blob', compressedBlob instanceof Blob) // true
    // console.log(`compressedBlob size ${size} MB`) // smaller than maxSizeMB
    const compressedFile = new File([compressedBlob], imageFile.name, { type: imageFile.type })

    return { compressedBlob, compressedFile, size }
  } catch (error) {
    console.log(error)
    toast.error('Ошибка при сжатии файла')
    throw error
  }
}
