import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useCategoriesCreateCategoryMutation,
  useCategoriesDeleteCategoryMutation,
  useCategoriesUpdateCategoryImageMutation,
} from '../entities/admin/queries'
import { useGetCategoriesListQuery } from '../entities/categoriesList'
import { useGetCategoryCriteria } from '../entities/criteria/queries'
import {
  useCategoriesGetCategoryInfoQuery,
  useSectionsGetSectionInfoQuery,
} from '../entities/sectionList/queries'
import { useModal } from '../shared/hooks/useModal'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { InputImageControlled } from '../shared/ui-kit/actual/InputImageControlled'
import { TextField } from '../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { AdminAddCriteriaModal } from '../shared/ui-kit/actual/components/AdminAddCriteriaModal'
import { CategoryButtonAdmin } from '../shared/ui-kit/actual/components/CategoryButtonAdmin'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BaseModal } from '../shared/ui-kit/actual/modals/BaseModal'
import { MiniModal } from '../shared/ui-kit/actual/modals/MiniModal'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  BasePage,
  FlexColumn,
  FlexColumnBetween,
  FlexRowBetween,
  SectionsPageWrapper,
  StyledButton,
  Table,
} from '../shared/ui-kit/projectComponents'
import { H1, H2 } from '../shared/ui-kit/titles'

export const AdminAddSubCategoryPage: React.FC = () => {
  const params = useParams()
  const sectionId = params['sectionId']
  const categoryId = params['categoryId']
  const { data: sectionData } = useSectionsGetSectionInfoQuery({ id: Number(sectionId) })
  const { data: categoryData } = useCategoriesGetCategoryInfoQuery({ id: Number(categoryId) })
  const { data, isLoading } = useGetCategoriesListQuery({
    parentCategoryId: Number(categoryId) || 0,
    sectionId: Number(sectionId),
    take: 300,
  })
  const { isLoading: isCreateLoading, isSuccess, mutate } = useCategoriesCreateCategoryMutation()
  const { mutate: deleteMutate } = useCategoriesDeleteCategoryMutation()
  const { setViewModal, viewModal } = useModal()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<Number>()
  const openDeleteModal = (id: number) => {
    setDeleteOpen(true)
    setDeleteId(id)
  }
  const { isLoading: isUpdateLoading, mutate: updateMutate } =
    useCategoriesUpdateCategoryImageMutation()
  const [updateOpen, setUpdateOpen] = useState(false)
  const [addCriteriaOpen, setAddCriteriaOpen] = useState(false)
  const [updateId, setUpdateId] = useState<Number>()
  const [name, setName] = useState('')
  const handleNameChange = (name: string) => setName(name)
  const [imageUrl, setImageUrl] = useState('')
  const handleUrlChange = (url: string) => setImageUrl(url)
  const [imageFile, setImageFile] = useState<File>()
  const handleFileChange = (file: File) => setImageFile(file)
  const openModal = () => {
    setViewModal(true)
    setImageFile(null)
    setImageUrl('')
    setName('')
  }
  const openUpdateModal = (id: number, name: string) => {
    setUpdateOpen(true)
    setUpdateId(id)
    setImageFile(null)
    setImageUrl('')
    setName(name)
  }
  const disabledBtn = !name || !imageFile
  const handleAddCategory = e => {
    e.preventDefault()
    mutate({
      Image: imageFile,
      Name: name,
      ParentCategoryId: Number(categoryId) || null,
      SectionId: Number(sectionId),
    })
  }
  const handleDeleteCategory = () => {
    deleteMutate({ id: Number(deleteId) })
    setDeleteOpen(false)
    setDeleteId(null)
  }
  const handleUpdateCategory = e => {
    e.preventDefault()
    updateMutate({ categoryId: Number(updateId), image: imageFile })
    setUpdateOpen(false)
    setName('')
  }

  useEffect(() => {
    isSuccess && setViewModal(false)
  }, [isSuccess, setViewModal])

  const { data: criteriaData } = useGetCategoryCriteria({ categoryId: +categoryId })

  return (
    <BasePage>
      <MainHeader />

      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      {categoryId ? (
        <H1>
          Создание подкатегорий в категории <br />
          {sectionData?.name} <br />в категории {categoryData?.name}
        </H1>
      ) : (
        <H1>
          Создание категорий в разделе <br />
          {sectionData?.name}
        </H1>
      )}
      <br />
      <StyledButton onClick={openModal} size={'xl'}>
        Создать подкатегорию
      </StyledButton>
      <br />
      <StyledButton onClick={() => setAddCriteriaOpen(true)} size={'xl'} variant={'outline'}>
        Создать новый критерий для поиска
      </StyledButton>
      <br />
      <SectionsPageWrapper>
        {!isLoading ? (
          data.items.map((category, idx) => (
            <FlexColumn key={idx}>
              <CategoryButtonAdmin id={''} image={category.imageUrl} name={category.categoryName} />
              <br />
              <StyledButton
                onClick={() => openUpdateModal(category.categoryId, category.categoryName)}
                size={'sm'}
                variant={'secondary'}
              >
                Обновить изображение
              </StyledButton>
              <br />
              <StyledButton
                onClick={() => openDeleteModal(category.categoryId)}
                size={'sm'}
                variant={'warning'}
              >
                Удалить категорию
              </StyledButton>
            </FlexColumn>
          ))
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            g
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
      <div style={{ width: '100%' }}>
        <H2>Критерии категории</H2>
        {criteriaData && (
          <div>
            {criteriaData.groupedCriteria.map((criteriaGroup, idx) => (
              <table key={idx} style={{ marginBottom: '24px' }}>
                <td style={{ fontSize: '24px', minWidth: '170px' }}>{criteriaGroup.groupName}</td>
                <td>
                  {criteriaGroup.criteria.map(criteria => (
                    <React.Fragment key={criteria.id}>
                      <tr style={{ fontSize: '18px', fontWeight: '600' }}>
                        {criteria.propertyName}
                      </tr>
                      <tr>
                        {criteria.possibleNumberValues.length > 0
                          ? criteria.possibleNumberValues.join(', ')
                          : criteria.possibleStringValues.join(', ')}
                      </tr>
                    </React.Fragment>
                  ))}
                </td>
              </table>
            ))}
          </div>
        )}
      </div>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        <AbsoluteLoader isLoading={isCreateLoading} />
        <form onSubmit={handleAddCategory}>
          <FlexColumnBetween style={{ alignItems: 'center' }}>
            <br />
            <InputImageControlled
              handleChange={handleFileChange}
              handleUrlChange={handleUrlChange}
              parentImageUrl={isLoading ? null : imageUrl}
              variant={'square'}
            />
            <br />
            <TextField
              inputProps={{
                onChange: e => handleNameChange(e.target.value),
                placeholder: 'Название категории',
                type: 'text',
                value: name,
              }}
              label={'Введите название категории'}
              fullwidth
            />
            <br />
            <StyledButton disabled={disabledBtn}>Создать</StyledButton>
          </FlexColumnBetween>{' '}
        </form>
      </BaseModal>
      <BaseModal setViewModal={() => setUpdateOpen(false)} viewModal={updateOpen}>
        <AbsoluteLoader isLoading={isUpdateLoading} />
        <form onSubmit={handleUpdateCategory}>
          <FlexColumnBetween style={{ alignItems: 'center' }}>
            <br />
            <H2>{name}</H2>
            <br />
            <InputImageControlled
              handleChange={handleFileChange}
              handleUrlChange={handleUrlChange}
              parentImageUrl={isLoading ? null : imageUrl}
              variant={'square'}
            />

            <br />
            <StyledButton disabled={!imageFile || isUpdateLoading}>обновить</StyledButton>
          </FlexColumnBetween>
        </form>
      </BaseModal>
      <MiniModal setViewModal={() => setDeleteOpen(false)} viewModal={deleteOpen}>
        <FlexColumn>
          <br />
          <H2>Вы уверены, что хотите удалить категорию?</H2>
          <br />
          <FlexRowBetween>
            <StyledButton onClick={() => setDeleteOpen(false)} variant={'secondary'}>
              НЕТ
            </StyledButton>
            <StyledButton onClick={handleDeleteCategory} variant={'warning'}>
              ДА
            </StyledButton>
          </FlexRowBetween>
          <br />
        </FlexColumn>
      </MiniModal>
      <AdminAddCriteriaModal
        addCriteriaModal={addCriteriaOpen}
        categoryId={Number(categoryId)}
        setAddCriteriaModal={setAddCriteriaOpen}
      />
    </BasePage>
  )
}
