import { ComponentPropsWithoutRef, FC, useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { FlexColumnStart } from '../projectComponents'

const Wrapper = styled(FlexColumnStart)<{ fullwidth?: boolean }>`
  row-gap: 8px;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : '327px')};
`

const StyledTextArea = styled.textarea<{ error?: string; isOutLimit?: boolean }>`
  font-size: 14px;
  resize: none;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ error, isOutLimit }) => (isOutLimit || error ? colors.red : colors.black)};
  width: 100%;
  box-sizing: border-box;
  padding: 16px 20px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: ${colors.lightGray};

  ::placeholder {
    color: ${colors.middleGray};
  }
`

const StyledText = styled.p<{ isOutLimit?: boolean }>`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  line-height: 25px; /* 208.333% */
  color: ${({ isOutLimit }) => (isOutLimit ? colors.red : colors.darkGrey)};
`

export type TextAreaProps = {
  error?: string
  fullwidth?: boolean
  inputProps?: ComponentPropsWithoutRef<'textarea'>
  maxCount?: number
  onChange?: (e) => void
  value?: string
} & ComponentPropsWithoutRef<'div'>

export const TextArea: FC<TextAreaProps> = props => {
  const { error, fullwidth, inputProps, maxCount, onChange, value, ...other } = props
  const [lenValue, setLenValue] = useState(inputProps?.value?.toString().length)

  useEffect(() => {
    setLenValue(inputProps?.value?.toString().length)
  }, [inputProps?.value])

  return (
    <Wrapper fullwidth={fullwidth}>
      <StyledTextArea
        isOutLimit={maxCount && lenValue > maxCount}
        {...inputProps}
        error={error}
        onChange={onChange}
        value={value}
      />
      {maxCount && (
        <StyledText isOutLimit={lenValue > maxCount}>
          {lenValue ?? 0}/{maxCount} символов
        </StyledText>
      )}
      {error && <StyledText isOutLimit={!!error}>{error}</StyledText>}
    </Wrapper>
  )
}
