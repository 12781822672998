import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetMarketItemQuery } from '../../../../entities/marketItem/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { LocationInfo } from '../../../../shared/ui-kit/actual/components/LocationInfo'
import {
  BottomModalWrapper,
  FlexColumnStart,
  InnerModalWrapper,
  StyledButton,
  TopModalWrapper,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { GoodsView } from '../GoodsView'

type Props = {
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const Step8 = ({ rentInfo, setStep }: Props) => {
  const { data } = useGetMarketItemQuery({ productId: rentInfo?.productId })
  const navigate = useNavigate()

  return (
    <InnerModalWrapper>
      <TopModalWrapper>
        <FlexColumnStart>
          <H3>Товар успешно возвращен</H3>
          <GoodsView rentInfo={rentInfo} />
          <br />
          <LocationInfo pickupAddress={data?.pickupAddress} />
        </FlexColumnStart>
      </TopModalWrapper>
      <BottomModalWrapper>
        {rentInfo.hasReviewAboutSeller && rentInfo.hasReviewAboutProduct ? (
          <StyledButton onClick={() => navigate(-1)} fullwidth>
            Закрыть
          </StyledButton>
        ) : (
          <StyledButton onClick={() => setStep(prev => prev + 1)} fullwidth>
            Оставить отзыв
          </StyledButton>
        )}
      </BottomModalWrapper>
    </InnerModalWrapper>
  )
}
