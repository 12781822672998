import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { FlexColumnBetween, MediumText } from '../projectComponents'
import { H5 } from '../titles'

const Wrapper = styled(FlexColumnBetween)<{ $color: string; $isSelected?: boolean }>`
  margin: 5px;
  width: 135px;
  min-width: 135px;
  height: 215px;
  border-radius: 12px;
  padding: 12px;
  background: ${({ $color }) => $color}; //#FFF1E0;
  outline: ${props => (props.$isSelected ? `solid ${colors.primary} 2px` : 'none')};
  align-items: flex-start;
  cursor: pointer;
`
const cardImages = {
  Mastercard: '/images/interface/master-card.svg',
  Mir: '/images/interface/Mir-logo.png',
  Visa: '/images/interface/visa-card.svg',
} as const

type Props = {
  cardNumber: string
  color: string
  isSelected?: boolean
  onClick: () => void
  paymentSystem: string
}
export const PayCard = ({ cardNumber, color, isSelected, onClick, paymentSystem }: Props) => {
  return (
    <Wrapper $color={color || colors.lightBlueBg} $isSelected={isSelected} onClick={onClick}>
      <img
        alt={paymentSystem}
        src={cardImages[paymentSystem] || '/images/buttons/Wallet.svg'}
        width={60}
      />
      <FlexColumnBetween style={{ alignItems: 'flex-start', gap: '8px' }}>
        <MediumText>{paymentSystem}</MediumText>
        <H5 as={'div'} style={{ margin: 0 }}>
          {cardNumber}
        </H5>
      </FlexColumnBetween>
    </Wrapper>
  )
}
