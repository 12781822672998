import { ChangeEvent, ComponentPropsWithoutRef, FC, useEffect, useRef, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { FlexColumn, FlexColumnStart, FlexRow, StyledButton } from '../projectComponents'
import { verticalScroll } from '../scroll'
import { TextField } from './TextField'

export type DROPDOWN_SIZE = 'lg' | 'xl'

type TKey = number | string
type TValue = TKey
export type TSELECTED_ID = null | number
export type TDropdownItem = { callback?: () => void; key: TKey; value: TValue }
export type TVoid = () => void

export type DropdownProps = {
  customVariantMessage?: string
  defaultValue?: string
  disabled?: boolean
  fullwidth?: boolean
  handleChange?: (id: TSELECTED_ID) => void
  handleChangeValue?: (value: any) => any
  items: TDropdownItem[]
  label?: string
  onBlur?: () => void
  selectedId?: TSELECTED_ID
  selectedIndexes?: number[]
  size?: DROPDOWN_SIZE
} & ComponentPropsWithoutRef<'div'>

const SelectWrapper = styled(FlexRow)`
  cursor: pointer;
  width: 100%;
  height: 50px;
  padding: 7px 7px 7px 20px;
  border-radius: 10px;
  background-color: ${colors.lightGray};
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  border: transparent 1px solid;

  p {
    color: ${colors.black};
  }
`
const TextWrapper = styled(FlexColumnStart)<{ hasSelected?: boolean }>`
  box-sizing: border-box;

  label {
    color: ${colors.grey};
    font-size: ${({ hasSelected }) => (hasSelected ? '10px' : '14px')};
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    color: ${colors.black};
  }
`

const Wrapper = styled(FlexColumn)<{ disabled?: boolean; fullwidth?: boolean }>`
  position: relative;
  z-index: 1000;
  gap: 6px;
  max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '327px')};
  width: 100%;
  box-sizing: border-box;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
const MenuWrapper = styled(FlexColumnStart)`
  position: absolute;
  z-index: 1500;
  width: 100%;
  height: auto;
  max-height: 220px;
  background-color: ${colors.lightGray};
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  top: 60px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
`
const MenuContent = styled.div`
  height: 100%;
  gap: 2px;
  box-sizing: border-box;
  min-height: 100%;
  overflow-y: auto;
  width: 100%;
  position: relative;
  ${verticalScroll}
`
const ItemWrapper = styled(FlexRow)<{ disabled?: boolean }>`
  cursor: ${props => (!props.disabled ? 'pointer' : 'not-allowed')};
  border-radius: 15px;
  padding: 14px 18px;
  box-sizing: border-box;
  width: 100%;
  align-items: start;
  text-align: start;
  justify-content: start;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    color: ${props => (!props.disabled ? 'inherit' : colors.middleGray)};
  }

  :hover {
    background-color: ${props => (props.disabled ? 'inherit' : colors.white)};
    transition: all ease-in 0.3s;
  }
`

export const Dropdown: FC<DropdownProps> = props => {
  const {
    customVariantMessage,
    defaultValue,
    disabled,
    fullwidth,
    handleChange,
    handleChangeValue,
    items,
    label,
    onBlur,
    selectedId = null,
    selectedIndexes,
    size,
    ...other
  } = props
  const ref = useRef()
  const [openMenu, setOpenMenu] = useState(false)
  const [isCustom, setCustom] = useState(false)
  const [selectId, setSelectId] = useState(selectedId || 0)

  useEffect(() => {
    setSelectId(selectedId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])
  const handleClick = (item: TDropdownItem, idx: number) => {
    handleChange && handleChange(idx)
    handleChangeValue(item)
    setSelectId(idx)
    setOpenMenu(false)
    if (item.callback) {
      item.callback()
    }
  }

  useOnClickOutside(ref, () => setOpenMenu(false))

  return (
    <>
      {isCustom ? (
        <TextField
          inputProps={{
            defaultValue,
            onBlur,
            placeholder: label,
          }}
          label={label}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeValue(e?.target)}
          fullwidth
        />
      ) : (
        <Wrapper fullwidth={fullwidth} {...other} disabled={disabled} ref={ref}>
          <SelectWrapper onClick={() => !disabled && setOpenMenu(!openMenu)}>
            <TextWrapper hasSelected={selectId !== null}>
              <label>{label}</label>
              {selectId != null && <p>{items.at(selectId)?.value}</p>}
            </TextWrapper>

            <img
              alt={''}
              height={28}
              src={'/images/buttons/arrow-down.svg'}
              style={{ rotate: openMenu ? '180deg' : '0deg' }}
              width={28}
            />
          </SelectWrapper>
          {openMenu && (
            <MenuWrapper>
              <MenuContent>
                {items.map((item, idx) => (
                  <ItemWrapper
                    onClick={() => {
                      if (!selectedIndexes || !selectedIndexes.includes(idx)) {
                        handleClick(item, idx)
                      }
                    }}
                    disabled={selectedIndexes && selectedIndexes.includes(item.key as number)}
                    key={item.key}
                  >
                    <span>{item.value}</span>
                  </ItemWrapper>
                ))}
                {customVariantMessage && (
                  <ItemWrapper key={customVariantMessage} onClick={() => setCustom(true)}>
                    <span>{customVariantMessage}</span>
                  </ItemWrapper>
                )}
              </MenuContent>
            </MenuWrapper>
          )}
        </Wrapper>
      )}
    </>
  )
}
