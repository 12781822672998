import { useContext, useEffect } from 'react'

import { useGetSectionsListQuery } from '../../entities/sectionList'
import SectionStorage from '../storage/SectionStorage/SectionStorage'

function useSectionsLoader() {
  const { sections, setSection } = useContext(SectionStorage)
  const { data, isLoading } = useGetSectionsListQuery({})

  useEffect(() => {
    if (!isLoading && data) {
      setSection(data?.sections)
    }
  }, [data, isLoading, setSection])

  return { isLoading, sections }
}

export default useSectionsLoader
