import { StyledButton } from '../StyledButton'

export const StyledButtonWithIcon = ({ src }: { src: string }) => (
  <StyledButton size={'xs'} variant={'neutral'} isIcoBtn>
    <img alt={''} src={src} />
  </StyledButton>
)
export const StyledButtonSettings = () => (
  <StyledButtonWithIcon src={'/images/buttons/Setting.svg'} />
)
