const truncate = (value: string): string => {
  const maxIndexByFirstHalf = Math.trunc(value.length * 0.17)
  const minIndexByLastHalf = value.length - Math.trunc(value.length * 0.12)

  return `${value.slice(0, maxIndexByFirstHalf)}...${value.slice(minIndexByLastHalf, value.length)}`
}

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  }
  const start = str.slice(0, maxLength / 2 - 1)
  const end = str.slice(str.length - maxLength / 2 + 1)

  return `${start}...${end}`
}

export const shortString = (str: string, maxLength: number) => {
  if (str && str.length <= maxLength) {
    return str
  }

  return `${str?.substring(0, maxLength)}...`
}

export default truncate
