import { GetReviewsListResponse } from '../api/generated'

const getAvgRating = (rating1: number, rating2: number): number => {
  const isSellerRatingExist = rating1 && rating1 > 0
  const isBuyerRatingExist = rating2 && rating2 > 0

  return (
    (isSellerRatingExist && isBuyerRatingExist && (rating1 + rating2) / 2) ||
    (isSellerRatingExist && rating1) ||
    (isBuyerRatingExist && rating2) ||
    0
  )
}

export default getAvgRating

export const getProductAvgRating = (reviews: GetReviewsListResponse): number => {
  const ratingItems = reviews?.items
  const totalRating = ratingItems?.reduce((acc, i) => acc + i.rating, 0)

  return (totalRating && totalRating / reviews?.totalCount) || 0
}
