import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { FlexColumnStart } from '../../projectComponents'

const ModalBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  min-height: 100svh;
  position: absolute;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: flex-end;
  justify-content: center;
  z-index: 2000;
`

const ModalWrapper = styled(FlexColumnStart)`
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 64px;
  width: clamp(300px, 100%, 600px);
  min-height: 90vh;
  min-height: 90svh;
  box-sizing: border-box;
  padding: 28px 24px 96px;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  gap: 4px;
  z-index: 2100;
`

type Props = {
  children?: ReactNode
  onCloseUrl?: string
}

const preventClick = e => {
  e.stopPropagation()
}

export const NotModal = ({ children, onCloseUrl }: Props) => {
  const navigate = useNavigate()

  return (
    <ModalBackground
      onClick={() => {
        if (onCloseUrl) {
          navigate(onCloseUrl)
        } else {
          navigate(-1)
        }
      }}
    >
      <ModalWrapper onClick={preventClick}>{children}</ModalWrapper>
    </ModalBackground>
  )
}
