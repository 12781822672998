import React, { useEffect, useState } from 'react'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'

import { useUsersChangeUserBalanceMutation } from '../../../entities/admin/queries'
import { useGetUserProfileQuery } from '../../../entities/userProfile/queries'
import { symbols } from '../../../shared/constants/symbols'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { BaseModal } from '../../../shared/ui-kit/actual/modals/BaseModal'
import {
  FlexColumnStart,
  FlexRowBetween,
  MediumTextL,
  StyledButton,
  StyledInput,
} from '../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../shared/ui-kit/titles'
import { round100 } from '../../../shared/utils/round100'

type Props = {
  paymentsOpen: boolean
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<unknown, unknown>>
  setPaymentsOpen: (value: React.SetStateAction<boolean>) => void
  userId: string
}
export const UserBalanceModal = ({ paymentsOpen, refetch, setPaymentsOpen, userId }: Props) => {
  const {
    data: userProfile,
    isLoading: isProfileLoading,
    refetch: userProfileRefetch,
  } = useGetUserProfileQuery(userId)
  const {
    isLoading: isPaymentsUpdateLoading,
    isSuccess: isPaymentsSuccess,
    mutate: updatePaymentsMutate,
  } = useUsersChangeUserBalanceMutation({})
  const [amount, setAmount] = useState<number | string>('')
  const [bonusAmount, setBonusAmount] = useState<number | string>('')
  const handleChangeBalance = () => {
    updatePaymentsMutate({
      amount: Number(amount),
      bonusAmount: Number(bonusAmount),
      userId,
    })
  }

  useEffect(() => {
    if (isPaymentsSuccess) {
      setPaymentsOpen(false)
      userProfileRefetch()
      refetch()
      setTimeout(() => {
        setBonusAmount('')
        setAmount('')
      }, 1000)
    }
  }, [isPaymentsSuccess, refetch, setPaymentsOpen, userProfileRefetch])
  const closeModal = () => {
    setPaymentsOpen(false)
    setTimeout(() => {
      setBonusAmount('')
      setAmount('')
    }, 1000)
  }

  return (
    <BaseModal setViewModal={closeModal} viewModal={paymentsOpen}>
      <AbsoluteLoader isLoading={isProfileLoading || isPaymentsUpdateLoading} />
      <H2>Изменить баланс пользователя</H2>
      <FlexColumnStart style={{ flexWrap: 'wrap', maxHeight: '230px' }}>
        <FlexRowBetween>
          <div style={{ width: '350px' }}>
            <MediumTextL>
              Подтвержденный баланс <br />{' '}
              <strong>{userProfile?.balanceUser?.accountBalance || 0} рублей</strong>
            </MediumTextL>
            <br />
            <MediumTextL>
              Изменить{' '}
              <StyledInput
                onChange={e => setAmount(+e.target.value)}
                style={{ height: '30px', width: '70px' }}
                type={'number'}
                value={amount}
              />{' '}
              {symbols.ruble}
            </MediumTextL>
          </div>
          <div style={{ flexShrink: 1 }}>
            <MediumTextL>После подтверждения баланс составит</MediumTextL>
            <H2>
              {round100(
                Number(userProfile?.balanceUser?.accountBalance || 0) + Number(amount || 0)
              )}{' '}
              {symbols.ruble}
            </H2>
          </div>
        </FlexRowBetween>
        <hr />
        <FlexRowBetween>
          <div style={{ width: '350px' }}>
            <MediumTextL>
              Подтвержденный баланс <br />{' '}
              <strong>{userProfile?.balanceUser?.bonusBalance || 0} шери</strong>
            </MediumTextL>
            <br />
            <MediumTextL>
              Изменить{' '}
              <StyledInput
                onChange={e => setBonusAmount(+e.target.value)}
                style={{ height: '30px', width: '70px' }}
                type={'number'}
                value={bonusAmount}
              />{' '}
              SHC
            </MediumTextL>
          </div>
          <div style={{ flexShrink: 1 }}>
            <MediumTextL>После подтверждения баланс составит</MediumTextL>
            <H2>
              {round100(
                Number(userProfile?.balanceUser?.bonusBalance || 0) + Number(bonusAmount || 0)
              )}{' '}
              SHC
            </H2>
          </div>
        </FlexRowBetween>
      </FlexColumnStart>
      <FlexRowBetween style={{ gap: '12px' }}>
        <StyledButton onClick={() => setPaymentsOpen(false)} variant={'outline'}>
          Отмена
        </StyledButton>
        <StyledButton onClick={handleChangeBalance} variant={'primary'} fullwidth>
          Подтвердить изменение баланса
        </StyledButton>
      </FlexRowBetween>
    </BaseModal>
  )
}
