import React, { useState } from 'react'

import { useGetMarketItemQuery } from '../../../entities/marketItem/queries'
import { useGetRentDefectFinesListQuery } from '../../../entities/rent/queries'
import { CheckBox } from '../../../shared/ui-kit/actual/checkbox'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  FlexColumnBetween,
  FlexRow,
  FlexRowBetween,
  MediumText,
  MediumTextBold,
} from '../../../shared/ui-kit/projectComponents'
import { H4 } from '../../../shared/ui-kit/titles'

export const DefectsTable = ({
  defectIds,
  rentId,
  setDefectsIds,
}: {
  defectIds: number[]
  rentId: number
  setDefectsIds: React.Dispatch<React.SetStateAction<number[]>>
}) => {
  const { data, isLoading } = useGetRentDefectFinesListQuery(String(rentId))

  const handleSetDefects = (defectId: number) => {
    if (defectIds.includes(defectId)) {
      setDefectsIds(defectIds.filter(id => id !== defectId))

      return
    }
    setDefectsIds(prev => [...prev, defectId])
  }

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <H4>Описание дефектов</H4>
      <FlexColumnBetween style={{ width: '100%' }}>
        {data?.items.map(defect => {
          return (
            <FlexRowBetween key={defect.id} style={{ marginBottom: '10px' }}>
              <FlexRow>
                <MediumTextBold>{defect.defectName}</MediumTextBold> •{' '}
                <MediumText>{defect.amount}</MediumText>
              </FlexRow>
              <CheckBox
                setChecked={e => {
                  handleSetDefects(defect.id)
                }}
                checked={defectIds.includes(defect.id)}
              />
            </FlexRowBetween>
          )
        })}
      </FlexColumnBetween>
    </>
  )
}
