import { useContext, useMemo } from 'react'
import { toast } from 'react-custom-alert'

import { AxiosError } from 'axios'

import { useCreateUserAddress, useGetUserAddresses } from '../../entities/userAddress'
import { useUserAddressesMakeUserAddressDefaultMutation } from '../../entities/userAddress/queries'
import { AddUserAddressCommand } from '../api/generated'
import { queryClient } from '../api/query-client'
import UserStorage from '../storage/UserStorage/UserStorage'

export const useAddresses = () => {
  const { userId } = useContext(UserStorage)

  const { data: dataAddress, isLoading: addressesLoading } = useGetUserAddresses(userId)

  const addresses = useMemo(() => {
    return dataAddress?.addresses.sort((a, b) => a.id - b.id)
  }, [dataAddress?.addresses])
  const defaultAddress = useMemo(() => {
    return dataAddress?.addresses.find(a => a.isDefault) || null
  }, [dataAddress?.addresses])
  const { isLoading: isSelectAddressLoading, mutate: setDefaultAddress } =
    useUserAddressesMakeUserAddressDefaultMutation(dataAddress)
  const handleChecked = (addressId: number) => {
    setDefaultAddress({ id: addressId })
  }

  const createAddressMutation = useCreateUserAddress({
    onError: error => {
      const e = error as AxiosError<{ errors: { Description: string[] }; message: string }>

      toast.error(
        e.response?.data?.message ||
          e.response?.data?.errors?.Description?.[0] ||
          'Ошибка добавления адреса'
      )
    },
    onSuccess: () => {
      toast.success('Адрес успешно добавлен')
      queryClient.invalidateQueries({ queryKey: ['get_address'] })
    },
  })
  const createAddress = async (data: AddUserAddressCommand) => {
    try {
      await createAddressMutation.mutateAsync(data)
    } catch (error) {
      toast.error('Ошибка при добавлении адреса: ', error)
    }
  }

  return {
    addresses,
    addressesLoading,
    createAddress,
    defaultAddress,
    defaultAddressId: defaultAddress?.id,
    handleChecked,
    isSelectAddressLoading,
  }
}
