import styled from 'styled-components/macro'

import { FlexColumnBetween } from '../../../shared/ui-kit/projectComponents'

export const StepWrapper = styled(FlexColumnBetween)`
  justify-content: space-between;
  row-gap: 16px;
  align-items: stretch;
  height: 100%;
  flex-grow: 1;
`
