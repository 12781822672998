import { FC, useState } from 'react'

import { ArticleCard, ArticleVariant } from '../..'
import { GetArticleInfoResponse } from '../../../../shared/api/generated'
import { Dropdown } from '../../../../shared/ui-kit/actual/Dropdown'
import {
  FilterTitle,
  FlexColumn,
  FlexColumnBetween,
  FlexColumnStart,
  MediumTextL,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'

interface ArticleReportModalProps {
  data: GetArticleInfoResponse
  onCloseModal?: () => void
}

const reasons = [
  { key: 1, value: 'Нецензурная лексика' },
  { key: 2, value: 'Оскорбление' },
]

const ArticleReportModal: FC<ArticleReportModalProps> = ({ data, onCloseModal }) => {
  const [selectId, setSelectId] = useState<null | number>(0)
  const [reason, setReason] = useState()
  const [isReportSend, setIsReportSend] = useState(false)

  return (
    <>
      {!isReportSend ? (
        <FlexColumnBetween style={{ height: '100%' }}>
          <FlexColumnStart style={{ flexGrow: '1', justifyContent: 'start' }}>
            <FlexColumnStart style={{ marginBottom: 32 }}>
              <FilterTitle style={{ marginBottom: 24 }}>
                Вы действительно хотите пожаловаться на статью?
              </FilterTitle>
              <ArticleCard item={data} variant={ArticleVariant.Card} />
            </FlexColumnStart>
            <FlexColumnStart>
              <FilterTitle style={{ marginBottom: 16 }}>Укажите причину</FilterTitle>
            </FlexColumnStart>
            <Dropdown
              customVariantMessage={'Укажите другую причину'}
              handleChange={setSelectId}
              handleChangeValue={e => setReason(e.value)}
              items={reasons}
              label={'Причина'}
              selectedId={selectId}
              style={{ marginTop: '16px' }}
              fullwidth
            />
          </FlexColumnStart>
          <br />
          <StyledButton
            onClick={() => {
              setIsReportSend(true)
            }}
            size={'xl'}
            fullwidth
          >
            Отправить жалобу
          </StyledButton>
        </FlexColumnBetween>
      ) : (
        <FlexColumn style={{ alignItems: 'center', height: '100%' }}>
          <FlexColumn style={{ flexGrow: '1', justifyContent: 'start' }}>
            <img
              alt={''}
              src={'/images/interface/close-err.svg'}
              style={{ height: 40, marginTop: '100px', width: 40 }}
            />
            <br />
            <H2>Жалоба получена</H2>
            <MediumTextL style={{ textAlign: 'center' }}>
              Не переживайте, платформа рассмотрит вашу жалобу
            </MediumTextL>
            <br />
          </FlexColumn>
          <StyledButton onClick={onCloseModal} style={{ marginBottom: '32px' }} fullwidth>
            На главную
          </StyledButton>
        </FlexColumn>
      )}
    </>
  )
}

export default ArticleReportModal
