import React, { useEffect } from 'react'

import styled from 'styled-components/macro'

import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { StyledBtnSmall } from '../../../../shared/ui-kit/actual/StyledButton'
import {
  BottomModalWrapper,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  InnerModalWrapper,
  MediumText,
  MediumTextPrimary,
  NeutralLink,
  StyledButton,
  TopModalWrapper,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { dateToString } from '../../../../shared/utils/dateToString'
import { GoodsView } from '../GoodsView'

type Props = {
  refetch: () => void
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}
const ContentWrapper = styled.div`
  width: fit-content;
  margin-top: 24px;
  border-radius: 11px;
  background-color: ${colors.lightGray};
  padding: 22px;
`

export const Step4 = ({ refetch, rentInfo, setStep }: Props) => {
  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 5000)

    return () => clearInterval(interval)
  }, [refetch])

  return (
    <>
      {/* <button onClick={() => setStep(8)}>жалоба арендатора</button> */}
      <InnerModalWrapper>
        <TopModalWrapper>
          <FlexColumnStart>
            <H3>Заказ получен</H3>
            <MediumText>
              Арендован до {dateToString(rentInfo?.periodTo)} <br /> Не забывайте о вежливости и
              внимательности к клиенту
            </MediumText>
            <GoodsView rentInfo={rentInfo} />
            <ContentWrapper>
              <FlexRow style={{ gap: '8px' }}>
                <img
                  alt={'Close'}
                  src={'/images/buttons/check-white-on-bg.svg'}
                  style={{ height: 20, width: 20 }}
                />
                <MediumTextPrimary>Договор подписан</MediumTextPrimary>
              </FlexRow>
            </ContentWrapper>
          </FlexColumnStart>
        </TopModalWrapper>
        <BottomModalWrapper style={{ gap: '8px' }}>
          <FlexRowBetween style={{ gap: '8px' }}>
            <NeutralLink to={`/users/${rentInfo?.buyerId}`}>
              <StyledButton variant={'secondary'} fullwidth>
                О заказчике
              </StyledButton>
            </NeutralLink>
            <NeutralLink style={{ width: 'min-content' }} to={`/messages/${rentInfo.rentId}`}>
              <StyledBtnSmall variant={'secondary'}>
                <img alt={''} src={'/images/buttons/Chat.svg'} style={{ height: 20, width: 20 }} />
              </StyledBtnSmall>
            </NeutralLink>
          </FlexRowBetween>
        </BottomModalWrapper>
      </InnerModalWrapper>
    </>
  )
}
