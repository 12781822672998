import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

// export const SERVER_URL = 'https://allsharing.af-dev.ru'
export const SERVER_URL = 'https://allsharing.su'

export const apiClient: { isAwaitRefresh?: boolean } & AxiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*',
    // 'Content-type': 'application/json',
  },
  withCredentials: true,
})
apiClient.isAwaitRefresh = false

apiClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${JSON.parse(localStorage.accessToken)}`

  return config
})

apiClient.interceptors.response.use(
  config => {
    return config
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true
      if (apiClient.isAwaitRefresh) {
        return
      }
      apiClient.isAwaitRefresh = true
      try {
        const response = await axios.post(`${SERVER_URL}/api/Auth/refreshToken`, {
          accessToken: JSON.parse(localStorage.accessToken),
          refreshToken: JSON.parse(localStorage.refreshToken),
        })

        if (response.status !== 200) {
          localStorage.clear()
          window.location.href = '/login'

          return
        }

        localStorage.setItem('accessToken', JSON.stringify(response.data?.accessToken))
        localStorage.setItem('refreshToken', JSON.stringify(response.data?.refreshToken))

        return apiClient.request(originalRequest)
      } catch (e) {
        localStorage.clear()
        window.location.href = '/login'
      } finally {
        apiClient.isAwaitRefresh = false
      }
    }
    throw error
  }
)

export const createInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  return apiClient({
    ...config,
    ...options,
    // signal,
  }).then(r => r.data)
}

export type BodyType<Data> = Data

export type ErrorType<Error> = AxiosError<Error>
