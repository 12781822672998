import { ReactNode, useState } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { FlexRowBetween } from '../projectComponents'

interface BlockTitleProps {
  bottomSpace?: SPACE_VARIANT
  topSpace?: SPACE_VARIANT
}

export type SPACE_VARIANT = 'md' | 'sm' | 'xl' | 'zero'
export const BlockTitle = styled.h2<BlockTitleProps>`
  text-align: left;
  margin: 0;
  margin-bottom: ${({ bottomSpace }) =>
    (bottomSpace === 'xl' && '32px') ||
    (bottomSpace === 'md' && '24px') ||
    (bottomSpace === 'sm' && '16px') ||
    '0'};
  margin-top: ${({ topSpace }) =>
    (topSpace === 'xl' && '32px') ||
    (topSpace === 'md' && '24px') ||
    (topSpace === 'sm' && '16px') ||
    '0'};
`

export const BlockTitleWithButton = styled(FlexRowBetween)`
  width: 100%;
  align-items: center;
  margin: 32px auto 16px;
`

const InfoButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 1900;
  display: inline;
`
const InfoWrapper = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  background-color: ${colors.lightGray};
  border-radius: 10px;
  width: 280px;
  padding: 12px 16px;
  display: ${({ isHovered }) => (isHovered ? 'block' : 'none')};
  z-index: 2000;
  cursor: text;
  right: 0;
  box-shadow: 0px 0px 14px 0px #0000001a;
  & > p {
    text-align: left;
  }
`

export const InfoButton = ({
  content,
  title,
}: {
  content?: ReactNode | string
  title?: string
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleOnHover = () => {
    setIsHovered(true)
  }
  const handleOffHover = () => {
    setIsHovered(false)
  }

  return (
    <>
      <InfoButtonWrapper onMouseLeave={handleOffHover} onMouseOver={handleOnHover}>
        {title ? (
          <span style={{ color: colors.primary }}>{title}</span>
        ) : (
          <img alt={''} src={'/images/buttons/info.svg'} />
        )}
        <InfoWrapper isHovered={isHovered}>
          <p>{content}</p>
        </InfoWrapper>
      </InfoButtonWrapper>
    </>
  )
}
