import { useState } from 'react'

import styled from 'styled-components/macro'

import { FlexColumnStart } from '../../../shared/ui-kit/projectComponents'
import { usePhoneEmailSignUpForm } from '../model/use-phone-email-signup-form'
import { FirstStep } from './forms/FirstStep'
import { FourthStep } from './forms/FourthStep'
import { SecondStep } from './forms/SecondStep'
import { ThirdStep } from './forms/ThirdStep'

const Wrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 800px);
  margin: 0 auto;
`

export const PhoneEmailRegister = () => {
  const {
    clearErrors,
    errors,
    getValues,
    handleSubmit,
    isValid,
    register,
    setValue,
    signUpMutation,
  } = usePhoneEmailSignUpForm()

  const [step, setStep] = useState(0)

  return (
    <Wrapper>
      {
        [
          <FirstStep
            errors={errors}
            isValid={isValid}
            key={0}
            register={register}
            setStep={setStep}
          />,
          <SecondStep getValues={getValues} key={1} setStep={setStep} setValue={setValue} />,
          <ThirdStep getValues={getValues} key={2} setStep={setStep} setValue={setValue} />,
          <FourthStep
            clearErrors={clearErrors}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            isValid={isValid}
            key={3}
            register={register}
            setValue={setValue}
            signUpMutation={signUpMutation}
          />,
        ][step]
      }
    </Wrapper>
  )
}
