import { useState } from 'react'

import styled from 'styled-components/macro'

import { InputImageNotCompress } from '../../../shared/ui-kit/actual/InputImageNotCompress'
import { SliderComponent } from '../../../shared/ui-kit/actual/Slider'
import { TextArea } from '../../../shared/ui-kit/actual/TextArea'
import { TextField } from '../../../shared/ui-kit/actual/TextField'
import { breakpoints, devices } from '../../../shared/ui-kit/breakPoints'
import {
  DesktopMiddleView,
  FlexColumn,
  FlexColumnStart,
  MobileTabletDesktopMinView,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { SubmitBlock } from './components/SubmitBlock'

const StyledWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media screen and (${devices.desktopMiddle}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
const FlexColumnRowTop = styled(FlexColumn)`
  gap: 8px;
  width: clamp(300px, 100%, ${breakpoints.desktopMin});
  @media screen and (${devices.desktopMin}) {
    flex-direction: row;
    align-items: start;
    /* order: -1; */
    align-self: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`
const BlockWrapperTop = styled(FlexColumnStart)`
  gap: 8px;
  width: 100%;
  align-items: center;
  @media screen and (${devices.desktopMin}) {
    width: 47%;
  }
  @media screen and (${devices.desktopMiddle}) {
    height: 100%;
    align-self: stretch;
  }
`
const FlexColumnRowMiddle = styled(FlexColumn)`
  gap: 8px;
  max-width: ${breakpoints.desktopMin};
  width: 100%;
  @media screen and (${devices.desktopMin}) {
    align-self: flex-start;
    width: 100%;
  }
  @media screen and (${devices.desktopMiddle}) {
    min-width: 400px;
    width: 30%;
    & > :nth-child(1) {
      margin-top: 0;
    }
  }
`

const FileInputWrapper = styled.div`
  height: 490px;
  width: 100%;
`

const Wrapper = styled.div`
  align-self: center;

  width: clamp(300px, 100%, 800px);
  @media screen and (${devices.desktopMiddle}) {
    width: 100%;
    align-self: stretch;
  }
`
const FirstStep = ({
  CategoryAndTagsSelect,
  DefectsItem,
  IntervalsItem,
  categorySelected,
  createProductMutation,
  errors,
  getValues,
  handleNextStep,
  imageUrls,
  isValid,
  onlyPremium,
  register,
  setImageUrls,
  setOnlyPremium,
  setValue,
}) => {
  const [activeId, setActiveId] = useState(0)
  const handleUrlChange = (url: string) => {
    setImageUrls(prevState => [...prevState, url])
    setActiveId(activeId + 1)
  }
  const togglePremium = () => {
    setOnlyPremium(active => {
      setValue('IsPremium', !active)

      return !active
    })
  }

  return (
    <Wrapper>
      <StyledWrapper>
        <FlexColumnRowTop>
          <BlockWrapperTop>
            {!!imageUrls.length && (
              <SliderComponent
                items={imageUrls.map((it, idx) => (
                  <img
                    alt={'product'}
                    height={400}
                    key={idx}
                    src={it}
                    style={{ objectFit: 'contain', width: '100%' }}
                  />
                ))}
                initialSlide={activeId > 0 ? activeId - 1 : 0}
                slidesToShow={1}
                arrows
                dots
              />
            )}
            {!imageUrls.length ? (
              <>
                <FileInputWrapper>
                  <InputImageNotCompress
                    handleChange={data => {
                      setValue('Images', [data])
                    }}
                    handleUrlChange={handleUrlChange}
                    variant={'full'}
                  />
                </FileInputWrapper>
              </>
            ) : (
              <InputImageNotCompress
                defaultImageNode={
                  <StyledButton variant={'secondary'} fullwidth>
                    {'Добавить фото'}
                  </StyledButton>
                }
                handleChange={data => {
                  const prevValues = getValues('Images')

                  setValue('Images', [...prevValues, data])
                }}
                handleUrlChange={handleUrlChange}
                variant={'full'}
              />
            )}
          </BlockWrapperTop>
          <BlockWrapperTop>
            <TextField
              inputProps={{
                placeholder: 'Название товара',
                type: 'text',
                ...register('Name', {
                  minLength: {
                    message: 'Минимум 3 символов',
                    value: 3,
                  },
                  required: 'Название товара обязательно для заполнения',
                }),
              }}
              error={errors.Name?.message?.toString()}
              label={'Название товара'}
              size={'md'}
              fullwidth
            />
            {CategoryAndTagsSelect}
            <TextArea
              inputProps={{
                placeholder: 'Описание',
                rows: 7,
                ...register('Description', {
                  minLength: {
                    message: 'Минимум 6 символов',
                    value: 6,
                  },
                  required: 'Описание товара обязательно для заполнения',
                }),
              }}
              error={errors.Description?.message?.toString()}
              fullwidth
            />
          </BlockWrapperTop>
          <DesktopMiddleView style={{ width: 'auto' }}>
            <SubmitBlock
              isValid={
                isValid &&
                !Object.keys(errors).length &&
                !createProductMutation.isLoading &&
                getValues('CategoryId') &&
                getValues('CategoryId') != 0
              }
              handleNextStep={handleNextStep}
              onlyPremium={onlyPremium}
              togglePremium={togglePremium}
            />
          </DesktopMiddleView>
        </FlexColumnRowTop>

        <FlexColumnRowMiddle>
          {IntervalsItem}
          {/* <BlockTitleWithButton>
            <H3>Размеры</H3>
          </BlockTitleWithButton>
          {[1, 2, 3].map((it, idx) => {
            return (
              <TextField
                inputProps={{
                  placeholder: `Размер №${idx + 1}`,
                }}
                right={
                  idx > 0 ? (
                    <img
                      alt={'delete'}
                      src={'images/buttons/delete.svg'}
                      style={{ width: '12px' }}
                    />
                  ) : null
                }
                key={it}
                label={`Размер №${idx + 1}`}
                size={'md'}
                fullwidth
              />
            )
          })}
          <StyledButton
            onClick={e => e.preventDefault()}
            variant={'secondary'}
            fullwidth
            // disabled={!regForm.formState.isValid}
          >
            {'Добавить размер'}
          </StyledButton> */}
          {DefectsItem}
          {/* <BlockTitleWithButton>
            <H3>Цвет и материал</H3>
          </BlockTitleWithButton>
          <Dropdown
            handleChange={e => console.log('2', e)}
            items={mockColors}
            label={'Цвет'}
            selectedId={0}
            size={'lg'}
            fullwidth
          /> */}
          {/*<TagsField*/}
          {/*  selectedTags={['Дерево', 'Керамогранит', 'Металл', 'Дерево']}*/}
          {/*  setSelectedTags={() => 'Дерево'}*/}
          {/*  tags={['Дерево', 'Керамогранит', 'Металл', 'Дерево']}*/}
          {/*/>*/}
        </FlexColumnRowMiddle>
        <MobileTabletDesktopMinView>
          <SubmitBlock
            isValid={
              isValid &&
              !Object.keys(errors).length &&
              !createProductMutation.isLoading &&
              getValues('CategoryId') &&
              getValues('CategoryId') != 0
              // &&
              // categorySelected()
            }
            handleNextStep={handleNextStep}
            onlyPremium={onlyPremium}
            togglePremium={togglePremium}
          />{' '}
        </MobileTabletDesktopMinView>
      </StyledWrapper>
    </Wrapper>
  )
}

export default FirstStep
