import React, { ReactNode, memo, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components/macro'

const Wrapper = styled.header`
  z-index: 100;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  align-items: center;
  padding: 24px 0;
  min-height: 32px;
  width: 100%;

  .left {
    align-items: flex-start;
    padding-left: 5px;
  }

  .right {
    align-items: flex-end;
    padding-right: 10px;
  }

  .center {
    align-items: center;
  }
`

const SideWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:nth-child(2) {
    flex: 1 0 70%;
  }
`

interface MessageHeaderProps {
  center?: ReactNode
  left?: ReactNode
  right?: ReactNode
}

export const MessageHeader = memo(
  ({ center, left, right }: MessageHeaderProps): React.JSX.Element => {
    const { pathname } = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])

    const leftContent = useMemo(() => left, [left])
    const centerContent = useMemo(() => center, [center])
    const rightContent = useMemo(() => right, [right])

    return (
      <Wrapper>
        <SideWrapper className={'left'}>{leftContent}</SideWrapper>
        <SideWrapper className={'center'}>{centerContent}</SideWrapper>
        <SideWrapper className={'right'}>{rightContent}</SideWrapper>
      </Wrapper>
    )
  }
)
