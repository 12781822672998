import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { useGetReviewsListQuery } from '../../entities/reviews/queries'
import { useGetUserProfileQuery } from '../../entities/userProfile/queries'
import Breadcrumbs from '../../features/market/ui/sections/BreadCrumbs'
import { UserBodyWrapper } from '../../features/profile/ui/body/UserBodyWrapper'
import { UserDesktopWrapper } from '../../features/profile/ui/body/UserDesktopWrapper'
import { UserDesktopHeader } from '../../features/profile/ui/head/UserDesktopHeader'
import { UserGoodsHeader } from '../../features/profile/ui/head/UserGoodsHeader'
import { useDisableBodyScroll } from '../../shared/hooks/use-disable-body-scroll'
import { MainLogoLink } from '../../shared/ui-kit/actual/MainLogoLink'
import { ProfileHeader } from '../../shared/ui-kit/actual/ProfileHeader'
import { AbsoluteLoader } from '../../shared/ui-kit/actual/components/AbsoluteLoader'
import { MainHeader } from '../../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../../shared/ui-kit/actual/layouts/BasePageLayout'
import { ProfileLayout } from '../../shared/ui-kit/actual/layouts/ProfileLayout'
import { DesktopModal } from '../../shared/ui-kit/actual/modals/DesktopModal'
import { MobileModal } from '../../shared/ui-kit/actual/modals/MobileModal'
import { RightModal } from '../../shared/ui-kit/actual/modals/RightModal'
import { MainMenuWindow } from '../../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import {
  DesktopView,
  FlexRowBetween,
  ImgButton,
  LoginText,
  MobileTabletView,
} from '../../shared/ui-kit/projectComponents'
import { shortString } from '../../shared/utils/truncate'
import MyReviewsComponent from '../ProfilePage/components/MyReviewsComponent'
import UserReviewsComponentHeader from '../ProfilePage/components/UserReviewsComponentHeader'
import UserReviewsWithSwitchTabs from '../ProfilePage/components/UserReviewsWithSwitchTabs'

const UserPage = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { userId } = useParams()
  const { data, isLoading } = useGetUserProfileQuery(userId)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const { data: buyerReviews } = useGetReviewsListQuery({
    buyerId: userId,
    reviewType: 'aboutBuyer',
    skip: 0,
    take: 150,
  })
  const buyerReviewsItems = buyerReviews?.items

  const { data: sellerReviews } = useGetReviewsListQuery({
    reviewType: 'aboutSeller',
    sellerId: userId,
    skip: 0,
    take: 150,
  })
  const sellerReviewsItems = sellerReviews?.items
  const userReviews = [...(buyerReviewsItems || []), ...(sellerReviewsItems || [])]
  const [ratingModal, setRatingModal] = useState(false)

  useDisableBodyScroll(ratingModal)

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      {ratingModal && (
        <>
          <MobileModal setViewModal={setRatingModal} viewModal={ratingModal}>
            <MyReviewsComponent
              buyerRating={data?.buyerRating}
              buyerReviews={buyerReviewsItems}
              sellerRating={data?.sellerRating}
              sellerReviews={sellerReviewsItems}
              userName={data?.accountName}
            />
          </MobileModal>
          <DesktopModal setViewModal={setRatingModal} viewModal={ratingModal}>
            <ProfileLayout
              body={
                <UserReviewsWithSwitchTabs
                  buyerReviews={buyerReviewsItems}
                  sellerReviews={sellerReviewsItems}
                />
              }
              head={
                <UserReviewsComponentHeader
                  buyerRating={data?.buyerRating}
                  buyerReviews={buyerReviewsItems}
                  sellerRating={data?.sellerRating}
                  sellerReviews={sellerReviewsItems}
                  userName={data?.accountName}
                />
              }
            />
          </DesktopModal>
        </>
      )}

      <>
        <MobileTabletView>
          <ProfileHeader
            right={
              <ImgButton
                alt={''}
                onClick={() => setIsModalOpen(prev => !prev)}
                src={'/images/buttons/menu-button.svg'}
              />
            }
            center={<LoginText>@{shortString(data?.accountName, 20)}</LoginText>}
            left={<MainLogoLink />}
          />
          <ProfileLayout
            body={
              <UserBodyWrapper
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setRatingModal={setRatingModal}
                userData={data}
                userReviews={userReviews}
              />
            }
            head={
              <UserGoodsHeader
                aboutItself={data?.aboutItself}
                accountType={data?.accountType}
                activeTab={activeTab}
                avatarLink={data?.avatarLink}
                buyerRating={data?.buyerRating}
                fullName={data?.fullName}
                isSubscribed={data?.isSubscribed}
                sellerRating={data?.sellerRating}
                setViewModal={() => null}
                subscribersCount={data?.subscribersCount}
                subscriptionsCount={data?.subscriptionsCount}
                userId={data?.id}
              />
            }
          />
          <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
            <MainMenuWindow handleModalClose={handleModalClose} />
          </RightModal>
        </MobileTabletView>

        <DesktopView>
          <BasePageLayout>
            <MainHeader />
            <FlexRowBetween style={{ alignItems: 'center' }}>
              <Breadcrumbs lastName={data?.accountName} />
            </FlexRowBetween>

            <ProfileLayout
              body={
                <UserDesktopWrapper buyerReviews={buyerReviews} sellerReviews={sellerReviews} />
              }
              head={
                <UserDesktopHeader
                  aboutItself={data?.aboutItself}
                  accountType={data?.accountType}
                  avatarLink={data?.avatarLink}
                  buyerRating={data?.buyerRating}
                  fullName={data?.fullName}
                  isSubscribed={data?.isSubscribed}
                  sellerRating={data?.sellerRating}
                  setRatingModal={setRatingModal}
                  subscribersCount={data?.subscribersCount}
                  subscriptionsCount={data?.subscriptionsCount}
                  userId={data?.id}
                />
              }
            />
          </BasePageLayout>
        </DesktopView>
      </>
    </>
  )
}

export default observer(UserPage)
