import { useState } from 'react'
import { toast } from 'react-custom-alert'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'

import { useCreateProductUpMutation } from '../../../entities/createProduct'
import { useCreateUserAddress } from '../../../entities/userAddress'
import {
  AddUserAddressCommand,
  PostApiProductsCreateProductBody,
} from '../../../shared/api/generated'
import { queryClient } from '../../../shared/api/query-client'
import { filesCompression } from '../../../shared/utils/filesCompression'

export function useCreateProductForm() {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<PostApiProductsCreateProductBody>({
    mode: 'all',
    reValidateMode: 'onBlur',
  })
  const navigate = useNavigate()

  const createAddressMutation = useCreateUserAddress({
    onError: error => {
      const e = error as AxiosError<{ errors: { Description: string[] }; message: string }>

      toast.warning(
        e.response?.data?.message ||
          e.response?.data?.errors?.Description?.[0] ||
          'Ошибка добавления адреса'
      )
    },
    onSuccess: () => {
      toast.success('Адрес успешно добавлен')
      queryClient.invalidateQueries({ queryKey: ['get_address'] })
    },
  })

  const createAddress = async (data: AddUserAddressCommand) => {
    try {
      await createAddressMutation.mutateAsync(data)
    } catch (error) {
      toast.error('Ошибка при добавлении адреса: ', error)
    }
  }

  const createProductMutation = useCreateProductUpMutation({
    onError: error => {
      const e = error as AxiosError<{ errors: { Description: string[] }; message: string }>

      toast.warning(
        e.response?.data?.message ||
          e.response?.data?.errors?.Description?.[0] ||
          'Ошибка размещения объявления'
      )
    },
    onSuccess: () => {
      toast.success('Товар успешно размещен')
      queryClient.invalidateQueries({ queryKey: ['get_products'] })
      navigate(-1)
    },
  })

  const [isCompressing, setIsCompressing] = useState(false)

  const createProduct = async (data: PostApiProductsCreateProductBody) => {
    if (data.Images) {
      setIsCompressing(true)
      await Promise.all(
        data.Images.map(async (image, idx) => {
          try {
            const { compressedFile } = await filesCompression(image)

            if (compressedFile && compressedFile instanceof File) {
              data.Images[idx] = compressedFile
            }
          } catch {
            return
          }
        })
      )

      setIsCompressing(false)
    }
    createProductMutation.mutate(data)
  }

  return {
    clearErrors,
    createProduct,
    createProductMutation,
    errors,
    getValues,
    handleSubmit: handleSubmit(createProduct),
    isCompressing,
    isValid,
    register,
    setError,
    setFocus,
    setValue,
    submitAddress: createAddress,
    trigger,
    watch,
  }
}
