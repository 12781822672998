import { toast } from 'react-custom-alert'

import styled from 'styled-components/macro'
import { useClipboard } from 'use-clipboard-copy'

import { useGenerateAndGetReferralCodeQuery } from '../../../../entities/ownProfile/queries'
import { copyTextSVG } from '../../../constants/images'
import { H4 } from '../../titles'
import { StyledButton } from '../StyledButton'

const StyledBtnPromo = styled(StyledButton)`
  padding: 0;
  min-height: unset;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
`

export const ReferralCode = ({ handleModalClose, userId }) => {
  const { data, isLoading } = useGenerateAndGetReferralCodeQuery(userId)
  const clipboard1 = useClipboard()
  const handleClick1 = () => {
    clipboard1.copy()
    toast.success('Код скопирован')
    handleModalClose()
  }
  const clipboard2 = useClipboard()
  const handleClick2 = () => {
    clipboard2.copy()
    toast.success('Ссылка скопирована')
    handleModalClose()
  }

  return (
    <div>
      <H4>Ваш реферальный код</H4>
      <StyledBtnPromo size={'s'} variant={'light'} fullwidth>
        <input
          defaultValue={data?.referralCode}
          ref={clipboard1.target}
          style={{ border: 'none', minWidth: '250px' }}
          readOnly
        />
        <img alt={''} onClick={handleClick1} src={copyTextSVG} />
      </StyledBtnPromo>
      <StyledBtnPromo variant={'light'} fullwidth>
        <input
          defaultValue={data && `https://allsharing.su/register?referral=${data?.referralCode}`}
          ref={clipboard2.target}
          style={{ border: 'none', minWidth: '250px', paddingRight: '10px' }}
          readOnly
        />
        <img alt={''} onClick={handleClick2} src={copyTextSVG} />
      </StyledBtnPromo>
    </div>
  )
}
