import { toast } from 'react-custom-alert'
import { UseMutationOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  CreateProductResponse,
  CreateProductTagCommand,
  DeleteProductTagCommand,
  GetApiProductsGetProductTagsListParams,
  PostApiProductsCreateProductBody,
  getApiProductsGetProductTagsList,
  postApiProductsCreateProduct,
  postApiProductsCreateProductTag,
  postApiProductsDeleteProductTag,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'

export function useCreateProductUpMutation(
  options?: UseMutationOptions<
    CreateProductResponse,
    unknown,
    PostApiProductsCreateProductBody,
    unknown
  >
) {
  return useMutation({
    mutationFn: (data: PostApiProductsCreateProductBody) => {
      return postApiProductsCreateProduct(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка размещения объявления')
    },
    ...options,
  })
}

export function useGetProductTagsList(params?: GetApiProductsGetProductTagsListParams) {
  return useQuery({
    onError: err => {
      console.log('ERROR: ', err)
    },
    queryFn: () => getApiProductsGetProductTagsList(params),
    queryKey: ['get_products_tags_list', params.SectionId],
    refetchOnWindowFocus: false,
  })
}

export function useDeleteProductTagMutation(
  options?: UseMutationOptions<unknown, unknown, DeleteProductTagCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: DeleteProductTagCommand) => {
      return postApiProductsDeleteProductTag(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления тега')
    },
    onSuccess: () => {
      toast.success('Тег успешно удален')
      queryClient.invalidateQueries({ queryKey: ['get_products_tags_list'] })
    },
    ...options,
  })
}

export function useCreateProductTagMutation(
  options?: UseMutationOptions<unknown, unknown, CreateProductTagCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CreateProductTagCommand) => {
      return postApiProductsCreateProductTag(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка добавления тега')
    },
    onSuccess: () => {
      toast.success('Тег успешно создан')
      queryClient.invalidateQueries({ queryKey: ['get_products_tags_list'] })
    },
    ...options,
  })
}
