import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { useDocumentsRemovePassportMutation } from '../../../entities/documents/queries'
import { usePostApiUsersGetUserInfo } from '../../../entities/ownProfile/queries'
import { VerifiedDocumentDto } from '../../../shared/api/generated'
import { useModal } from '../../../shared/hooks/useModal'
import ProfileStorage from '../../../shared/storage/ProfileStorage/ProfileStorage'
import { NoProducts } from '../../../shared/ui-kit/actual/NoProducts'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { BaseModal } from '../../../shared/ui-kit/actual/modals/BaseModal'
import { MiniModal } from '../../../shared/ui-kit/actual/modals/MiniModal'
import {
  FlexColumn,
  FlexColumnBetween,
  FlexRowBetween,
  FlexRowGap,
  MediumTextBold,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../shared/ui-kit/titles'
import { useProfileInfo } from '../../profile/model/use-profile-info'
import PassportAddForm from './PassportAddForm'
import { PassportAddStatus } from './PassportAddStatus'

const Wrapper = styled(FlexColumnBetween)``

const ListWrapper = styled(FlexColumn)`
  width: 100%;
  overflow: auto;
  column-gap: 8px;
  padding-bottom: 4px;
`
const verifiedBgColors = {
  verificationError: '#FFF4F4',
  verificationInProgress: '#FAFAF8',
  verificationRejected: '#FFF4F4',
  verified: '#F8F8FA',
}
const verifiedColors = {
  verificationError: '#FD7163',
  verificationInProgress: '#898C00',
  verificationRejected: '#FD7163',
  verified: '#008C8C',
}

export const verifiedImages = {
  verificationError: '/images/buttons/reject.svg',
  verificationInProgress: '/images/buttons/pending-clock.svg',
  verificationRejected: '/images/buttons/reject.svg',
  verified: '/images/buttons/check-white-on-bg.svg',
}

export const verifiedTexts = {
  verificationError: 'Ошибка верификации',
  verificationInProgress: 'В процессе верификации',
  verificationRejected: 'Отказано в верификации',
  verified: 'Верифицирован ',
}

export const VerifiedSign = styled(FlexRowGap)<{ $verificationStatus: string }>`
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: fit-content;
  height: 50px;
  padding: 10px 18px 10px 18px;
  border-radius: 15px;
  color: ${({ $verificationStatus }) => verifiedColors[$verificationStatus]};
  background-color: ${({ $verificationStatus }) => verifiedBgColors[$verificationStatus]};
  gap: 8px;
`
export const VerifiedText = styled.div`
  font-size: 16px;
  font-weight: 600;
`
const NoContentWrapper = styled(FlexColumnBetween)`
  width: 100%;
  height: 100%;
  row-gap: 8px;
`

const CardList = ({
  data: { rejectComment, verificationStatus },
  setViewModal,
}: {
  data: VerifiedDocumentDto
  setViewModal: Dispatch<SetStateAction<boolean>>
}) => (
  <>
    <ListWrapper>
      <div style={{ position: 'relative' }}>
        <img alt={''} src={'/images/mock-items/my-profile/passport-example.png'} />
        <VerifiedSign $verificationStatus={verificationStatus}>
          <img alt={''} src={verifiedImages[verificationStatus]} />
          <VerifiedText>{verifiedTexts[verificationStatus]}</VerifiedText>
        </VerifiedSign>
      </div>
    </ListWrapper>
    <MediumTextBold>{rejectComment}</MediumTextBold>
    <StyledButton
      onClick={() => setViewModal(true)}
      style={{ marginTop: '16px' }}
      variant={'secondary'}
      fullwidth
    >
      Удалить паспорт
    </StyledButton>
  </>
)
const NoContent = ({ setStatus, setViewModal }) => (
  <NoContentWrapper>
    <NoProducts
      imgPath={'/images/interface/passport-transparent.svg'}
      message={'Вы еще не добавили паспорт'}
    />
    <StyledButton
      onClick={() => {
        setStatus('edit')
        setViewModal(true)
      }}
      fullwidth
    >
      Добавить паспорт
    </StyledButton>
  </NoContentWrapper>
)

export type PassportAddStepStatus = 'edit' | 'error' | 'success'
const PersonalDataPassport = () => {
  const { setViewModal, viewModal } = useModal()
  const { setViewModal: setDeleteModal, viewModal: viewDeleteModal } = useModal()
  const [status, setStatus] = useState<PassportAddStepStatus>('edit')
  const {
    isLoading,
    isSuccess,
    mutate: handleDeletePassport,
  } = useDocumentsRemovePassportMutation()
  const ProfileStore = useContext(ProfileStorage)
  const { mutate } = usePostApiUsersGetUserInfo({
    onError: error => {
      console.log('profile error', error)
    },
    onSuccess: data => {
      ProfileStore.setUserInfo(data)
    },
  })

  useEffect(() => {
    if (isSuccess) {
      mutate({ userId: ProfileStore.getUserInfo().id })
      setDeleteModal(false)
      setStatus('edit')
    }
  }, [ProfileStore, isSuccess, mutate, setDeleteModal])

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <Wrapper>
        {ProfileStore.getUserInfo().passport ? (
          <CardList data={ProfileStore.getUserInfo().passport} setViewModal={setDeleteModal} />
        ) : (
          <NoContent setStatus={setStatus} setViewModal={setViewModal} />
        )}
      </Wrapper>
      <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
        {status === 'edit' ? (
          <PassportAddForm setStatus={setStatus} />
        ) : (
          <PassportAddStatus setStatus={setStatus} setViewModal={setViewModal} status={status} />
        )}
      </BaseModal>
      <MiniModal setViewModal={() => setDeleteModal(false)} viewModal={viewDeleteModal}>
        <FlexColumn>
          <br />
          <H2>Вы уверены, что хотите удалить паспорт?</H2>
          <br />
          <FlexRowBetween style={{ gap: '12px' }}>
            <StyledButton onClick={() => setDeleteModal(false)} variant={'secondary'}>
              НЕТ
            </StyledButton>
            <StyledButton
              onClick={() =>
                handleDeletePassport({ passportId: ProfileStore.getUserInfo().passport?.id })
              }
              variant={'warning'}
            >
              ДА
            </StyledButton>
          </FlexRowBetween>
          <br />
        </FlexColumn>
      </MiniModal>
    </>
  )
}

export default observer(PersonalDataPassport)
