import React from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { FilterTitle, FlexColumnStart, FlexRowBetween } from '../projectComponents'
import { SwitchComponent } from './SwitchComponent'

const Block = styled(FlexRowBetween)`
  cursor: pointer;
  margin-right: 12px;
  align-items: center;

  img {
    height: 18px;
    width: 18px;
  }
`
const Subtitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.grey};
`

interface FilterProps {
  subtitle: string
  switchOn: boolean
  title: string
  toggleSwitch: () => void
}

export const InputSwitchWithTitle: React.FC<FilterProps> = ({
  subtitle,
  switchOn,
  title,
  toggleSwitch,
}) => {
  return (
    <Block onClick={toggleSwitch}>
      <FlexColumnStart style={{ maxWidth: '80%' }}>
        <FilterTitle>{title}</FilterTitle>
        <Subtitle>{subtitle}</Subtitle>
      </FlexColumnStart>
      <SwitchComponent checked={switchOn} onChange={toggleSwitch} />
    </Block>
  )
}
