import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { devices } from '../../breakPoints'
import { FlexColumn, LoginDescription, LoginTitle, StyledButton } from '../../projectComponents'

const AuthWrapper = styled(FlexColumn)`
  width: 100%;
  text-align: left;
  gap: 8px;
  padding: 24px;
  align-items: start;
  @media screen and (${devices.desktopMin}) {
    width: 358px;
    text-align: center;
    gap: 8px;
    align-items: center;
    padding: 0;
  }
`

export const LoginOrSignupComponent = () => {
  const navigate = useNavigate()

  return (
    <AuthWrapper>
      <LoginTitle>Войдите в аккаунт</LoginTitle>
      <LoginDescription>
        Арендуйте и выкладывайте свои товары, добавляйте в избранное и пишите в блоге{' '}
      </LoginDescription>
      <StyledButton
        onClick={() => navigate('/login')}
        size={'xl'}
        style={{ marginTop: 16 }}
        type={'button'}
        variant={'primary'}
        fullwidth
      >
        Вход
      </StyledButton>
      <StyledButton
        onClick={() => navigate('/register')}
        size={'xl'}
        type={'button'}
        variant={'secondary'}
        fullwidth
      >
        Регистрация
      </StyledButton>
    </AuthWrapper>
  )
}
