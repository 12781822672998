import Select from 'react-select'

import styled from 'styled-components/macro'

import { colors } from '../constants/colors'

export const SelectWrapper = styled.div`
  .container {
    border-color: none;
    background-color: ${colors.lightGray};
  }
`
export const StyledSelect = styled(Select)`
  & .Select__control {
    border-color: ${colors.lightGray};
    background-color: ${colors.lightGray};
    border-radius: 10px;
    height: 50px;
    padding: 7px 7px 7px 20px;
    box-shadow: none;
    border-color: none !important;
    & :hover {
      box-shadow: none;
      border-color: none !important;
    }
  }
  & .Select__control--menu-is-open {
    box-shadow: none;
    border-color: none;

    & :hover {
      box-shadow: none;
      border-color: ${colors.primary};
      outline: none;
    }
    & .Select__dropdown-indicator {
      transform: rotate(180deg);
    }
  }

  & .Select__control--is-focused {
    box-shadow: none;
    border-color: none;
    & :hover {
      box-shadow: none;
      border-color: none;
    }
  }
  & .Select__menu {
    border-radius: 10px;
    border: solid 0.5px ${colors.gray};
    box-shadow: 0 0 0 1px ${colors.lightGray};
  }
  & .Select__menu-list {
    max-height: 250px;
  }
  & .Select__value-container {
    color: ${colors.grey};
    padding: 0;
  }
  & .Select__single-value {
    color: ${colors.grey};
  }
  & .Select__indicators {
    width: 30px;
  }
  & .Select__indicator-separator {
    display: none;
  }
  & .Select__dropdown-indicator {
    background-image: url('/images/buttons/arrow-down.svg');
    background-repeat: no-repeat;
    width: 28px;
    height: 25px;
    svg {
      display: none;
    }
  }
  & .Select__option:hover {
    background-color: ${colors.primaryBg};
  }
  & .Select__option:active {
    background-color: ${colors.lightGrayBg};
  }
  & .Select__option--is-selected {
    background-color: ${colors.primary};
    & :hover {
      background-color: ${colors.primaryActive};
    }
    & :active {
      background-color: ${colors.primaryActive};
    }
  }
`
