import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { UserListItem } from '../../../shared/api/generated'
import { colors } from '../../../shared/constants/colors'
import { FlexRow, StyledButton, StyledLink } from '../../../shared/ui-kit/projectComponents'
import { truncateString } from '../../../shared/utils/truncate'

interface AdminUsersTableRowProps {
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPaymentsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setRolesOpen: React.Dispatch<React.SetStateAction<boolean>>
  setUserId: React.Dispatch<React.SetStateAction<string>>
  user: UserListItem
}

const AdminUsersTableRow = ({
  setDeleteOpen,
  setPaymentsOpen,
  setRolesOpen,
  setUserId,
  user,
}: AdminUsersTableRowProps) => {
  const navigate = useNavigate()

  return (
    <tr>
      <td style={{ width: '40px' }}>
        <img
          alt={''}
          src={user?.avatarImageUrl || '/images/buttons/user-avatar.png'}
          style={{ borderRadius: '100%', height: 30, objectFit: 'cover', width: 30 }}
        />
      </td>
      <td style={{ minWidth: '250px' }}>
        <Link to={`/admin/users/${user.id}`}>{user.fullName}</Link>
      </td>
      <td>
        <Link to={`/users/${user.id}`}>{user.userName}</Link>
      </td>
      <td>
        <Link to={`mailto:${user.email}`}>{user.email}</Link>
      </td>
      <td>
        <Link to={`tel:${user.phone}`}>{user.phone}</Link>
      </td>
      <td>
        <Link to={`/admin/users/${user.id}`}>{truncateString(user.id, 8)}</Link>
      </td>
      {/* <td>
        {user?.buyerRating?.toFixed(1)} / {userProfile?.buyerRating?.toFixed(1)}
      </td> */}
      <td>
        <FlexRow style={{ gap: 6 }}>
          <StyledButton
            onClick={() => {
              setPaymentsOpen(true)
              setUserId(user.id)
            }}
            size={'s'}
            style={{ width: '100%' }}
          >
            Баланс
          </StyledButton>
          <StyledButton
            onClick={() => {
              setRolesOpen(true)
              setUserId(user.id)
            }}
            size={'s'}
            style={{ width: '100%' }}
            variant={'outline'}
          >
            Роли
          </StyledButton>
          <StyledButton
            onClick={() => {
              setDeleteOpen(true)
              setUserId(user.id)
            }}
            size={'s'}
            style={{ background: colors.redFail, width: '100%' }}
          >
            Удалить
          </StyledButton>
        </FlexRow>
      </td>
    </tr>
  )
}

export default AdminUsersTableRow
