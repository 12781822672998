import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getTime } from 'date-fns'
import styled from 'styled-components/macro'

import { useGetGeneralStatisticQuery } from '../entities/admin/queries'
import { useGetClaimsListQuery } from '../entities/claims/queries'
import { useGetProductTagsList } from '../entities/createProduct/queries'
import { useGetWithdrawalRequestsListQuery } from '../entities/payments/queries'
import { useGetProductsListQuery } from '../entities/productsList/queries'
import { useGetReviewsListQuery } from '../entities/reviews/queries'
import { useGetSectionsListQuery } from '../entities/sectionList'
import {
  useGetOperationsListQuery,
  useGetTransactionsQuery,
} from '../entities/transactions/queries'
import { useGetUsersListProfileQuery } from '../entities/userProfile/queries'
import { IResponsePasport } from '../shared/api/deprecated/documentService/DocumentService'
import { IDepositResponse } from '../shared/api/deprecated/paymentService/PaymentService'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { OperationComponent } from '../shared/ui-kit/deprecated/AdminComponents/OperationComponent'
import { PassportComponent } from '../shared/ui-kit/deprecated/AdminComponents/PassportComponent'
import { ReviewComponent } from '../shared/ui-kit/deprecated/AdminComponents/ReviewComponent'
import HeaderComponent from '../shared/ui-kit/deprecated/HeaderComponent'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import SortModal from '../shared/ui-kit/deprecated/modal/SortModal'
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  StyledButtonTransparent,
} from '../shared/ui-kit/projectComponents'
import { H2 } from '../shared/ui-kit/titles'
import { useGetPassportsListQuery } from '../entities/documents/queries'

const Page = styled(FlexColumn)`
  height: auto;
  @media (max-width: 768px) {
    height: 100vh;
  }
  @media (min-width: 280px) {
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    justify-content: flex-start;
  }
`

const CategoryButton = styled(FlexColumn)`
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  margin: 10px;
  width: clamp(300px, 39%, 800px);
  height: 100px;
  cursor: pointer;

  p {
    font-size: 15px;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  img {
    width: 30px;
    height: 30px;
    margin-bottom: 8px;
  }
`
const Filter = styled.div`
  padding: 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-right: 0;
  }

  img {
    width: 20px;
  }
`

const AdminCategoryWrapper = styled(FlexColumn)`
  width: calc(100% - 40px);
`

const Table = styled.table`
  font-size: 22px;
  font-weight: 600;
  td:first-child {
    font-size: 20px;
    font-weight: 400;
    padding-right: 20px;
  }
`
const productSortSelectors = [
  {
    title: 'По умолчанию',
    type: 'Default',
  },
  {
    title: 'По рейтингу - сверху',
    type: 'ByARating',
  },
  {
    title: 'По рейтингу - снизу',
    type: 'ByDRating',
  },
  {
    title: 'По количеству заказов - больше',
    type: 'ByAOrders',
  },
  {
    title: 'По количеству заказов - меньше',
    type: 'ByDOrders',
  },
  {
    title: 'По дате - новые',
    type: 'ByADate',
  },
  {
    title: 'По дате - старые',
    type: 'ByDDate',
  },
  {
    title: 'По цене в день - больше',
    type: 'ByDayAPrice',
  },
  {
    title: 'По цене в день - меньше',
    type: 'ByDayDPrice',
  },
  {
    title: 'По цене за 3 дня - больше',
    type: 'By3DaysAPrice',
  },
  {
    title: 'По цене за 3 дня - меньше',
    type: 'By3DaysDPrice',
  },
  {
    title: 'По цене за неделю - больше',
    type: 'ByWeekAPrice',
  },
  {
    title: 'По цене за неделю - меньше',
    type: 'ByWeekDPrice',
  },
  {
    title: 'По цене за месяц - больше',
    type: 'ByMonthAPrice',
  },
  {
    title: 'По цене за месяц - меньше',
    type: 'ByMonthDPrice',
  },
]
const docsSortSelectors = [
  {
    title: 'Ожидает ответа',
    type: 'Pending',
  },
  {
    title: 'Одобрено',
    type: 'Approved',
  },
  {
    title: 'Отклонено',
    type: 'Rejected',
  },
]
const menuStatuses = [
  // {
  //   img: '/images/bottom-menu/profile-gray.svg',
  //   pageTitle: 'Список пользователей',
  //   status: 'USERS',
  //   title: 'Пользователи',
  // },
  {
    img: '/images/interface/finance.png',
    pageTitle: 'Список операций',
    status: 'OPERATIONS',
    title: 'Операции',
  },
  {
    img: '/images/interface/review.png',
    pageTitle: 'Список отзывов',
    status: 'REVIEWS',
    title: 'Отзывы',
  },
  {
    img: '/images/interface/review.png',
    pageTitle: 'Список документов',
    status: 'DOCS',
    title: 'Документы',
  },
]

export const AdminPage: React.FC = () => {
  const [allDeposits, setAllDeposits] = useState<IDepositResponse[]>(null)
  const [allWithdraws, setAllWithdraws] = useState<IDepositResponse[]>(null)
  const setIsDeposit = (data, isDeposit: boolean) =>
    data?.map(d => {
      const newObj = { ...d } // Create a new object by spreading the original object

      newObj.isDeposit = isDeposit // Add the new key-value pair to the new object

      return newObj // Return the new object
    })
  const allOperations = useMemo(
    () =>
      allDeposits &&
      allWithdraws &&
      setIsDeposit(allDeposits, true)
        .concat(setIsDeposit(allWithdraws, false))
        .sort((a, b) => getTime(new Date(b.createdAt)) - getTime(new Date(a.createdAt))),
    [allDeposits, allWithdraws]
  )
  const [allPassports, setAllPassports] = useState<IResponsePasport[]>(null)

  const [openProductsSortModal, setOpenProductsSortModal] = useState(false)
  const [openDocsSortModal, setOpenDocsSortModal] = useState(false)
  const [menuStatus, setMenuStatus] = useState('BASE')

  const [productSortType, setProductSortTypes] = useState('Default')
  const [depositSortType, setDepositSortTypes] = useState('Default')
  const [reviewSortType, setReviewSortType] = useState('Default')
  const [documentSortType, setDocumentSortType] = useState('Pending')

  const handleProductModal = () => {
    setOpenProductsSortModal(!openProductsSortModal)
  }
  const handleDocsModal = () => {
    setOpenDocsSortModal(!openDocsSortModal)
  }

  const {
    data: allReviews,
    isFetching: isFetchingAllReviews,
    isLoading: isLoadingReviews,
    refetch: refetchAllReviews,
  } = useGetReviewsListQuery({
    take: 0,
  })
  const reviewsItems = allReviews?.items

  const { data: allTransactions, isLoading: isTransactionsLoading } = useGetTransactionsQuery({})
  const { data: allOperationsList, isLoading: isOperationsLoading } = useGetOperationsListQuery({})
  const { data: allTags, isLoading: isLoadingAllTags } = useGetProductTagsList({})
  const { data: allSections, isLoading: isLoadingAllSections } = useGetSectionsListQuery({})
  const { data: statistics, isLoading: isLoadingStatistics } = useGetGeneralStatisticQuery({})
  const { data: passports, isLoading: isLoadingPassports } = useGetPassportsListQuery({})
  const { data: claimsData, isLoading: isClaimsLoading } = useGetClaimsListQuery({})
  const { data: withdrawalRequestsData, isLoading: isWithdrawalRequestsLoading } =
    useGetWithdrawalRequestsListQuery({})
  const navigate = useNavigate()

  return (
    <Page>
      <AbsoluteLoader
        isLoading={
          isTransactionsLoading ||
          isLoadingAllSections ||
          isLoadingAllTags ||
          isLoadingStatistics ||
          isClaimsLoading ||
          isOperationsLoading ||
          isWithdrawalRequestsLoading
        }
      />
      <HeaderComponent />
      <SortModal
        handleMenu={handleProductModal}
        openMenu={openProductsSortModal}
        selectedType={productSortType}
        selectors={productSortSelectors}
        setSelectedSort={setProductSortTypes}
      />
      <SortModal
        handleMenu={handleDocsModal}
        openMenu={openDocsSortModal}
        selectedType={documentSortType}
        selectors={docsSortSelectors}
        setSelectedSort={setDocumentSortType}
      />
      <H2>Общая статистика</H2>
      <br />
      <Table>
        <tbody>
          <tr>
            <td>Всего активных пользователей: </td>
            <td>{statistics?.users.activeUsersCount}</td>
          </tr>
          <tr>
            <td>Баланс на счетах всех пользователей: </td>
            <td>{statistics?.users.activeUsersSummaryBalance}</td>
          </tr>
          <tr>
            <td>Бонусы на счетах всех пользователей: </td>
            <td>{statistics?.users.activeUsersSummaryBonusBalance}</td>
          </tr>
          <tr>
            <td>Всего активных объявлений: </td>
            <td>{statistics?.products.activeProductsCount}</td>
          </tr>
          <tr>
            <td>Всего активных аренд: </td>
            <td>{statistics?.rents.activeRentsCount}</td>
          </tr>
        </tbody>
      </Table>
      <FlexRow style={{ flexWrap: 'wrap', justifyContent: 'center', marginTop: 20, width: '100%' }}>
        <CategoryButton onClick={() => navigate('/admin/users')}>
          <img alt={''} src={'/images/bottom-menu/profile-gray.svg'} />
          <p>Пользователи</p>
          <p>{statistics?.users.activeUsersCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('sections')}>
          <img alt={''} src={'/images/bottom-menu/cart-gray.svg'} />
          <p>Объявления</p>
          <p>{statistics?.products.activeProductsCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('transactions')}>
          <img alt={''} src={'/images/interface/finance.png'} />
          <p>Транзакции</p>
          <p>{allTransactions?.totalCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('operations')}>
          <img alt={''} src={'/images/interface/finance.png'} />
          <p>Операции</p>
          <p>{allOperationsList?.totalCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('/admin/withdrawals')}>
          <img alt={''} src={'/images/interface/finance.png'} />
          <p>Запросы на вывод</p>
          <p>{withdrawalRequestsData?.totalCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('reviews')}>
          <img alt={''} src={'/images/interface/review.png'} />
          <p>Отзывы</p>
          <p>{allReviews?.totalCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('claims')}>
          <img alt={''} src={'/images/buttons/Edit.svg'} style={{ opacity: '0.6' }} />
          <p>Жалобы</p>
          <p>{claimsData?.totalCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('passports')}>
          <img
            alt={''}
            src={'/images/interface/passport-transparent.svg'}
            style={{ opacity: '0.6' }}
          />
          <p>Паспорта</p>
          <p>{passports?.totalCount}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('add-section')}>
          <img alt={''} src={'/images/interface/review.png'} />
          <p>Разделы</p>
          <p>{allSections?.sections?.length}</p>
        </CategoryButton>
        <CategoryButton onClick={() => navigate('tags')}>
          <H2 style={{ opacity: '0.6' }}>#</H2>
          <p>Теги</p>
          <p>{allTags?.totalCount}</p>
        </CategoryButton>
      </FlexRow>
      {menuStatus !== 'BASE' && (
        <AdminCategoryWrapper>
          <FlexRowBetween style={{ marginBottom: 12 }}>
            <StyledButtonTransparent
              onClick={() => setMenuStatus('BASE')}
              style={{ height: 38, marginLeft: 0 }}
              mini
            >
              Назад
            </StyledButtonTransparent>
            <p> {menuStatuses.find(m => m.status === menuStatus).pageTitle}</p>
          </FlexRowBetween>

          {menuStatus === 'OPERATIONS' && (
            <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15 }}>
              <p style={{ fontSize: 15, margin: 0, padding: 0 }}>
                Всего операций: {allDeposits?.length}
              </p>
            </FlexRowBetween>
          )}
          {menuStatus === 'REVIEWS' && (
            <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15 }}>
              <p style={{ fontSize: 15, margin: 0, padding: 0 }}>
                Всего отзывов: {allReviews?.totalCount}
              </p>
            </FlexRowBetween>
          )}
          {menuStatus === 'DOCS' && (
            <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15 }}>
              <p style={{ fontSize: 15, margin: 0, padding: 0 }}>
                Всего документов: {allPassports?.length}
              </p>
              <Filter onClick={() => setOpenDocsSortModal(true)}>
                <img alt={''} src={'/images/buttons/filter2.png'} />
              </Filter>
            </FlexRowBetween>
          )}
          {menuStatus === 'OPERATIONS' &&
            allOperations &&
            allOperations.map((operation, idx) => (
              <OperationComponent key={idx} operation={operation} />
            ))}
          {menuStatus === 'REVIEWS' &&
            (isFetchingAllReviews ? (
              <PageLoader />
            ) : (
              reviewsItems &&
              reviewsItems?.map(review => (
                <ReviewComponent key={`${review.createOn}+${review.authorId}`} review={review} />
              ))
            ))}
          {menuStatus === 'DOCS' &&
            allPassports &&
            allPassports.map(passport => (
              <PassportComponent key={passport.id} passport={passport} refetch={() => null} />
            ))}
        </AdminCategoryWrapper>
      )}
    </Page>
  )
}
