import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { CategoriesModal } from '../..'
import { usePathnames } from '../../../../shared/hooks/usePathnames'
import { useQueryString } from '../../../../shared/hooks/useQueryString'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import {
  FilterTitle,
  FlexColumnStart,
  NeutralLink,
} from '../../../../shared/ui-kit/projectComponents'
import { useGetSectionsListQuery } from '../../../sectionList'
import FilterByCategories from './FilterByCategories'

const SectionWrapper = styled(FlexColumnStart)`
  width: 100%;
`

const SectionTitle = styled(FilterTitle)`
  margin-bottom: 16px;
`

const ClearStyledBtn = styled.button`
  background: none;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  color: #008c8c;
  font-weight: 700;
  font-size: 14;
  cursor: pointer;
`

const FilterByCategoriesSection = ({
  handleFilterModalOpen,
  isSearch,
}: {
  handleFilterModalOpen: () => void
  isSearch?: boolean
}) => {
  const { data: sectionData, isLoading } = useGetSectionsListQuery({})

  const navigate = useNavigate()
  const [selectedSort, setSelectedSort] = useState('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const onOpenModalHandler = () => {
    setIsModalOpen(true)
  }
  const onCloseModalHandler = () => {
    setIsModalOpen(false)
  }
  const { setValue } = useQueryString('sectionId')
  const handleSetOrder = (value: string) => {
    console.log(isSearch)
    setSelectedSort(value)
    isSearch ? setValue(value) : navigate(`/sections/${value}`)
    onCloseModalHandler()
    handleFilterModalOpen()
  }

  return (
    <SectionWrapper>
      <NeutralLink to={'/sections'}>
        <SectionTitle>Разделы</SectionTitle>
      </NeutralLink>
      <FilterByCategories
        handleSetOrder={handleSetOrder}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
        sortingCategories={sectionData?.sections || []}
      />
      <ClearStyledBtn onClick={onOpenModalHandler}>Все разделы</ClearStyledBtn>
      <BaseModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
        <CategoriesModal
          handleSetOrder={handleSetOrder}
          onCloseModal={onCloseModalHandler}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          sortingCategories={sectionData?.sections || []}
        />
      </BaseModal>
    </SectionWrapper>
  )
}

export default FilterByCategoriesSection
