import React from 'react'

import styled from 'styled-components/macro'

import { ChatMessageExtended, GetChatInfoResponse, RentStatus } from '../../../shared/api/generated'
import { ErrorText, FlexColumnStart } from '../../../shared/ui-kit/projectComponents'
import { useChatConnection } from '../model/use-chat-connection'
import MessageContainer from './MessageContainer/MessageContainer'
import MessageHeader from './MessageHeader/MessageHeader'
import MessageInputBar from './MessageInputBar/MessageInputBar'

const Wrapper = styled(FlexColumnStart)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 80vh;

  @media all and (max-width: 800px) {
    height: 90vh;
    height: 100svh;
    padding: 0;
  }
`

interface MessageWrapperProps {
  chatInfo?: GetChatInfoResponse
  chatInfoError?: boolean
  handleApproveRent?: () => void
  isRenter?: boolean
  rentId: number
  status: RentStatus
}

export const MessageWrapper = ({
  chatInfo,
  chatInfoError,
  handleApproveRent,
  isRenter,
  rentId,
  status,
}: MessageWrapperProps) => {
  const { chatContainerRef, connectionIsLoading, endRef, messageData } = useChatConnection()

  if (chatInfoError) {
    return <ErrorText>Ошибка загрузки сообщений</ErrorText>
  }

  return (
    <Wrapper>
      <MessageHeader
        chatInfo={chatInfo}
        handleApproveRent={handleApproveRent}
        isRenter={isRenter}
        rentId={rentId}
        status={status}
      />
      <MessageContainer containerRef={chatContainerRef} data={messageData} endRef={endRef} />
      <MessageInputBar connectionIsLoading={connectionIsLoading} />
    </Wrapper>
  )
}
