import { ChangeEvent } from 'react'
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'

import { PostApiProductsCreateProductBody } from '../../../../shared/api/generated'
import { Dropdown } from '../../../../shared/ui-kit/actual/Dropdown'
import { BlockTitleWithButton } from '../../../../shared/ui-kit/actual/InfoButton'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { DoubleColumn, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'

type IntervalsItemProps = {
  clearErrors: UseFormClearErrors<PostApiProductsCreateProductBody>
  errors: FieldErrors<PostApiProductsCreateProductBody>
  getValues: UseFormGetValues<PostApiProductsCreateProductBody>
  intervals: any
  mockIntervals: any
  oneDayRequired: boolean
  register: UseFormRegister<PostApiProductsCreateProductBody>
  selectedIndexes: any
  setError: UseFormSetError<PostApiProductsCreateProductBody>
  setIntervals: (prevState: any) => void
  setSelectedIndexes: (prevState: any) => void
  setValue: UseFormSetValue<PostApiProductsCreateProductBody>
  trigger: any
}
export const IntervalsItem = ({
  clearErrors,
  errors,
  getValues,
  intervals,
  mockIntervals,
  oneDayRequired,
  register,
  selectedIndexes,
  setError,
  setIntervals,
  setSelectedIndexes,
  setValue,
  trigger,
}: IntervalsItemProps) => {
  const handleSetPrice = (
    e: ChangeEvent<HTMLInputElement>,
    idx: number,
    option: keyof PostApiProductsCreateProductBody
  ) => {
    const value = e.currentTarget.value

    setIntervals(prevState => {
      const newState = [...prevState]
      const item = newState.find(it => it.key === idx)
      const index = newState.findIndex(it => it.key === idx)

      newState[index] = { ...item, price: value }
      setValue(option, +value)
      trigger()

      return newState
    })
  }

  const handlePricesErrors = (idx: number) => {
    if (oneDayRequired && !getValues('DayPrice')) {
      setError('DayPrice', { message: 'Цена за один день обязательна' })
      trigger()
    } else if (getValues('DayPrice')) {
      clearErrors('DayPrice')
      trigger()
    }
  }

  return (
    <>
      <BlockTitleWithButton>
        <H3>Цены</H3>
      </BlockTitleWithButton>

      <TextField
        inputProps={{
          placeholder: 'Залог',
          type: 'number',
          ...register('Pledge', { required: 'Залог необходимо указать' }),
        }}
        error={errors.Pledge?.message?.toString()}
        label={'Залог'}
        size={'md'}
        fullwidth
      />

      {intervals.map((it, idx) => {
        return (
          <DoubleColumn key={idx}>
            <Dropdown
              handleChangeValue={e => {
                setIntervals(prevState => {
                  const newState = [...prevState]

                  newState[idx] = {
                    ...newState[idx],
                    key: e.key,
                    option: e.option,
                    value: e.value,
                  }

                  return newState
                })
              }}
              selectedId={
                selectedIndexes.includes(it.key)
                  ? intervals.find(it => !selectedIndexes.includes(it.key))?.key
                  : it.key
              }
              disabled={!!it.price}
              items={mockIntervals}
              label={it.price ? it.value : 'Период'}
              selectedIndexes={selectedIndexes}
              size={'lg'}
              style={{ zIndex: 1300 - idx }}
              fullwidth
            />
            <TextField
              inputProps={{
                // disabled: idx !== intervals.length - 1,
                // onBlur: () => handlePricesErrors(idx),
                onChange: e => {
                  handleSetPrice(e, it.key, it.option)
                },
                placeholder: 'Цена',
                type: 'number',
                value: it.price,
              }}
              right={
                idx !== 0 ? (
                  <img
                    onClick={() => {
                      setIntervals(prevState =>
                        prevState.filter(interval => interval.key !== it.key)
                      )
                      setSelectedIndexes(prevState => prevState.filter(n => n !== it.key))
                      setValue(it.option, null)
                    }}
                    alt={'delete'}
                    src={'/images/buttons/delete.svg'}
                    style={{ cursor: 'pointer', width: '12px' }}
                  />
                ) : null
              }
              error={errors.DayPrice?.message?.toString()}
              label={'Цена'}
              size={'md'}
              fullwidth
            />
          </DoubleColumn>
        )
      })}
      <StyledButton
        onClick={e => {
          e.preventDefault()
          setIntervals(prevState => [
            ...prevState,
            {
              key: prevState.length,
              option: mockIntervals[prevState.length].option,
              price: mockIntervals[prevState.length].price,
              value: mockIntervals[prevState.length].value,
            },
          ])
        }}
        disabled={!intervals.at(-1)?.price || intervals.length >= mockIntervals.length}
        variant={'secondary'}
        fullwidth
      >
        Добавить период
      </StyledButton>
    </>
  )
}
