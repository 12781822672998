import React from 'react'

import { useRentsArriveBackToSellerMutation } from '../../../../entities/rent/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { IncomingPhotos } from '../../../../shared/ui-kit/actual/IncomingPhotos'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumn, MediumText, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H3, H4 } from '../../../../shared/ui-kit/titles'
import { useSendPhotos } from '../../model/useSendPhotos'
import { AddPhotos } from '../AddPhotos'
import { StepWrapper } from '../StepWrapper'

type Props = {
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const Step5 = ({ rentInfo, setStep }: Props) => {
  const { isLoading, isSuccess, mutate } = useRentsArriveBackToSellerMutation()

  const { arePhotosSelected, handleSubmit, isCompressing, setArePhotosSelected, setImageFiles } =
    useSendPhotos({
      isSuccess,
      mutate,
      setStep,
    })

  return (
    <StepWrapper>
      <AbsoluteLoader isLoading={isLoading || isCompressing} />
      <FlexColumn>
        <H3>Сфотографируйте товар</H3>
        <MediumText style={{ alignSelf: 'flex-start' }}>
          Проверьте, что арендатор не оставил дефектов. Обязательно сфотографируйте товар со всех
          сторон. Это поможет в разрешении возможных споров{' '}
        </MediumText>
        <H4>Фото товара до использования</H4>
        <IncomingPhotos data={rentInfo?.beforeRentSellerImagesUrls} />
        <H4>Фото после использования</H4>

        <AddPhotos
          arePhotosSelected={arePhotosSelected}
          secondPhotoButtonNote={'Изменить фотографии'}
          setArePhotosSelected={setArePhotosSelected}
          setImageFiles={setImageFiles}
        />
      </FlexColumn>

      <FlexColumn style={{ gap: '8px' }}>
        <StyledButton onClick={() => setStep(10)} variant={'secondary'} fullwidth>
          Оставить жалобу
        </StyledButton>
        <StyledButton disabled={!arePhotosSelected || isLoading} onClick={handleSubmit} fullwidth>
          Забрать товар
        </StyledButton>
      </FlexColumn>
    </StepWrapper>
  )
}
