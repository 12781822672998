import { useState } from 'react'

import { ProductRating } from './ProductRating'
import { RentalRating } from './RentalRating'

export const RatingModalContent = ({ setViewModal }) => {
  const [ratingItem, setRatingItem] = useState('product')

  return (
    <>
      {ratingItem === 'product' ? (
        <ProductRating setRatingItem={setRatingItem} setViewModal={setViewModal} />
      ) : (
        <RentalRating setRatingItem={setRatingItem} setViewModal={setViewModal} />
      )}
    </>
  )
}
