import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { GetChatInfoResponse, RentStatus } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { ArrowBackButton } from '../../../../shared/ui-kit/actual/ArrowBackButton'
import {
  MobileTabletView,
  StyledButton,
  StyledLink,
} from '../../../../shared/ui-kit/projectComponents'

const ChatFormHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 100;
  width: 100%;
  padding: 20px 24px 16px 24px;
  box-shadow: 0px 7.800000190734863px 13.606666564941406px 0px #5e5e5e12;
  border-radius: 0 0 12px 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
`

const ChatInfoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
`

const ChatInfoImg = styled.img`
  width: 57px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
`

const ChatInfoDescriptionWrapper = styled.div``

const ChatInfoDescriptionName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: ${colors.black};

  @media all and (max-width: 800px) {
    font-size: 14px;
  }
`

const ChatInfoDescriptionProduct = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  color: ${colors.grey};

  @media all and (max-width: 800px) {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 189px;
    display: block;
  }
`

const ChatInfoDescriptionPrice = styled.span`
  color: ${colors.primary};
  font-size: 13px;
`

interface MessageHeaderProps {
  chatInfo?: GetChatInfoResponse
  handleApproveRent?: () => void
  isRenter?: boolean
  rentId: number
  status: RentStatus
}

const MessageHeader = ({
  chatInfo,
  handleApproveRent,
  isRenter,
  rentId,
  status,
}: MessageHeaderProps) => {
  return (
    <ChatFormHeader>
      <ChatInfoWrapper to={`/item/${chatInfo?.productId}`}>
        <ArrowBackButton />
        {chatInfo?.productImagesUrls[0] ? (
          <ChatInfoImg src={chatInfo?.productImagesUrls[0]} />
        ) : null}
        <ChatInfoDescriptionWrapper>
          <ChatInfoDescriptionName>{chatInfo?.buyerFullName}</ChatInfoDescriptionName>
          <div style={{ alignItems: 'center', display: 'flex', gap: '6px' }}>
            <ChatInfoDescriptionProduct>{chatInfo?.productName} </ChatInfoDescriptionProduct>
            <ChatInfoDescriptionPrice>
              {chatInfo?.productPledge.toLocaleString('ru-RU')}₽
            </ChatInfoDescriptionPrice>
          </div>
        </ChatInfoDescriptionWrapper>
      </ChatInfoWrapper>
      <MobileTabletView>
        {isRenter && status === 'created' && (
          <StyledButton onClick={handleApproveRent} fullwidth>
            Сдать в аренду
          </StyledButton>
        )}
        {isRenter && status !== 'created' && (
          <StyledLink to={`/delivery/send/${rentId}`} fullWidth>
            К аренде
          </StyledLink>
        )}
        {!isRenter && (
          <StyledLink to={`/delivery/receive/${rentId}`} fullWidth>
            К аренде
          </StyledLink>
        )}
      </MobileTabletView>
    </ChatFormHeader>
  )
}

export default MessageHeader
