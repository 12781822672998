import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { ArticleVariant } from '../../features/article'
import { MarketSections } from '../../features/market/ui/sections/MarketSections'
import useSectionsLoader from '../../shared/hooks/useSectionsLoader'
import { BottomMenuComponent } from '../../shared/ui-kit/actual/BottomMenuComponent'
import { AbsoluteLoader } from '../../shared/ui-kit/actual/components/AbsoluteLoader'
import { MarketUsefulArticles } from '../../shared/ui-kit/actual/components/MarketUsefulArticles'
import { MainHeader } from '../../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../../shared/ui-kit/actual/layouts/BasePageLayout'
import { breakpoints } from '../../shared/ui-kit/breakPoints'
import {
  BasePage,
  DesktopView,
  FlexColumn,
  FlexRow,
  MarketWrapper,
  MobileTabletView,
  MobileView,
  StyledLink,
  TabletDesktopView,
  TabletOnlyView,
} from '../../shared/ui-kit/projectComponents'

interface MarketPageProps {}

const ChangeOrderWrapper = styled(FlexRow)`
  display: none;
  @media screen and (min-width: ${breakpoints.desktopMin}) {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 20px;
  }
`
const ChangeOrderItem = styled.div`
  width: fit-content;
`

export const MarketPage: React.FC<MarketPageProps> = observer(() => {
  const { isLoading, sections } = useSectionsLoader()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      !isLoading && setLoading(false)
    }, 100)

    return () => clearTimeout(timeout)
  }, [isLoading, setLoading])

  return (
    <>
      {loading ? (
        <AbsoluteLoader isLoading={loading} />
      ) : (
        <BasePageLayout>
          <MainHeader isMobile />
          <MarketWrapper>
            {/* <ButtonPanelRow buttons={tagsMockData} /> */}
            {/* <MarketMainBanner /> */}
            {/* <MobileTabletView> */}
            <MarketSections sections={sections} isLoading />
            <br />
            {/* <MarketForYou /> */}
            {/* </MobileTabletView> */}
            {/* <ChangeOrderWrapper> */}
            {/* <ChangeOrderItem style={{ flex: '1 1' }}>
            <MarketForYou />
          </ChangeOrderItem> */}
            {/* <ChangeOrderItem style={{ flex: '0.7 1' }}> */}
            {/* <MarketSections sections={sections} isLoading /> */}
            {/* </ChangeOrderItem> */}
            {/* </ChangeOrderWrapper> */}
            {/* <MarketPromo /> */}
            {/* <MobileTabletView>
          <MarketPopular />
        </MobileTabletView> */}
            <MobileTabletView>
              <FlexColumn>
                <MarketUsefulArticles title={'Полезное'} />
                <br />
                <StyledLink style={{ width: '320px' }} to={'/blog'}>
                  Все статьи
                </StyledLink>
              </FlexColumn>
            </MobileTabletView>
            {/* <TabletOnlyView> */}
            <DesktopView>
              <FlexColumn>
                <MarketUsefulArticles title={'Полезное'} variant={ArticleVariant.Long} />
                <br />
                <StyledLink style={{ width: '350px' }} to={'/blog'}>
                  Все статьи
                </StyledLink>
              </FlexColumn>
            </DesktopView>
            {/* </TabletOnlyView> */}
            {/* <ChangeOrderWrapper> */}
            {/* <ChangeOrderItem style={{ flex: '1 1' }}>
            <MarketPopular />
          </ChangeOrderItem> */}
            {/* <ChangeOrderItem style={{ flex: '0.6 1' }}>
            <MarketUsefulArticles title={'Полезное'} />
          </ChangeOrderItem>
        </ChangeOrderWrapper> */}
          </MarketWrapper>
          <BottomMenuComponent />
        </BasePageLayout>
      )}
    </>
  )
})
