import { createContext } from 'react'

import { makeAutoObservable } from 'mobx'

import { CategoriesListItemDto } from '../../api/generated'

class CategoriesStorage {
  categories: CategoriesListItemDto[] = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setCategories(newCategories: CategoriesListItemDto[]): void {
    this.categories = newCategories
  }
}

export default createContext(new CategoriesStorage())
