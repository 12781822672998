import styled from 'styled-components/macro'

import { ProductListItem, RentListItem } from '../../../shared/api/generated'
import { NoProducts } from '../../../shared/ui-kit/actual/NoProducts'
import { devices } from '../../../shared/ui-kit/breakPoints'
import { PageLoader } from '../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumn,
  FlexRow,
  FlexRowWrap,
  StyledLinkPrimary,
} from '../../../shared/ui-kit/projectComponents'
import { PromoCard } from '../../market/ui/components/PromoCard'

const GoodsWrapper = styled(FlexRowWrap)<{ isContent?: boolean }>`
  gap: 16px 8px;
  align-items: stretch;
  @media screen and (${devices.tabletMin}) {
    align-self: ${props => (props.isContent ? 'flex-start' : 'center')};
    justify-content: flex-start;
  }
`

type GoodsListProps = {
  iconSrc?: string
  isLoading?: boolean
  items?: RentListItem[]
  messageNoProducts: string
  notYourList?: boolean
}
export const RentGoodsDesktopList = ({
  iconSrc,
  isLoading,
  items,
  messageNoProducts,
  notYourList,
}: GoodsListProps) => {
  return (
    <GoodsWrapper isContent={items?.length > 0}>
      {isLoading ? (
        <FlexRow style={{ width: '100%' }}>
          <PageLoader />
        </FlexRow>
      ) : (
        <>
          {items?.length ? (
            items.map((item, idx) => (
              <PromoCard
                brandImage={item?.sellerImageUrl || ''}
                iconImage={iconSrc}
                image={item?.productImagesUrls?.[0] || ''}
                key={idx}
                link={`/item/${item.productId}`}
                title={item.productName}
              />
            ))
          ) : (
            <FlexColumn>
              <NoProducts imgPath={'/images/interface/EmptyBag.svg'} message={messageNoProducts} />
              {!notYourList && (
                <StyledLinkPrimary style={{ whiteSpace: 'nowrap', width: 'auto' }} to={'/add'}>
                  Добавить объявление
                </StyledLinkPrimary>
              )}
            </FlexColumn>
          )}
        </>
      )}
    </GoodsWrapper>
  )
}
