import { toast } from 'react-custom-alert'
import { UseMutationOptions, useMutation } from 'react-query'

import { AxiosError } from 'axios'

import {
  ForgotPasswordCommand,
  ResetPasswordCommand,
  postApiAuthForgotPassword,
  postApiAuthResetPassword,
} from '../../shared/api/generated'

export function useAuthForgotPasswordMutation(
  options?: UseMutationOptions<unknown, unknown, ForgotPasswordCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ForgotPasswordCommand) => {
      return postApiAuthForgotPassword(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(
        e.response?.data?.message || 'Ошибка при восстановления пароля, попробуйте еще раз'
      )
    },
    onSuccess: () => {
      toast.success('Код для восстановления отправлен на ваш email')
    },
    ...options,
  })
}

export function useAuthResetPasswordMutation(
  options?: UseMutationOptions<unknown, unknown, ResetPasswordCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: ResetPasswordCommand) => {
      return postApiAuthResetPassword(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(
        e.response?.data?.message || 'Ошибка при восстановления пароля, попробуйте еще раз'
      )
    },
    onSuccess: () => {
      toast.success('Вы успешно обновили пароль')
    },
    ...options,
  })
}
