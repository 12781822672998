import React, { useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import { useCreateArticle } from '../../../../entities/articles/queries'
import { InputImageControlled } from '../../../../shared/ui-kit/actual/InputImageControlled'
import { TextArea } from '../../../../shared/ui-kit/actual/TextArea'
import { MiniModal } from '../../../../shared/ui-kit/actual/modals/MiniModal'
import {
  FileInputButton,
  FlexColumn,
  RelativeWrapper,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'

const FormWrapper = styled(FlexColumn)`
  width: 100%;
  padding: 24px;
`

type BlogDesktopHeaderProps = {
  articleModal?: boolean
  setArticleModal?: (viewModal: boolean) => void
}

export const NewArticleModal = ({ articleModal, setArticleModal }: BlogDesktopHeaderProps) => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const handleUrlChange = (url: string) => setImageUrl(url)
  const handleChangeImage = (file: Blob) => {
    setImageFile(file)
  }
  const { isLoading, isSuccess, mutate } = useCreateArticle()
  // TODO: в будущем проверить насколько тут нужны category и section пока рандомные стоят
  const createArticle = () => {
    mutate({
      CategoryId: 64,
      Content: content,
      Image: imageFile,
      SectionId: 6,
      Title: title,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setArticleModal(false)
      setTitle('')
      setContent('')
      setImageUrl('')
    }
  }, [isSuccess, setArticleModal])

  return (
    <MiniModal setViewModal={() => setArticleModal(false)} viewModal={articleModal}>
      <FormWrapper style={{ marginTop: 24 }}>
        <H2>Новая статья </H2>
        <br />
        <RelativeWrapper>
          <InputImageControlled
            defaultImageUrl={null}
            handleChange={handleChangeImage}
            handleUrlChange={handleUrlChange}
            parentImageUrl={imageUrl}
          />
          <FileInputButton>
            <InputImageControlled
              defaultImageNode={<img alt={''} src={'/images/buttons/Edit.svg'} />}
              handleChange={handleChangeImage}
              handleUrlChange={handleUrlChange}
              variant={'circle'}
            />
          </FileInputButton>
        </RelativeWrapper>
      </FormWrapper>
      <FormWrapper>
        <TextArea
          inputProps={{
            placeholder: 'Заголовок',
            rows: 1,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
          style={{ marginBottom: '8px', width: '100%' }}
          value={title}
          fullwidth
        />
        <br />
        <TextArea
          inputProps={{
            placeholder: 'Текст',
            rows: 10,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContent(e.target.value)}
          style={{ marginBottom: '8px', width: '100%' }}
          value={content}
          fullwidth
        />
        <br />
        <StyledButton
          disabled={!title || !content || isLoading || isSuccess}
          onClick={createArticle}
          type={'submit'}
          variant={'secondary'}
          fullwidth
        >
          {(isSuccess && 'Статья создана') || (isLoading && 'Статья создается') || 'Создать статью'}
        </StyledButton>
      </FormWrapper>
    </MiniModal>
  )
}
