import styled from 'styled-components/macro'

import { SectionListItemDto } from '../../../../shared/api/generated'
import { RadioInput } from '../../../../shared/ui-kit/actual/RadioInput'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'

interface BlogFilterCategoriesProps {
  handleSetOrder: (value: string) => void
  selectedSort: string
  setSelectedSort: (it: any) => void
  sortingCategories: SectionListItemDto[]
}

const CategoriesWrapper = styled.div`
  width: inherit;
  margin-bottom: 16px;
`

const FilterByCategories = ({
  handleSetOrder,
  selectedSort,
  sortingCategories,
}: BlogFilterCategoriesProps) => {
  return (
    <>
      <CategoriesWrapper>
        {sortingCategories.length > 0 ? (
          sortingCategories
            .slice(0, 5)
            .map((selector, idx) => (
              <RadioInput
                handleSetOrder={() => handleSetOrder(String(selector.id))}
                key={idx}
                selectedSort={selectedSort}
                selector={{ name: selector.name, value: String(selector.id) }}
              />
            ))
        ) : (
          <PageLoader />
        )}
      </CategoriesWrapper>
    </>
  )
}

export default FilterByCategories
