import { createPortal } from 'react-dom'

import { PageLoader } from '../../deprecated/PageLoader'
import { LoaderWrapper } from '../../projectComponents'

export const AbsoluteLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <>
      {isLoading &&
        createPortal(
          <LoaderWrapper>
            <PageLoader />
          </LoaderWrapper>,
          document.body
        )}
    </>
  )
}
