import { useContext, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { usePostApiUsersGetUserInfo } from '../entities/ownProfile/queries'
import { useGetUserProfileMutation } from '../entities/userProfile/queries'
import AddItemPage from '../pages/AddItemPage'
import { AdminAddCategoryPage } from '../pages/AdminAddCategoryPage'
import { AdminAddSectionPage } from '../pages/AdminAddSectionPage'
import { AdminAddSubCategoryPage } from '../pages/AdminAddSubCategoryPage'
import AdminArchiveProductsListPage from '../pages/AdminArchiveProductsListPage'
import AdminCategoriesPage from '../pages/AdminCategoriesPage'
import AdminClaimPage from '../pages/AdminClaimPage'
import AdminClaimsListPage from '../pages/AdminClaimsListPage'
import { AdminEditTagsPage } from '../pages/AdminEditTagsPage'
import { AdminOperationsPage } from '../pages/AdminOperationsPage'
import { AdminPage } from '../pages/AdminPage'
import AdminPassportPage from '../pages/AdminPassportPage'
import AdminPassportsListPage from '../pages/AdminPassportsListPage'
import AdminProductArchivePage from '../pages/AdminProductArchivePage'
import AdminProductPage from '../pages/AdminProductPage'
import AdminProductsListPage from '../pages/AdminProductsListPage'
import AdminReviewsPage from '../pages/AdminReviewsPage'
import { AdminSectionsPage } from '../pages/AdminSectionsPage'
import { AdminTagsPage } from '../pages/AdminTagsPage'
import { AdminTransactionsPage } from '../pages/AdminTransactionsPage'
import AdminUserPage from '../pages/AdminUserPage'
import { AdminUsersListPage } from '../pages/AdminUsersListPage'
import AdminWithdrawalPage from '../pages/AdminWithdrawalPage'
import AdminWithdrawalsListPage from '../pages/AdminWithdrawalsListPage'
import { ArchiveItemPage } from '../pages/ArchiveItemPage'
import ArticlePage from '../pages/ArticlePage/ArticlePage'
import AuthPage from '../pages/AuthPage'
import AuthPagePhone from '../pages/AuthPagePhone'
import AuthPageSMS from '../pages/AuthPageSMS'
import BlogFilterPage from '../pages/BlogFilterPage'
import BlogPage from '../pages/BlogPage'
import CategoryPage from '../pages/CategoryPage/CategoryPage'
import { ChatPage } from '../pages/ChatPage'
import { EditItemPage } from '../pages/EditItemPage'
import { MarketItemPage } from '../pages/MarketItemPage'
import { MarketPage } from '../pages/MarketPage/MarketPage'
import { MessagePage } from '../pages/MessagePage'
import { MyRequestsItemPage } from '../pages/MyRequestsItemPage'
import { MyRequestsItemProductPage } from '../pages/MyRequestsItemProductPage'
import { MyRequestsPage } from '../pages/MyRequestsPage'
import { OrdersPage } from '../pages/OrdersPage'
import PasswordForgotPage from '../pages/PasswordForgotPage'
import PasswordResetPage from '../pages/PasswordResetPage'
import ProfilePage from '../pages/ProfilePage/ProfilePage'
import ProfilePageNotAuth from '../pages/ProfilePageNotAuth/ProfilePageNotAuth'
import ProfilePersonalDataPage from '../pages/ProfilePersonalDataPage'
import ProfileRentPage from '../pages/ProfileRentPage'
import RegistrationPage from '../pages/RegisterPage'
import { RequestsItemPage } from '../pages/RequestsPage/RequestsItemPage'
import { RequestsPage } from '../pages/RequestsPage/RequestsPage'
import SearchPage from '../pages/SearchPage'
import SectionChildPage from '../pages/SectionPage/SectionChildPage'
import SectionPage from '../pages/SectionPage/SectionPage'
import SectionsPage from '../pages/SectionsPage'
import { TestPage } from '../pages/TestPage'
import UserPage from '../pages/UserPage/UserPage'
import UserReviewPage from '../pages/UserReviewPage'
import DeliveryReceivePage from '../pages/deliveryPage/DeliveryReceivePage'
import DeliverySendPage from '../pages/deliveryPage/DeliverySendPage'
import ProfileStorage from '../shared/storage/ProfileStorage/ProfileStorage'
import UserStorage from '../shared/storage/UserStorage/UserStorage'

function App() {
  const store = useContext(UserStorage)
  const ProfileStore = useContext(ProfileStorage)

  const { mutate } = usePostApiUsersGetUserInfo({
    onError: error => {
      console.log('profile error', error)
    },
    onSuccess: data => {
      ProfileStore.setUserInfo(data)
    },
  })

  const { mutate: addUserProfile } = useGetUserProfileMutation({
    onError: error => {
      console.log('profile error', error)
    },
    onSuccess: data => {
      ProfileStore.setProfile(data)
    },
  })

  useEffect(() => {
    store?.userId && mutate({ userId: store?.userId })
    store?.userId && addUserProfile({ userId: store?.userId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.userId])

  const isAdmin = store && store?.user?.UserRole === 'Admin'

  // TODO: нужно при завершении регистрации показывать OnBoardPage

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route Component={SectionsPage} path={'/sections'} />
          <Route Component={SectionPage} path={'/sections/:sectionId'} />
          <Route Component={SectionChildPage} path={'/sections/:sectionId/:parentCategoryId'} />
          <Route Component={CategoryPage} path={'/category/:categoryId'} />
          <Route Component={CategoryPage} path={'/sections/:sectionId/sub/:categoryId'} />
          <Route
            Component={CategoryPage}
            path={'/sections/:sectionId/:parentCategoryId/:categoryId'}
          />
          <Route Component={ArticlePage} path={'/article/:articleId'} />
          <Route Component={MarketPage} path={'/'} />
          <Route Component={MarketPage} path={'/users'} />
          <Route Component={BlogPage} path={'/blog'} />
          <Route Component={BlogPage} path={'/article'} />
          <Route Component={SearchPage} path={'/search'} />
          <Route
            Component={() => {
              window.location.href = 'https://landing.allsharing.su'

              return null
            }}
            path={'/landing'}
          />
          {!store.accessToken ? (
            <>
              {/* <Route Component={TestPage} path={'/test'} /> */}
              <Route Component={AuthPage} path={'/login'} />
              <Route Component={AuthPagePhone} path={'/login-phone'} />
              <Route Component={AuthPageSMS} path={'/login-phone/:phone'} />
              <Route Component={RegistrationPage} path={'/register'} />
              <Route Component={PasswordResetPage} path={'/reset'} />
              <Route Component={PasswordForgotPage} path={'/password-forgot'} />
              <Route element={<Navigate to={'/login'} replace />} path={'/*'} />
            </>
          ) : (
            <>
              <Route Component={ProfilePage} path={'/profile'} />
              <Route Component={DeliveryReceivePage} path={'/delivery/receive/:rentId'} />
              <Route Component={DeliverySendPage} path={'/delivery/send/:rentId'} />
              <Route Component={MessagePage} path={'/messages'} />
              <Route Component={ChatPage} path={'/messages/:rentId?'} />
              <Route Component={MarketItemPage} path={'/item/:itemId?'} />
              <Route Component={ArchiveItemPage} path={'/archive-item/:itemId?'} />
              <Route Component={EditItemPage} path={'/item/:itemId?/edit'} />
              <Route Component={OrdersPage} path={'/orders'} />
              <Route Component={UserPage} path={'/users/:userId?'} />
              <Route Component={UserReviewPage} path={'/users/:userId/review'} />
              <Route Component={BlogFilterPage} path={'/blog/filter'} />
              {/* <Route Component={WalletPage} path={'/wallet'} /> */}
              <Route Component={ProfileRentPage} path={'/profile/rent'} />
              <Route Component={ProfilePersonalDataPage} path={'/profile/personal-data'} />
              <Route Component={AddItemPage} path={'/add'} />
              <Route element={<Navigate to={'/'} replace />} path={'/*'} />
            </>
          )}
          {store.accessToken && isAdmin && (
            <>
              <Route Component={AdminPage} path={'/admin'} />
              <Route Component={AdminAddSectionPage} path={'/admin/add-section'} />
              <Route Component={AdminTagsPage} path={'/admin/tags'} />
              <Route Component={AdminSectionsPage} path={'/admin/sections'} />
              <Route Component={AdminCategoriesPage} path={'/admin/categories/:sectionId'} />
              <Route
                Component={AdminCategoriesPage}
                path={'/admin/categories/:sectionId/:parentCategoryId'}
              />
              <Route
                Component={AdminArchiveProductsListPage}
                path={'/admin/archive-products/:categoryId'}
              />
              <Route
                Component={AdminProductsListPage}
                path={'/admin/categories/:sectionId/sub/:categoryId'}
              />
              <Route
                Component={AdminProductsListPage}
                path={'/admin/categories/:sectionId/:parentCategoryId/sub/:categoryId'}
              />
              <Route Component={AdminProductPage} path={'/admin/product/:productId'} />
              <Route
                Component={AdminProductArchivePage}
                path={'/admin/archive-product/:productId'}
              />
              <Route Component={AdminUsersListPage} path={'/admin/users'} />
              <Route Component={AdminClaimsListPage} path={'/admin/claims'} />
              <Route Component={AdminPassportsListPage} path={'/admin/passports'} />
              <Route Component={AdminWithdrawalsListPage} path={'/admin/withdrawals'} />
              <Route
                Component={AdminWithdrawalPage}
                path={'/admin/withdrawals/:withdrawalRequestId'}
              />
              <Route Component={AdminClaimPage} path={'/admin/claims/:claimId'} />
              <Route Component={AdminUserPage} path={'/admin/users/:userId'} />
              <Route Component={AdminPassportPage} path={'/admin/passports/:userId'} />
              <Route Component={AdminAddCategoryPage} path={'/admin/add-categories/:sectionId'} />
              <Route Component={AdminEditTagsPage} path={'/admin/edit-tags/:sectionId'} />
              <Route Component={AdminTransactionsPage} path={'/admin/transactions'} />
              <Route Component={AdminOperationsPage} path={'/admin/operations'} />
              <Route Component={AdminReviewsPage} path={'/admin/reviews'} />
              <Route
                Component={AdminAddSubCategoryPage}
                path={'/admin/add-categories/:sectionId/:categoryId'}
              />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default observer(App)
