import { useContext } from 'react'
import { toast } from 'react-custom-alert'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'
import jwtDecode from 'jwt-decode'

import { useSignUpPhoneEmailMutation } from '../../../entities/register/queries'
import { SignUpCommand } from '../../../shared/api/generated'
import UserStorage, { UserFromJWT } from '../../../shared/storage/UserStorage/UserStorage'

export function usePhoneEmailSignUpForm() {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<{ passwordConfirm: string } & SignUpCommand>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })
  const navigate = useNavigate()
  const store = useContext(UserStorage)

  const signUpMutation = useSignUpPhoneEmailMutation({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      setError('root.serverError', {
        message: e.response?.data?.message || 'Ошибка регистрации',
        type: 'onChange',
      })

      toast.error(e.response?.data?.message || 'Ошибка регистрации')
    },
    onSuccess: data => {
      store.setTokens({ accessToken: data?.accessToken, refreshToken: data?.refreshToken })
      const user = jwtDecode(data?.accessToken) as UserFromJWT

      toast.success('Поздравляем, вы зарегистрировались!')

      store.setUser(user)
      store.setIsAuth(true)

      navigate('/')
    },
  })

  const registration = async (data: SignUpCommand) => {
    signUpMutation.mutate(data)
  }

  return {
    clearErrors,
    errors,
    getValues,
    handleSubmit: handleSubmit(registration),
    isValid,
    register,
    setError,
    setFocus,
    setValue,
    signUpMutation,
    trigger,
    watch,
  }
}
