import { useEffect } from 'react'

import { UpdateProductPriceCommand } from '../../../../shared/api/generated'
import { symbols } from '../../../../shared/constants/symbols'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import {
  DoubleColumn,
  FlexRowBetween,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H5 } from '../../../../shared/ui-kit/titles'
import { useItemPricesEditForm } from '../../model/use-item-prices-edit-form'

interface Props extends UpdateProductPriceCommand {
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}
export const ItemPricesEdit = ({
  dayPrice,
  id,
  monthPrice,
  pledge,
  setViewModal,
  threeDaysPrice,
  viewModal,
  weekPrice,
}: Props) => {
  const {
    clearErrors,
    createProduct,
    errors,
    getValues,
    handleSubmit,
    isLoading,
    isSuccess,
    isValid,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useItemPricesEditForm()

  useEffect(() => {
    id && setValue('id', id)
  }, [id, setValue])

  const closeModal = () => {
    setViewModal(false)
    setTimeout(() => {
      setValue('dayPrice', dayPrice)
      setValue('monthPrice', monthPrice)
      setValue('pledge', pledge)
      setValue('threeDaysPrice', threeDaysPrice)
      setValue('weekPrice', weekPrice)
    }, 1000)
  }

  useEffect(() => {
    if (isSuccess) {
      setViewModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <BaseModal setViewModal={closeModal} viewModal={viewModal}>
      <AbsoluteLoader isLoading={isLoading} />
      <H5>Залог</H5>
      <TextField
        inputProps={{
          placeholder: 'Залог',
          type: 'number',
          ...register('pledge', { required: 'Залог необходимо указать' }),
          defaultValue: pledge,
        }}
        error={errors.pledge?.message?.toString()}
        label={'Залог'}
        right={symbols.ruble}
        size={'md'}
        fullwidth
      />
      <H5>Цены за интервалы</H5>
      <DoubleColumn>
        <StyledButton variant={'neutral'} disabled fullwidth>
          1 день
        </StyledButton>
        <TextField
          inputProps={{
            ...register('dayPrice'),
            defaultValue: dayPrice,
            min: 0,
            placeholder: 'Цена за день',
            type: 'number',
          }}
          label={'Цена'}
          right={symbols.ruble}
          size={'md'}
          fullwidth
        />
      </DoubleColumn>
      <DoubleColumn>
        <StyledButton variant={'neutral'} disabled fullwidth>
          3 дня
        </StyledButton>
        <TextField
          inputProps={{
            ...register('threeDaysPrice'),
            defaultValue: threeDaysPrice,
            min: 0,
            placeholder: 'Цена за 3 дня',
            type: 'number',
          }}
          label={'Цена'}
          right={symbols.ruble}
          size={'md'}
          fullwidth
        />
      </DoubleColumn>
      <DoubleColumn>
        <StyledButton variant={'neutral'} disabled fullwidth>
          7 дней
        </StyledButton>
        <TextField
          inputProps={{
            ...register('weekPrice'),
            defaultValue: weekPrice,
            min: 0,
            placeholder: 'Цена за 7 дней',
            type: 'number',
          }}
          label={'Цена'}
          right={symbols.ruble}
          size={'md'}
          fullwidth
        />
      </DoubleColumn>
      <DoubleColumn>
        <StyledButton variant={'neutral'} disabled fullwidth>
          30 дней
        </StyledButton>
        <TextField
          inputProps={{
            ...register('monthPrice'),
            defaultValue: monthPrice,
            min: 0,
            placeholder: 'Цена за 30 дней',
            type: 'number',
          }}
          label={'Цена'}
          right={symbols.ruble}
          size={'md'}
          fullwidth
        />
      </DoubleColumn>
      <br />
      <FlexRowBetween style={{ gap: '12px' }}>
        <StyledButton onClick={closeModal} type={'button'} variant={'outline'} fullwidth>
          Отменить
        </StyledButton>
        <StyledButton onClick={handleSubmit} fullwidth>
          Подтвердить
        </StyledButton>
      </FlexRowBetween>
    </BaseModal>
  )
}
