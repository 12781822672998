import { Link } from 'react-router-dom'

import { StyledTextAuthFooter } from '../../../../shared/ui-kit/projectComponents'

export const RegFooter = () => {
  return (
    <footer>
      <StyledTextAuthFooter>
        Есть аккаунт? <Link to={'/login'}>Войти</Link>
      </StyledTextAuthFooter>
    </footer>
  )
}
