import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetApiChatsGetChatMessagesParams,
  GetChatInfoQuery,
  GetChatInfoResponse,
  PostApiChatsSendChatMessageBody,
  getApiChatsGetChatMessages,
  postApiChatsGetChatInfo,
  postApiChatsSendChatMessage,
} from '../../shared/api/generated'

export function usePostChatsInfoQuery(
  params: GetChatInfoQuery,
  options?: UseQueryOptions<unknown, unknown, GetChatInfoResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки сообщений')
    },
    queryFn: () => postApiChatsGetChatInfo(params),
    queryKey: ['get_chats_list', ...Object.values(params).map(String)],
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetChatMessage(params: GetApiChatsGetChatMessagesParams) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки сообщений')
    },
    queryFn: () => getApiChatsGetChatMessages(params),
    queryKey: ['get_messages', ...Object.values(params).map(String)],
    refetchOnWindowFocus: false,
  })
}

export function usePostNewMessage(options?: UseMutationOptions) {
  return useMutation({
    mutationFn: (data: PostApiChatsSendChatMessageBody) => {
      return postApiChatsSendChatMessage(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки сообщения')
    },
  })
}
