import { observer } from 'mobx-react-lite'

import ProfilePersonalDataBody from '../features/profilePersonalData/ui/body/ProfilePersonalDataBody'
import { ProfilePersonalDataHeader } from '../features/profilePersonalData/ui/head/ProfilePersonalDataHeader'
import { ProfileRentLayout } from '../shared/ui-kit/actual/layouts/ProfileRentLayout'

const ProfilePersonalDataPage = () => {
  return (
    <ProfileRentLayout body={<ProfilePersonalDataBody />} head={<ProfilePersonalDataHeader />} />
  )
}

export default observer(ProfilePersonalDataPage)
