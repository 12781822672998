import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { useGetOperationsListQuery } from '../entities/transactions/queries'
import { OperationListItem } from '../shared/api/generated'
import { operationsStatuses, operationsTypes } from '../shared/constants/transactions'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  FlexColumnBetween,
  FlexRowBetween,
  StyledButton,
  Table,
  Th,
} from '../shared/ui-kit/projectComponents'
import { Head } from '../shared/ui-kit/titles'
import { shortString, truncateString } from '../shared/utils/truncate'

const OperationsList = ({
  setUserId,
  transactions,
}: {
  setUserId: React.Dispatch<React.SetStateAction<string>>
  transactions: OperationListItem[]
}) => (
  <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}>
    {' '}
    <Table>
      <thead>
        <tr>
          <Th>Дата</Th>
          <Th style={{ maxWidth: '200px' }}>Пользователь</Th>
          <Th>Рубли</Th>
          <Th>Шери</Th>
          <Th>Тип</Th>
          <Th>Статус</Th>
          <Th>Комментарий</Th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(t => (
          <tr key={t.operationId}>
            <td>{format(new Date(t.createdOn), 'dd MMMM HH:mm', { locale: ru })}</td>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '100px' }}
                to={`/users/${t.accountOwner.id}`}
              >
                {shortString(t.accountOwner.userName, 18)}
              </Link>{' '}
              <Link
                style={{ display: 'inline-block', minWidth: '50px' }}
                to={`/admin/users/${t.accountOwner.id}`}
              >
                {truncateString(t.accountOwner.id, 8)}
              </Link>{' '}
              <button onClick={() => setUserId(t.accountOwner.id)}>только этот пользователь</button>
            </td>
            <td>{t.balanceDiff}</td>
            <td>{t.bonusBalanceDiff}</td>
            <td>{operationsTypes[t.changeType] || t.changeType}</td>
            <td>{operationsStatuses[t.status] || t.status}</td>
            <td>{t.comment}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </FlexRowBetween>
)

export const AdminOperationsPage = () => {
  const [userId, setUserId] = useState<null | string>(null)

  const [itemsPerPage, setItemsPerPage] = useState(24)
  const [page, setPage] = useState(0)
  const { data, isLoading } = useGetOperationsListQuery({
    skip: page * itemsPerPage,
    take: itemsPerPage,
    userId,
  })
  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [data, itemsPerPage]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  const resetTerms = () => {
    setUserId(null)
  }

  return (
    <FlexColumnBetween>
      <AbsoluteLoader isLoading={isLoading} />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      <Head>Операции</Head>

      <FlexRowBetween
        style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}
      >
        <p style={{ alignSelf: 'flex-start', fontSize: 15, marginLeft: '15px' }}>
          Всего операций: {data?.totalCount}
        </p>
        <StyledButton onClick={resetTerms} size={'sm'}>
          Сбросить фильтр
        </StyledButton>
      </FlexRowBetween>

      {data && data.items?.length && (
        <OperationsList setUserId={setUserId} transactions={data.items} />
      )}
      {data && <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />}
    </FlexColumnBetween>
  )
}
