import { useState } from 'react'

import styled from 'styled-components/macro'

import { useGetPaymentMethodsQuery } from '../../../entities/payments/queries'
import { useModal } from '../../../shared/hooks/useModal'
import { AbsoluteLoader } from '../../../shared/ui-kit/actual/components/AbsoluteLoader'
import {
  AddBankAccountModal,
  BankAccountsList,
  DeleteBankAccountModal,
  NoContent,
} from '../../../shared/ui-kit/actual/components/PayBankAccountsList'
import {
  FlexColumnBetween,
  FlexRowGap,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { useProfileInfo } from '../../profile/model/use-profile-info'

const Wrapper = styled(FlexColumnBetween)`
  width: 100%;
  align-items: flex-start;
  margin: 8px 0 12px;
  column-gap: 70px;
`

export const PersonalDataBusinessPay = () => {
  const { userId } = useProfileInfo()

  const { data, isLoading } = useGetPaymentMethodsQuery({ userId })
  const { setViewModal: setViewAddModal, viewModal: viewAddModal } = useModal()
  const { setViewModal: setViewDeleteModal, viewModal: viewDeleteModal } = useModal()
  const handleOpenAddModal = () => setViewAddModal(true)
  const handleOpenDeleteModal = () => setViewDeleteModal(true)
  const [selectedId, setSelectedId] = useState('')
  const [selectedCardNumber, setSelectedCardNumber] = useState('')

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <Wrapper>
        {data?.bankAccounts?.length ? (
          <>
            <BankAccountsList
              data={data}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              setSelectedNumber={setSelectedCardNumber}
            />
            <FlexRowGap style={{ paddingTop: '16px' }}>
              <StyledButton
                disabled={!selectedId}
                onClick={handleOpenDeleteModal}
                variant={'secondary'}
                fullwidth
              >
                Удалить
              </StyledButton>
              <StyledButton onClick={handleOpenAddModal} fullwidth>
                Добавить
              </StyledButton>
            </FlexRowGap>
          </>
        ) : (
          <>
            <NoContent />
            <StyledButton onClick={handleOpenAddModal} fullwidth>
              Добавить
            </StyledButton>
          </>
        )}
      </Wrapper>
      <AddBankAccountModal setViewModal={setViewAddModal} viewModal={viewAddModal} />
      <DeleteBankAccountModal
        selectedCardId={selectedId}
        selectedCardNumber={selectedCardNumber}
        setViewModal={setViewDeleteModal}
        viewModal={viewDeleteModal}
      />
    </>
  )
}
