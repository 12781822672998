import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useUsersGetUserInfoQuery } from '../../../../entities/ownProfile/queries'
import { colors } from '../../../../shared/constants/colors'
import { primaryStarSVG } from '../../../../shared/constants/images'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { StyledBtnSmall, StyledButton } from '../../../../shared/ui-kit/actual/StyledButton'
import { ButtonColumn } from '../../../../shared/ui-kit/actual/components/buttonColumn'
import {
  AvatarLarge,
  BoldNumber,
  BtnRowWrapper,
  BtnWrapper,
  FlexColumn,
  OpacityText,
  RatingWrapper,
  RelativeWrapper,
} from '../../../../shared/ui-kit/projectComponents'
import getAvgRating from '../../../../shared/utils/getAvgRating'

const StyledHeader = styled.h3`
  max-width: 272px;
  color: ${colors.black};
  font-size: 21px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin: 0;
`

const SubHeader = styled(StyledHeader)`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`

const UserNameWrapper = styled(FlexColumn)`
  gap: 8px;
  margin-top: 32px;
  color: #fff;
  margin-bottom: 20px;
`

const SubscribersWrapper = styled.div<{ $activeTab: number }>`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(2, 20px);
  column-gap: 50px;
  justify-items: center;
  transition: 0.2s ease-out;
  height: ${({ $activeTab }) => ($activeTab ? '40px' : '0')};
  transform: ${({ $activeTab }) => ($activeTab ? 'none' : 'scaleY(0%)')};
`

type Props = {
  isProfilePage?: boolean
  setBalanceModal?: (viewModal: boolean) => void
  setPayoutModal?: (viewModal: boolean) => void
  setRatingModal?: (viewModal: boolean) => void
  setViewModal?: (viewModal: boolean) => void
}

export const ProfileDesktopHeader = ({
  isProfilePage,
  setBalanceModal,
  setPayoutModal,
  setRatingModal,
  setViewModal,
}: Props) => {
  const ProfileStore = useContext(ProfileStorage)
  const profile = ProfileStore.getProfile()
  const user = ProfileStore.getUserInfo()
  const store = useContext(UserStorage)

  const sellerRating = profile?.sellerRating || 0
  const buyerRating = profile?.buyerRating || 0
  const avgRating = getAvgRating(buyerRating, sellerRating)
  const navigate = useNavigate()
  const { data } = useUsersGetUserInfoQuery(store?.userId)

  if (!isProfilePage) {
    return (
      <FlexColumn style={{ minWidth: '290px' }}>
        <RelativeWrapper>
          <AvatarLarge src={user?.profileImageUrl || '/images/bottom-menu/profile-light.svg'} />

          <RatingWrapper onClick={() => setViewModal(true)} roleType={user.accountType}>
            <img alt={''} src={'/images/interface/whiteStar.svg'} />
            <span>{avgRating?.toFixed(1)}</span>
          </RatingWrapper>
        </RelativeWrapper>

        <UserNameWrapper>
          <StyledHeader>{data?.fullName}</StyledHeader>
          <SubHeader>{data?.aboutItself}</SubHeader>
        </UserNameWrapper>
        <BtnWrapper>
          <BtnRowWrapper>
            <StyledButton onClick={() => navigate('/add')} fullwidth>
              Сдать в аренду
            </StyledButton>
            {/* <StyledBtnSmall>
              <img alt={''} src={'/images/buttons/Calendar.svg'} />
            </StyledBtnSmall> */}
          </BtnRowWrapper>
          {/* <BtnRowWrapper>
            <StyledButton variant={'secondary'} fullwidth>
              Запросы
            </StyledButton>
          </BtnRowWrapper> */}
        </BtnWrapper>
      </FlexColumn>
    )
  }

  return (
    <FlexColumn style={{ minWidth: '290px' }}>
      <RelativeWrapper>
        <AvatarLarge src={data?.profileImageUrl || '/images/bottom-menu/profile-light.svg'} />

        <RatingWrapper onClick={() => setViewModal(true)} roleType={user.accountType}>
          <img alt={''} src={'/images/interface/whiteStar.svg'} />
          <span>{avgRating?.toFixed(1)}</span>
        </RatingWrapper>
      </RelativeWrapper>

      <UserNameWrapper>
        <StyledHeader>{data?.fullName}</StyledHeader>
        <SubHeader>{data?.aboutItself}</SubHeader>
      </UserNameWrapper>
      <SubscribersWrapper $activeTab={1}>
        <BoldNumber>{profile.subscribersCount}</BoldNumber>
        <BoldNumber>{profile.subscriptionsCount}</BoldNumber>
        <OpacityText>Подписчиков</OpacityText>
        <OpacityText>Подписок</OpacityText>
      </SubscribersWrapper>
      <BtnWrapper>
        <BtnRowWrapper>
          <FlexColumn onClick={() => setRatingModal(true)} style={{ width: '100%' }}>
            <ButtonColumn
              top={
                <>
                  {' '}
                  <img alt={''} src={primaryStarSVG} />
                  &nbsp;{buyerRating?.toFixed(1)}
                </>
              }
              bottom={<>Арендатор</>}
            />
          </FlexColumn>
          <FlexColumn onClick={() => setRatingModal(true)} style={{ width: '100%' }}>
            <ButtonColumn
              top={
                <>
                  <img alt={''} src={primaryStarSVG} />
                  &nbsp;{sellerRating?.toFixed(1)}
                </>
              }
              bottom={<>Арендодатель</>}
            />
          </FlexColumn>
        </BtnRowWrapper>
        <BtnRowWrapper>
          <ButtonColumn
            bottom={<>Баланс</>}
            onClick={() => setBalanceModal(true)}
            top={<>{profile?.balanceUser?.accountBalance} ₽</>}
          />
          <ButtonColumn bottom={<>Бонусы</>} top={<>{profile?.balanceUser?.bonusBalance} SHC</>} />
          <StyledBtnSmall
            onClick={() => setPayoutModal(true)}
            style={{ flexGrow: 1, height: '55px' }}
            variant={'secondary'}
          >
            <img alt={''} src={'/images/interface/Wallet.svg'} />
          </StyledBtnSmall>
        </BtnRowWrapper>
        {/* <BtnRowWrapper>
          <StyledButton onClick={() => navigate('/profile/rent')} fullwidth>
            Сдать в аренду
          </StyledButton>
          <StyledBtnSmall>
            <img alt={''} src={'/images/buttons/Calendar.svg'} />
          </StyledBtnSmall>
          <StyledBtnSmall variant={'secondary'}>
            <img alt={''} src={'/images/buttons/exclamation.svg'} />
          </StyledBtnSmall>
        </BtnRowWrapper>
        <BtnRowWrapper>
          <StyledButton variant={'secondary'} fullwidth>
            Запросы аренды
          </StyledButton>
          <StyledBtnSmall onClick={() => navigate('/')} variant={'secondary'}>
            <img alt={''} src={'/images/buttons/HandbagSimple.svg'} />
          </StyledBtnSmall>
        </BtnRowWrapper> */}
      </BtnWrapper>
    </FlexColumn>
  )
}
