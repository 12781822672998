import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import Breadcrumbs from '../features/market/ui/sections/BreadCrumbs'
import useSectionsLoader from '../shared/hooks/useSectionsLoader'
import { BackArrowWithTitleLink } from '../shared/ui-kit/actual/BackArrowWithTitleLink'
import { BottomMenuComponent } from '../shared/ui-kit/actual/BottomMenuComponent'
import { SectionItem } from '../shared/ui-kit/actual/SectionItem'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../shared/ui-kit/actual/layouts/BasePageLayout'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import { BaseWrapper, FlexColumn, SectionsPageWrapper } from '../shared/ui-kit/projectComponents'

const SectionsPage = observer(() => {
  const { isLoading, sections } = useSectionsLoader()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <BasePageLayout>
      <MainHeader />
      <BackArrowWithTitleLink />
      <BaseWrapper>
        <Breadcrumbs />
        <SectionsPageWrapper>
          {!isLoading ? (
            sections.map(section => (
              <SectionItem
                img={section.imageUrl}
                key={section.id}
                productCount={section.productsCount}
                title={section.name}
                to={`/sections/${section.id}`}
              />
            ))
          ) : (
            <FlexColumn style={{ width: '100vw' }}>
              <PageLoader />
            </FlexColumn>
          )}
        </SectionsPageWrapper>
      </BaseWrapper>
      <BottomMenuComponent />
    </BasePageLayout>
  )
})

export default SectionsPage
