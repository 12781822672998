import { toast } from 'react-custom-alert'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetCategoriesListQuery,
  GetCategoriesListResponse,
  GetCategoryInfoQuery,
  GetCategoryInfoResponse,
  postApiCategoriesGetCategoriesList,
  postApiCategoriesGetCategoryInfo,
} from '../../shared/api/generated'

export function useGetCategoriesListQuery(
  params: GetCategoriesListQuery,
  options?: UseQueryOptions<unknown, unknown, GetCategoriesListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки категорий')
    },
    // onSettled: data => {
    //   console.log(data.items.map(s => s.categoryName).join('\n'))
    // },
    queryFn: () => postApiCategoriesGetCategoriesList(params),
    queryKey: ['get_categories', ...Object.values(params).map(String)],
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetSubCategoriesListMutate(
  params: GetCategoriesListQuery,

  options?: UseMutationOptions<GetCategoriesListResponse, unknown, unknown, unknown>
) {
  return useMutation({
    mutationFn: () => {
      return postApiCategoriesGetCategoriesList(params)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки подкатегорий')
    },
    // onSettled: data => {
    //   console.log(data.items.map(s => s.categoryName).join('\n'))
    // },
    ...options,
  })
}

export function useGetCategoryInfo(
  params: GetCategoryInfoQuery,
  options?: UseMutationOptions<GetCategoryInfoResponse, unknown, unknown, unknown>
) {
  return useMutation({
    mutationFn: () => {
      return postApiCategoriesGetCategoryInfo(params)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка загрузки категории')
    },
    ...options,
  })
}
