import { useEffect, useState } from 'react'

import { AxiosError, AxiosRequestConfig } from 'axios'
type Props<T> = {
  getApi: (params?: unknown, options?: AxiosRequestConfig<any>) => Promise<T>
  termName: string
  value: string
}
export const useDebounceSearch = <T>({ getApi, termName, value }: Props<T>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [productsList, setProductsList] = useState<T>()

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const getSearchResult = (value: string) => {
      setIsLoading(true)
      getApi({ Take: 100, [termName]: value }, { signal })
        .then(data => setProductsList(data))
        .catch(error => {
          const e = error as AxiosError<{ message: string }>

          console.log(e.response?.data?.message || 'Слишком долгий поиск')
        })
        .finally(() => setIsLoading(false))
    }

    const timeout = setTimeout(() => {
      if (value.length > 1) {
        getSearchResult(value)
      }
    }, 500)

    return () => {
      clearTimeout(timeout)
      controller.abort()
      if (value.length > 1) {
        setProductsList(null)
      }
    }
  }, [getApi, termName, value])

  return { isLoading, productsList }
}
