import React from 'react'

import { StyledSwitch } from '../projectComponents'

interface SwitchComponentProps {
  checked: boolean
  onChange: () => void
}

export const SwitchComponent: React.FC<SwitchComponentProps> = ({ checked, onChange }) => {
  return (
    <StyledSwitch>
      <input checked={checked} onChange={onChange} type={'checkbox'} />
      <span className={'slider'}></span>
    </StyledSwitch>
  )
}
