import styled from 'styled-components/macro'

import { DropDownMini } from '../../../../features/profilePersonalData/ui/DropDownMini'
import { UserAddressDto } from '../../../api/generated'
import { colors } from '../../../constants/colors'
import { FlexRowBetween, MediumText } from '../../projectComponents'
import { CheckBox } from '../checkbox'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const ShortAddressTitle = styled.h5`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.3px;
  color: ${colors.black};
`
const FullAddressDescription = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 23.36px;
  color: ${colors.grey};
`

const MainAddress = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 23.36px;
  color: ${colors.primary};
`

interface Props {
  address: UserAddressDto
  isLoading?: boolean
  isSelected?: boolean
  setSelectedAddress: (id: any) => void
}

export const AddressRow = ({ address, isLoading, isSelected, setSelectedAddress }: Props) => {
  const handleChecked = addressId => {
    setSelectedAddress(addressId)
  }

  return (
    <FlexRowBetween>
      <Container>
        <CheckBox
          checked={isSelected}
          setChecked={() => (!isLoading && !isSelected ? handleChecked(address?.id) : null)}
        />
        <div>
          <ShortAddressTitle>{address?.name}</ShortAddressTitle>
          <FullAddressDescription>
            <span>{address?.address}</span>
          </FullAddressDescription>
          <MainAddress>{address?.postcode}</MainAddress>
          <hr />
        </div>
      </Container>
      <DropDownMini addressId={address.id} />
    </FlexRowBetween>
  )
}
