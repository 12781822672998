import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-custom-alert'
import { useSearchParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { useCreateRentMutation, useGetRentPriceMutation } from '../../../../entities/rent'
import { GetProductInfoResponse, RentProductDeliveryType } from '../../../../shared/api/generated'
import { useQuerySteps } from '../../../../shared/hooks/useQuerySteps'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { StepsRow } from '../../../../shared/ui-kit/actual/StepsRow'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import { FlexColumn } from '../../../../shared/ui-kit/projectComponents'
import { Finish } from './Finish'
import FirstStep from './FirstStep'
import FourthStep from './FourthStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import Unsuccess from './Unsuccess'

type Props = {
  openMenu: boolean
  productInfo: GetProductInfoResponse
  setViewModal: (viewModal: boolean) => void
}

const Order = ({ openMenu, productInfo, setViewModal }: Props) => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const { setStep, step } = useQuerySteps()
  const { data, isLoading, isSuccess: isCreateSuccess, mutate } = useCreateRentMutation()
  const { userId } = useContext(UserStorage)
  const [deliveryAddressId, setDeliveryAddressId] = useState<number>()
  const [startDateString, setStartDateString] = useState('')
  const [endDateString, setEndDateString] = useState('')
  const [deliveryType, setDeliveryType] = useState<RentProductDeliveryType>()

  const [searchParams, setSearchParams] = useSearchParams()

  const deliveryTypeQuery = searchParams.get('deliveryType') as RentProductDeliveryType
  const startDateStringQuery = searchParams.get('startDateString')
  const endDateStringQuery = searchParams.get('endDateString')
  const deliveryAddressIdQuery = searchParams.get('deliveryAddressId')

  const handleCreateRent = () => {
    // пользователь итак не увидит эту модалку, если это не его товар, эту проверку можно убрать
    if (userId === productInfo?.owner?.id) {
      toast.error('Нельзя арендовать свой товар')

      return
    }

    mutate({
      deliveryType: deliveryTypeQuery,
      periodFrom: startDateStringQuery,
      periodTo: endDateStringQuery,
      productId: productInfo.id,
      userAddressId:
        deliveryTypeQuery === 'pickup'
          ? productInfo?.pickupAddress?.id
          : Number(deliveryAddressIdQuery),
    })
  }

  const {
    data: price,
    isLoading: isPriceLoading,
    isSuccess: isPriceLoaded,
    mutate: getRentPrice,
    reset,
  } = useGetRentPriceMutation()

  const handleCloseModal = () => {
    setViewModal(false)
    setTimeout(() => {
      setSearchParams({})
    }, 1000)
  }

  return (
    <BaseModal setViewModal={handleCloseModal} viewModal={openMenu}>
      <StepsRow activeStep={step} totalSteps={4} />
      <FlexColumn style={{ height: '100%', justifyContent: 'space-between', width: '100%' }}>
        {step === 0 && (
          <FirstStep
            currentStage={step}
            endDateString={endDateString}
            getProductInfoQuery={productInfo}
            getRentPrice={getRentPrice}
            isLoading={isLoading}
            isPriceLoaded={isPriceLoaded}
            isPriceLoading={isPriceLoading}
            price={price?.price}
            reset={reset}
            setCurrentStage={setStep}
            setEndDateString={setEndDateString}
            setStartDateString={setStartDateString}
            startDateString={startDateString}
          />
        )}
        {step === 1 && (
          <SecondStep
            activeTab={activeTab}
            currentStage={step}
            deliveryAddressId={deliveryAddressId}
            productInfo={productInfo}
            setActiveTab={setActiveTab}
            setCurrentStage={setStep}
            setDeliveryAddressId={setDeliveryAddressId}
            setDeliveryType={setDeliveryType}
          />
        )}
        {step === 2 && (
          <ThirdStep
            currentStage={step}
            deliveryAddressId={deliveryAddressId}
            deliveryType={deliveryType}
            handleCreateRent={handleCreateRent}
            price={price?.price}
            productInfo={productInfo}
            setCurrentStage={setStep}
          />
        )}
        {step === 3 && (
          <FourthStep
            currentStage={step}
            deliveryAddressId={deliveryAddressId}
            deliveryType={deliveryType}
            endDateString={endDateString}
            getRentPrice={getRentPrice}
            handleCreateRent={handleCreateRent}
            isCreateSuccess={isCreateSuccess}
            isLoading={isLoading}
            price={price?.price}
            productInfo={productInfo}
            setCurrentStage={setStep}
            startDateString={startDateString}
          />
        )}
        {step === 4 && <Finish rentId={data?.rentId} />}
        {step === 5 && <Unsuccess handleCloseModal={handleCloseModal} />}
      </FlexColumn>
    </BaseModal>
  )
}

export default observer(Order)
