import { FC, ReactNode } from 'react'

import styled from 'styled-components/macro'

import { BasePage, DesktopView, FlexColumn, MobileTabletView } from '../../projectComponents'
import { MainHeader } from '../headers/MainHeader'
import { NotModal } from '../modals/NotModal'

type AuthLayoutProps = {
  footer?: ReactNode
  form?: ReactNode
  header?: ReactNode
}

const Wrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  width: clamp(350px, 100%, 800px);
  min-height: 80vh;
  overflow-x: hidden;
  align-items: center;
  display: flex;
  flex-direction: column;

  main {
    width: 100%;
    margin: auto;
    justify-self: center;
    align-items: center;
    align-self: center;
    gap: 48px;
  }

  footer {
    box-sizing: border-box;
  }
`

export const AuthLayout: FC<AuthLayoutProps> = props => {
  const { footer, form, header } = props

  return (
    <>
      <MobileTabletView>
        <NotModal onCloseUrl={'/'}>
          <Wrapper>
            <main>
              {header}
              {form}
            </main>
            {footer}
          </Wrapper>
        </NotModal>
      </MobileTabletView>
      <DesktopView>
        <BasePage>
          <MainHeader />
          <Wrapper>
            <main>
              {header}
              {form}
            </main>
            {footer}
          </Wrapper>
        </BasePage>
      </DesktopView>
    </>
  )
}
