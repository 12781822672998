import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { useGetCategoryInfo } from '../../../entities/categoriesList/queries'
import { useGetProductTagsList } from '../../../entities/createProduct/queries'
import { useGetProductsListQuery } from '../../../entities/productsList/queries'
import { SortOrder } from '../../../shared/api/generated'
import { goods } from '../../../shared/constants/mockData'
import { useModal } from '../../../shared/hooks/useModal'
import { usePathnames } from '../../../shared/hooks/usePathnames'
import FilterStorage from '../../../shared/storage/FilterStorage/FilterStorage'
import { BackArrowWithTitleLink } from '../../../shared/ui-kit/actual/BackArrowWithTitleLink'
import { FilterButton } from '../../../shared/ui-kit/actual/FilterButton'
import { PaginationComponent } from '../../../shared/ui-kit/actual/PaginationComponent'
import { SortButton } from '../../../shared/ui-kit/actual/SortButton'
import { RightModal } from '../../../shared/ui-kit/actual/modals/RightModal'
import FilterWindowNew from '../../../shared/ui-kit/actual/modalsContent/FilterWindowNew'
import { MainMenuWindow } from '../../../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import { breakpoints, devices } from '../../../shared/ui-kit/breakPoints'
import { MainSearchComponent } from '../../../shared/ui-kit/deprecated/MainSearchComponent'
import {
  DesktopTitle,
  FilterSubWrapper,
  FilterWrapper,
  FlexColumnStart,
  FlexRow,
  ImgButton,
  MobileView,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { hideScroll, horizontalScroll } from '../../../shared/ui-kit/scroll'
import Breadcrumbs from '../../market/ui/sections/BreadCrumbs'
import { GoodsDesktopList } from '../../profile/ui/GoodsDesktopList'

interface SectionPageProps {
  setOpenSortMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const TagsSlider = styled(FlexRow)`
  justify-content: start;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 4px;
  padding-bottom: 3px;
  ${horizontalScroll}
  @media screen and (width <= ${breakpoints.tabletMin}) {
    ${hideScroll}
  }
`

const ReverseOrder = styled(FlexColumnStart)`
  width: 100%;
  @media (${devices.tabletMin}) {
    flex-direction: column-reverse;
  }
`

const CategoryProductsList = ({ setOpenSortMenu }: SectionPageProps) => {
  const params = useParams()
  const categoryId = params['categoryId']

  const { sorting } = useContext(FilterStorage)

  const { data: categoryInfo, mutate } = useGetCategoryInfo({ id: parseInt(categoryId) })
  const pageTitle = categoryInfo?.name || ''
  const { data: tagsData } = useGetProductTagsList({
    SectionId: categoryInfo?.sectionId,
    Skip: 0,
    Take: 100,
  })
  const tags = tagsData
    ? [{ id: 999999, name: 'Все' }, ...tagsData.items]
    : [{ id: 999999, name: 'Все' }]
  const [activeTagId, setActiveTagId] = useState(999999)
  const { lastPathname, titleFromLocation } = usePathnames()

  const { setViewModal, viewModal } = useModal()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleFilterModalOpen = () => {
    setViewModal(prev => !prev)
  }
  const handleModalClose = () => {
    setIsModalOpen(prev => !prev)
  }

  useEffect(() => {
    if (categoryId) {
      mutate({})
    }
  }, [mutate, categoryId])

  // const CategoryId = ROUTES_IDS[lastPathname]
  // TODO поменять на пагинацию
  const itemsPerPage = 300
  const [pageId, setPageId] = useState(0)
  const [sortOrder, setSortOder] = useState<SortOrder>('asc')
  const toggleSortOrder = () => {
    setSortOder(sortOrder === 'asc' ? 'desc' : 'asc')
  }
  const { data, isLoading } = useGetProductsListQuery({
    categoryId: Number(categoryId) || null,
    dayPriceFrom: sorting.dayPriceFrom || null,
    dayPriceTo: sorting.dayPriceTo || null,
    orderType: sorting.orderType,
    premiumOnly: sorting.premiumOnly,
    productTagsIds: activeTagId !== 999999 ? [activeTagId] : [],
    ratingFrom: sorting.ratingFrom,
    skip: pageId * itemsPerPage,
    sortOrder: sortOrder,
    take: itemsPerPage,
  })

  const productsCount = data?.totalCount || 0
  const maxPages = data && productsCount / itemsPerPage
  const pagesNum =
    data && productsCount && Array.from(Array(Math.round(maxPages / 2) * 2), (_, i) => i + 1)

  return (
    <>
      <BackArrowWithTitleLink
        rightEl={
          <ImgButton alt={''} onClick={handleModalClose} src={'/images/buttons/menu-button.svg'} />
        }
        title={categoryInfo?.name}
      />
      <MobileView>
        <MainSearchComponent
          onClickFilter={handleFilterModalOpen}
          setIsFixed={() => null}
          hideFilterButton
        />
      </MobileView>
      <Breadcrumbs lastName={categoryInfo?.name} />
      <ReverseOrder>
        <TagsSlider>
          {tags?.map(tag => (
            <StyledButton
              key={`${tag.name}+${tag.id}`}
              onClick={() => setActiveTagId(tag.id)}
              size={'sm'}
              variant={tag.id === activeTagId ? 'primary' : 'disabled'}
            >
              {tag.name}
            </StyledButton>
          ))}
        </TagsSlider>
        <FilterWrapper>
          <DesktopTitle>{titleFromLocation || pageTitle}</DesktopTitle>
          <FilterSubWrapper>
            <SortButton
              onClickSort={() => {
                setOpenSortMenu(prev => !prev)
              }}
              selectedSort={sorting.orderType || 'byDefault'}
              sortOrder={sortOrder}
              toggleSort={toggleSortOrder}
            />
            <FilterButton onClickFilter={handleFilterModalOpen} />
          </FilterSubWrapper>
        </FilterWrapper>
      </ReverseOrder>
      <br />
      {data && (
        <GoodsDesktopList
          goods={goods}
          isLoading={isLoading}
          items={data?.items}
          messageNoProducts={'В этой категории еще нет объявлений c такими характеристиками'}
        />
      )}
      {data && (
        <PaginationComponent
          maxPages={maxPages}
          pageId={pageId}
          pagesNum={pagesNum}
          setPageId={setPageId}
        />
      )}
      <RightModal setViewModal={setViewModal} viewModal={viewModal}>
        <FilterWindowNew handleFilterModalOpen={handleFilterModalOpen} />
      </RightModal>
      <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
        <MainMenuWindow handleModalClose={handleModalClose} />
      </RightModal>
    </>
  )
}

export default observer(CategoryProductsList)
