import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

const ArrowImage = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }
`

interface BottomMenuComponentProps {
  link?: string
}

export const ArrowBackButton = ({ link }: BottomMenuComponentProps) => {
  const navigate = useNavigate()

  return (
    <ArrowImage onClick={() => (link ? navigate(link) : navigate(-1))}>
      <img alt={'arrow-back'} src={'/images/buttons/arrow-right-black.svg'} />
    </ArrowImage>
  )
}
export const ArrowBackButtonCommand = ({ onClick }: { onClick: () => void }) => {
  return (
    <ArrowImage onClick={onClick}>
      <img alt={'arrow-back'} src={'/images/buttons/arrow-right-black.svg'} />
    </ArrowImage>
  )
}
