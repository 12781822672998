import { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import { addDays, differenceInCalendarDays, subDays } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import 'react-datepicker/dist/react-datepicker.css'

import { colors } from '../../constants/colors'

registerLocale('ru-RU', ru)

const Wrapper = styled.div<{ $isSuccess?: boolean }>`
  .react-datepicker {
    box-shadow: 1px 2px 10px -3px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 16px;
  }
  .react-datepicker__current-month {
    border-bottom: solid 0.8px #e4e5e7;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.black};
  }
  .react-datepicker__navigation {
    height: 70px;
  }
  .react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg) scale(70%);
  }
  .react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg) scale(70%);
  }
  .react-datepicker__header {
    background-color: inherit;
  }
  .react-datepicker__current-month {
    text-transform: capitalize;
  }
  .react-datepicker__month {
    margin-top: 0;
  }
  .react-datepicker__header {
    border-bottom: none;
  }
  .react-datepicker__day-name {
    font-size: 10px;
    color: ${colors.primaryBg};
    text-transform: uppercase;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 9px;
  }
  .react-datepicker__day {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #33333380;
    &:hover {
      border-radius: 50%;
      background: ${({ $isSuccess }) => ($isSuccess ? colors.primaryBg : colors.red)};
    }
  }
  .react-datepicker__day--in-range {
    color: #000;
    font-weight: 600;
    background-color: #c6e2e4;
    border-radius: 0;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -18px;
      background-color: #c6e2e4;
      height: 100%;
      width: 18px;
    }
    &::after {
      content: '';
      position: absolute;
      right: -18px;
      background-color: #c6e2e4;
      height: 100%;
      width: 18px;
    }
    &:hover {
      background-color: none;
    }
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    background: ${({ $isSuccess }) => ($isSuccess ? colors.primary : colors.red)};
    color: #fff;
    &::before {
      height: 0;
      width: 0;
    }
    &::after {
      height: 0;
      width: 0;
    }
  }
  .react-datepicker__day--range-start {
    border-radius: 50% 0% 0% 50%;
    background: ${({ $isSuccess }) => ($isSuccess ? colors.primary : colors.red)};
    color: #fff;
    &::before {
      height: 0;
      width: 0;
    }
    &::after {
      height: 0;
      width: 0;
    }
  }
  .react-datepicker__day--range-end {
    border-radius: 0% 50% 50% 0 !important;
    background: ${({ $isSuccess }) => ($isSuccess ? colors.primaryBg : colors.red)};
    color: #fff;
    &::before {
      height: 0;
      width: 0;
    }
    &::after {
      height: 0;
      width: 0;
    }
  }
  .react-datepicker__day.react-datepicker__day--in-selecting-range {
    border-radius: 50%;
    background: ${({ $isSuccess }) => ($isSuccess ? colors.primaryBg : colors.red)};
    color: #fff;
  }
  .react-datepicker__day--range-start.react-datepicker__day--range-end {
    border-radius: 50% !important;
    background-color: ${colors.primary};
  }
  .react-datepicker__day--disabled {
    color: ${colors.black};
    background: ${colors.redTransparent};
    &:hover {
      background-color: ${colors.redTransparent};
      border-radius: unset;
    }
  }
`

type CalendarProps = {
  excludeDateIntervals?: {
    end: Date
    start: Date
  }[]
  isSuccess: boolean
  minDate?: Date
  setEndDateString?: (date: string) => void
  setIntervalLength?: (length: number) => void
  setStartDateString?: (date: string) => void
}

export const CalendarDatePicker = ({
  excludeDateIntervals,
  isSuccess,
  minDate,
  setEndDateString,
  setIntervalLength,
  setStartDateString,
}: CalendarProps) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const inlyneStyle = {
    width: '300px',
  }

  const onChange = dates => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
    if (setIntervalLength && start) {
      setIntervalLength(differenceInCalendarDays(end || start, start) + 1)
    }
  }

  useEffect(() => {
    if (setStartDateString) {
      setStartDateString(startDate.toISOString().split('T')[0])
    }
    if (setEndDateString) {
      setEndDateString(endDate?.toISOString().split('T')[0])
    }
  }, [startDate, endDate, setEndDateString, setStartDateString])

  return (
    <Wrapper $isSuccess={isSuccess}>
      <DatePicker
        endDate={endDate}
        excludeDateIntervals={excludeDateIntervals}
        locale={'ru-RU'}
        minDate={minDate}
        onChange={onChange}
        selected={startDate}
        startDate={startDate}
        inline
        selectsRange
      />
    </Wrapper>
  )
}
