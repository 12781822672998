import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { usePostApiUsersGetUserInfo } from '../../../../entities/ownProfile/queries'
import { useProfileInfo } from '../../../../features/profile/model/use-profile-info'
import { GetUserInfoResponse } from '../../../api/generated'
import { colors } from '../../../constants/colors'
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { FlexColumnBetween, FlexColumnStart, FlexRow, StyledLink } from '../../projectComponents'
import { ReferralCode } from './ReferralCode'

const MenuLink = styled(Link)`
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  color: ${colors.black};
`
const Counter = styled.div`
  position: absolute;
  font-size: 10px;
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -30px;
  bottom: 3px;
`

export const MainMenuWindow = ({ handleModalClose }: { handleModalClose: () => void }) => {
  const store = useContext(UserStorage)
  const [userInfo, setUserInfo] = useState<GetUserInfoResponse>()
  const { mutate } = usePostApiUsersGetUserInfo({
    onError: error => {
      console.log('profile error', error)
    },
    onSuccess: data => {
      setUserInfo(data)
    },
  })

  useEffect(() => {
    store.isAuth && mutate({ userId: store.userId })
  }, [mutate, store.isAuth, store.userId])

  return (
    <FlexColumnBetween style={{ alignItems: 'flex-start', alignSelf: 'center', flexGrow: 1 }}>
      {store?.userId ? (
        <ReferralCode handleModalClose={handleModalClose} userId={store.userId} />
      ) : (
        <MenuLink onClick={handleModalClose} to={'/login'}>
          Войти
        </MenuLink>
      )}
      <FlexColumnStart style={{ flexGrow: 1, gap: '8px' }}>
        <MenuLink onClick={handleModalClose} to={'/orders'}>
          Корзина
        </MenuLink>
        <MenuLink
          onClick={handleModalClose}
          style={{ position: 'relative' }}
          to={'/messages?activeTab=1'}
        >
          Новые заказы
          {userInfo && <Counter>{userInfo.newRentsAsSellerCount}</Counter>}
        </MenuLink>
        <MenuLink onClick={handleModalClose} to={'/messages'}>
          Чат
        </MenuLink>
        <MenuLink onClick={handleModalClose} to={'/'}>
          Главная
        </MenuLink>
        <MenuLink onClick={handleModalClose} to={'/blog'}>
          Блог
        </MenuLink>
        <MenuLink onClick={handleModalClose} to={'/profile'}>
          Профиль
        </MenuLink>
        <MenuLink onClick={handleModalClose} to={'/profile/personal-data'}>
          Настройки
        </MenuLink>
        <MenuLink
          onClick={handleModalClose}
          target={'_blank'}
          to={'https://t.me/Allsharing_support'}
        >
          Помощь
        </MenuLink>
        <StyledLink
          style={{
            fontSize: '14px',
            margin: 0,
            marginTop: '5px',
            paddingInline: '40px',
            width: '100%',
          }}
          onClick={handleModalClose}
          to={'/add'}
        >
          Новый товар
        </StyledLink>
      </FlexColumnStart>
      {store.isAuth && (
        <FlexRow
          onClick={() => {
            handleModalClose()
            store.logout()
          }}
          style={{
            alignItems: 'center',
            cursor: 'pointer',
            gap: '16px',
            justifyContent: 'flex-start',
          }}
          className={'AccordionTrigger'}
        >
          <img alt={''} src={'/images/buttons/Logout.svg'} />
          <span style={{ color: colors.red }}>Выйти из аккаунта</span>
        </FlexRow>
      )}
    </FlexColumnBetween>
  )
}
