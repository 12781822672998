import { useEffect, useState } from 'react'
type Props = {
  isResendSuccess: boolean
  seconds: number
}
export const useInterval = ({ isResendSuccess, seconds }: Props) => {
  const [newInterval, setNewInterval] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (newInterval > 0) {
        setNewInterval(prev => prev - 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [newInterval])

  useEffect(() => {
    if (isResendSuccess) {
      setNewInterval(seconds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendSuccess])

  return {
    newInterval,
  }
}
