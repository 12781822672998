import { SelectItem } from '../types/inteface'

export const LEGAL_STATUS_LIST = [
  'Физическое лицо',
  'Самозанятый',
  'Юридическое лицо',
  'Индивидуальный предприниматель',
]

export enum LEGAL_VALUES_LIST {
  'Индивидуальный предприниматель' = 'LegalEntity',
  'Самозанятый' = 'SelfEmployed',
  'Физическое лицо' = 'Individual',
  'Юридическое лицо' = 'SoleProprietor',
}

export enum LEGAL_NAMES_LIST {
  'individual' = 'Физическое лицо',
  'legalEntity' = 'Юридическое лицо',
  'selfEmployed' = 'Самозанятый',
  'soleProprietor' = 'Индивидуальный предприниматель',
}

export const LEGAL_STATUSES: SelectItem[] = [
  { key: 'individual', value: 'Физическое лицо' },
  { key: 'legalEntity', value: 'Юридическое лицо' },
  { key: 'selfEmployed', value: 'Самозанятый' },
  { key: 'soleProprietor', value: 'Индивидуальный предприниматель' },
]
export type SelectLabelItem = { label: string; value: string }
export const LEGAL_STATUSES_OPTIONS: SelectLabelItem[] = [
  { label: 'Физическое лицо', value: 'individual' },
  { label: 'Юридическое лицо', value: 'legalEntity' },
  { label: 'Самозанятый', value: 'selfEmployed' },
  { label: 'Индивидуальный предприниматель', value: 'soleProprietor' },
]
