import { useEffect, useState } from 'react'

import { AddItemFormContainer } from '../features/addItem/ui/components/AddItemFormContainer'
import Breadcrumbs from '../features/market/ui/sections/BreadCrumbs'
import {
  BackArrowWithTitleCommand,
  BackArrowWithTitleLink,
} from '../shared/ui-kit/actual/BackArrowWithTitleLink'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../shared/ui-kit/actual/layouts/BasePageLayout'
import { RightModal } from '../shared/ui-kit/actual/modals/RightModal'
import { MainMenuWindow } from '../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import {
  BaseWrapper,
  ImgButton,
  MobileView,
  TabletDesktopView,
} from '../shared/ui-kit/projectComponents'
import { Head } from '../shared/ui-kit/titles'

const AddItemPage = () => {
  const [currentStage, setCurrentStage] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStage])

  const handleNextStep = (back?: boolean) => {
    if (back) {
      setCurrentStage(prev => (prev ? prev - 1 : prev))
    } else {
      setCurrentStage(prev => prev + 1)
    }
  }

  const titleText = currentStage === 0 ? 'Новый товар' : ''
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <BasePageLayout>
      <MainHeader />
      <BaseWrapper>
        <Breadcrumbs />
        <TabletDesktopView>
          <Head style={{ marginBottom: '0', width: '100%' }}>{titleText}</Head>
          <AddItemFormContainer currentStage={currentStage} handleNextStep={handleNextStep} />
        </TabletDesktopView>
        <MobileView>
          {currentStage === 0 ? (
            <BackArrowWithTitleLink
              rightEl={
                <ImgButton
                  alt={''}
                  onClick={() => setIsModalOpen(prev => !prev)}
                  src={'/images/buttons/menu-button.svg'}
                />
              }
              title={titleText}
            />
          ) : (
            <BackArrowWithTitleCommand
              rightEl={
                <ImgButton
                  alt={''}
                  onClick={() => setIsModalOpen(prev => !prev)}
                  src={'/images/buttons/menu-button.svg'}
                />
              }
              onClick={() => handleNextStep(true)}
              title={titleText}
            />
          )}
          <AddItemFormContainer currentStage={currentStage} handleNextStep={handleNextStep} />
          <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
            <MainMenuWindow handleModalClose={() => setIsModalOpen(false)} />
          </RightModal>
        </MobileView>
      </BaseWrapper>
    </BasePageLayout>
  )
}

export default AddItemPage
