import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetMarketItemQuery } from '../../../../entities/marketItem/queries'
import {
  useGetRentInfoQuery,
  useRentsCancelByBuyerMutation,
} from '../../../../entities/rent/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { Dropdown } from '../../../../shared/ui-kit/actual/Dropdown'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumn,
  LoaderWrapper,
  NeutralLink,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { GoodsView } from '../GoodsView'
import { StepWrapper } from '../StepWrapper'

type Props = {
  back: () => void
  refetch: () => void
  rentInfo: GetRentInfoResponse
}

const reasons = [
  { key: 1, value: 'Товар больше не нужен' },
  { key: 2, value: 'Не смогу получить товар' },
]

export const Step1 = ({ back, refetch, rentInfo }: Props) => {
  const [isOrderOK, setIsOrderOK] = useState(true)
  const [selectId, setSelectId] = useState<null | number>(0)
  const [reason, setReason] = useState()

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 5000)

    return () => clearInterval(interval)
  }, [refetch])

  const { isLoading, isSuccess, mutate } = useRentsCancelByBuyerMutation()

  useEffect(() => {
    isSuccess && back()
  }, [back, isSuccess])

  return (
    <StepWrapper>
      <div>
        {isOrderOK ? (
          <H3>Ожидает подтверждения арендодателя</H3>
        ) : (
          <H3>Вы действительно хотите отменить заказ?</H3>
        )}
        <GoodsView rentInfo={rentInfo} />
        {!isOrderOK && (
          <>
            <H3>Укажите причину</H3>
            <Dropdown
              customVariantMessage={'Укажите другую причину'}
              handleChange={setSelectId}
              handleChangeValue={e => setReason(e.value)}
              items={reasons}
              label={'Причина'}
              selectedId={selectId}
              style={{ marginTop: '16px' }}
              fullwidth
            />
          </>
        )}
      </div>
      <FlexColumn style={{ gap: '8px' }}>
        {isOrderOK ? (
          <NeutralLink to={`/users/${rentInfo?.sellerId}`}>
            <StyledButton fullwidth>Об арендодателе</StyledButton>
          </NeutralLink>
        ) : (
          <StyledButton onClick={() => setIsOrderOK(true)} fullwidth>
            Назад
          </StyledButton>
        )}

        {isOrderOK ? (
          <StyledButton onClick={() => setIsOrderOK(false)} variant={'secondary'} fullwidth>
            Отменить заказ
          </StyledButton>
        ) : (
          <StyledButton
            onClick={() => {
              mutate({ rentId: Number(rentInfo.rentId) })
            }}
            disabled={isLoading}
            variant={'secondary'}
            fullwidth
          >
            Отменить заказ
          </StyledButton>
        )}
      </FlexColumn>
    </StepWrapper>
  )
}
