import React, { useState } from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import styled from 'styled-components/macro'

import { ReviewListItemDto } from '../../../api/generated'
import { goldStarImg } from '../../../constants/images'
import { FlexColumn, FlexRow, FlexRowBetween } from '../../projectComponents'
import { Spacer } from '../Spacer'
import ReviewModal from './ReviewModal'

const Block = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  justify-content: start;
  height: 200px;
  cursor: pointer;
  p {
    font-size: 15px;
    margin: 0;
    text-align: start;
    width: 100%;
  }
`

interface ReviewComponentProps {
  review: ReviewListItemDto
}

export const ReviewComponent: React.FC<ReviewComponentProps> = ({ review }) => {
  const getDate = (date: string) => format(new Date(date), 'dd MMMM HH:mm', { locale: ru })
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <>
      {modalOpened && <ReviewModal reviewData={review} setOpen={setModalOpened} />}
      <Block onClick={() => setModalOpened(true)}>
        <p style={{ fontSize: 14, marginTop: 5, textAlign: 'center' }}>
          {getDate(review.createOn)}
        </p>
        <FlexRowBetween style={{ marginTop: 10, width: '100%' }}>
          <FlexColumn style={{ width: '50%' }}>
            <p style={{ fontSize: 14, marginTop: 14, textAlign: 'center' }}>
              @{review.buyerUserName}
            </p>
            <p style={{ fontSize: 14, marginTop: 14, textAlign: 'center' }}>
              {review.buyerFullName}
            </p>
          </FlexColumn>
          <img
            alt={''}
            src={'/images/buttons/arrow-right.svg'}
            style={{ filter: 'invert(1)', marginTop: 17 }}
          />
          <FlexColumn style={{ width: '50%' }}>
            <p
              style={{
                fontSize: 14,
                marginTop: 14,
                textAlign: 'center',
              }}
            >
              @{review.sellerUserName}
            </p>
            <p style={{ fontSize: 14, marginTop: 4, textAlign: 'center' }}>
              {review.sellerFullName}
            </p>
          </FlexColumn>
        </FlexRowBetween>
        <Spacer />
        <FlexRow>
          {[1, 2, 3, 4, 5].map(
            st =>
              st <= review.rating && (
                <img alt={'Star'} key={st} src={goldStarImg} style={{ margin: 5, width: 22 }} />
              )
          )}
          <Spacer />
        </FlexRow>
        <FlexRow style={{ marginTop: 10 }}>
          <p>{review?.productName}</p>
        </FlexRow>
      </Block>
    </>
  )
}
