import { useContext, useEffect, useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { useGetUserProfileMutation } from '../../../../entities/userProfile/queries'
import {
  GetProductInfoResponse,
  GetProductRentPriceQuery,
  GetProductRentPriceResponse,
  RentProductDeliveryType,
} from '../../../../shared/api/generated'
import { usePayment } from '../../../../shared/hooks/use-payment'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import { PaymentBlock } from '../../../../shared/ui-kit/actual/components/PaymentBlock'
import {
  Desc,
  FlexColumnBetween,
  FlexRowBetween,
  FlexRowGap,
  StyledButton,
  Title,
} from '../../../../shared/ui-kit/projectComponents'
import { BankRequisites } from '../../../../shared/ui-kit/actual/components/BankRequisites'

const Box = styled.div`
  background: #f8f8fa;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 22px 0;
  .title {
    color: #008c8c;
    font-size: 24px;
    font-weight: 600;
    line-height: 25.36px;
    margin: 0;
    padding: 0;
  }
  .desc {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.04px;
    margin: 0;
    padding: 0;
    opacity: 0.2;
  }
`

type Props = {
  currentStage: number
  deliveryAddressId: number
  deliveryType: RentProductDeliveryType
  endDateString: string
  getRentPrice: UseMutateFunction<
    GetProductRentPriceResponse,
    unknown,
    GetProductRentPriceQuery,
    unknown
  >
  handleCreateRent: () => void
  isCreateSuccess: boolean
  isLoading: boolean
  price: number
  productInfo: GetProductInfoResponse
  setCurrentStage: (it: number) => void
  startDateString: string
}
const FourthStep = ({
  currentStage,
  deliveryAddressId,
  deliveryType,
  endDateString,
  getRentPrice,
  handleCreateRent,
  isCreateSuccess,
  isLoading,
  price,
  productInfo,
  setCurrentStage,
  startDateString,
}: Props) => {
  const [condition, setCondition] = useState(false)

  const ProfileStore = useContext(ProfileStorage)

  const { mutate: addUserProfile } = useGetUserProfileMutation({
    onError: error => {
      console.log('profile loading error', error)
    },
    onSuccess: data => {
      ProfileStore.setProfile(data)
    },
  })
  const {
    amount,
    createPayment,
    data,
    handleSetAmount,
    isPaymentLoading,
    safariButton,
    setAmount,
    setSafariButton,
  } = usePayment()

  // Нужно чтобы обновить баланс пользователя в профиле
  useEffect(() => {
    isCreateSuccess && addUserProfile({ userId: ProfileStore?.userInfo?.id })
  }, [ProfileStore?.userInfo?.id, addUserProfile, isCreateSuccess])

  useEffect(() => {
    if (isCreateSuccess) {
      setCurrentStage(currentStage <= 3 ? currentStage + 1 : currentStage)
    } else if (condition && isCreateSuccess === false && isLoading === false) {
      setCurrentStage(5)
    }
  }, [condition, currentStage, isCreateSuccess, isLoading, setCurrentStage])

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    if (deliveryType) {
      updatedSearchParams.set('deliveryType', deliveryType)
    }
    if (startDateString) {
      updatedSearchParams.set('startDateString', startDateString)
    }
    if (endDateString) {
      updatedSearchParams.set('endDateString', endDateString)
    }
    if (deliveryAddressId) {
      updatedSearchParams.set('deliveryAddressId', String(deliveryAddressId))
    }
    setSearchParams(updatedSearchParams.toString())
  }, [
    deliveryAddressId,
    deliveryType,
    endDateString,
    searchParams,
    setSearchParams,
    startDateString,
  ])
  const startDateFromQuery = searchParams.get('startDateString')
  const endDateFromQuery = searchParams.get('endDateString')

  useEffect(() => {
    if (startDateFromQuery && endDateFromQuery && productInfo?.id) {
      getRentPrice({
        periodFrom: startDateFromQuery,
        periodTo: endDateFromQuery,
        productId: productInfo?.id,
      })
    }
  }, [getRentPrice, productInfo?.id, startDateFromQuery, endDateFromQuery])

  return (
    <FlexColumnBetween style={{ height: '100%', paddingBottom: '64px', width: '100%' }}>
      <div style={{ flex: '1 0', width: '100%' }}>
        <Title style={{ marginBottom: '24px' }}>Оплата</Title>
        <Box>
          <FlexRowGap style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div>
              <h3 className={'title'}>{ProfileStore.profile.balanceUser.accountBalance} ₽</h3>
              <p className={'desc'}>Баланс</p>
            </div>
            <StyledButton
              onClick={() => {
                addUserProfile({ userId: ProfileStore?.userInfo?.id })
              }}
              size={'sm'}
            >
              Обновить
            </StyledButton>
          </FlexRowGap>
        </Box>
        {ProfileStore.getUserInfo().legalStatus === 'individual' ||
        ProfileStore.getUserInfo().legalStatus === 'selfEmployed' ? (
          <>
            <br />
            <PaymentBlock
              amount={amount}
              confirmationUrl={data?.confirmationUrl}
              createPayment={createPayment}
              handleSetAmount={handleSetAmount}
              isPaymentLoading={isPaymentLoading}
              safariButton={safariButton}
              setAmount={setAmount}
              setSafariButton={setSafariButton}
            />
          </>
        ) : (
          <BankRequisites />
        )}
        <br />
        {/* <CheckBox
          label={
            <Paragraph style={{ maxWidth: '185px' }}>
              {' '}
              Использовать мои бонусные баллы <span style={{ color: '#008C8C' }}>(1000SH)</span>
            </Paragraph>
          }
          checked={condition}
          setChecked={setCondition}
        /> */}
      </div>
      <div style={{ width: '100%' }}>
        {/* <FlexRowBetween
          style={{ borderBottom: '1px solid #E0E5E6', marginBottom: '24px', paddingBottom: '8px' }}
        >
          <SubTitle>Скидка</SubTitle>
          <Desc style={{ color: '#008C8C' }}>10 000₽</Desc>
        </FlexRowBetween> */}
        <FlexRowBetween>
          <Title>Итого</Title>
          <Desc style={{ color: '#008C8C' }}>{productInfo?.pledge + (price || 0)} ₽</Desc>
        </FlexRowBetween>
        <br />
        <StyledButton
          onClick={() => {
            handleCreateRent()
            setCondition(true)
          }}
          style={{ margin: 0, marginTop: '16px', minWidth: '100%' }}
          variant={'primary'}
        >
          Заказать
        </StyledButton>
      </div>
    </FlexColumnBetween>
  )
}

export default observer(FourthStep)
