import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { useGetClaimsListQuery } from '../entities/claims/queries'
import { useGetWithdrawalRequestsListQuery } from '../entities/payments/queries'
import { ClaimStatus, WithdrawalRequestDto } from '../shared/api/generated'
import {
  operationsStatuses,
  transactionsStatuses,
  transactionsTypes,
} from '../shared/constants/transactions'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { TextsField } from '../shared/ui-kit/actual/TextsField'
import { AdminClaimsTable } from '../shared/ui-kit/actual/components/AdminClaimsTable'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  BasePage,
  FlexColumn,
  FlexRowBetween,
  FlexRowGap,
  SectionsPageWrapper,
  StyledButton,
  Table,
  Th,
} from '../shared/ui-kit/projectComponents'
import { H1, H5 } from '../shared/ui-kit/titles'
import { shortString, truncateString } from '../shared/utils/truncate'

const TransactionsList = ({
  setUserId,
  withdrawals,
}: {
  setUserId: React.Dispatch<React.SetStateAction<string>>
  withdrawals: WithdrawalRequestDto[]
}) => (
  <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}>
    {' '}
    <Table>
      <thead>
        <tr>
          <Th></Th>
          <Th>Дата</Th>
          <Th style={{ maxWidth: '200px' }}>Пользователь</Th>
          <Th>Сумма</Th>
          <Th>Статус</Th>
          <Th>Комментарий</Th>
        </tr>
      </thead>
      <tbody>
        {withdrawals.map(t => (
          <tr key={t.withdrawalRequestId}>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '150px' }}
                to={`/admin/withdrawals/${t.withdrawalRequestId}`}
              >
                К запросу
              </Link>{' '}
            </td>
            <td>{format(new Date(t.createdOn), 'dd MMMM HH:mm', { locale: ru })}</td>
            <td>
              <Link
                style={{ display: 'inline-block', minWidth: '150px' }}
                to={`/users/${t.userId}`}
              >
                {shortString(t.userName, 18)}
              </Link>{' '}
              <Link
                style={{ display: 'inline-block', minWidth: '100px' }}
                to={`/admin/users/${t.userId}`}
              >
                {truncateString(t.userId, 8)}
              </Link>
              <button onClick={() => setUserId(t.userId)}>только этот пользователь</button>
            </td>
            <td>{t.withdrawalAmount}</td>
            <td>{operationsStatuses[t.status] || t.status}</td>
            <td>{t.comment}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </FlexRowBetween>
)

export default function AdminWithdrawalsListPage() {
  const [statuses, setStatuses] = useState<string[]>([])
  const [userId, setUserId] = useState<null | string>(null)

  useEffect(() => {
    setPage(0)
  }, [statuses])
  const resetTerms = () => {
    setStatuses([])
    setPage(0)
  }
  const [itemsPerPage, setItemsPerPage] = useState(24)
  const [page, setPage] = useState(0)
  const { data, isLoading } = useGetWithdrawalRequestsListQuery({
    skip: page * itemsPerPage,
    // statuses: statuses as ClaimStatus[],
    take: itemsPerPage,
    userId,
  })
  const totalPages = useMemo(
    () => (data ? Math.ceil(data?.totalCount / itemsPerPage) : 1),
    [itemsPerPage, data]
  )

  return (
    <BasePage>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <ArrowBackButton />
        <H1 style={{ width: '100%' }}>Просмотр списка запросов на вывод</H1>
      </FlexRowBetween>
      <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15 }}>
        <p style={{ fontSize: 15, margin: 0, padding: 0 }}>
          Всего запросов c примененным фильтром : {data?.totalCount}
        </p>
        {/* <Filter onClick={() => setOpenUserSortModal(true)}>
              <img alt={''} src={'/images/buttons/filter2.png'} />
            </Filter> */}
        <StyledButton onClick={resetTerms} size={'sm'}>
          Сбросить фильтр
        </StyledButton>
      </FlexRowBetween>
      <br />

      <SectionsPageWrapper>
        {!isLoading ? (
          <>
            <TransactionsList setUserId={setUserId} withdrawals={data?.items || []} />
            {data && (
              <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
            )}
          </>
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
    </BasePage>
  )
}
