import { useMemo, useState } from 'react'

import { useRentsGetRentsListQuery } from '../../../entities/rent/queries'
import { useProfileInfo } from '../../profile/model/use-profile-info'

const itemsPerPage = 12

export const useGetBayerRentsList = () => {
  const { userInfo } = useProfileInfo()
  const [buyerFinishedPage, setBuyerFinishedPage] = useState(0)
  const {
    data: dataFinished,
    isLoading,
    isSuccess,
  } = useRentsGetRentsListQuery({
    buyerId: userInfo.id,
    rentStatuses: [
      'cancelledByBuyer',
      'cancelledBySeller',
      'completed',
      'completedWithClaimByBuyer',
      'completedWithClaimBySeller',
      'rejectedBySeller',
    ],
    skip: buyerFinishedPage * itemsPerPage,
    take: itemsPerPage,
    userId: userInfo.id,
  })
  const totalBuyerFinishedPages = useMemo(
    () => (dataFinished ? Math.ceil(dataFinished?.totalCount / itemsPerPage) : 1),
    [dataFinished]
  )

  const [buyerActivePage, setBuyerActivePage] = useState(0)
  const {
    data: dataActive,
    isLoading: isActiveLoading,
    isSuccess: isActiveSuccess,
  } = useRentsGetRentsListQuery({
    buyerId: userInfo.id,
    rentStatuses: [
      'approvedBySeller',
      'inUseByBuyer',
      'qrCodeGenerated',
      'qrCodeValidated',
      'sentBackToSeller',
      'sentToBuyer',
      'created',
    ],
    skip: buyerActivePage * itemsPerPage,
    take: itemsPerPage,
    userId: userInfo.id,
  })
  const totalBuyerActivePages = useMemo(
    () => (dataActive ? Math.ceil(dataActive?.totalCount / itemsPerPage) : 1),
    [dataActive]
  )

  return {
    active: dataActive?.items,
    buyerActivePage,
    buyerFinishedPage,
    finished: dataFinished?.items,
    isLoading: isLoading || isActiveLoading,
    isSuccess: isSuccess || isActiveSuccess,
    setBuyerActivePage,
    setBuyerFinishedPage,
    totalBuyerActivePages,
    totalBuyerFinishedPages,
  }
}

export const useGetSellerRentsList = () => {
  const { userInfo } = useProfileInfo()
  const [sellerFinishedPage, setSellerFinishedPage] = useState(0)

  const {
    data: dataFinished,
    isLoading,
    isSuccess,
  } = useRentsGetRentsListQuery({
    rentStatuses: [
      'cancelledByBuyer',
      'cancelledBySeller',
      'completed',
      'completedWithClaimByBuyer',
      'completedWithClaimBySeller',
      'rejectedBySeller',
    ],
    sellerId: userInfo.id,
    skip: sellerFinishedPage * itemsPerPage,
    take: itemsPerPage,
    userId: userInfo.id,
  })
  const totalSellerFinishedPages = useMemo(
    () => (dataFinished ? Math.ceil(dataFinished?.totalCount / itemsPerPage) : 1),
    [dataFinished]
  )

  const [sellerActivePage, setSellerActivePage] = useState(0)
  const {
    data: dataActive,
    isLoading: isActiveLoading,
    isSuccess: isActiveSuccess,
  } = useRentsGetRentsListQuery({
    rentStatuses: [
      'approvedBySeller',
      'inUseByBuyer',
      'qrCodeGenerated',
      'qrCodeValidated',
      'sentBackToSeller',
      'sentToBuyer',
    ],
    sellerId: userInfo.id,
    skip: sellerActivePage * itemsPerPage,
    take: itemsPerPage,
    userId: userInfo.id,
  })
  const totalSellerActivePages = useMemo(
    () => (dataActive ? Math.ceil(dataActive?.totalCount / itemsPerPage) : 1),
    [dataActive]
  )

  return {
    activeSale: dataActive?.items,
    finishedSale: dataFinished?.items,
    isLoading: isLoading || isActiveLoading,
    isSuccess: isSuccess || isActiveSuccess,
    sellerActivePage,
    sellerFinishedPage,
    setSellerActivePage,
    setSellerFinishedPage,
    totalSellerActivePages,
    totalSellerFinishedPages,
  }
}
