import React from 'react'

import { MarketTitle, MarketTitleWrapper, MobileView } from '../../projectComponents'
import { ArrowForwardButton } from '../ArrowForwardButton'

interface MarketTitleComponentProps {
  link?: string
  title: string
}

export const MarketTitleComponent: React.FC<MarketTitleComponentProps> = ({ link, title }) => {
  return (
    <MarketTitleWrapper>
      <MarketTitle>{title}</MarketTitle>
      <MobileView style={{ width: 'auto' }}>
        <ArrowForwardButton link={link} />
      </MobileView>
    </MarketTitleWrapper>
  )
}
