import styled from 'styled-components/macro'

import { MapContainer } from '../../../../features/map'
import { PickupAddressDto } from '../../../api/generated'
import { colors } from '../../../constants/colors'
import { devices } from '../../breakPoints'
import { FlexRowBetween } from '../../projectComponents'
import { Paragraph } from '../Paragraph'

const SubTitle = styled.p`
  margin: 0;
  padding: 0;
  line-height: 23.36px;
  color: ${colors.black};
  font-size: 16px;
`
const Wrapper = styled(FlexRowBetween)`
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media screen and (${devices.tabletMax}) {
    flex-wrap: nowrap;
  }
`

export const LocationInfo = ({
  noMap,
  pickupAddress,
}: {
  noMap?: boolean
  pickupAddress: PickupAddressDto
}) => (
  <>
    {pickupAddress ? (
      <Wrapper>
        <div>
          <SubTitle>{pickupAddress?.city}</SubTitle>
          <Paragraph style={{ lineHeight: '23.36px' }}>{pickupAddress?.address}</Paragraph>
          {pickupAddress?.postcode && (
            <Paragraph style={{ lineHeight: '23.36px' }}>
              Почтовый индекс: {pickupAddress?.postcode}
            </Paragraph>
          )}
        </div>
        {!noMap && (
          <MapContainer
            coordinates={[pickupAddress?.latitude, pickupAddress?.longitude]}
            height={200}
            width={300}
            isPlacemark
          />
        )}
      </Wrapper>
    ) : null}
  </>
)
