import styled from 'styled-components/macro'

import { colors } from '../../../../shared/constants/colors'
import { ProfileSwitchTabs } from '../../../../shared/ui-kit/actual/ProfileSwitchTabs'
import { FlexColumn } from '../../../../shared/ui-kit/projectComponents'
import { ProfileRentDeliveryTab } from './ProfileRentDeliveryTab'
import { ProfileRentPickupTab } from './ProfileRentPickupTab'

type Props = {
  activeTab: number
  setActiveTab: (activeTab: number) => void
}

const Wrapper = styled(FlexColumn)`
  width: 100%;
  box-sizing: border-box;
  padding: 28px 24px 96px 24px;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  gap: 24px;
  transition: 0.2s ease-out;
`

export const ProfileRentWrapper = ({ activeTab, setActiveTab }: Props) => {
  return (
    <Wrapper>
      <ProfileSwitchTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabHead0={'Доставки'}
        tabHead1={'Самовывоз'}
      />
      {!activeTab ? <ProfileRentDeliveryTab /> : <ProfileRentPickupTab />}
    </Wrapper>
  )
}
