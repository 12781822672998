import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useGetMarketItemQuery } from '../../../../entities/marketItem/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { StyledCheckbox } from '../../../../shared/ui-kit/actual/StyledCheckbox'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumn,
  FlexColumnStart,
  FlexRowBetween,
  FlexRowGap,
  MediumText,
  MediumTextDark,
  MediumTextPrimary,
  NeutralLink,
  StyledButton,
  StyledSwitch,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { dateToString } from '../../../../shared/utils/dateToString'
import { GoodsView } from '../GoodsView'
import { StepWrapper } from '../StepWrapper'

type Props = {
  handleSubmit: () => void
  isSendPhotosLoading: boolean
  isSendPhotosSuccess: boolean
  rentInfo: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}
const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  border-radius: 11px;
  background-color: ${colors.lightGray};
  padding: 22px;
`

const AgreementWrapper = styled(FlexRowGap)`
  margin: 16px 0;
  align-items: center;
`

export const Step4 = ({
  handleSubmit,
  isSendPhotosLoading,
  isSendPhotosSuccess,
  rentInfo,
  setStep,
}: Props) => {
  const [isAgree, setIsAgree] = useState(false)
  const { data: productInfo, isLoading } = useGetMarketItemQuery({ productId: rentInfo?.productId })

  useEffect(() => {
    isSendPhotosSuccess && setStep(prev => prev + 1)
  }, [isSendPhotosSuccess, setStep])

  return (
    <StepWrapper>
      <AbsoluteLoader isLoading={isSendPhotosLoading} />
      <FlexColumnStart>
        <H3>Товар проверен</H3>
        <MediumText>
          Аренда до: {dateToString(rentInfo?.periodTo)} <br /> Будьте аккуратны, приятного
          использования
        </MediumText>
        <GoodsView rentInfo={rentInfo} />
        <ContentWrapper>
          <MediumText style={{ marginBottom: '16px' }}>
            В данной таблице указаны штрафы за дефекты
          </MediumText>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              {productInfo.defectFines.map((item, idx) => (
                <FlexRowBetween key={idx}>
                  <MediumTextDark>{item.defectName}</MediumTextDark>
                  <MediumTextPrimary>{item.amount} ₽</MediumTextPrimary>
                </FlexRowBetween>
              ))}
            </>
          )}
        </ContentWrapper>
      </FlexColumnStart>
      <FlexColumn>
        <AgreementWrapper>
          <StyledCheckbox checked={isAgree} onChange={e => setIsAgree(e.target.checked)} />
          <MediumText>
            Я согласен со всеми правилами арендодателя и платформы (Подробнее:{' '}
            <Link
              style={{ color: colors.primary }}
              target={'_blank'}
              to={'https://landing.allsharing.su/rules/terms-of-use-ru.html'}
            >
              Договор
            </Link>
            )
          </MediumText>
        </AgreementWrapper>
        <StyledButton disabled={!isAgree || isSendPhotosLoading} onClick={handleSubmit} fullwidth>
          Забрать заказ
        </StyledButton>
        <br />
      </FlexColumn>
    </StepWrapper>
  )
}
