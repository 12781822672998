import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { AccountType } from '../api/generated'
import { colors } from '../constants/colors'
import { StyledButton } from './actual/StyledButton'
import { StyledInput } from './actual/StyledInput'
import {
  breakpoints,
  desktopMiddleView,
  desktopView,
  devices,
  mobileTabletDesktopMinView,
  mobileTabletView,
  mobileView,
  tabletDesktopView,
  tabletOnlyView,
} from './breakPoints'
import { hideScroll, horizontalScroll } from './scroll'

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
export const FlexRowStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const FlexRowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`
export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const FlexColumnBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`
export const FlexColumnStart = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`
export const FlexRowBetween = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

export const FlexRowGap = styled(FlexRow)`
  column-gap: 20px;
  width: clamp(300px, 100%, 800px);
  justify-content: flex-start;
`

export { StyledButton, StyledInput }
export const StyledButtonTransparent = styled(StyledButton)`
  background: none;
  border-radius: 10px;
  padding: ${props => (props.mini ? '10px 14px' : '15px')};
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 8px;
  font-size: ${props => (props.mini ? '13px' : '16px')};
  font-weight: ${props => (props.mini ? '400' : '500')};
  border: 1px solid ${colors.primary};
  color: ${colors.primary};

  p {
    font-size: ${props => (props.mini ? '13px' : '16px')};
    margin: 0;
    padding: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
`

type IErrorTextProps = {
  hasError?: boolean
}

export const ErrorText = styled.p<IErrorTextProps>`
  color: ${colors.redFail};
  font-size: 12px;
  height: fit-content;
  visibility: ${props => (props.hasError ? 'visible' : 'hidden')};
`

ErrorText.defaultProps = {
  hasError: false,
}

export const ChangeDataButton = styled(StyledButton)`
  background: #fff;
  color: #000;
  border-radius: 10px;
  box-shadow: 1px 0 10px -3px rgba(0, 0, 0, 0.25);
`
export const StyledLink = styled(Link)<{ fullWidth?: boolean }>`
  background: #008c8c;
  color: #fff;
  border-radius: 15px;
  padding: 13px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  :hover {
    opacity: 0.6;
    transition: all ease-in 0.2s;
  }
`
export const StyledLinkHeader = styled(StyledLink)`
  padding: 12px 40px;
  margin: 0;
  font-size: 15px;

  &:hover {
    background: ${colors.primaryActive};
  }
`

export const StyledLinkTransparent = styled(Link)`
  background: none;
  color: #008c8c;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  margin: 8px;
  text-decoration: none;
`
export const BaseLink = styled(Link)`
  color: ${colors.primary};
  font-weight: 600;
`
export const StyledLinkPrimary = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  background-color: ${colors.primary};
  color: ${colors.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  padding: 14px 36px;
  border-radius: 10px;
  transition: all ease-in 0.2s;
  :hover {
    opacity: 0.6;
  }
`
export const ConfirmIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 8px;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;

  img {
    width: 22px;
    height: 22px;
  }

  &:hover {
    opacity: 0.9;
  }
`

export const Modal = styled(FlexColumn)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: start;
  background: #fff;
  z-index: 999;
  overflow-y: scroll;

  * {
    box-sizing: border-box;
  }
`
export const ModalWindow = styled(FlexColumn)`
  background: #fff;
  border-radius: 16px;
  padding: 10px;
  width: calc(100% - 20px);
  max-width: 400px;
`

export const MarketTitleWrapper = styled(FlexRowBetween)`
  align-items: center;
  margin: 8px 8px 16px 12px;
`
export const CategoriesTitleWrapper = styled(FlexRowStart)`
  width: 100%;
  margin-bottom: 16px;
  margin-top: 32px;
`
export const CategoriesTitleH1 = styled.h1`
  flex-grow: 1;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
  color: ${colors.black};
  @media ${devices.desktopMin} {
    text-align: left;
  }
`
export const MarketTitle = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
  color: ${colors.black};
`
export const MarketWrapper = styled(FlexColumn)`
  padding: 10px 24px 30px;
  width: 100%;
`

export const SectionsWrapper = styled(FlexColumn)`
  gap: 20px;
  width: 100%;
  height: auto;
  margin-top: 0;
  overflow: auto;
  padding: 0 7px 10px;
  @media (min-width: 450px) {
    margin: 0 auto;
    gap: 8px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
`

export const SectionsPageWrapper = styled(FlexRowBetween)`
  gap: 20px;
  width: 100%;
  margin-top: 0;
  overflow: auto;
  padding: 0 7px 10px;
  justify-content: center;
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.mobileMax}) {
    gap: 40px;
  }
`

export const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  align-items: center;
  justify-content: center;
  justify-items: center;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.disabledSwitch};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  span:before {
    position: absolute;
    content: '';
    border-radius: 50%;
    height: 17px;
    width: 17px;
    left: 3px;
    bottom: 3.5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:focus {
  }

  input:checked + span {
    background-color: ${colors.primary};
  }

  input:checked + span:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
`

export const StyledTag = styled.button`
  padding: 8px 16px;
  background: ${colors.lightGray};
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;

  font-size: 14px;
  font-weight: 600;
  color: ${colors.darkGrayText};
`

export const LoginText = styled.h3`
  color: ${colors.darkGrey};
  align-self: center;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
`

export const FilterTitle = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 21px;
`

export const AllFilterButton = styled.button`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.primary};
  background: none;
  border: none;
  cursor: pointer;
`

export const BoldNumber = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
`

export const OpacityText = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.2);
`
export const MediumText = styled.div`
  color: ${colors.gray};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  @media screen and (${devices.tabletMax}) {
    font-size: 16px;
  }
`
export const MediumTextDark = styled(MediumText)`
  color: ${colors.black};
`
export const MediumTextBold = styled(MediumTextDark)`
  font-size: 16px;
  font-weight: 600;
`
export const MediumTextL = styled.div`
  color: ${colors.darkGray};
  font-size: 16px;
  font-weight: 400;
`
export const MediumTextPrimary = styled.div`
  color: ${colors.primary};
  font-size: 16px;
  font-weight: 600;
`

export const SmallText = styled.div`
  color: ${colors.black};
  opacity: 0.5;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
`
export const SmallTextGrey = styled.div`
  color: ${colors.grey};
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`
export const SmallTextBold = styled.div`
  color: ${colors.black};
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
`
export const RelativeWrapper = styled.div`
  position: relative;
`
export const Dot = styled.div<{ $selected?: boolean }>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${colors.primary};
  opacity: ${({ $selected }) => ($selected ? 1 : 0.5)};
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translate(-25%, -25%);
    cursor: pointer;
  }
`
export const DotsWrapper = styled(FlexRow)`
  gap: 10px;
`
export const HeaderTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: rgb(100, 100, 100);
`

export const StyledLinkImg = styled(Link)`
  color: unset;
  text-decoration: none;
`

export const MobileTabletDesktopMinView = styled.div`
  width: 100%;
  ${mobileTabletDesktopMinView}
`
export const DesktopMiddleView = styled.div`
  width: 100%;
  ${desktopMiddleView}
`
export const DesktopView = styled.div`
  width: 100%;
  ${desktopView}
`
export const TabletDesktopView = styled.div`
  width: 100%;
  ${tabletDesktopView}
`
export const MobileTabletView = styled.div`
  width: 100%;
  ${mobileTabletView}
`
export const TabletOnlyView = styled.div`
  width: 100%;
  ${tabletOnlyView}
`
export const MobileView = styled.div`
  width: 100%;
  ${mobileView}
`

export const CardWrapper = styled(FlexRow)`
  gap: 8px;
  justify-content: flex-start;
  overflow-x: scroll;
`

export const PromoCardWrapper = styled(CardWrapper)`
  @media (min-width: 800px) {
    flex-wrap: wrap;
    max-height: 486px;
  }
`
export const Block = styled.div`
  width: 100%;
  overflow: auto;
  align-self: flex-start;

  ${horizontalScroll}
  @media screen and (max-width: ${breakpoints.desktopMin}) {
    ${hideScroll}
  }
`
export const InnerModalWrapper = styled(FlexColumn)`
  width: 100%;
  height: 90%;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;
  overflow: visible;
  flex-grow: 1;
`
export const TopModalWrapper = styled(FlexColumn)`
  width: 100%;
  flex: 1 0;
  justify-content: flex-start;
  align-items: stretch;
  gap: 24px;
  overflow: visible;
  @media screen and (${devices.mobileMax}) {
    justify-content: space-evenly;
    padding-inline: 52px;
  }
`

export const BottomModalWrapper = styled(FlexColumn)`
  flex: 1 0;
  width: 100%;
  justify-content: flex-end;
  align-items: stretch;
`

export const BasePage = styled(FlexColumn)`
  padding: 0 12px 60px;
  width: clamp(${breakpoints.mobileMin}, 100%, ${breakpoints.desktopMax});
  margin: 0 auto;
  @media (${devices.tabletMin}) {
    padding: 0 36px 60px;
    overflow: auto;
  }
`
export const BtnWrapper = styled(FlexColumn)`
  width: 100%;
  gap: 8px;
`

export const BtnRowWrapper = styled(FlexRowBetween)`
  gap: 8px;
  align-items: center;
`

export const StyledBtnSmall = styled(StyledButton)`
  flex: 0 1;
`

export const StyledBtnColumn = styled(StyledButton)`
  flex-direction: column;
  color: #000;
`

export const CloseModalBtn = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  outline: none;
  right: 16px;
  top: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`
export const ImgButton = styled.img`
  cursor: pointer;
`
export const WrapperMobile = styled(FlexColumn)`
  margin-top: 16px;
  width: clamp(300px, 100%, 800px);
  box-sizing: border-box;
  padding: 24px 24px 96px;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  gap: 24px;
  transition: 0.2s ease-out;
`
export const Logo = styled.img`
  width: 36px !important;
  margin-right: 10px;
`
export const RateTabloWrapper = styled(FlexColumnStart)`
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  margin-right: 24px;
`
export const MainRateBig = styled.h1`
  font-size: 30px;
  margin: 0;
`
export const MainRateSmall = styled.p`
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 5px;
  margin: 0;
`
export const LoginTitle = styled.h3`
  font-size: 21px;
  padding: 0;
  margin: 0;
  font-weight: 600;
  @media (${devices.mobileMax}) {
    font-size: 24px;
  }
`
export const LoginDescription = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
  @media (${devices.mobileMax}) {
    font-size: 16px;
  }
`
export const RateTablo = styled(FlexRow)`
  justify-content: start;
  align-items: end;
  min-width: 80px;
`

export const StatusBar = styled(FlexColumn)`
  width: 100px;
  height: 4px;
  background: #ededed;
  margin-left: 12px;
  border-radius: 5px;
  align-items: start;
`
export const GoldStatus = styled.div`
  width: 50px;
  height: 4px;
  background: #008c8c;
  border-radius: 5px;
`
export const StatusTitle = styled.p`
  margin: 0 0 0 10px;
  font-size: 14px;
`
export const NeutralLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
`
export const AvatarLarge = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 12px;
  object-fit: cover;
`
export const FileInputButton = styled(FlexRow)`
  position: absolute;
  height: 52px;
  width: 52px;
  bottom: -10%;
  left: 87%;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: ${colors.white};
  gap: 4px;
  cursor: pointer;
  box-shadow: 0px 2px 30px 0px #0000000d;
`

export const RatingWrapper = styled(FlexRow)<{ roleType: AccountType }>`
  position: absolute;
  bottom: -17%;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  border-radius: 16px;
  background-color: ${({ roleType }) =>
    roleType === 'premium'
      ? colors.primary
      : roleType === 'verified'
        ? colors.yellowGreen
        : colors.red};
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(151, 145, 219, 0.24);

  span {
    color: ${colors.white};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
    text-align: center;
  }
`

export const LoaderWrapper = styled(FlexColumn)`
  top: 0;
  left: 0;
  height: 90vh;
  width: 100vw;
  z-index: 3000;
  position: fixed;
  align-items: center;
  justify-content: center;
`
export const CodeCheckFormWrapper = styled(FlexColumnStart)`
  align-self: center;
  gap: 12px;
`

export const CodeFormWrapper = styled.form`
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Subtitle = styled.h3`
  color: ${colors.darkBlue};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
`

export const CodeText = styled.h3`
  color: ${colors.darkGrey};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 208.333% */
`

export const CodeWrapper = styled(FlexRowBetween)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(6, 1fr);
`

export const OneNumberInput = styled.input`
  color: ${colors.darkBlue};
  background-color: ${colors.lightGray};
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 10px 5px 10px 15px;
  width: 35px;
  height: 40px;
  font-size: 16px;
  font-weight: 600px;
  align-self: center;
  justify-self: center;
`
export const StyledTextAuthFooter = styled.p`
  color: ${colors.darkGrey};
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  a {
    color: ${colors.primary};
    font-weight: 700;
    text-decoration: none;
    font-size: 24px;
    &:hover {
      text-decoration: underline;
    }
  }
`
export const CategoriesWrapper = styled(FlexRowWrap)`
  gap: 16px 8px;
  @media (min-width: 480px) {
    justify-content: start;
  }
`
export const BaseWrapper = styled.div`
  width: 500px;
  width: 100%;
  padding: 0 36px;
  @media screen and (width <= ${breakpoints.tabletMin}) {
    padding: 0;
  }
`
export const PaginationWrapper = styled.div`
  margin-top: 32px;

  @media all and (max-width: 800px) {
    margin-bottom: 35px;
  }
`
export const DoubleColumn = styled(FlexRowBetween)`
  gap: 8px;
  width: 100%;
`
export const Table = styled.table`
  width: 100%;
  overflow: auto;
`
export const Th = styled.th`
  text-align: left;
`

export const FilterWrapper = styled(FlexRowBetween)`
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
`
export const FilterSubWrapper = styled(FlexRowBetween)`
  width: 100%;
  align-items: center;
  gap: 8px;
  @media screen and (${devices.tabletMin}) {
    justify-content: end;
  }
`

export const Title = styled.h3`
  font-size: 21px;
  text-align: start;
  width: auto;
  margin: 0;
  padding: 0;
  font-weight: bold;
`

export const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  width: auto;
  margin: 0;
  padding: 0;
  color: #008c8c;
`

export const Desc = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 23.36px;
  width: auto;
  margin: 0;
  padding: 0;
  color: #6e6e70;
`
export const DesktopTitle = styled(Title)`
  display: none;
  @media (${devices.tabletMin}) {
    display: block;
    width: 100%;
  }
`
export const ImageWrapper = styled(FlexRow)`
  width: 100%;
  height: 340px;
  margin-top: 16px;
  border-radius: 11px;
  background-color: ${colors.lightGray};
  overflow: auto;
  & > img {
    width: 100%;
  }
`
