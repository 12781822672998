import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useGetProductsListQuery } from '../entities/productsList/queries'
import { useSectionsGetSectionInfoQuery } from '../entities/sectionList'
import { useCategoriesGetCategoryInfoQuery } from '../entities/sectionList/queries'
import { useDebounce } from '../shared/hooks/use-debounce'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { PaginationComponent } from '../shared/ui-kit/actual/PaginationComponent'
import { AdminProductsTable } from '../shared/ui-kit/actual/components/AdminProductsTable'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  BasePage,
  FlexColumn,
  FlexRowBetween,
  FlexRowGap,
  SectionsPageWrapper,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H1, H5 } from '../shared/ui-kit/titles'

export default function AdminProductsListPage() {
  const params = useParams()
  const sectionId = params['sectionId']
  const categoryId = params['categoryId']
  const { data: sectionData } = useSectionsGetSectionInfoQuery({ id: Number(sectionId) })
  const { data: categoryData } = useCategoriesGetCategoryInfoQuery({ id: Number(categoryId) })
  const { debounceNameTerm, nameTerm, setNameTerm } = useDebounce()
  const handleNameTerm = (
    fullName: string,
    setNameTerm: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setNameTerm(fullName)
    if (page > 0) {
      setPage(0)
    }
  }
  const resetTerms = () => {
    setNameTerm('')
    setPage(0)
  }
  const [itemsPerPage, setItemsPerPage] = useState(24)
  const [page, setPage] = useState(0)
  const { data: productsData, isLoading } = useGetProductsListQuery({
    categoryId: Number(categoryId),
    orderType: 'byDate',
    productNameTerm: debounceNameTerm,
    skip: page * itemsPerPage,
    sortOrder: 'desc',
    take: itemsPerPage,
  })
  const totalPages = useMemo(
    () => (productsData ? Math.ceil(productsData?.totalCount / itemsPerPage) : 1),
    [itemsPerPage, productsData]
  )

  return (
    <BasePage>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <ArrowBackButton />
        <H1>
          Просмотр объявлений в разделе <br />
          {sectionData?.name}
        </H1>
        <Link to={`/admin/archive-products/${categoryId}`}>
          Архив категории <br /> {categoryData?.name}
        </Link>
      </FlexRowBetween>
      <H5 style={{ alignSelf: 'flex-start' }}>Категория: {categoryData?.name}</H5>
      <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15 }}>
        <p style={{ fontSize: 15, margin: 0, padding: 0 }}>
          Всего объявлений в категории с примененным фильтром : {productsData?.totalCount}
        </p>
        {/* <Filter onClick={() => setOpenUserSortModal(true)}>
              <img alt={''} src={'/images/buttons/filter2.png'} />
            </Filter> */}
        <StyledButton onClick={resetTerms} size={'sm'}>
          Сбросить поиск
        </StyledButton>
      </FlexRowBetween>
      <br />
      <FlexRowGap style={{ alignSelf: 'flex-start', margin: '0 0 24px 24px' }}>
        <label htmlFor={'search'}>Поиск по наименованию</label>
        <input
          id={'search'}
          onChange={e => handleNameTerm(e.target.value, setNameTerm)}
          style={{ alignSelf: 'flex-start' }}
          type={'search'}
          value={nameTerm}
        />
      </FlexRowGap>
      <SectionsPageWrapper>
        {!isLoading ? (
          <>
            <AdminProductsTable products={productsData?.items || []} />
            {productsData && (
              <PaginationComponent maxPages={totalPages} pageId={page} setPageId={setPage} />
            )}
          </>
        ) : (
          <FlexColumn style={{ width: '100vw' }}>
            <PageLoader />
          </FlexColumn>
        )}
      </SectionsPageWrapper>
    </BasePage>
  )
}
