import { useForm } from 'react-hook-form'

import { useUpdateProductInfoMutation } from '../../../entities/marketItem/queries'
import { UpdateProductInfoCommand } from '../../../shared/api/generated'

export function useItemInfoEditForm() {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<UpdateProductInfoCommand>({
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const { isLoading, isSuccess, mutate } = useUpdateProductInfoMutation({})

  const createProduct = async (data: UpdateProductInfoCommand) => {
    mutate(data)
  }

  return {
    clearErrors,
    createProduct,
    errors,
    getValues,
    handleSubmit: handleSubmit(createProduct),
    isLoading,
    isSuccess,
    isValid,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  }
}
