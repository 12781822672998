import { ReactNode } from 'react'

import styled from 'styled-components/macro'

import { StyledButton } from '../../projectComponents'
import { Paragraph } from '../Paragraph'

const StyledBtnColumn = styled(StyledButton)`
  flex-direction: column;
  color: #000;
`

export const ButtonColumn = ({
  bottom,
  isHeight,
  onClick,
  top,
}: {
  bottom: ReactNode
  isHeight?: boolean
  onClick?: () => void
  top: ReactNode
}) => (
  <StyledBtnColumn
    onClick={onClick}
    size={'md'}
    style={{ height: isHeight && 60 }}
    variant={'disabled'}
    fullwidth
  >
    <Paragraph style={{ color: '#000', fontSize: '12px', fontWeight: 600 }}>{top}</Paragraph>
    <Paragraph style={{ color: '#000', fontSize: '10px', lineHeight: 'normal', opacity: '.2' }}>
      {bottom}
    </Paragraph>
  </StyledBtnColumn>
)
