import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useParams } from 'react-router-dom'

import { useSendToBuyerMutation } from '../../../../entities/rent/queries'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FlexColumn,
  LoaderWrapper,
  MediumText,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { useSendPhotos } from '../../model/useSendPhotos'
import { AddPhotos } from '../AddPhotos'
import { StepWrapper } from '../StepWrapper'

type Props = {
  back: () => void
  refetch: () => void
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const Step2 = ({ refetch, setStep }: Props) => {
  const { isLoading, isSuccess, mutate } = useSendToBuyerMutation()

  const { arePhotosSelected, handleSubmit, setArePhotosSelected, setImageFiles } = useSendPhotos({
    isSuccess,
    mutate,
    setStep,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 5000)

    return () => clearInterval(interval)
  }, [refetch])

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <StepWrapper>
        <FlexColumn>
          <H3>Продемонстрируйте товар</H3>
          <MediumText style={{ alignSelf: 'flex-start' }}>
            Покажите товар арендатору и дайте возможность провести фото- и видеофиксацию наличия или
            отсутствия дефектов
          </MediumText>
          <AddPhotos
            arePhotosSelected={arePhotosSelected}
            setArePhotosSelected={setArePhotosSelected}
            setImageFiles={setImageFiles}
          />
        </FlexColumn>
        <FlexColumn style={{ gap: '8px' }}>
          <StyledButton onClick={() => setStep(p => p - 1)} variant={'secondary'} fullwidth>
            Назад
          </StyledButton>
          {arePhotosSelected ? (
            <StyledButton disabled={isLoading} onClick={handleSubmit} fullwidth>
              Отправить товар
            </StyledButton>
          ) : (
            <StyledButton variant={'secondary'} disabled fullwidth>
              До отправки сделайте 6 фото
            </StyledButton>
          )}
        </FlexColumn>
      </StepWrapper>
    </>
  )
}
