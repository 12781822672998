import { Link, useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { ProfileHeader } from '../../../../shared/ui-kit/actual/ProfileHeader'
import { HeaderTitle, StyledButton } from '../../../../shared/ui-kit/projectComponents'

const StyledResetButton = styled.button`
  text-decoration: underline;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #008c8c;
`

const FilterCategoriesHeader = () => {
  const navigate = useNavigate()
  const onBackPageHandler = () => navigate(-1)

  return (
    <ProfileHeader
      left={
        <StyledButton
          onClick={onBackPageHandler}
          style={{ padding: '0', width: 'fit-content' }}
          variant={'neutral'}
          isIcoBtn
        >
          <img alt={'Go back'} src={'/images/buttons/arrow-left.svg'} />
        </StyledButton>
      }
      center={<HeaderTitle>Фильтры</HeaderTitle>}
      right={<StyledResetButton>Сбросить</StyledResetButton>}
    />
  )
}

export default FilterCategoriesHeader
