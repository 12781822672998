import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { useGetRentInfoQuery, useRentsArriveToBuyerMutation } from '../../entities/rent/queries'
import { useSendPhotos } from '../../features/delivery/model/useSendPhotos'
import { Step1 } from '../../features/delivery/ui/receive/Step1'
import { Step2 } from '../../features/delivery/ui/receive/Step2'
import { Step3 } from '../../features/delivery/ui/receive/Step3'
import { Step4 } from '../../features/delivery/ui/receive/Step4'
import { Step5 } from '../../features/delivery/ui/receive/Step5'
import { Step6 } from '../../features/delivery/ui/receive/Step6'
import { Step7 } from '../../features/delivery/ui/receive/Step7'
import { Step8 } from '../../features/delivery/ui/receive/Step8'
import { Step9 } from '../../features/delivery/ui/receive/Step9'
import { Step10 } from '../../features/delivery/ui/receive/Step10'
import { Step11 } from '../../features/delivery/ui/receive/Step11'
import { Step12 } from '../../features/delivery/ui/receive/Step12'
import { Step13 } from '../../features/delivery/ui/receive/Step13'
import { ProfileRentHeader } from '../../features/profieRent/ui/head/ProfileRentHeader'
import { StepsRow } from '../../shared/ui-kit/actual/StepsRow'
import { NotModal } from '../../shared/ui-kit/actual/modals/NotModal'
import { PageLoader } from '../../shared/ui-kit/deprecated/PageLoader'
import { LoaderWrapper } from '../../shared/ui-kit/projectComponents'

const DeliveryReceivePage = () => {
  const [step, setStep] = useState(0)
  const navigate = useNavigate()
  const back = () => {
    navigate('/')
  }
  const { rentId } = useParams()
  const { data, isLoading, isSuccess, refetch } = useGetRentInfoQuery(rentId)

  useEffect(() => {
    if (
      isSuccess &&
      (data?.status === 'cancelledBySeller' ||
        data?.status === 'cancelledByBuyer' ||
        data?.status === 'rejectedBySeller')
    ) {
      setStep(10)
    }
    if (isSuccess && (data?.status === 'created' || data?.status === 'approvedBySeller')) {
      setStep(0)
    }
    if (isSuccess && (data?.status === 'sentToBuyer' || data?.status === 'qrCodeGenerated')) {
      setStep(1)
    }
    if (isSuccess && data?.status === 'qrCodeValidated') {
      setStep(2)
    }
    if (isSuccess && data?.status === 'inUseByBuyer') {
      setStep(4)
    }
    if (isSuccess && data?.status === 'sentBackToSeller') {
      setStep(6)
    }
    if (isSuccess && data?.status === 'completed') {
      setStep(7)
    }
    if (isSuccess && data?.status === 'completedWithClaimByBuyer') {
      setStep(9)
    }
    if (isSuccess && data?.status === 'completedWithClaimBySeller') {
      setStep(11)
    }
  }, [data?.status, isSuccess, navigate])

  const {
    isLoading: isSendPhotosLoading,
    isSuccess: isSendPhotosSuccess,
    mutate,
  } = useRentsArriveToBuyerMutation()

  const { arePhotosSelected, handleSubmit, setArePhotosSelected, setImageFiles } = useSendPhotos({
    isSuccess: isSendPhotosSuccess,
    mutate,
    setStep,
  })

  return (
    <>
      {isLoading ? (
        <LoaderWrapper>
          <PageLoader />
        </LoaderWrapper>
      ) : (
        <>
          <ProfileRentHeader />
          <NotModal>
            <StepsRow activeStep={step} totalSteps={8} />
            {/* <div>
              <button onClick={() => setStep(prev => (prev > 0 ? prev - 1 : 0))}>Prev</button>
              <button onClick={() => setStep(prev => (prev < 12 ? prev + 1 : 12))}>Next</button>
            </div> */}
            {
              [
                <Step1 back={back} key={'step1'} refetch={refetch} rentInfo={data} />,
                <Step2
                  key={'step2'}
                  refetch={refetch}
                  rentId={data?.rentId}
                  sellerId={data?.sellerId}
                />,
                <Step3
                  arePhotosSelected={arePhotosSelected}
                  beforeRentSellerImagesUrls={data?.beforeRentSellerImagesUrls}
                  isLoading={isSendPhotosLoading}
                  key={'step3'}
                  setArePhotosSelected={setArePhotosSelected}
                  setImageFiles={setImageFiles}
                  setStep={setStep}
                />,
                <Step4
                  handleSubmit={handleSubmit}
                  isSendPhotosLoading={isSendPhotosLoading}
                  isSendPhotosSuccess={isSendPhotosSuccess}
                  key={'step4'}
                  rentInfo={data}
                  setStep={setStep}
                />,
                <Step5 key={'step5'} rentInfo={data} setStep={setStep} />,
                <Step6 key={'step6'} rentInfo={data} setStep={setStep} />,
                <Step7 key={'step7'} refetch={refetch} rentInfo={data} />,
                <Step8 key={'step8'} rentInfo={data} setStep={setStep} />,
                <Step9 back={back} key={'step9'} rentInfo={data} />,
                <Step10 back={back} key={'step10'} rentInfo={data} />,
                <Step11 back={back} key={'step11'} status={data?.status} />,
                <Step12 back={back} key={'step12'} rentInfo={data} />,
                <Step13 key={'step8'} rentInfo={data} setStep={setStep} />,
              ][step]
            }
          </NotModal>
        </>
      )}
    </>
  )
}

export default observer(DeliveryReceivePage)
