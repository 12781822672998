import React from 'react'

import styled from 'styled-components/macro'

import {
  FlexColumn,
  FlexColumnBetween,
  MediumTextL,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../shared/ui-kit/titles'
import { PassportAddStepStatus } from './PersonalDataPassport'

const Wrapper = styled(FlexColumnBetween)`
  height: 100%;
`
const MessageWrapper = styled(FlexColumn)`
  flex-grow: 1;
  row-gap: 8px;
`

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<PassportAddStepStatus>>
  setViewModal: React.Dispatch<React.SetStateAction<boolean>>
  status: PassportAddStepStatus
}

const SuccessMessage = () => (
  <>
    <img
      alt={''}
      height={40}
      src={'/images/buttons/check-white-on-bg.svg'}
      style={{ marginBottom: '8px' }}
      width={40}
    />
    <H2>Успешно!</H2>
    <MediumTextL>Документы загружены в профиль</MediumTextL>
  </>
)
const ErrorMessage = () => (
  <>
    <img
      alt={''}
      height={40}
      src={'/images/buttons/reject.svg'}
      style={{ marginBottom: '8px' }}
      width={40}
    />
    <H2>Ошибка загрузки документов</H2>
    <MediumTextL style={{ textAlign: 'center' }}>
      Попробуйте загрузить документы еще раз. Проверьте формат файлов
    </MediumTextL>
  </>
)

export const DocsBusinessAddStatus = ({ setStatus, setViewModal, status }: Props) => {
  const handleClick = () => {
    setViewModal(false)
    setTimeout(() => setStatus('edit'), 500)
  }

  return (
    <Wrapper>
      <MessageWrapper>
        {status === 'success' ? <SuccessMessage /> : <ErrorMessage />}
      </MessageWrapper>
      <br />
      <StyledButton onClick={handleClick} fullwidth>
        Подтвердить
      </StyledButton>
    </Wrapper>
  )
}
