import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useGetUsersListProfileQuery } from '../../../entities/userProfile/queries'
type Props = {
  emailTerm: string
  fullNameTerm: string
  phoneTerm: string
  userNameTerm: string
}
export const useUsersPagination = ({ emailTerm, fullNameTerm, phoneTerm, userNameTerm }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(searchParams.get('page')) || 0
  const setPage = (page: number) => {
    setSearchParams({ page: String(page) })
  }
  const itemsPerPage = 10
  const {
    data: allUsers,
    isLoading: isLoadingAllUsers,
    refetch,
  } = useGetUsersListProfileQuery({
    emailTerm,
    fullNameTerm,
    phoneTerm,
    skip: page * itemsPerPage,
    take: itemsPerPage,
    userNameTerm,
  })
  const totalPages = useMemo(
    () => (allUsers ? Math.ceil(allUsers?.totalCount / itemsPerPage) : 1),
    [allUsers, itemsPerPage]
  )

  return { allUsers, isLoadingAllUsers, itemsPerPage, page, refetch, setPage, totalPages }
}
