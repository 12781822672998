import { createContext } from 'react'

import { makeAutoObservable } from 'mobx'

import { SectionListItemDto } from '../../api/generated'

class SectionStorage {
  sections: SectionListItemDto[] = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setSection(newSections: SectionListItemDto[]): void {
    this.sections = newSections
    this.sections.sort((a, b) => a.id - b.id)
  }
}

export default createContext(new SectionStorage())
