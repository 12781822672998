import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { toast } from 'react-custom-alert'
import { useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { usePostNewMessage } from '../../../../entities/message/queries'
import { PostApiChatsSendChatMessageBody } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import { breakpoints } from '../../../../shared/ui-kit/breakPoints'
import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import { StyledButton } from '../../../../shared/ui-kit/projectComponents'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.lightGray};
  border-radius: 10px;
  padding: 16px 20px;
  margin: 16px auto;
  width: 93%;
  max-height: 208;

  /* @media all and (max-width: ${breakpoints.desktopMin}) {
    width: 360px;
  } */
`
const Paragraph = styled.p`
  width: 100%;
  margin: 0;
  outline: none;
  padding: 5px;
`

const SendMessageBtns = styled(StyledButton)`
  background: none;
  width: 24px;
  padding: 0;
  height: auto;
`

const SendPhotoImg = styled.img`
  cursor: pointer;
  margin-right: 8px;
`

const PreviewImg = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
`

const SendMessageImg = styled(SendPhotoImg)``

const MessageInputBar = ({ connectionIsLoading }) => {
  const [file, setFile] = useState<File | null>(null)
  const { rentId } = useParams()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const pRef = useRef<HTMLParagraphElement>(null)
  const [previewUrl, setPreviewUrl] = useState<null | string>(null)
  const approveBySellerMutation = usePostNewMessage()

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]

    setFile(selectedFile)
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile)

      setPreviewUrl(imageUrl)
    }
  }

  const sendMessage = (message: string) => {
    if (message.trim().length < 1 && !file) {
      return toast.error('Введите сообщение')
    }
    if (message.trim().length < 1) {
      message = `📷`
    }
    const messageData: PostApiChatsSendChatMessageBody = {
      ChatId: Number(rentId),
    }

    if (file) {
      messageData.Files = [file]
    }

    if (message) {
      messageData.Message = message
    }

    approveBySellerMutation.mutate(messageData)
    pRef.current.innerText = ''
    setFile(null)
    setPreviewUrl(null)
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLParagraphElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (connectionIsLoading) {
        return toast.info('Подключение к чату')
      }
      event.preventDefault()
      sendMessage(pRef.current?.innerText || '')
    }
  }

  return (
    <Wrapper>
      {previewUrl && <PreviewImg alt={'Preview'} src={previewUrl} />}
      <label htmlFor={'file'}>
        <SendMessageBtns onClick={handleIconClick}>
          <SendPhotoImg src={'/images/chat/camera.svg'} />
        </SendMessageBtns>
        <input
          accept={'image/*'}
          id={'file'}
          multiple={false}
          onChange={handleFileChange}
          ref={fileInputRef}
          type={'file'}
          hidden
        />
      </label>
      <Paragraph onKeyPress={handleKeyPress} ref={pRef} contentEditable />
      {connectionIsLoading ? (
        <PageLoader height={30} width={30} />
      ) : (
        <SendMessageBtns onClick={() => sendMessage(pRef.current?.innerText || '')} type={'submit'}>
          <SendMessageImg src={'/images/buttons/send-message.svg'} />
        </SendMessageBtns>
      )}
    </Wrapper>
  )
}

export default MessageInputBar
