import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetUserProfileQuery,
  GetUserProfileResponse,
  GetUsersListQuery,
  postApiUsersGetUserProfile,
  postApiUsersGetUsersList,
} from '../../shared/api/generated'
import { queryClient } from '../../shared/api/query-client'
import { round100 } from '../../shared/utils/round100'

export function useGetUserProfileQuery(
  userId: string,
  options?: UseQueryOptions<unknown, unknown, GetUserProfileResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      console.error(e.response?.data?.message || 'Ошибка загрузки профиля пользователя')
    },
    onSettled(data, error) {
      if (data && !error) {
        const transformedData = {
          ...data,
          buyerRating: round100(data.buyerRating),
          sellerRating: round100(data.sellerRating),
        }

        queryClient.setQueryData(['get_user_profile_data', userId], transformedData)
      }
    },
    queryFn: () => postApiUsersGetUserProfile({ userId }),
    queryKey: ['get_user_profile_data', userId],
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetUserProfileMutation(
  options?: UseMutationOptions<GetUserProfileResponse, unknown, GetUserProfileQuery, unknown>
) {
  return useMutation({
    mutationFn: (data: GetUserProfileQuery) => {
      return postApiUsersGetUserProfile(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      console.error(e.response?.data?.message || 'Ошибка загрузки профиля пользователя')
    },
    ...options,
  })
}

export function useGetUsersListProfileQuery(usersListQuery: GetUsersListQuery) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      console.error(e.response?.data?.message || 'Ошибка загрузки списка пользователей')
    },
    queryFn: () => postApiUsersGetUsersList(usersListQuery),
    queryKey: ['get_all_users_list', usersListQuery],
    refetchOnWindowFocus: false,
  })
}
