import styled from 'styled-components/macro'

import { useSubscribe, useUnSubscribe } from '../../../../entities/subscribe'
import { useGetUserProfileQuery } from '../../../../entities/userProfile/queries'
import { AccountType, GetUserProfileResponse } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import {
  AvatarLarge,
  BoldNumber,
  FlexColumn,
  OpacityText,
  RatingWrapper,
  RelativeWrapper,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import getAvgRating from '../../../../shared/utils/getAvgRating'
import { useProfileInfo } from '../../model/use-profile-info'
import { useRoleType } from '../../model/use-role-type'

const StyledHeader = styled.h3`
  max-width: 272px;
  color: ${colors.black};
  font-size: 21px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin: 0;
`

const SubHeader = styled(StyledHeader)`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`

const UserNameWrapper = styled(FlexColumn)`
  gap: 8px;
  margin-top: 32px;
  color: #fff;
  margin-bottom: 20px;
`

const SubscribersWrapper = styled.div<{ $activeTab: number }>`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(2, 20px);
  column-gap: 50px;
  justify-items: center;
  transition: 0.2s ease-out;
  height: ${({ $activeTab }) => ($activeTab ? '40px' : '0')};
  transform: ${({ $activeTab }) => ($activeTab ? 'none' : 'scaleY(0%)')};
`

type Props = {
  aboutItself: string
  accountType: AccountType
  activeTab: number
  avatarLink: string
  buyerRating: number
  fullName: string
  isSubscribed: boolean
  sellerRating: number
  setViewModal: (viewModal: boolean) => void
  subscribersCount: number
  subscriptionsCount: number
  userId: string
}

export const UserGoodsHeader = ({
  aboutItself,
  accountType,
  activeTab,
  avatarLink,
  buyerRating,
  fullName,
  isSubscribed,
  sellerRating,
  setViewModal,
  subscribersCount,
  subscriptionsCount,
  userId,
}: Props) => {
  const avgRating = getAvgRating(buyerRating, sellerRating)
  const { userInfo } = useProfileInfo()
  const subscribed = isSubscribed
  const subscribeMutation = useSubscribe()
  const unSubscribeMutation = useUnSubscribe()
  const subscribe = () =>
    !subscribed ? subscribeMutation.mutate({ userId }) : unSubscribeMutation.mutate({ userId })
  const iThisUser = userId === userInfo?.id

  return (
    <FlexColumn>
      <RelativeWrapper>
        <AvatarLarge src={avatarLink || '/images/bottom-menu/profile-light.svg'} />
        <RatingWrapper onClick={() => setViewModal(true)} roleType={accountType}>
          <img alt={''} src={'/images/interface/whiteStar.svg'} />
          <span>{avgRating.toFixed(1)}</span>
        </RatingWrapper>
      </RelativeWrapper>

      <UserNameWrapper>
        <StyledHeader>{fullName}</StyledHeader>
        <SubHeader>{aboutItself}</SubHeader>
      </UserNameWrapper>
      <SubscribersWrapper $activeTab={activeTab}>
        <BoldNumber>{subscribersCount}</BoldNumber>
        <BoldNumber>{subscriptionsCount}</BoldNumber>
        <OpacityText>Подписчиков</OpacityText>
        <OpacityText>Подписок</OpacityText>
      </SubscribersWrapper>
      {!iThisUser && (
        <StyledButton
          disabled={subscribeMutation.isLoading || unSubscribeMutation.isLoading}
          onClick={subscribe}
          style={{ marginTop: '16px' }}
          fullwidth
        >
          {subscribed ? 'Отменить подписку' : 'Подписаться'}
        </StyledButton>
      )}
    </FlexColumn>
  )
}
