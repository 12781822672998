import { Dropdown } from '../../../../shared/ui-kit/actual/Dropdown'
import { TagsFieldNew } from '../../../../shared/ui-kit/actual/TagsFieldNew'

export const CategoryAndTagsSelect = ({
  categories,
  parentCategoryId,
  sections,
  selectedCategoryId,
  selectedId,
  selectedSubCategoryId,
  selectedTags,
  setOneDayRequired,
  setParentCategoryId,
  setSectionId,
  setSelectedCategoryId,
  setSelectedId,
  setSelectedSubCategoryId,
  setSelectedTags,
  setValue,
  subCategories,
  tags,
}) => {
  return (
    <>
      <Dropdown
        handleChangeValue={e => {
          setSectionId(e.key)
          if (e.key === 16) {
            setOneDayRequired(false)
          } else {
            setOneDayRequired(true)
          }
          setSelectedCategoryId(0)
          setSelectedSubCategoryId(0)
          setValue('CategoryId', 0)
        }}
        handleChange={setSelectedId}
        items={[{ key: 0, value: '-- выбор раздела --' }, ...sections]}
        label={'Раздел'}
        selectedId={selectedId}
        size={'lg'}
        style={{ zIndex: 1300 }}
        fullwidth
      />
      <Dropdown
        handleChangeValue={e => {
          setSelectedSubCategoryId(0)
          if (e.hasChildCategories) {
            setParentCategoryId(e.key)
            setValue('CategoryId', 0)
          } else {
            setValue('CategoryId', e.key)
            setParentCategoryId(0)
          }
        }}
        handleChange={setSelectedCategoryId}
        items={[{ key: 0, value: '-- выбор категории --' }, ...categories]}
        label={'Категория'}
        selectedId={selectedCategoryId}
        size={'lg'}
        style={{ zIndex: 1200 }}
        fullwidth
      />
      {subCategories.length > 0 && parentCategoryId !== 0 && (
        <Dropdown
          handleChangeValue={e => {
            setValue('CategoryId', e.key)
          }}
          handleChange={setSelectedSubCategoryId}
          items={[{ key: 0, value: '-- выбор подкатегории --' }, ...subCategories]}
          label={'Подкатегория'}
          selectedId={selectedSubCategoryId}
          size={'lg'}
          style={{ zIndex: 1100 }}
          fullwidth
        />
      )}
      <TagsFieldNew selectedTags={selectedTags} setSelectedTags={setSelectedTags} tags={tags} />
    </>
  )
}
