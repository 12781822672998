import styled from 'styled-components/macro'

import { GetProductInfoResponse } from '../../../shared/api/generated'
import { colors } from '../../../shared/constants/colors'
import { useModal } from '../../../shared/hooks/useModal'
import { ArrowBackButton } from '../../../shared/ui-kit/actual/ArrowBackButton'
import { BlockTitle } from '../../../shared/ui-kit/actual/InfoButton'
import { Paragraph } from '../../../shared/ui-kit/actual/Paragraph'
import { ProfileHeader } from '../../../shared/ui-kit/actual/ProfileHeader'
import { SliderComponent } from '../../../shared/ui-kit/actual/Slider'
import { LineBreaks } from '../../../shared/ui-kit/actual/components/LineBreaks'
import { LocationInfo } from '../../../shared/ui-kit/actual/components/LocationInfo'
import {
  FlexColumn,
  FlexColumnBetween,
  FlexColumnStart,
  FlexRowBetween,
  MediumTextL,
  StyledButton,
} from '../../../shared/ui-kit/projectComponents'
import { H1, H5 } from '../../../shared/ui-kit/titles'
import { ItemAddressEdit } from './editForms/ItemAddressEdit'
import { ItemInfoEdit } from './editForms/ItemInfoEdit'
import { ItemPricesEdit } from './editForms/ItemPricesEdit'

const Page = styled(FlexColumn)`
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 24px;
  height: 100vh;
  justify-content: start;
  align-items: start;

  @media (min-width: 700px) {
    width: 600px;
    height: auto;
    min-height: 100vh;
    align-self: center;
    align-items: center;
  }
`

const Wrapper = styled(FlexColumn)`
  width: 100%;

  @media (min-width: 280px) {
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px;
    padding-bottom: 36px;
  }
  @media (min-width: 1100px) {
    justify-content: center;
    padding: 0px;
  }
`

const SubTitle = styled.h6`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #008c8c;
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
`

const TableWrapper = styled.div`
  width: -webkit-fill-available;
  padding: 18px 20px;
  background-color: #f8f8fa;
  border-radius: 10px;
`

const PartWrapper = styled(FlexColumnStart)`
  width: 100%;
  margin: 24px 0;
  padding: 16px;
  border-radius: 15px;
  border: solid 2px ${colors.primary};
`

interface ItemComponentProps {
  productInfo: GetProductInfoResponse
}

export const ItemEditView = ({ productInfo }: ItemComponentProps) => {
  const { setViewModal, viewModal } = useModal()
  const { setViewModal: setViewAddressModal, viewModal: viewAddressModal } = useModal()
  const { setViewModal: setViewPricesEdit, viewModal: viewPricesEdit } = useModal()

  // TODO: добавить загрузку циклов аренды как появится на бэке
  // TODO: вернуть количество товаров на платформе и количество времени на платформе когда появится на бэке

  return (
    <>
      <Page>
        <Wrapper>
          <ProfileHeader
            center={<H1>Редактировать объявление</H1>}
            left={<ArrowBackButton link={'/profile'} />}
          />
          <SliderComponent
            items={productInfo?.imagesUrls?.map((image, index) => (
              <img
                alt={'product'}
                height={425}
                key={index}
                src={image}
                style={{ objectFit: 'contain' }}
                width={'100%'}
              />
            ))}
            slidesToShow={1}
            dots
          />
          <PartWrapper>
            <H5>Наименование</H5>
            <Paragraph style={{ width: '100%' }}>{productInfo?.name}</Paragraph>
            <br />
            <H5>О товаре</H5>
            <Paragraph style={{ width: '100%' }}>
              <LineBreaks>{productInfo?.description}</LineBreaks>
            </Paragraph>
            <br />
            <StyledButton
              onClick={() => setViewModal(true)}
              style={{ alignSelf: 'flex-end' }}
              variant={'secondary'}
            >
              Редактировать
            </StyledButton>
          </PartWrapper>

          <PartWrapper>
            <FlexRowBetween style={{ alignItems: 'center' }}>
              <Paragraph>Залог {productInfo?.pledge}₽</Paragraph>
              <SubTitle>{productInfo?.dayPrice}₽ в день</SubTitle>
            </FlexRowBetween>

            <br />
            <TableWrapper>
              <Paragraph style={{ marginBottom: '16px' }}>
                В данной таблице указаны варианты аренды и цены
              </Paragraph>
              <FlexColumnBetween style={{ gap: '8px' }}>
                <FlexRowBetween style={{ alignItems: 'center' }}>
                  {' '}
                  <Paragraph style={{ color: colors.black }}>1 день</Paragraph>{' '}
                  <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                    {productInfo?.dayPrice} ₽
                  </Paragraph>
                </FlexRowBetween>
                <FlexRowBetween style={{ alignItems: 'center' }}>
                  {' '}
                  <Paragraph style={{ color: colors.black }}>3 дня</Paragraph>{' '}
                  <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                    {productInfo?.threeDaysPrice} ₽
                  </Paragraph>
                </FlexRowBetween>
                <FlexRowBetween style={{ alignItems: 'center' }}>
                  {' '}
                  <Paragraph style={{ color: colors.black }}>Неделя</Paragraph>{' '}
                  <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                    {productInfo?.weekPrice} ₽
                  </Paragraph>
                </FlexRowBetween>
                <FlexRowBetween style={{ alignItems: 'center' }}>
                  {' '}
                  <Paragraph style={{ color: colors.black }}>Месяц</Paragraph>{' '}
                  <Paragraph style={{ color: '#008C8C', fontWeight: 600 }}>
                    {productInfo?.monthPrice} ₽
                  </Paragraph>
                </FlexRowBetween>
              </FlexColumnBetween>
            </TableWrapper>
            <br />
            <StyledButton
              onClick={() => setViewPricesEdit(true)}
              style={{ alignSelf: 'flex-end' }}
              variant={'secondary'}
            >
              Редактировать
            </StyledButton>
          </PartWrapper>
          <br />
          <TableWrapper>
            <Paragraph style={{ marginBottom: '16px' }}>
              В данной таблице указаны штрафы за дефекты
            </Paragraph>
            <>
              <FlexColumnBetween style={{ gap: '8px' }}>
                {productInfo?.defectFines?.map((defect, idx) => (
                  <FlexRowBetween key={idx} style={{ alignItems: 'center' }}>
                    {' '}
                    <Paragraph style={{ color: colors.black, maxWidth: '85%' }}>
                      {defect.defectName}
                    </Paragraph>{' '}
                    <Paragraph
                      style={{
                        color: colors.primary,
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        width: 'max-content',
                      }}
                    >
                      {defect.amount} ₽
                    </Paragraph>
                  </FlexRowBetween>
                ))}
              </FlexColumnBetween>
            </>
          </TableWrapper>

          <PartWrapper>
            <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
              Доставка
            </BlockTitle>
            <div style={{ alignSelf: 'flex-start' }}>
              {productInfo?.deliveryType === 'countryWithSelection' && (
                <FlexColumnStart>
                  <Paragraph>В городах: </Paragraph>
                  <MediumTextL>{productInfo.deliveryCities.join(', ')}</MediumTextL>
                </FlexColumnStart>
              )}
              {productInfo?.deliveryType === 'russiaAny' && (
                <FlexRowBetween>
                  <Paragraph>В пределах РФ</Paragraph>
                </FlexRowBetween>
              )}
              {productInfo?.deliveryType === 'notAvailable' && (
                <FlexRowBetween>
                  <Paragraph>Варианты доставки не указаны арендодателем</Paragraph>
                </FlexRowBetween>
              )}
            </div>
            <BlockTitle style={{ marginBottom: '8px', width: '100%' }} topSpace={'xl'}>
              Самовывоз
            </BlockTitle>
            {productInfo?.pickupAddress ? (
              <LocationInfo pickupAddress={productInfo.pickupAddress} />
            ) : (
              <Paragraph style={{ alignSelf: 'flex-start' }}>
                Адрес самовывоза не указан арендодателем
              </Paragraph>
            )}
            <br />
            <StyledButton
              onClick={() => setViewAddressModal(true)}
              style={{ alignSelf: 'flex-end' }}
              variant={'secondary'}
            >
              Редактировать
            </StyledButton>
          </PartWrapper>
        </Wrapper>
      </Page>
      <ItemInfoEdit
        categoryId={productInfo.categoryId}
        description={productInfo.description}
        id={productInfo.id}
        name={productInfo.name}
        setViewModal={setViewModal}
        viewModal={viewModal}
      />
      <ItemAddressEdit
        categoryId={productInfo.categoryId}
        deliveryCities={productInfo.deliveryCities}
        deliveryPrice={productInfo.deliveryPrice}
        deliveryType={productInfo.deliveryType}
        id={productInfo.id}
        name={productInfo.name}
        pickupAddressId={productInfo.pickupAddress?.id}
        setViewModal={setViewAddressModal}
        viewModal={viewAddressModal}
      />
      <ItemPricesEdit
        dayPrice={productInfo.dayPrice}
        id={productInfo.id}
        monthPrice={productInfo.monthPrice}
        pledge={productInfo.pledge}
        setViewModal={setViewPricesEdit}
        threeDaysPrice={productInfo.threeDaysPrice}
        viewModal={viewPricesEdit}
        weekPrice={productInfo.weekPrice}
      />
    </>
  )
}
