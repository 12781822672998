import { useEffect, useState } from 'react'

import {
  useUsersGetUserInfoQuery,
  useUsersUpdateUserAvatarMutation,
} from '../../../../entities/ownProfile/queries'
import { ArrowBackButton } from '../../../../shared/ui-kit/actual/ArrowBackButton'
import { InputImageControlled } from '../../../../shared/ui-kit/actual/InputImageControlled'
import { ProfileHeader } from '../../../../shared/ui-kit/actual/ProfileHeader'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { MainHeader } from '../../../../shared/ui-kit/actual/headers/MainHeader'
import {
  DesktopView,
  FileInputButton,
  FlexColumn,
  LoginText,
  MobileTabletView,
  RelativeWrapper,
} from '../../../../shared/ui-kit/projectComponents'
import { shortString } from '../../../../shared/utils/truncate'
import { useProfileInfo } from '../../../profile/model/use-profile-info'

export const ProfilePersonalDataHeader = () => {
  const [abort, setAbort] = useState(false)
  const { getUserInfo, userId, userInfo } = useProfileInfo()

  const { data } = useUsersGetUserInfoQuery(userId)
  const { isLoading, isSuccess, mutate } = useUsersUpdateUserAvatarMutation()
  const handleChangeImage = (file: Blob) => {
    mutate({ avatarImage: file, userId: userInfo.id })
  }
  const [imageUrl, setImageUrl] = useState(userInfo?.profileImageUrl)
  const handleUrlChange = (url: string) => setImageUrl(url)

  useEffect(() => {
    !abort && isSuccess && getUserInfo
    setAbort(true)
  }, [abort, getUserInfo, isSuccess, userInfo.id])

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <DesktopView>
        <MainHeader />
        <br />
      </DesktopView>
      <MobileTabletView>
        <ProfileHeader
          center={<LoginText>@{shortString(data?.accountName, 20)}</LoginText>}
          left={<ArrowBackButton />}
        />
      </MobileTabletView>

      <FlexColumn style={{ margin: '24px 115px 0', position: 'relative' }}>
        <RelativeWrapper>
          <InputImageControlled
            defaultImageUrl={data?.profileImageUrl}
            handleChange={handleChangeImage}
            handleUrlChange={handleUrlChange}
            parentImageUrl={isLoading ? null : imageUrl || data?.profileImageUrl}
            variant={'square'}
          />
          <FileInputButton>
            <InputImageControlled
              defaultImageNode={<img alt={''} src={'/images/buttons/Edit.svg'} />}
              handleChange={handleChangeImage}
              handleUrlChange={handleUrlChange}
              variant={'circle'}
            />
          </FileInputButton>
        </RelativeWrapper>
      </FlexColumn>
    </>
  )
}
