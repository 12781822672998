import { createContext } from 'react'

import { action, autorun, computed, makeAutoObservable } from 'mobx'

import { GetUserInfoResponse, GetUserProfileResponse } from '../../api/generated'
import { round100 } from '../../utils/round100'

const initialUserInfo: GetUserInfoResponse = {}
const initialProfile: GetUserProfileResponse = {}

class ProfileStorage {
  profile: GetUserProfileResponse = localStorage.profile
    ? JSON.parse(localStorage.profile)
    : initialProfile

  userInfo: GetUserInfoResponse = localStorage.userInfo
    ? JSON.parse(localStorage.userInfo)
    : initialUserInfo

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    autorun(() => {
      localStorage.userInfo = JSON.stringify(this.userInfo)
      localStorage.profile = JSON.stringify(this.profile)
    })
  }

  @action
  clear() {
    this.userInfo = initialUserInfo
    this.profile = initialProfile
  }

  @computed getProfile() {
    return this.profile
  }

  @computed getUserInfo() {
    return this.userInfo
  }

  @action setProfile(data: GetUserProfileResponse) {
    const newProfile = {
      ...data,
      buyerRating: round100(data.buyerRating),
      sellerRating: round100(data.sellerRating),
    }

    this.profile = newProfile
  }

  @action setUserInfo(newUserInfo: GetUserInfoResponse) {
    this.userInfo = newUserInfo
  }
}

export default createContext(new ProfileStorage())
