import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { isThisHour } from 'date-fns'
import styled from 'styled-components/macro'

import { useSubscribe, useUnSubscribe } from '../../../../entities/subscribe'
import { useGetUserProfileQuery } from '../../../../entities/userProfile/queries'
import { colors } from '../../../../shared/constants/colors'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import { StyledButton } from '../../../../shared/ui-kit/actual/StyledButton'
import {
  AvatarLarge,
  BoldNumber,
  BtnRowWrapper,
  BtnWrapper,
  FlexColumn,
  NeutralLink,
  OpacityText,
  RatingWrapper,
  RelativeWrapper,
} from '../../../../shared/ui-kit/projectComponents'
import getAvgRating from '../../../../shared/utils/getAvgRating'

const StyledHeader = styled.h3`
  max-width: 272px;
  color: ${colors.black};
  font-size: 21px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin: 0;
`

const SubHeader = styled(StyledHeader)`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`

const UserNameWrapper = styled(FlexColumn)`
  gap: 8px;
  margin-top: 32px;
  color: #fff;
  margin-bottom: 20px;
`

const SubscribersWrapper = styled.div<{ $activeTab: number }>`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(2, 20px);
  column-gap: 50px;
  justify-items: center;
  transition: 0.2s ease-out;
  height: ${({ $activeTab }) => ($activeTab ? '40px' : '0')};
  transform: ${({ $activeTab }) => ($activeTab ? 'none' : 'scaleY(0%)')};
`

type BlogDesktopHeaderProps = {
  authorId?: string
  handleOpenDeleteModal?: () => void
  isArticlesPage?: boolean
  isDeleteLoading?: boolean
  setArticleModal: (viewModal: boolean) => void
  setViewModal: (viewModal: boolean) => void
}

export const BlogDesktopHeader = ({
  authorId,
  handleOpenDeleteModal,
  isArticlesPage,
  isDeleteLoading,
  setArticleModal,
  setViewModal,
}: BlogDesktopHeaderProps) => {
  const { data: authorData } = useGetUserProfileQuery(authorId)
  const ProfileStore = useContext(ProfileStorage)
  const profile = ProfileStore.getProfile()
  const isArticle = authorData?.id !== profile.id
  const sellerRating = (isArticle ? authorData?.sellerRating : profile?.sellerRating) || 0
  const buyerRating = (isArticle ? authorData?.buyerRating : profile?.buyerRating) || 0
  const avgRating = getAvgRating(buyerRating, sellerRating)
  const profileInfoExtended = isArticle ? authorData : profile

  const subscribed = authorData?.isSubscribed

  const subscribeMutation = useSubscribe()
  const unSubscribeMutation = useUnSubscribe()
  const subscribe = () =>
    !subscribed
      ? subscribeMutation.mutate({ userId: authorData?.id })
      : unSubscribeMutation.mutate({ userId: authorData?.id })
  const subscribeButtonAction = () => (isArticle ? subscribe() : setArticleModal(true))
  const subscriptionText = subscribed ? 'Отменить подписку' : 'Подписаться'
  const iThisUser = authorData?.id === profile?.id

  return (
    <FlexColumn style={{ minWidth: '290px' }}>
      <RelativeWrapper>
        <NeutralLink to={`/users/${authorId}`}>
          <AvatarLarge
            src={profileInfoExtended?.avatarLink || '/images/bottom-menu/profile-light.svg'}
          />
        </NeutralLink>
        <RatingWrapper
          onClick={() => setViewModal(true)}
          roleType={profileInfoExtended?.accountType}
        >
          <img alt={''} src={'/images/interface/whiteStar.svg'} />
          <span>{avgRating?.toFixed(1)}</span>
        </RatingWrapper>
      </RelativeWrapper>
      <UserNameWrapper>
        <StyledHeader>{profileInfoExtended?.fullName}</StyledHeader>
        <SubHeader>{profileInfoExtended?.aboutItself}</SubHeader>
      </UserNameWrapper>
      <SubscribersWrapper $activeTab={1}>
        <BoldNumber>{profileInfoExtended?.subscribersCount}</BoldNumber>
        <BoldNumber>{profileInfoExtended?.subscriptionsCount}</BoldNumber>
        <OpacityText>Подписчиков</OpacityText>
        <OpacityText>Подписок</OpacityText>
      </SubscribersWrapper>
      <BtnWrapper style={{ marginTop: 16 }}>
        <FlexColumn style={{ gap: '12px', width: '100%' }}>
          <StyledButton
            disabled={subscribeMutation.isLoading || unSubscribeMutation.isLoading}
            onClick={subscribeButtonAction}
            fullwidth
          >
            {!iThisUser ? subscriptionText : 'Новая статья'}
          </StyledButton>
          {iThisUser && !isArticlesPage && (
            <StyledButton
              disabled={isDeleteLoading}
              onClick={handleOpenDeleteModal}
              variant={'warning'}
              fullwidth
            >
              Удалить статью
            </StyledButton>
          )}
          {/* {!isArticle && (
            <StyledBtnSmall onClick={() => navigate('/profile')}>
              <img alt={''} src={'/images/buttons/user-avatar-small-white.svg'} />
            </StyledBtnSmall>
          )} */}
        </FlexColumn>
      </BtnWrapper>
    </FlexColumn>
  )
}
