import { ButtonHTMLAttributes, FC } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'
import { StyledButton } from '../projectComponents'

interface BottomButtonComponentProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 24px 10px;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 -9px 16px 0 rgba(94, 94, 94, 0.07);
`

const BottomButtonComponent: FC<BottomButtonComponentProps> = ({ ...otherProps }) => {
  return (
    <Wrapper>
      <StyledButton {...otherProps} size={'xl'}></StyledButton>
    </Wrapper>
  )
}

export default BottomButtonComponent
