import { useContext, useState } from 'react'
import { toast } from 'react-custom-alert'

import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import { useClipboard } from 'use-clipboard-copy'

import { useGenerateAndGetReferralCodeQuery } from '../../../../entities/ownProfile/queries'
import { colors } from '../../../../shared/constants/colors'
import { copyTextSVG, primaryStarLargeSVG } from '../../../../shared/constants/images'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import { InfoButton } from '../../../../shared/ui-kit/actual/InfoButton'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { devices } from '../../../../shared/ui-kit/breakPoints'
import {
  DesktopView,
  FlexColumn,
  FlexColumnStart,
  MediumText,
  MobileTabletView,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H2, H5 } from '../../../../shared/ui-kit/titles'
import { useProfileInfo } from '../../../profile/model/use-profile-info'
import { PersonalDataAddress } from '../PersonalDataAddress'
import { PersonalDataBusinessDocs } from '../PersonalDataBusinessDocs'
import { PersonalDataBusinessPay } from '../PersonalDataBusinessPay'
import { PersonalDataForm } from '../PersonalDataForm'
import { PersonalDataOperations } from '../PersonalDataOperations'
import PersonalDataPassport from '../PersonalDataPassport'
import { PersonalDataPay } from '../PersonalDataPay'
import { PersonalDataTransactions } from '../PersonalDataTransactions'
import { ProfilePersonalDataAccordion } from './ProfilePersonalDataAccordion'

const DesktopRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (${devices.desktopMin}) {
    flex-direction: row;
    gap: 24px;
    width: 97%;
  }
  @media screen and (width >= 1024px) {
    flex-direction: row;
    gap: 48px;
    width: 85%;
  }
`
const Wrapper = styled(FlexColumn)`
  width: 100%;
  margin-top: 54px;
  box-sizing: border-box;
  padding: 28px 24px 96px 24px;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  gap: 24px;
  justify-content: flex-start;
  @media screen and (${devices.desktopMin}) {
    margin-left: 16px;
    width: 350px;
  }
`

const StyledBtnRow = styled(StyledButton)`
  height: fit-content;
  justify-content: space-between;
  padding-left: 10px;
  background: ${colors.lightGray};
  & > div {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }
`
const PromoWrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 800px);
  background: ${colors.lightGray};
  border-radius: 15px;
  padding: 16px 28px;
  row-gap: 16px;
`
const StyledBtnPromo = styled(StyledButton)`
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
`

const ProfilePersonalDataBody = () => {
  const clipboard1 = useClipboard()
  const handleClick1 = () => {
    clipboard1.copy()
    toast.success('Код скопирован')
  }
  const clipboard2 = useClipboard()
  const handleClick2 = () => {
    clipboard2.copy()
    toast.success('Ссылка скопирована')
  }

  const { userId } = useContext(UserStorage)
  const { data, isLoading } = useGenerateAndGetReferralCodeQuery(userId)
  const [desktopAccordionState, setDesktopAccordionState] = useState('')
  const { userInfo } = useProfileInfo()

  return (
    <>
      <AbsoluteLoader isLoading={isLoading} />
      <DesktopRowWrapper>
        <Wrapper>
          <StyledBtnRow
            style={{ alignItems: 'center', opacity: 1 }}
            variant={'secondary'}
            fullwidth
          >
            <div>
              <img alt={''} src={primaryStarLargeSVG} />
              <div>
                <MobileTabletView>
                  <H5 style={{ textAlign: 'left' }}>Премиум аккаунт</H5>
                </MobileTabletView>
                <DesktopView>
                  <H5>Премиум аккаунт</H5>
                </DesktopView>
                <MediumText>Оформите за 10000₽ в месяц</MediumText>
              </div>
            </div>
            <InfoButton
              content={`Ваша комиссия за сдачу товаров снижается в 2 раза, с 10% до 5% от суммы сделки. Все Ваши объявления будут выделяться цветом на фоне остальных объявлений. Вы сможете присваивать своим объявлениям “премиум статус”, которые смогут видеть пользователи только с премиум аккаунтом. 
          Вы сможете видеть все объявления на платформе, в том числе и объявления с премиум статусом.`}
            />
          </StyledBtnRow>
          <PromoWrapper>
            <div>
              <H5>Пригласите друга</H5>
              <MediumText>Получите 100SH за каждого нового пользователя</MediumText>
            </div>
            <StyledBtnPromo variant={'light'} fullwidth>
              <input
                defaultValue={data?.referralCode}
                ref={clipboard1.target}
                style={{ border: 'none' }}
                readOnly
              />

              <img alt={''} onClick={handleClick1} src={copyTextSVG} />
            </StyledBtnPromo>
            <StyledBtnPromo variant={'light'} fullwidth>
              <input
                defaultValue={
                  data && `https://allsharing.su/register?referral=${data?.referralCode}`
                }
                ref={clipboard2.target}
                style={{ border: 'none', width: '370px' }}
                readOnly
              />
              <img alt={''} onClick={handleClick2} src={copyTextSVG} />
            </StyledBtnPromo>
          </PromoWrapper>
          <ProfilePersonalDataAccordion setDesktopAccordionState={setDesktopAccordionState} />
        </Wrapper>
        <DesktopView style={{ flexGrow: 1 }}>
          {
            {
              'item-1': (
                <>
                  <H2>Личные данные</H2>
                  <br />
                  <PersonalDataForm />
                </>
              ),
              'item-2': (
                <>
                  <H2>Способы оплаты</H2>
                  <br />
                  <>
                    {' '}
                    {userInfo.legalStatus === 'legalEntity' ||
                    userInfo.legalStatus === 'soleProprietor' ? (
                      <PersonalDataBusinessPay />
                    ) : (
                      <PersonalDataPay />
                    )}
                  </>
                </>
              ),
              'item-3': (
                <>
                  <H2>История платежей</H2>
                  <br />
                  <PersonalDataTransactions />
                </>
              ),
              'item-4': (
                <>
                  <H2>История операций</H2>
                  <br />
                  <PersonalDataOperations />
                </>
              ),
              'item-5': (
                <>
                  <H2>Документы</H2>
                  <br />
                  <>
                    {userInfo.legalStatus === 'legalEntity' ||
                    userInfo.legalStatus === 'soleProprietor' ? (
                      <PersonalDataBusinessDocs />
                    ) : (
                      <PersonalDataPassport />
                    )}
                  </>
                </>
              ),
              'item-6': (
                <>
                  <H2>Адреса</H2>
                  <br />
                  <PersonalDataAddress />
                </>
              ),
            }[desktopAccordionState]
          }
        </DesktopView>
      </DesktopRowWrapper>
    </>
  )
}

export default observer(ProfilePersonalDataBody)
