import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'

export const useCodeInput = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refs = {
    r1: useRef(null),
    r2: useRef(null),
    r3: useRef(null),
    r4: useRef(null),
    r5: useRef(null),
    r6: useRef(null),
  }
  const refsReset = () => {
    for (const ref in refs) {
      refs[ref].current.value = null
    }
    refs.r1.current.focus()
  }

  useEffect(() => {
    refs.r1 && refs.r1.current.focus()
  }, [refs.r1])

  const [code, setCode] = useState(['', '', '', '', '', ''])
  const [btnDisabled, setBtnDisabled] = useState(true)

  const onChange = (idx: number, e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value) {
      const nextKey = `r${idx + 2}`

      refs[nextKey]?.current.focus()
    }

    const key = `r${idx + 1}`

    setCode(prev => {
      prev[idx] = refs[key].current.value
      if (prev.every(v => v)) {
        setBtnDisabled(false)
      } else {
        setBtnDisabled(true)
      }

      return prev
    })
  }
  const onKeyDown = (idx: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !e.currentTarget.value && idx !== 0) {
      const nextKey = `r${idx}`

      refs[nextKey]?.current.focus()
    }
    if (e.key === 'ArrowLeft' && !e.currentTarget.value && idx !== 0) {
      const nextKey = `r${idx}`

      refs[nextKey]?.current.focus()
    }
    if (e.key === 'ArrowRight' && !e.currentTarget.value && idx !== 5) {
      const nextKey = `r${idx + 2}`

      refs[nextKey]?.current.focus()
    }
  }

  useEffect(() => {
    const pasteHandler = e => {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')?.trim()

      for (let i = 0; i < 6; i++) {
        refs[`r${i + 1}`].current.value = text[i]
        setCode(prev => {
          prev[i] = text[i]

          return prev
        })
      }
      refs?.['r6'].current?.focus()
      setBtnDisabled(false)
    }

    window.addEventListener('paste', pasteHandler)

    return () => {
      window.removeEventListener('paste', pasteHandler)
    }
  }, [refs])

  return {
    btnDisabled,
    code,
    onChange,
    onKeyDown,
    refs,
    refsReset,
  }
}
