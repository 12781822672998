import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useGetClaimInfoQuery } from '../entities/claims/queries'
import { colors } from '../shared/constants/colors'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { Paragraph } from '../shared/ui-kit/actual/Paragraph'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { AdminApproveClaimModal } from '../shared/ui-kit/actual/components/AdminApproveClaimModal'
import { AdminClaimPhotos } from '../shared/ui-kit/actual/components/AdminClaimPhotos'
import { translatesObj } from '../shared/ui-kit/actual/components/AdminClaimsTable'
import { AdminRejectClaimModal } from '../shared/ui-kit/actual/components/AdminRejectClaimModal'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { Spacer } from '../shared/ui-kit/deprecated/Spacer'
import {
  BasePage,
  FlexColumn,
  FlexColumnBetween,
  FlexRow,
  FlexRowBetween,
  FlexRowGap,
  FlexRowWrap,
  MediumTextBold,
  StyledButton,
} from '../shared/ui-kit/projectComponents'
import { H3, H4 } from '../shared/ui-kit/titles'

const DataElement = styled(FlexColumn)<{ $isWarning?: boolean }>`
  justify-content: start;
  align-items: start;
  align-self: start;
  margin: 5px;
  background-color: ${props => (props.$isWarning ? colors.lightRed : colors.lightGray)};
  border-radius: 15px;
  padding: 10px 15px;
`
const DataTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  margin: 8px 0 4px;
`
const DataInfo = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  text-align: left;
`
const BorderLine = styled.div`
  width: calc(100% - 32px);
  margin: 20px 0;
  border: 0.5px solid ${colors.lightGray};
`

const AdminClaimPage = () => {
  const params = useParams()
  const claimId = params['claimId']
  const { data: claim, isLoading } = useGetClaimInfoQuery({ claimId: +claimId })

  const [rejectModal, setRejectModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)

  return (
    <BasePage>
      <AbsoluteLoader isLoading={isLoading} />
      <MainHeader />
      <div style={{ alignSelf: 'start' }}>
        <br />
        <ArrowBackButton />
      </div>
      {claim && (
        <>
          <FlexRowWrap
            style={{
              alignItems: 'flex-start',
              alignSelf: 'flex-start',
              gap: '24px',
              justifyContent: 'flex-start',
            }}
          >
            <DataElement $isWarning>
              <DataTitle>Заявитель</DataTitle>
              <DataInfo>
                <Link to={`/users/${claim.authorId}`}>{claim.authorFullName}</Link> <br />{' '}
                <Link to={`/admin/users/${claim.authorId}`}>{claim.authorUserName}</Link> <br />{' '}
              </DataInfo>
            </DataElement>
            <DataElement>
              <DataTitle>Объявление</DataTitle>
              <DataInfo>
                <Link to={`/item/${claim.productId}`}>{claim.productId} - обзор</Link> <br />{' '}
                <Link to={`/admin/product/${claim.productId}`}>{claim.productId} - полное</Link>{' '}
                <br />{' '}
              </DataInfo>
            </DataElement>
            <DataElement>
              <DataTitle>Статус</DataTitle>
              <DataInfo>{translatesObj[claim.claimStatus]} </DataInfo>
              <DataInfo>{translatesObj[claim.claimType]} </DataInfo>
            </DataElement>
            <DataElement>
              <DataTitle>Обнаруженные дефекты</DataTitle>
              {claim.selectedDefectFines?.length > 0 && (
                <>
                  <FlexColumnBetween style={{ gap: '8px', marginTop: '16px' }}>
                    {claim.selectedDefectFines.map((defect, idx) => (
                      <FlexRowBetween key={idx} style={{ alignItems: 'center', gap: '24px' }}>
                        <Paragraph style={{ color: colors.black, maxWidth: '85%' }}>
                          {defect.defectName}
                        </Paragraph>{' '}
                        <Paragraph
                          style={{
                            color: colors.primary,
                            fontWeight: 600,
                            whiteSpace: 'nowrap',
                            width: 'max-content',
                          }}
                        >
                          {defect.amount} ₽
                        </Paragraph>
                      </FlexRowBetween>
                    ))}
                  </FlexColumnBetween>
                </>
              )}
            </DataElement>
          </FlexRowWrap>
          <BorderLine />

          <FlexRowWrap
            style={{
              alignItems: 'flex-start',
              alignSelf: 'flex-start',
              gap: '24px',
              justifyContent: 'flex-start',
            }}
          >
            <DataElement $isWarning={claim.buyerId === claim.authorId}>
              <DataTitle>Арендатор {claim.buyerId === claim.authorId && '(заявитель)'} </DataTitle>
              <DataInfo>
                <Link to={`/users/${claim.buyerId}`}>{claim.buyerFullName}</Link> <br />{' '}
                <Link to={`/admin/users/${claim.buyerId}`}>{claim.buyerUserName}</Link> <br />{' '}
                {claim.buyerId}
                {claim.messageByBuyer && (
                  <>
                    <H4>Сообщение: </H4>
                    <MediumTextBold>{claim.messageByBuyer} </MediumTextBold>
                  </>
                )}{' '}
              </DataInfo>
            </DataElement>
            <DataElement $isWarning={claim.sellerId === claim.authorId}>
              <DataTitle>
                Арендодатель {claim.sellerId === claim.authorId && '(Заявитель)'}
              </DataTitle>
              <DataInfo>
                <Link to={`/users/${claim.sellerId}`}>{claim.sellerFullName}</Link> <br />{' '}
                <Link to={`/admin/users/${claim.sellerId}`}>{claim.sellerUserName}</Link> <br />{' '}
                {claim.sellerId}
                {claim.messageBySeller && (
                  <>
                    <H4>Сообщение: </H4>
                    <MediumTextBold>{claim.messageBySeller} </MediumTextBold>
                  </>
                )}
              </DataInfo>
            </DataElement>
          </FlexRowWrap>
          <BorderLine />
          <AdminClaimPhotos rentId={claim.rentId} />
          <BorderLine />
          <FlexRowGap style={{ width: '100%' }}>
            <StyledButton onClick={() => setRejectModal(true)} size={'xl'} variant={'danger'}>
              Отклонить жалобу
            </StyledButton>
            <StyledButton onClick={() => setApproveModal(true)} size={'xl'}>
              Удовлетворить жалобу
            </StyledButton>
          </FlexRowGap>

          <Spacer />
          <AdminRejectClaimModal
            claimId={+claimId}
            rejectModal={rejectModal}
            setOpen={setRejectModal}
          />
          <AdminApproveClaimModal
            approveModal={approveModal}
            claimId={+claimId}
            setOpen={setApproveModal}
          />
        </>
      )}
    </BasePage>
  )
}

export default AdminClaimPage
