import React from 'react'

import styled from 'styled-components/macro'

import { FlexColumn } from '../projectComponents'

const NoProductsContainer = styled(FlexColumn)`
  height: clamp(100px, 50vh, 500px);
  flex: flex;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 800px) {
    flex: none !important;
  }
`

const NoProductsMessage = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #979797;
  margin-top: 12px;
  text-align: center;
`

export const NoProducts = ({
  imgPath,
  message,
  style,
  width,
}: {
  imgPath: string
  message: string
  style?: React.CSSProperties
  width?: number
}) => {
  return (
    <NoProductsContainer style={style}>
      <img alt={''} src={imgPath} width={width} />
      <NoProductsMessage>{message}</NoProductsMessage>
    </NoProductsContainer>
  )
}
