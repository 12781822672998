import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useGetCategoryInfo } from '../../../../entities/categoriesList/queries'
import { useGetSectionInfoMutate } from '../../../../entities/sectionList/queries'
import { ROUTES_TRANSLATE } from '../../../../shared/constants/routesTranslate'
import { usePathnames } from '../../../../shared/hooks/usePathnames'
import { tabletDesktopView } from '../../../../shared/ui-kit/breakPoints'

const Wrapper = styled.div`
  font-size: 18px;
  color: #000;
  margin: 32px 0 24px;
  width: 100%;
  a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ${tabletDesktopView}
`

const Breadcrumbs = ({ fromEnd, lastName }: { fromEnd?: number; lastName?: string }) => {
  const { pathnames } = usePathnames()
  const { categoryId, parentCategoryId, sectionId } = useParams()

  const { data: sectionInfo, mutate: sectionMutate } = useGetSectionInfoMutate({
    id: Number(sectionId),
  })
  const { data: parentCategoryInfo, mutate: parentCategoryMutate } = useGetCategoryInfo({
    id: Number(parentCategoryId),
  })
  const { data: categoryInfo, mutate: categoryMutate } = useGetCategoryInfo({
    id: Number(categoryId),
  })

  useEffect(() => {
    sectionId && sectionMutate({})
  }, [sectionMutate, sectionId])
  useEffect(() => {
    parentCategoryId && parentCategoryMutate({})
  }, [parentCategoryId, parentCategoryMutate])
  useEffect(() => {
    categoryId && categoryMutate({})
  }, [categoryId, categoryMutate])

  return (
    <Wrapper>
      <Link to={'/'}>Главная</Link>
      {pathnames[0] !== 'sections' ? (
        pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`

          if (lastName && index === pathnames.length - 1 - (fromEnd || 0)) {
            return (
              <span key={to}>
                {!lastName.includes('/') && ' / '}
                {lastName}
              </span>
            )
          }

          return (
            <span key={to}>
              {' / '}
              <Link to={to}>{ROUTES_TRANSLATE[value]}</Link>
            </span>
          )
        })
      ) : (
        <span key={'sections'}>
          {' '}
          / <Link to={`/${pathnames[0]}`}>{ROUTES_TRANSLATE[pathnames[0]]}</Link>
        </span>
      )}
      {sectionInfo?.name && (
        <span key={sectionId}>
          {' '}
          / <Link to={`/${pathnames[0]}/${sectionId}`}>{sectionInfo?.name} </Link>
        </span>
      )}
      {parentCategoryInfo?.name ? (
        <span key={parentCategoryId}>
          {' '}
          /{' '}
          <Link to={`/${pathnames[0]}/${sectionId}/${parentCategoryId}`}>
            {parentCategoryInfo?.name}{' '}
          </Link>
        </span>
      ) : (
        ''
      )}
      {categoryInfo?.name && (
        <span key={categoryId}>
          {' '}
          /{' '}
          <Link
            to={`/${pathnames[0]}/${sectionId}/${parentCategoryId ? parentCategoryId : 'sub'}/${categoryId}`}
          >
            {categoryInfo?.name}{' '}
          </Link>
        </span>
      )}
    </Wrapper>
  )
}

export default Breadcrumbs
