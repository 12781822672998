import { useDeferredValue, useEffect, useState } from 'react'

export const useDebounce = () => {
  const [nameTerm, setNameTerm] = useState('')
  const difNameTerm = useDeferredValue(nameTerm)
  const [debounceNameTerm, setDebounceNameTerm] = useState('')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceNameTerm(difNameTerm)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [difNameTerm])

  return { debounceNameTerm, nameTerm, setNameTerm }
}
