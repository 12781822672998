import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { GetRentInfoResponse, ReviewType } from '../../../../shared/api/generated'
import { FlexColumn, MediumTextL, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H2 } from '../../../../shared/ui-kit/titles'
import { FeedbackForm } from '../FeedbackForm'

type Props = {
  back: () => void
  rentInfo: GetRentInfoResponse
}

export const Step10 = ({ back, rentInfo }: Props) => {
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const { rentId } = useParams()
  const rentIdNumber = parseInt(rentId)

  return (
    <>
      <FlexColumn style={{ alignItems: 'center' }}>
        <img
          alt={''}
          src={'/images/interface/close-err.svg'}
          style={{ height: 40, marginTop: '100px', width: 40 }}
        />
        <br />
        <H2>Жалоба получена</H2>
        <MediumTextL>Не переживайте, платформа рассмотрит вашу жалобу</MediumTextL>
        <br />
        {!rentInfo.hasReviewAboutSeller && (
          <>
            <H2 style={{ marginBottom: '16px', textAlign: 'center' }}>Оцените арендодателя</H2>
            <FeedbackForm
              comment={comment}
              rating={rating}
              rentId={rentIdNumber}
              reviewType={ReviewType.aboutSeller}
              setComment={setComment}
              setRating={setRating}
            />
          </>
        )}
        <StyledButton onClick={back} style={{ marginBottom: '32px' }} fullwidth>
          На главную
        </StyledButton>
      </FlexColumn>
    </>
  )
}
