import { GetUserProfileResponse, ReviewListItemDto } from '../../../../shared/api/generated'
import { ProfileSwitchTabs } from '../../../../shared/ui-kit/actual/ProfileSwitchTabs'
import { WrapperMobile } from '../../../../shared/ui-kit/projectComponents'
import { UserAbout } from './UserAbout'
import { UserGoodsBody } from './UserGoodsBody'

type Props = {
  activeTab: number
  setActiveTab: (activeTab: number) => void
  setRatingModal: (value: boolean) => void
  userData: GetUserProfileResponse
  userReviews: ReviewListItemDto[]
}

export const UserBodyWrapper = ({
  activeTab,
  setActiveTab,
  setRatingModal,
  userData,
  userReviews,
}: Props) => {
  return (
    <WrapperMobile>
      <ProfileSwitchTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabHead0={'Товары'}
        tabHead1={'О пользователе'}
      />
      {!activeTab ? (
        <UserGoodsBody />
      ) : (
        <UserAbout setRatingModal={setRatingModal} userData={userData} userReviews={userReviews} />
      )}
    </WrapperMobile>
  )
}
