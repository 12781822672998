import { createContext, useState } from 'react'
import { Link } from 'react-router-dom'

import Breadcrumbs from '../features/market/ui/sections/BreadCrumbs'
import {
  useGetBayerRentsList,
  useGetSellerRentsList,
} from '../features/orders/model/use-get-rents-list'
import { OrdersBodyWrapper } from '../features/orders/ui/OrdersBodyWrapper'
import { OrdersDesktopBodyWrapper } from '../features/orders/ui/OrdersDesktopBodyWrapper'
import { RatingModalContent } from '../features/orders/ui/RatingModalContent'
import { useModal } from '../shared/hooks/useModal'
import { BottomMenuComponent } from '../shared/ui-kit/actual/BottomMenuComponent'
import { ProfileHeader } from '../shared/ui-kit/actual/ProfileHeader'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../shared/ui-kit/actual/layouts/BasePageLayout'
import { BaseModal } from '../shared/ui-kit/actual/modals/BaseModal'
import { RightModal } from '../shared/ui-kit/actual/modals/RightModal'
import { MainMenuWindow } from '../shared/ui-kit/actual/modalsContent/MainMenuWindow'
import {
  BaseWrapper,
  DesktopView,
  ImgButton,
  LoginText,
  MobileTabletView,
} from '../shared/ui-kit/projectComponents'

export const ModalContext = createContext(null)

export const OrdersPage = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { setViewModal, viewModal } = useModal()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  const {
    active,
    buyerActivePage,
    buyerFinishedPage,
    finished,
    isLoading,
    isSuccess,
    setBuyerActivePage,
    setBuyerFinishedPage,
    totalBuyerActivePages,
    totalBuyerFinishedPages,
  } = useGetBayerRentsList()

  const {
    activeSale,
    finishedSale,
    isLoading: isSaleLoading,
    isSuccess: isSaleSuccess,
    sellerActivePage,
    sellerFinishedPage,
    setSellerActivePage,
    setSellerFinishedPage,
    totalSellerActivePages,
    totalSellerFinishedPages,
  } = useGetSellerRentsList()

  return (
    <>
      <AbsoluteLoader isLoading={isLoading || isSaleLoading} />
      {isSuccess && active && finished && isSaleSuccess && activeSale && finishedSale && (
        <ModalContext.Provider value={setViewModal}>
          <MobileTabletView>
            <ProfileHeader
              left={
                <Link to={'/'}>
                  <img alt={'home-link'} src={'/images/allsharinglogo.svg'} width={39} />
                </Link>
              }
              right={
                <ImgButton
                  alt={''}
                  onClick={() => setIsModalOpen(prev => !prev)}
                  src={'/images/buttons/menu-button.svg'}
                />
              }
              center={<LoginText>Заказы</LoginText>}
            />
            <OrdersBodyWrapper
              active={active}
              activeSale={activeSale}
              activeTab={activeTab}
              buyerActivePage={buyerActivePage}
              buyerFinishedPage={buyerFinishedPage}
              finished={finished}
              finishedSale={finishedSale}
              sellerActivePage={sellerActivePage}
              sellerFinishedPage={sellerFinishedPage}
              setActiveTab={setActiveTab}
              setBuyerActivePage={setBuyerActivePage}
              setBuyerFinishedPage={setBuyerFinishedPage}
              setSellerActivePage={setSellerActivePage}
              setSellerFinishedPage={setSellerFinishedPage}
              totalBuyerActivePages={totalBuyerActivePages}
              totalBuyerFinishedPages={totalBuyerFinishedPages}
              totalSellerActivePages={totalSellerActivePages}
              totalSellerFinishedPages={totalSellerFinishedPages}
            />
            <BottomMenuComponent />
            <RightModal setViewModal={setIsModalOpen} viewModal={isModalOpen}>
              <MainMenuWindow handleModalClose={handleModalClose} />
            </RightModal>
          </MobileTabletView>

          <DesktopView>
            <BasePageLayout>
              <MainHeader />
              <BaseWrapper>
                <Breadcrumbs />
                <OrdersDesktopBodyWrapper
                  active={active}
                  activeSale={activeSale}
                  buyerActivePage={buyerActivePage}
                  buyerFinishedPage={buyerFinishedPage}
                  finished={finished}
                  finishedSale={finishedSale}
                  sellerActivePage={sellerActivePage}
                  sellerFinishedPage={sellerFinishedPage}
                  setBuyerActivePage={setBuyerActivePage}
                  setBuyerFinishedPage={setBuyerFinishedPage}
                  setSellerActivePage={setSellerActivePage}
                  setSellerFinishedPage={setSellerFinishedPage}
                  totalBuyerActivePages={totalBuyerActivePages}
                  totalBuyerFinishedPages={totalBuyerFinishedPages}
                  totalSellerActivePages={totalSellerActivePages}
                  totalSellerFinishedPages={totalSellerFinishedPages}
                />
              </BaseWrapper>
            </BasePageLayout>
          </DesktopView>
          <BaseModal setViewModal={setViewModal} viewModal={viewModal}>
            <RatingModalContent setViewModal={setViewModal} />
          </BaseModal>
        </ModalContext.Provider>
      )}
    </>
  )
}
