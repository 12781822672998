import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useApproveBySellerMutation,
  useRentsCancelBySellerMutation,
} from '../../../../entities/rent/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { Dropdown } from '../../../../shared/ui-kit/actual/Dropdown'
import { AbsoluteLoader } from '../../../../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumn, NeutralLink, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { GoodsView } from '../GoodsView'
import { StepWrapper } from '../StepWrapper'

type Props = {
  back: () => void
  refetch: () => void
  rentInfo?: GetRentInfoResponse
  setStep: React.Dispatch<React.SetStateAction<number>>
}
const reasons = [
  { key: 1, value: 'Обнаружен дефект товара' },
  { key: 2, value: 'Невозможно доставить' },
]

export const Step1 = ({ back, refetch, rentInfo, setStep }: Props) => {
  const [isOrderOK, setIsOrderOK] = useState(true)
  const [selectId, setSelectId] = useState<null | number>(0)
  const [reason, setReason] = useState()
  const { rentId } = useParams()

  const { isLoading, isSuccess, mutate } = useApproveBySellerMutation()
  const handleApprove = () => {
    if (rentInfo.status === 'created') {
      mutate({ rentId: +rentId })
    } else {
      setStep(1)
    }
  }

  useEffect(() => {
    isSuccess && setStep(p => p + 1)
  }, [isSuccess, setStep])
  const {
    isLoading: isCancelLoading,
    isSuccess: isCancelSuccess,
    mutate: cancelMutate,
  } = useRentsCancelBySellerMutation()

  useEffect(() => {
    isCancelSuccess && back()
  }, [back, isCancelSuccess])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 5000)

    return () => clearInterval(interval)
  }, [refetch])

  return (
    <StepWrapper>
      <AbsoluteLoader isLoading={isLoading} />
      <div>
        {isOrderOK ? (
          <H3>Вам необходимо доставить</H3>
        ) : (
          <H3>Вы действительно хотите отменить доставку?</H3>
        )}
        <GoodsView rentInfo={rentInfo} />
        {!isOrderOK && (
          <>
            <H3>Укажите причину</H3>
            <Dropdown
              customVariantMessage={'Укажите другую причину'}
              handleChange={setSelectId}
              handleChangeValue={e => setReason(e.value)}
              items={reasons}
              label={'Причина'}
              selectedId={selectId}
              style={{ marginTop: '16px' }}
              fullwidth
            />
          </>
        )}
      </div>
      <FlexColumn style={{ gap: '8px' }}>
        {isOrderOK ? (
          <>
            <StyledButton onClick={handleApprove} fullwidth>
              Передать заказ
            </StyledButton>
            <NeutralLink to={`/users/${rentInfo?.buyerId}`}>
              <StyledButton variant={'secondary'} fullwidth>
                Об арендаторе
              </StyledButton>
            </NeutralLink>
            <StyledButton onClick={() => setIsOrderOK(false)} variant={'outline'} fullwidth>
              Отменить доставку
            </StyledButton>
          </>
        ) : (
          <>
            <StyledButton onClick={() => setIsOrderOK(true)} fullwidth>
              Назад
            </StyledButton>
            <StyledButton
              onClick={() => {
                cancelMutate({ rentId: Number(rentInfo.rentId) })
              }}
              disabled={isCancelLoading}
              variant={'secondary'}
              fullwidth
            >
              Отменить доставку
            </StyledButton>
          </>
        )}
      </FlexColumn>
    </StepWrapper>
  )
}
