import styled from 'styled-components/macro'

import { FlexColumn } from '../../../../shared/ui-kit/projectComponents'
import FilterCategoriesSection from './FilterByCategoriesSection'
import FilterTagList from './FilterTagList'

const ContentWrapper = styled(FlexColumn)`
  width: calc(100% - 48px);
  margin-top: 20px;
  padding-bottom: 130px;
`

const FilterByCategoriesBodyWrapper = () => {
  return (
    <>
      <ContentWrapper>
        <FilterCategoriesSection handleFilterModalOpen={() => null} />
        <FilterTagList />
      </ContentWrapper>
    </>
  )
}

export default FilterByCategoriesBodyWrapper
