import { nanoid } from 'nanoid'

import { CalendarDatePicker } from '../../../../shared/ui-kit/actual/CalendarDatePicker'
import { NoProducts } from '../../../../shared/ui-kit/actual/NoProducts'
import { RentList } from '../RentList'

//mock data

// toggle to empty myProducts for testing <NoProducts/> blank component
// const myProducts = []
const myProducts = [
  {
    id: nanoid(),
    imageLinks: ['/images/mock-items/my-profile/car-1.jpg'],
    name: 'Volkswagen',
    priceDay: 5000,
  },
  {
    id: nanoid(),
    imageLinks: ['/images/mock-items/my-profile/car-2.jpg'],
    name: 'Volkswagen',
    priceDay: 5000,
  },
  {
    id: nanoid(),
    imageLinks: ['/images/mock-items/my-profile/car-3.jpg'],
    name: 'Volkswagen',
    priceDay: 5000,
  },
  {
    id: nanoid(),
    imageLinks: ['/images/mock-items/my-profile/car-4.jpg'],
    name: 'Volkswagen',
    priceDay: 5000,
  },
]

export const ProfileRentPickupTab = () => {
  return (
    <>
      <CalendarDatePicker isSuccess />
      {myProducts?.length ? (
        <RentList />
      ) : (
        <NoProducts imgPath={'/images/interface/EmptyBox.svg'} message={'У вас еще нет заказов'} />
      )}
    </>
  )
}
