import styled from 'styled-components/macro'

import { useGetMarketItemQuery } from '../../../entities/marketItem/queries'
import { GetRentInfoResponse } from '../../../shared/api/generated'
import { colors } from '../../../shared/constants/colors'
import { PageLoader } from '../../../shared/ui-kit/deprecated/PageLoader'
import { FlexRowGap, MediumText } from '../../../shared/ui-kit/projectComponents'
import { H5 } from '../../../shared/ui-kit/titles'
import { dateToString } from '../../../shared/utils/dateToString'

const ImageWrapper = styled.div`
  width: 94px;
  height: 94px;
  border-radius: 11px;
  overflow: hidden;
  & img {
    width: 94px;
    height: 94px;
    object-fit: cover;
  }
`

const SmallText = styled.div`
  color: ${colors.primary};
  font-size: 11px;
  font-weight: 500;
  line-height: 24px;
`
const TextContent = styled.div`
  max-width: 65%;
`

type Props = {
  rentInfo: GetRentInfoResponse
}

export const GoodsView = ({ rentInfo }: Props) => {
  const { data: productInfo, isLoading } = useGetMarketItemQuery({ productId: rentInfo?.productId })

  return (
    <FlexRowGap style={{ marginTop: '24px' }}>
      <ImageWrapper>
        {isLoading ? (
          <PageLoader />
        ) : (
          <img
            alt={''}
            src={productInfo?.imagesUrls[0] || '/images/interface/EmptyBox.svg'}
            style={{ objectFit: 'contain' }}
          />
        )}
      </ImageWrapper>
      <TextContent>
        <SmallText>
          Залог {rentInfo?.pledge} ₽ • К оплате {rentInfo?.price} ₽
        </SmallText>
        <H5>{rentInfo?.productName}</H5>
        <MediumText>
          {dateToString(rentInfo?.periodFrom)} – {dateToString(rentInfo?.periodTo)}
        </MediumText>
      </TextContent>
    </FlexRowGap>
  )
}
