import { UseFormSetValue } from 'react-hook-form'

import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'

import { PostApiProductsCreateProductBody } from '../../../../shared/api/generated'
import { BlockTitleWithButton, InfoButton } from '../../../../shared/ui-kit/actual/InfoButton'
import { TextArea } from '../../../../shared/ui-kit/actual/TextArea'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  FlexRowGap,
  MediumText,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'

const DefectColumn = styled(FlexColumnStart)`
  width: 100%;
  flex-grow: 1;
  gap: 10px;
`
const DefectsWrapper = styled(FlexColumnStart)`
  width: 100%;
  gap: 24px;
`

type Props = {
  defects: { amount: number | string; defectName: string; id: string }[]
  setDefects: (prevState: any) => void
  setValue: UseFormSetValue<PostApiProductsCreateProductBody>
}
export const DefectsItem = ({ defects, setDefects, setValue }: Props) => {
  const addDefectName = (e, id, idx) => {
    setDefects(prevState => {
      return prevState.map(defect => {
        if (defect.id === id) {
          return { ...defect, defectName: e.target.value }
        }

        return defect
      })
    })
    setValue(`DefectFines.${idx}`, {
      amount: +defects[idx].amount,
      defectName: e.target.value,
    })
  }
  const deleteDefect = (id, idx) => {
    setDefects(prevState => {
      const defects = prevState.filter(defect => defect.id !== id)

      setValue(`DefectFines`, defects)

      return defects
    })
  }
  const addDefectAmount = (e, id, defectName, idx) => {
    setDefects(prevState => {
      return prevState.map(defect => {
        if (defect.id === id) {
          return { ...defect, amount: e.target.value }
        }

        return defect
      })
    })
    setValue(`DefectFines.${idx}`, {
      amount: +e.target.value,
      defectName,
    })
  }
  const addDefect = e => {
    e.preventDefault()
    setDefects(prevState => [...prevState, { amount: '', defectName: '', id: nanoid() }])
  }

  return (
    <>
      <BlockTitleWithButton>
        <H3>Штрафы за дефекты</H3>
        <InfoButton
          content={
            'Опишите возможные дефекты и штрафы за них. Если вы этого не сделаете, то арендатор оставивший дефект не будет обязан возмещать убытки'
          }
        />
      </BlockTitleWithButton>
      <DefectsWrapper>
        {defects.map((it, idx) => {
          return (
            <DefectColumn key={it.id}>
              <TextArea
                inputProps={{
                  defaultValue: it.defectName,
                  // disabled: idx < defects.length - 1,
                  placeholder: 'Опишите дефект',
                }}
                onChange={e => addDefectName(e, it.id, idx)}
                fullwidth
              />
              <FlexRowGap
                style={{ alignSelf: 'flex-end', justifySelf: 'flex-end', width: 'fit-content' }}
              >
                <MediumText>Штраф за дефект: </MediumText>
                <TextField
                  inputProps={{
                    defaultValue: it.amount,
                    // idx < defects.length - 1 ||
                    disabled: !it.defectName,
                    placeholder: 'Штраф',
                    type: 'number',
                  }}
                  right={
                    idx > 0 ? (
                      <img
                        alt={'delete'}
                        onClick={() => deleteDefect(it.id, idx)}
                        src={'images/buttons/delete.svg'}
                        style={{ cursor: 'pointer', width: '12px' }}
                      />
                    ) : null
                  }
                  label={'Цена'}
                  onChange={e => addDefectAmount(e, it.id, it.defectName, idx)}
                  size={'md'}
                  style={{ alignSelf: 'flex-end' }}
                />
              </FlexRowGap>
            </DefectColumn>
          )
        })}
      </DefectsWrapper>
      <StyledButton
        disabled={!defects.at(-1).defectName || !defects.at(-1).amount}
        onClick={addDefect}
        variant={'outline'}
        fullwidth
      >
        Добавить дефект
      </StyledButton>
    </>
  )
}
