import React, { useState } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import styled from 'styled-components/macro'
import { useHookFormMask } from 'use-mask-input'

import { SignUpCommand } from '../../../../shared/api/generated'
import { EMAIL_VALIDATION } from '../../../../shared/constants/regex'
import { emailRequired, invalidEmail } from '../../../../shared/constants/validationMessages'
import { PHONE_RULES } from '../../../../shared/rules/PhoneRules'
import { InfoButton } from '../../../../shared/ui-kit/actual/InfoButton'
import { StyledCheckbox } from '../../../../shared/ui-kit/actual/StyledCheckbox'
import { TextField } from '../../../../shared/ui-kit/actual/TextField'
import { BaseLink, FlexRowGap, StyledButton } from '../../../../shared/ui-kit/projectComponents'
import { convertToPhonNumber } from '../../../../shared/utils/convertToPhonNumber'
import { RegisterTitle } from '../headers/RegisterTitle'

type FirstStepProps = {
  errors: FieldErrors<
    {
      passwordConfirm: string
    } & SignUpCommand
  >

  isValid: boolean
  register: UseFormRegister<
    {
      passwordConfirm: string
    } & SignUpCommand
  >
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 48px;
  box-sizing: border-box;
  width: clamp(300px, 100%, 400px);
  align-self: center;
`
const ReferWrapper = styled.div`
  position: relative;
`
const InfoButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 16px;
`

export const FirstStep = ({ errors, isValid, register, setStep }: FirstStepProps) => {
  const registerWithMask = useHookFormMask(register)
  const handleNextStep = () => {
    setStep(prev => prev + 1)
  }
  const [searchParams] = useSearchParams()
  const referral = searchParams?.get('referral') || ''
  const [agree, setAgree] = useState(false)

  return (
    <Wrapper>
      <RegisterTitle title={'Добро пожаловать в Allsharing'} />

      <TextField
        inputProps={{
          ...registerWithMask('phoneNumber', ['(999)999-9999', '9999999999'], {
            ...PHONE_RULES,
            setValueAs: convertToPhonNumber,
          }),
          placeholder: 'Телефон',
          type: 'tel',
        }}
        error={errors.phoneNumber?.message}
        label={'Начните с кода оператора, не используйте пробелы'}
        style={{ position: 'relative' }}
        fullwidth
      />

      <TextField
        inputProps={{
          placeholder: 'Электронная почта',
          type: 'email',
          ...register('email', {
            pattern: {
              message: invalidEmail,
              value: EMAIL_VALIDATION,
            },
            required: emailRequired,
          }),
        }}
        error={errors.email?.message?.toString()}
        label={'Введите email'}
        fullwidth
      />
      <ReferWrapper>
        <TextField
          inputProps={{
            placeholder: 'Реферальный код',
            type: 'text',
            ...register('referralCode'),
            defaultValue: referral,
          }}
          label={'Это поле не обязательно заполнять'}
          fullwidth
        />
        <InfoButtonWrapper>
          <InfoButton
            content={
              'Вы и пригласивший вас человек получите по 100 бонусных шерингов. Это поле не обязательно заполнять'
            }
          />
        </InfoButtonWrapper>
      </ReferWrapper>
      <StyledButton disabled={!isValid || !agree} onClick={handleNextStep} fullwidth>
        Регистрация
      </StyledButton>
      <FlexRowGap>
        <StyledCheckbox
          onChange={() => setAgree(prev => !prev)}
          style={{ height: '20px', width: '20px' }}
          type={'checkbox'}
        ></StyledCheckbox>
        <div style={{ fontSize: '12px' }}>
          <span>Я согласен с условиями </span>
          <BaseLink
            target={'_blank'}
            to={'https://landing.allsharing.su/rules/terms-of-use-ru.html'}
          >
            Пользовательского соглашения
          </BaseLink>
          ,{' '}
          <BaseLink
            target={'_blank'}
            to={'https://landing.allsharing.su/rules/privacy-policy-ru.html'}
          >
            Политикой конфиденциальности
          </BaseLink>
          ,{' '}
          <BaseLink
            target={'_blank'}
            to={'https://landing.allsharing.su/rules/ad-posting-policy-ru.html'}
          >
            Правилами размещения объявлений
          </BaseLink>{' '}
          и{' '}
          <BaseLink
            target={'_blank'}
            to={'https://landing.allsharing.su/rules/offer-for-lessors-ru.html'}
          >
            Офертой для арендодателей
          </BaseLink>
        </div>
      </FlexRowGap>
    </Wrapper>
  )
}
