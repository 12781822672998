import { useContext } from 'react'

import styled from 'styled-components/macro'

import { AccountType } from '../../../../shared/api/generated'
import { colors } from '../../../../shared/constants/colors'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import {
  BottomModalWrapper,
  Dot,
  DotsWrapper,
  FlexRow,
  FlexRowBetween,
  InnerModalWrapper,
  MediumText,
  MobileView,
  StyledButton,
  TopModalWrapper,
} from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import getAvgRating from '../../../../shared/utils/getAvgRating'
import { TagsModalTexts } from './TagsModalTexts'

const RatingWrapper = styled(FlexRow)<{
  $bg: string
  $selected: boolean
}>`
  width: fit-content;
  border-radius: 38px;
  color: ${colors.white};
  background-color: ${({ $bg }) => ($bg ? $bg : colors.primary)};
  opacity: ${({ $selected }) => ($selected ? 1 : 0.5)};
  transform: ${({ $selected }) => ($selected ? 'scale(100%)' : 'scale(75%)')};
  padding: 10px 30px;
  align-items: center;
  gap: 4px;
  font-size: 34px;
  font-weight: 600;
  box-shadow: 0px 8.335588455200195px 41.67794418334961px 0px #9791db3d;
  img {
    width: 25px;
    height: 25px;
  }
`

const Ratings = styled(FlexRowBetween)`
  z-index: 1200;
  width: 500px;
  align-items: center;
  align-self: center;
`

const statusList: AccountType[] = ['notVerified', 'verified', 'premium']

const sortedColorsArrays = {
  notVerified: [colors.primary, colors.red, colors.yellowGreen],
  premium: [colors.yellowGreen, colors.primary, colors.red],
  undefined: [colors.primary, colors.red, colors.yellowGreen],
  verified: [colors.red, colors.yellowGreen, colors.primary],
}

type Props = {
  accountType: AccountType
  setViewModal: (viewModal: boolean) => void
}

export const TagsModal = ({ accountType, setViewModal }: Props) => {
  const profileStore = useContext(ProfileStorage)
  const profile = profileStore.getProfile()
  const avgRating = getAvgRating(profile?.buyerRating, profile?.sellerRating)

  return (
    <InnerModalWrapper>
      <TopModalWrapper>
        <div>
          <H3 style={{ marginTop: '5px' }}>Ваш статус</H3>
          <MediumText>
            Статус присваивается в зависимости от подтверждения определенных данных. Это нужно для
            обеспечения безопасности на платформе
          </MediumText>
        </div>
        <Ratings>
          {sortedColorsArrays?.[accountType].map((color, index) => (
            <RatingWrapper $bg={color} $selected={index === 1} key={index}>
              <img alt={''} src={'/images/interface/whiteStar.svg'} />
              <span>{avgRating?.toFixed(1)}</span>
            </RatingWrapper>
          ))}
        </Ratings>
        <DotsWrapper>
          {statusList.map((status, index) => (
            <Dot $selected={status === accountType} key={index} />
          ))}
        </DotsWrapper>
        <TagsModalTexts accountType={accountType} />
        <StyledButton as={'a'} href={'/profile/personal-data'} variant={'secondary'} fullwidth>
          {accountType !== 'premium' ? 'Купить премиум' : 'К настройкам'}
        </StyledButton>
      </TopModalWrapper>
      <MobileView>
        <BottomModalWrapper>
          <StyledButton onClick={() => setViewModal(false)} fullwidth>
            К профилю
          </StyledButton>
        </BottomModalWrapper>
      </MobileView>
    </InnerModalWrapper>
  )
}
