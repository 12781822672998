import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { IProductSearchResponse } from '../../../api/deprecated/productService/ProductService'
import { ISection } from '../../../api/deprecated/sectionsService/SectionsService'
import { GetProductsListResponse, ProductListItem } from '../../../api/generated'
import { colors } from '../../../constants/colors'
import { useQueryString } from '../../../hooks/useQueryString'
import SectionsStorage from '../../../storage/SectionsStorage/SectionsStorage'
import { FilterButton } from '../../actual/FilterButton'
import { LogoAllSharing } from '../../actual/LogoAllsharing'
import { breakpoints } from '../../breakPoints'
import { Logo, StyledInput } from '../../projectComponents'
import { PageLoader } from '../PageLoader'

const DropDownComp = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  width: 100%;
`
const DropDownMainWrapper = styled.div`
  white-space: nowrap;
  box-sizing: border-box;
  background: ${colors.lightGray};
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  width: 100%;
  height: 54px;

  img {
    width: 16px;
    margin-left: 8px;
  }
`

interface DropdownContentProps {
  openMenu: boolean
}

const DropdownContent = styled.div<DropdownContentProps>`
  position: absolute;
  z-index: 1500;
  left: 0;
  margin-top: 20px;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  box-sizing: border-box;
  /* z-index: 1; */
  border-radius: 5px;
  transform: translateY(calc(50% + 25px));
  max-height: 80vh;
  padding: 15px 0;
  font-size: 14px;
  display: ${props => (props.openMenu ? 'flex' : 'none')};
  overflow: hidden;
  overflow-y: scroll;
  background: #fff;
  width: clamp(${breakpoints.mobileMin}, 100%, ${breakpoints.desktopMax});
  box-shadow: 9px 9px 15.7px 0px rgba(94, 94, 94, 0.07);

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    height: 30px;
    background-color: #a6a3a2;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 1);
  }
`

interface DropdownButtonProps {
  $isActive?: boolean
}

const DropdownButton = styled(Link)<DropdownButtonProps>`
  color: #a0a6a6;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0);
  background: ${props => (props.$isActive ? '#008C8C70' : '#fff')};
  cursor: pointer;
  border-bottom: 1px solid #e0e5e6;
  height: 68px;
  padding: 12.5px;

  p {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin: 0;
    line-height: normal;
  }
`

interface DropdownComponentProps {
  hideFilterButton?: boolean
  isLoading?: boolean
  itemId: null | number
  itemList?: ProductListItem[]
  noLogo?: boolean
  onClickFilter: any
  openMenu: any
  placeholder?: string
  setId: any
  setOpenMenu: any
  setValue: (value: string) => void
  value: string
}

const DropdownProductComponent = ({
  isLoading,
  itemId,
  itemList,
  noLogo,
  openMenu,
  placeholder,
  setId,
  setOpenMenu,
  setValue,
  value,
}: DropdownComponentProps) => {
  // const SectionsStore = useContext(SectionsStorage)
  // const [sections, setSections] = useState<ISection[]>(SectionsStore.sections)

  const foundText = (string, returnFirst) => {
    const index = string.toLowerCase().indexOf(value.toLowerCase())

    if (returnFirst) {
      return string.substring(0, index)
    } else {
      return string.substring(index + value.length)
    }
  }
  const { setValue: setSearchValue, value: searchValue } = useQueryString('searchValue', `/search`)
  const searchLimit = 20
  // TODO: зафиксировать при скроле открытый поиск
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSearchValue(value)
  }

  return (
    <DropDownComp>
      <DropDownMainWrapper onClick={() => setOpenMenu(!openMenu)}>
        {!noLogo ? (
          <LogoAllSharing />
        ) : (
          <Link to={`/search?value=${value}`}>
            <img
              alt={'search'}
              src={'/images/buttons/Search.svg'}
              style={{ marginRight: '10px', paddingTop: '5px', width: '20px' }}
            />
          </Link>
        )}
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <StyledInput
            onChange={e => {
              setValue(e.target.value)
            }}
            onInput={() => setOpenMenu(true)}
            placeholder={placeholder || 'Введите текст...'}
            style={{ width: '100%' }}
            type={'text'}
            value={value}
          />
        </form>
        {!noLogo && (
          <Link to={`/search?value=${value}`}>
            <img
              alt={'search'}
              src={'/images/buttons/Search.svg'}
              style={{ marginRight: '10px', paddingTop: '5px', width: '20px' }}
            />
          </Link>
        )}
        <div onClick={() => setValue('')}>
          <img
            alt={'search'}
            src={'/images/buttons/close.png'}
            style={{ marginRight: '10px', width: '10px' }}
          />
        </div>
        {/* <FilterButton hideFilterButton={hideFilterButton} onClickFilter={onClickFilter} /> */}
      </DropDownMainWrapper>

      {value.length > 1 && (
        <DropdownContent openMenu={openMenu}>
          {isLoading ? (
            <PageLoader />
          ) : (
            itemList?.map(
              (x, id) =>
                id < searchLimit && (
                  <DropdownButton
                    onClick={() => {
                      setId(id)
                      setOpenMenu(false)
                    }}
                    $isActive={id === itemId}
                    key={x.productId}
                    to={`/item/${x.productId}`}
                  >
                    <p style={{ textAlignLast: 'start' }}>
                      {foundText(x.name, true)}
                      <span style={{ color: colors.black }}>{value}</span>
                      {foundText(x.name, false)}
                    </p>
                    {/* <p
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          textAlignLast: 'start',
                        }}
                      >
                        {sections && sections[x.]?.name}
                      </p> */}
                  </DropdownButton>
                )
            )
          )}
          {!isLoading && itemList?.length === 0 && (
            <p style={{ textAlignLast: 'start' }}>Нет результатов</p>
          )}
        </DropdownContent>
      )}
    </DropDownComp>
  )
}

export default DropdownProductComponent
