import styled from 'styled-components/macro'

import { FlexColumnStart } from '../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../shared/ui-kit/titles'
import { RentItem } from './RentItem'

const Wrapper = styled(FlexColumnStart)`
  width: clamp(300px, 100%, 800px);
  row-gap: 16px;
`

export const RentList = () => {
  return (
    <Wrapper>
      <H3>В этот день</H3>
      {[1, 2, 3].map(n => (
        <RentItem key={n} />
      ))}
      <br />
      <H3>Далее</H3>
      {[1, 2, 3].map(n => (
        <RentItem key={n} />
      ))}
    </Wrapper>
  )
}
