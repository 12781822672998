import { memo } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { FlexColumn } from '../../projectComponents'

const Block = styled(FlexColumn)`
  /* height: 197px; */
  width: 175px;
  justify-content: flex-start;
  img {
    border-radius: 12px;
    object-fit: fill;
  }
  p {
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 12.36px;
    font-weight: 600;
  }
`
const StyledLink = styled(Link)`
  display: inline-block;

  text-decoration: none !important;
  color: ${colors.black};
`

interface CategoryButtonProps {
  hasChild?: boolean
  id: number | string
  image?: null | string
  name: string
  sectionId?: number | string
}

const CategoryButton = memo(({ hasChild, id, image, name, sectionId }: CategoryButtonProps) => {
  const route = hasChild ? `${String(id)}` : `sub/${String(id)}`

  return (
    <Block>
      <StyledLink to={route}>
        <img alt={name} height={175} loading={'lazy'} src={image} width={175} />
        <p>{name}</p>
      </StyledLink>
    </Block>
  )
})

export default CategoryButton
