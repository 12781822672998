import { toast } from 'react-custom-alert'
import { UseQueryOptions, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  GetArchivedProductsListQuery,
  GetArchivedProductsListResponse,
  GetProductsListQuery,
  GetProductsListResponse,
  postApiProductsGetArchivedProductsList,
  postApiProductsGetProductsList,
} from '../../shared/api/generated'

export function useGetProductsListQuery(
  params: GetProductsListQuery,
  options?: UseQueryOptions<unknown, unknown, GetProductsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки товаров')
    },
    queryFn: () => postApiProductsGetProductsList(params),
    queryKey: ['get_products', JSON.stringify(params)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
export function useGetArchivedProductsList(
  params: GetArchivedProductsListQuery,
  options?: UseQueryOptions<unknown, unknown, GetArchivedProductsListResponse, string[]>
) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки архивных товаров')
    },
    queryFn: () => postApiProductsGetArchivedProductsList(params),
    queryKey: ['get_archive_products', JSON.stringify(params)],
    refetchOnWindowFocus: false,
    ...options,
  })
}
