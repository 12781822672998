import styled from 'styled-components/macro'

import { BankAccountDto } from '../../api/generated'
import { colors } from '../../constants/colors'
import { FlexRowStart, MediumText } from '../projectComponents'
import { H5 } from '../titles'
import { RadioInputNew } from './RadioInputNew'

const Wrapper = styled(FlexRowStart)<{ isSelected?: boolean }>`
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 15px;
  align-items: center;
  margin: 8px 0 12px;
  column-gap: 20px;
  border: ${props => (props.isSelected ? `solid 1px ${colors.primary}` : 'none')};
`

type Props = {
  data: BankAccountDto
  isSelected?: boolean
  onClick: () => void
}
export const BankAccountCard = ({ data, isSelected, onClick }: Props) => {
  return (
    <Wrapper isSelected={isSelected} onClick={onClick}>
      <RadioInputNew checked={isSelected} />
      <div>
        <H5>{data.bankName}</H5>
        <MediumText>Расчетный счет: {data.paymentAccount}</MediumText>
        <MediumText>Корреспондентский счет: {data.correspondentBank}</MediumText>
        <MediumText>БИК: {data.bankIdentificationCode}</MediumText>
        <MediumText>ИНН: {data.inn}</MediumText>
        {data.kpp && <MediumText>КПП: {data.kpp}</MediumText>}
        <MediumText style={{ color: colors.primary }}>{data.companyName}</MediumText>
      </div>
    </Wrapper>
  )
}
