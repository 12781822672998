import { Link } from 'react-router-dom'

import { PassportsListItem } from '../../../api/generated'
import { FlexRowBetween, Table, Th } from '../../projectComponents'

export const translatesObj = {
  // eslint-disable-next-line prettier/prettier
  undefined: 'не известно',
  verificationError: 'ошибка',
  verificationInProgress: 'в процессе',
  verificationRejected: 'отклонен',
  verified: 'верифицирован',
}

export const AdminPassportsTable = ({ passports }: { passports: PassportsListItem[] }) => (
  <FlexRowBetween style={{ alignItems: 'center', marginBottom: 15, width: 'calc(100% - 28px)' }}>
    {' '}
    <Table>
      <thead>
        <tr>
          <Th style={{ maxWidth: '200px' }}></Th>
          <Th>ФИО</Th>
          <Th>Серия</Th>
          <Th>Номер</Th>
          <Th>Статус</Th>
          <Th>Комментарий</Th>
        </tr>
      </thead>
      <tbody>
        {passports.map(p => (
          <tr key={p.passportId}>
            <td>
              <Link style={{ display: 'inline-block', minWidth: '150px' }} to={`${p.ownerId}`}>
                К паспорту
              </Link>{' '}
            </td>
            <td>{p.fullName}</td>
            <td>{p.series}</td>
            <td>{p.number}</td>

            <td>{translatesObj[p.verificationStatus]}</td>
            <td>{p.rejectComment}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </FlexRowBetween>
)
