import { toast } from 'react-custom-alert'
import { UseMutationOptions, useMutation, useQuery } from 'react-query'

import { AxiosError } from 'axios'

import {
  CreateReviewCommand,
  DeleteReviewCommand,
  GetReviewsListQuery,
  UpdateReviewCommand,
  postApiReviewsCreateReview,
  postApiReviewsDeleteReview,
  postApiReviewsGetReviewsList,
  postApiReviewsUpdateReview,
} from '../../shared/api/generated'

export function useGetReviewsListQuery(reviewsListQuery: GetReviewsListQuery) {
  return useQuery({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.warning(e.response?.data?.message || 'Ошибка загрузки списка отзывов')
    },
    queryFn: () => postApiReviewsGetReviewsList(reviewsListQuery),
    queryKey: ['get_reviews_list', JSON.stringify(reviewsListQuery)],
    refetchOnWindowFocus: false,
  })
}

export function useCreateReviewMutation(
  options?: UseMutationOptions<any, unknown, CreateReviewCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: CreateReviewCommand) => {
      return postApiReviewsCreateReview(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка отправки отзыва')
    },
    ...options,
  })
}

export function useDeleteReviewMutation(
  options?: UseMutationOptions<any, unknown, DeleteReviewCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: DeleteReviewCommand) => {
      return postApiReviewsDeleteReview(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка удаления отзыва')
    },
    ...options,
  })
}

export function useUpdateReviewMutation(
  options?: UseMutationOptions<any, unknown, UpdateReviewCommand, unknown>
) {
  return useMutation({
    mutationFn: (data: UpdateReviewCommand) => {
      return postApiReviewsUpdateReview(data)
    },
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      toast.error(e.response?.data?.message || 'Ошибка изменения отзыва')
    },
    ...options,
  })
}
