import styled from 'styled-components/macro'

import { colors } from '../../constants/colors'

export type StyledInputProps = {
  hasIcon?: boolean
  isError?: boolean
}

export type TINPUT_SIZE = 'md' | 'sm' | 'xl'
export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  background-color: ${colors.lightGray};
  margin: 0;
  border: none;
  color: ${({ isError }) => isError && colors.red};
  position: relative;
  &[type='date'] {
    background: transparent;

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      opacity: 0.7;
      padding: 4px;
      color: ${colors.primary};
      outline: ${colors.primary};
      background-color: ${colors.secondary};
      border-radius: 50%;
      box-shadow: 0px 2px 50px 0px #0000000f;
    }
  }
`
