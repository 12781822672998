import styled from 'styled-components/macro'

import { FlexColumnStart, FlexRow } from '../../../../shared/ui-kit/projectComponents'

const ImageProduct = styled.img`
  width: 94px;
  height: 106px;
  border-radius: 10px;
  object-fit: cover;
`
const Price = styled.p`
  font-size: 11px;
  margin: 0;
  padding: 0;
  color: #008c8c;
  line-height: 23.36px;
`
const Name = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #008c8c;
  font-weight: 600;
  line-height: 25.3px;
`
const Size = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #6e6e70;
  font-weight: 500;
  line-height: 23.36px;
`

export const ProductInfo = ({
  img,
  name,
  price,
  size,
}: {
  img: string
  name: string
  price: number | string
  size?: number | string
}) => (
  <FlexRow style={{ alignItems: 'center', gap: '36px', justifyContent: 'start' }}>
    <ImageProduct alt={'product'} src={img} />
    <FlexColumnStart>
      <Price>Залог {price} ₽</Price>
      <Name>{name}</Name>
      {size && <Size>Размер {size}</Size>}
    </FlexColumnStart>
  </FlexRow>
)
