import { observer } from 'mobx-react-lite'

import { SignInTitle } from '../features/auth/ui/SignInTitle'
import { SigninFooter } from '../features/auth/ui/SigninFooter'
import { PasswordForgot } from '../features/passwordRestore/ui/PasswordForgot'
import { AuthLayout } from '../shared/ui-kit/actual/layouts/AuthLayout'

const PasswordForgotPage = () => {
  return (
    <AuthLayout
      footer={<SigninFooter />}
      form={<PasswordForgot />}
      header={<SignInTitle title={'Восстановление пароля'} />}
    />
  )
}

export default observer(PasswordForgotPage)
