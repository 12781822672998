import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useApproveWithdrawalRequestMutation } from '../../../../entities/payments/queries'
import { FlexColumn, FlexRowBetween, StyledButton } from '../../projectComponents'
import { H3 } from '../../titles'
import { TextArea } from '../TextArea'
import { MiniModal } from '../modals/MiniModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const ContentModal = styled(FlexColumn)`
  padding-bottom: 20px;
  width: 100%;
`

interface RejectClaimModalProps {
  approveModal: boolean
  setOpen: (v: boolean) => void
  withdrawalRequestId: string
}

export const AdminApproveWithdrawalModal = ({
  approveModal,
  setOpen,
  withdrawalRequestId,
}: RejectClaimModalProps) => {
  const navigate = useNavigate()

  const { isLoading, isSuccess, mutate } = useApproveWithdrawalRequestMutation()
  const CloseModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    isSuccess && navigate(-1)
  }, [isSuccess, navigate])
  const [comment, setComment] = useState('')

  return (
    <MiniModal setViewModal={setOpen} viewModal={approveModal} minWidth>
      <AbsoluteLoader isLoading={isLoading} />
      <br />
      <H3 style={{ marginBottom: 20 }}>Подтвердить вывод средств?</H3>
      <TextArea
        inputProps={{
          placeholder: 'Введите комментарий',
        }}
        style={{
          borderRadius: '15px',
          flexGrow: 1,
          height: '48px',
          paddingLeft: '16px',
          width: '100%',
        }}
        onChange={e => setComment(e.target.value)}
        value={comment}
        fullwidth
      />
      <br />
      <ContentModal>
        <FlexRowBetween style={{ gap: '24px' }}>
          <StyledButton onClick={CloseModal} size={'xl'} variant={'outline'}>
            Отмена
          </StyledButton>
          <StyledButton
            onClick={() => {
              mutate({ comment, withdrawalRequestId })
            }}
            disabled={isLoading}
            size={'xl'}
            variant={'danger'}
          >
            Перевод сделан
          </StyledButton>
        </FlexRowBetween>
      </ContentModal>
    </MiniModal>
  )
}
