import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import { useDocumentsRejectPassportVerificationMutation } from '../../../../entities/documents/queries'
import { FlexColumn, FlexRowBetween, StyledButton } from '../../projectComponents'
import { H3 } from '../../titles'
import { TextArea } from '../TextArea'
import { MiniModal } from '../modals/MiniModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const ContentModal = styled(FlexColumn)`
  padding-bottom: 20px;
  width: 100%;
`

interface ArchiveItemModalProps {
  passportId: string
  setOpen: (v: boolean) => void
  viewModal: boolean
}

export const AdminPassportRejectModal = ({
  passportId,
  setOpen,
  viewModal,
}: ArchiveItemModalProps) => {
  const { isLoading, isSuccess, mutate } = useDocumentsRejectPassportVerificationMutation()
  const [comment, setComment] = useState('')

  useEffect(() => {
    isSuccess && setOpen(false)
  }, [isSuccess, setOpen])

  return (
    <MiniModal setViewModal={setOpen} viewModal={viewModal}>
      <AbsoluteLoader isLoading={isLoading} />

      <H3 style={{ marginBottom: 20 }}>Отклонить верификацию паспорта?</H3>
      <TextArea
        inputProps={{
          placeholder: 'Введите комментарий',
        }}
        style={{
          borderRadius: '15px',
          flexGrow: 1,
          height: '48px',
          paddingLeft: '16px',
          width: '100%',
        }}
        onChange={e => setComment(e.target.value)}
        value={comment}
        fullwidth
      />
      <br />
      <ContentModal>
        <FlexRowBetween>
          <StyledButton onClick={() => setOpen(false)} variant={'outline'}>
            Отмена
          </StyledButton>
          <StyledButton
            onClick={() => {
              mutate({ comment, passportId })
            }}
            disabled={isLoading}
            size={'xl'}
            style={{ width: '100%' }}
            variant={'warning'}
          >
            Отклонить верификацию
          </StyledButton>
        </FlexRowBetween>
      </ContentModal>
    </MiniModal>
  )
}
