import { useEffect } from 'react'

import { useGetMarketItemQuery } from '../../../../entities/marketItem/queries'
import { GetRentInfoResponse } from '../../../../shared/api/generated'
import { LocationInfo } from '../../../../shared/ui-kit/actual/components/LocationInfo'
import { FlexColumnStart } from '../../../../shared/ui-kit/projectComponents'
import { H3 } from '../../../../shared/ui-kit/titles'
import { GoodsView } from '../GoodsView'
import { StepWrapper } from '../StepWrapper'
type Props = {
  refetch: () => void
  rentInfo: GetRentInfoResponse
}
export const Step7 = ({ refetch, rentInfo }: Props) => {
  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 10000)

    return () => clearInterval(interval)
  }, [refetch])
  const { data } = useGetMarketItemQuery({ productId: rentInfo?.productId })

  return (
    <StepWrapper>
      <FlexColumnStart>
        <H3>Товар на пути к владельцу</H3>
        <GoodsView rentInfo={rentInfo} />
        <br />
        <LocationInfo pickupAddress={data?.pickupAddress} />
      </FlexColumnStart>
    </StepWrapper>
  )
}
