import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { usePostApiUsersGetUserInfo } from '../../../../entities/ownProfile/queries'
import {
  useApproveBySellerMutation,
  useRentsCancelBySellerMutation,
} from '../../../../entities/rent'
import { RentListItem } from '../../../../shared/api/generated'
import { queryClient } from '../../../../shared/api/query-client'
import { colors } from '../../../../shared/constants/colors'
import useDateRangeFormat from '../../../../shared/hooks/useDateRangeFormat'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import { BaseModal } from '../../../../shared/ui-kit/actual/modals/BaseModal'
import {
  BtnRowWrapper,
  BtnWrapper,
  StyledButton,
} from '../../../../shared/ui-kit/projectComponents'
import { MapContainer } from '../../../map'

const RentModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 800px) {
    margin: 0 auto;
  }
  & > div {
    display: flex;
    gap: 94px;
    justify-content: space-between;

    @media all and (max-width: 800px) {
      display: block;
      width: 400px;
    }
  }
`
const LeftSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const RentInfoBlock = styled.div`
  margin-bottom: 32px;
  text-align: center;

  @media all and (max-width: 800px) {
    width: auto;
  }

  & > span {
    display: inline-block;
    max-width: 327px;
    width: 100%;
    font-size: 16px;
    line-height: 23.36px;
    color: ${colors.grey};
  }
`

const RentInfoIcon = styled.img``

const RentInfoTitle = styled.h5`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.78px;
  color: ${colors.black};
`

const RentItemBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media all and (max-width: 800px) {
    margin-bottom: 32px;
  }
`

const RentImgWrapper = styled.div`
  position: relative;
`

const RentImg = styled.img`
  width: 94px;
  height: 106px;
  border-radius: 6px;

  @media all and (max-width: 800px) {
    width: 80px;
    height: 76px;
    object-fit: cover;
  }
`

const UserAvatarWrapper = styled.div`
  position: absolute;
  top: -6px;
  left: -6px;
  background: ${colors.white};
  padding: 2.5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProfileImg = styled.img`
  width: 21px;
  height: 21px;
  object-fit: cover;
  border-radius: inherit;
`

const RentDataPriceWrapper = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 23.36px;
  color: ${colors.primary};
`

const ProductDetails = styled.div`
  /* max-width: 222px; */
  width: 100%;
`

const ProductDetailsName = styled.h6`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.3px;
  color: ${colors.black};
  @media all and (max-width: 800px) {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ProductDetailsBuyerName = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 23.36px;
  color: ${colors.grey};
`

const WarningSection = styled.div`
  margin-top: 86px;
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 17px 23px;
  background: ${colors.lightRed};
  border-radius: 10.53px;

  @media all and (max-width: 800px) {
    margin: 0;
  }
`

const WarningIcon = styled.img``

const WarningDescription = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 23.36px;
  color: ${colors.red};

  @media all and (max-width: 800px) {
    width: 327px;
  }
`

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media all and (max-width: 800px) {
    width: 400px;
    align-items: center;
  }
`

const LocationBtnsBlock = styled.div`
  width: 100%;
`

const LocationBlockTitle = styled.div`
  & > h4 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    color: ${colors.black};
    @media all and (max-width: 800px) {
      margin-bottom: 4px;
      width: 400px;
    }
  }
  & > span {
    display: inline-block;
    max-width: 350px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.36px;
    color: ${colors.grey};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${colors.primary};

    @media all and (max-width: 800px) {
      font-size: 13.63px;
    }
  }
`

const MapSection = styled.div`
  width: 358px;
  height: 307px;
  border-radius: 10.53px;
  margin: 16px 0 21px 0;

  @media all and (max-width: 800px) {
    width: 327px;
    height: 107px;
  }
`

interface RentModalProps {
  isModalOpen?: boolean
  selectedRent?: RentListItem
  setIsModalOpen?: (viewModal: boolean) => void
  setIsSecondModalOpen?: (isSecondModalOpen: boolean) => void
  setModal?: (viewModal: boolean) => void
  setModalMessage?: React.Dispatch<
    React.SetStateAction<{
      img: string
      message: string
      title: string
    }>
  >
}

export const RentModal = ({
  isModalOpen,
  selectedRent,
  setIsModalOpen,
  setIsSecondModalOpen,
  setModalMessage,
}: RentModalProps) => {
  const dataRenge = useDateRangeFormat(selectedRent?.periodFrom, selectedRent?.periodTo)
  const data = selectedRent
  const coordinates = [data?.address?.latitude, data?.address?.longitude]
  const ProfileStore = useContext(ProfileStorage)

  const { mutate } = usePostApiUsersGetUserInfo({
    onError: error => {
      console.log('profile error', error)
    },
    onSuccess: data => {
      ProfileStore.setUserInfo(data)
    },
  })
  const approveBySellerMutation = useApproveBySellerMutation({
    onError: error => {
      setModalMessage({
        img: '/images/interface/close-err.svg',
        message:
          'Возможно заказ отменен арендатором. Если ошибка повторится, напишите в техподдержку.',
        title: 'Что-то пошло не так',
      })
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })

      setIsSecondModalOpen(true)
    },
    onSuccess: () => {
      setModalMessage({
        img: '/images/interface/done-success.svg',
        message: 'Заказ будет добавлен в вашу корзину',
        title: 'Успешно!',
      })
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })
      mutate({ userId: ProfileStore.userInfo.id })
      setIsSecondModalOpen(true)
    },
  })

  const rejectBySellerMutation = useRentsCancelBySellerMutation({
    onError: error => {
      setModalMessage({
        img: '/images/interface/close-err.svg',
        message:
          'Попробуйте отклонить заказ еще раз. Если ошибка повторится, напишите в техподдержку.',
        title: 'Что-то пошло не так',
      })
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })

      setIsSecondModalOpen(true)
    },
    onSuccess: () => {
      setModalMessage({
        img: '/images/interface/done-success.svg',
        message: 'Вы успешно отклонили заказ',
        title: 'Успешно!',
      })
      queryClient.invalidateQueries({ queryKey: ['get_all_rents_info'] })

      setIsSecondModalOpen(true)
    },
  })

  const handleApprove = (rentId: number) => {
    if (!rentId) {
      return null
    }
    approveBySellerMutation.mutate({ rentId })
    setIsModalOpen(false)
  }

  const handleCancel = (rentId: number) => {
    if (!rentId) {
      return null
    }
    rejectBySellerMutation.mutate({ rentId })
    setIsModalOpen(false)
  }

  // useEffect(() => {
  //   approveBySellerMutation.isSuccess && navigate(`/delivery/send/${selectedRent?.rentId}`)
  // }, [approveBySellerMutation.isSuccess, navigate, selectedRent?.rentId])

  return (
    <BaseModal setViewModal={setIsModalOpen} viewModal={isModalOpen} width={'1000'}>
      <div>
        <RentModalWrapper>
          <div>
            <LeftSection>
              <RentInfoBlock>
                <RentInfoIcon
                  alt={'Info Incon'}
                  height={40}
                  src={'/images/buttons/info.svg'}
                  width={40}
                />
                <RentInfoTitle>Важно</RentInfoTitle>
                <span>Убедитесь, что вы сможете предоставить товар покупателю в нужные сроки</span>
              </RentInfoBlock>
              <RentItemBlock>
                <RentImgWrapper>
                  <RentImg src={data?.productImagesUrls[0]} />
                  <UserAvatarWrapper>
                    <ProfileImg
                      src={data?.buyerImageUrl || '/images/bottom-menu/profile-light.svg'}
                    />
                  </UserAvatarWrapper>
                </RentImgWrapper>
                <div>
                  <RentDataPriceWrapper>
                    {dataRenge} •{' '}
                    {selectedRent?.price !== 0
                      ? `${selectedRent?.price.toLocaleString('ru-RU')}₽`
                      : 'Цена не указана'}
                  </RentDataPriceWrapper>
                  <ProductDetails>
                    <ProductDetailsName>{selectedRent?.productName}</ProductDetailsName>
                    <ProductDetailsBuyerName>{selectedRent?.buyerName}</ProductDetailsBuyerName>
                  </ProductDetails>
                </div>
              </RentItemBlock>
              <WarningSection>
                <WarningIcon src={'/images/interface/info-warning.svg'} />
                <WarningDescription>
                  В случае недоставки или невыполнения обязательств это приведет к снижению рейтинга
                  на платформе.
                </WarningDescription>
              </WarningSection>
            </LeftSection>
            <RightSection>
              <LocationBlockTitle>
                <h4>{data?.deliveryType === 'pickup' ? 'Самовывоз' : 'Доставка'}</h4>
                <span>{data?.address?.name || 'Адрес не указан'}</span>
              </LocationBlockTitle>
              <MapSection>
                <MapContainer coordinates={coordinates} isPlacemark />
              </MapSection>
              <LocationBtnsBlock>
                <BtnWrapper>
                  <BtnRowWrapper>
                    <StyledButton onClick={() => handleApprove(data?.rentId)} fullwidth>
                      Принять заказ
                    </StyledButton>
                    {/* <StyledBtnSmall variant={'secondary'}>
                      <img alt={''} src={'/images/buttons/calendar-green.svg'} />
                    </StyledBtnSmall> */}
                  </BtnRowWrapper>
                  <BtnRowWrapper>
                    <StyledButton
                      onClick={() => handleCancel(data?.rentId)}
                      variant={'secondary'}
                      fullwidth
                    >
                      Отклонить заказ
                    </StyledButton>
                  </BtnRowWrapper>
                </BtnWrapper>
              </LocationBtnsBlock>
            </RightSection>
          </div>
        </RentModalWrapper>
      </div>
    </BaseModal>
  )
}
