import React from 'react'
import { FieldErrors, UseFormSetValue } from 'react-hook-form'

import {
  AddUserAddressCommand,
  PostApiProductsCreateProductBody,
  UserAddressDto,
} from '../../../shared/api/generated'
import {
  FlexRowWrap,
  StyledButton,
  TabletDesktopView,
} from '../../../shared/ui-kit/projectComponents'
import { AddItemDelivery } from './components/AddItemDelivery'
import { AddItemPickup } from './components/AddItemPickup'
type Props = {
  checkedAllRussia: boolean
  errors: FieldErrors<PostApiProductsCreateProductBody>
  handleNextStep: (next?: Boolean) => void
  selectedAddressId: number
  selectedCities: string[]
  setCheckedAllRussia: React.Dispatch<React.SetStateAction<boolean>>
  setDisabledBtn: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<UserAddressDto>>
  setSelectedAddressId: React.Dispatch<React.SetStateAction<number>>
  setSelectedCities: React.Dispatch<React.SetStateAction<string[]>>
  setValue: UseFormSetValue<PostApiProductsCreateProductBody>
  submitAddress: (data: AddUserAddressCommand) => Promise<void>
}
export const SecondStep = ({
  checkedAllRussia,
  errors,
  handleNextStep,
  selectedAddressId,
  selectedCities,
  setCheckedAllRussia,
  setDisabledBtn,
  setSelectedAddress,
  setSelectedAddressId,
  setSelectedCities,
  setValue,
  submitAddress,
}: Props) => {
  return (
    <>
      <TabletDesktopView>
        <StyledButton
          onClick={() => handleNextStep(true)}
          size={'md'}
          style={{ width: 'fit-content' }}
          type={'button'}
          variant={'outline'}
        >
          Вернутся к товару
        </StyledButton>
      </TabletDesktopView>
      <FlexRowWrap style={{ alignItems: 'flex-start', gap: '48px' }}>
        <AddItemDelivery
          checkedAllRussia={checkedAllRussia}
          errors={errors}
          selectedCities={selectedCities}
          setCheckedAllRussia={setCheckedAllRussia}
          setDisabledBtn={setDisabledBtn}
          setSelectedCities={setSelectedCities}
          setValue={setValue}
          submitAddress={submitAddress}
        />
        <AddItemPickup
          errors={errors}
          selectedAddressId={selectedAddressId}
          setDisabledBtn={setDisabledBtn}
          setSelectedAddress={setSelectedAddress}
          setSelectedAddressId={setSelectedAddressId}
          setValue={setValue}
          submitAddress={submitAddress}
        />
      </FlexRowWrap>
    </>
  )
}
