import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetReviewsListQuery } from '../entities/reviews/queries'
import { useGetUserProfileQuery } from '../entities/userProfile/queries'
import Breadcrumbs from '../features/market/ui/sections/BreadCrumbs'
import { RenterReviewTab } from '../features/userReview/ui/RenterReviewTab'
import { UserReviewDesktopHead } from '../features/userReview/ui/UserReviewDesktopHead'
import { UserReviewHead } from '../features/userReview/ui/UserReviewHead'
import { UserReviewWrapper } from '../features/userReview/ui/UserReviewWrapper'
import { ArrowBackButton } from '../shared/ui-kit/actual/ArrowBackButton'
import { ProfileHeader } from '../shared/ui-kit/actual/ProfileHeader'
import { MainHeader } from '../shared/ui-kit/actual/headers/MainHeader'
import { BasePageLayout } from '../shared/ui-kit/actual/layouts/BasePageLayout'
import { ProfileLayout } from '../shared/ui-kit/actual/layouts/ProfileLayout'
import { PageLoader } from '../shared/ui-kit/deprecated/PageLoader'
import {
  DesktopView,
  FlexColumn,
  FlexRowBetween,
  LoginText,
  MobileTabletView,
} from '../shared/ui-kit/projectComponents'
import { shortString } from '../shared/utils/truncate'

const UserReviewPage = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { userId } = useParams()
  const { data: userInfo, isLoading } = useGetUserProfileQuery(userId)

  const { data: buyerReviews } = useGetReviewsListQuery({
    buyerId: userId,
    skip: 0,
    take: 1000,
  })
  const buyerReviewsItems = buyerReviews?.items

  const { data: sellerReviews } = useGetReviewsListQuery({
    sellerId: userId,
    skip: 0,
    take: 1000,
  })
  const sellerReviewsItems = sellerReviews?.items
  const userReviews = [...(buyerReviewsItems || []), ...(sellerReviewsItems || [])]

  return (
    <>
      {isLoading ? (
        <FlexColumn style={{ height: '100vh' }}>
          <PageLoader />
        </FlexColumn>
      ) : (
        <>
          <MobileTabletView>
            <ProfileHeader
              center={<LoginText>@{shortString(userInfo?.accountName, 20)}</LoginText>}
              left={<ArrowBackButton />}
            />
            <ProfileLayout
              body={
                <UserReviewWrapper
                  activeTab={activeTab}
                  buyerReviewsItems={buyerReviewsItems?.reverse()}
                  sellerReviewsItems={sellerReviewsItems?.reverse()}
                  setActiveTab={setActiveTab}
                />
              }
              head={<UserReviewHead reviews={userReviews} />}
            />
          </MobileTabletView>

          <DesktopView>
            <BasePageLayout>
              <MainHeader />
              <FlexRowBetween style={{ alignItems: 'center' }}>
                <Breadcrumbs fromEnd={1} lastName={userInfo?.fullName} />
              </FlexRowBetween>
              <br />
              <ProfileLayout
                body={
                  !activeTab ? (
                    <RenterReviewTab reviews={buyerReviewsItems?.reverse()} isBuyer />
                  ) : (
                    <RenterReviewTab reviews={sellerReviewsItems?.reverse()} />
                  )
                }
                head={
                  <UserReviewDesktopHead
                    activeTab={activeTab}
                    reviews={userReviews}
                    setActiveTab={setActiveTab}
                  />
                }
              />
            </BasePageLayout>
          </DesktopView>
        </>
      )}
    </>
  )
}

export default UserReviewPage
