import { ReactNode } from 'react'

import styled from 'styled-components/macro'

import { colors } from '../../../constants/colors'
import { useDisableBodyScroll } from '../../../hooks/use-disable-body-scroll'
import { mobileTabletView } from '../../breakPoints'
import { FlexColumnStart } from '../../projectComponents'
import { verticalScroll } from '../../scroll'

const ModalBackground = styled.div<{ $viewModal: boolean }>`
  width: 100%;
  min-height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: flex-end;
  justify-content: center;
  z-index: 2000;
  visibility: ${({ $viewModal }) => ($viewModal ? '' : 'hidden')};
  ${mobileTabletView}
`

const ModalWrapper = styled(FlexColumnStart)`
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 64px;
  width: clamp(300px, 100%, 600px);
  height: 90vh;
  box-sizing: border-box;
  padding: 28px 24px 96px;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px -9px 15.7px 0px rgba(94, 94, 94, 0.07);
  background-color: ${colors.white};
  gap: 4px;
  overflow: auto;
  z-index: 2100;
  ${verticalScroll}
`

type Props = {
  children?: ReactNode
  setViewModal: (viewModal: boolean) => void
  viewModal: boolean
}

const preventClick = e => {
  e.stopPropagation()
}

export const MobileModal = ({ children, setViewModal, viewModal }: Props) => {
  return (
    <ModalBackground
      onClick={() => {
        setViewModal(false)
      }}
      $viewModal={viewModal}
    >
      <ModalWrapper onClick={preventClick}>{children}</ModalWrapper>
    </ModalBackground>
  )
}
