import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components/macro'

import { useProductsArchiveProductMutation } from '../../../../entities/marketItem/queries'
import { FlexColumn, FlexRowBetween, StyledButton } from '../../projectComponents'
import { H3 } from '../../titles'
import { TextArea } from '../TextArea'
import { MiniModal } from '../modals/MiniModal'
import { AbsoluteLoader } from './AbsoluteLoader'

const ContentModal = styled(FlexColumn)`
  padding-bottom: 20px;
  width: 100%;
`

interface ArchiveItemModalProps {
  archiveModal: boolean
  productId: number
  setOpen: (v: boolean) => void
}

export const AdminArchiveItemModal = ({
  archiveModal,
  productId,
  setOpen,
}: ArchiveItemModalProps) => {
  const navigate = useNavigate()

  const { isLoading, isSuccess, mutate } = useProductsArchiveProductMutation()
  const [comment, setComment] = useState('')
  const CloseModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    isSuccess && navigate(-1)
  }, [isSuccess, navigate])

  return (
    <MiniModal setViewModal={setOpen} viewModal={archiveModal}>
      <AbsoluteLoader isLoading={isLoading} />

      <H3 style={{ marginBottom: 20 }}>Отправить товар в архив?</H3>
      <TextArea
        inputProps={{
          placeholder: 'Введите комментарий',
        }}
        style={{
          borderRadius: '15px',
          flexGrow: 1,
          height: '48px',
          paddingLeft: '16px',
          width: '100%',
        }}
        onChange={e => setComment(e.target.value)}
        value={comment}
        fullwidth
      />
      <br />
      <ContentModal>
        <FlexRowBetween>
          <StyledButton onClick={CloseModal} variant={'outline'}>
            Отмена
          </StyledButton>
          <StyledButton
            onClick={() => {
              mutate({ comment, productId })
            }}
            disabled={isLoading}
            size={'xl'}
            style={{ width: '100%' }}
            variant={'warning'}
          >
            Скрыть в архиве
          </StyledButton>
        </FlexRowBetween>
      </ContentModal>
    </MiniModal>
  )
}
