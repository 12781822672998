import styled from 'styled-components/macro'

import { StarsRating } from '../../../../shared/ui-kit/actual/StarsRating'
import { FlexColumn, FlexRow, FlexRowBetween } from '../../../../shared/ui-kit/projectComponents'

const Wrapper = styled(FlexColumn)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  .paragraph {
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    color: #6e6e70;
  }
`

const ImageAvatar = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 50%;
`

interface ReviewItemProps {
  avatar: string
  dateAgo: string
  desc: string
  name: string
  rating: number
}

export const ReviewItem = ({ avatar, dateAgo, desc, name, rating }: ReviewItemProps) => {
  return (
    <Wrapper>
      <FlexRowBetween style={{ alignItems: 'last baseline', width: '100%' }}>
        <FlexRow style={{}}>
          <ImageAvatar alt={'avatar'} src={avatar || '/images/bottom-menu/profile-light.svg'} />
          <FlexColumn style={{ alignItems: 'start', marginLeft: '12px' }}>
            <StarsRating rating={rating} small />
            <div className={'paragraph'}>{name}</div>
          </FlexColumn>
        </FlexRow>
        <div style={{ marginTop: '8px' }}>
          <div className={'paragraph'}>{dateAgo} назад</div>
        </div>{' '}
      </FlexRowBetween>
      <div style={{ marginTop: '8px' }}>
        <div className={'paragraph'}>{desc}</div>
      </div>{' '}
    </Wrapper>
  )
}
