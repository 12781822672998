import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'
import jwtDecode from 'jwt-decode'

import { useEmailSignInMutation } from '../../../entities/auth/queries'
import { SignInEmailCommand } from '../../../shared/api/generated'
import { queryClient } from '../../../shared/api/query-client'
import UserStorage, { UserFromJWT } from '../../../shared/storage/UserStorage/UserStorage'

export function useEmailSignInForm() {
  const {
    clearErrors,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError,
    setFocus,
    setValue,
    watch,
  } = useForm<SignInEmailCommand>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })

  const store = useContext(UserStorage)
  const navigate = useNavigate()
  const signInMutation = useEmailSignInMutation({
    onError: error => {
      const e = error as AxiosError<{ message: string }>

      setError('password', {
        message: e.response?.data?.message,
        type: 'onChange',
      })
    },
    onSuccess: data => {
      store.setTokens({ accessToken: data?.accessToken, refreshToken: data?.refreshToken })
      const user = jwtDecode(data?.accessToken) as UserFromJWT

      store.setUser(user)
      store.setIsAuth(true)
      queryClient.invalidateQueries({ queryKey: ['get_my_profile_data'] })
      navigate('/')
    },
  })

  const authorization = async (data: SignInEmailCommand) => {
    signInMutation.mutate(data)
  }

  return {
    clearErrors,
    errors,
    handleSubmit: handleSubmit(authorization),
    isValid,
    register,
    setFocus,
    setValue,
    signInMutation,
    watch,
  }
}
