import React from 'react'

import styled from 'styled-components/macro'

import { FlexRowBetween } from '../projectComponents'

const Block = styled(FlexRowBetween)`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e0e5e6;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    color: #000;
    display: flex;
    justify-content: space-between;
    width: inherit;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`

interface RadioInputProps {
  handleSetOrder: () => void
  selectedSort: string
  selector: {
    name: string
    value: string
  }
}

export const RadioInput: React.FC<RadioInputProps> = ({
  handleSetOrder,
  selectedSort,
  selector,
}) => {
  return (
    <Block onClick={handleSetOrder}>
      <label>
        {selector.name}
        <div style={{ cursor: 'pointer', height: 20, width: 20 }}>
          <input
            checked={selector.value === selectedSort}
            name={selector.value}
            onChange={handleSetOrder}
            style={{ opacity: 0, position: 'absolute' }}
            type={'radio'}
            value={selector.value}
          />
          <img
            alt={'selector'}
            height={20}
            src={`/images/buttons/${selectedSort === selector.value ? 'active-radio.svg' : 'disabled-radio.svg'}`}
            style={{}}
            width={20}
          />
        </div>
      </label>
    </Block>
  )
}
