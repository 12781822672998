const displayStringByAmount = (str: string, length: number): string => {
  const lastTwoDigits = length % 100
  const lastDigit = length % 10

  if (
    (lastTwoDigits >= 5 && lastTwoDigits <= 19) ||
    lastDigit === 0 ||
    (lastDigit >= 5 && lastDigit <= 9)
  ) {
    return `${str}ов`
  } else if (lastDigit === 1) {
    return str
  } else {
    return `${str}а`
  }
}

export const displayRateByAmount = (str: string, length: number): string => {
  const lastTwoDigits = length % 100
  const lastDigit = length % 10

  if (
    (lastTwoDigits >= 5 && lastTwoDigits <= 19) ||
    lastDigit === 0 ||
    (lastDigit >= 5 && lastDigit <= 9)
  ) {
    return `${str}ок`
  } else if (lastDigit === 1) {
    return `${str}ка`
  } else {
    return `${str}ки`
  }
}

export default displayStringByAmount

export const calculateStarAmount = (currentStar: number, itemData) => {
  return itemData?.filter(item => item.rating === currentStar)?.length
}

export const calculateStarPercent = (currentStar: number, itemData) => {
  return (calculateStarAmount(currentStar, itemData) / itemData?.length) * 100
}
