import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import styled from 'styled-components/macro'

import {
  GetProductTagsListResponse,
  GetProductsListResponse,
  postApiProductsGetProductsList,
} from '../../api/generated'
import { useDebounceSearch } from '../../hooks/use-debounce-search'
import { useQueryString } from '../../hooks/useQueryString'
import { FlexRow } from '../projectComponents'
import DropdownProductComponent from './DropdownProductComponent/DropdownProductComponent'

interface MainSearchComponentProps {
  hideFilterButton?: boolean
  noLogo?: boolean
  onClickFilter?: () => void
  setIsFixed: (value: boolean) => void
}

const SearchWrapper = styled(FlexRow)`
  border-radius: 10px;
  width: 100%;
  max-width: 750px;
  z-index: 1999;
  @media (max-width: 800px) {
    margin-right: 0;
  }
`

export const MainSearchComponent = ({
  hideFilterButton,
  noLogo,
  onClickFilter,
  setIsFixed,
}: MainSearchComponentProps) => {
  const { setValue: setSearchValue, value: searchValue } = useQueryString('searchValue')

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [openProducts, setOpenProducts] = useState(false)
  const [value, setValue] = useState(searchValue || '')

  useEffect(() => {
    if (value.length > 1) {
      setIsFixed(true)
    } else {
      setIsFixed(false)
    }
  }, [setIsFixed, value])

  const { isLoading, productsList } = useDebounceSearch<GetProductsListResponse>({
    getApi: postApiProductsGetProductsList,
    termName: 'ProductNameTerm',
    value,
  })

  return (
    <SearchWrapper>
      <DropdownProductComponent
        hideFilterButton={hideFilterButton}
        isLoading={isLoading}
        itemId={selectedProduct}
        itemList={productsList?.items}
        noLogo={noLogo}
        onClickFilter={onClickFilter}
        openMenu={openProducts}
        placeholder={'Поиск по названию'}
        setId={setSelectedProduct}
        setOpenMenu={setOpenProducts}
        setValue={setValue}
        value={value}
      />
    </SearchWrapper>
  )
}
