import { useContext, useMemo } from 'react'

import styled from 'styled-components/macro'

import { useUsersGetUserInfoQuery } from '../../../../entities/ownProfile/queries'
import { colors } from '../../../../shared/constants/colors'
import ProfileStorage from '../../../../shared/storage/ProfileStorage/ProfileStorage'
import UserStorage from '../../../../shared/storage/UserStorage/UserStorage'
import {
  AvatarLarge,
  BoldNumber,
  FlexColumn,
  OpacityText,
  RatingWrapper,
  RelativeWrapper,
} from '../../../../shared/ui-kit/projectComponents'

const StyledHeader = styled.h3`
  max-width: 272px;
  color: ${colors.black};
  font-size: 21px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin: 0;
`

const SubHeader = styled(StyledHeader)`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`

const UserNameWrapper = styled(FlexColumn)`
  gap: 8px;
  margin-top: 32px;
  color: #fff;
  margin-bottom: 20px;
`
const SubscribersWrapper = styled.div<{ $activeTab: number }>`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(2, 20px);
  column-gap: 50px;
  justify-items: center;
  transition: 0.2s ease-out;
  height: ${({ $activeTab }) => ($activeTab ? '40px' : '0')};
  transform: ${({ $activeTab }) => ($activeTab ? 'none' : 'scaleY(0%)')};
`

type Props = {
  activeTab: number
  setViewModal: (viewModal: boolean) => void
}

export const ProfileGoodsHeader = ({ activeTab, setViewModal }: Props) => {
  const ProfileStore = useContext(ProfileStorage)
  const profile = ProfileStore.getProfile()
  const store = useContext(UserStorage)

  const userRating = profile?.sellerRating || 0
  const { data } = useUsersGetUserInfoQuery(store?.userId)

  return (
    <FlexColumn>
      <RelativeWrapper>
        <AvatarLarge src={data?.profileImageUrl || '/images/bottom-menu/profile-light.svg'} />

        <RatingWrapper onClick={() => setViewModal(true)} roleType={profile.accountType}>
          <img alt={''} src={'/images/interface/whiteStar.svg'} />
          <span>{userRating?.toFixed(1)}</span>
        </RatingWrapper>
      </RelativeWrapper>

      <UserNameWrapper>
        <StyledHeader>{data?.fullName}</StyledHeader>
        <SubHeader>{data?.aboutItself}</SubHeader>
      </UserNameWrapper>
      <SubscribersWrapper $activeTab={activeTab}>
        <BoldNumber>{profile?.subscribersCount}</BoldNumber>
        <BoldNumber>{profile?.subscriptionsCount}</BoldNumber>
        <OpacityText>Подписчиков</OpacityText>
        <OpacityText>Подписок</OpacityText>
      </SubscribersWrapper>
    </FlexColumn>
  )
}
