export const menuLinks = [
  {
    img: '/images/bottom-menu/cart-gray.svg',
    imgActive: '/images/bottom-menu/cart-light.svg',
    name: 'Заказы',
    url: '/orders',
  },
  {
    img: '/images/bottom-menu/chat-gray.svg',
    imgActive: '/images/bottom-menu/chat-light.svg',
    name: 'Чат',
    url: '/messages',
  },
  {
    img: '/images/bottom-menu/blog-gray.svg',
    imgActive: '/images/bottom-menu/blog-light.svg',
    name: 'Блог',
    url: '/blog',
  },
  {
    img: '/images/bottom-menu/main-gray.svg',
    imgActive: '/images/bottom-menu/main-light.svg',
    name: 'Главная',
    url: '/',
  },
  {
    img: '/images/bottom-menu/profile-gray.svg',
    imgActive: '/images/bottom-menu/profile-light.svg',
    name: 'Профиль',
    url: '/profile',
  },
  // {
  //   img: '/images/buttons/headphones.svg',
  //   imgActive: '/images/buttons/headphones.svg',
  //   name: 'Поддержка',
  //   target: '_blank',
  //   url: 'https://t.me/Allsharing_support',
  // },
]
