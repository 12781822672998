import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetMarketItemQuery } from '../entities/marketItem/queries'
import { ItemEditView } from '../features/marketItemEdit/ui/ItemEditView'
import UserStorage from '../shared/storage/UserStorage/UserStorage'
import { AbsoluteLoader } from '../shared/ui-kit/actual/components/AbsoluteLoader'
import { FlexColumn } from '../shared/ui-kit/projectComponents'

export const EditItemPage = () => {
  const params = useParams()
  const itemId = params['itemId']
  const { userId } = useContext(UserStorage)
  const navigate = useNavigate()
  const { data: productInfo, isError, isLoading } = useGetMarketItemQuery({ productId: +itemId })

  useEffect(() => {
    if (productInfo && productInfo?.owner?.id !== userId) {
      navigate(-1)
    }
  }, [navigate, productInfo, userId])

  return (
    <FlexColumn style={{ width: '100%' }}>
      {isLoading ? (
        <AbsoluteLoader isLoading={isLoading} />
      ) : (
        <>{!isError && productInfo && <ItemEditView productInfo={productInfo} />}</>
      )}
    </FlexColumn>
  )
}
