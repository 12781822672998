import styled from 'styled-components/macro'

import { PageLoader } from '../../../../shared/ui-kit/deprecated/PageLoader'
import {
  FilterTitle,
  FlexColumnStart,
  FlexRowBetween,
  StyledTag,
} from '../../../../shared/ui-kit/projectComponents'

const mockTags = ['Короткие', 'Длинные', 'Яркие', 'Миди', 'Хит сезона']

const SectionTitle = styled(FilterTitle)`
  margin-bottom: 12px;
`

const TagsWrapper = styled(FlexColumnStart)`
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
`

const FilterTagList = () => {
  return (
    <>
      <FlexRowBetween>
        <SectionTitle>Теги</SectionTitle>
        <div>Все</div>
      </FlexRowBetween>

      {mockTags.length > 0 ? (
        <TagsWrapper>
          {mockTags.map((item, idx) => (
            <StyledTag key={idx}>{item}</StyledTag>
          ))}
        </TagsWrapper>
      ) : (
        <PageLoader />
      )}
    </>
  )
}

export default FilterTagList
