import { useForm } from 'react-hook-form'

import { useUpdateProductDeliveryMutation } from '../../../entities/marketItem/queries'
import { UpdateProductDeliveryCommand } from '../../../shared/api/generated'

export function useItemAddressEditForm() {
  const {
    clearErrors,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<UpdateProductDeliveryCommand>({
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const { isLoading, isSuccess, mutate } = useUpdateProductDeliveryMutation({})

  const updateProduct = async (data: UpdateProductDeliveryCommand) => {
    mutate(data)
  }

  return {
    clearErrors,
    errors,
    getValues,
    handleSubmit: handleSubmit(updateProduct),
    isLoading,
    isSuccess,
    isValid,
    register,
    reset,
    setError,
    setFocus,
    setValue,
    trigger,
    updateProduct,
    watch,
  }
}
