import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components/macro'

import { breakpoints } from '../breakPoints'

type ProfileHeaderProps = {
  center?: ReactNode
  left?: ReactNode
  right?: ReactNode
}

const Wrapper = styled.header`
  z-index: 100;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  align-items: center;
  padding: 16px 0;
  min-height: 32px;
  width: 100%;

  .left {
    align-items: flex-start;
    /* @media screen and (width <= ${breakpoints.mobileMax}) {
      padding-left: 5px;
    } */
  }

  .right {
    align-items: flex-end;
    /* @media screen and (width <= ${breakpoints.mobileMax}) {
      padding-right: 5px;
    } */
    & img {
      cursor: pointer;
      margin-right: 5px;
    }
  }

  .center {
    align-items: center;
  }
`

const SideWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:nth-child(2) {
    flex: 1 0 70%;
  }
`

export const ProfileHeader = ({ center, left, right }: ProfileHeaderProps) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Wrapper>
      <SideWrapper className={'left'}>{left}</SideWrapper>
      <SideWrapper className={'center'}>{center}</SideWrapper>
      <SideWrapper className={'right'}>{right}</SideWrapper>
    </Wrapper>
  )
}
